import { Observable } from 'rxjs';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault('Asia/Kolkata');
export interface AppointmentTimeState {
  onGoing: boolean;
  inPast: boolean;
  inFuture: boolean;
  before24Hr: boolean;
  in24Hr: boolean;
  before4Hr: boolean;
  in4Hr: boolean;
  inSTMinus5: boolean;
  inSTPlus10: boolean;
}
export class AppointmentTimeHelper {
  constructor() {}

  getApptTimeState(date: string, timeslot: string) {
    return new Observable<AppointmentTimeState>((subscriber) => {
      const timer = setInterval(() => {
        let currentDateTime = dayjs(
          new Date().getTime()
        ).valueOf();
        let timeState: AppointmentTimeState = {
          onGoing: false,
          inPast: false,
          inFuture: false,
          before24Hr: false,
          in24Hr: false,
          inSTMinus5: false,
          inSTPlus10: false,
          before4Hr: false,
          in4Hr: false,
        };
        if (date && timeslot) {
          let stime = timeslot.split('-')[0];
          let etime = timeslot.split('-')[1];

          let apptDateSTime = dayjs(
            date + ' ' + stime
          ).valueOf();
          let apptDateETime = dayjs(
            date + ' ' + etime
          ).valueOf();
          
          if(etime<stime){
            apptDateETime = dayjs(
              date + ' ' + etime
            ).add(1,'day').valueOf();
          }
          
          let STMinus5Mins = apptDateSTime - 5 * 60 * 1000;
          let STPlus10Mins = apptDateSTime + 10 * 60 * 1000;
          let STMinus24Hrs = apptDateSTime - 24 * 60 * 60 * 1000;
          let STMinus4Hrs = apptDateSTime - 4 * 60 * 60 * 1000;

          if (
            currentDateTime <= STMinus24Hrs &&
            currentDateTime < apptDateSTime
          ) {
            //before 24hr window
            timeState.before24Hr = true;
          }
          if (
            currentDateTime > STMinus24Hrs &&
            currentDateTime < apptDateSTime
          ) {
            //in 24hr window
            timeState.in24Hr = true;
          }
          if (
            currentDateTime <= STMinus4Hrs &&
            currentDateTime < apptDateSTime
          ) {
            //before 4hr from ST
            timeState.before4Hr = true;
          }
          if (
            currentDateTime > STMinus4Hrs &&
            currentDateTime < apptDateSTime
          ) {
            //in 4hr window from ST
            timeState.in4Hr = true;
          }
          if (
            currentDateTime >= STMinus5Mins &&
            currentDateTime < apptDateSTime
          ) {
            //in ST minus 5mins
            timeState.inSTMinus5 = true;
          }
          if (
            currentDateTime < STPlus10Mins &&
            currentDateTime >= apptDateSTime
          ) {
            //in ST plus 10mins
            timeState.inSTPlus10 = true;
          }
          if (
            apptDateETime > currentDateTime &&
            apptDateSTime <= currentDateTime
          ) {
            //in appt. time frame
            timeState.onGoing = true;
          }
          if (apptDateETime < currentDateTime) {
            //after appt. time frame
            timeState.inPast = true;
          }
          if (currentDateTime < apptDateSTime) {
            //before appt. time frame
            timeState.inFuture = true;
          }
        }
        subscriber.next(timeState);
      }, 1500);

      const unsubscribe = () => {
        clearInterval(timer);
      };
      return unsubscribe;
    });
  }
  sleep(millis: number) {
    return new Promise((resolve) => setTimeout(resolve, millis));
  }
}
