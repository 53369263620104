import { Expose } from "class-transformer";

export class OrganisationConnection {
  @Expose()
  organisationName: string;

  @Expose()
  organisationId: string;

  @Expose()
  uhid: string;

  @Expose()
  connectedById: string;

  @Expose()
  connectedByName: string;

  @Expose()
  connectionId: string;

  @Expose()
  connectedOn: number;

  @Expose()
  lastInteractedOn: number;
}
