import { Pipe, PipeTransform } from '@angular/core';
import { DoctorListingProfile } from '../models-ts/Actors/Doctor/DoctorListingProfile';
@Pipe({
  name: 'assignedFilter',
})
export class AssignedFilterPipe implements PipeTransform {
  transform(items: DoctorListingProfile[], filter: any): any[] {
    if (!items) return [];
    // if (!filter.searchText) return items;
    filter.searchText = filter.searchText.toLowerCase();

    items = items.filter((element) => {
      if (
        element.assignProfile.get(filter.assignedFor) &&
        element.assignProfile.get(filter.assignedFor).status == 'unassigned'
      ) {
        return true;
      }
      return false;
    });

    if (filter.photoUrl && filter.photoUrl != '') {
      items = items.filter((element) => {
        if (element.photoUrl) {
          return true;
        }
        return false;
      });
    }
    if (
      filter.medicalRegistrationVerified &&
      filter.medicalRegistrationVerified != ''
    ) {
      items = items.filter((element) => {
        if (element.isMedicalRegistrationVerified == true) {
          return true;
        }
        return false;
      });
    }
    if (filter.showInListing && filter.showInListing != '') {
      items = items.filter((element) => {
        if (element.showInListing) {
          return true;
        }
        return false;
      });
    }
    if (filter.publicContactNumber && filter.publicContactNumber != '') {
      items = items.filter((element) => {
        if (element.publicContactNumber && element.publicContactNumber.number) {
          return true;
        }
        return false;
      });
    }
    if (filter.photoUrl === false) {
      items = items.filter((element) => {
        if (element.photoUrl == null || element.photoUrl === '') {
          return true;
        }
        return false;
      });
    }
    if (filter.publicContactNumber === false) {
      items = items.filter((element) => {
        if (
          element.publicContactNumber == null ||
          element.publicContactNumber.number == null
        ) {
          return true;
        }
        return false;
      });
    }

    if (filter.medicalRegistrationVerified === false) {
      items = items.filter((element) => {
        if (element.isMedicalRegistrationVerified == false) {
          return true;
        }
        return false;
      });
    }

    return items.filter((it) => {
      if (it.name.toLowerCase().includes(filter.searchText)) {
        return true;
      }
      if (it.id.toLowerCase().includes(filter.searchText)) {
        return true;
      }
      return false;
    });
  }
}
