export let masterSpecializationMap: any = {
    // "Paediatrician": "paediatrician",
    // "Gastroenterologist": "gastroenterologist",
    // "Veterinarian": "veterinarian",
    // "Neurologist": "neurologist",
    // "Internal Medicine": "physician",
    // "Consultant Physician": "physician",
    // "General Physician": "physician",
    // "Diabetologist": "diabetologist",
    // "General Practitioner": "physician",
    // "Aviation and Aerospace Medicine Specialist": "physician",
    // "Urologist": "urologist",
    // "Laparoscopic Surgeon": "surgeon",
    // "ENT/ Otorhinolaryngologist": "ent-ear-nose-throat-specialist",
    // "ENT Specialist": "ent-ear-nose-throat-specialist",
    // "ENT specialist": "ent-ear-nose-throat-specialist",
    // "Gynecologist": "gynaecologist",
    // "Gynaecologist": "gynaecologist",
    // "Laparoscopic Surgeon (Obs & Gyn)": "gynaecologist",
    // "Obstetrician": "obstetrician",
    // "Joint Replacement Surgeon": "orthopedist",
    // "Orthopedist": "orthopedist",
    // "Pediatric Neurologist": "pediatric-neurologist",
    // "Pediatrician": "paediatrician",
    // "Bariatric Surgeon": "bariatric-surgeon",
    // "General Surgeon": "surgeon",
    // "general-surgeon": "surgeon",
    // "Family Physician": "physician",
    // "Endocrinologist": "endocrinologist",
    // "Infectious Diseases Physician": "infectious-diseases-physician",
    // "HIV Specialist": "infectious-diseases-physician",
    // "Dermatologist": "dermatologist",
    // "Cosmetologist": "dermatologist",
    // "Neonatologist": "neonatologist",
    // "Allergist/Immunologist": "paediatrician",
    // "GastroIntestinal Surgeon": "gastrointestinal-surgeon",
    // "Hepatologist": "hepatologist",
    // "Dentist": "dentist",
    // "Cosmetic/Aesthetic Dentist": "dentist",
    // "Dental Surgeon": "dentist",
    // "Oral And MaxilloFacial Surgeon": "dentist",
    // "Implantologist": "dentist",
    // "Orthodontist": "dentist",
    // "Pediatric Dentist": "dentist",
    // "Periodontist": "dentist",
    // "Prosthodontist": "dentist",
    "Therapist / Counsellor": "therapists",
    "Addiction Psychiatrist": "psychiatrist",
    "Psychiatrist": "psychiatrist",
    // "Ayurveda": "ayurveda",
    "Counselling Psychologist": "psychologist",
    "Counseling Psychologist": "psychologist",
    "Life Coach": "psychologist",
    "Counselling Therapist": "psychologist",
    // "Homoeopath": "homoeopath",
    "Clinical Psychologist": "psychologist",
    "Psychologist": "psychologist",
    "Health Psychologist": "psychologist",
    // "Pulmonologist": "pulmonologist",
    // "Veterinary Physician": "veterinarian",
    // "Paediatric Intensivist": "paediatrician",
    // "Veterinary Surgeon": "veterinarian",
    // "Pediatric Dermatologist": "dermatologist",
    // "Interventional Cardiologist": "cardiologist",
    // "Cardiologist": "cardiologist",
    "Psychotherapist": "psychologist",
    "Geriatric Psychiatrist": "psychiatrist",
    "Forensic Psychiatrist": "psychiatrist",
    // "Neurosurgeon": "neurosurgeon",
    // "Spine Surgeon": "" //Under review,
    // "Sexologist (Ayurveda)": "ayurveda",
    // "Sexologist (Unani)": "unani",
    // "Unani": "unani",
    // "Urological Surgeon": "urological-surgeon",
    // "Tuberculous and chest Diseases Specialist": "pulmonologist",
    // "Somnologist (Sleep Specialist)": "pulmonologist"
}

export let masterSpecializationDisplayMap: any = {
    // "paediatrician": "Paediatrician",
    // "gastroenterologist": "Gastroenterologist",
    // "veterinarian": "Veterinarian",
    // "neurologist": "Neurologist",
    // "physician": "Physician",
    // "diabetologist": "Diabetologist",
    // "urologist": "Urologist",
    // "surgeon": "Surgeon",
    // "general-surgeon": "General Surgeon",
    // "ent-ear-nose-throat-specialist": "ENT Specialist",
    // "gynaecologist": "Gynaecologist",
    // "obstetrician": "Obstetrician",
    // "obstetrician-gynaecologist": "Ob/Gyn",
    // "orthopedist": "Orthopedist",
    // "pediatric-neurologist": "Pediatric Neurologist",
    // "bariatric-surgeon": "Bariatric Surgeon",
    // "endocrinologist": "Endocrinologist",
    // "infectious-diseases-physician": "Infectious Diseases Physician",
    // "dermatologist": "Dermatologist",
    // "neonatologist": "Neonatologist",
    // "gastrointestinal-surgeon": "Gastrointestinal Surgeon",
    // "hepatologist": "Hepatologist",
    // "dentist": "Dentist",
    "psychiatrist": "Psychiatrist",
    // "ayurveda": "Ayurveda",
    "psychologist": "Psychologist",
    // "homoeopath": "Homoeopath",
    // "pulmonologist": "Pulmonologist",
    // "cardiologist": "Cardiologist",
    // "neurosurgeon": "Neurosurgeon",
    // "unani": "Unani",
    // "urological-surgeon": "Urological Surgeon"
}

export let convertSpecializationArrayForSlug = (array: string[]) => {
    let tempArray: string[] = [];
    for (let s of array) {
        if (masterSpecializationMap[s] != null && !tempArray.includes(masterSpecializationMap[s])) {
            tempArray.push(masterSpecializationMap[s]);
        }
    }
    return tempArray;
}

export let masterSpecializationConversion = (array: string[]) => {
    let tempArray: string[] = [];
    for (let s of array) {
        if (masterSpecializationMap[s] != null && !tempArray.includes(masterSpecializationMap[s])) {
            tempArray.push(masterSpecializationMap[s]);
        }
    }
    // console.log(tempArray);
    return applyRules(tempArray);
}

export let masterSpecializationConversionDisplayValue = (array: string[]) => {
    let valueArray = masterSpecializationConversion(array);
    let tempArray: string[] = [];
    valueArray.forEach(val => {
        if (masterSpecializationDisplayMap[val]) {
            tempArray.push(masterSpecializationDisplayMap[val])
        } else {
            tempArray.push(val);
        }
    })
    return tempArray;
}

let applyRules = (array: string[]) => {
    // if (array.includes("ent-ear-nose-throat-specialist")) {
    //     array = entRules(array);
    // }
    // if (array.includes("endocrinologist")) {
    //     array = endocrinologistRule(array);
    // }
    // if (array.includes("diabetologist")) {
    //     array = diabetologistRule(array);
    // }
    // if (array.includes("obstetrician") || array.includes("gynaecologist")) {
    //     array = obgRule(array);
    // }
    // if (array.includes("infectious-diseases-physician")) {
    //     array = infectiousDiseasePhysicianRule(array);
    // }
    // if (array.includes("pulmonologist")) {
    //     array = pulmonologistRule(array);
    // }
    // if (array.includes("urologist")) {
    //     array = urologistRule(array);
    // }
    // if (array.includes("urological-surgeon")) {
    //     array = urologicalSurgeonRule(array);
    // }
    // if (array.includes("neurosurgeon")) {
    //     array = neuroSurgeonRule(array);
    // }
    // if (array.includes("surgeon")) {
    //     array = generalSurgeonRule(array);
    // }
    if (array.includes("psychiatrist")) {
        array = psychiatristRule(array);
    }
    // if (array.includes("ayurveda")) {
    //     array = ayurvedaRule(array);
    // }
    // if (array.includes("homoeopath")) {
    //     array = homeopathRule(array);
    // }
    // if (array.includes("bariatric-surgeon")) {
    //     array = bariatricSurgeonRule(array);
    // }
    // if (array.includes("paediatrician")) {
    //     array = paediatricianRule(array);
    // }
    // if (array.includes("hepatologist")) {
    //     array = hepatologistRule(array);
    // }
    // if (array.includes("pediatric-neurologist")) {
    //     array = pediatricNeurologistRule(array);
    // }
    // if (array.includes("cardiologist")) {
    //     array = cardiologistRule(array);
    // }
    // if (array.includes("dentist")) {
    //     array = dentistRule(array);
    // }
    // if (array.includes("dermatologist")) {
    //     array = dermatologistRule(array);
    // }
    // if (array.includes("gastrointestinal-surgeon")) {
    //     array = gastrointestinalSurgeonRule(array);
    // }
    // if (array.includes("gastroenterologist")) {
    //     array = gastroenterologistRule(array);
    // }
    // if (array.includes("orthopedist")) {
    //     array = orthopedistRule(array);
    // }
    return array;
}



// let entRules = (array: string[]) => {
//     if (array.includes("physician")) {
//         array.splice(array.indexOf("physician"), 1);
//     }
//     if (array.includes("surgeon")) {
//         array.splice(array.indexOf("surgeon"), 1);
//     }
//     return array;
// }

// let orthopedistRule = (array: string[]) => {
//     if (array.includes("physician")) {
//         array.splice(array.indexOf("physician"), 1);
//     }
//     if (array.includes("surgeon")) {
//         array.splice(array.indexOf("surgeon"), 1);
//     }
//     return array;
// }

// let gastroenterologistRule = (array: string[]) => {
//     if (array.includes("physician")) {
//         array.splice(array.indexOf("physician"), 1);
//     }
//     if (array.includes("surgeon")) {
//         array.splice(array.indexOf("surgeon"), 1);
//     }
//     return array;
// }

// let gastrointestinalSurgeonRule = (array: string[]) => {
//     if (array.includes("gastroenterologist")) {
//         array.splice(array.indexOf("gastroenterologist"), 1);
//     }
//     if (array.includes("surgeon")) {
//         array.splice(array.indexOf("surgeon"), 1);
//     }
//     return array;
// }

// let dermatologistRule = (array: string[]) => {
//     if (array.includes("physician")) {
//         array.splice(array.indexOf("physician"), 1);
//     }
//     return array;
// }

// let dentistRule = (array: string[]) => {
//     array.splice(0);
//     array.push("dentist");
//     return array;
// }

// let cardiologistRule = (array: string[]) => {
//     if (array.includes("urologist")) {
//         array.splice(array.indexOf("urologist"), 1);
//     }
//     if (array.includes("physician")) {
//         array.splice(array.indexOf("physician"), 1);
//     }
//     return array;
// }

// let obgRule = (array: string[]) => {
//     if (array.includes("obstetrician") && array.includes("gynaecologist")) {
//         array.splice(array.indexOf("obstetrician"), 1);
//         array.splice(array.indexOf("gynaecologist"), 1);
//         array.push("obstetrician-gynaecologist");
//     }
//     if (array.includes("gynaecologist")) {
//         if (array.includes("physician")) {
//             array.splice(array.indexOf("physician"), 1);
//         }
//         if (array.includes("surgeon")) {
//             array.splice(array.indexOf("surgeon"), 1);
//         }
//     }
//     return array;
// }

// let pulmonologistRule = (array: string[]) => {
//     if (array.includes("physician")) {
//         array.splice(array.indexOf("physician"), 1);
//     }
//     if (array.includes("urologist")) {
//         array.splice(array.indexOf("urologist"), 1);
//     }
//     return array;
// }

// let urologicalSurgeonRule = (array: string[]) => {
//     if (array.includes("urologist")) {
//         array.splice(array.indexOf("urologist"), 1);
//     }
//     if (array.includes("surgeon")) {
//         array.splice(array.indexOf("surgeon"), 1);
//     }
//     return array;
// }

// let urologistRule = (array: string[]) => {
//     if (array.includes("physician")) {
//         array.splice(array.indexOf("physician"), 1);
//     }
//     if (array.includes("surgeon")) {
//         array.splice(array.indexOf("surgeon"), 1);
//     }
//     return array;
// }

// let generalSurgeonRule = (array: string[]) => {
//     if (array.includes("physician")) {
//         array.splice(array.indexOf("physician"), 1);
//     }
//     return array;
// }

// let neuroSurgeonRule = (array: string[]) => {
//     if (array.includes("neurologist")) {
//         array.splice(array.indexOf("neurologist"), 1);
//     }
//     if (array.includes("surgeon")) {
//         array.splice(array.indexOf("surgeon"), 1);
//     }
//     if (array.includes("psychologist")) {
//         array.splice(array.indexOf("psychologist"), 1);
//     }
//     return array;
// }

// let paediatricianRule = (array: string[]) => {
//     if (array.includes("physician")) {
//         array.splice(array.indexOf("physician"), 1);
//     }
//     if (array.includes("surgeon")) {
//         array.splice(array.indexOf("surgeon"), 1);
//     }
//     if (array.includes("neonatologist")) {
//         array.splice(array.indexOf("neonatologist"), 1);
//     }
//     return array;
// }
// let pediatricNeurologistRule = (array: string[]) => {
//     if (array.includes("paediatrician")) {
//         array.splice(array.indexOf("paediatrician"), 1);
//     }
//     return array;
// }
// let hepatologistRule = (array: string[]) => {
//     if (array.includes("physician")) {
//         array.splice(array.indexOf("physician"), 1);
//     }
//     return array;
// }
let psychiatristRule = (array: string[]) => {
    if (array.includes("Addiction Psychiatrist")) {
        array.splice(array.indexOf("psychologist"), 1);
    }
    if (array.includes("psychologist")) {
        array.splice(array.indexOf("psychologist"), 1);
    }
    if (array.includes("physician")) {
        array.splice(array.indexOf("physician"), 1);
    }
    return array;
}

// let ayurvedaRule = (array: string[]) => {
//     array.splice(0);
//     array.push("ayurveda");
//     return array;
// }

// let homeopathRule = (array: string[]) => {
//     array.splice(0);
//     array.push("homeopath")
//     return array;
// }
// let bariatricSurgeonRule = (array: string[]) => {
//     if (array.includes("surgeon")) {
//         array.splice(array.indexOf("surgeon"), 1);
//     }
//     if (array.includes("gastrointestinal-surgeon")) {
//         array.splice(array.indexOf("gastrointestinal-surgeon"), 1);
//     }
//     if (array.includes("gastroenterologist")) {
//         array.splice(array.indexOf("gastroenterologist"), 1);
//     }
//     return array;
// }

// let infectiousDiseasePhysicianRule = (array: string[]) => {

//     if (array.includes("physician")) {
//         array.splice(array.indexOf("physician"), 1);
//     }
//     if (array.includes("urologist")) {
//         array.splice(array.indexOf("urologist"), 1);
//     }
//     return array;
// }


// let diabetologistRule = (array: string[]) => {
//     if (array.includes("physician")) {
//         array.splice(array.indexOf("physician"), 1);
//     }
//     return array;
// }

// let endocrinologistRule = (array: string[]) => {
//     if (array.includes("diabetologist")) {
//         array.splice(array.indexOf("diabetologist"), 1);
//     }
//     if (array.includes("urologist")) {
//         array.splice(array.indexOf("urologist"), 1);
//     }
//     if (array.includes("physician")) {
//         array.splice(array.indexOf("physician"), 1);
//     }
//     return array;
// }