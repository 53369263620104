import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DoctorListingProfile } from '../../models-ts/Actors/Doctor/DoctorListingProfile';
import { User } from '../../models-ts/Actors/User/User';
import { PatientService } from '../../services/patient/patient.service';

@Component({
  selector: 'app-patient-search',
  templateUrl: './patient-search.component.html',
  styleUrls: ['./patient-search.component.css'],
})
export class PatientSearchComponent implements OnInit {
  patientProfilesList: User[] = [];
  selectedDoctorProfile: DoctorListingProfile = new DoctorListingProfile();
  searchSub = new Subject<string>();
  @Input()
  set patientId(val: string) {
    if (val) {
      this.searchPatient(val);
    }
  }
  @Input() searchText: string;
  @Output() getPatient: EventEmitter<User> = new EventEmitter<User>();

  @Input() listMode: boolean = false;
  @Input() focusOnInit: boolean = false;
  @Input() specificProvider: boolean = false;
  @ViewChild('patientSearchSelect') patientSearchSelect;
  selectedIndex: number;
  loadList: boolean = false;
  constructor(private _patientService: PatientService) {}

  ngOnInit(): void {
    this.searchSub.pipe(debounceTime(1000)).subscribe((val) => {
      this.searchPatient(val);
    });
  }

  ngAfterViewInit() {
    this.initSeq();
  }

  searchPatient(val) {
    // console.log("search called: ",val)
    this.patientProfilesList = [];
    this.loadList = true;
    let connectedDoctorId = null;
    if (this.selectedDoctorProfile && this.selectedDoctorProfile.id) {
      connectedDoctorId = this.selectedDoctorProfile.id;
    }
    this._patientService
      .searchPatientByKeyword(val, connectedDoctorId)
      .then((res) => {
        if (res != null) {
          this.loadList = false;
          res.forEach((val) => {
            let json = val;
            // console.log(json);
            this.patientProfilesList.push(plainToClass(User, json));
          });
          // console.warn("ran name search");
        } else {
          this.loadList = false;
        }
        if (
          this.patientProfilesList &&
          this.patientProfilesList.length == 1 &&
          !this.specificProvider
        ) {
          let _ = this.patientProfilesList[0];
          this.getPatient.emit(_);
          this.searchText = _.name;
        }
      })
      .catch((err) => {
        console.log(err);
        this.loadList = false;
      });
  }

  setSearch(event) {
    let _ = event.target.value;
    this.searchSub.next(_);
  }

  setPatient(patient?: User, index?: number) {
    if (index != null) {
      this.selectedIndex = index;
      // console.log("index",this.selectedIndex)
    }

    let _;
    if (patient) {
      _ = patient;
    } else {
      _ = this.patientProfilesList.filter(
        (da) => da.docvitaId == this.searchText
      )[0];
    }

    // console.log(_)
    this.getPatient.emit(_);
  }

  initSeq() {
    if (this.focusOnInit) {
      document.getElementById('patient-search-inp').focus();
    }
    if (this.searchText) {
      // console.log(this.searchText)
      this.searchSub.next(this.searchText);
    }
  }
  setProviderFromSearch(event) {
    this.selectedDoctorProfile = new DoctorListingProfile();
    if (event && event['id']) {
      this.selectedDoctorProfile = event;
    }
    this.searchSub.next(this.searchText);
  }
}
