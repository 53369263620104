import { Component, OnInit } from '@angular/core';
import { classToPlain } from 'class-transformer';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { DoctorListingProfile } from 'src/app/shared/models-ts/Actors/Doctor/DoctorListingProfile';
import { CRMUser } from 'src/app/shared/models-ts/Actors/User/CRMUser';
import { Schedule } from 'src/app/shared/models-ts/Entities/Schedule';
import { AuthUserInfoService } from 'src/app/shared/services/auth-user-info/auth-user-info.service';
import { DoctorDataService } from 'src/app/shared/services/doctor-data/doctor-data.service';
import { DoctorsService } from 'src/app/shared/services/doctors/doctors.service';

@Component({
  selector: 'app-provider-availability',
  templateUrl: './provider-availability.component.html',
  styleUrls: ['./provider-availability.component.scss']
})
export class ProviderAvailabilityComponent implements OnInit {
  currentHeroInfo: CRMUser = new CRMUser();
  providerProfile: DoctorListingProfile = new DoctorListingProfile();
  dataLayerSubscription: Subscription;
  scheduleConfig = {
    // availableForVideoConsultation: {
    //   bool: true,
    //   display: 'Video Consult'
    // },
    // availableForAudioConsultation: {
    //   bool: true,
    //   display: 'Phone Consult'
    // },
    // availableForChatConsultation: {
    //   bool: true,
    //   display: 'Chat Consult'
    // },
    acceptsBookingOnDocVita: {
      bool: true,
      display: 'Accept Booking On Docvita'
    },
    // acceptsCallOnDocVita: {
    //   bool: true,
    //   display: 'Accept Call On Docvita'
    // }
  }

  scheduleGenericSlotLength: number;
  appointmentDurationLength: number;
  acceptAppointmentDiffLength: number;
  
  isSaving: boolean = false;


  constructor(
    private _authUserInfoService: AuthUserInfoService,
    private _doctorDataService: DoctorDataService,
    private _doctorService: DoctorsService,
    private _toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    this.currentHeroInfo = this._authUserInfoService.getUserInfo();
    this.dataLayerSubscription = this._doctorDataService.currentSelection.subscribe(
      (data) => {
        if (data != null && data['memberSelected'] != null) {
          this.providerProfile = data['memberSelected'];
          this.populateScheduleConfig();
        }
      }
    );
  }

  get appointmentDuration(){
    if (
      this.providerProfile.onlineConsultationConfig &&
      this.providerProfile.onlineConsultationConfig.appointmentDuration
    ) {
      return this.providerProfile.onlineConsultationConfig.appointmentDuration;
    } 
    return 60;
  }

  get slotDuration(){
    if (
      this.providerProfile.schedule &&
      this.providerProfile.schedule.length > 0 &&
      this.providerProfile.schedule[0].timeSlotLength
    ) {
      return this.providerProfile.schedule[0].timeSlotLength;
    } else {
      return 60;
    }
  }

  setSpecificConfig(key: string, event: boolean) {
    this.scheduleConfig[key]['bool'] = event;
  }

  markAllConfig(event: boolean) {
    console.log(event)
    const bool: boolean = event;
    for (let i in this.scheduleConfig) {
      this.scheduleConfig[i]['bool'] = bool;
    }
  }

  returnZero() {
    return 0;
  }

  populateScheduleConfig() {
    if (this.providerProfile.schedule && this.providerProfile.schedule.length > 0) {
      this.scheduleGenericSlotLength = +this.providerProfile.schedule[0].timeSlotLength;
      this.appointmentDurationLength = this.appointmentDuration;
      this.acceptAppointmentDiffLength = this.providerProfile.onlineConsultationConfig?.acceptAppointmentDiff;
      for (let sch of this.providerProfile.schedule) {
        if (sch && sch.days && sch.days.length > 0 && sch.startTime && sch.endTime && sch.acceptsBookingOnDocVita == true) 
          for (let c in this.scheduleConfig) {
            if (sch[c] != null) {
              this.scheduleConfig[c]['bool'] = sch[c]
            }
          }
      }
    }
  }


  async saveChangesForConfig() {
    if(!this.appointmentDurationLength || !this.acceptAppointmentDiffLength || !this.scheduleGenericSlotLength) {
      this._toastrService.error("Fill all the fields")
      return;
    }
    this.isSaving = true;
    let json: any = {
      onlineConsultationConfig: {
        appointmentDuration: this.appointmentDurationLength,
        acceptAppointmentDiff: this.acceptAppointmentDiffLength,
      }
    };
    await this._doctorService.updateBasicInformation(json, this.providerProfile.id);
    let defaultScheduleObj = new Schedule();
    defaultScheduleObj.days.push("Mon");
    defaultScheduleObj.endTime = "10:00";
    defaultScheduleObj.startTime = "09:00";
    let schedule = this.providerProfile.schedule?.length > 0? this.providerProfile.schedule: [
      defaultScheduleObj
    ];
    if(schedule) {
      let finalSchedules:any[] = [];
      [...schedule].forEach((val)=>{
        val.timeSlotLength = this.scheduleGenericSlotLength;
        val.acceptsBookingOnDocVita = this.scheduleConfig.acceptsBookingOnDocVita?.bool;
        finalSchedules.push(classToPlain(val));
      })
      await this._doctorService.updateScheduleInformation(finalSchedules, this.providerProfile.id);
    }
    this.isSaving = false;
    this._toastrService.success("Successfully updated config");

  }


}
