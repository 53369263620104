import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { listingUrl } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProviderModuleConfigsService {

  constructor(private _http: HttpClient) { }

  async enableChatModule(providerId:string,bool:boolean,updatedById:string,updatedByName:string){
    try {
      const body = {
        providerId:providerId,
        bool:bool,
        updatedById:updatedById,
        updatedByName:updatedByName
      }
      const url = listingUrl + "/v1/chat-monitoring/provider/change-chat-access"
      const enabled = await this._http.post(url,body).toPromise();
      if(enabled&&enabled['statusCode']&&enabled['body']&&enabled['body']['success']){
        return true;
      }else{
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  async getChatModuleConfig(providerId:string){
    try {
      const url = listingUrl + "/v1/chat-monitoring/provider/check-chat-access?providerId="+providerId;
      const enabled = await this._http.get(url).toPromise();
      if(
          enabled&&
          enabled['statusCode']&&
          enabled['body']&&
          enabled['body']['success']&&
          enabled['body']['data']&&
          enabled['body']['data']['enabled']
      ){
        return true;
      }else{
        return false;
      }
    } catch (error) {
      // console.log(error)
      return false;
    }
  }
}
