import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthUserInfoService } from '../auth-user-info/auth-user-info.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(private router:Router,private authUserService:AuthUserInfoService) { }
  canActivate(): boolean {
    if (!this.authUserService.isUserSignedIn()) {
      this.router.navigate(['auth']);
      return false;
    }
    return true;
  }
}
