import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class MediaUploadService {
  constructor(private _http: HttpClient) {}
  uploadFiles(finalFileJson: any) {
    return new Promise((resolve, reject) => {
      let url = environment['upload-media-to-chat'];
      this._http
        .post(url, finalFileJson)
        .toPromise()
        .then((res) => {
          if (
            res &&
            res['statusCode'] == 200 &&
            res['body']['success'] == true
          ) {
            resolve(res['body']);
          } else {
            reject(
              res['body']['message'] ? res['body']['message'] : 'server err'
            );
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
