<div class="d-flex align-items-center mt-3">
  <h2>Blocked Timeslots</h2>
  <button
    class="btn btn-primary btn-sm ms-auto me-3"
    (click)="addBlockSlot(slotModal)"
  >
    +Add
  </button>
</div>
<div class="w-100 d-flex mb-2">
  <div class="d-flex align-items-center ms-3">
    <h5>From:</h5>
    <input
      type="date"
      class="form-control ms-2"
      [(ngModel)]="fromDate"
      (ngModelChange)="applyFilter()"
    />
  </div>
  <div class="d-flex ms-4 align-items-center">
    <h5>To:</h5>
    <input
      type="date"
      class="form-control ms-2"
      [(ngModel)]="toDate"
      (ngModelChange)="applyFilter()"
    />
  </div>
  <button
    class="btn btn-primary btn-sm ms-3"
    style="height: 2rem"
    (click)="clearDate()"
  >
    clear
  </button>
</div>
<div class="container mt-1 p-2 mb-5">
  <div
    class="border rounded p-2 mt-2 w-100 d-flex align-items-center"
    *ngFor="let slot of applyFilter()"
  >
    <div>
      {{ slot.date | date: "dd-MM-yyyy" }} •
      <span style="font-weight: 500; color: #29b6f6"
        >{{ slot.stime }} - {{ slot.etime }}</span
      >
    </div>

    <div class="ms-auto d-flex align-items-center">
      <button
        class="btn btn-sm btn-primary"
        (click)="openModal(slotModal, slot, slot.id, true)"
      >
        Update
      </button>
      <button class="btn btn-sm btn-danger ms-2" (click)="delete(slot.id)">
        Delete
      </button>
    </div>
  </div>
  <div *ngIf="blockedSlots && blockedSlots.length == 0" class="p-2">
    No Slots 💤
  </div>
</div>

<ng-template #slotModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update Block Slot</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closeModal()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label for="date">Start Date:</label>
      <div class="d-flex align-items-center">
        <input
          id="date"
          class="form-control"
          type="date"
          [(ngModel)]="selectedSlot['date']"
        />
        <div class="d-flex ms-1">
          <button
            class="btn btn-outline-primary btn-sm border-0 ps-0 pe-0"
            (click)="appointmentDateScroller('-', 'date')"
          >
            <i class="feather icon-chevron-left" style="font-size: 16px"></i>
          </button>
          <button
            class="btn btn-outline-primary btn-sm border-0 ps-0 pe-0"
            (click)="appointmentDateScroller('+', 'date')"
          >
            <i class="feather icon-chevron-right" style="font-size: 16px"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="form-group" *ngIf="!updateMode">
      <label for="enddate"
        >End Date:
        <span
          class="text-muted"
          placement="top"
          tooltip="Create range of block slots."
          >• (optional)</span
        ></label
      >
      <div class="d-flex align-items-center">
        <input
          id="enddate"
          class="form-control"
          type="date"
          [(ngModel)]="selectedSlot['endDate']"
        />
        <div class="d-flex ms-1">
          <button
            class="btn btn-outline-primary btn-sm border-0 ps-0 pe-0"
            (click)="appointmentDateScroller('-', 'endDate')"
          >
            <i class="feather icon-chevron-left" style="font-size: 16px"></i>
          </button>
          <button
            class="btn btn-outline-primary btn-sm border-0 ps-0 pe-0"
            (click)="appointmentDateScroller('+', 'endDate')"
          >
            <i class="feather icon-chevron-right" style="font-size: 16px"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="date">From:</label>
      <input
        id="date"
        class="form-control"
        type="time"
        [(ngModel)]="selectedSlot['stime']"
      />
    </div>
    <div class="form-group">
      <label for="date">To:</label>
      <input
        id="date"
        class="form-control"
        type="time"
        [(ngModel)]="selectedSlot['etime']"
      />
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      [disabled]="loader"
      (click)="updateSlot()"
    >
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
        *ngIf="loader"
      ></span>
      Update
    </button>
  </div>
</ng-template>
