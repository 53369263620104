export enum TransactionStatus {
    CREATED="CREATED", COMPLETED = "COMPLETED"
}

export enum TransactionLogType { LINK_OPEN = "LINK_OPEN", PAYMENT_COMPLETED = "PAYMENT_COMPLETED" }
export enum TransactionType { ACCEPT_PAYMENT = "ACCEPT_PAYMENT" } 

export enum PaymentPurpose {
  Consultation = "Consultation",
  Treatment = "Treatment",
  LabTest = "LabTest",
  Medicines = "Medicines",
}

export enum TransactionSource {
  Razorpay="Razorpay",
  Cashfree="Cashfree",
  HDFCBANK="HDFCBANK",
  Paytm="Paytm",
  GPay="GPay",
  Wise="Wise",
  DocVitaWallet="DocVitaWallet",
  PartnerWallet="PartnerWallet",
  PlanPurchaseWallet="PlanPurchaseWallet",
  AppointmentWallet="AppointmentWallet", 
  DocVitaPromoCredit="DocVitaPromoCredit",
  PayPal="PayPal",
  Stripe="Stripe",
  DOCUBE="Docube",
}

export enum RefundSource {
  Razorpay="Razorpay",
  Cashfree="Cashfree",
  Cashgram="Cashgram",
  Stripe="Stripe",
  Paytm="Paytm",
  HDFCBANK="HDFCBANK",
  DocVitaWallet="DocVitaWallet"
}