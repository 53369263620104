import { Component, OnInit, ViewChildren } from '@angular/core';
//services
import { DoctorsService } from '../../shared/services/doctors/doctors.service';
import { DoctorListingProfile } from '../../shared/models-ts/Actors/Doctor/DoctorListingProfile';
import { plainToClass } from 'class-transformer';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault('Asia/Kolkata');
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DoctorDataService } from 'src/app/shared/services/doctor-data/doctor-data.service';
import {
  city,
  specializations,
  languages,
} from 'src/app/shared/constants/constants';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import firebase from 'firebase';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { AuthUserInfoService } from 'src/app/shared/services/auth-user-info/auth-user-info.service';
declare var $: any;

@Component({
  selector: 'app-doctor-education-update-listing',
  templateUrl: './doctor-education-update-listing.component.html',
  styleUrls: ['./doctor-education-update-listing.component.css'],
})
export class DoctorEducationUpdateListingComponent implements OnInit {
  title: string = 'Medical Registration & Education';
  tabState: string = 'education';
  listingProfiles: DoctorListingProfile[] = [];
  selectedDoctor: DoctorListingProfile;
  heroId: string = '';
  currentHeroData: any;
  currentHeroInfo: any;
  idInvalid: boolean = false;
  listingListener: any;
  defaultLogoUrl: string =
    'https://default-docvita-images.s3.ap-south-1.amazonaws.com/no-image-available.jpg';
  photoUrl: any;
  id: string;
  city = city;
  specialization = specializations;
  languages = languages;
  searchText: string = '';
  selectedCity: string = '';
  selectedSpecialization: string = '';
  listingProfilesCopy: DoctorListingProfile[] = [];
  isNewDoctor: boolean = false;
  newDoctorForm: DoctorListingProfile = new DoctorListingProfile();
  searchTermSubject: Subject<string> = new Subject<string>();
  searching: boolean = false;
  extension: any;
  logoUploaded: boolean = false;
  newRegPhotoUrl: any;
  page: number = 0;
  pageSize: number = 10;
  newFilter: any = {
    photoUrl: false,
    medicalRegistrationVerified: false,
    showInListing: false,
    publicContactNumber: false,
    limit: 100,
  };
  photoUrlExists: string;
  fetching: boolean = false;
  fragment: string;
  filterMetadata = { count: 0 };
  constructor(
    private _doctorService: DoctorsService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _doctorDataService: DoctorDataService,
    private _authUserInfoService: AuthUserInfoService,
    private _spinner: NgxSpinnerService,
    private _toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.getTabState();
    this._activatedRoute.fragment.subscribe((fragment) => {
      this.fragment = fragment;
      if (this.fragment == 'upload') {
        this.title = 'Medical Registration Upload';
      } else {
        this.title = 'Medical Registration Verification';
      }
    });
    if (this._activatedRoute.snapshot.firstChild) {
      this.id = this._activatedRoute.snapshot.firstChild.params['id'];
      this.getExistingFilters();
    } else {
      this.getExistingFilters();
    }
    this.currentHeroInfo = this._authUserInfoService.getUserInfo();
  }

  logoutCurrentUser() {
    this._authUserInfoService.signout();
  }

  openNewUserRegistration() {
    this.newRegPhotoUrl = null;
    this.logoUploaded = false;
    this.newDoctorForm = new DoctorListingProfile();
    let id = firebase.database().ref().push().key;
    this.newDoctorForm.id = id;
    this.newDoctorForm.type = 'doctor';
    this.newDoctorForm['createdOn'] = new Date().getTime();
    $('#registerUserModal').modal('show');
  }

  getExistingFilters() {
    this._activatedRoute.queryParams.subscribe((params) => {
      if (params['city']) {
        this.selectedCity = params['city'];
      }
      if (params['specialization']) {
        this.selectedSpecialization = params['specialization'];
      }
      if (params['photoUrl']) {
        this.newFilter['photoUrl'] =
          params['photoUrl'] == 'true'
            ? true
            : params['photoUrl'] == ''
            ? ''
            : false;
      }
      if (params['medicalRegistrationVerified']) {
        this.newFilter['medicalRegistrationVerified'] =
          params['medicalRegistrationVerified'] == 'true'
            ? true
            : params['medicalRegistrationVerified'] == ''
            ? ''
            : false;
      }
      if (params['showInListing']) {
        this.newFilter['showInListing'] =
          params['showInListing'] == 'true'
            ? true
            : params['showInListing'] == ''
            ? ''
            : false;
      }
      if (params['publicContactNumber']) {
        this.newFilter['publicContactNumber'] =
          params['publicContactNumber'] == 'true'
            ? true
            : params['publicContactNumber'] == ''
            ? ''
            : false;
      }
      if (params['limit']) {
        this.newFilter['limit'] = params['limit'];
      }
      this.getListingProfiles();
    });
  }

  updateNewDoctor() {
    if (
      this.newDoctorForm['name'] &&
      this.newDoctorForm['name'].length > 0 &&
      this.newDoctorForm['salutation'] &&
      this.newDoctorForm['city']
    ) {
      this.newDoctorForm['city'] = this.newDoctorForm['city']['label']
        ? this.newDoctorForm['city']['label']
        : this.newDoctorForm['city'];
      if (this.newDoctorForm['specializations']) {
        for (let i = 0; i < this.newDoctorForm['specializations'].length; i++) {
          this.newDoctorForm['specializations'][i] = this.newDoctorForm[
            'specializations'
          ][i]['label']
            ? this.newDoctorForm['specializations'][i]['label']
            : this.newDoctorForm['specializations'][i];
        }
      }
      if (this.newDoctorForm['languages']) {
        for (let i = 0; i < this.newDoctorForm['languages'].length; i++) {
          this.newDoctorForm['languages'][i] = this.newDoctorForm['languages'][
            i
          ]['label']
            ? this.newDoctorForm['languages'][i]['label']
            : this.newDoctorForm['languages'][i];
        }
      }
      if (
        this.newDoctorForm.primaryContactNumber &&
        this.newDoctorForm.primaryContactNumber.number
      ) {
        this.newDoctorForm.primaryContactNumber['countryCode'] = '91';
        this.newDoctorForm.primaryContactNumber.number =
          this.newDoctorForm.primaryContactNumber.number.toString();
      }
      if (
        this.newDoctorForm.publicContactNumber &&
        this.newDoctorForm.publicContactNumber.number
      ) {
        this.newDoctorForm.publicContactNumber['countryCode'] = '91';
        this.newDoctorForm.publicContactNumber.number =
          this.newDoctorForm.publicContactNumber.number.toString();
      }

      if (
        this.newDoctorForm.whatsappContactNumber &&
        this.newDoctorForm.whatsappContactNumber.number
      ) {
        this.newDoctorForm.whatsappContactNumber['countryCode'] = '91';
        this.newDoctorForm.whatsappContactNumber.number =
          this.newDoctorForm.whatsappContactNumber.number.toString();
      }

      if (this.newDoctorForm.experienceInYears) {
        this.newDoctorForm['experienceInYears'] =
          this.newDoctorForm.experienceInYears + '';
      }

      this.newDoctorForm['uniqueTag'] = this.newDoctorForm.id;
      this.newDoctorForm['createdOn'] = new Date().getTime();
      this.newDoctorForm['isProfileClaimed'] = false;
      this.newDoctorForm['isMedicalRegistrationVerified'] = false;
      this.newDoctorForm['showInListing'] = false;
      this.newDoctorForm['id'] = this.newDoctorForm.id;
      this.newDoctorForm['profileSource'] =
        'created new profile by docvita team';
      let id = this.newDoctorForm.id;
      let doctor = this.newDoctorForm;
      this._doctorService
        .updateBasicInformation(this.newDoctorForm, id)
        .then((res) => {
          if (this.logoUploaded) {
            this._doctorService
              .uploadPhoto(this.newRegPhotoUrl, id, this.extension)
              .subscribe((data) => {
                this.updateUserStats(
                  this.currentHeroInfo.docvitaId,
                  this.id,
                  'photo'
                );
                $('#registerUserModal').modal('hide');
                this.newRegPhotoUrl = null;
                this._toastr.success('New Doctor Added!');
                this._toastr.success('Photo uploaded!');
                this.id = id;
                this._doctorDataService.updateCurrentDoctor(doctor);
                this.selectedDoctor = doctor;

                if (
                  this.selectedDoctor != null &&
                  this.selectedDoctor.id != null
                ) {
                  this._router.navigate([
                    `profile-listing/${this.selectedDoctor.id}/basicInfo`,
                  ]);
                }
              });
          } else {
            this._toastr.success('New Doctor Added!');
            $('#registerUserModal').modal('hide');
            this.id = id;
            this._doctorDataService.updateCurrentDoctor(doctor);
            this.selectedDoctor = doctor;

            if (this.selectedDoctor != null && this.selectedDoctor.id != null) {
              this._router.navigate([
                `profile-listing/${this.selectedDoctor.id}/basicInfo`,
              ]);
            }
          }
          this.updateUserStats(
            this.currentHeroInfo.docvitaId,
            this.id,
            'create'
          );
        })
        .catch((err) => console.log(err));
    } else {
      this._toastr.warning('Incomplete parameters!');
    }
  }

  autoFillAbout() {
    if (
      this.newDoctorForm.salutation &&
      this.newDoctorForm.name &&
      this.newDoctorForm.experienceInYears
    ) {
      let about =
        this.newDoctorForm.salutation +
        ' ' +
        this.newDoctorForm.name +
        ' is a highly qualified and experienced doctor with an overall experience of ' +
        this.newDoctorForm.experienceInYears +
        ' years.';

      this.newDoctorForm.about = about;
    } else {
      this._toastr.error(
        'Salutation, Name and Exp. in years required, for auto to work.'
      );
    }
  }

  search() {
    this.searchTermSubject
      .pipe(debounceTime(750), distinctUntilChanged())
      .subscribe((term) => {
        //call the elastic api here and push the details to listingProfile
        console.log(term);
      });
  }

  getTabState() {
    let obj = {
      preview: 'preview-tab',
      basicInfo: 'info-tab',
      education: 'education-tab',
      practices: 'practices-tab',
      schedule: 'schedule-tab',
    };
    let url = this._router.url;
    let state = url.split('/')[3];
    if (state) {
      this.tabState = state;
      $(`#${obj[this.tabState]}`).tab('show');
    }
  }

  onSearchTextChange() {
    this.saveFilter();
  }

  clearFilters() {
    this.newFilter.photoUrl = '';
    this.newFilter.medicalRegistrationVerified = '';
    this.newFilter.limit = 100;
    this.newFilter.publicContactNumber = '';
    this.newFilter.showInListing = '';
    this.saveFilter();
  }

  saveFilter() {
    let filter = {
      selectedCity: this.selectedCity,
      selectedSpecialization: this.selectedSpecialization,
      advanceFilters: this.newFilter,
    };
    localStorage.setItem('filterValues', JSON.stringify(filter));
  }

  // newDoctor() {
  //   this.isNewDoctor = true;
  //   let doctor = new DoctorListingProfile();
  //   let id = firebase.database().ref().push().key;
  //   doctor.id = id;
  //   doctor.type = 'doctor';
  //   this.id = id;
  //   doctor['createdOn'] = new Date().getTime();
  //   this._doctorDataService.updateCurrentDoctor(doctor);
  //   this.selectedDoctor = doctor;
  //   $('#info-tab').tab('show');
  //   this.tabState = 'basicInfo';
  //   if (this.selectedDoctor != null && this.selectedDoctor.id != null) {
  //     this._router.navigate([
  //       `profile-listing/${this.selectedDoctor.id}/basicInfo`,
  //     ]);
  //   }
  // }

  getListingProfiles(e?: any) {
    let self = this;
    let filterValues;
    if (e) {
      filterValues = e;
      this.newFilter = e;
    }

    if (filterValues != null) {
      this.selectedCity = filterValues.selectedCity;
      this.selectedSpecialization = filterValues.selectedSpecialization;
      if (filterValues['advanceFilters']) {
        this.newFilter = filterValues['advanceFilters'];
      }
    }
    if (!this.searching) {
      this.fetching = true;
      this._doctorService
        .getProfileForListing(
          this.selectedSpecialization,
          this.selectedCity,
          this.newFilter.limit
        )
        .onSnapshot(
          function (querySnapshot) {
            self.fetching = false;
            self.isNewDoctor = false;
            self.getTabState();
            self.listingProfiles = [];
            self.listingProfilesCopy = [];
            if (self.listingProfiles) {
              self.listingProfiles = self.listingProfiles.splice(
                0,
                self.listingProfiles.length
              );
              self.listingProfilesCopy = self.listingProfiles.splice(
                0,
                self.listingProfiles.length
              );
            }

            querySnapshot.forEach(function (doc) {
              let data = plainToClass(DoctorListingProfile, doc.data(), {
                excludeExtraneousValues: true,
              });

              if (
                data.assignProfile.get('medicalRegistrationVerification') &&
                data.assignProfile.get('medicalRegistrationVerification')
                  .assignedToId === self.currentHeroInfo.docvitaId
              ) {
                self.listingProfiles.push(data);
              }
            });

            self.listingProfilesCopy = self.listingProfiles.slice();
            if (self.id) {
              self.listingProfiles.forEach((profile: DoctorListingProfile) => {
                if (profile.id == self.id) {
                  self.selectedDoctor = profile;
                  self._doctorDataService.updateCurrentDoctor(
                    self.selectedDoctor
                  );
                }
              });
            } else {
              let url = self._router.url;
              let state = url.split('/')[3];
              if (state) {
                self.selectedDoctor = self.listingProfiles[0];
              }
            }
          },
          (err) => {
            console.log(err);
          }
        );
    }
  }

  checkHeroIfExists() {
    if (this.heroId.length > 0) {
      this._doctorService
        .getHeroProfile(this.heroId)
        .then((doc) => {
          this.currentHeroData = doc.data();
          if (
            this.currentHeroData &&
            this.currentHeroData['healthHeroInfo'] &&
            this.currentHeroData['healthHeroInfo']['isEnrolled']
          ) {
            this.idInvalid = false;
          } else {
            this.idInvalid = true;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  getSpecialisation(specialisations) {
    if (specialisations != null && specialisations.length > 0) {
      let result = '';
      for (let i = 0; i < specialisations.length; i++) {
        let s = specialisations[i];
        if (s && s.length > 0) {
          s = s.charAt(0).toUpperCase() + s.slice(1);
          s = s.replace('-', ' ');
          result += s;
          if (i != specialisations.length - 1) {
            result += ', ';
          }
        }
      }
      return result;
    }
  }

  ifPhoto(doctor) {
    if (doctor['photoUrl']) {
      return true;
    } else {
      return false;
    }
  }

  formatCreateOn(timestamp) {
    return dayjs(timestamp).format('LLL');
  }

  onSelectedDoctorClicked(doctor: DoctorListingProfile) {
    this.isNewDoctor = false;
    this.selectedDoctor = doctor;
    this.id = this.selectedDoctor.id;
    if (this.selectedDoctor != null && this.selectedDoctor.id != null) {
      this._doctorDataService.updateCurrentDoctor(this.selectedDoctor);
    }
    this._router.navigate(
      [`data-entry/medical-registration/${this.selectedDoctor.id}`],
      { queryParamsHandling: 'preserve', preserveFragment: true }
    );
    let obj = {
      preview: 'preview-tab',
      basicInfo: 'info-tab',
      education: 'education-tab',
      practices: 'practices-tab',
      schedule: 'schedule-tab',
    };
    $(`#${obj[this.tabState]}`).tab('show');
  }

  routerToEducation() {
    if (this.selectedDoctor != null && this.selectedDoctor.id != null) {
      this._router.navigate(
        [`profile-listing/${this.selectedDoctor.id}/education`],
        { queryParamsHandling: 'preserve' }
      );
      this.tabState = 'education';
    }
  }

  fileProgress(fileInput: any) {
    let imageData;
    imageData = <File>fileInput.target.files[0];
    fileInput.target.value = null;
    this.extension = imageData['name'].match(/\.[0-9a-z]+$/i)[0];
    var mimeType = imageData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(imageData);
    reader.onload = (_event) => {
      this.newRegPhotoUrl = reader.result;
      this.logoUploaded = true;
    };
  }

  updateUserStats(heroId, docId, reqType) {
    this._doctorService
      .updateDocvitaUserStats(heroId, docId, reqType)
      .subscribe((data) => {
        if (data['statusCode'] == 200) {
          console.log('User stats updated!');
        } else {
          console.log('User stats did not update!');
        }
      });
  }

  backClicked() {
    this._router.navigate(['../'], { relativeTo: this._activatedRoute });
  }

  ngOnDestroy(): void {
    if (this.listingListener) {
      this.listingListener();
    }
  }
}
