<div class="d-flex w-100 mt-3">
  <h2>Manage Payments</h2>
  <button class="btn btn-primary ms-auto" (click)="createNewPayment()">
    + new payment
  </button>
</div>
<div class="container mt-2 p-2 w-100 d-flex justify-content-center">
  <div
    *ngIf="allTransactions && allTransactions.length > 0"
    class="d-flex flex-column w-100"
  >
    <div
      *ngFor="let t of allTransactions"
      class="card shadow-sm p-3 bg-white rounded m-2 d-flex flex-row"
    >
      <div>
        <div
          class="d-flex flex-row align-items-center"
          style="font-weight: 500; font-size: 15px"
        >
          <span class="me-3" style="color: rgb(20, 131, 5)"
            >₹ {{ t.amount }}</span
          >
          <span class="me-1"
            >CreatedBy: {{ t.createdByName }},
            {{ t.createdOn | date: "dd/MM/yy, hh:mm a" }}</span
          >
        </div>
        <div class="mb-2" style="font-weight: 500; font-size: 14px">
          <span class="me-1" style="color: gray">{{
            t.typeOfTransaction
          }}</span>
          •
          <span class="ms-1" style="color: rgb(21, 142, 255)">{{
            t.transactionStatus
          }}</span>
        </div>
        <div style="font-size: 13px">
          <span style="font-weight: 500">TransactionId: </span> {{ t.id }}
        </div>
        <div *ngIf="t.purposeItemsMap" style="font-size: 13px">
          <span style="font-weight: 500">PurposeTags: </span>
          <span *ngFor="let item of t.purposeItemsMap | keyvalue"
            >{{ item.value.type }},</span
          >
        </div>
        <div *ngIf="t.customerInfo" style="font-size: 13px">
          <div>
            <span style="font-weight: 500">Customer name:</span>
            {{ t.customerInfo.name }}
          </div>
          <div>
            <span style="font-weight: 500">Customer email:</span>
            {{ t.customerInfo.email }}
          </div>
          <div>
            <span style="font-weight: 500">Customer number:</span>
            {{ t.customerInfo.contactNumber.number }}
          </div>
        </div>
        <div class="d-flex flex-row" *ngIf="t.isSuccessful()">
          <div *ngIf="!t.isPayoutSettlementDone">
            <div class="d-flex flex-row mt-2">
              <div>
                <span
                  *ngIf="
                    t.getLatestPayout == null ||
                    t.getLatestPayout.status == null
                  "
                  class="me-2 p-1 badge bg-warning"
                  >Not Initiated</span
                >
              </div>
              <button
                *ngIf="
                  t.getLatestPayout == null || t.getLatestPayout.status == null
                "
                class="d-flex align-items-center btn btn-sm btn-outline-primary border-0"
                (click)="openIntiateTransferModal(ChangePayoutAmount, t)"
              >
                <i style="font-size: 15px" class="material-icons me-1"
                  >add_circle_outline</i
                >
                Transfer
              </button>
            </div>
            <div *ngIf="t.getLatestPayout">
              <!-- <span class="badge bg-success">Profile is claimed</span> -->
              <!-- Sucess / Error / Failed / Pending / Reversed -->
              <div class="d-flex align-items-center">
                <button
                  (click)="refreshTransferStatus()"
                  *ngIf="t.getLatestPayout.status == 'PENDING'"
                  class="btn btn-sm btn-outline-secondary me-2"
                >
                  <span
                    *ngIf="isInitiatedTransfer"
                    class="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Refresh Status
                </button>
                <span
                  *ngIf="t.getLatestPayout.status == 'PENDING'"
                  class="p-1 badge bg-info"
                >
                  Pending</span
                >
              </div>
              <span
                *ngIf="t.getLatestPayout.status == 'SUCCESS'"
                class="p-1 badge bg-success"
                >Success</span
              >
              <span
                *ngIf="t.getLatestPayout.status == 'REVERSED'"
                class="p-1 badge bg-secondary"
                >Reversed</span
              >
              <span
                *ngIf="t.getLatestPayout.status == 'FAILED'"
                class="p-1 badge bg-warning"
                >Failed</span
              >
              <span
                *ngIf="t.getLatestPayout.status == 'ERROR'"
                class="p-1 badge bg-danger"
                >Error</span
              >
              <span
                *ngIf="t.getLatestPayout.status == 'SUCCESS'"
                class="p-1"
                style="font-size: 13px"
                >Info: Settled ₹{{ t.getLatestPayout.settlementAmount }} on
                {{ t.getLatestPayout.updatedOn | date: "short" }}</span
              >
            </div>
          </div>
        </div>
      </div>

      <div class="ms-auto d-flex flex-column">
        <button
          type="button"
          class="btn btn-sm btn-outline-primary border-0 ms-auto"
          (click)="copyInfo(t.url)"
          [disabled]="t.url == null || t.url.length == 0"
        >
          Copy Payment url
        </button>
        <button
          type="button"
          class="btn btn-sm btn-outline-primary border-0 ms-auto"
          (click)="copyInfo(t.id)"
          [disabled]="t.id == null || t.id.length == 0"
        >
          Copy TransactionId
        </button>
        <button
          type="button"
          class="btn btn-sm btn-outline-primary border-0 ms-auto"
          (click)="copyInfo(t.customerInfo.contactNumber.number)"
          [disabled]="
            t.customerInfo == null ||
            t.customerInfo.contactNumber.number.length == 0
          "
        >
          Copy Customer contact
        </button>
      </div>
    </div>
  </div>
  <div class="spinner-border text-secondary" role="status" *ngIf="isLoading">
    <span class="sr-only">Loading...</span>
  </div>
  <h2 style="text-align: center" *ngIf="noPayments">💸 No payments yet.</h2>
</div>

<div
  class="modal fade"
  id="paymentsModal"
  tabindex="-1"
  aria-labelledby="paymentsModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="paymentsModalLabel">Payment Details</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="!showPaymentLink">
          <div class="form-group">
            <label for="nameInput"
              >Customer Name <span class="text-muted">• optional</span></label
            >
            <input
              type="text"
              class="form-control"
              id="nameInput"
              placeholder="Customer's Name"
              [(ngModel)]="currentTransaction.customerInfo.name"
            />
          </div>

          <div class="form-group">
            <label for="emailInput"
              >Customer Email <span class="text-muted">• optional</span></label
            >
            <input
              type="email"
              class="form-control"
              id="emailInput"
              placeholder="Customer's email"
              [(ngModel)]="currentTransaction.customerInfo.email"
            />
          </div>

          <div class="form-group">
            <label for="contactInput">Customer Contact Number</label>
            <div class="d-flex">
              <input
                type="text"
                class="form-control me-1"
                [value]="'+' + '91'"
                style="width: 3rem; padding: 0.5rem"
                disabled
              />
              <input
                type="number"
                class="form-control"
                placeholder="Contact Number"
                id="contactInput"
                [(ngModel)]="
                  currentTransaction.customerInfo.contactNumber.number
                "
              />
            </div>
          </div>

          <div class="form-group">
            <label for="purposeInput">Purpose Tags</label>
            <ng-select
              id="purposeInput"
              [items]="purposeTags"
              [multiple]="true"
              [closeOnSelect]="false"
              [hideSelected]="true"
              bindLabel="name"
              placeholder="Select Purpose Tags"
              [(ngModel)]="selectedPurposeTags"
            >
            </ng-select>
          </div>

          <div class="form-group">
            <label for="amountInput">Payable Amount</label>
            <input
              type="number"
              class="form-control"
              id="amountInput"
              placeholder="Amount (₹)"
              [(ngModel)]="currentTransaction.amount"
            />
          </div>

          <div class="form-group">
            <label for="descInput"
              >Description <span class="text-muted">• optional</span></label
            >
            <input
              type="text"
              class="form-control"
              id="descInput"
              placeholder="Description"
              [(ngModel)]="currentTransaction.description"
            />
          </div>
        </div>

        <div
          *ngIf="showPaymentLink"
          class="d-flex flex-column align-items-center"
        >
          <h3>💸 Payment link generated!</h3>
          <h5 class="mt-1">{{ displayPaymentLink }}</h5>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="makePaymentRequest()"
          [disabled]="modalLoader || showPaymentLink"
        >
          {{ modalLoader ? "" : "Create Payment" }}
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
            *ngIf="modalLoader"
          ></span>
          <span class="sr-only" *ngIf="modalLoader">Loading...</span>
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #ChangePayoutAmount let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Update Payout for {{ profileData.fullName() }}
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closeModal()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="dateOfBirth">New Amount</label>
        <input
          ngbAutofocus
          id="updatedPayoutAmountInput"
          class="form-control"
          type="number"
          name="dp"
          [(ngModel)]="formInputSettlementAmount"
        />
      </div>
    </form>
    <div *ngIf="currentTransaction.amount">
      <span style="font-weight: 500">Total Amount Received:</span>&nbsp;₹{{
        currentTransaction.amount
      }}
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="confirmIntiateTransferModal(InitiateTransferModal)"
    >
      Initiate Transfer
    </button>
  </div>
</ng-template>

<ng-template #InitiateTransferModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Initiate Payout of ₹{{ formInputSettlementAmount }} for
      {{ profileData.fullName() }}
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closeModal()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="initiateTransfer()"
      [disabled]="isInitiatedTransfer"
    >
      <span
        *ngIf="isInitiatedTransfer"
        class="spinner-border spinner-border-sm me-2"
        role="status"
        aria-hidden="true"
      ></span>
      Yes. Initiate transfer.
    </button>
  </div>
</ng-template>
