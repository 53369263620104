import { EventEmitter, Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  Subscription,
  timer,
} from 'rxjs';
import { ChatListItem } from 'src/app/shared/models-ts/Entities/chat/ChatListItem';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { plainToClass, classToPlain } from 'class-transformer';
// import { InitChatMessageDB } from '../db/init-chat-message';
import { ChatMessage } from 'src/app/shared/models-ts/Entities/chat/ChatMessage';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/shared/models-ts/Actors/User/User';
import { AuthUserInfoService } from 'src/app/shared/services/auth-user-info/auth-user-info.service';
import { CRMUser } from 'src/app/shared/models-ts/Actors/User/CRMUser';
import { map } from 'rxjs/operators';
import { docvitaSenderId } from 'src/app/shared/models-ts/constants/chat-constants';
@Injectable()
export class ChatMessagesService {
  private firebaseListeners: any[];
  private firestoreListener: any;
  // private chatMessageDB: InitChatMessageDB = new InitChatMessageDB();
  public messagesSub: BehaviorSubject<{
    messages: ChatMessage[];
    scrollDown: boolean;
  }> = new BehaviorSubject({ messages: [], scrollDown: false });
  private messages: ChatMessage[] = [];
  private currentChatListItemId: string = '';
  private batch: number = 17;
  currentHeroInfo: CRMUser = new CRMUser();
  snapToStart: any;
  listenerOfNewMessage: any;
  constructor(
    private _http: HttpClient,
    private _authUserInfoService: AuthUserInfoService
  ) {
    this.currentHeroInfo = this._authUserInfoService.getUserInfo();
  }

  topXMessagesListener() {
   
  }

  fetchSingleMessage(messageId: string) {
    return new Promise<ChatMessage>((resolve,reject)=>{
      let messageObj:ChatMessage;
      for(let m of this.messages) {
        if(m.messageId == messageId) {
          messageObj = m;
        }
      }
      if(messageObj) {
        resolve(messageObj);
      } else {
        firebase
        .firestore()
        .collection('chat_messages')
        .doc(messageId)
        .get()
        .then((doc)=>{
          if(doc.exists) {
            resolve(plainToClass(ChatMessage, doc.data()))
          } else {
            reject();
          }
        }).catch(err=>{
          console.error(err);
          reject(err);
        })
      }
    })
  }

  newMessageNotification() {
    this.destroyNewMessageListener();
    this.listenerOfNewMessage = firebase
      .firestore()
      .collection('chat_messages')
      .where('toId', '==', docvitaSenderId)
      .orderBy('createdOn', 'desc')
      .limit(1)
      .onSnapshot((snap) => {
        if (!snap.empty) {
          snap.docChanges().forEach((change) => {
            if (change.type === 'added') {
              // var audio = new Audio('../../../assets/audio/beep.mp3');
              // audio.volume = 0.3;
              // audio.play();
            }
          });
        }
      });
  }

  destroyNewMessageListener() {
    if (this.listenerOfNewMessage) {
      this.listenerOfNewMessage();
    }
  }

  activateChatMessagesFromFirestore(
    chatListItemId: string,
    messageId?: string,
    lastMessageCreatedOn?: number
  ) {
    if (messageId && messageId.length > 0) {
      firebase
        .firestore()
        .collection('chat_messages')
        .doc(messageId)
        .get()
        .then((snap) => {
          if (snap.exists) {
            // this.snapToStart = snap;
            this.activateChatMessagesFromFirestoreStartingFromSnap(
              chatListItemId,
              snap
            );
          } else {
            // this.snapToStart = null;
            this.activateChatMessagesFromFirestoreDefault(chatListItemId);
          }
        })
        .catch((err) => {
          // this.snapToStart = null;
          this.activateChatMessagesFromFirestoreDefault(chatListItemId);
        });
    } else {
      // this.snapToStart = null;
      this.activateChatMessagesFromFirestoreDefault(chatListItemId);
    }
  }
  activateChatMessagesFromFirestoreDefault(chatListItemId: string) {
    let ref = firebase
      .firestore()
      .collection('chat_messages')
      .where('chatListItemId', '==', chatListItemId)
      .orderBy('createdOn', 'desc')
      .limit(this.batch);

    this.firestoreListener = ref.onSnapshot(
      (snap) => {
        if (!snap.empty) {
          snap.forEach((doc) => {
            if (doc.exists) {
              let message = plainToClass(ChatMessage, doc.data());
              this.messagePush(message, false);
            }
          });
          this.updateMessageList();
        } else {
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }

  activateChatMessagesFromFirestoreStartingFromSnap(
    chatListItemId: string,
    snapshotToStart: any
  ) {
    let ref1 = firebase
      .firestore()
      .collection('chat_messages')
      .where('chatListItemId', '==', chatListItemId)
      .orderBy('createdOn', 'desc')
      .startAt(snapshotToStart)
      .limit(this.batch);
    let ref2 = firebase
      .firestore()
      .collection('chat_messages')
      .where('chatListItemId', '==', chatListItemId)
      .orderBy('createdOn', 'asc')
      .startAt(snapshotToStart)
      .limit(this.batch);

    combineLatest([ref1.get(), ref2.get()])
      .pipe(
        map((results) => {
          let _results: any[] = [];
          if (!results[0].empty) {
            results[0].forEach((da) => {
              _results.push(da);
            });
          }
          if (!results[1].empty) {
            results[1].forEach((da) => {
              _results.push(da);
            });
          }
          return _results;
        })
      )
      .subscribe((snap) => {
        console.log(snap);
        if (snap && snap.length > 0) {
          snap.forEach((doc) => {
            if (doc.exists) {
              let message = plainToClass(ChatMessage, doc.data());
              this.messagePush(message, false);
            }
          });
          this.updateMessageList();
        } else {
        }
      });
    // this.firestoreListener = ref.onSnapshot(
    //   (snap) => {
    //     if (!snap.empty) {
    //       snap.forEach((doc) => {
    //         if (doc.exists) {
    //           let message = plainToClass(ChatMessage, doc.data());
    //           this.messagePush(message, false);
    //         }
    //       });
    //       this.updateMessageList();
    //     } else {
    //     }
    //   },
    //   (err) => {
    //     console.error(err);
    //   }
    // );
  }

  previousBatchOfChatMessagesFromFirestore(
    chatListItemId: string,
    lastMessageCreatedOn: number
  ) {
    let ref = firebase
      .firestore()
      .collection('chat_messages')
      .where('chatListItemId', '==', chatListItemId)
      .where('createdOn', '<=', lastMessageCreatedOn)
      .orderBy('createdOn', 'desc')
      .limit(this.batch);
    console.log('Fetching prev batch', new Date().getTime());

    this.firestoreListener = ref.onSnapshot(
      (snap) => {
        if (!snap.empty) {
          snap.forEach((doc) => {
            if (doc.exists) {
              let message = plainToClass(ChatMessage, doc.data());
              this.messagePush(message, false);
            }
          });
          console.log('Fetched prev batch', new Date().getTime());
          this.updateMessageList();
        } else {
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }

  nextBatchOfChatMessagesFromFirestore(
    chatListItemId: string,
    lastMessageCreatedOn: number,
    scrollDownAfterLoading?:boolean
  ) {
    console.log('using ', lastMessageCreatedOn);
    let ref = firebase
      .firestore()
      .collection('chat_messages')
      .where('chatListItemId', '==', chatListItemId)
      .where('createdOn', '>=', lastMessageCreatedOn)
      .orderBy('createdOn', 'asc')
      .limit(this.batch);
    console.log('Fetching next batch', lastMessageCreatedOn);

    this.firestoreListener = ref.onSnapshot(
      (snap) => {
        if (!snap.empty) {
          snap.forEach((doc) => {
            if (doc.exists) {
              let message = plainToClass(ChatMessage, doc.data());
              this.messagePush(message, false);
            }
          });

          // console.log('Fetched next batch', new Date().getTime());
          this.updateMessageList(scrollDownAfterLoading);
        } else {
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }

  specificMessageMidwayFetchFromFirestrore() {}

  activateChatMessages(chatListItemId: string, messageId?: string) {
    this.resetFirebaseListeners();
    this.messagesSub.next({ messages: [], scrollDown: false });
    this.currentChatListItemId = chatListItemId;
    this.messages = [];
    // this.activateLocalDBCache(chatListItemId);
    this.activateChatMessagesFromFirestore(
      chatListItemId,
      messageId ? messageId : null
    );
    // let ref = firebase.database().ref("chat_ref_list").child(chatListItemId).orderByChild("createdOn").limitToLast(this.batch);
    // ref.on("child_added", async (snap)=>{
    //   if(snap.exists()) {
    //     this.chatMessageDB.putRef(snap.key, snap.val());
    //     let obj = await this.fetchFreshChatMessageIfUpdatedOnDoesNotMatch(snap.key,snap.val());
    //     if(obj) {
    //       this.messagePush(obj, false);
    //       this.updateMessageList();
    //     }
    //   }
    // })

    // ref.on("child_changed", async (snap)=>{
    //   if(snap.exists()) {
    //     this.chatMessageDB.putRef(snap.key, snap.val());
    //     let obj = await this.fetchFreshChatMessageIfUpdatedOnDoesNotMatch(snap.key,snap.val());
    //     if(obj) {
    //       this.messagePush(obj, false);
    //       this.updateMessageList();
    //     }
    //   }
    // })
    // this.firebaseListeners.push(ref);
  }

  resetFirebaseListeners() {
    if (this.firestoreListener) {
      this.firestoreListener();
    }
    if (this.firebaseListeners) {
      for (let f of this.firebaseListeners) {
        if (f) f.off();
      }
      this.firebaseListeners = [];
    } else {
      this.firebaseListeners = [];
    }
  }

  activateLocalDBCache(chatListItemId: string) {
    console.log('called message find', new Date().getTime());
    // this.chatMessageDB.db.createIndex({
    //   index: {
    //     fields: ['chatListItemId','updatedOn'],
    //     ddoc: "chat-messages-updated-on"
    //   }
    // })
    // this.chatMessageDB.db.find({
    //   selector: {
    //     "chatListItemId": {
    //       "$eq": chatListItemId
    //     }
    //   },
    //   sort: ["chatListItemId",{'updatedOn':"desc"}],
    //   limit: this.batch,
    //   use_index: "chat-messages-updated-on"
    // }).then((result) => {
    //   console.log("chat messages got results",result.docs.length,new Date().getTime());
    //   // handle result
    //   if(result.docs) {
    //     for(let r of result.docs) {
    //       let obj = plainToClass(ChatMessage,r);
    //       this.messagePush(obj, false)
    //     }
    //     this.updateMessageList();
    //     console.log("chat messages 1 tock",new Date().getTime());
    //   } else {
    //     // this.updateMessageList();
    //     console.log("chat messages 2 tock",new Date().getTime());
    //   }
    // }).catch((err) => {
    //   console.log(err);
    //   // this.chatListItemsUpdated.next([]);
    //   console.log("chat messages 3 tock",new Date().getTime());
    // });
  }

  async fetchFreshChatMessageIfUpdatedOnDoesNotMatch(
    messageId: string,
    messageRef: any
  ) {
    // try {
    //   let obj = await this.chatMessageDB.get(messageId);
    //   if(obj == null || obj.updatedOn != messageRef["updatedOn"]) {
    //     let ref = firebase.firestore().collection("chat_messages").doc(messageId).get();
    //     let doc = await ref;
    //     let chatMessage = plainToClass(ChatMessage, doc.data());
    //     chatMessage.messageId = doc.id;
    //     this.chatMessageDB.put(doc.id,doc.data());
    //     return chatMessage;
    //   } else {
    //     return obj;
    //   }
    // } catch(e) {
    //   console.error(e);
    //   return null;
    // }
  }

  updateMessageList(scrollDown?:boolean) {
    this.sortMessages();
    this.messagesSub.next({ messages: this.messages, scrollDown: scrollDown != null? scrollDown: true });
  }

  sortMessages() {
    this.messages = this.messages.sort((a, b) => {
      return b.createdOn - a.createdOn;
    });
  }

  messagePush(chatMessage: ChatMessage, forcePush?: boolean) {
    if (this.currentChatListItemId == chatMessage.chatListItemId) {
      if (forcePush) {
        this.messages.push(chatMessage);
      } else {
        let idx = this.messages.findIndex((a) => {
          return a.messageId == chatMessage.messageId;
        });
        if (idx > -1) {
          let val = this.messages[idx];
          if (val.updatedOn < chatMessage.updatedOn) {
            this.messages[idx] = chatMessage;
          }
        } else {
          this.messages.push(chatMessage);
        }
      }
    }
  }

  sendTextMessage(
    text: string,
    platformSenderId: string,
    sentById: string,
    sentByName: string
  ) {
    return new Promise((resolve, reject) => {
      let url = environment['send-wa-text-message'];
      this._http
        .post(url, {
          text: text,
          platformSenderId: platformSenderId,
          sentById: sentById,
          sentByName: sentByName,
        })
        .subscribe(
          (res: Response) => {
            if (res != null && res['statusCode'] == 200) {
              resolve({ success: true });
            } else if (res != null && res['statusCode'] == 811) {
              resolve({ success: false, errorCode: 811 });
            } else {
              resolve({ success: false });
            }
          },
          (err) => {
            console.error(err);
          }
        );
    });
  }
  sendHelloTemplateMessage(
    text: string,
    platformSenderId: string,
    sentById: string,
    sentByName: string
  ) {
    return new Promise((resolve, reject) => {
      let url = environment['send-wa-hello-template-message'];
      this._http
        .post(url, {
          text: text,
          platformSenderId: platformSenderId,
          sentById: sentById,
          sentByName: sentByName,
        })
        .subscribe(
          (res: Response) => {
            if (res != null && res['statusCode'] == 200) {
              resolve({ success: true });
            } else if (res != null && res['statusCode'] == 811) {
              resolve({ success: false, errorCode: 811 });
            } else {
              resolve({ success: false });
            }
          },
          (err) => {
            console.error(err);
          }
        );
    });
  }

  markChatAsRead(chatItemId: string, messageIdsToMarkRead?: string[]) {
    return new Promise((resolve, reject) => {
      let { docvitaId, name } = classToPlain(this.currentHeroInfo);
      let finalJson = {
        chatItemId: chatItemId,
        crmUser: { docvitaId, name },
        messageIds:
          messageIdsToMarkRead && messageIdsToMarkRead.length > 0
            ? messageIdsToMarkRead
            : [],
      };
      finalJson = JSON.parse(JSON.stringify(finalJson));
      let url = environment['chat-mark-as-read'];
      this._http
        .post(url, finalJson)
        .toPromise()
        .then((res) => {
          if (
            res &&
            res['statusCode'] == 200 &&
            res['body']['success'] == true
          ) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  markChatAsUnRead(chatItemId: string) {
    return new Promise((resolve, reject) => {
      let { docvitaId, name } = classToPlain(this.currentHeroInfo);
      let finalJson = {
        chatItemId: chatItemId,
        crmUser: { docvitaId, name },
      };
      finalJson = JSON.parse(JSON.stringify(finalJson));
      let url = environment['chat-mark-as-unread'];
      this._http
        .post(url, finalJson)
        .toPromise()
        .then((res) => {
          if (
            res &&
            res['statusCode'] == 200 &&
            res['body']['success'] == true
          ) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  reassignToBrain(platformSenderId: string) {
    return new Promise((resolve, reject) => {
      // let { docvitaId, name } = classToPlain(this.currentHeroInfo);
      if (platformSenderId) {
        let url =
          environment['assign-to-brain'] +
          '?platformSenderId=' +
          platformSenderId;
        this._http
          .get(url)
          .toPromise()
          .then((res) => {
            if (
              res &&
              res['statusCode'] == 200 &&
              res['body']['success'] == true
            ) {
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        reject('no sender id');
      }
    });
  }

  reassignToTeam(platformSenderId: string) {
    return new Promise((resolve, reject) => {
      // let { docvitaId, name } = classToPlain(this.currentHeroInfo);
      if (platformSenderId) {
        let url =
          environment['assign-to-team'] +
          '?platformSenderId=' +
          platformSenderId;
        this._http
          .get(url)
          .toPromise()
          .then((res) => {
            if (
              res &&
              res['statusCode'] == 200 &&
              res['body']['success'] == true
            ) {
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        reject('no sender id');
      }
    });
  }
}
