<div
  class="mb-1 message-text w-100 d-flex flex-column"
  [id]="message?.messageId"
  [ngClass]="{
    'align-items-start': isInbound,
    'align-items-end': !isInbound,
    'highlight-message': highLightId == message?.messageId
  }"
>
  <div
    class="message-container d-flex flex-column"
    [ngClass]="{
      'align-items-start': isInbound,
      'align-items-end': !isInbound
    }"
  >
    <div
      class="d-flex flex-column"
      [ngClass]="{
        'inbound-bubble': isInbound,
        'outbound-bubble': !isInbound
      }"
    >
    <ng-container *ngIf="message?.context?.parentId">
      <ng-container *ngTemplateOutlet="replyMessageTemplate"></ng-container>
    </ng-container>
      <div
        class="d-flex w-100"
        [ngClass]="{
          'align-items-center p-2':
            message.waMessageType &&
            message.waMessageType != 'text' &&
            getFileIcon(message.waMessageType)
        }"
      >
        <span
          class="me-2"
          *ngIf="
            message.waMessageType &&
            message.waMessageType != 'text' &&
            getFileIcon(message.waMessageType)
          "
        >
          <i
            class="feather media-icon text-primary"
            [ngClass]="{ 'text-white': isInbound }"
            [class]="getFileIcon(message.waMessageType)"
          ></i>
        </span>
        <span [innerHTML]="message.messageText"></span>
        <span *ngIf="message.fileUrl && !message.text">
          <a
            [href]="message.fileUrl"
            [class]="isInbound ? 'text-white' : 'link-primary'"
            target="_blank"
            [title]="message.fileUrl"
            >{{
              message.fileUrl && message.fileUrl.length > 40
                ? message.fileUrl.slice(0, 40) + ".."
                : message.fileUrl
            }}</a
          >
        </span>
      </div>

      <span id="footer" class="footer-text mt-1" *ngIf="message.footerText">{{
        message.footerText
      }}</span>
    </div>
    <div
      class="d-flex flex-column w-100"
      *ngIf="message.buttons.length > 0"
      [ngClass]="{
        'align-items-start': isInbound,
        'align-items-end': !isInbound
      }"
    >
      <span
        class="mt-1 w-100 text-center"
        [ngClass]="{
          'inbound-bubble': isInbound,
          'outbound-bubble': !isInbound
        }"
        *ngFor="let b of message.buttons"
        >{{ b }}</span
      >
    </div>
    <div
      *ngIf="!isInbound"
      class="sender-text-outbound"
      [tooltip]="popTemplate"
    >
      <ng-container *ngTemplateOutlet="status"></ng-container> Sent by:
      {{ message.outboundMessageSenderName }} ﹒
      {{ messageCellDisplayTime }}
    </div>
    <div *ngIf="isInbound" class="sender-text-inbound" [tooltip]="readBy">
      <ng-container *ngTemplateOutlet="status"></ng-container> From whatsapp ﹒
      {{ messageCellDisplayTime }}
    </div>
  </div>
</div>

<ng-template #replyMessageTemplate>
  <div class="p-2 rounded pointer" style="background-color: #2d26b4" (click)="goToMessageReply()">
    <span>{{replyMessagePreview}}</span>
  </div>
</ng-template>

<ng-template #popTemplate>{{ message.status }}</ng-template>

<ng-template #status>
  <ng-container *ngIf="message.status == chatMessageStatus.sent">
    <i class="la la-check" style="color: gray"></i>
  </ng-container>
  <ng-container *ngIf="message.status == chatMessageStatus.delivered">
    <i class="la la-check-double" style="color: gray"></i>
  </ng-container>
  <ng-container *ngIf="message.status == chatMessageStatus.seen">
    <i class="la la-check-double" style="color: green"></i>
  </ng-container>
  <ng-container *ngIf="message.status == chatMessageStatus.failed">
    <i class="la la-info-circle" style="color: red"></i>
  </ng-container>
</ng-template>
