import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringTrim',
})
export class StringTrimPipe implements PipeTransform {
  transform(value: string, trimAmount: number): string {
    if (value) {
      let _ =
        trimAmount && value.length > trimAmount
          ? value.slice(0, trimAmount) + '...'
          : value;
      return _;
    } else {
      return '';
    }
  }
}
