import { Expose, Transform } from "class-transformer";
import * as dayjs from "dayjs";
import * as timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);
import * as utc from "dayjs/plugin/utc";
dayjs.extend(utc);
import * as dayjsIsSameOrAfter from "dayjs/plugin/isSameOrAfter";
dayjs.extend(dayjsIsSameOrAfter);
import * as dayjsIsSameOrBefore from "dayjs/plugin/isSameOrBefore";
dayjs.extend(dayjsIsSameOrBefore);
dayjs.tz.setDefault("Asia/Kolkata");

export class Schedule {
	@Expose() days: string[] = [];
	@Expose() startTime: string;
	@Expose() endTime: string;
	@Expose() timeSlotLength: number;
	@Expose() availableForVideoConsultation: boolean = false;
	@Expose() availableForAudioConsultation: boolean = false;
	@Expose() availableForChatConsultation: boolean = false;
	@Expose() availableForInstantConnect: boolean = false;
	@Expose() acceptsCallOnDocVita: boolean = false;
	@Expose() acceptsBookingOnDocVita: boolean = false;

	@Expose()
	@Transform(
		(value) => {
			if (value) {
				let map = new Map<string, boolean>();
				for (let entry of Object.entries(value))
					map.set(entry[0], <boolean>entry[1]);
				return map;
			} else {
				return new Map<string, boolean>();
			}
		},
		{ toClassOnly: true }
	)
	availableAtPractices: Map<string, boolean>;

	public getSortingKey() {
		if (this.days.length > 0) {
			this.sortDays();
			let daysOrder = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
			return daysOrder.indexOf(this.days[0]);
		}
		return 0;
	}

	public sortDays() {
		this.days.sort((a, b) => {
			let daysOrder = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
			if (daysOrder.indexOf(a) == -1) {
				return 1;
			} else if (daysOrder.indexOf(b) == -1) {
				return -1;
			} else {
				return daysOrder.indexOf(a) - daysOrder.indexOf(b);
			}
		});
	}

	public isInstantBookingOn(): boolean {
		let bool = false;
		let currentTime = dayjs(new Date());
		let today = dayjs(new Date()).format("ddd");
		if (
			this.days != null &&
			this.availableForInstantConnect &&
			this.days.includes(today)
		) {
			let startTime = dayjs(
				dayjs(new Date()).format("YYYY-MM-DD") +
					" " +
					this.startTime
			);
			let endTime = dayjs(
				dayjs(new Date()).format("YYYY-MM-DD") +
					" " +
					this.endTime
			);
			if (
				startTime.isSameOrBefore(currentTime) &&
				endTime.isSameOrAfter(currentTime)
			) {
				bool = true;
			}
		}
		return bool;
	}

	public getDaysDisplayString() {
		let str = "";
		let daysOrder = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
		this.days.sort((a, b) => {
			if (daysOrder.indexOf(a) == -1) {
				return 1;
			} else if (daysOrder.indexOf(b) == -1) {
				return -1;
			} else {
				return daysOrder.indexOf(a) - daysOrder.indexOf(b);
			}
		});
		if (this.days.length == 1) {
			str = this.days[0];
		} else if (this.days.length > 1) {
			let startDay = "";
			let endDay = "";
			let prevDay = "";
			let count = 0;
			for (let d of this.days) {
				if (startDay.length == 0) {
					startDay = d;
					let tempStr = startDay;
					if (count == this.days.length - 1) {
						if (str.length == 0) {
							str += tempStr;
						} else {
							str += ", " + tempStr;
						}
					}
				} else if (
					daysOrder.indexOf(prevDay) > -1 &&
					this.days.indexOf(d) > -1
				) {
					if (daysOrder.indexOf(d) - daysOrder.indexOf(prevDay) == 1) {
						endDay = d;
						prevDay = d;
						if (count == this.days.length - 1) {
							let tempStr = startDay + "-" + endDay;
							if (str.length == 0) {
								str += tempStr;
							} else {
								str += ", " + tempStr;
							}
						}
					} else {
						let tempStr = "";
						if (startDay.length > 0 && endDay.length == 0) {
							tempStr = prevDay;
						} else if (startDay.length > 0 && endDay.length > 0) {
							tempStr = startDay + "-" + endDay;
							startDay = d;
							prevDay = d;
							endDay = "";
						}
						if (str.length == 0) {
							str += tempStr;
						} else {
							str += ", " + tempStr;
						}
						if (count == this.days.length - 1) {
							let tempStr = "";
							if (startDay.length > 0 && endDay.length == 0) {
								tempStr = d;
							} else if (startDay.length > 0 && endDay.length > 0) {
								tempStr = startDay + "-" + endDay;
							}
							if (tempStr.length > 0) {
								if (str.length == 0) {
									str += tempStr;
								} else {
									str += ", " + tempStr;
								}
							}
						}
					}
				}
				prevDay = d;
				count++;
			}
		}
		return str;
	}
}
