import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { listingUrl } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  getThreeDayScheduleCallingTimestamp: number;
  constructor(
    private _http:HttpClient
  ) { }

  async getThreeDaySchedule(dates:string[],providerId:string, practiceId?: string){
    try {
      const currentTimestamp = new Date().getTime();
      this.getThreeDayScheduleCallingTimestamp = currentTimestamp;
      if(!dates||dates?.length==0||!providerId){
        throw new Error("missing_params");
      }
      const url = listingUrl + '/v1/provider-slots/get-slots-for-schedule-management';
      const body = {
        dates,
        providerId,
        practiceId
      }
      const getSchedule = await this._http.post(url,body).toPromise()
      if(this.getThreeDayScheduleCallingTimestamp == currentTimestamp) { //Check if this was the latest API call for this function
        if(getSchedule && getSchedule['statusCode']==200 && getSchedule['body'] && getSchedule['body']['success'] && getSchedule['body']['data']){
          return getSchedule['body']['data'];
        }
        throw new Error("no_schedule");
      } else {
        throw new Error("not_the_latest_call");
      }
    } catch (error) {
      throw error;
    }
  }

  async blockTimeslot(data:{
    date: string,
    startTime: string,
    endTime: string,
    providerId: string
  }){
    try {
      const url = listingUrl + '/v1/provider-slots/block';
      const blockResponse = await this._http.post(url,data).toPromise()
      // console.log(blockResponse)
      if(blockResponse && blockResponse['statusCode']==200 && blockResponse['body'] && blockResponse['body']['success']){
        return true;
      }
      throw new Error("failed_to_block");
    } catch (error) {
      // handle error
      console.log(error);
      return null;
    }
  }

  async unblockTimeslot(data:{
    date: string,
    startTime: string,
    endTime: string,
    providerId: string
  }){
    try {
      const url = listingUrl + '/v1/provider-slots/unblock';
      const unblockResponse = await this._http.post(url,data).toPromise()
      if(unblockResponse && unblockResponse['statusCode']==200 && unblockResponse['body'] && unblockResponse['body']['success']){
        return true;
      }
      throw new Error("failed_to_unblock");
    } catch (error) {
      // handle error
      console.log(error);
      return null;
    }
  }

  async saveSchedule(scheduleArray, providerId:string){
    try {
      scheduleArray = JSON.parse(JSON.stringify(scheduleArray))
      const url = listingUrl + '/v1/provider-slots/save-schedule';
      const data = {
        scheduleArray,
        providerId
      }
      const response = await this._http.post(url,data).toPromise()
      // console.log(response)
      if(response && response['statusCode']==200 && response['body'] && response['body']['success']){
        return true;
      }
      throw new Error("failed_to_unblock");
    } catch (error) {
      return null;
    }
  }

  async blockAllSlots(date:string, providerId:string){
    try {
      const url = listingUrl + '/v1/provider-slots/block-all-slots';
      const data = {
        date,
        providerId
      }
      const response = await this._http.post(url,data).toPromise()
      // console.log(response)
      if(response && response['statusCode']==200 && response['body'] && response['body']['success']){
        return true;
      }
      throw new Error("failed_to_unblock");
    } catch (error) {
      return null;
    }
  }
  
}
