import { Expose } from "class-transformer";
import { TransactionSource } from "../Enums/pay";
import { LedgerItem } from "./LedgerItem";

export class ClientLedgerItem extends LedgerItem {
	@Expose() transactionDetails: string;
	@Expose() id: string;
	@Expose() clientId: string;
	@Expose() receiverClientId: string; //Optional - used for wallet to wallet transfer amongst family members / groups
	@Expose() senderClientId: string; //Optional - used for wallet to wallet transfer amongst family members / groups
	@Expose() source: TransactionSource;
	@Expose() transferId: string;
	@Expose() modeOfPayment: string; //Enum needed
	@Expose() modeOfPaymentDetails: any; //Enum needed
	@Expose() ledgerId: string;
	@Expose() leadId: string;
	@Expose() appointmentId: string;
}
