import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ServiceTypes } from '../../models-ts/Enums/Enums';
import { DoctorListingProfile } from '../../models-ts/Actors/Doctor/DoctorListingProfile';

interface IncomingParams {
  selectedMembershipPlan: string,
  provider: DoctorListingProfile,
  selection: ServiceTypes
}

@Component({
  selector: 'app-session-type-selector',
  templateUrl: './session-type-selector.component.html',
  styleUrls: ['./session-type-selector.component.scss']
})
export class SessionTypeSelectorComponent implements OnInit {
  _params: IncomingParams;
  @Input() set params(val: IncomingParams) {
    this._params = val;
    this.keepRelevantSessionTypeOptions();
    if(this.currentSessionType != val.selection) {
      this.currentSessionType = val.selection;
    }
  }

  get params() {
    return this._params;
  }

  sessionTypeOptions = Object.values(ServiceTypes);
  _currentSessionType: ServiceTypes = ServiceTypes.individual;
  set currentSessionType(val: ServiceTypes) {
    this._currentSessionType = val;
    this.selection.emit(val);
  }

  get currentSessionType() {
    return this._currentSessionType;
  }

  @Output() selection = new EventEmitter<ServiceTypes>();

  constructor() { }

  ngOnInit(): void {
  }

  keepRelevantSessionTypeOptions() {
    let arr: ServiceTypes[] = [];
    for (let s of this.sessionTypeOptions) {
      if (this.params.provider?.onlineConsultationConfig?.servicewiseFeesMap?.has(s)) arr.push(s);
    }
  }

}
