import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { DoctorListingProfile } from 'src/app/shared/models-ts/Actors/Doctor/DoctorListingProfile';
import {
  city,
  specializations,
  languages,
} from 'src/app/shared/constants/constants';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { DoctorsService } from '../../services/doctors/doctors.service';
import { plainToClass } from 'class-transformer';
import { DoctorDataService } from '../../services/doctor-data/doctor-data.service';
import { UntypedFormControl } from '@angular/forms';
import { accountManagersList, tagsList } from '../../constants/constants';

import { ToastrService } from 'ngx-toastr';
import { CopyClipboardService } from '../../services/copy-clipboard/copy-clipboard.service';
import { HttpClient } from '@angular/common/http';
import { listingUrl } from 'src/environments/environment';
@Component({
  selector: 'app-doc-master-list',
  templateUrl: './doc-master-list.component.html',
  styleUrls: ['./doc-master-list.component.css'],
})
export class DocMasterListComponent implements OnInit {
  id: string;
  searching: boolean = false;
  searchTermSubject: Subject<string> = new Subject<string>();
  searchFormControl = new UntypedFormControl();
  selectedSpecialization: string = '';
  defaultLogoUrl: string =
    'https://default-docvita-images.s3.ap-south-1.amazonaws.com/no-image-available.jpg';
  page: number = 0;
  pageSize: number = 30;
  newFilter: any = {
    photoUrl: '',
    showClaimReadyDoctorProfilesOnlyWithPendingInvitation: '',
    email: '',
    selectedCity: '',
    searchText: '',
    selectedSpecialization: '',
    medicalRegistrationVerified: '',
    medicalRegistrationUploaded: '',
    showInListing: '',
    isProfileClaimed: '',
    publicContactNumber: '',
    primaryContactNumber: '',
    isClaimProfileEmailInviteSent: '',
    isClaimProfileWAInviteSent: '',
    isFounderInviteViaEmailSent: '',
    isFounderInviteViaWhatsappSent: '',
    tags: [],
    accountManagers: [],
    limit: 200,
  };
  photoUrlExists: string;
  fetching: boolean = false;
  firebaseSubscription;
  listingProfiles: DoctorListingProfile[] = [];
  @Input() currentSelectedDoctor: DoctorListingProfile;
  @Output()
  selectedDoctor: EventEmitter<DoctorListingProfile> = new EventEmitter();
  @Output()
  currentFilters: EventEmitter<any> = new EventEmitter<any>();
  @Output() newDoctorClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() index: EventEmitter<number> = new EventEmitter<number>();

  tagsSuggestionList: string[] = tagsList;
  accountManagerSuggestionList: string[] = accountManagersList;

  @Output() isLoading: EventEmitter<boolean> = new EventEmitter<boolean>();

  //Search related variables
  mustArray = [];
  mustNotArray = [];

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _doctorService: DoctorsService,
    private _doctorDataService: DoctorDataService,
    private _toastr: ToastrService,
    private _http: HttpClient,
    private _copyToClipboardService: CopyClipboardService,
  ) {
  }

  ngOnInit(): void {
    this.fetchTheInitialProfileID();
  }

  fetchTheInitialProfileID() {
    if (
      this._activatedRoute != null &&
      this._activatedRoute.snapshot != null &&
      this._activatedRoute.snapshot.firstChild != null &&
      this._activatedRoute.snapshot.firstChild.params != null &&
      this._activatedRoute.snapshot.firstChild.params != null &&
      this._activatedRoute.snapshot.firstChild.params['id'] != null
    ) {
      this.id = this._activatedRoute.snapshot.firstChild.params['id'];
      if (this.id) this.firebaseListenerForCurrentSelectedProfile(this.id);
    } else if (
      this._activatedRoute != null &&
      this._activatedRoute.snapshot != null &&
      this._activatedRoute.snapshot.params != null &&
      this._activatedRoute.snapshot.params['id'] != null
    ) {
      this.id = this._activatedRoute.snapshot.params['id'];
      if (this.id) this.firebaseListenerForCurrentSelectedProfile(this.id);
    }
  }

  exportCSV() {
    let txt = '';
    for (let profile of this.listingProfiles) {
      let str = this.getCSVStringForProfile(profile);
      txt += str + '\n';
    }
    const blob = new Blob([txt], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    window.open(url, '_blank');
  }

  ifPhoto(doctor) {
    if (doctor['photoUrl']) {
      return true;
    } else {
      return false;
    }
  }

  getSpecialisation(specialisations) {
    if (specialisations != null && specialisations.length > 0) {
      let result = '';
      for (let i = 0; i < specialisations.length; i++) {
        let s = specialisations[i];
        if (s && s.length > 0) {
          s = s.charAt(0).toUpperCase() + s.slice(1);
          s = s.replace('-', ' ');
          result += s;
          if (i != specialisations.length - 1) {
            result += ', ';
          }
        }
      }
      return result;
    }
  }

  isCurrentSelectedProvider(provider: DoctorListingProfile) {
    return (
      this.id != null &&
      this.id.length > 0 &&
      provider != null &&
      this.id == provider.id
    );
  }

  onSelectedDoctorClicked(doctor: DoctorListingProfile, index?: number) {
    console.log('onSelectedDoctorClicked');
    this.index.emit(index);
    this.firebaseListenerForCurrentSelectedProfile(doctor.id);
    this.currentSelectedDoctor = doctor;
    this.id = doctor.id;
  }

  firebaseListenerForCurrentSelectedProfile(id: string) {
    if (id != null) {
      console.log('firebaseListenerForCurrentSelectedProfile: id check');
      if (this.firebaseSubscription != null) {
        console.log('firebaseListenerForCurrentSelectedProfile: unsub');
        this.firebaseSubscription();
      }
      this.isLoading.emit(true);
      console.log('firebaseListenerForCurrentSelectedProfile: isLoading');
      this.firebaseSubscription = this._doctorService
        .getProfileByIdLive(id)
        .onSnapshot(
          (snap) => {
            this.isLoading.emit(false);
            if (snap.exists) {
              this.currentSelectedDoctor = plainToClass(
                DoctorListingProfile,
                snap.data()
              );
              console.log(
                'firebaseListenerForCurrentSelectedProfile: emit selectedDoctor'
              );
              this.selectedDoctor.emit(this.currentSelectedDoctor);
              // this._doctorDataService.updateCurrentDoctor(this.currentSelectedDoctor);
            }
          },
          (err) => {
            console.log(
              'firebaseListenerForCurrentSelectedProfile: emit selectedDoctorisloading false'
            );
            console.error(err);
            this.isLoading.emit(false);
          }
        );
    }
  }

  filtersChange(e) {
    this.newFilter = e;
    // console.log(this.newFilter);
    this.populateDoctorList();
  }

  populateDoctorList() {
    // console.log('Elastic called');
    this.mustArray.splice(0, this.mustArray.length);
    this.mustNotArray.splice(0, this.mustNotArray.length);

    this.searchElasticQuery();
    this.doctorTypeCheckElasticQuery();
    this.cityElasticQuery();
    this.specializationElasticQuery();
    this.stringFilterForElasticQuery('photoUrl', 'photoUrl');
    this.stringFilterForElasticQuery('email', 'email');
    this.stringFilterForElasticQuery(
      'publicContactNumber',
      'publicContactNumber.number'
    );
    this.stringFilterForElasticQuery(
      'primaryContactNumber',
      'primaryContactNumber.number'
    );

    this.boolFliterForElasticQuery('showInListing', 'showInListing');
    this.boolFliterForElasticQuery('isProfileClaimed', 'isProfileClaimed');
    this.boolFliterForElasticQuery(
      'isClaimProfileEmailInviteSent',
      'outreachCommunication.inviteToClaimViaEmail.isSent'
    );
    this.boolsWithORFilterForElasticQuery('isClaimProfileWAInviteSent', [
      'outreachCommunication.inviteToClaimViaWhatsapp.isSent',
      'outreachCommunication.inviteLinkClaimViaWhatsapp.isSent',
    ]);
    this.boolFliterForElasticQuery(
      'isFounderInviteViaEmailSent',
      'outreachCommunication.founderInviteViaEmail.isSent'
    );
    this.boolFliterForElasticQuery(
      'isFounderInviteViaWhatsappSent',
      'outreachCommunication.founderInviteViaWhatsapp.isSent'
    );
    this.boolFliterForElasticQuery(
      'medicalRegistrationVerified',
      'isMedicalRegistrationVerified'
    );
    this.medicalRegistrationUploadedFilterExistsForElasticQuery(
      'medicalRegistrationUploaded',
      'medicalRegistrationProofUrlsCount'
    );
    this.stringArrayFilterElasticQuery('tags', this.newFilter.tags);
    this.stringArrayFilterElasticQuery(
      'accountManagers',
      this.newFilter.accountManagers
    );

    if (
      this.newFilter != null &&
      this.newFilter.showClaimReadyDoctorProfilesOnlyWithPendingInvitation ==
        true
    ) {
      this.newFilter.isProfileClaimed = 'false';
      let array = [];
      for (let elasticKey of [
        'outreachCommunication.inviteToClaimViaEmail.isSent',
        'outreachCommunication.inviteToClaimViaWhatsapp.isSent',
        'outreachCommunication.inviteLinkClaimViaWhatsapp.isSent',
        'outreachCommunication.founderInviteViaEmail.isSent',
        'outreachCommunication.founderInviteViaWhatsapp.isSent',
      ]) {
        let temp = {};
        temp['bool'] = {};
        temp['bool']['should'] = [];
        temp['bool']['minimum_should_match'] = 1;
        let matchFalseJSON = {};
        matchFalseJSON[elasticKey] = true;
        temp['bool']['should'].push({ match: matchFalseJSON });
        array.push(temp);
      }
      this.mustNotArray.push({
        bool: {
          should: array,
          minimum_should_match: 1,
        },
      });
      this.mustArray.push({
        bool: {
          should: [
            // {
            //   exists: {
            //     field: 'emails',
            //   },
            // },
            // {
            //   exists: {
            //     field: 'contactNumbers',
            //   },
            // },
            {
              exists: {
                field: 'primaryContactNumber.number',
              },
            },
            {
              exists: {
                field: 'publicContactNumber.number',
              },
            },
            {
              exists: {
                field: 'whatsappContactNumber.number',
              },
            },
          ],
          minimum_should_match: 1,
        },
      });
      this.mustArray.push({
        exists: {
          field: 'photoUrl',
        },
      });
      this.boolFliterForElasticQuery('isProfileClaimed', 'isProfileClaimed');
    }

    let queryBody: any = {
      query: {
        bool: {
          must: this.mustArray,
          must_not: this.mustNotArray,
        },
      },
      sort: [
        {
          'name.keyword': 'asc',
        }
        // ,
        // {
        //   createdOn: 'desc',
        // },
      ],
    };

    return new Promise((resolve, reject) => {
      this.fetchProviders(queryBody)
      // this._elasticsearchHelperService.client
      //   .search({
      //     index: 'listing_profiles',
      //     // type: '_doc',
      //     filter_path: ['hits.hits._source', 'hits.total', '_scroll_id'],
      //     body: queryBody,
      //     size: this.newFilter.limit,
      //   })
        .then((response: any[]) => {
          console.log('Total results: ', response);
          this.listingProfiles = [];
          if (response != null) {
            response.forEach((val) => {
              let json = val;
              if (json['listedIn']) {
                let arr = json['listedIn'];
                let tempListedIn = {};
                for (let a of arr) {
                  tempListedIn[a.urlPath] = a;
                }
                json['listedIn'] = tempListedIn;
              }
              let profile = plainToClass(DoctorListingProfile, json);
              this.listingProfiles.push(profile);
            });
            console.log('Total results list: ', this.listingProfiles.length);
          }
          this.selectcurrentProfileListingPage();
          resolve(this.listingProfiles);
        })
        .catch((err) => {
          console.error(err.toString());
          reject();
        });
    });
  }

  async fetchProviders(query: any) {
    let arr: any[] = [];
    const resp = await this._http.post(listingUrl+"/v1/profiles/list",{
      queryBody: query,
      limit: this.newFilter.limit
    }).toPromise();
    if(resp&&resp['statusCode']==200&&resp['body']&&resp['body']['data']){
      arr = resp["body"]["data"];
    }
    return arr;
  }

  searchElasticQuery() {
    if (this.newFilter['searchText'].length > 0) {
      this.mustArray.push({
        multi_match: {
          query: this.newFilter.searchText,
          type: 'phrase_prefix',
        },
      });
    }
  }

  cityElasticQuery() {
    if (
      this.newFilter.selectedCity != null &&
      this.newFilter.selectedCity.length > 0
    ) {
      this.mustArray.push({
        match: {
          city: this.newFilter.selectedCity,
        },
      });
    }
  }

  doctorTypeCheckElasticQuery() {
    this.mustArray.push({
      bool: {
        should: [
          {
            match: {
              type: 'doctor',
            },
          },
          {
            match: {
              type: 'specialist',
            },
          },
        ],
      },
    });
  }

  specializationElasticQuery() {
    if (
      this.newFilter.selectedSpecialization != null &&
      this.newFilter.selectedSpecialization.length > 0
    ) {
      this.mustArray.push({
        query_string: {
          default_field: 'specializations.keyword',
          query: this.newFilter.selectedSpecialization,
        },
      });
    }
  }

  stringArrayFilterElasticQuery(elasticKey: string, selectionValues: string[]) {
    if (selectionValues.length > 0) {
      let mustArr = [];
      for (let s of selectionValues) {
        mustArr.push({
          query_string: {
            default_field: elasticKey,
            query: s,
          },
        });
      }
      let jsonBool = {
        bool: {
          must: mustArr,
        },
      };
      this.mustArray.push(jsonBool);
    }
  }

  boolsWithORFilterForElasticQuery(filterKey: string, elasticKeys: string[]) {
    if (
      this.newFilter[filterKey] != null &&
      this.newFilter[filterKey].toString().length > 0
    ) {
      let val = this.newFilter[filterKey];
      if (val == true) {
        let shouldArray = [];
        for (let elasticKey of elasticKeys) {
          let temp = {};
          temp['bool'] = {
            must: [],
          };
          temp['bool']['must'].push({
            exists: {
              field: elasticKey,
            },
          });
          let matchJSON = {};
          matchJSON[elasticKey] = true;
          temp['bool']['must'].push({
            match: matchJSON,
          });
          shouldArray.push(temp);
        }
        this.mustArray.push({
          bool: {
            should: shouldArray,
            minimum_should_match: 1,
          },
        });
      } else if (val == false) {
        let array = [];
        for (let elasticKey of elasticKeys) {
          let temp = {};
          temp['bool'] = {};
          temp['bool']['should'] = [];
          temp['bool']['minimum_should_match'] = 1;
          let matchFalseJSON = {};
          matchFalseJSON[elasticKey] = true;
          temp['bool']['should'].push({ match: matchFalseJSON });
          array.push(temp);
        }
        this.mustNotArray.push({
          bool: {
            should: array,
            minimum_should_match: 1,
          },
        });
      }
    }
  }

  boolFliterForElasticQuery(filterKey: string, elasticKey: string) {
    if (
      this.newFilter[filterKey] != null &&
      this.newFilter[filterKey].toString().length > 0
    ) {
      let val = this.newFilter[filterKey];
      let matchJSON = {};
      matchJSON[elasticKey] = true;
      let matchFalseJSON = {};
      matchFalseJSON[elasticKey] = false;
      if (val == true) {
        this.mustArray.push({
          exists: {
            field: elasticKey,
          },
        });
        this.mustArray.push({
          match: matchJSON,
        });
        
      } else if (val == false) {
        this.mustNotArray.push({
          bool: {
            should: [
              {
                match: matchJSON,
              },
            ],
            minimum_should_match: 1,
          },
        });
      }
    }
  }
  medicalRegistrationUploadedFilterExistsForElasticQuery(
    filterKey: string,
    elasticKey: string
  ) {
    if (
      this.newFilter[filterKey] != null &&
      this.newFilter[filterKey].toString().length > 0
    ) {
      let val = this.newFilter[filterKey];
      let lengthGTZeroJSON = { range: {} };
      lengthGTZeroJSON['range'][elasticKey] = {
        gt: 0,
      };
      if (val == true) {
        this.mustArray.push({
          exists: {
            field: elasticKey,
          },
        });
        this.mustArray.push(lengthGTZeroJSON);
      } else if (val == false) {
        this.mustNotArray.push({
          bool: {
            should: [lengthGTZeroJSON],
            minimum_should_match: 1,
          },
        });
      }
    }
  }

  stringFilterForElasticQuery(filterKey: string, elasticKey: string) {
    if (
      this.newFilter[filterKey] != null &&
      this.newFilter[filterKey].toString().length > 0
    ) {
      let lengthGTZeroJSON = { range: {} };
      lengthGTZeroJSON['range'][elasticKey] = {
        gt: 0,
      };
      let val = this.newFilter[filterKey];
      if (val == true) {
        this.mustArray.push({
          exists: {
            field: elasticKey,
          },
        });
        this.mustArray.push(lengthGTZeroJSON);
        
      } else if (val == false) {
        this.mustNotArray.push({
          bool: {
            should: [lengthGTZeroJSON],
            minimum_should_match: 1,
          },
        });
      }
    }
  }

  selectcurrentProfileListingPage() {
    let idx = -1;
    if (
      this.listingProfiles.length > 0 &&
      this.id != null &&
      this.id.length > 0
    ) {
      for (let i = 0; i < this.listingProfiles.length; i++) {
        if (idx == -1 && this.listingProfiles[i].id == this.id) {
          idx = i;
          break;
        }
      }
      if (idx > -1) {
        this.page = Math.ceil(idx / this.pageSize);
        // console.log(idx, this.page,this.pageSize)
      } else {
        this.page = 1;
        // console.log(idx, this.page,this.pageSize)
      }
      // console.log(idx, this.page)
    }
  }

  searchGoogleFor(profileData: DoctorListingProfile) {
    if (profileData.name) {
      let _url = `https://www.google.com/search?q=${
        profileData.salutation
      }+${profileData.name
        .split(' ')
        .join('+')}+${profileData.specializations[0]
        .split(' ')
        .join('+')}+${profileData.city.split(' ').join('+')}`;
      window.open(_url, '_blank');
    }
  }

  getCSVStringForProfile(profileData: DoctorListingProfile): string {
    let str = '';
    str += profileData.id + ',';
    str += profileData.fullName() + ',';
    str += profileData.getMasterSpecializationDisplay() + ',';
    str += profileData.city + ',';
    // if (
    //   profileData.profileLinks != null &&
    //   profileData.profileLinks.practoProfileUrl != null
    // ) {
    //   str += profileData.profileLinks.practoProfileUrl;
    // }
    // else {
    //   str += ',';
    // }
    // if(profileData.languages) {
    //   profileData.languages.forEach((val)=>{
    //     str += (val + ',');
    //   })
    // }
    if (profileData.emails) {
      profileData.emails.forEach((val, key) => {
        str += key + ',';
      });
    }
    return str;
  }

  copyProfileForCSV(profileData: DoctorListingProfile) {
    // console.log('FOR CSV: ', profileData);
    let str = this.getCSVStringForProfile(profileData);
    // str += profileData.id + '\t';
    // str += profileData.fullName() + '\t';
    // str += profileData.getMasterSpecialization() + '\t';
    // str += profileData.city + '\t';
    // if (
    //   profileData.profileLinks != null &&
    //   profileData.profileLinks.practoProfileUrl != null
    // ) {
    //   str += profileData.profileLinks.practoProfileUrl + '\t';
    // } else {
    //   str += '\t';
    // }
    this._copyToClipboardService.copyToClipoard(str);
  }

  newDoctorClicked() {
    this.newDoctorClick.emit();
  }
}
