import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { CRMUser } from 'src/app/shared/models-ts/Actors/User/CRMUser';
import { Lead } from 'src/app/shared/models-ts/Entities/Lead';
import { environment } from '../../../environments/environment';
import { CancellationByType } from 'src/app/shared/models-ts/Enums/Enums';
@Injectable({
  providedIn: 'root',
})
export class PatientLeadsBackendService {
  leadCache: Map<string, Lead> = new Map();
  constructor(private _http: HttpClient) {}

  setInLeadCache(leadId: string, lead: Lead) {
    this.leadCache.set(leadId, lead);
  }

  getLeadFromCache(leadId: string): Lead {
    return this.leadCache.has(leadId) ? this.leadCache.get(leadId): null;
  }

  bookingStarted(from?:string, to?:string, nolimit?:boolean,filter?:string,limitResults?:number) {
    let ref = firebase
      .firestore()
      .collection('patient_booking_started')
      .orderBy('createdOn', 'desc');
    if (from && from != undefined && from.length > 9) {
      let fromDate = new Date(from + ' 00:00').getTime();
      ref = ref.where('createdOn', '>=', fromDate);
    }
    if (to && to != undefined && to.length > 9) {
      let toDate = new Date(to + ' 23:59').getTime();
      ref = ref.where('createdOn', '<=', toDate);
    }
    
    if (nolimit) {
      return ref;
    } else {
      if(limitResults!=null){
        return ref.limit(limitResults);
      }else{
        return ref.limit(200);
      }
      
    }
  }

  leadRef(leadId: string) {
    return firebase
    .firestore()
    .collection('patient_leads')
    .doc(leadId);
  }

  updateIncompleteBooking(id: string, json: any) {
    return new Promise<void>((resolve, reject) => {
      firebase
        .firestore()
        .collection('patient_booking_started')
        .doc(id)
        .update(json)
        .then(() => {
          resolve();
        })
        .catch((err) => {
          console.error(err);
          reject();
        });
    });
  }

  updateLead(id: string, json: any) {
    return new Promise<void>((resolve, reject) => {
      firebase
        .firestore()
        .collection('patient_leads')
        .doc(id)
        .update(json)
        .then(() => {
          resolve();
        })
        .catch((err) => {
          console.error(err);
          reject();
        });
    });
  }

  setMergeLead(id: string, json: any) {
    return new Promise<void>((resolve, reject) => {
      firebase
        .firestore()
        .collection('patient_leads')
        .doc(id)
        .set(json, { merge: true })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          console.error(err);
          reject();
        });
    });
  }

  updatePaymentSource(lead: Lead, source: string) {
    return new Promise<void>((resolve, reject) => {
      let url = environment['update-lead-payment-source'];
      let body: any = {
        leadId: lead.id,
        paymentSource: source,
      };
      this._http.post(url, body).subscribe((resp) => {
        if (
          resp &&
          resp['statusCode'] == 200 &&
          resp['body'] != null &&
          resp['body']['success']
        ) {
          resolve();
        } else {
          reject();
        }
      });
    });
  }

  updatePaymentId(lead: Lead, paymentId: string) {
    return new Promise<void>((resolve, reject) => {
      let url = environment['update-lead-payment-id'];
      let body: any = {
        leadId: lead.id,
        paymentId: paymentId,
      };
      this._http.post(url, body).subscribe((resp) => {
        if (
          resp &&
          resp['statusCode'] == 200 &&
          resp['body'] != null &&
          resp['body']['success']
        ) {
          resolve();
        } else {
          reject();
        }
      });
    });
  }
  updateAnyJSON(lead: Lead, jsonVal: any) {
    return new Promise<void>((resolve, reject) => {
      let url = environment['update-appointment-json'];
      let body: any = {
        leadId: lead.id,
        jsonVal: jsonVal,
      };
      this._http.post(url, body).subscribe((resp) => {
        if (
          resp &&
          resp['statusCode'] == 200 &&
          resp['body'] != null &&
          resp['body']['success']
        ) {
          resolve();
        } else {
          reject();
        }
      });
    });
  }

  saveContact(
    emailToStoreTheContact: string,
    leadId: string,
    name: string,
    countryCode: string,
    number: string,
    city?: string
  ) {
    return new Promise<void>((resolve, reject) => {
      let url = environment['save-contact-customer'];
      let body: any = {
        leadId: leadId,
        email: emailToStoreTheContact,
        name: name,
        countryCode: countryCode,
        number: number,
      };
      if (city) body['city'] = city;
      this._http.post(url, body).subscribe((resp) => {
        if (
          resp &&
          resp['statusCode'] == 200 &&
          resp['body'] != null &&
          resp['body']['success']
        ) {
          resolve();
        } else {
          reject();
        }
      });
    });
  }

  saveContactWithMultipleContacts(
    emailToStoreTheContact: string,
    leadId: string,
    name: string,
    contactNumbers: any[],
    city?: string
  ) {
    return new Promise<void>((resolve, reject) => {
      let url = environment['save-multiple-customer-contacts'];
      let body: any = {
        leadId: leadId,
        email: emailToStoreTheContact,
        name: name,
        contactNumbers: contactNumbers,
      };
      if (city) body['city'] = city;
      this._http.post(url, body).subscribe((resp) => {
        if (
          resp &&
          resp['statusCode'] == 200 &&
          resp['body'] != null &&
          resp['body']['success']
        ) {
          resolve();
        } else {
          reject();
        }
      });
    });
  }

  saveBookingContact(
    emailToStoreTheContact: string,
    appointmentId: string,
    name: string,
    countryCode: string,
    number: string
  ) {
    return new Promise<void>((resolve, reject) => {
      let url = environment['save-booking-contact-customer'];
      let body: any = {
        appointmentId: appointmentId,
        email: emailToStoreTheContact,
        name: name,
        countryCode: countryCode,
        number: number,
      };
      this._http.post(url, body).subscribe((resp) => {
        if (
          resp &&
          resp['statusCode'] == 200 &&
          resp['body'] != null &&
          resp['body']['success']
        ) {
          resolve();
        } else {
          reject();
        }
      });
    });
  }

  initiatePayout(leadId: string, settlementAmount: number) {
    return new Promise<any>((resolve, reject) => {
      let url = environment['lead-payout'];
      let body: any = {
        leadId: leadId,
        settlementAmount: settlementAmount,
      };
      this._http.post(url, body).subscribe((resp) => {
        resolve(resp);
      });
    });
  }
  refreshTransferStatusPayout(leadId: string, transferId: string) {
    return new Promise<any>((resolve, reject) => {
      let url = environment['lead-refresh-status-payout'];
      let body: any = {
        leadId: leadId,
        transferId: transferId,
      };
      this._http.post(url, body).subscribe((resp) => {
        resolve(resp);
      });
    });
  }
  initiatePaymentLink(transactionId: string, settlementAmount: number) {
    return new Promise<any>((resolve, reject) => {
      let url = environment['payment-link-payout'];
      let body: any = {
        transactionId: transactionId,
        settlementAmount: settlementAmount,
      };
      this._http.post(url, body).subscribe((resp) => {
        resolve(resp);
      });
    });
  }
  refreshTransferStatusPaymentLink(transactionId: string, transferId: string) {
    return new Promise<any>((resolve, reject) => {
      let url = environment['payment-link-refresh-status-payout'];
      let body: any = {
        transactionId: transactionId,
        transferId: transferId,
      };
      this._http.post(url, body).subscribe((resp) => {
        resolve(resp);
      });
    });
  }

  getPatientAppointment(organisationId, appointmentId) {
    let path = appointmentId + '.appointmentId';
    return firebase
      .firestore()
      .collection('organisation_data')
      .doc(organisationId)
      .collection('organisation_appointments')
      .where(path, '==', appointmentId);
  }

  appointmentCancellation(lead: Lead,crmHero?:CRMUser, cancellationByType? : CancellationByType) {
    return new Promise<void>((resolve, reject) => {
      let url = environment['cancel-appointment'];
      let body = {
        leadId: lead.id,
        consultationInfo: lead.consultationInfo,
        cancellationByType: cancellationByType
      };
      if(crmHero){
        body['cancelledByName'] = crmHero.name
        body['cancelledById'] = crmHero.docvitaId
      }
      this._http.post(url, body).subscribe((resp) => {
        if (
          resp &&
          resp['statusCode'] == 200 &&
          resp['body'] != null &&
          resp['body']['success']
        ) {
          resolve();
        } else {
          reject();
        }
      });
    });
  }

  appointmentRestoration(lead: Lead) {
    return new Promise<void>((resolve, reject) => {
      let url = environment['restore-appointment'];
      let body = {
        leadId: lead.id,
        consultationInfo: lead.consultationInfo,
      };
      this._http.post(url, body).subscribe((resp) => {
        if (
          resp &&
          resp['statusCode'] == 200 &&
          resp['body'] != null &&
          resp['body']['success']
        ) {
          resolve();
        } else {
          reject();
        }
      });
    });
  }

  addFollowupInfo(leadId: string, followupDate: string, followupTime?: string) {
    return new Promise<void>((resolve, reject) => {
      let url = environment['add-followup-info'];
      let body = {
        leadId: leadId,
        followupDate: followupDate,
      };
      if(followupTime) body["followupTime"] = followupTime;
      this._http.post(url, body).subscribe((resp) => {
        if (
          resp &&
          resp['statusCode'] == 200 &&
          resp['body'] != null &&
          resp['body']['success']
        ) {
          resolve();
        } else {
          reject();
        }
      });
    });
  }

  clearFollowupInfo(leadId: string) {
    return new Promise<void>((resolve, reject) => {
      let url = environment['clear-followup-info'];
      let body = {
        leadId: leadId,
      };
      this._http.post(url, body).subscribe((resp) => {
        if (
          resp &&
          resp['statusCode'] == 200 &&
          resp['body'] != null &&
          resp['body']['success']
        ) {
          resolve();
        } else {
          reject();
        }
      });
    });
  }

  async getPatientLeadById(id: string) {
    try {
      let lead = await firebase
        .firestore()
        .collection('patient_leads')
        .doc(id)
        .get();
      if (lead.exists) {
        // console.log('Fetched lead data:', lead.data());
        let _lead = plainToClass(Lead, lead.data());
        _lead.id = lead.id;
        return _lead;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  sendWhatsappRetargetMessageToClient(booking_started_id: string) {
    return new Promise<boolean>((resolve, reject) => {
      let url = environment['client-retarget-message'];
      let body = {
        booking_started_id
      };
      this._http.post(url, body).subscribe((resp) => {
        console.log(resp)
        if (
          resp &&
          resp['statusCode'] == 200 &&
          resp['body'] != null &&
          resp['body']['success']
        ) {
          resolve(true);
        } else {
          reject(false);
        }
      });
    });
  }

}
