import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault('Asia/Kolkata');
@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.css'],
})
export class DatePickerComponent implements OnInit {
  currentDate = new Date();
  fromDate: Date = new Date();
  toDate: Date = new Date();
  form = new UntypedFormGroup({
    dateYMD: new UntypedFormControl(new Date()),
    dateFull: new UntypedFormControl(new Date()),
    dateMDY: new UntypedFormControl(new Date()),
    dateRange: new UntypedFormControl([
      new Date(),
      new Date(this.currentDate.setDate(this.currentDate.getDate() + 7)),
    ]),
  });
  selectedDateSet: string = 'Select Date range';
  @Input()
  set fDate(date: string) {
    if (date) {
      this.fromDate = new Date(date);
      this.setRange();
    }
  }
  @Input()
  set tDate(date: string) {
    if (date) {
      this.toDate = new Date(date);
      this.setRange();
    }
  }
  @Output() dateRange: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  setRange() {
    this.form.setControl(
      'dateRange',
      new UntypedFormControl([this.fromDate, this.toDate])
    );
  }

  onDateRangeSelection(event) {
    this.fromDate = event[0];
    this.toDate = event[1];
    this.dateRange.emit({
      fromDate: dayjs(this.fromDate).format('YYYY-MM-DD'),
      toDate: dayjs(this.toDate).format('YYYY-MM-DD'),
    });
    // if (!this.fromDate && !this.toDate) {
    //   this.fromDate = date;
    // } else if (this.fromDate && !this.toDate) {
    //   this.toDate = date;
    // } else {
    //   this.toDate = null;
    //   this.fromDate = date;
    // }
    // this.dateExporter();
  }

  // isHovered(date: NgbDate) {
  //   return (
  //     this.fromDate &&
  //     !this.toDate &&
  //     this.hoveredDate &&
  //     date.after(this.fromDate) &&
  //     date.before(this.hoveredDate)
  //   );
  // }

  // isInside(date: NgbDate) {
  //   return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  // }

  // isRange(date: NgbDate) {
  //   return (
  //     date.equals(this.fromDate) ||
  //     (this.toDate && date.equals(this.toDate)) ||
  //     this.isInside(date) ||
  //     this.isHovered(date)
  //   );
  // }

  // validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
  //   const parsed = this.formatter.parse(input);
  //   return parsed && this.calendar.isValid(NgbDate.from(parsed))
  //     ? NgbDate.from(parsed)
  //     : currentValue;
  // }

  // setDates(option) {
  //   this.selectedDateSet = option;
  //   switch (option) {
  //     case 'Today': {
  //       this.fromDate = this.calendar.getToday();
  //       this.toDate = this.calendar.getToday();
  //       this.dateExporter();
  //       break;
  //     }
  //     case 'Yesterday': {
  //       this.fromDate = this.calendar.getNext(
  //         this.calendar.getToday(),
  //         'd',
  //         -1
  //       );
  //       this.toDate = this.calendar.getNext(this.calendar.getToday(), 'd', -1);
  //       this.dateExporter();
  //       break;
  //     }
  //     case 'Last 7 days': {
  //       this.fromDate = this.calendar.getNext(
  //         this.calendar.getToday(),
  //         'd',
  //         -7
  //       );
  //       this.toDate = this.calendar.getToday();
  //       this.dateExporter();
  //       break;
  //     }
  //     case 'Last 30 days': {
  //       this.fromDate = this.calendar.getNext(
  //         this.calendar.getToday(),
  //         'd',
  //         -30
  //       );
  //       this.toDate = this.calendar.getToday();
  //       this.dateExporter();
  //       break;
  //     }
  //     case 'This Month': {
  //       let _ = this.calendar.getToday();
  //       this.fromDate = new NgbDate(_.year, _.month, 1);
  //       this.toDate = this.calendar.getNext(this.fromDate, 'm', 1);
  //       this.toDate = this.calendar.getPrev(this.toDate, 'd', 1);
  //       this.dateExporter();
  //       break;
  //     }
  //     case 'Last Month': {
  //       let _ = this.calendar.getToday();
  //       if (_.month == 1) {
  //         this.fromDate = new NgbDate(_.year - 1, 12, 1);
  //         this.toDate = this.calendar.getNext(this.fromDate, 'm', 1);
  //       } else {
  //         this.fromDate = new NgbDate(_.year, _.month - 1, 1);
  //         this.toDate = this.calendar.getNext(this.fromDate, 'm', 1);
  //         this.toDate = this.calendar.getPrev(this.toDate, 'd', 1);
  //       }
  //       this.dateExporter();
  //       break;
  //     }
  //     default: {
  //       break;
  //     }
  //   }
  // }

  // dateExporter() {
  //   let f, t;
  //   if (this.fromDate) {
  //     f = this.formatter.format(this.fromDate);
  //   }
  //   if (this.toDate) {
  //     t = this.formatter.format(this.toDate);
  //   }
  //   let _ = {
  //     fromDate: f,
  //     toDate: t,
  //   };
  //   this.dateRange.emit(_);
  // }
}
