import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { classToPlain, plainToClass } from 'class-transformer';
import { ToastrService } from 'ngx-toastr';
import { DoctorListingProfile } from '../../models-ts/Actors/Doctor/DoctorListingProfile';
import { ScheduleHelper } from '../../models-ts/Actors/Doctor/ScheduleHelper';
import { Appointment } from '../../models-ts/Entities/Appointment';
import { Lead } from '../../models-ts/Entities/Lead';
import { AppointmentService } from '../../services/appointment/appointment.service';
import { DoctorsService } from '../../services/doctors/doctors.service';
import * as moment from 'moment';
import {
  ClientCommunication,
  ProviderCommunication,
} from 'src/app/patient-leads/components/next-steps/next-steps.component';
import { CopyClipboardService } from '../../services/copy-clipboard/copy-clipboard.service';
import {
  CheckListEntity,
  CheckListActionKey,
  UserType,
  CheckListSection,
} from 'src/app/patient-leads/components/next-steps/next-steps.models';
import { User } from '../../models-ts/Actors/User/User';
import { PatientLeadsBackendService } from 'src/app/patient-leads/services/patient-leads-backend.service';
import { PatientService } from '../../services/patient/patient.service';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault('Asia/Kolkata');
import { CRMUser } from '../../models-ts/Actors/User/CRMUser';
import { CountrywiseFeeCalc } from '../../models-ts/Helpers/countrywiseFeeCalc';
import { ReschedulingRequestType } from '../../models-ts/Enums/Enums';

@Component({
  selector: 'app-reschedule-appointment',
  templateUrl: './reschedule-appointment.component.html',
  styleUrls: ['./reschedule-appointment.component.css'],
})
export class RescheduleAppointmentComponent implements OnInit {
  @Input() leadDetails: Lead = new Lead();
  @Input() currentHeroInfo: CRMUser = new CRMUser();
  @Input() patientProfile: User = new User();
  @Input() showButton: boolean = true;
  @Output() resetSelectedLead: EventEmitter<any> = new EventEmitter<any>();

  sendWhatsAppConfirmations: boolean = true;
  doctorProfile: DoctorListingProfile = new DoctorListingProfile();
  oldAppointment: Appointment = new Appointment();
  currentAppointment: Appointment = new Appointment();
  doctorProfiles: DoctorListingProfile[];
  appointmentsList: Appointment[];
  providerCommunication: ProviderCommunication = new ProviderCommunication();
  clientCommunication: ClientCommunication = new ClientCommunication();
  noAppointments: boolean = false;
  noSlots: boolean = false;
  currentSlots: any[] = [];
  selectedSlot: any;
  selectedDate: string;
  selectedTime: string;
  selectedSlotLength: string;
  selectedPurpose: string;
  selectedAmount: number;
  rescheduleRequestType: ReschedulingRequestType;
  reschedulingTypes: {
    title: string,
    value: ReschedulingRequestType
  }[] = [
      {
        title: "Provider Requested to Reschedule",
        value: ReschedulingRequestType.provider_to_client
      },
      {
        title: "Client Requested to Reschedule",
        value: ReschedulingRequestType.client_to_provider
      },
      {
        title: "DocVita Team Requested to Reschedule",
        value: ReschedulingRequestType.docvita_team
      }
    ]

  slotLoader: boolean = false;
  apptLoader: boolean = false;
  updateLoader: boolean = false;

  gotoApptStep: boolean = false;
  gotoApptUrl: string = '';
  showGotoButton: boolean = true;

  isLoadingNewLeads: boolean = false;
  afterReschedulingSection: CheckListSection;

  openLinkInNewWindow: boolean = false;

  constructor(
    private modalService: BsModalService,
    private _toastrService: ToastrService,
    private _doctorService: DoctorsService,
    private _patientLeadsBackend: PatientLeadsBackendService,
    private _patientService: PatientService,
    private _appointmentService: AppointmentService
  ) { }

  ngOnInit(): void { }

  @ViewChild('resappointment') rescheduleModal;
  openModal() {
    this.modalService.show(this.rescheduleModal, {
      class: 'modal-lg',
      backdrop: 'static',
    });
    this.selectedSlot = null;
    this.selectedTime = '';
    this.selectedSlotLength = '';
    this.selectedAmount = null;
    this.rescheduleRequestType = null;
    this.appointmentsList = [];
    this.currentSlots = [];
    this.gotoApptStep = false;
    this.gotoApptUrl = '';
    this.showGotoButton = true;
    this.isLoadingNewLeads = false;
    this.openLinkInNewWindow = false;
    this.doctorProfile = new DoctorListingProfile();
    if (this.leadDetails) {
      this.doctorProfile.id = this.leadDetails.docvitaId;
      this.currentAppointment = plainToClass(
        Appointment,
        classToPlain(this.leadDetails.consultationInfo)
      );
      this.selectedDate = this.currentAppointment.date;
      this.selectedTime = this.currentAppointment.timeslot.split('-')[0];
      this.selectedSlotLength = this.getTimeslotLength(
        this.currentAppointment.timeslot
      );
    }
  }

  setProvider(event) {
    if (event) {
      console.log('event', event);
      this.doctorProfile = event;
      if (
        this.currentAppointment.doctorId != this.doctorProfile.id &&
        this.doctorProfile.appointmentDuration != null &&
        this.doctorProfile.appointmentDuration > 0
      ) {
        this.selectedSlotLength = '' + this.doctorProfile.appointmentDuration;
      } else {
        this.selectedSlotLength = '' + this.currentAppointment.getDuration();
      }
    }
  }

  mapBlockedSlots(): any[] {
    if (this.doctorProfile?.blockedSlots) {
      // let today = new Date().toISOString().split('T')[0]
      let date = this.selectedDate;
      let _ = Object.values(this.doctorProfile.blockedSlots).filter(
        (da) => da && da['date'] == date
      );
      _.sort((a, b) =>
        a['timeslot'].split('-')[0].localeCompare(b['timeslot'].split('-')[0])
      );
      return _;
    } else {
      return [];
    }
  }

  getTimeslotLength(timeslot) {
    let a = moment(timeslot.split('-')[0], 'HH:mm');
    let b = moment(timeslot.split('-')[1], 'HH:mm');
    let _ = moment.duration(a.diff(b));
    let minutes = Math.abs(_.asMinutes());
    return minutes.toString();
  }

  selectSlot(slot) {
    this.selectedSlot = slot;
    this.selectedTime = this.selectedSlot['startTime'];
  }

  clinicMapper(practices) {
    console.log(practices);
    let _ = [];
    let pracs = Object.keys(practices);
    // pracs.forEach(id=>{
    //   _.push(this.doctorProfile.associatedPractices.get(id).name)
    // })
    return _.toString();
  }
  @ViewChild('apptClashModal') apptClashModal;
  rescheduleAppointment() {
    // console.log(this.doctorProfile);
    if (
      this.doctorProfile &&
      this.doctorProfile.id &&
      this.selectedSlotLength &&
      this.selectedTime &&
      this.selectedDate &&
      this.currentHeroInfo &&
      this.currentHeroInfo.docvitaId &&
      this.currentHeroInfo.name &&
      this.currentHeroInfo.name.length > 0 &&
      this.rescheduleRequestType
    ) {
      let _timeslot =
        this.selectedTime +
        '-' +
        dayjs(
          new Date(this.selectedDate + ' ' + this.selectedTime)
        )
          .add(+this.selectedSlotLength, 'minutes')
          .format('HH:mm');
      // this.currentAppointment.timeslot = _timeslot;
      // this.currentAppointment.date = this.selectedDate;
      // this.currentAppointment.doctorId = this.doctorProfile.id;
      // this.currentAppointment.organisationId = this.doctorProfile.id;
      let body = {};

      body['leadId'] = this.leadDetails.id;
      body['rescheduleRequestType'] = this.rescheduleRequestType;

      body['heroInfo'] = classToPlain(this.currentHeroInfo);

      body['selectedDate'] = this.selectedDate;
      body['selectedTimeslot'] = _timeslot;
      body['isChangingDateTime'] = true;

      body['doNotSendComms'] = !this.sendWhatsAppConfirmations;

      this.apptClashModal
        .openConflictPromise(
          this.doctorProfile.id,
          this.selectedDate,
          _timeslot,
          this.doctorProfile.fullName(),
          this.leadDetails.id
        )
        .then((hasConflicts) => {
          if (hasConflicts) {
            let leadsWithSameAppt = hasConflicts['leads'].filter(
              (da) =>
                da &&
                da.appointmentId == this.currentAppointment.appointmentId
            );
            if (
              leadsWithSameAppt &&
              leadsWithSameAppt.length > 0 &&
              hasConflicts['leads'].length == 1
            ) {
              this.continueWithReschduleFlow(body);
            } else {
              this.updateLoader = false;
              this.apptClashModal.openModal();
            }
          } else {
            this.continueWithReschduleFlow(body);
          }
        })
        .catch((err) => {
          this.continueWithReschduleFlow(body);
        });
    } else {
      this._toastrService.warning('Incomplete info! or Slots not available.');
    }
  }

  continueWithReschduleFlow(body) {
    if (window.confirm('Are you sure you want to reschedule?')) {
      this.startReschedulingFlow(body);
    }
  }

  startReschedulingFlow(body: any) {
    this.updateLoader = true;
    this._appointmentService
      .rescheduleAppointment(body)
      .then((res) => {
        this.updateLoader = false;
        this.postRescheduleController(body);
      })
      .catch((err) => {
        console.log(err);
        this.updateLoader = false;
        this._toastrService.error('⚠ Rescheduling failed!');
      });
  }

  startReassignmentFlow(body: any) {
    this.updateLoader = true;
    this._appointmentService
      .reassignAppointment(body)
      .then((success) => {
        if (success) {
          this.postRescheduleController(body);
        } else {
          this._toastrService.error('⚠ Rescheduling failed!');
        }
        this.updateLoader = false;
      })
      .catch((err) => {
        console.log(err);
        this.updateLoader = false;
        this._toastrService.error('⚠ Rescheduling failed!');
      });
  }

  closeModal() {
    this.modalService.hide();
  }

  setSlotLength(num) {
    this.selectedSlotLength = num;
  }

  displayCurrentDay() {
    return new Date(this.selectedDate).getTime();
  }

  get displayCurrentApptDateTime() {
    if (
      this.leadDetails &&
      this.leadDetails.consultationInfo &&
      this.leadDetails.consultationInfo.date &&
      this.leadDetails.consultationInfo.timeslot
    ) {
      return (
        this.leadDetails.consultationInfo.date +
        ', ' +
        this.leadDetails.consultationInfo.timeslot
      );
    } else {
      return '-';
    }
  }

  timeToMin(hm: any) {
    let a = hm.split(':');
    let minutes = +a[0] * 60 + +a[1];
    return minutes;
  }

  minToTime(mins: any) {
    let m = mins % 60;
    let h = ((mins - m) / 60).toString();
    var HHMM =
      (h.length > 1 ? h : '0' + h) + ':' + (m < 10 ? '0' : '') + m.toString();
    return HHMM;
  }

  postRescheduleController(rescheduleBody) {
    let body = rescheduleBody;
    this.gotoApptUrl =
      location.origin +
      '/patient-bookings/' +
      this.leadDetails.id +
      '?from=' +
      body['selectedDate'] +
      '&to=' +
      body['selectedDate'] +
      '&sortBy=bookingDate';
    this.openLinkInNewWindow = false;
    if (
      this.leadDetails?.consultationInfo?.date != body['selectedDate']
    ) {
      this.resetSelectedLead.emit();
      this.openLinkInNewWindow = true;
    }
    this.showGotoButton = true;
    this.gotoApptStep = true;
    this.sendWhatsAppConfirmations = true;
    this.setupSuccessPage(body);
    this._toastrService.success('🎉Appointment rescheduled!');
  }

  //for when appointment has a date change and list is sorted by date
  gotoRescheduledAppointment() {
    if (this.modalService.getModalsCount() > 0) {
      this.modalService.hide();
    }
    if (this.gotoApptUrl) {
      if (this.openLinkInNewWindow) {
        window.open(this.gotoApptUrl, '_blank');
      } else {
        window.open(this.gotoApptUrl, '_self');
      }
    }
  }

  // copyProviderRescheduleMessage() {
  //   let lead = new Lead();
  //   lead.patientName = this.leadDetails.patientName;
  //   lead.consultationInfo = this.rescheduleBody['newConsultationInfo'];
  //   let message = this.doctorProfileComms.getInformProviderOfReschedulingMessage(
  //     this.doctorProfile,
  //     lead
  //   );
  //   this._clipboardService.copyToClipoard(message);
  // }
  // copyThankYouOnRescheduleMessage() {
  //   let lead = new Lead();
  //   lead.patientName = this.leadDetails.patientName;
  //   lead.consultationInfo = this.rescheduleBody['newConsultationInfo'];
  //   let message = this.clientComms.getThankYouForReschedulingMessage(
  //     this.doctorProfile,
  //     lead
  //   );
  //   this._clipboardService.copyToClipoard(message);
  // }
  async setupSuccessPage(body) {
    try {
      this.isLoadingNewLeads = true;
      let lead = await this._patientLeadsBackend.getPatientLeadById(
        body['leadId']
      );
      if (lead) {
        this.leadDetails = lead;
      }
      let patient = await this._patientService.getPatientById(
        this.leadDetails?.consultationInfo?.patientId
      );
      if (patient && patient.exists) {
        this.patientProfile = plainToClass(User, patient.data());
      }
      // console.log(this.leadDetails);
      this.initAfterrReschedulingSection();
      this.isLoadingNewLeads = false;
    } catch (error) {
      this._toastrService.error(
        'Something went wrong! Please continue further changes after closing the modal.'
      );
    }
  }

  initAfterrReschedulingSection() {
    let entities: CheckListEntity[] = [];
    let informProviderOfReschedulingMessage =
      this.providerCommunication.getInformProviderOfReschedulingMessage(
        this.doctorProfile,
        this.leadDetails
      );
    let informProviderOfRescheduling = new CheckListEntity(
      CheckListActionKey.InformProviderOfRescheduling,
      'Provider - Inform about rescheduled appointment',
      informProviderOfReschedulingMessage,
      UserType.provider,
      true
    );
    entities.push(informProviderOfRescheduling);
    let informClientOfReschedulingMessage =
      this.clientCommunication.getWAReschedulingMessage(
        this.doctorProfile,
        this.leadDetails
      );
    let informClientOfRescheduling = new CheckListEntity(
      CheckListActionKey.InformClientOfRescheduling,
      'Client - Inform about rescheduled appointment',
      informClientOfReschedulingMessage,
      UserType.client,
      true
    );
    entities.push(informClientOfRescheduling);
    let thankyouForReschedulingMessage =
      this.clientCommunication.getThankYouForReschedulingMessage(
        this.doctorProfile,
        this.leadDetails
      );
    let thankyou = new CheckListEntity(
      CheckListActionKey.ThankYouForRescheduling,
      'Client/Provider - Thank you',
      thankyouForReschedulingMessage,
      UserType.client
    );
    entities.push(thankyou);
    this.afterReschedulingSection = new CheckListSection(
      'After Rescheduling Appointment',
      entities
    );
    // console.log(this.afterReschedulingSection);
  }
  getAppointmentDateTime() {
    if (this.leadDetails && this.leadDetails.consultationInfo) {
      let date = this.leadDetails.consultationInfo.date;
      let time = this.leadDetails.consultationInfo.timeslot.split('-')[0];
      return dayjs(new Date(date + ' ' + time)).format(
        'DD MMM, hh:mm a'
      );
    } else {
      return '';
    }
  }

  get standardNatInNatPricing() {
    if (this.doctorProfile && this.doctorProfile.onlineConsultationConfig && this.doctorProfile.onlineConsultationConfig.fee != null) {
      const masterSpecialization = this.doctorProfile.getMasterSpecialization();
      const fee = this.doctorProfile.onlineConsultationConfig.fee;
      const in_fees = new CountrywiseFeeCalc().fee(fee, "IN", masterSpecialization)
      const us_fees = new CountrywiseFeeCalc().fee(fee, "US", masterSpecialization)
      const ca_fees = new CountrywiseFeeCalc().fee(fee, "CA", masterSpecialization)
      const othr_fees = new CountrywiseFeeCalc().fee(fee, null, masterSpecialization)
      let fees_string = '(IN) ' + in_fees.amount + ' ' + in_fees.unit + ' • (US) ' + us_fees.amount + ' ' + us_fees.unit + ' • (CA) ' + ca_fees.amount + ' ' + ca_fees.unit + ' • (Other) ' + othr_fees.amount + ' ' + othr_fees.unit
      return fees_string;
    } else {
      return "-"
    }
  }
}
