import { Expose } from "class-transformer";
import { RefundSource, TransactionSource } from "../Enums/pay";
import { LedgerItem } from "./LedgerItem";

export class AppointmentLedgerItem extends LedgerItem {
	@Expose() transactionDetails: string;
	@Expose() id: string;
	@Expose() source: TransactionSource;
	@Expose() refundSource: RefundSource;
	@Expose() clientId: string; //will have this for refunds in client wallet
	@Expose() transferId: string;
	@Expose() modeOfPayment: string; //Enum needed
	@Expose() modeOfPaymentDetails: any; //Enum needed
	@Expose() ledgerId: string;
	@Expose() partnerId: string;
	@Expose() feeDiff: number;
	@Expose() changeFee: number;

	get isRefundTransaction() {
		return this.debit > 0 && (this.refundSource != null || this.transactionDetails.toLowerCase().includes("refund"));
	}

	get isRefundedToClientWallet() {
		return this.debit > 0 && (this.transactionDetails.toLowerCase().includes("refund") && this.transactionDetails.toLowerCase().includes("client")
			&& this.transactionDetails.toLowerCase().includes("wallet"));
	}
}
