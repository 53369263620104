import { Expose } from "class-transformer";

export class DocumentInfo {
  @Expose()
  id: string;
  @Expose()
  type: string;
  @Expose()
  uploadedBy: string;
  @Expose()
  uploadedOn: number;
  @Expose()
  url: string;
}
