import { Expose, Type } from "class-transformer";
import { ContactNumber } from "../Actors/Person/ContactNumber";
import { WACallInitiatorEnum, WACallMediumEnum } from "../Enums/Enums";

export class ConsultationPlatformConfig {
  @Expose() isPrimary:boolean;
  @Expose() isDynamic:boolean; //false - if static link exists.
  @Expose() url:string;
  @Expose() meetingId:string;
  @Expose() password:string;
  @Expose() waCallInitiator: WACallInitiatorEnum; //For WhatsApp
  @Expose() waCallMedium: WACallMediumEnum; //For WhatsApp
  @Type(() => ContactNumber)
  @Expose() waContactNumber: ContactNumber = new ContactNumber(); //For WhatsApp
}
