import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { classToPlain, plainToClass } from 'class-transformer';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault('Asia/Kolkata');
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ClientWalletService } from 'src/app/services/client-wallet.service';
import { DoctorListingProfile } from '../../models-ts/Actors/Doctor/DoctorListingProfile';
import { CRMUser } from '../../models-ts/Actors/User/CRMUser';
import { User } from '../../models-ts/Actors/User/User';
import { Appointment } from '../../models-ts/Entities/Appointment';
import { Lead } from '../../models-ts/Entities/Lead';
import {
  PaylinkDetails,
  PaylinkType,
} from '../../models-ts/Entities/PaylinkDetails';
import { TransactionSource } from '../../models-ts/Enums/pay';
import { ActionDoneBy } from '../../models-ts/Protocols/ActionDoneBy';
import { OrganisationConnection } from '../../models-ts/Relationships/OrganisationConnection';
import { CopyClipboardService } from '../../services/copy-clipboard/copy-clipboard.service';
import { DoctorsService } from '../../services/doctors/doctors.service';
import { PatientService } from '../../services/patient/patient.service';
import { PaymentLinksService } from '../../services/payment-links/payment-links.service';
import firebase from 'firebase';
import 'firebase/database';
import { AppointmentService } from '../../services/appointment/appointment.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PaymentSourcesArray } from '../../models-ts/constants/payment-constants';
import { LedgerService } from 'src/app/ledger/services/ledger.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {
  CountrywiseFeeCalc,
  Currency,
} from '../../models-ts/Helpers/countrywiseFeeCalc';
import parsePhoneNumber from 'libphonenumber-js';
import { AuthUserInfoService } from '../../services/auth-user-info/auth-user-info.service';
import { ApptListEvent } from '../../reusable-components/provider-patient-appointments/provider-patient-appointments.component';
import { AmountWithCurrency } from '../../models-ts/Protocols/AmountWithCurrency';
import { MembershipPlanPurchased } from '../../models-ts/Entities/MembershipPlan/MembershipPlanPurchased';
import { environment, websiteBaseUrl } from 'src/environments/environment';
import { ServiceTypes } from '../../models-ts/Enums/Enums';
import { PatientRelationshipService } from '../../services/patient/patient-relationship.service';
@Component({
  selector: 'new-client-booking',
  templateUrl: './new-client-booking.component.html',
  styleUrls: ['./new-client-booking.component.scss'],
  providers: [ClientWalletService, LedgerService],
  encapsulation: ViewEncapsulation.None,
})
export class NewClientBookingComponent implements OnInit {
  modalRef: BsModalRef;
  @ViewChild('bookingPaylinkModal') bookingPaylinkModal;
  @Input() viewOnlyMode: boolean = false;
  @Input() showButton: boolean = true;
  @Input() paymentLinkType: PaylinkType = PaylinkType.BOOKING; //default
  @Input() lead: Lead = new Lead();
  @Input() patientId: string;
  @Input() doctorId: string;
  @Input() formPurposeVal: string;
  @Input() titleOverride: string;

  currentHeroInfo: CRMUser = new CRMUser();
  btnLoader: boolean = false;
  bookingSwitch: boolean = false;
  noPaymentLinks: boolean = false;
  allLinksLoader: boolean = false;
  pageNumber: number = 1;

  patientProfile: User = new User();
  doctorProfile: DoctorListingProfile = new DoctorListingProfile();
  newPatientForm;
  paymentLinkForm;
  payInfoform: UntypedFormGroup;
  appointmentBookingForm;
  paymentLink = '';
  quickConnections: OrganisationConnection[] = [];
  connectedProfiles: DoctorListingProfile[] = [];
  currentAppointment: Appointment = new Appointment();
  allPaymentLinks: PaylinkDetails[] = [];
  paymentSource = 'razorpay';
  PaymentSourceArray: string[] = PaymentSourcesArray;
  currentSlots: any[] = [];
  appointmentsList: Appointment[];
  patientAppointmentsList: Appointment[];
  isLoadingPatientAppointments: boolean = false;
  slotLoader: boolean = false;
  apptLoader: boolean = false;
  noAppointments: boolean = false;
  noSlots: boolean = false;
  selectedSlot: any;
  amtfetching: boolean;
  selectedFilter: string; //all or paid or unpaid
  noticeText: string;
  showReassignOptions: boolean = false;
  showAvailableTimeslots: boolean = true;
  allowTimeEdit: boolean = false;
  currentProviderSlots: any[] = [];
  payViaWallet: boolean = false;
  payViaPartnerWallet: boolean = false;
  walletBalance: number = 0;
  wallet: Map<Currency, number> = new Map<Currency, number>();
  isWalletBalanceLoading: boolean = false;
  currentStepName: string = "+ New";
  formPurpose: string = '';
  formPurposeMap: any = {
    'accept-money': 'Receive payment & add Docvita Credits',
    'booking-link': 'Receive payment & book an appointment',
    'book-appointment': 'Money received? Verify & book appointment',
    'free-followup': 'Book a free follow-up session',
  };
  orderDataDisplay: string;
  orderIdMatched: boolean = false;
  orderCheckLoader: boolean = false;
  orderIdFetchSub = new Subject<any>();
  providerInternationalFees: AmountWithCurrency = new AmountWithCurrency();
  currenciesToSelect = Object.keys(Currency);
  selectedCurrency: Currency = Currency.INR;
  providerDisplayAmount: number;
  providerDisplayCurrency: Currency = Currency.INR;
  selectedMembershipPlan: MembershipPlanPurchased = new MembershipPlanPurchased();

  practiceOptions: { key: string, value: string }[] = []
  isInPerson: boolean = false;
  practiceSelection: { key: string, value: string } = {
    key: null,
    value: null
  };

  sessionTypeOptions = Object.keys(ServiceTypes);
  currentSessionType: ServiceTypes = ServiceTypes.individual;

  constructor(
    private modalService: BsModalService,
    private _patientService: PatientService,
    private _toastrService: ToastrService,
    private _doctorService: DoctorsService,
    private _paymentLinksService: PaymentLinksService,
    private _clipboardService: CopyClipboardService,
    private _clientWalletService: ClientWalletService,
    private _appointmentService: AppointmentService,
    private _ledgerService: LedgerService,
    private _authUserInfoService: AuthUserInfoService,
    private _patientRelationshipService: PatientRelationshipService
  ) {
    this.providerInternationalFees.amount = 0
    this.providerInternationalFees.currency = Currency.INR;
  }

  ngOnInit(): void {
    this.currentHeroInfo = this._authUserInfoService.getUserInfo();
    this.orderIdFetchSub.pipe(debounceTime(800)).subscribe((val) => {
      this.checkForOrderInfoManually();
    });
  }

  openModal() {
    this.gotoPage(1);
    this.resetMasterForm();
    this.modalRef = this.modalService.show(this.bookingPaylinkModal, {
      class: 'custom-booking-modal',
    });
  }
  closeModal() {
    this.modalRef.hide();
  }

  populatePracticeOptions() {
    this.practiceOptions = [];
    if (this.doctorProfile?.associatedPractices?.size > 0) {
      for (const i of this.doctorProfile?.associatedPractices) {
        if (i[1]?.acceptingAppointmentsOnDocVita) {
          this.practiceOptions.push({ key: i[0], value: i[1].name })
        }
      }
    }
  }

  get getBookingDateTime() {
    if (
      this.appointmentBookingForm['date'] &&
      this.appointmentBookingForm['time']
    ) {
      let dateTime = dayjs(
        new Date(
          this.appointmentBookingForm['date'] +
          ' ' +
          this.appointmentBookingForm['time']
        )
      ).format('hh:mma, DD MMM');
      return dateTime;
    } else {
      return '';
    }
  }

  resetMasterForm() {
    if (this.formPurposeVal) {
      this.formPurpose = this.formPurposeVal
      let displayName = this.formPurpose.split('-').join(' ');
      this.setCurrentStepName(displayName);
    } else {
      this.formPurpose = null;
      this.currentStepName = '+ New';
    }
    this.paymentSource = 'razorpay';
    this.patientProfile = new User();
    this.quickConnections = [];
    this.connectedProfiles = [];
    this.resetPaymentForm();
  }

  commonResets() {
    this.paymentLink = '';
    this.paymentSource = 'razorpay';
    this.currentSessionType = ServiceTypes.individual;
    this.allowTimeEdit = false;
    this.showAvailableTimeslots = true;
    this.doctorProfile = new DoctorListingProfile();
    this.practiceOptions = [];
    this.practiceSelection = {
      key: null,
      value: null
    }
    this.isInPerson = false;
    this.orderDataDisplay = null;
    this.orderIdMatched = false;
    this.allPaymentLinks = [];
    this.currentProviderSlots = [];
    this.isWalletBalanceLoading = false;
    this.payViaWallet = false;
    this.payViaPartnerWallet = false;
    this.selectedCurrency = Currency.INR;
    this.providerInternationalFees = new AmountWithCurrency();
    this.providerDisplayAmount = null;
    this.providerDisplayCurrency = Currency.INR;
    this.initBools();
    this.setPaymentInfoForm();
    this.initAppointmentBookingForm();

  }

  resetButPreservePatientDetails() {
    this.commonResets();
    this.paymentLinkForm = {
      amount: null,
      description: '',
      customerName: this.paymentLinkForm['customerName']
        ? this.paymentLinkForm['customerName']
        : '',
      customerEmail: this.paymentLinkForm['customerEmail']
        ? this.paymentLinkForm['customerEmail']
        : '',
      customerContact: {
        countryCode:
          this.paymentLinkForm['customerContact'] &&
            this.paymentLinkForm['customerContact']['countryCode']
            ? this.paymentLinkForm['customerContact']['countryCode']
            : '',
        number:
          this.paymentLinkForm['customerContact'] &&
            this.paymentLinkForm['customerContact']['number']
            ? this.paymentLinkForm['customerContact']['number']
            : '',
      },
    };
    this.selectedPlan(null)
  }

  resetPaymentForm() {
    this.commonResets();
    this.walletBalance = null;
    this.wallet = new Map<Currency, number>();
    this.initPaymentLinkForm();
  }

  initBools() {
    this.btnLoader = false;
    this.bookingSwitch = false;
    this.noPaymentLinks = false;
    this.allLinksLoader = false;
  }
  initPaymentLinkForm() {
    this.paymentLinkForm = {
      amount: null,
      description: '',
      customerName: '',
      customerEmail: '',
      customerContact: {
        countryCode: '',
        number: '',
      },
    };
  }
  initAppointmentBookingForm() {
    this.currentSlots = [];
    this.appointmentsList = [];
    this.patientAppointmentsList = [];
    this.doctorProfile = new DoctorListingProfile();
    this.appointmentBookingForm = {
      date: new Date().toISOString().split('T')[0],
      time: '',
      slotLength: '',
    };
  }

  appointmentFormValidation() {
    if (
      !this.appointmentBookingForm ||
      !this.appointmentBookingForm['date'] ||
      this.appointmentBookingForm['date'].length < 1 ||
      !this.appointmentBookingForm['time'] ||
      this.appointmentBookingForm['time'].length < 1 ||
      !this.appointmentBookingForm['slotLength'] ||
      this.appointmentBookingForm['slotLength'].length < 1
    ) {
      return false;
    } else {
      return true;
    }
  }
  paymentLinkFormValidation() {
    if (
      !this.paymentLinkForm ||
      !this.paymentLinkForm['amount'] ||
      !this.paymentLinkForm['customerName'] ||
      this.paymentLinkForm['customerName'].length < 1 ||
      !this.paymentLinkForm['customerContact'] ||
      !this.paymentLinkForm['customerContact']['countryCode'] ||
      this.paymentLinkForm['customerContact']['countryCode'].length < 1 ||
      isNaN(this.paymentLinkForm['customerContact']['countryCode']) ||
      !this.paymentLinkForm['customerContact']['number'] ||
      this.paymentLinkForm['customerContact']['number'].length < 1 ||
      isNaN(this.paymentLinkForm['customerContact']['number']) ||
      (this.formPurpose == 'accept-money' &&
        (this.paymentSource == 'stripe' || this.paymentSource == 'cashfree') &&
        (this.paymentLinkForm['description'] == '' ||
          this.paymentLinkForm['description'] == null))
    ) {
      return false;
    } else {
      return true;
    }
  }

  newPaymentLink() {
    this.resetMasterForm();
    this.gotoPage(1);
  }

  setPatient(event) {
    if (event) {
      if (!this.formPurposeVal) {
        this.currentStepName = 'What would you like to do?';
      }
      this.patientProfile = event;
      this.getOrganizaitonNameForQuickContact();
      // console.log(event);
      this.paymentLinkForm['customerName'] = this.patientProfile.name;
      if (
        this.patientProfile.contactNumbers &&
        this.patientProfile.getPrimaryContactNumbers() &&
        this.patientProfile.getPrimaryContactNumbers().length > 0
      ) {
        let contact = this.patientProfile.getPrimaryContactNumbers()[0];
        this.paymentLinkForm['customerContact'] = {
          number: contact.number,
          countryCode: contact.countryCode,
        };
      } else if (
        this.patientProfile.primaryContactNumber &&
        this.patientProfile.primaryContactNumber.number
      ) {
        let contact = this.patientProfile.primaryContactNumber;
        this.paymentLinkForm['customerContact'] = {
          number: contact.number,
          countryCode: contact.countryCode,
        };
      }

      if (
        this.patientProfile &&
        this.patientProfile.emails &&
        this.patientProfile.getPrimaryEmail() &&
        this.patientProfile.getPrimaryEmail().email
      ) {
        this.paymentLinkForm['customerEmail'] =
          this.patientProfile.getPrimaryEmail().email;
      } else if (
        this.patientProfile &&
        this.patientProfile.emails &&
        this.patientProfile.getEmails() &&
        this.patientProfile.getEmails().length > 0
      ) {
        this.paymentLinkForm['customerEmail'] =
          this.patientProfile.getEmails()[0].email;
      } else if (this.patientProfile.primaryEmail) {
        this.paymentLinkForm['customerEmail'] =
          this.patientProfile.primaryEmail;
      }
    } else {
      this.resetMasterForm();
    }
  }

  togglePaymentSource(source: string) {
    if (source) {
      this.paymentSource = source;
      if (source != 'stripe') {
        this.selectedCurrency = Currency.INR;
      }
    }
  }

  delay(time: number) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(true);
      }, time);
    });
  }

  jsonParser(data) {
    return JSON.parse(JSON.stringify(data));
  }

  gotoPage(number: number) {
    this.pageNumber = number;
  }

  toggleBookingSwitch() {
    if (this.bookingSwitch) {
      this.initAppointmentBookingForm();
    }
  }

  toggleInPersonBool(event: any) {
    if (event?.target?.checked) {
      this.practiceSelection = this.practiceOptions[0];
      console.log(this.practiceSelection);
    } else {
      this.practiceSelection = null;
    }
  }

  async getOrganizaitonNameForQuickContact() {
    const resp = await this._patientService.getOrganizaitonNameForQuickContact(this.patientProfile);
    this.quickConnections = resp.quickConnections;
    this.connectedProfiles = resp.profiles;
  }
  
  setProvider(event) {
    if (event) {
      this.doctorProfile = event;
      // console.log(this.doctorProfile)
      this.appointmentBookingForm['slotLength'] = this.doctorProfile
        .appointmentDuration
        ? this.doctorProfile.appointmentDuration.toString()
        : null;

      this.populatePracticeOptions();

      this.setInternationalPricing();
      if (this.formPurpose == 'free-followup') {
        this.paymentLinkForm['amount'] = 0;
      }

      this.providerDisplayAmount = new CountrywiseFeeCalc().fee(
        this.doctorProfile.onlineConsultationConfig.fee,
        null,
        this.doctorProfile.getMasterSpecialization()
      ).amount;
      this.providerDisplayCurrency = new CountrywiseFeeCalc().fee(
        this.doctorProfile.onlineConsultationConfig.fee,
        null,
        this.doctorProfile.getMasterSpecialization()
      ).unit;

    } else {
      this.clearProviderSelection();
    }
  }
  get checkIfInternationalClient() {
    if (
      this.patientProfile &&
      (this.patientProfile && this.patientProfile.latestCountryOfVisit != "IN") ||
      (this.patientProfile.primaryContactNumber &&
        this.patientProfile.primaryContactNumber.countryCode &&
        this.patientProfile.primaryContactNumber.countryCode != '91')
    ) {
      return true;
    } else {
      return false;
    }
  }

  get standardNatInNatPricing() {
    if (this.doctorProfile && this.doctorProfile.onlineConsultationConfig && this.doctorProfile.onlineConsultationConfig.fee != null) {
      const masterSpecialization = this.doctorProfile.getMasterSpecialization();
      const fee = this.doctorProfile.onlineConsultationConfig.fee;
      const in_fees = new CountrywiseFeeCalc().fee(fee, "IN", masterSpecialization)
      const us_fees = new CountrywiseFeeCalc().fee(fee, "US", masterSpecialization)
      const othr_fees = new CountrywiseFeeCalc().fee(fee, null, masterSpecialization)
      let fees_string = '(IN) ' + in_fees.amount + ' ' + in_fees.unit + ' • (US/CA) ' + us_fees.amount + ' ' + us_fees.unit + ' • (Other) ' + othr_fees.amount + ' ' + othr_fees.unit
      return fees_string;
    } else {
      return "-"
    }
  }

  setInternationalPricing() {
    if (
      this.checkIfInternationalClient &&
      this.doctorProfile.onlineConsultationConfig.fee
    ) {
      let patientNumber =
        this.patientProfile.primaryContactNumber.countryCode +
        this.patientProfile.primaryContactNumber.number +
        '';

      let pn = parsePhoneNumber('+' + patientNumber);
      if (patientNumber && pn && pn.isValid()) {
        let currentFee = this.doctorProfile.onlineConsultationConfig.fee;
        let countryCodeText = pn.country;
        let masterSpecialization = this.doctorProfile.getMasterSpecialization();
        console.log(currentFee, countryCodeText, masterSpecialization)
        const obj = new CountrywiseFeeCalc().fee(
          currentFee,
          countryCodeText,
          masterSpecialization
        );;
        this.providerInternationalFees.amount = obj.amount;
        this.providerInternationalFees.currency = obj.unit;
        console.log("Is international client and fee fetched")
      }
    } else {
      this.providerInternationalFees = new AmountWithCurrency();
    }
  }

  clearProviderSelection() {
    this.doctorProfile = new DoctorListingProfile();
    this.appointmentBookingForm['slotLength'] = '';
    this.paymentLinkForm['amount'] = null;
    this.currentProviderSlots = [];
    this.providerDisplayAmount = null;
    this.providerDisplayCurrency = Currency.INR;
    this.selectedPlan(null)
  }

  manageListFetchState(event: ApptListEvent) {
    if (this.formPurpose != 'free-followup' && !this.isPlanSelected) {
      if (event.loadState == true && event.appointmentList.length == 0) {
        this.amtfetching = true;
      } else {
        this.amtfetching = false;
        if (event.appointmentList && event.appointmentList.length > 0) {
          const latest = event.appointmentList.slice(0, 4);
          let appt: Appointment;
          event.appointmentList.forEach((item) => {
            if (appt == null && item && item.amount > 0) {
              appt = item;
            }
          });
          let pg: string;
          if (appt
            && appt.paymentSource
            && (
              appt.paymentSource == TransactionSource.Cashfree ||
              appt.paymentSource == TransactionSource.Razorpay ||
              appt.paymentSource == TransactionSource.Stripe
            )) {
            pg = appt.paymentSource.toLowerCase();
          }
          if (pg) this.togglePaymentSource(pg);
          if (this.checkIfInternationalClient && this.doctorProfile && this.doctorProfile.id) {
            if (this.providerInternationalFees && this.providerInternationalFees['amount']) {
              if (event.appointmentList.length > 0) {
                let currentFee = this.doctorProfile.onlineConsultationConfig.fee;
                let countryCodeText;
                let masterSpecialization = this.doctorProfile.getMasterSpecialization();
                if (appt && appt.currency != null && appt.currency == Currency.USD) {
                  countryCodeText = "US"
                } else {
                  countryCodeText = null;
                }
                const obj = new CountrywiseFeeCalc().fee(
                  currentFee,
                  countryCodeText,
                  masterSpecialization
                );
                this.providerInternationalFees.amount = obj.amount
                this.providerInternationalFees.currency = obj.unit;
              }
              const match_intl_price = latest.filter(d => d.amount == this.providerInternationalFees['amount'] && d.currency == this.providerInternationalFees['currency']);
              // console.log("manageListFetchState",match_intl_price, latest, this.providerInternationalFees);
              const fit_ratio = match_intl_price.length / latest.length;
              if (fit_ratio >= 0.5) {
                //use intl fees
                this.selectedCurrency = this.providerInternationalFees['currency'];
                this.paymentLinkForm['amount'] = this.providerInternationalFees['amount'];
                if (this.providerInternationalFees['currency'] != Currency.INR) {
                  this.paymentSource = 'stripe'
                }
              } else {
                this.paymentLinkForm['amount'] = null;
              }
            } else {
              this.paymentLinkForm['amount'] = null;
            }
          } else {
            this.defaultAmountSetter()
          }
        } else {
          if (this.checkIfInternationalClient && this.doctorProfile && this.doctorProfile.id) {
            if (this.providerInternationalFees && this.providerInternationalFees['amount']) {
              this.selectedCurrency = this.providerInternationalFees['currency'];
              this.paymentLinkForm['amount'] = this.providerInternationalFees['amount'];
              if (this.providerInternationalFees['currency'] != Currency.INR) {
                this.paymentSource = 'stripe'
              }
            } else {
              this.paymentLinkForm['amount'] = null;
            }
          } else {
            this.defaultAmountSetter()
          }
        }
      }
    }

  }

  getAppointmentBody() {
    if (
      this.doctorProfile &&
      this.doctorProfile.id &&
      this.appointmentFormValidation()
    ) {
      this.currentAppointment = new Appointment();
      let _timeslot =
        this.appointmentBookingForm['time'] +
        '-' +
        dayjs(
          new Date(
            this.appointmentBookingForm['date'] +
            ' ' +
            this.appointmentBookingForm['time']
          )
        )
          .add(+this.appointmentBookingForm['slotLength'], 'minutes')
          .format('HH:mm');
      this.currentAppointment.timeslot = _timeslot;
      this.currentAppointment.doctorId = this.doctorProfile.id;
      this.currentAppointment.organisationId = this.doctorProfile.id;
      this.currentAppointment.doctorName = this.doctorProfile.fullName();
      this.currentAppointment.organisationName = this.doctorProfile.fullName();
      this.currentAppointment.date = this.appointmentBookingForm['date'];
      if (this.paymentLinkForm && this.paymentLinkForm['amount'] != null)
        this.currentAppointment.amount = this.paymentLinkForm['amount'];
      this.currentAppointment.currency = this.selectedCurrency;
      this.currentAppointment.purposeOfVisitTitle = 'Online Consultation';
      this.currentAppointment.purposeOfVisitDescription =
        'Chat and Video (Valid for 72 hrs)';
      this.currentAppointment.modeOfAppointment = 'docvita';
      this.currentAppointment.sessionType = this.currentSessionType;
      if ((this.paymentSource == 'stripe' || this.paymentSource == 'razorpay') && this.selectedCurrency != Currency.INR) {
        this.currentAppointment.providerDisplayAmount = this.providerDisplayAmount;
        this.currentAppointment.providerDisplayCurrency = this.providerDisplayCurrency;
      }
      this.currentAppointment.initiator = new ActionDoneBy();
      this.currentAppointment.initiator.updatedById = this.currentHeroInfo.docvitaId;
      this.currentAppointment.initiator.updatedByName = this.currentHeroInfo.name;
      this.currentAppointment.initiator.updatedOn = new Date().getTime();
      let body = classToPlain(this.currentAppointment);
      body['paymentIntent'] = 'payNow';
      body['platform'] = 'whatsapp';
      body = { ...body, ...this.setAppointmentTypeAndInPersonDetails() }

      let patientInfo = {
        patientId: this.patientProfile.docvitaId,
        patientName: this.patientProfile.name,
        patientGender: this.patientProfile.gender
          ? this.patientProfile.gender
          : '',
      };
      if (
        this.patientProfile.contactNumbers &&
        this.patientProfile.contactNumbers.size > 0
      ) {
        let contactNumber = this.patientProfile.getPrimaryContactNumbers()[0];
        if (contactNumber.number && contactNumber.number.length > 0) {
          patientInfo['patientPrimaryContactNumber'] = {
            countryCode: contactNumber.countryCode,
            number: contactNumber.number,
          };
        }
      } else if (
        this.patientProfile.primaryContactNumber &&
        this.patientProfile.primaryContactNumber.number?.length > 0
      ) {
        patientInfo['patientPrimaryContactNumber'] = {
          countryCode: this.patientProfile.primaryContactNumber.countryCode,
          number: this.patientProfile.primaryContactNumber.number,
        };
      }

      if (
        this.patientProfile.emails &&
        this.patientProfile.emails.size > 0 &&
        this.patientProfile.getPrimaryEmail()
      ) {
        patientInfo['patientPrimaryEmail'] =
          this.patientProfile.getPrimaryEmail().email;
      } else {
        patientInfo['patientPrimaryEmail'] = this.patientProfile.primaryEmail
          ? this.patientProfile.primaryEmail
          : '';
      }

      body['patientsArray'] = [patientInfo];
      // console.log(JSON.parse(JSON.stringify(body)))
      return JSON.parse(JSON.stringify(body));
    } else {
      return null;
    }
  }

  @ViewChild('apptClashModal') apptClashModal;
  createPaymentLink() {
    this.btnLoader = true;
    if (!this.paymentSource || this.paymentSource.length == 0) {
      this._toastrService.warning('Select payment source.');
      this.btnLoader = false;
      return;
    }
    if (!this.currentHeroInfo || !this.currentHeroInfo.docvitaId || !this.currentHeroInfo.name) {
      this._toastrService.warning('Your CRM account is missing details');
      this.btnLoader = false;
      return;
    }
    if (this.paymentLinkFormValidation()) {
      // console.log(this.paymentLinkForm);
      let body = {};
      body['amount'] = this.paymentLinkForm['amount'];
      body['description'] = this.paymentLinkForm['description']
        ? this.paymentLinkForm['description']
        : '';
      body['customer'] = {
        name: this.paymentLinkForm['customerName'],
        contact: this.paymentLinkForm['customerContact'],
      };
      if (this.lead && this.lead.id && this.lead.consultationInfo) {
        body['leadId'] = this.lead.id;
        body['appointmentId'] = this.lead.consultationInfo.appointmentId;
      }
      body['providerId'] =
        this.doctorProfile && this.doctorProfile.id
          ? this.doctorProfile.id
          : null;
      body['patientId'] = this.patientProfile.docvitaId;
      if (this.currentHeroInfo && this.currentHeroInfo.docvitaId) {
        body['createdById'] = this.currentHeroInfo.docvitaId;
        body['createdByName'] = this.currentHeroInfo.name;
      }
      if (
        this.paymentLinkForm['customerEmail'] &&
        this.paymentLinkForm['customerEmail'].length > 1
      ) {
        if (this.validateEmail(this.paymentLinkForm['customerEmail'])) {
          body['customer']['email'] =
            this.paymentLinkForm['customerEmail'].trim();
        } else {
          this._toastrService.warning('Customer email is not valid!');
          this.btnLoader = false;
          return;
        }
      }
      if (this.formPurpose == 'accept-money') {
        body['type'] = PaylinkType.CREDIT_PURCHASE;
        body['creditInfo'] = {};
      }
      if (this.paymentSource &&( this.paymentSource == 'stripe' || this.paymentSource == 'razorpay')) {
        body['currency'] = this.selectedCurrency;
      }
      if (this.formPurpose == 'booking-link') {
        if (
          this.appointmentFormValidation() &&
          this.doctorProfile.id &&
          this.patientProfile.docvitaId
        ) {
          let apptBody = this.getAppointmentBody();
          if (apptBody) {
            const apptId = firebase.database().ref().push().key;
            body["returnOnSuccessUrl"] = websiteBaseUrl + "/member/appointments/" + apptId;///
            body["appointmentId"] = apptId;
            apptBody["appointmentId"] = apptId;
            body['consultationInfo'] = apptBody;
            body['type'] = PaylinkType.BOOKING;
            if (body['description'] == '') {
              body['description'] = this.payLinkNewSessionDescriptionBody(
                body['consultationInfo']
              );
            }
            if (this.paymentSource && this.paymentSource == 'stripe') {
              body['imageUrls'] = [this.doctorProfile.photoUrl];
            }
          } else {
            this._toastrService.warning('Incomplete Profile info!');
            this.btnLoader = false;
            return;
          }
        } else {
          this._toastrService.warning('Incomplete booking info!');
          this.btnLoader = false;
          return;
        }
      }

      if (body && body['consultationInfo']) {
        if (body['consultationInfo']) {
          this.checkforClashingAppts(body, body['consultationInfo']);
        }
      } else {
        this.continueWithPaymentLinkCreation(body);
      }
    } else {
      this._toastrService.warning('Payment form incomplete.');
      this.btnLoader = false;
    }
  }
  checkforClashingAppts(payLinkBody, consultBody) {
    this.btnLoader = true;
    this.apptClashModal
      .openConflictPromise(
        consultBody['doctorId'],
        consultBody['date'],
        consultBody['timeslot'],
        consultBody['doctorName']
      )
      .then((hasConflicts) => {
        if (hasConflicts) {
          this.btnLoader = false;
          this.apptClashModal.openModal();
        } else {
          this.continueWithPaymentLinkCreation(payLinkBody);
        }
      })
      .catch((err) => {
        this.continueWithPaymentLinkCreation(payLinkBody);
      });
  }
  continueWithPaymentLinkCreation(body) {
    this.btnLoader = true;
    this._paymentLinksService
      .createPaymentLink(body, this.paymentSource)
      .then((res) => {
        if (
          res &&
          res['body'] &&
          res['body']['short_url'] &&
          res['body']['id']
        ) {
          this.btnLoader = false;
          this._toastrService.success(
            'Payment link created! ' + this.paymentSource
          );
          if (
            this.paymentSource == 'cashfree' &&
            body['consultationInfo'] != null &&
            body['consultationInfo']['patientsArray'] != null &&
            body['consultationInfo']['doctorId'] != null
          ) {
            this.gotoPage(3);
            this.paymentLink = res['body']['short_url'];
            this.sendWAMessageForPaymentLinkWithBooking(
              res['body']['id'],
              this.paymentSource,
              this.patientProfile
            );
          } else if (
            this.paymentSource == 'razorpay' &&
            body['consultationInfo'] != null &&
            body['consultationInfo']['patientsArray'] != null &&
            body['consultationInfo']['doctorId'] != null
          ) {
            this.gotoPage(3);
            this.paymentLink = res['body']['short_url'];
            this.sendWAMessageForPaymentLinkWithBooking(
              res['body']['id'],
              this.paymentSource,
              this.patientProfile
            );
          } else if (
            this.paymentSource == 'stripe' &&
            body['consultationInfo'] != null &&
            body['consultationInfo']['patientsArray'] != null &&
            body['consultationInfo']['doctorId'] != null
          ) {
            this.gotoPage(3);
            this.paymentLink = res['body']['short_url'];
            this.sendWAMessageForPaymentLinkWithBooking(
              res['body']['id'],
              this.paymentSource,
              this.patientProfile
            );
          } else {
            this.paymentLink = res['body']['short_url'];
            this.gotoPage(3);
          }
        } else {
          this._toastrService.error('Failed to create payment link');
          this.btnLoader = false;
        }
      })
      .catch((err) => {
        this.btnLoader = false;
        this._toastrService.error('Failed to create payment link');
      });
  }

  sendWAMessageForPaymentLinkWithBooking(
    paymentLinkId: string,
    paymentSource: string,
    patientProfile: User
  ) {
    let promises: Promise<any>[] = [];
    let waNumbers = patientProfile.getWhatsappContactNumbers();
    this._toastrService.info('Sharing the link on WA');
    if (waNumbers && waNumbers.length > 0) {
      for (let waNum of waNumbers) {
        promises.push(
          new Promise((resolve, reject) => {
            let requestBody: any = {};
            requestBody['paymentLinkId'] = paymentLinkId;
            requestBody['recipient'] = waNum.countryCode + '' + waNum.number;
            this._paymentLinksService
              .sendBookingPaymentLinkonWA(requestBody, paymentSource)
              .then((val) => {
                resolve(val);
              })
              .catch((err) => {
                reject(err);
              });
          })
        );
      }
      Promise.all(promises)
        .then((val) => {
          this._toastrService.success("Sent the link on client's whatsapp");
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  payLinkNewSessionDescriptionBody(appointmentBody) {
    /*
    Session with Dr. Aditya for 13 Dec, 10:00 am IST
     */
    let desc = '';
    if (this.doctorProfile && this.doctorProfile.id && appointmentBody) {
      let date = appointmentBody['date'];
      let time = appointmentBody['timeslot'].split('-')[0];
      let dateTime = dayjs(date + ' ' + time);
      desc = `Session with ${this.doctorProfile.getFirstNameWithSalutation()} for ${dateTime.format(
        'DD MMM'
      )}, ${dateTime.format('hh:mm a')} IST`;
    }
    return desc;
  }

  updatePaymentLinkCollection(body) { }

  copyPaymentLink() {
    this._clipboardService.copyToClipoard(
      this.paymentLink ? this.paymentLink : ''
    );
  }
  validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  copy(text) {
    if (text) {
      this._clipboardService.copyToClipoard(text);
    }
  }

  timeToMin(hm: any) {
    let a = hm.split(':');
    let minutes = +a[0] * 60 + +a[1];
    return minutes;
  }

  minToTime(mins: any) {
    let m = mins % 60;
    let h = ((mins - m) / 60).toString();
    var HHMM =
      (h.length > 1 ? h : '0' + h) + ':' + (m < 10 ? '0' : '') + m.toString();
    return HHMM;
  }

  setSlotLength(num) {
    this.appointmentBookingForm['slotLength'] = num;
  }

  getStatusStyle(status) {
    if (status == 'created') {
      return 'badge bg-primary';
    } else if (status == 'paid') {
      return 'badge bg-success';
    } else if (status == 'cancelled') {
      return 'badge bg-danger';
    } else {
      return 'badge bg-light';
    }
  }

  selectSlot(slot) {
    this.selectedSlot = slot;
    this.appointmentBookingForm['time'] = this.selectedSlot['startTime'];
    this.toggleProviderTimeslots(false);
    this.allowTimeEdit = false;
    // this.selectedSlotLength = this.selectedSlot["sLength"];
  }

  displayCurrentDay() {
    return new Date(this.appointmentBookingForm['date']).getTime();
  }

  tConvert(time) {
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? ' am' : ' pm';
      time[0] = +time[0] % 12 || 12;
    }
    return time.join('');
  }

  appointmentDateScroller(direction) {
    if (direction == '+' && this.appointmentBookingForm['date']) {
      this.appointmentBookingForm['date'] = dayjs(
        new Date(this.appointmentBookingForm['date'])
      )
        .add(1, 'day')
        .format('YYYY-MM-DD');
    } else if (direction == '-' && this.appointmentBookingForm['date']) {
      this.appointmentBookingForm['date'] = dayjs(
        new Date(this.appointmentBookingForm['date'])
      )
        .subtract(1, 'day')
        .format('YYYY-MM-DD');
    } else {
      //cool
    }
  }
  appointmentPaymentDateScroller(direction) {
    if (direction == '+' && this.appointmentBookingForm['date']) {
      this.payInfoform.patchValue({
        paymentDoneDate: dayjs(
          new Date(this.payInfoform.get('paymentDoneDate').value)
        )
          .add(1, 'day')
          .format('YYYY-MM-DD'),
      });
    } else if (direction == '-' && this.appointmentBookingForm['date']) {
      this.payInfoform.patchValue({
        paymentDoneDate: dayjs(
          new Date(this.payInfoform.get('paymentDoneDate').value)
        )
          .subtract(1, 'day')
          .format('YYYY-MM-DD'),
      });
    } else {
      //cool
    }
  }
  toggleProviderTimeslots(bool: boolean) {
    this.showAvailableTimeslots = bool;
  }
  editTimeManually() {
    this.toggleProviderTimeslots(false);
    this.allowTimeEdit = true;
    this.appointmentBookingForm['time'] = null;
  }
  handleProviderSlots(slots: any[]) {
    this.currentProviderSlots = slots;
    // this.cdRef.detectChanges();
  }

  get disablePayViaWallet() {
    return (
      +this.paymentLinkForm['amount'] == null || this.wallet == null ||
      this.wallet.size == 0 ||
      (this.wallet && this.wallet.size > 0 && this.wallet.get(this.selectedCurrency) != null && this.wallet.get(this.selectedCurrency) < +this.paymentLinkForm['amount'])
    );
  }

  bookAppointmentWithWalletBalance() {
    if (
      !this.appointmentFormValidation() ||
      this.patientProfile == null ||
      this.doctorProfile == null ||
      this.paymentLinkForm['amount'] == null ||
      !this.currentHeroInfo ||
      !this.currentHeroInfo.docvitaId ||
      !this.currentHeroInfo.name
    ) {
      this._toastrService.error('Something is missing to book the appointment');
    } else {
      this.btnLoader = true;
      let selectedTime = this.appointmentBookingForm['time'];
      let selectedDate = this.appointmentBookingForm['date'];
      let selectedSlotLength = +this.appointmentBookingForm['slotLength'];
      let selectedAmount = +this.paymentLinkForm['amount'];
      this.currentAppointment = new Appointment();
      let _timeslot =
        selectedTime +
        '-' +
        dayjs(new Date(selectedDate + ' ' + selectedTime))
          .add(selectedSlotLength, 'minutes')
          .format('HH:mm');
      this.currentAppointment.timeslot = _timeslot;
      this.currentAppointment.doctorId = this.doctorProfile.id;
      this.currentAppointment.organisationId = this.doctorProfile.id;
      this.currentAppointment.doctorName = this.doctorProfile.fullName();
      this.currentAppointment.organisationName = this.doctorProfile.fullName();
      this.currentAppointment.date = selectedDate;
      if (selectedAmount != null)
        this.currentAppointment.amount = selectedAmount;
      this.currentAppointment.currency = this.selectedCurrency;
      this.currentAppointment.purposeOfVisitTitle = 'Online Consultation';
      this.currentAppointment.purposeOfVisitDescription =
        'Chat and Video (Valid for 72 hrs)';
      this.currentAppointment.modeOfAppointment = 'docvita';
      this.currentAppointment.initiator = new ActionDoneBy();
      this.currentAppointment.initiator.updatedById =
        this.currentHeroInfo.docvitaId;
      this.currentAppointment.initiator.updatedByName =
        this.currentHeroInfo.name;
      this.currentAppointment.initiator.updatedOn = new Date().getTime();
      this.currentAppointment.sessionType = this.currentSessionType;
      if (this.selectedCurrency != Currency.INR) {
        this.currentAppointment.providerDisplayAmount = this.providerDisplayAmount;
        this.currentAppointment.providerDisplayCurrency = this.providerDisplayCurrency;

        this.currentAppointment.acceptedAmount = new CountrywiseFeeCalc().fee(
          this.doctorProfile.onlineConsultationConfig.fee,
          null,
          this.doctorProfile.getMasterSpecialization()
        ).amount;
        this.currentAppointment.acceptedAmountCurrency = new CountrywiseFeeCalc().fee(
          this.doctorProfile.onlineConsultationConfig.fee,
          null,
          this.doctorProfile.getMasterSpecialization()
        ).unit;
      }
      if (this.payViaWallet) {
        this.currentAppointment.paymentId = firebase
          .database()
          .ref()
          .push().key;
        this.currentAppointment.paymentSource = TransactionSource.DocVitaWallet;
        this.currentAppointment.paymentDoneOn = new Date().getTime();
        this.currentAppointment.totalAmountPaid = selectedAmount;
      } else if (selectedAmount != 0) {
        if (
          this.payInfoform.value['paymentSource'] &&
          this.payInfoform.value['paymentSource'] == 'Razorpay' &&
          this.payInfoform.value['paymentId'] &&
          selectedAmount
        ) {
          this.currentAppointment.paymentId =
            this.payInfoform.value['paymentId'];
          this.currentAppointment.paymentSource =
            this.payInfoform.value['paymentSource'];
          this.currentAppointment.totalAmountPaid = selectedAmount;
        } else if (this.validatePaymentForm()) {
          this.currentAppointment.paymentId =
            this.payInfoform.value['paymentId'];
          this.currentAppointment.paymentSource =
            this.payInfoform.value['paymentSource'];
          this.currentAppointment.paymentDoneOn = new Date(
            this.payInfoform.value['paymentDoneDate'] +
            ' ' +
            this.payInfoform.value['paymentDoneTime']
          ).getTime();
          this.currentAppointment.totalAmountPaid = selectedAmount;
        } else {
          // console.log('payment validation', this.validatePaymentForm());
          this.btnLoader = false;
          this._toastrService.warning('Invalid Payment info!');
          return;
        }
      }
      let body = classToPlain(this.currentAppointment);
      body['paymentIntent'] = 'payNow';
      if (this.payViaWallet) body['payViaWallet'] = this.payViaWallet;
      body['platform'] = 'whatsapp';
      body = { ...body, ...this.setAppointmentTypeAndInPersonDetails() }

      let patientInfo = {};
      if (
        this.patientProfile &&
        this.patientProfile.docvitaId &&
        this.patientProfile.name &&
        this.patientProfile.primaryContactNumber &&
        this.patientProfile.primaryContactNumber.countryCode &&
        this.patientProfile.primaryContactNumber.number
      ) {
        patientInfo = {
          patientId: this.patientProfile.docvitaId,
          patientName: this.patientProfile.name,
          patientGender: this.patientProfile.gender
            ? this.patientProfile.gender
            : '',
        };

        if (
          this.patientProfile.contactNumbers &&
          this.patientProfile.contactNumbers.size > 0
        ) {
          let contactNumber = this.patientProfile.getPrimaryContactNumbers()[0];
          if (contactNumber.number && contactNumber.number.length > 0) {
            patientInfo['patientPrimaryContactNumber'] = {
              countryCode: contactNumber.countryCode,
              number: contactNumber.number,
            };
          }
        } else if (
          this.patientProfile.primaryContactNumber &&
          this.patientProfile.primaryContactNumber.number?.length > 0
        ) {
          patientInfo['patientPrimaryContactNumber'] = {
            countryCode: this.patientProfile.primaryContactNumber.countryCode,
            number: this.patientProfile.primaryContactNumber.number,
          };
        }

        if (
          this.patientProfile.emails &&
          this.patientProfile.emails.size > 0 &&
          this.patientProfile.getPrimaryEmail()
        ) {
          patientInfo['patientPrimaryEmail'] =
            this.patientProfile.getPrimaryEmail().email;
        } else {
          patientInfo['patientPrimaryEmail'] = this.patientProfile.primaryEmail
            ? this.patientProfile.primaryEmail
            : '';
        }

        body['patientsArray'] = [patientInfo];
      }
      // console.log(body);
      this.btnLoader = true;
      this.apptClashModal
        .openConflictPromise(
          body['doctorId'],
          body['date'],
          body['timeslot'],
          body['doctorName']
        )
        .then((hasConflicts) => {
          if (hasConflicts) {
            this.btnLoader = false;
            this.apptClashModal.openModal();
          } else {
            this.checkForOrderInfoAndContinue(body);
          }
        })
        .catch((err) => {
          this.checkForOrderInfoAndContinue(body);
        });
    }
  }

  bookAppointmentWithPartnerWallet() {
    if (
      !this.appointmentFormValidation() ||
      this.patientProfile == null ||
      this.doctorProfile == null ||
      this.paymentLinkForm['amount'] == null ||
      !this.checkForPartnership ||
      !this.currentHeroInfo ||
      !this.currentHeroInfo.docvitaId ||
      !this.currentHeroInfo.name
    ) {
      this._toastrService.error('Something is missing to book the appointment');
    } else {
      this.btnLoader = true;
      let selectedTime = this.appointmentBookingForm['time'];
      let selectedDate = this.appointmentBookingForm['date'];
      let selectedSlotLength = +this.appointmentBookingForm['slotLength'];
      let selectedAmount = +this.paymentLinkForm['amount'];
      this.currentAppointment = new Appointment();
      let _timeslot =
        selectedTime +
        '-' +
        dayjs(new Date(selectedDate + ' ' + selectedTime))
          .add(selectedSlotLength, 'minutes')
          .format('HH:mm');
      this.currentAppointment.timeslot = _timeslot;
      this.currentAppointment.doctorId = this.doctorProfile.id;
      this.currentAppointment.organisationId = this.doctorProfile.id;
      this.currentAppointment.doctorName = this.doctorProfile.fullName();
      this.currentAppointment.organisationName = this.doctorProfile.fullName();
      this.currentAppointment.date = selectedDate;
      if (selectedAmount != null)
        this.currentAppointment.amount = selectedAmount;
      this.currentAppointment.currency = this.selectedCurrency;
      this.currentAppointment.purposeOfVisitTitle = 'Online Consultation';
      this.currentAppointment.purposeOfVisitDescription =
        'Chat and Video (Valid for 72 hrs)';
      this.currentAppointment.sessionType = this.currentSessionType;
      this.currentAppointment.modeOfAppointment = 'docvita';
      this.currentAppointment.initiator = new ActionDoneBy();
      this.currentAppointment.initiator.updatedById =
        this.currentHeroInfo.docvitaId;
      this.currentAppointment.initiator.updatedByName =
        this.currentHeroInfo.name;
      this.currentAppointment.initiator.updatedOn = new Date().getTime();
      if (this.selectedCurrency != Currency.INR) {
        this.currentAppointment.providerDisplayAmount = this.providerDisplayAmount;
        this.currentAppointment.providerDisplayCurrency = this.providerDisplayCurrency;

        this.currentAppointment.acceptedAmount = new CountrywiseFeeCalc().fee(
          this.doctorProfile.onlineConsultationConfig.fee,
          null,
          this.doctorProfile.getMasterSpecialization()
        ).amount;
        this.currentAppointment.acceptedAmountCurrency = new CountrywiseFeeCalc().fee(
          this.doctorProfile.onlineConsultationConfig.fee,
          null,
          this.doctorProfile.getMasterSpecialization()
        ).unit;
      }
      this.currentAppointment.sessionType = this.currentSessionType;
      this.currentAppointment.paymentId = firebase.database().ref().push().key;
      this.currentAppointment.paymentSource = TransactionSource.PartnerWallet;
      this.currentAppointment.paymentDoneOn = new Date().getTime();
      this.currentAppointment.totalAmountPaid = selectedAmount;
      const [firstKey] = this.patientProfile.partners.keys()
      this.currentAppointment.partnerId = firstKey;

      let body = classToPlain(this.currentAppointment);
      body['paymentIntent'] = 'payNow';
      if (this.payViaWallet) body['payViaWallet'] = this.payViaWallet;
      body['platform'] = 'whatsapp';
      body = { ...body, ...this.setAppointmentTypeAndInPersonDetails() }

      let patientInfo = {};
      if (
        this.patientProfile &&
        this.patientProfile.docvitaId &&
        this.patientProfile.name &&
        this.patientProfile.primaryContactNumber &&
        this.patientProfile.primaryContactNumber.countryCode &&
        this.patientProfile.primaryContactNumber.number
      ) {
        patientInfo = {
          patientId: this.patientProfile.docvitaId,
          patientName: this.patientProfile.name,
          patientGender: this.patientProfile.gender
            ? this.patientProfile.gender
            : '',
        };

        if (
          this.patientProfile.contactNumbers &&
          this.patientProfile.contactNumbers.size > 0
        ) {
          let contactNumber = this.patientProfile.getPrimaryContactNumbers()[0];
          if (contactNumber.number && contactNumber.number.length > 0) {
            patientInfo['patientPrimaryContactNumber'] = {
              countryCode: contactNumber.countryCode,
              number: contactNumber.number,
            };
          }
        } else if (
          this.patientProfile.primaryContactNumber &&
          this.patientProfile.primaryContactNumber.number?.length > 0
        ) {
          patientInfo['patientPrimaryContactNumber'] = {
            countryCode: this.patientProfile.primaryContactNumber.countryCode,
            number: this.patientProfile.primaryContactNumber.number,
          };
        }

        if (
          this.patientProfile.emails &&
          this.patientProfile.emails.size > 0 &&
          this.patientProfile.getPrimaryEmail()
        ) {
          patientInfo['patientPrimaryEmail'] =
            this.patientProfile.getPrimaryEmail().email;
        } else {
          patientInfo['patientPrimaryEmail'] = this.patientProfile.primaryEmail
            ? this.patientProfile.primaryEmail
            : '';
        }

        body['patientsArray'] = [patientInfo];
      }
      // console.log(body);
      this.btnLoader = true;
      this.apptClashModal
        .openConflictPromise(
          body['doctorId'],
          body['date'],
          body['timeslot'],
          body['doctorName']
        )
        .then((hasConflicts) => {
          if (hasConflicts) {
            this.btnLoader = false;
            this.apptClashModal.openModal();
          } else {
            this.continueBookingAppointment(body);
          }
        })
        .catch((err) => {
          this.continueBookingAppointment(body);
        });
    }
  }

  setAppointmentTypeAndInPersonDetails() {
    let json: any = {}
    if (this.isInPerson && this.practiceSelection.key) {
      json["type"] = "inPerson";
      json["locationId"] = this.practiceSelection.key;
    } else {
      json["type"] = "online";
    }
    return json;
  }

  bookAppointmentWithSelectedPlan() {
    if (
      !this.appointmentFormValidation() ||
      this.patientProfile == null ||
      this.doctorProfile == null ||
      this.paymentLinkForm['amount'] == null ||
      this.selectedMembershipPlan == null ||
      !this.selectedMembershipPlan.planId ||
      !this.selectedMembershipPlan.planPurchaseId ||
      !this.currentHeroInfo ||
      !this.currentHeroInfo.docvitaId ||
      !this.currentHeroInfo.name
    ) {
      this._toastrService.error('Something is missing to book the appointment');
    } else {
      this.btnLoader = true;
      let selectedTime = this.appointmentBookingForm['time'];
      let selectedDate = this.appointmentBookingForm['date'];
      let selectedSlotLength = +this.appointmentBookingForm['slotLength'];
      let selectedAmount = +this.paymentLinkForm['amount'];
      this.currentAppointment = new Appointment();
      let _timeslot =
        selectedTime +
        '-' +
        dayjs(new Date(selectedDate + ' ' + selectedTime))
          .add(selectedSlotLength, 'minutes')
          .format('HH:mm');
      this.currentAppointment.timeslot = _timeslot;
      this.currentAppointment.doctorId = this.doctorProfile.id;
      this.currentAppointment.organisationId = this.doctorProfile.id;
      this.currentAppointment.doctorName = this.doctorProfile.fullName();
      this.currentAppointment.organisationName = this.doctorProfile.fullName();
      this.currentAppointment.date = selectedDate;
      if (selectedAmount != null)
        this.currentAppointment.amount = selectedAmount;
      this.currentAppointment.currency = this.selectedCurrency;
      this.currentAppointment.purposeOfVisitTitle = 'Online Consultation';
      this.currentAppointment.purposeOfVisitDescription =
        'Chat and Video (Valid for 72 hrs)';
      this.currentAppointment.modeOfAppointment = 'docvita';
      this.currentAppointment.initiator = new ActionDoneBy();
      this.currentAppointment.initiator.updatedById =
        this.currentHeroInfo.docvitaId;
      this.currentAppointment.initiator.updatedByName =
        this.currentHeroInfo.name;
      this.currentAppointment.initiator.updatedOn = new Date().getTime();
      if (this.selectedCurrency != Currency.INR) {
        this.currentAppointment.providerDisplayAmount = this.providerDisplayAmount;
        this.currentAppointment.providerDisplayCurrency = this.providerDisplayCurrency;

        this.currentAppointment.acceptedAmount = new CountrywiseFeeCalc().fee(
          this.doctorProfile.onlineConsultationConfig.fee,
          null,
          this.doctorProfile.getMasterSpecialization()
        ).amount;
        this.currentAppointment.acceptedAmountCurrency = new CountrywiseFeeCalc().fee(
          this.doctorProfile.onlineConsultationConfig.fee,
          null,
          this.doctorProfile.getMasterSpecialization()
        ).unit;
      }
      this.currentAppointment.paymentId = firebase.database().ref().push().key;
      this.currentAppointment.paymentSource = TransactionSource.PlanPurchaseWallet;
      this.currentAppointment.paymentDoneOn = new Date().getTime();
      this.currentAppointment.totalAmountPaid = selectedAmount;

      let body = classToPlain(this.currentAppointment);
      body['paymentIntent'] = 'payNow';
      body['platform'] = 'whatsapp';
      body = { ...body, ...this.setAppointmentTypeAndInPersonDetails() }
      body['getStartedSessionId'] = this.selectedMembershipPlan && this.selectedMembershipPlan.getStartedSessionId ? this.selectedMembershipPlan.getStartedSessionId : null
      body['planPurchaseId'] = this.selectedMembershipPlan?.planPurchaseId;
      body['sessionType'] = this.selectedMembershipPlan?.planDetails?.sessionType;

      let patientInfo = {};
      if (
        this.patientProfile &&
        this.patientProfile.docvitaId &&
        this.patientProfile.name &&
        this.patientProfile.primaryContactNumber &&
        this.patientProfile.primaryContactNumber.countryCode &&
        this.patientProfile.primaryContactNumber.number
      ) {
        patientInfo = {
          patientId: this.patientProfile.docvitaId,
          patientName: this.patientProfile.name,
          patientGender: this.patientProfile.gender
            ? this.patientProfile.gender
            : '',
        };

        if (
          this.patientProfile.contactNumbers &&
          this.patientProfile.contactNumbers.size > 0
        ) {
          let contactNumber = this.patientProfile.getPrimaryContactNumbers()[0];
          if (contactNumber.number && contactNumber.number.length > 0) {
            patientInfo['patientPrimaryContactNumber'] = {
              countryCode: contactNumber.countryCode,
              number: contactNumber.number,
            };
          }
        } else if (
          this.patientProfile.primaryContactNumber &&
          this.patientProfile.primaryContactNumber.number?.length > 0
        ) {
          patientInfo['patientPrimaryContactNumber'] = {
            countryCode: this.patientProfile.primaryContactNumber.countryCode,
            number: this.patientProfile.primaryContactNumber.number,
          };
        }

        if (
          this.patientProfile.emails &&
          this.patientProfile.emails.size > 0 &&
          this.patientProfile.getPrimaryEmail()
        ) {
          patientInfo['patientPrimaryEmail'] =
            this.patientProfile.getPrimaryEmail().email;
        } else {
          patientInfo['patientPrimaryEmail'] = this.patientProfile.primaryEmail
            ? this.patientProfile.primaryEmail
            : '';
        }

        body['patientsArray'] = [patientInfo];
      }

      this.btnLoader = true;
      this.apptClashModal
        .openConflictPromise(
          body['doctorId'],
          body['date'],
          body['timeslot'],
          body['doctorName']
        )
        .then((hasConflicts) => {
          if (hasConflicts) {
            this.btnLoader = false;
            this.apptClashModal.openModal();
          } else {
            this.continueBookingAppointment(body);
          }
        })
        .catch((err) => {
          this.continueBookingAppointment(body);
        });
    }
  }

  checkForOrderInfoAndContinue(body) {
    if (
      this.payInfoform.value['paymentId'] &&
      this.payInfoform.value['paymentId'].length > 0 &&
      this.payInfoform.value['paymentSource'] == 'Razorpay'
    ) {
      this.orderDataDisplay = null;
      this.orderIdMatched = false;
      this.orderCheckLoader = true;
      this.fetchRazorpayTransactionInfo()
        .then((resp) => {
          if (resp && resp['msg'] && resp['msg'] == 'success') {
            body['paymentDoneOn'] = +resp['paymentDoneOn'];
            this.continueBookingAppointment(body);
          } else if (resp && resp['msg'] && resp['msg'] == 'incorrect-amount') {
            this._toastrService.error(
              'Order amount and booking amount do not match!'
            );
            this.btnLoader = false;
            this.orderCheckLoader = false;
          } else {
            this._toastrService.error('No matching orders for orderID!');
            this.btnLoader = false;
            this.orderCheckLoader = false;
          }
        })
        .catch((err) => {
          this._toastrService.error('No matching orders orderID!');
          this.btnLoader = false;
          this.orderCheckLoader = false;
        });
    } else {
      this.continueBookingAppointment(body);
    }
  }

  checkForOrderInfoManually() {
    this.orderCheckLoader = true;
    this.fetchRazorpayTransactionInfo()
      .then(() => {
        this.orderCheckLoader = false;
      })
      .catch((err) => {
        this.orderCheckLoader = false;
      });
  }

  continueBookingAppointment(body) {
    this._appointmentService
      .bookAppointment(body)
      .then((res) => {
        if (res) {
          // console.log(res);
          this.btnLoader = false;
          this._toastrService.success('🎉Appointment booked!');
          this.modalService.hide();
        }
      })
      .catch((err) => {
        console.log(err);
        this._toastrService.error('⚠ Booking failed!');
        this.btnLoader = false;
      });
  }

  setFormPurpose(purpose: string) {
    this.formPurpose = purpose;
    let displayName = purpose.split('-').join(' ');
    this.setCurrentStepName(displayName);
    this.resetButPreservePatientDetails();
  }
  setCurrentStepName(stepName: string) {
    this.currentStepName = stepName;
  }
  setPaymentInfoForm() {
    this.payViaWallet = false;
    let date = dayjs(new Date()).format('YYYY-MM-DD');
    let time = dayjs(new Date()).format('HH:mm');
    this.payInfoform = new UntypedFormGroup({
      paymentId: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(4),
      ]),
      paymentSource: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(1),
      ]),
      paymentDoneDate: new UntypedFormControl(date, [
        Validators.required,
        Validators.minLength(4),
      ]),
      paymentDoneTime: new UntypedFormControl(time, [
        Validators.required,
        Validators.minLength(4),
      ]),
      // totalAmountPaid: new FormControl('',[Validators.required,Validators.minLength(1)])
    });
  }

  paymentSourceChanged(source: string) {
    this.orderDataDisplay = null;
    this.payInfoform.patchValue({
      paymentSource: source,
    });
  }

  get selectedPaymentSource(): string {
    return this.payInfoform.get('paymentSource').value;
  }
  validatePaymentForm(): boolean {
    let _ = this.payInfoform.value;
    if (this.payInfoform.valid) {
      return true;
    } else {
      return false;
    }
  }
  fetchRazorpayTransactionInfo() {
    return new Promise((resolve, reject) => {
      if (this.payInfoform.value['paymentId']) {
        this.orderDataDisplay = null;
        this.orderIdMatched = false;
        this._ledgerService
          .getOrderInfoRZP(this.payInfoform.value['paymentId'])
          .then((val) => {
            if (val) {
              let creditAmount = +val['amount_paid'] / 100;
              let createdAt = +val['created_at'] * 1000;
              if (
                creditAmount &&
                creditAmount != +this.paymentLinkForm['amount']
              ) {
                let displaytext =
                  '=> Credited  : ' +
                  creditAmount +
                  ' | Credited On : ' +
                  dayjs(+createdAt).format('YYYY-MM-DD, hh:mma');
                this.orderDataDisplay = displaytext;
                this.orderIdMatched = false;
                // this._toastrService.error(
                //   'Order amount and booking amount do not match!'
                // );
                resolve({
                  msg: 'incorrect-amount',
                  creditAmount: creditAmount,
                  createdAt: createdAt,
                });
              } else {
                let displaytext =
                  '=> Credited  : ' +
                  creditAmount +
                  ' | Credited On : ' +
                  dayjs(+createdAt).format('YYYY-MM-DD, hh:mma');
                this.orderDataDisplay = displaytext;
                this.orderIdMatched = true;
                resolve({ msg: 'success', paymentDoneOn: createdAt });
              }
            } else {
              this.orderDataDisplay = 'No matching order!';
              this.orderIdMatched = false;
              resolve(null);
            }
          })
          .catch((err) => {
            this.orderDataDisplay = 'No matching order!';
            this.orderIdMatched = false;
            resolve(null);
          });
      } else {
        this.orderDataDisplay = 'No matching order!';
        this.orderIdMatched = false;
        resolve(null);
      }
    });
  }
  setOrderIdValues(event) {
    if (event && event.target.value) {
      let val = event.target.value + '';
      val = val.trim();
      this.payInfoform.patchValue({
        paymentId: val,
      });
      this.orderIdFetchSub.next(val);
    } else {
      this.orderDataDisplay = '';
    }
  }

  get DateInDay() {
    let date = this.appointmentBookingForm['date'];
    if (date) {
      return dayjs(date).format('dddd');
    } else {
      return '';
    }
  }

  setCurrency(val: Currency) {
    this.selectedCurrency = val;
  }

  defaultAmountSetter() {
    if (this.selectedCurrency == Currency.INR && this.doctorProfile && this.doctorProfile.id) {
      this.paymentLinkForm['amount'] = this.doctorProfile
        .onlineConsultationConfig.fee
        ? this.doctorProfile.onlineConsultationConfig.fee
        : null;
    } else {
      this.paymentLinkForm['amount'] = null;
    }
  }


  get currencySymbol() {
    return new CountrywiseFeeCalc().currencyToSymbol(this.selectedCurrency);
  }

  get currencyProviderSymbol() {
    return new CountrywiseFeeCalc().currencyToSymbol(this.providerDisplayCurrency);
  }

  get checkForPartnership() {
    if (this.patientProfile && this.patientProfile.partners && this.patientProfile.partners.size > 0) {
      return true
    } else {
      return false;
    }
  }

  getWalletBalance(wallet: Map<Currency, number>) {
    if (wallet.size > 0) {
      this.wallet = wallet;
    } else {
      this.wallet = new Map<Currency, number>();
    }
  }

  setProviderCurrency(val: Currency) {
    this.providerDisplayCurrency = val;
  }

  selectedPlan(event: MembershipPlanPurchased) {
    if (event && event.planId) {
      this.selectedMembershipPlan = event
      console.log(event)
      this.paymentLinkForm['amount'] = event?.planDetails?.appointmentBookingAmountAndCurrency?.amount;
      this.selectedCurrency = event?.planDetails?.appointmentBookingAmountAndCurrency?.currency;
      this.paymentLinkForm['currency'] = event?.planDetails?.appointmentBookingAmountAndCurrency?.currency;
      this.appointmentBookingForm['slotLength'] = event?.planDetails?.sessionDurationInMinutes;
      this.providerDisplayAmount = event?.planDetails?.appointmentBookingAmountAndCurrency?.providerDisplayAmount;
      this.providerDisplayCurrency = event?.planDetails?.appointmentBookingAmountAndCurrency?.providerDisplayCurrency;
      this.paymentSourceChanged(TransactionSource.PlanPurchaseWallet)
    } else {
      this.selectedMembershipPlan = new MembershipPlanPurchased();
      this.paymentLinkForm['amount'] = null;
      this.selectedCurrency = Currency.INR;
      this.paymentLinkForm['currency'] = Currency.INR;
      this.appointmentBookingForm['slotLength'] = null;
      this.providerDisplayAmount = null;
      this.providerDisplayCurrency = Currency.INR;
      this.paymentSourceChanged(null)
    }
  }

  get isPlanSelected() {
    if (this.selectedMembershipPlan && this.selectedMembershipPlan.planId) {
      return true
    } else {
      return false;
    }
  }

  async getFeeForSessionType() {
    const amountWithCurrency = await this._patientRelationshipService.getFeeSuggestion(this.patientProfile.docvitaId, this.doctorProfile.id, this.currentSessionType)
    // console.log("getFeeSuggestion", amountWithCurrency);
    if (amountWithCurrency) {
      this.paymentLinkForm['amount'] = amountWithCurrency.amount;
      this.paymentLinkForm['currency'] = amountWithCurrency.currency;
    } else {
      const servicewiseFeeMap =
        this.doctorProfile.onlineConsultationConfig.servicewiseFeesMap;
      const serviceFee = servicewiseFeeMap.get(
        this.currentSessionType
      );
      if (serviceFee && serviceFee.isActive && this.formPurpose != 'free-followup') {
        const fee = serviceFee.fee;
        const currency = serviceFee.currency;
        const cc = new CountrywiseFeeCalc().currencyToCountry(currency);
        const countryWiseFee = new CountrywiseFeeCalc().fee(fee, cc, this.doctorProfile.getMasterSpecialization());
        this.paymentLinkForm['amount'] = countryWiseFee.amount;
        this.paymentLinkForm['currency'] = countryWiseFee.unit;
      }
    }
  }

  get getSessionTypeWiseFee() {
    const servicewiseFeeMap =
      this.doctorProfile.onlineConsultationConfig.servicewiseFeesMap;
    let feesForDisplay = '';
    for (const i of this.sessionTypeOptions) {
      const serviceFee = servicewiseFeeMap.get(
        ServiceTypes[i]
      );
      if (serviceFee && serviceFee.isActive) {
        const fee = serviceFee.fee;
        const currency = serviceFee.currency;
        const cc = new CountrywiseFeeCalc().currencyToCountry(currency);
        const countryWiseFee = new CountrywiseFeeCalc().fee(fee, cc, this.doctorProfile.getMasterSpecialization());
        // this.paymentLinkForm['amount'] = countryWiseFee.amount;
        // this.paymentLinkForm['currency'] = countryWiseFee.unit;
        feesForDisplay += i + ': ' + countryWiseFee.amount + ' ' + countryWiseFee.unit + ' | '
      }
    }
    return feesForDisplay.slice(0, -3);
  }

}
