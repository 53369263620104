import { Expose } from "class-transformer";

export class ProfileLinks {
  @Expose() ownWebsiteUrl: string;
  @Expose() googleProfileUrl: string;
  @Expose() practoProfileUrl: string;
  @Expose() facebookProfileUrl: string;
  @Expose() justdialProfileUrl: string;
  @Expose() instagramProfileUrl: string;
  @Expose() linkedinProfileUrl: string;
  @Expose() twitterProfileUrl: string;
  @Expose() myupcharProfileUrl: string;
  @Expose() docprimeProfileUrl: string;
  @Expose() kivihealthProfileUrl: string;
  @Expose() denteeProfileUrl: string;
}
