import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TextBlurService {
  blurState: BehaviorSubject<{}>;
  constructor() { 
    this.blurState = new BehaviorSubject(null);
    let blurState = localStorage.getItem('isBlurredText')
    if(blurState&&JSON.parse(blurState)===true){
      this.blurState['isBlurred'] = true;
    }else{
      this.blurState['isBlurred'] = false;
    }
  }

  setBlurState(bool){
    this.blurState['isBlurred'] = bool;
    localStorage.setItem('isBlurredText', JSON.stringify(bool));
  }

  getBlurState():boolean{
    let state = localStorage.getItem('isBlurredText')
    if(state!=null&&JSON.parse(state)===true){
      return true;
    }else{
      return false;
    }
  }

  
}
