import { Expose } from "class-transformer"
export class DiscountDetails{
    @Expose() leadId:string;
    @Expose() appointmentId:string;
    @Expose() clientId:string;
    @Expose() providerId:string;
    @Expose() discountCode:string;
    @Expose() discountRate:number;
    @Expose() finalAmount:number;
    @Expose() currency:string;
    @Expose() createdOn:number;
    @Expose() id:string;
}