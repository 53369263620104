import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatWindowComponent } from './chat-window/chat-window.component';
import { ChatMessageCellComponent } from './chat-message-cell/chat-message-cell.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ChatFileUploaderComponent } from './chat-file-uploader/chat-file-uploader.component';

@NgModule({
  declarations: [
    ChatWindowComponent,
    ChatMessageCellComponent,
    ChatFileUploaderComponent,
  ],
  imports: [
    CommonModule,
    TabsModule,
    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule,
    TooltipModule,
    ScrollingModule,
  ],
  exports: [
    ChatWindowComponent,
    ChatMessageCellComponent,
    ChatFileUploaderComponent,
  ],
  providers: [],
})
export class ChatSharedModule {}
