<div class="card form-row mt-3 mb-3">
    <div class="card-header bg-transparent d-flex">
      <h6>Emails</h6>
      <button
        class="btn btn-sm btn-primary ms-auto"
        (click)="addEmailField()"
      >
        +
      </button>
    </div>
    <div class="card-body">
      <div
        class="form-group"
        *ngFor="
          let _email of emails;
          let x = index;
          "
      >
        <label [for]="'EmailInput' + x"
          >Email {{ x + 1 }}</label
        >
        <div class="d-flex">
          <input
            type="email"
            class="form-control"
            [id]="'EmailInput' + x"
            [(ngModel)]="_email.email"
            placeholder="Email"
          />
          <button
            class="btn btn-sm btn-danger ms-1"
            (click)="deleteEmailField(x)"
          >
            &times;
          </button>
        </div>
        <div class="form-check mt-1">
          <input class="form-check-input" type="checkbox" [id]="'_email'+i" [(ngModel)]="_email.isPrimary">
          <label class="form-check-label ms-1 text-muted" [for]="'_email'+i">Is Primary Email?</label>
        </div>
      </div>
    </div>
  </div>

<!-- <div
  class="modal fade"
  id="editEmails"
  tabindex="-1"
  aria-labelledby="editEmailsLabel"
  aria-hidden="true"
  style="z-index:9999;"
>
  <div class="modal-dialog modal-lg modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="editEmailsLabel">
          Email Info for {{displayEmail}}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <dl>
            <dt>Source of information:</dt>
            <dd class="mt-2">
                <input
                type="text"
                class="form-control"
                id='source'
                placeholder="Source url"
                [(ngModel)]="source"
                />
            </dd>
        </dl>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Close
        </button>
        <button type="button" class="btn btn-primary" (click)="saveChangesOfModal()">
          Save changes
        </button>
      </div>
    </div>
  </div>
</div> -->
