import { Expose } from "class-transformer";
import { ActionRelatedTo, ChatMessageStatus } from "../Enums/chatEnum";

export class LeadBooleanAction {
  @Expose() bool: boolean;
  @Expose() updatedById: string;
  @Expose() updatedByName: string;
  @Expose() updatedOn: number;

  @Expose() relatedTo: ActionRelatedTo; //client / provider / docvita_team 
  @Expose() messageStatus: ChatMessageStatus; //sent / delivered / seen
  @Expose() messageId: string;

  @Expose() responseCaptured: ResponseCaptured[]; //Multiple actions
}

export class ResponseCaptured {
  @Expose() responseText: string;
  @Expose() createdOn: number;
}