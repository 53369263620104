import { Person } from "../Person/Person";
import { Expose, Transform, plainToClass, Type, Exclude } from "class-transformer";
import { OneUserType } from '../../Enums/Enums';
export class AccountConnectionMeta {
  @Expose() profileId: string;
  @Expose() connectedOn: number;
  @Expose() connectedById: string;//Optional
  @Expose() connectedByName: string;//Optional
  @Expose() type: string; //Profile Type
  @Expose() roles: string[] = [];
}

export class ProviderLogin extends Person {
  @Expose() type: string;
  @Expose() blocked: boolean;
  // @Expose() createdOn: number;
  @Expose() userType: OneUserType;
  @Expose()
  @Transform(
    (value) => {
      if (value) {
        let map = new Map<string, AccountConnectionMeta>();
        for (let entry of Object.entries(value))
          map.set(entry[0], plainToClass(AccountConnectionMeta, entry[1]));
        return map;
      } else {
        return new Map<string, AccountConnectionMeta>();
      }
    },
    { toClassOnly: true }
  )
  connectedAccounts: Map<string, AccountConnectionMeta> = new Map();

  @Expose()
  @Transform(
    (value) => {
      if (value) {
        let map = new Map<string, AccountConnectionMeta>();
        for (let entry of Object.entries(value))
          map.set(entry[0], plainToClass(AccountConnectionMeta, entry[1]));
        return map;
      } else {
        return new Map<string, AccountConnectionMeta>();
      }
    },
    { toClassOnly: true }
  )
  profiles: Map<string, AccountConnectionMeta> = new Map();
}

// @Type(() => OrganisationConnection)
// organisationConnections: Map<string, OrganisationConnection>;
