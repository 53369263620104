<div class="position-relative">
  <label class="file" *ngIf="!listingProfile.photoUrl">
    <span style="font-size: 14px">+</span> <br />
    <span style="font-size: 8px">Upload</span><br />
    <span style="font-size: 8px">Photograph</span>
    <input
      type="file"
      id="files"
      (click)="clearFileSelection()"
      (change)="fileChangeEvent($event)"
      accept="image/x-png,image/jpeg"
      style="height: 150px"
    />
  </label>
  <div
    style="z-index: 600; position: relative"
    *ngIf="listingProfile.photoUrl"
  >
    <input
      type="file"
      id="files"
      (change)="fileChangeEvent($event)"
      accept="image/x-png,image/jpeg"
      class="doc-image"
      style="background-color: tomato !important"
    />
    <img
      [src]="currentPhotoUrl"
      alt="Hero Image"
      class="doc-image"
      type="file"
    />
  </div>
  <div
    style="
      position: absolute;
      bottom: 4px;
      right: 0;
      z-index: 700;
      margin-right: 4px;
    "
    *ngIf="listingProfile.photoUrl"
  >
    <button
      style="background-color: white; padding: 4px"
      class="btn btn-sm btn-outline-danger border-0"
    >
      <span
        class="material-icons"
        style="font-size: 18px"
        (click)="onDeleteConfirmation('#deleteImageModal')"
      >
        delete
      </span>
    </button>
  </div>
</div>

<div
  class="modal fade"
  id="deleteImageModal"
  tabindex="-1"
  aria-labelledby="deleteImageModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteImageModalLabel">
          Are you sure you want to delete?
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Close
        </button>
        <button type="button" class="btn btn-danger" (click)="deleteImage()">
          Delete
        </button>
      </div>
    </div>
  </div>
</div>

<!-- <input type="file" (change)="fileChangeEvent($event)" /> -->

<!-- <img [src]="croppedImage" /> -->

<div
  class="modal fade"
  id="cropModal"
  tabindex="-1"
  style="z-index: 2000"
  aria-labelledby="cropModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="cropModalLabel">
          Doctor Profile Image Upload
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeModal()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="d-flex modal-body">
        <!-- <div class="col-3">
          <span>Profile Photo Guidelines</span>
          <ul>
            <li>Remove all </li>
          </ul>
        </div> -->
        <div class="col-12 text-center">
          <button
            class="btn btn-sm btn-outline-secondary"
            (click)="rotateLeft()"
          >
            Rotate left
          </button>
          <button
            class="btn btn-sm btn-outline-secondary ms-3"
            (click)="rotateRight()"
          >
            Rotate right
          </button>
          <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="false"
            format="jpeg"
            imageQuality="80"
            [output]="'base64'"
            [transform]="transform"
            [canvasRotation]="canvasRotation"
            (imageCropped)="imageCropped($event)"
            (cropperReady)="cropperReady()"
            (loadImageFailed)="loadImageFailed()"
          ></image-cropper>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          (click)="closeModal()"
        >
          Close
        </button>
        <button type="button" class="btn btn-primary" (click)="saveChanges()">
          Save changes
        </button>
      </div>
    </div>
  </div>
</div>
