import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { LeadFollowUp } from 'src/app/shared/models-ts/Entities/LeadFollowUp';
import { classToPlain } from 'class-transformer';
import { LeadFollowUpShort } from 'src/app/shared/models-ts/Entities/LeadFollowUpShort';

@Injectable({
    providedIn: 'root',
})

export class FollowupService{
    getFollowupList(from: string,to: string,status){
        let ref = firebase.firestore().collection('patient_leads')
            .orderBy('consultationInfo.followupDate','desc'); 
        if(from&&from!=undefined&&from.length>9){
            // let fromDate = new Date(from+' 00:00').getTime();
            ref = ref.where('consultationInfo.followupDate','>=',from);
        }
        if(to&&to!=undefined&&to.length>9){
            // let toDate = new Date(to+' 23:59').getTime();
            ref = ref.where('consultationInfo.followupDate','<=',to);
        }
        if(status&&status!=undefined){
            ref = ref.where('consultationInfo.followupStatus','==',status);
        }
        return ref;
    }
    updateFollowUp(jsonData){
        if(jsonData&&jsonData["id"]){
            let shortFollowUp = new LeadFollowUpShort();
            shortFollowUp.id = jsonData["id"];
            shortFollowUp.followUpDate = jsonData["followUpDate"];
            shortFollowUp.status = jsonData['status']; 
            
            let promises = []
            promises.push(firebase.firestore().collection('patient_followups').doc(jsonData['id']).set(jsonData,{merge:true}));
            if(jsonData["linkedLeadId"]!=undefined){
                let leadUpdate = {}
                leadUpdate["followUps"]={}
                leadUpdate["followUps"][shortFollowUp.id] = JSON.parse(JSON.stringify(classToPlain(shortFollowUp)));
                promises.push(firebase.firestore().collection("patient_leads").doc(jsonData["linkedLeadId"]).set(leadUpdate,{merge:true}));
            }
            
            return Promise.all(promises);
        }else{
            return Promise.reject();
        }
    }
}