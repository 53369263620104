<div class="w-100">
    <div class="d-flex w-100 justify-content-start" *ngIf="showProfileDetails">
        <div class="card p-1 px-3 m-1 w-50" *ngIf="showProviderProfile&&provider&&provider.id" style="font-size: 0.9em;">
            <b>Doctor Profile:</b>
           <div>{{provider.fullName()}} {{provider.getMasterSpecializationDisplay()?'('+provider.getMasterSpecializationDisplay()+')':""}}</div> 
           <div (click)="copyTheDamnThing(provider.id)">Id: {{provider.id}}</div> 
           <div>
            <span *ngIf="provider.isProfileClaimed" class="badge bg-info ms-1 me-1">Claimed</span>
            <span *ngIf="provider.showInListing" class="badge bg-primary">Listed</span>
           </div>
           <div>
            Contacts:
            <div *ngIf="provider.contactNumbers&&provider.contactNumbers.size>0">
                <div  *ngFor="let c of provider.getAllContactNumbers()" class="ms-2 d-flex align-items-center pii-text">
                    {{c?'+'+c.countryCode+'-'+c.number:""}} {{c.isWhatsappContactNumber?'(W)':""}}
                    <!-- <span *ngIf="c.isWhatsappContactNumber" class="badge bg-primary ms-2 pointer" (click)="tryToOpenWhatsAppChatForProvider(c)">Message</span> -->
                    <button type="button" class="
                        btn btn-sm btn-outline-primary
                        d-flex
                        align-items-center
                        border-0
                        mx-1
                    "
                    *ngIf="c.isWhatsappContactNumber"
                    (click)="tryToOpenWhatsAppChatForProvider(c)">
                    <i style="font-size: 15px" class="material-icons me-1">message</i>
                        Message
                    </button>
                </div>
            </div>
           </div>
        </div>
        <div class="card p-1 px-3 m-1 w-50" *ngIf="showPatientProfile&&patient&&patient.docvitaId" style="font-size: 0.9em;">
            <b>Patient Profile:</b>
           <div class="pii-text">{{patient.name?patient.name:""}}</div> 
           <div (click)="copyTheDamnThing(patient.docvitaId)">Id: {{patient.docvitaId}}</div> 
           <div>Gender: {{patient.gender?patient.gender:'-'}}</div>
            <ng-container *ngIf="patient.contactNumbers&&patient.contactNumbers.size>0">
                <div>
                    Contacts:
                    <div  *ngFor="let c of patient.getAllContactNumbers()" class="ms-2 d-flex align-items-center pii-text">
                        {{c?'+'+c.countryCode+'-'+c.number:""}} {{c.isWhatsappContactNumber?'(W)':""}}
                        <!-- <span *ngIf="c.isWhatsappContactNumber" class="badge bg-primary ms-2 pointer" (click)="tryToOpenWhatsAppChatForProvider(c)">Message</span> -->
                        <button type="button" class="
                            btn btn-sm btn-outline-primary
                            d-flex
                            align-items-center
                            border-0
                            mx-1
                        "
                        *ngIf="c.isWhatsappContactNumber"
                        (click)="tryToOpenWhatsAppChatForNumber(c)">
                        <i style="font-size: 15px" class="material-icons me-1">message</i>
                            Message
                        </button>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!(patient.contactNumbers&&patient.contactNumbers.size>0)">
                <div style="width: fit-content;" class="d-flex align-items-center">
                    <span (click)="copyPatientNumber(patient)" class="pii-text">Contact: {{patientContactInfo()?'+'+patientContactInfo().countryCode+'-'+patientContactInfo().number:""}}</span>
                    <!-- <span class="badge bg-primary ms-2 pointer" >Message</span> -->
                    <button type="button" class="
                        btn btn-sm btn-outline-primary
                        d-flex
                        align-items-center
                        border-0
                        mx-1
                    "
                    (click)="tryToOpenWhatsAppChatForPatient()">
                    <i style="font-size: 15px" class="material-icons me-1">message</i>
                    Message
                    </button>
                </div>
            </ng-container>
            
        </div>
    </div>
    <div class="d-flex flex-column m-2" *ngIf="showSections && sections && sections.length > 0">
        <div *ngFor="let section of sections">
            <ng-container *ngTemplateOutlet="
                checklistSection;
                context: { section: section }
            ">
            </ng-container>
        </div>
    </div>
</div>


<ng-template #checklistSection let-section="section">
    <div class="d-flex flex-column py-2 border-top">
        <span style="font-size: 14px; font-weight: 450">{{
            section.sectionTitle
            }}</span>
        <ng-container *ngFor="let item of section.entities">
            <ng-container *ngTemplateOutlet="
            checklistSectionEntity;
            context: { checkListEntity: item }
          "></ng-container>
        </ng-container>
    </div>
</ng-template>

<ng-template #checklistSectionEntity let-checkListEntity="checkListEntity">
    <div class="d-flex flex-row align-items-center">
        <ng-container *ngTemplateOutlet="
          radioButtonTemplate;
          context: { key: checkListEntity.key }
        ">
        </ng-container>
        <span style="font-size: 14px; font-weight: 400">
            {{ checkListEntity.title }}
        </span>
        <button type="button" class="
          btn btn-sm btn-outline-primary
          d-flex
          align-items-center
          border-0
          mx-1
        " (click)="copyTheDamnThing(checkListEntity.message)">
            <i style="font-size: 15px" class="material-icons me-1">content_copy</i>
        </button>
        <ng-container *ngIf="checkListEntity.type&&checkListEntity.type=='client'">
            <ng-container *ngIf="!checkListEntity.newWAButton">
                <button  class="btn btn-sm btn-outline-success border-0 mx-1 d-flex align-items-center" placement="top" [tooltip]="'Patient: '+number" *ngFor="let number of patientWaNumbers" (click)="tryToOpenWhatsAppChatForNumber(number,checkListEntity.message)">
                    <i style="font-size: 15px" class="material-icons me-1">message</i>
                </button>
            </ng-container>
            <ng-container *ngIf="checkListEntity.newWAButton">
                <button class="btn btn-sm btn-outline-success border-0 mx-1 d-flex align-items-center" placement="top" [tooltip]="'WA API: Patient: '+number" *ngFor="let number of patientWaNumbers" (click)="sendViaWaApi(checkListEntity,number)">
                    <i style="font-size: 15px" class="material-icons me-1">message</i>
                </button>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="checkListEntity.type&&checkListEntity.type=='provider'">
            <ng-container *ngIf="!checkListEntity.newWAButton">
                <button class="btn btn-sm btn-outline-primary border-0 mx-1 d-flex align-items-center" placement="top" [tooltip]="'Doctor: '+number" *ngFor="let number of providerWaNumbers" (click)="tryToOpenWhatsAppChatForNumber(number,checkListEntity.message)">
                    <i style="font-size: 15px" class="material-icons me-1">message</i>
                </button>
            </ng-container>
            <ng-container *ngIf="checkListEntity.newWAButton">
                <button class="btn btn-sm btn-outline-primary border-0 mx-1 d-flex align-items-center" placement="top" [tooltip]="'WA API: Doctor: '+number" *ngFor="let number of providerWaNumbers" (click)="sendViaWaApi(checkListEntity,number)">
                    <i style="font-size: 15px" class="material-icons me-1">message</i>
                </button>
            </ng-container>
        </ng-container>
    </div>
</ng-template>

<ng-template #radioButtonTemplate let-key="key">
    <div class="pointer d-flex align-items-center mt-1">
        <i *ngIf="!currentChecklistAction(key)" (click)="recordEvent(key, !currentChecklistAction(key))"
            class="material-icons me-1 task-item-radio">radio_button_unchecked</i>
        <i *ngIf="currentChecklistAction(key)" (click)="recordEvent(key, !currentChecklistAction(key))"
            class="material-icons me-1 task-item-radio">radio_button_checked</i>
    </div>
</ng-template>
