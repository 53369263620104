<ul class="d-flex flex-column gap-3" *ngIf="!loading&&plansPurchased&&plansPurchased.length>0">
    <li *ngFor="let item of plansPurchased">
        <div class="d-flex w-100 gap-1">
            <span class="fw-600">{{item?.planDetails?.validity}} days plan</span> with <span class="fw-600">{{providerMap[item?.providerId]}}</span>  ->  <span class="text-primary">{{item?.pendingSessionBalance}} / {{item?.planDetails?.noOfSessions}}</span> sessions remaining <span class="ms-auto btn-sm btn-outline-danger" *ngIf="isPlanActive(item)">[expires on {{item?.planExpiryDateReadable}}]</span> <span class="ms-auto btn-sm btn-danger" disabled="true" *ngIf="!isPlanActive(item)">[expired on {{item?.planExpiryDateReadable}}]</span> 
        </div>
        <div>
            [Per Session Cost: <span class="text-success">{{item?.planDetails?.pricing?.sellingPricePerSessionInINR?item?.planDetails?.pricing?.sellingPricePerSessionInINR:'-'}} INR</span>, Total Amount Paid: <span class="text-success">{{item?.planDetails?.pricing?.sellingPriceDisplayVal}}</span>] [Session Type: {{item?.planDetails?.sessionType}}]
        </div>
    </li>
</ul>

<div class="spinner-border spinner-border-sm" role="status" *ngIf="loading">
    <span class="visually-hidden">Loading...</span>
</div>

<div *ngIf="!loading&&plansPurchased.length==0" class="text-muted">
    No active plans 🍀
</div>
