<form [formGroup]="form" style="width: 100%;" class="ms-3">
  <div class="row">
    <div class="form-group">
      <input type="text"
        class="form-control"
        formControlName="dateRange"
        #daterangepicker="bsDaterangepicker"
        bsDaterangepicker
        style="min-width: 300px;"
        (bsValueChange)="onDateRangeSelection($event)"
        [bsConfig]="{ rangeInputFormat : 'MMM Do YYYY', dateInputFormat: 'MMM Do YYYY', showWeekNumbers: false }">
      </div>
    <!-- <div class="form-group">
      <button class="btn btn-success" (click)="daterangepicker.toggle()" [attr.aria-expanded]="daterangepicker.isOpen" type="button">Date Range Picker</button>
    </div> -->
  </div>
</form>

<!-- <form class="form-inline">
    <div class="form-group hidden">
      <div class="input-group">
        <input name="datepicker"
               class="form-control"
               ngbDatepicker
               #datepicker="ngbDatepicker"
               [autoClose]="'outside'"
               (dateSelect)="onDateSelection($event)"
               [displayMonths]="2"
               [dayTemplate]="t"
               outsideDays="hidden"
               [startDate]="fromDate!"
               tabindex="-1">
        <ng-template #t let-date let-focused="focused">
          <span class="custom-day"
                [class.focused]="focused"
                [class.range]="isRange(date)"
                [class.faded]="isHovered(date) || isInside(date)"
                (mouseenter)="hoveredDate = date"
                (mouseleave)="hoveredDate = null">
            {{ date.day }}
          </span>
        </ng-template>
      </div>
    </div>
  </div>

  <div class="form-group">
    <div class="input-group">
      <input #dpFromDate style="width: 8rem;" class="form-control" placeholder="yyyy-mm-dd" name="dpFromDate"
        [value]="formatter.format(fromDate)" (input)="fromDate = validateInput(fromDate, dpFromDate.value)">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" (click)="datepicker.toggle()" type="button">
          <span class="material-icons" style="font-size:15px;">
            date_range
          </span>
        </button>
      </div>
    </div>
  </div>
  <div class="form-group ms-2">
    <div class="input-group">
      <input #dpToDate class="form-control" placeholder="yyyy-mm-dd" name="dpToDate" style="width: 8rem;"
        [value]="formatter.format(toDate)" (input)="toDate = validateInput(toDate, dpToDate.value)">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" (click)="datepicker.toggle()" type="button">
          <span class="material-icons" style="font-size:15px;">
            date_range
          </span>
        </button>
      </div>
    </div>
  </div>
  <div dropdown class="btn-group d-inline-block w-100">
    <button id="button-animated" dropdownToggle type="button" class="btn btn-sm btn-outline-primary dropdown-toggle"
      aria-controls="dropdown-animated">
      {{selectedDateSet}}
    </button>
    <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-animated">
      <li role="menuitem">
        <button (click)="setDates('Today')">Today</button>
      </li>
      <li role="menuitem">
        <button (click)="setDates('Yesterday')">Yesterday</button>
      </li>
      <li role="menuitem">
        <button (click)="setDates('Last 7 days')">Last 7 days</button>
      </li>
      <li role="menuitem">
        <button (click)="setDates('Last 30 days')">Last 30 days</button>
      </li>
      <li role="menuitem">
        <button (click)="setDates('This Month')">This Month</button>
      </li>
      <li role="menuitem">
        <button (click)="setDates('Last Month')">Last Month</button>
      </li>
    </ul>
  </div>
</form> -->
