import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { DoctorListingProfile } from '../../models-ts/Actors/Doctor/DoctorListingProfile';
import { User } from '../../models-ts/Actors/User/User';
import { Appointment } from '../../models-ts/Entities/Appointment';
import { CountrywiseFeeCalc } from '../../models-ts/Helpers/countrywiseFeeCalc';
import { AppointmentService } from '../../services/appointment/appointment.service';
export interface ApptListEvent{
  loadState:boolean;
  appointmentList:Appointment[]
}
@Component({
  selector: 'provider-patient-appointments',
  templateUrl: './provider-patient-appointments.component.html',
  styleUrls: ['./provider-patient-appointments.component.css'],
})

export class ProviderPatientAppointmentsComponent implements OnInit {
  @Input() set doctorProfile(val: DoctorListingProfile) {
    this._doctorProfile = val;
    this.getPatientAppointments();
  }
  @Input() set patientProfile(val: User) {
    this._patientProfile = val;
    this.getPatientAppointments();
  }
  @Output() appointmentList: EventEmitter<Appointment[]> = new EventEmitter<
    Appointment[]
  >();
  @Output() listFetchState: EventEmitter<string> = new EventEmitter<string>();

  //active event in use
  @Output() appoimentListState: EventEmitter<ApptListEvent> = new EventEmitter<ApptListEvent>();

  _doctorProfile: DoctorListingProfile = new DoctorListingProfile();
  _patientProfile: User = new User();
  patientAppointmentsList: Appointment[];
  isLoadingPatientAppointments: boolean = false;

  constructor(private _appointmentService: AppointmentService) {}

  ngOnInit(): void {
    this.getPatientAppointments();
  }

  getPatientAppointments() {
    this.emitListState('fetch_start');
    this.emitApptListState(true)
    this.patientAppointmentsList = [];
    if (
      this._doctorProfile &&
      this._doctorProfile.id &&
      this._patientProfile &&
      this._patientProfile.docvitaId
    ) {
      this.isLoadingPatientAppointments = true;
      this._appointmentService
        .getAppointmentsForPatient(this._patientProfile.docvitaId)
        .toPromise()
        .then((res) => {
          this.patientAppointmentsList = [];
          this.isLoadingPatientAppointments = false;
          if (res != null && res['statusCode'] == 200) {
            // console.log('getAppointments', res['body']);
            let upcomingAppointments = res['body']['upcomingAppointments'];
            let pastAppointments = res['body']['pastAppointments'];

            if (upcomingAppointments) {
              for (let a of upcomingAppointments) {
                if (a.date && a.timeslot) {
                  this.patientAppointmentsList.push(
                    plainToClass(Appointment, a)
                  );
                }
              }
            }
            if (pastAppointments) {
              for (let a of pastAppointments) {
                if (a.date && a.timeslot) {
                  this.patientAppointmentsList.push(
                    plainToClass(Appointment, a)
                  );
                }
              }
            }
            this.patientAppointmentsList = this.patientAppointmentsList.filter(
              (da) => da && da.doctorId == this._doctorProfile.id
            );

            this.patientAppointmentsList.sort(
              (a, b) =>
                new Date(b.date + ' ' + b.timeslot.split('-')[0]).getTime() -
                new Date(a.date + ' ' + a.timeslot.split('-')[0]).getTime()
            );

            this.emitListState('fetch_end');
            this.emitAppointments();
            this.emitApptListState(false)

            // console.log(this.allAppointments);
          } else {
            this.patientAppointmentsList = [];
            this.isLoadingPatientAppointments = false;
            this.emitListState('fetch_end');
            this.emitAppointments();
            this.emitApptListState(false)
          }
        })
        .catch((err) => {
          this.patientAppointmentsList = [];
          this.isLoadingPatientAppointments = false;
          this.emitListState('fetch_end');
          this.emitAppointments();
          this.emitApptListState(false)
        });
    } else {
      this.patientAppointmentsList = [];
      this.isLoadingPatientAppointments = false;
      this.emitListState('fetch_end');
      this.emitAppointments();
      this.emitApptListState(false)
    }
  }

  emitAppointments() {
    this.appointmentList.emit(this.patientAppointmentsList);
  }
  emitListState(title: string) {
    this.listFetchState.emit(title);
  }
  emitApptListState(loadstate:boolean) {
    if(loadstate == true){
      const _state:ApptListEvent = {loadState:true,appointmentList:[]}
      this.appoimentListState.emit(_state);
    }else{
      const _state:ApptListEvent = {loadState:true,appointmentList:this.patientAppointmentsList}
      this.appoimentListState.emit(_state);
    }
    
  }

  get doctorName() {
    return this._doctorProfile.getFirstNameWithSalutation()
      ? this._doctorProfile.getFirstNameWithSalutation()
      : 'doctor';
  }

  get patientName() {
    return this._patientProfile && this._patientProfile.getFirstName()
      ? this._patientProfile.getFirstName()
      : 'patient';
  }
  currencyToSymbol(appointment:Appointment){
    return new CountrywiseFeeCalc().currencyToSymbol(appointment.currency);
  }
}
