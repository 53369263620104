import { Expose } from "class-transformer";

export class LeadNote{
    @Expose() note:string;
    @Expose() createdById:string;
    @Expose() createdByName:string;
    @Expose() createdOn:number;
    @Expose() updatedById:string;
    @Expose() updatedByName:string;
    @Expose() updatedOn:number;
}