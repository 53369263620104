<div class="d-flex flex-column border-bottom">
    <div class="d-flex flex-row justify-content-between mx-1 mt-2 mb-2">
        <span class="font-16 fw-500">{{ hideTitle? ' ': 'Next Appointment'}} </span>
        <div class="d-flex flex-row" *ngIf="editMode">
            <button class="btn btn-sm btn-outline-secondary border-0" (click)="undo()" >Undo</button>
            <button class="btn btn-sm btn-primary border-0 ms-2"  (click)="save()">Save</button>
        </div>
        <div class="d-flex flex-row" *ngIf="!editMode">
            <button class="btn btn-sm btn-outline-primary ms-2"  style="border-width: 1px" (click)="editMode = !editMode" >
                <i class="feather icon-edit-1 me-1"></i>
                Edit
            </button>
        </div>
    </div>
    <div class="d-flex flex-row mx-1 mb-2">
        <div class="me-2">
            <h6>Date:</h6>
            <input [disabled]="!editMode" type="date" class="form-control" style="width: 180px;" [(ngModel)]="followupDate">
        </div>
        <div>
            <h6>Time:</h6>
            <input [disabled]="!editMode" type="time" class="form-control" style="width: 180px;" [(ngModel)]="followupTime">
        </div>
    </div>
    
    <button class="btn btn-sm btn-primary my-2 d-flex align-items-center justify-content-center" (click)="inviteModal.openModal()">
        <i class="feather icon-calendar"></i>
        <span class="ms-2">New Invite</span>
    </button>
</div>

<create-invite-modal [showButton]="false" [suggestedTime]="followupTime"  [date]="followupDate" [currentHeroInfo]="currentHeroInfo" [patientId]="lead.consultationInfo.patientId" [doctorId]="lead.consultationInfo.doctorId" #inviteModal></create-invite-modal>