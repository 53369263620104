import { Expose, plainToClass, Transform, Type } from "class-transformer";
import { LeadPayout, LeadPayoutStatus } from "./LeadPayout";
import "reflect-metadata";
import { Appointment } from "./Appointment";
import { LeadBooleanAction } from "./LeadBooleanAction";
import { ContactNumber } from "../Actors/Person/ContactNumber";
import { Refund } from "./Refund";
import { LeadTransfer } from "./LeadTransfer";
import { AppointmentType, OnlineConsultationPlatform } from "../Enums/Enums";
import { OnlineMeetingDetails } from "./OnlineMeetingDetails";
import { LeadFollowUpShort } from "./LeadFollowUpShort";
import { ClientFeedback } from "../Protocols/ClientFeedback";
import { AppointmentLedgerItem } from "../Protocols/AppointmentLedgerItem";
import { AmountWithCurrency } from "../Protocols/AmountWithCurrency";
import { Currency } from "../Helpers/countrywiseFeeCalc";
import { RefundSource, TransactionSource } from "../Enums/pay";
import { LeadNote } from "./LeadNote";
import { DayjsHelper } from "../Helpers/dayjsHelper";
import { BooleanAction } from "../Protocols/BooleanAction";
export enum LeadObjectMode {
  FOLLOWUP = "FOLLOWUP",
  REGULAR = "REGULAR",
  TENTATIVE = "TENTATIVE",
}

export class Lead {
  @Expose() id: string;
  @Expose() leadType: string;
  @Expose() bookingApiSource: string;
  @Expose() status: LeadStatus;
  @Expose() city: string;
  @Expose() category: string;
  @Expose() createdOn: number;
  @Expose() doctorName: string;
  @Expose() isVerified: boolean;
  @Expose() patientName: string;
  @Expose() platform: OnlineConsultationPlatform;
  @Expose() doctorContactInfo: ContactNumber;
  @Expose() patientContactInfo: ContactNumber;

  @Expose() resolvedBy: BooleanAction;

  @Expose() locked: boolean;
  @Expose() lockedOn: number;

  modeOfObject: LeadObjectMode; //Help in UI
  needsAttention: boolean; //help in UI

  get isFollowupMode(): boolean {
    //Help in UI
    return (
      this.modeOfObject != null && this.modeOfObject == LeadObjectMode.FOLLOWUP
    );
  }

  isFollowupModeFunc(selectedDate: string): boolean {
    //Help in UI
    return (
      this.consultationInfo.followupDate &&
      selectedDate.localeCompare(this.consultationInfo.date) != 0
    );
  }

  @Type(() => Appointment)
  @Expose()
  consultationInfo: Appointment;

  @Type(() => Appointment)
  @Expose()
  originalConsultationInfos: Appointment[] = [];

  @Expose() docvitaId: string;
  @Expose() callStatus: string;
  @Expose() organisationId: string;
  @Expose() enquiryMessage: string;
  @Expose() isValidExtension: boolean;
  @Expose() isGoogleDynamic: boolean;
  @Expose() dialedExtension: string;
  @Expose() callType: string;
  @Expose() callStartTime: string;
  @Expose() callDuration: string;
  @Expose() leadCategories: string[];
  @Expose() onlineMeetingDetails: OnlineMeetingDetails;
  @Expose()
  @Transform(
    (value) => {
      if (value) {
        let map = new Map<string, LeadPayout>();
        for (let entry of Object.entries(value)) {
          map.set(entry[0], plainToClass(LeadPayout, entry[1]));
        }
        return map;
      } else {
        return new Map<string, LeadPayout>();
      }
    },
    { toClassOnly: true }
  )
  payouts: Map<string, LeadPayout> = new Map<string, LeadPayout>();

  @Expose()
  @Transform(
    (value) => {
      if (value) {
        let map = new Map<string, AppointmentLedgerItem>();
        for (let entry of Object.entries(value)) {
          map.set(entry[0], plainToClass(AppointmentLedgerItem, entry[1]));
        }
        return map;
      } else {
        return new Map<string, AppointmentLedgerItem>();
      }
    },
    { toClassOnly: true }
  )
  ledger: Map<string, AppointmentLedgerItem> = new Map<
    string,
    AppointmentLedgerItem
  >();

  @Expose()
  @Transform(
    (value) => {
      if (value) {
        let map = new Map<string, Refund>();
        for (let entry of Object.entries(value)) {
          map.set(entry[0], plainToClass(Refund, entry[1]));
        }
        return map;
      } else {
        return new Map<string, Refund>();
      }
    },
    { toClassOnly: true }
  )
  refunds: Map<string, Refund> = new Map<string, Refund>();

  @Expose()
  @Transform(
    (value) => {
      if (value) {
        let map = new Map<string, LeadTransfer>();
        for (let entry of Object.entries(value)) {
          map.set(entry[0], plainToClass(LeadTransfer, entry[1]));
        }
        return map;
      } else {
        return new Map<string, LeadTransfer>();
      }
    },
    { toClassOnly: true }
  )
  transfers: Map<string, LeadTransfer> = new Map<string, LeadTransfer>();

  @Type(() => LeadBooleanAction)
  @Expose()
  whatsappGroupCreated: LeadBooleanAction = new LeadBooleanAction();

  @Type(() => LeadBooleanAction)
  @Expose()
  whatsappReachoutSent: LeadBooleanAction = new LeadBooleanAction();

  // Confirmation Sent to Client & Provider
  @Type(() => LeadBooleanAction)
  @Expose()
  confirmationWhatsAppSentProvider: LeadBooleanAction = new LeadBooleanAction();

  @Type(() => LeadBooleanAction)
  @Expose()
  confirmationWhatsAppSentClient: LeadBooleanAction = new LeadBooleanAction();

  @Type(() => LeadBooleanAction)
  @Expose()
  careCoordinatorIntroSent: LeadBooleanAction = new LeadBooleanAction();

  @Type(() => LeadBooleanAction)
  @Expose()
  instructionToConnectSent: LeadBooleanAction = new LeadBooleanAction();

  @Expose()
  @Transform(
    (value) => {
      if (value) {
        let map = new Map<string, LeadBooleanAction>();
        for (let entry of Object.entries(value)) {
          map.set(entry[0], plainToClass(LeadBooleanAction, entry[1]));
        }
        return map;
      } else {
        return new Map<string, LeadBooleanAction>();
      }
    },
    { toClassOnly: true }
  )
  checklistActions: Map<string, LeadBooleanAction> = new Map<
    string,
    LeadBooleanAction
  >();

  @Expose()
  @Transform(
    (value) => {
      if (value) {
        let map = new Map<string, LeadFollowUpShort>();
        for (let entry of Object.entries(value)) {
          map.set(entry[0], plainToClass(LeadFollowUpShort, entry[1]));
        }
        return map;
      } else {
        return new Map<string, LeadFollowUpShort>();
      }
    },
    { toClassOnly: true }
  )
  followUps: Map<string, LeadFollowUpShort> = new Map<
    string,
    LeadFollowUpShort
  >();

  @Type(() => ClientFeedback)
  @Expose()
  clientFeedback: ClientFeedback;

  @Expose()
  @Transform(
    (value) => {
      if (value) {
        let map = new Map<string, LeadNote>();
        for (let entry of Object.entries(value)) {
          map.set(entry[0], plainToClass(LeadNote, entry[1]));
        }
        return map;
      } else {
        return new Map<string, LeadNote>();
      }
    },
    { toClassOnly: true }
  )
  leadNotes: Map<string, LeadNote> = new Map<string, LeadNote>();

  public get ledgerPayoutProcessed(): boolean {
    let bool: boolean = false;
    if (this.ledger) {
      this.ledger.forEach((val, key) => {
        if (val.ledgerId == this.consultationInfo.doctorId && val.debit > 0) {
          bool = true;
        }
      });
    }
    return bool;
  }

  public get ledgerRefundProcessed(): boolean {
    let bool: boolean = false;
    if (this.ledger) {
      this.ledger.forEach((val, key) => {
        if (
          val.transactionDetails &&
          val.transactionDetails.includes("Refund") &&
          val.debit > 0
        ) {
          bool = true;
        }
      });
    }
    return bool && this.ledgerBalance == 0;
  }

  public get ledgerPartialRefundProcessed(): boolean {
    let bool: boolean = false;
    if (this.ledger) {
      this.ledger.forEach((val, key) => {
        if (
          val.transactionDetails &&
          val.transactionDetails.includes("Refund") &&
          val.debit > 0
        ) {
          bool = true;
        }
      });
    }
    return bool && this.ledgerBalance != 0;
  }

  public get ledgerPayoutBalance(): number {
    let balance = 0;
    if (this.ledger) {
      this.ledger.forEach((val, key) => {
        if (val.ledgerId == this.consultationInfo.doctorId) {
          balance += val.debit;
          balance -= val.credit;
        }
      });
    }
    return balance;
  }

  public get ledgerBalance(): number {
    let balance = 0;
    if (this.ledger) {
      this.ledger.forEach((val, key) => {
        balance +=
          val.providerDisplayAmountHelper.currency != Currency.INR
            ? val.providerDisplayAmountHelper.amount
            : val.credit;
        balance -= val.debit;
      });
    }
    return balance;
  }

  public get ledgerRefundedBalance(): AmountWithCurrency {
    let balance = 0;
    let currency: Currency = Currency.INR;
    if (this.ledger) {
      this.ledger.forEach((val, key) => {
        if (
          val.transactionDetails &&
          val.transactionDetails.includes("Refund") &&
          val.debit > 0
        ) {
          balance += val.debit;
          currency = val.currency;
        }
      });
    }
    let obj = new AmountWithCurrency();
    obj.amount = balance;
    obj.currency = currency;
    return obj;
  }

  public get ledgerRefundedBalanceINR(): AmountWithCurrency {
    let balance = 0;
    let currency: Currency = Currency.INR;
    if (this.ledger) {
      this.ledger.forEach((val, key) => {
        if (
          val.transactionDetails &&
          val.transactionDetails.includes("Refund") &&
          val.debit > 0
        ) {
          if(val.currency == null || val.currency == Currency.INR) balance += val.debit;
          if (val.currency && val.currency != Currency.INR && val.providerDisplayAmount > 0) balance += val.providerDisplayAmount;
        }
      });
    }
    let obj = new AmountWithCurrency();
    obj.amount = balance;
    obj.currency = currency;
    return obj;
  }

  public get ledgerDirectRefundedBalance(): AmountWithCurrency {
    let balance = 0;
    let currency: Currency = Currency.INR;
    if (this.ledger) {
      this.ledger.forEach((val, key) => {
        if (
          val.transactionDetails &&
          val.transactionDetails.includes("Refund") &&
          (val.refundSource == RefundSource.Cashgram ||
            val.refundSource == RefundSource.Stripe ||
            val.refundSource == RefundSource.HDFCBANK ||
            val.refundSource == RefundSource.Paytm ||
            val.refundSource == RefundSource.Cashfree ||
            val.refundSource == RefundSource.Razorpay) &&
          val.debit > 0
        ) {
          balance += val.debit;
          currency = val.currency;
        }
      });
    }
    let obj = new AmountWithCurrency();
    obj.amount = balance;
    obj.currency = currency;
    return obj;
  }

  public get isPlanBooking(): boolean {
    if (
      this.consultationInfo &&
      this.consultationInfo.planPurchaseId &&
      this.consultationInfo.planPurchaseId.length > 0
    ) {
      return true;
    } else if (
      this.consultationInfo &&
      this.consultationInfo.paymentSource ==
      TransactionSource.PlanPurchaseWallet
    ) {
      return true;
    } else {
      return false;
    }
  }

  public get ledgerDocVitaEarningBalance(): number {
    let balance = 0;
    if (this.ledger) {
      this.ledger.forEach((val, key) => {
        if (val.ledgerId == "docvitaearnings") {
          balance += val.credit;
          balance -= val.debit;
        }
      });
    }
    return balance;
  }

  public get ledgerDocVitaPromoCreditsUsed(): number {
    let balance = 0;
    if (this.ledger) {
      this.ledger.forEach((val, key) => {
        if (val.source == TransactionSource.DocVitaPromoCredit) {
          balance += val.credit;
          balance -= val.debit;
        }
      });
      if (balance > 0 && this.ledgerRefundedBalance.currency == Currency.INR) {
        balance -= this.ledgerRefundedBalance.amount;
      }
    }
    return balance;
  }

  public get ledgerProviderEarningBalance(): number {
    let balance = 0;
    if (this.ledger) {
      this.ledger.forEach((val, key) => {
        if (val.ledgerId == this.consultationInfo.doctorId) {
          balance += val.credit;
          balance -= val.debit;
        }
      });
    }
    return balance;
  }

  public get isLedgerSettlementDone(): boolean {
    let bool: boolean = false;
    if (this.ledgerBalance == 0 && this.ledgerPayoutBalance > 0) {
      bool = true;
    }
    return bool;
  }

  public get isPayoutSettlementDone(): boolean {
    let bool: boolean = false;
    if (this.payouts.size > 0) {
      this.payouts.forEach((val, key) => {
        if (!bool && val.status == LeadPayoutStatus.SUCCESS) {
          bool = true;
        }
      });
    }
    if (this.transfers.size > 0) {
      this.transfers.forEach((val, key) => {
        if (!bool && val.isSettlementDone()) {
          bool = true;
        }
      });
    }
    if (this.ledgerPayoutProcessed) {
      bool = true;
    }
    return bool;
  }

  public get isPayoutSettlementInProgress(): boolean {
    let bool: boolean = false;
    if (this.payouts.size > 0) {
      this.payouts.forEach((val, key) => {
        if (!bool && val.status == LeadPayoutStatus.PENDING) {
          bool = true;
        }
      });
    }
    if (this.transfers.size > 0) {
      this.transfers.forEach((val, key) => {
        if (!val.onHold && !val.isSettlementDone() && !val.hasBeenReversed()) {
          bool = true;
        }
      });
    }
    return bool;
  }

  public get isPayoutSettlementFailed(): boolean {
    let bool: boolean = false;
    if (this.payouts.size > 0) {
      this.payouts.forEach((val, key) => {
        if (!bool && val.status == LeadPayoutStatus.FAILED) {
          bool = true;
        }
      });
    }
    if (this.transfers.size > 0) {
      this.transfers.forEach((val, key) => {
        if (val.isSettlementFailed()) {
          bool = true;
        }
      });
    }
    return bool;
  }

  public get totalRefund(): number {
    let num = 0;
    if (this.refunds) {
      this.refunds.forEach((val, key) => {
        if (val.refundId != null && val.processed == "YES") {
          num += +val.refundAmount;
        }
      });
    }
    return num;
  }

  /**
   * This is for the net amount we have received froma client.
   * Eg. client paid 1000 and we refunded 200 so net amount is 800
   */
  public get netAmountReceived(): number {
    if (this.consultationInfo) {
      let netAmount = this.consultationInfo.totalAmountPaid - this.totalRefund;
      return netAmount;
    } else {
      return 0;
    }
  }

  public get totalPayout(): number {
    let num = 0;
    if (this.ledger && this.ledger.size > 0 && this.ledgerPayoutBalance > 0) {
      num += this.ledgerPayoutBalance;
    } else {
      if (this.transfers) {
        this.transfers.forEach((val, key) => {
          num += +val.netTransferAmount;
        });
      }
      if (this.payouts) {
        this.payouts.forEach((val, key) => {
          if (val.isSuccessful()) {
            num += +val.settlementAmount;
          }
        });
      }
    }
    return num;
  }

  public get isRefunded(): boolean {
    let bool: boolean = false;
    this.refunds.forEach((val, key) => {
      if (!bool && val.refundId != null && val.processed == "YES") {
        bool = true;
      }
    });
    if (this.ledgerRefundProcessed) {
      bool = true;
    }
    return bool;
  }

  public get isRefundInProcess(): boolean {
    let bool: boolean = false;
    this.refunds.forEach((val, key) => {
      if (!bool && val.processed == "NO") {
        bool = true;
      }
    });
    return bool;
  }

  public get getLatestRefund(): Refund {
    let obj: Refund;
    this.refunds.forEach((val, key) => {
      if (obj == null || obj.initiatedOn <= val.initiatedOn) {
        obj = val;
      }
    });
    return obj;
  }

  public get getLatestPayout(): LeadPayout {
    let obj: LeadPayout;
    this.payouts.forEach((val, key) => {
      if (obj == null || obj.createdOn <= val.createdOn) {
        obj = val;
      }
    });
    return obj;
  }

  public get cityNameForDisplay(): string {
    if (this.city == null || this.city.length == 0) {
      return "India";
    }
    let map: any = {
      bangalore: "Bangalore",
      ahmedabad: "Ahmedabad",
    };
    if (map[this.city] != null) {
      return map[this.city];
    }
    return this.city.substring(0, 1).toUpperCase() + this.city.substr(1);
  }

  public statusMap: any = {
    CALL_NOW: "Call",
    CALLBACK_LATER: "Callback",
    BOOKED: "Booked",
    ENQUIRY: "Messaged",
    INVALID: "To DocVita",
  };

  public mapStatus(): string {
    if (this.enquiryMessage && this.enquiryMessage.length > 0) {
      return this.statusMap["ENQUIRY"];
    } else if (this.isValidExtension == false) {
      return this.statusMap["INVALID"];
    } else if (this.status) {
      return this.statusMap[this.status];
    }
  }

  getClientFirstName() {
    let str;
    if (this.patientName != null) {
      let name = this.patientName;
      if (name.includes(".") && !name.includes(". ")) {
        name = name.split(".").join(". ");
      }
      let arr = name.split(" ");
      let trimmedArr: string[] = [];
      for (let a of arr) {
        trimmedArr.push(a.trim());
      }
      for (let t of trimmedArr) {
        if (
          str == null &&
          t.length > 2 &&
          !t.includes("(") &&
          !t.includes(")") &&
          !t.includes(".")
        ) {
          str = t;
        }
      }
    }
    return str;
  }

  public getPlatformMeetingUrl() {
    let urlData = "";
    if (this.onlineMeetingDetails?.url) {
      urlData += this.onlineMeetingDetails.url;
    }
    if (this.onlineMeetingDetails?.platform == "zoom") {
      if (this.onlineMeetingDetails.id) {
        urlData += `\n\nMeeting ID: ${this.onlineMeetingDetails.id}`;
      }
      if (this.onlineMeetingDetails?.passcode) {
        urlData += `\nPasscode: ${this.onlineMeetingDetails.passcode}`;
      }
    }
    return urlData;
  }

  public getPlatformTitle(): string {
    if (!this.onlineMeetingDetails?.platform) {
      return 'Video Call'
    }
    switch (this.onlineMeetingDetails?.platform) {
      case OnlineConsultationPlatform.googlemeet:
        return "Google Meet";
      case OnlineConsultationPlatform.zoom:
        return "Zoom";
      case OnlineConsultationPlatform.whatsapp:
        return "WhatsApp";
      default:
        return (
          this.onlineMeetingDetails?.platform.charAt(0).toUpperCase() +
          this.onlineMeetingDetails?.platform.slice(1)
        );
    }
  }

  public isJoiningInfoAvailableForClient() {
    let bool = false;
    switch (this.onlineMeetingDetails?.platform) {
      case OnlineConsultationPlatform.googlemeet:
        if (
          this.onlineMeetingDetails?.url &&
          this.onlineMeetingDetails?.url.length > 0
        ) {
          bool = true;
        }
        break;
      case OnlineConsultationPlatform.zoom:
        if (
          this.onlineMeetingDetails?.url &&
          this.onlineMeetingDetails?.url.length > 0
        ) {
          bool = true;
        }
        break;
      case OnlineConsultationPlatform.whatsapp:
        if (this.onlineMeetingDetails?.providerWAContactNumber) {
          bool = true;
        }
        break;
    }
    return bool;
  }

  public getAppropriateNameSuffix(firstLetterCaps?: boolean): string {
    if (
      this.consultationInfo != null &&
      this.consultationInfo.patientGender != null
    ) {
      let gender = this.consultationInfo.patientGender;
      if (gender.toLowerCase() == "male")
        return firstLetterCaps ? "Sir" : "sir";
      else if (gender.toLowerCase() == "female")
        return firstLetterCaps ? "Ma'am" : "ma'am";
      else return "";
    } else {
      return "";
    }
  }

  public netIncomingBalanceForProvider(): AmountWithCurrency {
    let obj: AmountWithCurrency = new AmountWithCurrency();
    obj.amount = 0;
    let uniqueTransferIds: string[] = [];
    if (this.ledger) {
      this.ledger.forEach((item, key) => {
        if (item.credit > 0 && !uniqueTransferIds.includes(item.transferId)) {
          obj.amount += item.providerDisplayAmountHelper.amount;
          obj.currency = item.providerDisplayAmountHelper.currency;
          uniqueTransferIds.push(item.transferId);
        }
        if (
          item.transactionDetails &&
          item.transactionDetails.includes("Refund") &&
          item.debit > 0
        ) {
          if(item.currency == item.providerDisplayAmountHelper.currency) {
            obj.amount -= item.debit;
            obj.currency = item.providerDisplayAmountHelper.currency;
            uniqueTransferIds.push(item.transferId);
          } else if(item.currency != item.providerDisplayAmountHelper.currency && item.providerDisplayAmount > 0) {
            obj.amount -= item.providerDisplayAmount;
            obj.currency = item.providerDisplayAmountHelper.currency;
            uniqueTransferIds.push(item.transferId);
          }
        }
      });
    }
    return obj;
  }

  public netIncomingBalancePaidByClient(): AmountWithCurrency {
    let obj: AmountWithCurrency = new AmountWithCurrency();
    obj.amount = 0;
    if (this.ledger) {
      this.ledger.forEach((item, key) => {
        if (item.credit > 0) {
          obj.amount += item.displayAmountPaidByClientHelper.amount;
          obj.currency = item.displayAmountPaidByClientHelper.currency;
        }
      });
    }

    if (
      obj.currency == this.ledgerRefundedBalance.currency ||
      (!this.ledgerRefundedBalance.currency &&
        this.ledgerRefundedBalance.amount != null)
    ) {
      obj.amount -= this.ledgerRefundedBalance.amount;
    }
    if ((!this.ledger || this.ledger.size == 0) && this.consultationInfo) {
      obj.amount = this.netAmountReceived ? this.netAmountReceived : 0;
      obj.currency = Currency.INR;
    }
    return obj;
  }

  public netIncomingBalanceAcceptedCurrency(): AmountWithCurrency {
    let obj: AmountWithCurrency = new AmountWithCurrency();
    obj.amount = 0;
    if (this.ledger) {
      this.ledger.forEach((item, key) => {
        if (item.credit > 0) {
          obj.amount += item.providerDisplayAmountHelper.amount;
          obj.currency = item.providerDisplayAmountHelper.currency;
        }
      });
    }
    if (obj.currency == this.ledgerRefundedBalance.currency) {
      obj.amount -= this.ledgerRefundedBalance.amount;
    }
    return obj;
  }

  public actualIncomingBalanceInINR(): AmountWithCurrency {
    let obj: AmountWithCurrency = new AmountWithCurrency();
    obj.amount = 0;
    if (this.ledger) {
      this.ledger.forEach((item, key) => {
        if (item.credit > 0) {
          obj.amount += item.actualAmountInINRHelper.amount;
          obj.currency = item.actualAmountInINRHelper.currency;
        }
      });
    }
    if (obj.currency == this.ledgerRefundedBalanceINR.currency) {
      obj.amount -= this.ledgerRefundedBalanceINR.amount;
    }
    return obj;
  }

  public amountPaidByClient(): AmountWithCurrency {
    let obj: AmountWithCurrency = new AmountWithCurrency();
    obj.amount = 0;
    if (this.ledger) {
      this.ledger.forEach((item, key) => {
        if (item.credit > 0) {
          obj.amount += item.credit;
          obj.currency = item.currency;
        }
      });
    }
    return obj;
  }

  public cancellationFeeCharged(): AmountWithCurrency {
    //TODO: have to change the logic here.
    let obj: AmountWithCurrency = new AmountWithCurrency();
    obj.amount = 0;
    if (this.ledger) {
      this.ledger.forEach((item, key) => {
        if (item.changeFee > 0) {
          obj.amount += item.changeFee;
          obj.currency = item.currency;
        }
      });
    }
    return obj;
  }

  get providerName() {
    return this.consultationInfo?.doctorName || "-";
  }
  get isScheduledAppointment(): boolean {
    const status = this.consultationInfo?.status;
    return status == "SCHEDULED";
  }
  get isConfirmedAppointment(): boolean {
    const status = this.consultationInfo?.status;
    return status == "CONFIRMED";
  }
  get isCompletedAppointment(): boolean {
    const status = this.consultationInfo?.status;
    return status == "COMPLETED";
  }
  get isCancelledAppointment(): boolean {
    const status = this.consultationInfo?.status;
    return status == "CANCELLED";
  }
  get isExpiredAppointment(): boolean {
    const status = this.consultationInfo?.status;
    return status == "EXPIRED";
  }

  get isInPersonAppointment(): boolean {
    const type = this.consultationInfo?.type;
    return type == AppointmentType.inPerson;
  }

  get isPendingChangeAppointment(): boolean {
    return this.consultationInfo?.markedPendingChangeAppointment?.bool;
  }

  get isMarkedNoShow(): boolean {
    return this.consultationInfo?.markedNoShow?.bool || this.consultationInfo?.status == "NOSHOW";
  }

  get isPast() {
    const dayjsHelper = new DayjsHelper();
    const currentTime = dayjsHelper.currentTime();
    const endDate = this.consultationInfo?.endDate ? this.consultationInfo?.endDate : this.consultationInfo?.date;
    return (endDate + this.consultationInfo?.getEndTime()).localeCompare(
      dayjsHelper.unixToYYYYMMDD(currentTime) + dayjsHelper.unixToHHmm(currentTime)
    ) < 0;
  }
  get isFuture() {
    const dayjsHelper = new DayjsHelper();
    const currentTime = dayjsHelper.currentTime();
    const endDate = this.consultationInfo?.endDate ? this.consultationInfo?.endDate : this.consultationInfo?.date;
    return (this.isScheduledAppointment || this.isConfirmedAppointment)
      && (this.consultationInfo?.date + this.consultationInfo?.getStartTime()).localeCompare(
        dayjsHelper.unixToYYYYMMDD(currentTime) + dayjsHelper.unixToHHmm(currentTime)
      ) > 0;
  }
  get isOnGoing() {
    const dayjsHelper = new DayjsHelper();
    const currentTime = dayjsHelper.currentTime();
    const endDate = this.consultationInfo?.endDate ? this.consultationInfo?.endDate : this.consultationInfo?.date;
    const apptStartDateTime = this.consultationInfo?.date + this.consultationInfo?.getStartTime();
    const apptEndDateTime = endDate + this.consultationInfo?.getEndTime();
    const currentDateTime = dayjsHelper.unixToYYYYMMDD(currentTime) + dayjsHelper.unixToHHmm(currentTime);
    return this.consultationInfo?.status == "CONFIRMED" &&
      (apptStartDateTime).localeCompare(currentDateTime) <= 0 && (apptEndDateTime).localeCompare(currentDateTime) >= 0;
  }
}

export enum LeadStatus {
  CALL_NOW = "CALL_NOW",
  CALLBACK_LATER = "CALLBACK_LATER",
  BOOKED = "BOOKED",
}
