import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DoctorListingProfile } from 'src/app/shared/models-ts/Actors/Doctor/DoctorListingProfile';
import { AuthUserInfoService } from 'src/app/shared/services/auth-user-info/auth-user-info.service';
import { DoctorDataService } from 'src/app/shared/services/doctor-data/doctor-data.service';
import { DoctorsService } from 'src/app/shared/services/doctors/doctors.service';
import { internTags } from 'src/app/shared/constants/constants';
@Component({
  selector: 'app-photo-upload-details',
  templateUrl: './photo-upload-details.component.html',
  styleUrls: ['./photo-upload-details.component.css']
})
export class PhotoUploadDetailsComponent implements OnInit {

  doctorProfile: DoctorListingProfile = new DoctorListingProfile();
  currentHeroInfo: any;
  internTags: {} = internTags;
  photographCheck: boolean;
  constructor(
    private _doctorDataService: DoctorDataService,
    private _authUserInfoService: AuthUserInfoService,
    private _toastr: ToastrService,
    private _doctorService: DoctorsService,
  ) { }

  ngOnInit(): void {
    this.currentHeroInfo = this._authUserInfoService.getUserInfo();
    this._doctorDataService.currentSelection.subscribe(
      (data) => {
        if (data != null && data['memberSelected'] != null) {
          this.doctorProfile = data['memberSelected'];
          this.photographCheck = this.doctorProfile.internTags!=null && this.doctorProfile.internTags.includes('profile_photograph_not_found');
        }
      }
    );
  }

  updatePhotoChanges(e) {
    this.doctorProfile.photoUrl = e.photoUrl;
    this._doctorDataService.updateCurrentDoctor(this.doctorProfile);
  }

  updateInternalTags(tag: string, addTag: boolean) {
    let promise: Promise<any>;
    if (addTag) {
      //Add query
      promise = this._doctorService.updateDoctorProfileInternTags(
        tag,
        this.doctorProfile.id
      );
    } else {
      //Remove tag
      promise = this._doctorService.removeDoctorProfileInternTags(
        tag,
        this.doctorProfile.id
      );
    }
    promise
      .then(() => {
        this._toastr.success('Updated successfully.');
      })
      .catch((err) => {
        console.error(err);
        this._toastr.error('Something went wrong');
      });
  }

  updateCheckBox(internTagKey: string, event: boolean) {
    let oldVal = this.doctorProfile.internTags;
    if (event == false) {
      if (window.confirm('Are you sure to undo this?')) {
        this.updateInternalTags(internTagKey, event);
      } else {
        setTimeout(() => {
          this.doctorProfile.internTags = oldVal;
        }, 200);
      }
    } else {
      this.updateInternalTags(internTagKey, event);
    }
  }

}
