<!-- <p>doc-membership-plans works!</p> -->
<div class="p-2">
    <div class="d-flex flex-row justify-content-between w-100">
        <h4>Membership Plans</h4>
        <div class="flex-row">
            <button class="btn btn-primary me-2" style="width: fit-content;" (click)="generate4WeekPlan()">Generate 4 week Plan</button>
            <button class="btn btn-primary me-2" style="width: fit-content;" (click)="generate9WeekPlan()">Generate 9 week Plan</button>
            <button class="btn btn-primary" style="width: fit-content;" (click)="addPlan()">Add Plan</button>
        </div>
    </div>
    <div class="d-flex flex-column">
        <ng-container *ngFor="let plan of membershipPlans">
            <ng-container *ngTemplateOutlet="planItem; context:{plan: plan};"></ng-container>
        </ng-container>
    </div>
</div>
<app-doc-membership-plan-modify  (refreshPlans)="fetchPlans()" #planmodifycanvas ></app-doc-membership-plan-modify>
<app-doc-membership-plan-generator  (refreshPlans)="fetchPlans()" #plangeneratorcanvas ></app-doc-membership-plan-generator>
<ng-template #planItem let-plan="plan">
    <div class="d-flex flex-column p-2 mb-2 border" style="border-radius: 8px;">
        <span> Validity: {{ plan.validityDisplayValue }}</span>
        <span> No of sessions: {{ plan.noOfSessions }}</span>
        <span> Session Duration : {{ plan.sessionDurationInMinutes }}</span>
        <span> Session Type : {{ plan.sessionType }}</span>
        <span> Most Popular : {{ plan.isMostPopular }}</span>
        <span> Email Support On : {{ plan.isEmailSupportOn }}</span>
        <span> Chat Support On : {{ plan.isCareManagerSupportOn }}</span>
        <span>Pricing:</span>
        <div *ngFor="let item of plan.countrywisePricing | keyvalue">
            {{ item.value.country }},{{ item.value.currency }}, {{ item.value.sellingPrice }}, {{ item.value.listPrice }}
        </div>
        <button class="btn btn-primary" style="width: fit-content;" (click)="editPlan(plan)">Edit</button>
    </div>
</ng-template>