import { Expose, plainToClass, Transform, Type } from "class-transformer";
import {
	ChatMessageChannel,
	ChatMessageStatus,
	WAMessageTypes,
} from "../../Enums/chatEnum";
import { ActionDoneBy } from "../../Protocols/ActionDoneBy";
import { BooleanAction } from "../../Protocols/BooleanAction";
import { docvitaSenderId } from "../../constants/chat-constants";
import * as dayjs from "dayjs";
import * as timezone from "dayjs/plugin/timezone";
import * as RelativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(timezone);
dayjs.extend(RelativeTime);
import * as utc from "dayjs/plugin/utc";
import { Appointment } from "../Appointment";
import { Lead } from "../Lead";
import { DvChatEntities } from "./ChatListItem";
dayjs.extend(utc);
dayjs.tz.setDefault("Asia/Kolkata");

export class WAContext {
	@Expose() parentId: string;
	@Expose() postback: string;
}

export class AppointmentInfoMinimal{
	@Expose() date:string;
	@Expose() timeslot:string;
	@Expose() patientName:string;
	@Expose() doctorName:string;
	@Expose() patientId:string;
	@Expose() doctorId:string;
	@Expose() leadId:string;
	@Expose() sessionType:string;
}

export class LinkPreview {
	@Expose() ogTitle: string;
	@Expose() ogDescription: string;
	@Expose() ogType: string;
	@Expose() ogImage: string;
	@Expose() ogImageSecureUrl: string;
}

export class ChatMessage {
	@Expose() messageId: string;
	@Expose() channel: ChatMessageChannel;
	@Expose() text: string;
	@Expose() dvContext: any;
	@Expose() waMessageType: WAMessageTypes; //Only for outgoing whatsapp messages. Why not incoming?
	@Expose() fileUrl: string;
	@Expose() fileCaption: string;
	@Expose() fileSize: number;  //size in kb
	@Expose() fileFormat:string;
	@Expose()
	@Transform(
		(value) => {
			if (value) {
				let map = new Map<string, BooleanAction>();
				for (let entry of Object.entries(value)) {
					map.set(entry[0], plainToClass(BooleanAction, entry[1]));
				}
				return map;
			} else {
				return new Map<string, BooleanAction>();
			}
		},
		{ toClassOnly: true }
	)
	readReceipts: Map<string, BooleanAction>;

	@Expose() isForwardedMessage: boolean;
	@Expose() status: ChatMessageStatus;
	@Expose() updatedOn: number;
	@Expose() createdOn: number;
	@Expose() senderName: string; //For WhatsApp: Vikram
	@Expose() platformSenderId: string; //For WhatsApp: 919407406690
	@Expose() messageDraftDurationInSeconds: number; //time for which a message will remain in draft state
	@Expose() sendOnlyAfterApproval: boolean; //settings for controlling the flow of messaegs
	@Expose() appointmentDetails:AppointmentInfoMinimal; // for consultation related messages

	@Type(() => BooleanAction)
	@Expose()
	approved: BooleanAction;

	@Type(() => ActionDoneBy)
	@Expose()
	sent: ActionDoneBy;

	@Type(() => BooleanAction)
	@Expose()
	deliveredOn: BooleanAction;

	@Type(() => BooleanAction)
	@Expose()
	failedOn: BooleanAction;

	@Expose() fromId: string;
	@Expose() toId: string;
	@Expose() chatListItemId: string;

	@Expose() templateId: string;
	@Expose() templateArgs: string[];

	@Expose() linksInText: string[];

	@Type(() => LinkPreview)
	@Expose() 
	linkPreview: LinkPreview;

	@Type(() => WAContext)
	@Expose()
	context: WAContext; //this has the reply or template response message id as well.

	@Expose() senderEntityType:string;
	@Expose() receiverEntityType:string;

	get displayMessageTime(): string {
		return dayjs(this.createdOn).fromNow();
	}

	get messageCellDisplayTime(): string {
		let today = dayjs(new Date()).format("YYYY-MM-DD");
		let date = dayjs(this.createdOn).format("YYYY-MM-DD");
		let time = dayjs(this.createdOn).format("hh:mm a");
		let tomorrow = dayjs(
			new Date().getTime() + 24 * 3600 * 1000
		).format("YYYY-MM-DD");
		if (date != null) {
			switch (date) {
				case today: {
					return "today" + ", " + time;
				}
				case tomorrow: {
					return "tomorrow" + ", " + time;
				}
				default:
					return dayjs(date).format("DD MMM") + ", " + time;
			}
		} else {
			return "-";
		}
	}

	get isInbound(): boolean {
		return this.toId == docvitaSenderId;
	}

	get outboundMessageSenderName(): string {
		let str = "";
		if (this.sent && this.sent.updatedByName) {
			str = this.sent.updatedByName;
		} else {
			str = "User";
		}
		return str;
	}

	get messageText(): string {
		let str = "";
		let textVal = this.text;
		if (textVal && textVal.includes("Footer: "))
			textVal = textVal.split("Footer: ")[0];
		if (textVal && textVal.includes("Buttons: "))
			textVal = textVal.split("Buttons: ")[0];
		if (textVal && textVal.includes("Body: ")) {
			str = textVal.split("Body: ")[1];
		} else if (textVal) {
			str = textVal;
		}
		if (str && str.length > 0) {
			let match = str.match(
				/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi
			);
			if (match && match.length > 0) {
				match.map((url) => {
					str = str.replace(
						url,
						'<a href="' +
							url +
							`" target="_BLANK" class="${
								this.isInbound ? "text-white" : "link-primary"
							}"
							}" title="${url}">` +
							(url && url.length > 40 ? url.slice(0, 40) + ".." : url) +
							"</a>"
					);
				});
			}
		}
		if (str.length > 0) {
			return str;
		} else {
			return null;
		}
	}
	get footerText(): string {
		let str = "";
		let textVal = this.text;
		if (textVal && textVal.includes("Buttons: "))
			textVal = textVal.split("Buttons: ")[0];
		if (textVal && textVal.includes("Footer: ")) {
			textVal = textVal.split("Footer: ")[1];
			str = textVal;
		}
		if (str.length > 0) {
			return str;
		} else {
			return null;
		}
	}

	get buttons(): string[] {
		let buttons: string[] = [];
		// if (this.text && this.text.includes("Buttons: ")) {
		// 	let arr: string[] = this.text.split("Buttons: ")[1].split(",");
		// 	for (let b of arr) {
		// 		if (b.split("] ")[1] && b.split("] ")[1].length > 0)
		// 			buttons.push(b.split("] ")[1]);
		// 	}
		// }
		if (this.text && this.text.includes("Buttons: ")) {
			let arr: string[] = this.text.split("Buttons: ")[1].split(", [");
			// console.log(arr);
			for (let b of arr) {
				if (b.split("] ")[1]?.length > 0) buttons.push(b.split("] ")[1]);
				else if(b.split("] ").length == 1) {
					buttons.push(b.split("] ")[0]);
				}
			}
		}
		return buttons;
	}

	get previewForChatList(): string {
		let str = "";
		let previewLength = 30;
		if (this.text) {
			str =
				this.text.length > previewLength
					? this.text.substr(0, previewLength) + "..."
					: this.text;
		} else if (this.fileUrl && this.fileUrl.length > 0) {
			str = "shared a file";
		}
		return str;
	}

	get replyMessagePreview(): string {
		let str = "";
		let previewLength = 30;
		let tempTxt = this.text;
		if (tempTxt) {
			tempTxt += "";
			tempTxt = tempTxt.split("\n").join("");
			str =
				tempTxt.length > previewLength
					? tempTxt.substr(0, previewLength) + "..."
					: tempTxt;
		} else if (this.fileUrl && this.fileUrl.length > 0) {
			str = "shared a file";
		}
		return str;
	}

	get fullPreviewForChatList(): string {
		let str = "";
		let previewLength = 200;
		if (this.text) {
			str =
				this.text.length > previewLength
					? this.text.substr(0, previewLength) + "..."
					: this.text;
		} else if (this.fileUrl && this.fileUrl.length > 0) {
			str = "shared a file";
		}
		return str;
	}
}


