import { PatientLeadsBackendService } from 'src/app/patient-leads/services/patient-leads-backend.service';
import { CopyClipboardService } from 'src/app/shared/services/copy-clipboard/copy-clipboard.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DoctorListingProfile } from '../../../shared/models-ts/Actors/Doctor/DoctorListingProfile';
import { Lead } from '../../../shared/models-ts/Entities/Lead';
import { User } from '../../../shared/models-ts/Actors/User/User';
import { ToastrService } from 'ngx-toastr';
import { LeadBooleanAction } from 'src/app/shared/models-ts/Entities/LeadBooleanAction';
import { DatePipe } from '@angular/common';
import { OnlineConsultationPlatform } from 'src/app/shared/models-ts/Enums/Enums';
import {
  CheckListActionKey,
  CheckListSection,
  CheckListEntity,
  UserType,
} from './next-steps.models';
import { NextStepModalComponent } from '../next-step-modal/next-step-modal.component';
import { OnlineMeetingDetails } from 'src/app/shared/models-ts/Entities/OnlineMeetingDetails';
import { DoctorsService } from 'src/app/shared/services/doctors/doctors.service';
import * as moment from 'moment';
import { WaTemplateMessagesService } from '../../services/wa-template-messages.service';
import { CreateNewGoogleMeetLinkService } from '../../../services/create-new-google-meet-link.service';
import { WaTemplateMessagesHelperService } from '../../services/wa-template-messages-helper.service';
import { Subscription } from 'rxjs';
import {
  AppointmentTimeHelper,
  AppointmentTimeState,
} from 'src/app/shared/services/appt-time-state/appt-time-state.helper';
import { CRMUser } from 'src/app/shared/models-ts/Actors/User/CRMUser';

@Component({
  selector: 'app-next-steps',
  templateUrl: './next-steps.component.html',
  styleUrls: ['./next-steps.component.css'],
  providers: [DatePipe],
})
export class NextStepsComponent implements OnInit {
  dummyTrueVariable: boolean = true;
  timerSub: Subscription;
  isOngoing: boolean;
  isInPast: boolean;
  isInFuture: boolean;

  _provider: DoctorListingProfile;
  _patientProfile: User;
  @Input() set provider(val: DoctorListingProfile) {
    this._provider = val;
    this.populateProviderWhasAppNumbers();
    this.initCheckLists();
  }

  get provider() {
    return this._provider;
  }

  @Input() set patientProfile(val: User) {
    this._patientProfile = val;
    this.populateClientWhatsAppNumbers();
  }

  get patientProfile() {
    return this._patientProfile;
  }

  patientWaNumbers = [];
  providerWaNumbers = [];
  tasks: CheckListEntity[] = [];
  shareInfoTasks: CheckListEntity[] = [];

  _lead: Lead;
  @Input() set lead(val: Lead) {
    this._lead = val;
    this.boolMap = val.checklistActions;
    this.linkSent = false;
    for (let k of this.requiredActionKeys) {
      if (!this.lead.checklistActions.has(k)) {
        this.lead.checklistActions.set(k, new LeadBooleanAction());
      }
    }
    this.initCheckLists();
    if (this._lead && this._lead.id) this.timerForLead(this._lead);
    // this.setProviderGoogleDynamicBool();
  }
  get lead() {
    return this._lead;
  }

  _currentHeroInfo: CRMUser;
  @Input() set currentHeroInfo(val: CRMUser) {
    this._currentHeroInfo = val;
    this.initCheckLists();
  }

  // currentHeroInfo.primaryContactNumber.number == '9407406690'
  //             || currentHeroInfo.primaryContactNumber.number == '9713311888'

  get currentHeroInfo() {
    return this._currentHeroInfo;
  }

  @Input() isLoadingprovider: boolean;

  clientCommunication: ClientCommunication = new ClientCommunication();
  providerCommunication: ProviderCommunication = new ProviderCommunication();

  @ViewChild(NextStepModalComponent)
  nextStepModalComponent: NextStepModalComponent;

  boolMap: Map<string, LeadBooleanAction> = new Map();

  //
  confirmationSection: CheckListSection;
  coordinatorIntroSection: CheckListSection;
  afterTheAppointmentSection: CheckListSection;
  beforeReschedulingSection: CheckListSection;
  whatsappGroupSection: CheckListSection;
  afterReschedulingSection: CheckListSection;
  sharingSection: CheckListSection;
  feedbackSection: CheckListSection;
  genericSection: CheckListSection;
  internationalSection: CheckListSection;
  paymentSection: CheckListSection;

  context: NextStepsComponent;

  platforms: OnlineConsultationPlatform[] = [
    OnlineConsultationPlatform.whatsapp,
    OnlineConsultationPlatform.zoom,
    OnlineConsultationPlatform.googlemeet,
    OnlineConsultationPlatform.phone,
  ];
  newPlatform: string;
  newPlatformMeetingUrl: string;
  enablePlatformSaveButton: boolean = false;

  linkSent: boolean = false;
  meetsLoader: boolean = false;
  isProviderGoogleDynamic: boolean;
  requiredActionKeys: CheckListActionKey[] = [
    CheckListActionKey.ClientConfirmationWA,
    CheckListActionKey.ClientConfirmationWAPunchline,
    CheckListActionKey.ProviderConfirmationWA,
    CheckListActionKey.InformProviderOfRescheduling,
    CheckListActionKey.InformClientOfRescheduling,
  ];
  apptTimeState: AppointmentTimeState = {
    onGoing: false,
    inPast: false,
    inFuture: false,
    before24Hr: false,
    in24Hr: false,
    inSTMinus5: false,
    inSTPlus10: false,
    in4Hr: false,
    before4Hr: false,
  };
  constructor(
    private _copyToClipboardService: CopyClipboardService,
    private _leadsBackendService: PatientLeadsBackendService,
    private _toastrService: ToastrService,
    private _waTemplateMessagesHelper: WaTemplateMessagesHelperService,
    private _waTemplateMessagesService: WaTemplateMessagesService
  ) {
    this.context = this;
  }

  ngOnInit(): void {}

  openSectionPopup(sections: []) {
    this.nextStepModalComponent.open(
      sections,
      this.currentHeroInfo,
      this.provider,
      this.patientProfile
    );
  }

  populateProviderWhasAppNumbers() {
    this.providerWaNumbers = [];
    if (this.provider && this.provider.getAllContactNumbers().length > 0) {
      for (let c of this.provider.getAllContactNumbers()) {
        if (c.isWhatsappContactNumber) {
          let _number = c.countryCode + c.number;
          if (!this.providerWaNumbers.includes(_number)) {
            this.providerWaNumbers.push(_number);
          }
        }
      }
    }
  }

  populateClientWhatsAppNumbers() {
    this.patientWaNumbers = [];
    if (
      this.patientProfile &&
      this.patientProfile.docvitaId != undefined &&
      this.patientProfile.getWhatsappContactNumbers().length > 0
    ) {
      for (let number of this.patientProfile.getWhatsappContactNumbers()) {
        let _number = number.countryCode + number.number;
        if (!this.patientWaNumbers.includes(_number)) {
          this.patientWaNumbers.push(_number);
        }
      }
    } else {
      if (
        this.patientProfile &&
        this.patientProfile.docvitaId != undefined &&
        this.patientContactInfo()
      ) {
        this.patientWaNumbers.push(
          this.patientContactInfo().countryCode +
            this.patientContactInfo().number
        );
      }
    }
  }

  patientContactInfo() {
    if (this.patientProfile && this.patientProfile.primaryContactNumber) {
      let contact = this.patientProfile.primaryContactNumber;
      if (contact.number && contact.number.length > 0) {
        return contact;
      } else {
        return null;
      }
    } else if (
      this.patientProfile &&
      this.patientProfile.contactNumbers.size > 0
    ) {
      let primary = this.patientProfile.getPrimaryContactNumbers()[0];
      return primary;
    } else {
      return null;
    }
  }

  initTaskList() {
    if (
      this.provider == null ||
      this.lead == null ||
      this.lead.consultationInfo == null ||
      this.currentHeroInfo == null ||
      this.patientProfile == null
    ) {
      return;
    }
    this.tasks = [];
    let clientConfirmationWA = new CheckListEntity(
      CheckListActionKey.ClientConfirmationWAPunchline,
      'Booking - Confirmation to Client',
      '',
      UserType.client,
      true
    );
    clientConfirmationWA.onClickFunc = this.sendViaWaApi;
    clientConfirmationWA.checkboxToggleFunc = this.recordEvent;
    clientConfirmationWA.isDone = this.lead.checklistActions.get(
      clientConfirmationWA.key
    ).bool;
    clientConfirmationWA.updatedOn = this.lead.checklistActions.get(
      clientConfirmationWA.key
    ).updatedOn;
    clientConfirmationWA.updatedByName = this.lead.checklistActions.get(
      clientConfirmationWA.key
    ).updatedByName;
    let providerConfirmationWA = new CheckListEntity(
      CheckListActionKey.ProviderConfirmationWA,
      'Booking - Confirmation to Provider',
      '',
      UserType.provider,
      true
    );
    providerConfirmationWA.onClickFunc = this.sendViaWaApi;
    providerConfirmationWA.checkboxToggleFunc = this.recordEvent;
    providerConfirmationWA.isDone = this.lead.checklistActions.get(
      providerConfirmationWA.key
    ).bool;
    providerConfirmationWA.updatedOn = this.lead.checklistActions.get(
      providerConfirmationWA.key
    ).updatedOn;
    providerConfirmationWA.updatedByName = this.lead.checklistActions.get(
      providerConfirmationWA.key
    ).updatedByName;
    this.tasks.push(clientConfirmationWA);
    this.tasks.push(providerConfirmationWA);

    this.initShareJoiningInfo();
    this.initReschedulingAppointmentConfirmation();
  }

  initReschedulingAppointmentConfirmation() {
    if (this.lead?.originalConsultationInfos?.length > 0) {
      let providerReschedulingConfirmationWA = new CheckListEntity(
        CheckListActionKey.InformProviderOfRescheduling,
        'Rescheduling - Confirmation to Provider',
        '',
        UserType.provider,
        true
      );
      providerReschedulingConfirmationWA.onClickFunc = this.sendViaWaApi;
      providerReschedulingConfirmationWA.checkboxToggleFunc = this.recordEvent;
      providerReschedulingConfirmationWA.isDone =
        this.lead.checklistActions.get(
          providerReschedulingConfirmationWA.key
        ).bool;
      providerReschedulingConfirmationWA.updatedOn =
        this.lead.checklistActions.get(
          providerReschedulingConfirmationWA.key
        ).updatedOn;
      providerReschedulingConfirmationWA.updatedByName =
        this.lead.checklistActions.get(
          providerReschedulingConfirmationWA.key
        ).updatedByName;
      this.tasks.push(providerReschedulingConfirmationWA);

      let clientReschedulingConfirmationWA = new CheckListEntity(
        CheckListActionKey.InformClientOfRescheduling,
        'Rescheduling - Confirmation to Client',
        '',
        UserType.client,
        true
      );
      clientReschedulingConfirmationWA.onClickFunc = this.sendViaWaApi;
      clientReschedulingConfirmationWA.checkboxToggleFunc = this.recordEvent;
      clientReschedulingConfirmationWA.isDone = this.lead.checklistActions.get(
        clientReschedulingConfirmationWA.key
      ).bool;
      clientReschedulingConfirmationWA.updatedOn =
        this.lead.checklistActions.get(
          clientReschedulingConfirmationWA.key
        ).updatedOn;
      clientReschedulingConfirmationWA.updatedByName =
        this.lead.checklistActions.get(
          clientReschedulingConfirmationWA.key
        ).updatedByName;
      this.tasks.push(clientReschedulingConfirmationWA);
    }
  }

  initShareJoiningInfo() {
    if (this.lead.platform) {
      let shareJoiningInfoWithClient = new CheckListEntity(
        CheckListActionKey.ShareAppointmentJoiningInfoClient,
        'Share Joining Info with Client' +
          (this.lead.onlineMeetingDetails?.platform
            ? '(' + this.lead.getPlatformTitle() + ')'
            : ''),
        '',
        UserType.client,
        true
      );
      shareJoiningInfoWithClient.onClickFunc = this.shareMeetingLink;
      shareJoiningInfoWithClient.checkboxToggleFunc =
        this.updatePatientProviderLinkStatus;
      shareJoiningInfoWithClient.isDone =
        this.lead.onlineMeetingDetails.linkSent;
      shareJoiningInfoWithClient.updatedOn =
        this.lead.onlineMeetingDetails.linkSentOn;
      shareJoiningInfoWithClient.updatedByName =
        this.lead.onlineMeetingDetails.linkSentByName;
      this.tasks.push(shareJoiningInfoWithClient);
    }
  }

  shareMeetingLink(
    context: NextStepsComponent,
    checkListEntity: CheckListEntity,
    number: string
  ) {
    if (context == null) {
      context = this;
    }
    context._waTemplateMessagesService
      .sendWAShareLink(number, context.lead.id)
      .then(() => {})
      .catch((err) => {
        console.error(err);
      });
  }

  updatePatientProviderLinkStatus(
    key: CheckListActionKey,
    connected: boolean,
    context?: NextStepsComponent
  ) {
    if (context == null) context = this;
    let json = {
      checklistActions: {
        ShareAppointmentJoiningInfoClient: {
          bool: connected,
          updatedById: context.currentHeroInfo?.docvitaId,
          updatedByName: context.currentHeroInfo?.name,
          updatedOn: new Date().getTime(),
        },
      },
      onlineMeetingDetails: {
        linkSent: connected,
        linkSentOn: new Date().getTime(),
        linkSentById: context.currentHeroInfo?.docvitaId,
        linkSentByName: context.currentHeroInfo?.name,
      },
    };

    context._leadsBackendService
      .setMergeLead(context.lead.id, json)
      .then(() => {
        context._toastrService.success('Status updated!');
      })
      .catch((err) => {
        console.error(err);
        context._toastrService.success('Status failed to update!');
      });
  }

  initCheckLists() {
    if (
      this.provider == null ||
      this.lead == null ||
      this.lead.consultationInfo == null ||
      this.currentHeroInfo == null ||
      this.patientProfile == null
    ) {
      return;
    }
    this.initTaskList();
    this.initConfirmationCheckList();
    this.initCoordinatorIntroCheckList();
    this.initAfterTheAppointmentSection();
    this.initBeforeReschedulingSection();
    this.initWhatsappGroupSection();
    this.initAfterrReschedulingSection();
    this.initSharingSection();
    this.initGenericSection();
    this.initPaymentSection();
    // this.initInternationalSection(); //not needed for now
    this.initFeedbackSection();
    // this.initOnlinePlatformConfig();
  }

  capitalize(str: string) {
    if (str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    } else {
      return '';
    }
  }

  initGenericSection() {
    let entities: CheckListEntity[] = [];
    let requestDroppedMessage =
      this.clientCommunication.getRequestedDroppedMessage(
        this.provider,
        this.lead
      );
    let requestDropped = new CheckListEntity(
      CheckListActionKey.RequestDroppedToProvider,
      'Client - dropped a request',
      requestDroppedMessage,
      UserType.client
    );
    entities.push(requestDropped);
    let preliminaryCallMessage =
      this.providerCommunication.getPreliminaryRequestMessage(
        this.provider,
        this.lead
      );
    let preliminaryCall = new CheckListEntity(
      CheckListActionKey.PreliminaryCallRequestToProvider,
      'Provider - Preliminary Call Request',
      preliminaryCallMessage,
      UserType.provider
    );
    entities.push(preliminaryCall);
    let shareProviderProfileLinkMessage =
      this.clientCommunication.getProviderProfileLinkForFutureMessage(
        this.provider
      );
    let shareProviderProfileLink = new CheckListEntity(
      CheckListActionKey.ShareProviderProfileLink,
      'Share Provider Profile Link',
      shareProviderProfileLinkMessage,
      UserType.client
    );
    entities.push(shareProviderProfileLink);
    this.genericSection = new CheckListSection(
      'Generic Requests/Phrases',
      entities
    );
  }

  timerForLead(lead: Lead) {
    if (this.timerSub) {
      this.timerSub.unsubscribe();
    }
    this.timerSub = new AppointmentTimeHelper()
      .getApptTimeState(
        lead.consultationInfo.date,
        lead.consultationInfo.timeslot
      )
      .subscribe((val) => {
        this.apptTimeState = val;
      });
  }

  initPaymentSection() {
    let entities: CheckListEntity[] = [];
    let message1 = this.clientCommunication.getSessionInviteMessage(
      this.lead,
      this.provider
    );
    let checklistItem1 = new CheckListEntity(
      CheckListActionKey.ShareAppointmentInviteLinkWithClient,
      'Invite for next session',
      message1,
      UserType.client
    );
    entities.push(checklistItem1);

    let message2 = this.clientCommunication.sharedPaymentLinkMessage(
      this.lead,
      this.provider
    );
    let checklistItem2 = new CheckListEntity(
      CheckListActionKey.SharePaymentLinkWithClient,
      'Share payment link for booking',
      message2,
      UserType.client
    );
    entities.push(checklistItem2);

    let message3 =
      this.clientCommunication.requestForExtraAmountForExtendedSessionMessage(
        this.lead,
        this.provider
      );
    let checklistItem3 = new CheckListEntity(
      CheckListActionKey.RequestForExtendedSessionPayment,
      'Share payment link for extra payment',
      message3,
      UserType.client
    );
    entities.push(checklistItem3);
    // SharePaymentLinkWithClient = 'SharePaymentLinkWithClient',
    // ShareAppointmentInviteLinkWithClient = 'ShareAppointmentBookingLinkWithClient',
    // RequestForExtendedSessionPayment = 'RequestForExtendedSessionPayment',
    this.paymentSection = new CheckListSection(
      'Payments & Next Booking',
      entities
    );
  }

  initInternationalSection() {
    let entities: CheckListEntity[] = [];
    let internationalNumberSupportMessage =
      this.clientCommunication.getInternationalNumberSupport();
    let internationalNumberSupport = new CheckListEntity(
      CheckListActionKey.InternationalNumberSupport,
      'International Numbers Support',
      internationalNumberSupportMessage
    );
    entities.push(internationalNumberSupport);
    let careCoordinatorIntroOnlyMessage =
      this.clientCommunication.getCareCoordinatorIntroOnly(
        this.currentHeroInfo
      );
    let careCoordinatorIntroOnly = new CheckListEntity(
      CheckListActionKey.InternationalNumberSupport,
      'Care Coordinator Intro',
      careCoordinatorIntroOnlyMessage
    );
    entities.push(careCoordinatorIntroOnly);

    let askForInfoInternationalClientMessage =
      this.clientCommunication.askForInfo();
    let askForInfoInternationalClient = new CheckListEntity(
      CheckListActionKey.AskForInfoInternationalClient,
      'Ask for client info',
      askForInfoInternationalClientMessage
    );
    entities.push(askForInfoInternationalClient);

    let sharePaymentLinkWithInternationalClientMessage =
      this.clientCommunication.sendPaymentLinkToInternationalClient();
    let sharePaymentLinkWithInternationalClient = new CheckListEntity(
      CheckListActionKey.SharePaymentLinkWithInternationalClient,
      'Send Payment Link',
      sharePaymentLinkWithInternationalClientMessage
    );
    entities.push(sharePaymentLinkWithInternationalClient);

    this.internationalSection = new CheckListSection(
      'International Client',
      entities
    );
  }

  initFeedbackSection() {
    let entities: CheckListEntity[] = [];
    let feedbackRequestMessage =
      this.clientCommunication.getBeforeFeedbackMessage(
        this.provider,
        this.lead,
        this.currentHeroInfo
      );
    let feedbackRequest = new CheckListEntity(
      CheckListActionKey.FeedbackRequest,
      'Ask Feedback (only for first interaction)',
      feedbackRequestMessage,
      UserType.client
    );
    entities.push(feedbackRequest);
    let feedbackReceivedMessage =
      this.clientCommunication.getAfterFeedbackMessage(this.lead);
    let feedbackReceived = new CheckListEntity(
      CheckListActionKey.FeedbackReceived,
      'Feedback Received',
      feedbackReceivedMessage,
      UserType.client
    );
    entities.push(feedbackReceived);
    let shareProviderProfileLinkMessage =
      this.clientCommunication.getProviderProfileLinkForFutureMessage(
        this.provider
      );
    let shareProviderProfileLink = new CheckListEntity(
      CheckListActionKey.ShareProviderProfileLink,
      'Share Provider Profile Link (After feedback)',
      shareProviderProfileLinkMessage,
      UserType.client
    );
    entities.push(shareProviderProfileLink);
    this.feedbackSection = new CheckListSection('Client Feedback', entities);
  }
  initWhatsappGroupSection() {
    let entities: CheckListEntity[] = [];
    let WAGroupTitleMessage =
      this.clientCommunication.getConsultationGroupTitle(
        this.provider,
        this.lead
      );
    let groupTitle = new CheckListEntity(
      CheckListActionKey.CreateWAGroupTitle,
      'Title',
      WAGroupTitleMessage
    );
    entities.push(groupTitle);

    let WAGroupIntroMessage =
      this.clientCommunication.getConsultationGroupIntroFirstMessage(
        this.provider,
        this.lead
      );
    let groupIntro = new CheckListEntity(
      CheckListActionKey.CreateWAGroupTitleIntro,
      'Introduction',
      WAGroupIntroMessage
    );
    entities.push(groupIntro);

    let WAGroupInstructionMessage =
      this.clientCommunication.getConsultationGroupIntroSecondMessage(
        this.provider,
        this.lead
      );
    let groupInstruction = new CheckListEntity(
      CheckListActionKey.CreateWAGroupInstruction,
      'Instruction',
      WAGroupInstructionMessage
    );
    entities.push(groupInstruction);

    this.whatsappGroupSection = new CheckListSection(
      'Whatsapp Group',
      entities
    );
  }

  initAfterrReschedulingSection() {
    let entities: CheckListEntity[] = [];
    let informProviderOfReschedulingMessage =
      this.providerCommunication.getInformProviderOfReschedulingMessage(
        this.provider,
        this.lead
      );
    let informProviderOfRescheduling = new CheckListEntity(
      CheckListActionKey.InformProviderOfRescheduling,
      'Provider - Inform about rescheduled appointment',
      informProviderOfReschedulingMessage,
      UserType.provider,
      true
    );
    entities.push(informProviderOfRescheduling);
    let informClientOfReschedulingMessage =
      this.clientCommunication.getWAReschedulingMessage(
        this.provider,
        this.lead
      );
    let informClientOfRescheduling = new CheckListEntity(
      CheckListActionKey.InformClientOfRescheduling,
      'Client - Inform about rescheduled appointment',
      informClientOfReschedulingMessage,
      UserType.client,
      true
    );
    entities.push(informClientOfRescheduling);
    let thankyouForReschedulingMessage =
      this.clientCommunication.getThankYouForReschedulingMessage(
        this.provider,
        this.lead
      );
    let thankyou = new CheckListEntity(
      CheckListActionKey.ThankYouForRescheduling,
      'Client/Provider - Thank you',
      thankyouForReschedulingMessage,
      UserType.client
    );
    entities.push(thankyou);
    this.afterReschedulingSection = new CheckListSection(
      'After Rescheduling Appointment',
      entities
    );
  }

  initSharingSection() {
    let entities: CheckListEntity[] = [];
    let shareFilesWithProviderMessage =
      this.providerCommunication.getFileSharingMessage(
        this.provider,
        this.lead
      );
    let shareFilesWithProvider = new CheckListEntity(
      CheckListActionKey.FileSharingWithProvider,
      'Provider - Share client files/images',
      shareFilesWithProviderMessage,
      UserType.provider
    );
    entities.push(shareFilesWithProvider);
    let shareTextWithProviderMessage =
      this.providerCommunication.getTextSharingMessage(
        this.provider,
        this.lead
      );
    let shareTextWithProvider = new CheckListEntity(
      CheckListActionKey.TextSharingWithProvider,
      'Provider - Share client text message',
      shareTextWithProviderMessage,
      UserType.provider
    );
    entities.push(shareTextWithProvider);
    this.sharingSection = new CheckListSection(
      'Share Files/Images/Texts',
      entities
    );
  }

  initBeforeReschedulingSection() {
    let entities: CheckListEntity[] = [];
    let rescheduleRequestClientMessage =
      this.clientCommunication.getWARequestRescheduleMessageToClient(
        this.provider,
        this.lead
      );
    let requestToRescheduleClient = new CheckListEntity(
      CheckListActionKey.RequestToRescheduledClient,
      'Client - Request to reschedule',
      rescheduleRequestClientMessage,
      UserType.client
    );
    entities.push(requestToRescheduleClient);
    this.beforeReschedulingSection = new CheckListSection(
      'Reschedule Appointment',
      entities
    );
  }

  initAfterTheAppointmentSection() {
    let entities: CheckListEntity[] = [];
    let prescriptionSharingMessage =
      this.clientCommunication.getPrescriptionSharingMessage(
        this.provider,
        this.lead
      );
    let prescriptionSharing = new CheckListEntity(
      CheckListActionKey.PrescriptionShareWA,
      'Client - Send Prescription',
      prescriptionSharingMessage,
      UserType.client
    );
    entities.push(prescriptionSharing);
    let askForFollowUpMessage =
      this.clientCommunication.getAskForFollowUpMessage(
        this.provider,
        this.lead
      );
    let askForFollowUp = new CheckListEntity(
      CheckListActionKey.AskForFollowUpWA,
      'Provider - Ask for follow up',
      askForFollowUpMessage,
      UserType.provider
    );
    entities.push(askForFollowUp);

    if (this.lead.consultationInfo.isFirstAppointmentWithCurrentProvider) {
      let askforClientFeedbackMessage =
        this.clientCommunication.getBeforeFeedbackMessage(
          this.provider,
          this.lead,
          this.currentHeroInfo
        );
      let askForClientFeedback = new CheckListEntity(
        CheckListActionKey.AskForClientFeedback,
        'Client - Ask for feedback',
        askforClientFeedbackMessage
      );
      entities.push(askForClientFeedback);

      let thankClientForFeedbackMessage =
        this.clientCommunication.getAfterFeedbackMessage(this.lead);
      let thankClientForFeedback = new CheckListEntity(
        CheckListActionKey.ThankClientForGivingFeedback,
        'Client - Thank you for the feedback',
        thankClientForFeedbackMessage
      );
      entities.push(thankClientForFeedback);
    }
    this.afterTheAppointmentSection = new CheckListSection(
      'After Appointment',
      entities
    );
  }

  initCoordinatorIntroCheckList() {
    let entities: CheckListEntity[] = [];
    let CCIntroMessage = this.clientCommunication.getCareCoordinatorMessage(
      this.provider,
      this.lead,
      this.currentHeroInfo
    );

    let coordinatorIntro = new CheckListEntity(
      CheckListActionKey.CoordinatorIntroWA,
      'Send Coordinator Intro',
      CCIntroMessage,
      UserType.client
    );

    let CCInstructionsToConnect =
      this.clientCommunication.getInstructionsToConnect(this.provider);

    let instructionsToConnect = new CheckListEntity(
      CheckListActionKey.CoordinatorInstructionToConnect,
      'Instructions to Connect',
      CCInstructionsToConnect,
      UserType.client
    );
    entities.push(coordinatorIntro);

    let wishGoodLuckClientMessage = this.clientCommunication.getGoodLuckMessage(
      this.provider,
      this.lead,
      this.currentHeroInfo
    );

    let goodLuck = new CheckListEntity(
      CheckListActionKey.CoordinatorInstructionToConnect,
      'Client - Wish them luck (10 mins before)',
      wishGoodLuckClientMessage,
      UserType.client
    );
    entities.push(goodLuck);

    entities.push(instructionsToConnect);

    this.coordinatorIntroSection = new CheckListSection(
      'To Client - Few hours before',
      entities
    );
  }

  initConfirmationCheckList() {
    let entities: CheckListEntity[] = [];
    // Confirmation Section
    let clientConfirmationWAMessage =
      this.clientCommunication.getWABookingConfirmationMessage(
        this.provider,
        this.lead
      );

    let clientConfirmationWAWithPunchlineMessage =
      clientConfirmationWAMessage +
      '\n\n' +
      this.clientCommunication.getWABookingPunchlineForFirstBooking(
        this.provider,
        this.lead
      );

    let clientConfirmationWAWithPunchline = new CheckListEntity(
      CheckListActionKey.ClientConfirmationWAPunchline,
      'Client - Send Confirmation with Punchline on WA',
      clientConfirmationWAWithPunchlineMessage,
      UserType.client,
      true
    );
    let clientConfirmationWA = new CheckListEntity(
      CheckListActionKey.ClientConfirmationWA,
      'Client - Send Confirmation on WA',
      clientConfirmationWAMessage,
      UserType.client,
      true
    );

    let providerConfirmationWAMessage =
      this.providerCommunication.getWABookingConfirmationMessage(
        this.provider,
        this.lead
      );

    let providerConfirmationWA = new CheckListEntity(
      CheckListActionKey.ProviderConfirmationWA,
      'Provider - Send Confirmation on WA',
      providerConfirmationWAMessage,
      UserType.provider,
      true
    );

    let isFirstBooking: boolean =
      this.lead.consultationInfo.isFirstAppointmentWithCurrentProvider;

    entities.push(
      isFirstBooking ? clientConfirmationWAWithPunchline : clientConfirmationWA
    );
    entities.push(providerConfirmationWA);

    this.confirmationSection = new CheckListSection('Confirmations', entities);
  }

  openCommonPhrasesModal() {}

  public get doesProviderExist(): boolean {
    return this.provider != null && this.provider.name != null;
  }

  public get isLeadCall(): boolean {
    return this.lead != null && this.lead.status == 'CALL_NOW';
  }

  public get isLeadCallback(): boolean {
    return this.lead != null && this.lead.status == 'CALLBACK_LATER';
  }

  public get isBooked(): boolean {
    return this.lead != null && this.lead.status == 'BOOKED';
  }

  public get isLeadDocVita(): boolean {
    return this.lead != null && this.lead.mapStatus() == 'To DocVita';
  }

  public get isConfirmationWASentProvider(): boolean {
    return (
      this.lead != null &&
      this.lead.confirmationWhatsAppSentProvider != null &&
      this.lead.confirmationWhatsAppSentProvider.bool
    );
  }

  public get isClientWhatsAppReachoutSent(): boolean {
    return (
      this.lead != null &&
      this.lead.whatsappReachoutSent != null &&
      this.lead.whatsappReachoutSent.bool
    );
  }

  public get isClientProviderGroupCreated(): boolean {
    return (
      this.lead != null &&
      this.lead.whatsappGroupCreated != null &&
      this.lead.whatsappGroupCreated.bool
    );
  }

  public get isProviderWhatsAppReachoutSent(): boolean {
    //TODO: Interfacing
    return false;
  }

  comingSoon() {
    this._toastrService.info('Coming Soon');
  }

  copyTheDamnThing(someStupidText: string) {
    this._copyToClipboardService.copyToClipoard(someStupidText);
  }

  // Request Message to Provider to Interact With Client
  copyInteractionRequestForProvider() {
    this._copyToClipboardService.copyToClipoard(
      this.providerCommunication.getRequestMessageToContactAndSchedule(
        this.provider,
        this.lead
      )
    );
  }

  // WhatsApp Reachout after Call / Callback
  copyWAReachoutFirstMessage() {
    this._copyToClipboardService.copyToClipoard(
      this.clientCommunication.getWAReachoutFirstMessage(
        this.provider,
        this.lead
      )
    );
  }

  copyWAReachoutSecondMessage() {
    this._copyToClipboardService.copyToClipoard(
      this.clientCommunication.getWAReachoutSecondMessage()
    );
  }

  copyBeforeFeedbackMessage() {
    this._copyToClipboardService.copyToClipoard(
      this.clientCommunication.getBeforeFeedbackMessage(
        this.provider,
        this.lead,
        this.currentHeroInfo
      )
    );
  }

  copyAfterFeedbackMessage() {
    this._copyToClipboardService.copyToClipoard(
      this.clientCommunication.getAfterFeedbackMessage(this.lead)
    );
  }

  // for Confirmed Consultation
  copyWABookingConfirmationClient() {
    this._copyToClipboardService.copyToClipoard(
      this.clientCommunication.getWABookingConfirmationMessage(
        this.provider,
        this.lead
      )
    );
  }
  copyWABookingConfirmationAndPunchlineForFirstBookingClient() {
    this._copyToClipboardService.copyToClipoard(
      this.clientCommunication.getWABookingConfirmationMessage(
        this.provider,
        this.lead
      ) +
        '\n\n' +
        this.clientCommunication.getWABookingPunchlineForFirstBooking(
          this.provider,
          this.lead
        )
    );
  }

  // for rescheduling
  copyRescheduleRequestToClientMessage() {
    this._copyToClipboardService.copyToClipoard(
      this.clientCommunication.getWARequestRescheduleMessageToClient(
        this.provider,
        this.lead
      )
    );
  }
  copyReschedulingMessage() {
    this._copyToClipboardService.copyToClipoard(
      this.clientCommunication.getWAReschedulingMessage(
        this.provider,
        this.lead
      )
    );
  }

  // for Care Coordination
  copyCareCoordinatorIntroduction() {
    this._copyToClipboardService.copyToClipoard(
      this.clientCommunication.getCareCoordinatorMessage(
        this.provider,
        this.lead,
        this.currentHeroInfo
      )
    );
  }
  copyGoodLuckMessage() {
    this._copyToClipboardService.copyToClipoard(
      this.clientCommunication.getGoodLuckMessage(
        this.provider,
        this.lead,
        this.currentHeroInfo
      )
    );
  }
  copyAvailableForSupportMessage() {
    this._copyToClipboardService.copyToClipoard(
      this.clientCommunication.getAvailableForSupportMessage()
    );
  }

  copyInstructionsToConnect() {
    this._copyToClipboardService.copyToClipoard(
      this.clientCommunication.getInstructionsToConnect(this.provider)
    );
  }
  copyZoomLinkToConnect() {
    this._copyToClipboardService.copyToClipoard(
      this.clientCommunication.getZoomLinkMessageForAppointment(
        this.provider,
        this.lead
      )
    );
  }

  copyWABookingConfirmationProvider() {
    this._copyToClipboardService.copyToClipoard(
      this.providerCommunication.getWABookingConfirmationMessage(
        this.provider,
        this.lead
      )
    );
  }

  // WhatsApp Group for Confirmed Consultations
  copyWhatsAppGroupTitle() {
    this._copyToClipboardService.copyToClipoard(
      this.clientCommunication.getConsultationGroupTitle(
        this.provider,
        this.lead
      )
    );
  }

  copyWhatsAppIntroFirstMessage() {
    this._copyToClipboardService.copyToClipoard(
      this.clientCommunication.getConsultationGroupIntroFirstMessage(
        this.provider,
        this.lead
      )
    );
  }

  copyWhatsAppIntroSecondMessage() {
    this._copyToClipboardService.copyToClipoard(
      this.clientCommunication.getConsultationGroupIntroSecondMessage(
        this.provider,
        this.lead
      )
    );
  }

  getTaskCompleteByString(key) {
    return '-';
  }

  currentChecklistAction(key: CheckListActionKey) {
    if (
      this.lead.checklistActions != null &&
      this.lead.checklistActions.has(key)
    ) {
      return this.lead.checklistActions.get(key).bool;
    } else {
      return false;
    }
  }

  recordEvent(key: string, sentStatus: boolean, that?: NextStepsComponent) {
    if (that == null) {
      that = this;
    }
    let finalJson: any = {};
    finalJson['checklistActions.' + key + '.bool'] = sentStatus;
    finalJson['checklistActions.' + key + '.updatedById'] =
      that.currentHeroInfo.docvitaId;
    finalJson['checklistActions.' + key + '.updatedByName'] =
      that.currentHeroInfo.name;
    finalJson['checklistActions.' + key + '.updatedOn'] = new Date().getTime();

    that._leadsBackendService
      .updateLead(that.lead.id, finalJson)
      .then(() => {
        if (sentStatus) {
          that._toastrService.success('Message sent: ' + key);
        } else {
          that._toastrService.success('Undo');
        }
      })
      .catch((err) => {
        that._toastrService.error('Something went wrong!');
        console.error(err);
      });
  }

  whatsappReachOutSent(sentStatus: boolean) {
    this._leadsBackendService
      .updateLead(this.lead.id, {
        'whatsappReachoutSent.bool': sentStatus,
        'whatsappReachoutSent.updatedById': this.currentHeroInfo.docvitaId,
        'whatsappReachoutSent.updatedByName': this.currentHeroInfo.name,
        'whatsappReachoutSent.updatedOn': new Date().getTime(),
      })
      .then(() => {
        if (sentStatus) {
          this._toastrService.success('Reach out message sent!');
        } else {
          this._toastrService.success('Undo');
        }
      })
      .catch((err) => {
        this._toastrService.error('Something went wrong!');
        console.error(err);
      });
  }

  whatsappGroupCreated(createdStatus: boolean) {
    this._leadsBackendService
      .updateLead(this.lead.id, {
        'whatsappGroupCreated.bool': createdStatus,
        'whatsappGroupCreated.updatedById': this.currentHeroInfo.docvitaId,
        'whatsappGroupCreated.updatedByName': this.currentHeroInfo.name,
        'whatsappGroupCreated.updatedOn': new Date().getTime(),
      })
      .then(() => {
        if (createdStatus) {
          this._toastrService.success('Group created!');
        } else {
          this._toastrService.success('Undo');
        }
      })
      .catch((err) => {
        this._toastrService.error('Something went wrong!');
        console.error(err);
      });
  }

  feedbackReceived(createdStatus: boolean) {
    this._leadsBackendService
      .updateLead(this.lead.id, {
        'clientFeedbackReceived.bool': createdStatus,
        'clientFeedbackReceived.updatedById': this.currentHeroInfo.docvitaId,
        'clientFeedbackReceived.updatedByName': this.currentHeroInfo.name,
        'clientFeedbackReceived.updatedOn': new Date().getTime(),
      })
      .then(() => {
        if (createdStatus) {
          this._toastrService.success('feedback received!');
        } else {
          this._toastrService.success('Undo');
        }
      })
      .catch((err) => {
        this._toastrService.error('Something went wrong!');
        console.error(err);
      });
  }

  sendViaWaApi(
    that: NextStepsComponent,
    checkListEntity: CheckListEntity,
    number: string
  ) {
    console.log();
    switch (checkListEntity.key) {
      case CheckListActionKey.ClientConfirmationWA: {
        that._waTemplateMessagesHelper.sendClientConfirmationUsingWaAPI(
          number,
          that.lead.consultationInfo.appointmentId
        );
        break;
      }
      case CheckListActionKey.ClientConfirmationWAPunchline: {
        that._waTemplateMessagesHelper.sendClientConfirmationUsingWaAPI(
          number,
          that.lead.consultationInfo.appointmentId
        );
        break;
      }
      case CheckListActionKey.ProviderConfirmationWA: {
        that._waTemplateMessagesHelper.sendProviderConfirmationUsingWaAPI(
          number,
          that.lead.consultationInfo.appointmentId
        );
        break;
      }
      case CheckListActionKey.InformProviderOfRescheduling: {
        that._waTemplateMessagesHelper.sendProviderRescheduleConfirmationUsingWaAPI(
          that.lead.consultationInfo?.appointmentId,
          number
        );
        break;
      }
      case CheckListActionKey.InformClientOfRescheduling: {
        that._waTemplateMessagesHelper.sendClientRescheduleConfirmationUsingWaAPI(
          that.lead.consultationInfo?.appointmentId,
          number
        );
        break;
      }
    }
  }
}

export class ProviderCommunication {
  getWABookingConfirmationMessage(provider: DoctorListingProfile, lead: Lead) {
    let greeting = '✅ Dear ' + provider.getFirstNameWithSalutation() + ', ';
    let serviceTitle = provider.isPsychologist ? 'session' : 'consultation';
    let clientInfo =
      'a client - *' +
      lead.patientName +
      '* has booked a confirmed ' +
      serviceTitle +
      ' with you for ' +
      lead.consultationInfo.getStartTimeIn12HoursFormat() +
      ' ' +
      lead.consultationInfo.getDisplayDayAppointmentConfirmation() +
      '.';

    return greeting + clientInfo;
  }

  getWABookingPunchlineForFirstBooking(
    provider: DoctorListingProfile,
    lead: Lead
  ) {
    let serviceTitle = provider.isPsychologist ? 'session' : 'consultation';
    let punchline =
      "When it's time for your " +
      serviceTitle +
      ', one of our care coordinators will reach out and help you connect with ' +
      lead.patientName +
      '.';
    return punchline;
  }

  getFileSharingMessage(provider: DoctorListingProfile, lead: Lead) {
    if (lead == null || provider == null) {
      return '';
    }
    let message =
      'These are from your client ' + lead.consultationInfo.patientName;
    return message;
  }

  getTextSharingMessage(provider: DoctorListingProfile, lead: Lead) {
    if (lead == null || provider == null) {
      return '';
    }
    let message =
      'You have a message from ' +
      lead.consultationInfo.patientName +
      '\n' +
      '-----------------' +
      '\n\n\n' +
      '-----------------';
    return message;
  }

  getInformProviderOfReschedulingMessage(
    provider: DoctorListingProfile,
    lead: Lead
  ) {
    if (lead == null || provider == null) {
      return '';
    }
    let providerGreeting = provider.getFirstNameWithSalutation() + ', ';
    let message =
      'I have rescheduled ' +
      '*' +
      lead.patientName +
      "'s" +
      '*' +
      ' session to ' +
      lead.consultationInfo.getStartTimeIn12HoursFormat() +
      ', ' +
      lead.consultationInfo.getDisplayDay();
    return providerGreeting + message;
  }

  getRequestMessageToContactAndSchedule(
    provider: DoctorListingProfile,
    lead: Lead
  ) {
    if (lead == null || provider == null) {
      return '';
    }
    let providerGreeting = '*' + provider.getFirstNameWithSalutation() + '*, ';
    var interactionMessage = '';
    if (lead.mapStatus() == 'Call')
      interactionMessage = 'a potential client *called* you on DocVita.\n\n';
    if (lead.mapStatus() == 'Callback')
      interactionMessage =
        'a potential client has requested a *callback* on DocVita.\n\n';
    if (lead.mapStatus() == 'Messaged')
      interactionMessage =
        'a potential client has sent a *message* on DocVita.\n\n';

    var punchLine =
      '\nProbably wants to speak before booking a ' +
      (provider.isPsychologist ? 'session.' : 'consultation.');
    var punchLine2 =
      ' If you could speak with the client and schedule an appointment, our care team will coordinate. 😊';
    return (
      providerGreeting +
      interactionMessage +
      this.getClientContactInfo(lead) +
      punchLine +
      punchLine2
    );
  }

  getPreliminaryRequestMessage(provider: DoctorListingProfile, lead: Lead) {
    if (lead == null || provider == null) {
      return '';
    }
    let providerGreeting = '*' + provider.getFirstNameWithSalutation() + '*';
    let message =
      ', there’s a client who has requested a preliminary call before booking a session, ' +
      'What time is convenient for you to take up the call?';
    return providerGreeting + message;
  }

  getLeadContact(lead: Lead) {
    return (
      lead.patientContactInfo.countryCode + '-' + lead.patientContactInfo.number
    );
  }
  getClientContactInfo(lead: Lead) {
    if (
      lead != null &&
      lead.patientName != null &&
      lead.patientContactInfo != null
    ) {
      var nameRow = 'Name: *' + lead.patientName + '*';
      var contactRow =
        'Contact: +' +
        this.getLeadContact(lead) +
        ' ' +
        (lead.isVerified ? '(Verified)' : '(Unverified)');
      var cityRow = 'City: *' + lead.cityNameForDisplay + '*';
      var messageRow = lead.enquiryMessage
        ? 'Message: ' + lead.enquiryMessage + '\n'
        : '';
      return nameRow + '\n' + contactRow + '\n' + cityRow + '\n' + messageRow;
    } else {
      return '';
    }
  }
}

export class ClientCommunication {
  mapOfPLatformChoice: Map<string, string> = new Map<string, string>();
  mapOfZoomLinks: Map<string, string> = new Map<string, string>();

  constructor() {
    this.mapOfPLatformChoice['-MZpZh_S-l6ipfGK5Nbw'] = 'Zoom'; // Dr. Sanjay
    this.mapOfPLatformChoice['-MZpv17PmbzsHOXtTtpt'] = 'Zoom'; // Dr. Shweta

    this.mapOfZoomLinks['-MZpZh_S-l6ipfGK5Nbw'] =
      'https://us04web.zoom.us/j/4599840271?pwd=dUw2WmJZbEx4aXdKcTRnT0h1aVovZz09' +
      '\n\n' +
      'Meeting ID: 459 984 0271' +
      '\n' +
      'Passcode: 5iZ7H1';
    this.mapOfZoomLinks['-MZpv17PmbzsHOXtTtpt'] =
      'https://zoom.us/j/4761145729?pwd=U2FxdzNOd0xTWXVnVVJBZndURlB5Zz09' +
      '\n\n' +
      'Meeting ID: 476 114 5729' +
      '\n' +
      'Passcode: E0zxT2';
  }

  getPlatformOfChoice(providerId: string): string {
    if (this.mapOfPLatformChoice[providerId] != null) {
      return this.mapOfPLatformChoice[providerId];
    } else {
      return 'Unknown';
    }
  }

  getZoomLinkForAppointment(providerId: string): string {
    if (this.mapOfZoomLinks[providerId] != null) {
      return this.mapOfZoomLinks[providerId];
    } else {
      return null;
    }
  }

  isOnZoom(providerId: string): boolean {
    return this.getPlatformOfChoice(providerId) == 'Zoom';
  }

  getZoomLinkMessageForAppointment(provider: DoctorListingProfile, lead: Lead) {
    let zoomLinkForAppointment = this.getZoomLinkForAppointment(provider.id);
    if (zoomLinkForAppointment == null) return '';
    let greeting =
      'Hi ' +
      lead.getClientFirstName() +
      ' ' +
      lead.getAppropriateNameSuffix() +
      ', For your ';
    let serviceTitle = provider.isPsychologist ? 'session' : 'consultation';
    let message =
      ' with ' +
      provider.getFirstNameWithSalutation() +
      ' at ' +
      lead.consultationInfo.getStartTimeIn12HoursFormat() +
      ' ' +
      lead.consultationInfo.getDisplayDay() +
      ', ' +
      'kindly use the following link to join the Zoom call.\n\n' +
      zoomLinkForAppointment;
    return greeting + serviceTitle + message;
  }
  getPrescriptionSharingMessage(provider: DoctorListingProfile, lead: Lead) {
    let prefix = lead.getAppropriateNameSuffix(true);
    if (prefix.length > 0) {
      prefix += ', here';
    } else {
      prefix += 'Here';
    }
    let message =
      prefix +
      ` is your prescription from today's consultation. ` +
      "\n\n"+provider.fullName()+ " has recommended to book your follow up after .";
    return message;
  }

  getAskForFollowUpMessage(provider: DoctorListingProfile, lead: Lead) {
    let message = 'Will this client need a followup?';
    return message;
  }

  getCareCoordinatorMessage(
    provider: DoctorListingProfile,
    lead: Lead,
    coordinator: CRMUser
  ) {
    let greeting =
      'Hi ' +
      lead.getClientFirstName() +
      ' ' +
      lead.getAppropriateNameSuffix() +
      ', ';

    let coordinatorIntro =
      'this is ' +
      coordinator.getFirstName() +
      ' - your Care Coordinator at DocVita for your appointment ';
    // let serviceTitle = provider.isPsychologist ? 'session' : 'consultation';
    let aboutTheBooking =
      'with ' +
      provider.getFirstNameWithSalutation() +
      ' at ' +
      lead.consultationInfo.getStartTimeIn12HoursFormat() +
      ' ' +
      lead.consultationInfo.getDisplayDay() +
      '.';

    return greeting + coordinatorIntro + aboutTheBooking;
  }

  getInstructionsToConnect(provider: DoctorListingProfile) {
    let serviceTitle = provider.isPsychologist ? 'session' : 'consultation';
    let startingMessage = '30 mins before your ' + serviceTitle + ', ';
    let connectDetails = this.isOnZoom(provider.id)
      ? 'I will reach out & share the Zoom link to'
      : 'I will reach out and help you';
    let finalSuffix =
      ' connect with ' + provider.getFirstNameWithSalutation() + '.';

    return startingMessage + connectDetails + finalSuffix;
    // Zoom - I will share a Zoom link to connect with <dr-name>
    // Unknown - I will reach out & help you connect with <dr-name>
  }

  getAvailableForSupportMessage() {
    return 'I will be available for support during & after your session - so feel free to reach out to me for any help. 😊';
  }

  getGoodLuckMessage(
    provider: DoctorListingProfile,
    lead: Lead,
    coordinator: CRMUser
  ) {
    let greeting =
      'Hi ' +
      lead.getClientFirstName() +
      ' ' +
      lead.getAppropriateNameSuffix() +
      ', ';
    let serviceTitle = provider.isPsychologist ? 'session' : 'consultation';
    let mainMessage =
      '*Good Luck for your ' +
      serviceTitle +
      ', ' +
      lead.getAppropriateNameSuffix() +
      '*. ' +
      this.getAvailableForSupportMessage();
    // '\n\n~\n' +
    // coordinator.getFirstName() +
    // ' from DocVita';
    return mainMessage;
  }

  // WhatsApp Confirmation - Booking
  getWARequestRescheduleMessageToClient(
    provider: DoctorListingProfile,
    lead: Lead
  ) {
    let greeting =
      '🗓 Dear ' +
      lead.getClientFirstName() +
      ' ' +
      lead.getAppropriateNameSuffix() +
      ', something has come up and ' +
      provider.getFirstNameWithSalutation() +
      ' has humbly requested if your ';
    let serviceTitle = provider.isPsychologist ? 'session' : 'consultation';
    let finalMessage =
      ' can be rescheduled to .\n\nWill this be okay for you, ' +
      lead.getAppropriateNameSuffix() +
      '?';
    // let aboutTheBooking =
    //   'your ' +
    //   serviceTitle +
    //   ' booking with ' +
    //   provider.fullName() +
    //   ' is rescheduled to ' +
    //   lead.consultationInfo.getStartTimeIn12HoursFormat() +
    //   ' ' +
    //   lead.consultationInfo.getDisplayDay() +
    //   '.';

    return greeting + serviceTitle + finalMessage;
  }
  // WhatsApp Confirmation - Booking
  getWAReschedulingMessage(provider: DoctorListingProfile, lead: Lead) {
    let greeting = '🗓 Dear ' + lead.patientName + ', ';
    let serviceTitle = provider.isPsychologist ? 'session' : 'consultation';
    let aboutTheBooking =
      'your ' +
      serviceTitle +
      ' booking with ' +
      provider.fullName() +
      ' is rescheduled to ' +
      lead.consultationInfo.getStartTimeIn12HoursFormat() +
      ' ' +
      lead.consultationInfo.getDisplayDay() +
      '.';

    return greeting + aboutTheBooking;
  }

  // WhatsApp Confirmation - Booking
  getThankYouForReschedulingMessage(
    provider: DoctorListingProfile,
    lead: Lead
  ) {
    return 'Thank you so much for accommodating our reschedule request';
  }

  // WhatsApp Confirmation - Booking
  getWABookingConfirmationMessage(provider: DoctorListingProfile, lead: Lead) {
    if (lead.consultationInfo) {
      let greeting = '✅ Dear ' + lead.patientName + ', ';
      let serviceTitle = provider.isPsychologist ? 'session' : 'consultation';
      let aboutTheBooking =
        'your ' +
        serviceTitle +
        ' booking is confirmed with ' +
        provider.fullName() +
        ' for ' +
        lead.consultationInfo.getStartTimeIn12HoursFormat() +
        ' ' +
        lead.consultationInfo.getDisplayDayAppointmentConfirmation() +
        '.';

      return greeting + aboutTheBooking;
    } else {
      return '';
    }
  }

  getWABookingPunchlineForFirstBooking(
    provider: DoctorListingProfile,
    lead: Lead
  ) {
    let serviceTitle = provider.isPsychologist ? 'session' : 'consultation';
    let punchline =
      "When it's time for your " +
      serviceTitle +
      ', one of our care coordinators will reach out and help you connect with ' +
      provider.getFirstNameWithSalutation() +
      '.';
    return punchline;
  }

  // WhatsApp Reach Out - Call or Callback
  getWAReachoutFirstMessage(provider: DoctorListingProfile, lead: Lead) {
    var patientName =
      lead.patientName && lead.patientName.length > 0
        ? lead.patientName
        : 'there';

    if (lead.mapStatus() == 'To DocVita') {
      return 'Hey ' + patientName + ' 👋🏼, thanks for contacting DocVita.';
    }

    var providerName = provider.fullName();
    var callBackLaterSuffix =
      lead.mapStatus() == 'Callback' || lead.mapStatus() == 'Messaged'
        ? 'Please expect a response or callback within 2-3 working hours. '
        : '';

    var providerGender = provider.gender == 'male' ? 'him' : 'her';

    var actionOnDocVita =
      lead.mapStatus() == 'Messaged' ? 'sending a message to ' : 'contacting ';

    return (
      'Hey ' +
      patientName +
      ' 👋🏼, thanks for ' +
      actionOnDocVita +
      providerName +
      ' on DocVita.\n\n' +
      callBackLaterSuffix +
      'You can connect instantly or reserve a time with ' +
      providerGender +
      ' using the link below:' +
      '\n\n' +
      provider.getProfileLink()
    );
  }
  getWAReachoutSecondMessage() {
    return 'Reply with a "Yes" if you are unable to click the link, reply with "Chat" to speak with a Care Coordinator.';
  }

  getBeforeFeedbackMessage(
    provider: DoctorListingProfile,
    lead: Lead,
    coordinator: CRMUser
  ) {
    let serviceTitle = provider.isPsychologist ? 'session' : 'consultation';
    return (
      'Hi ' +
      lead.consultationInfo.patientName +
      ' ' +
      lead.getAppropriateNameSuffix() +
      ',\n' +
      'Thanks for taking the time to meet with ' +
      provider.fullName() +
      '. How was your overall experience for this ' +
      serviceTitle +
      '?'
    );
    // ', How was your consultation with ' +
    // provider.fullName() +
    // ' ? Did everything go well?'
  }

  getAfterFeedbackMessage(lead: Lead) {
    return 'Glad to know that. Thank you so much. Please take care and stay safe. :)';
  }

  getProviderProfileLinkForFutureMessage(provider: DoctorListingProfile) {
    return (
      'For your future appointments, you can always check ' +
      provider.getFirstNameWithSalutation() +
      '’s availability on the link below:\n\n' +
      provider.getProfileLink() +
      '\n\n' +
      'If you are looking for a time that’s not listed on the site, you can always message us and it’d be our pleasure to assist you with the same. :blush:'
    );
  }

  getSessionInviteMessage(lead: Lead, provider: DoctorListingProfile) {
    return (
      'Hey ' +
      lead.consultationInfo.patientName +
      ' ' +
      lead.getAppropriateNameSuffix() +
      ', ' +
      provider.getFirstNameWithSalutation() +
      ' has invited you for a session at <Insert Time-Date>. ' +
      'Please use the following link to complete your booking: \n\n' +
      provider.getProfileLink() +
      '/booking?sd=<DATE>&st=<START_TIME>&et=<END_TIME' +
      provider.appointmentDuration +
      '>'
    );
  }

  sharedPaymentLinkMessage(lead: Lead, provider: DoctorListingProfile) {
    return (
      'Hey ' +
      lead.consultationInfo.patientName +
      ' ' +
      lead.getAppropriateNameSuffix() +
      ', you can complete the payment for your session with ' +
      provider.getFirstNameWithSalutation() +
      ' using the link below:\n\n<Insert link>'
    );
  }

  requestForExtraAmountForExtendedSessionMessage(
    lead: Lead,
    provider: DoctorListingProfile
  ) {
    return 'WIP';
  }

  getInternationalNumberSupport() {
    return 'Our website doesn’t yet support International Numbers. But if you like, one of our Care Coordinators can help you book this appointment over WhatsApp.';
  }

  getCareCoordinatorIntroOnly(currentHeroInfo: CRMUser) {
    return (
      'I am ' +
      currentHeroInfo.getFirstName() +
      ' - your Care Coordinator at DocVita'
    );
  }

  askForInfo() {
    return `Could you please share the following details?
    - Name
    - Gender
    - Age
    - Email`;
  }

  sendPaymentLinkToInternationalClient() {
    return `You can complete your booking using the link below:
    <INSERT LINK>
    We accept all international cards also. :)`;
  }

  getRequestedDroppedMessage(provider: DoctorListingProfile, lead: Lead) {
    if (lead == null || provider == null) {
      return '';
    }
    let message =
      'I have dropped in a request for the same, I’ll update you as soon as I hear back from ' +
      provider.getFirstNameWithSalutation();
    return message;
  }

  // Consultation Group - WhatsApp > Later in DocVita
  getConsultationGroupTitle(provider: DoctorListingProfile, lead: Lead) {
    let timeVal = lead.consultationInfo.getStartTimeIn12HoursFormat();
    let separatedTime = timeVal.split(':');
    let h: number = +separatedTime[0];
    let m = +separatedTime[1].substr(0, 2);
    let ampm = separatedTime[1].substr(2, 4);
    let txt = '';
    // if (h > 0) {
    txt = h + '';
    // }
    if (m > 0) {
      txt += ':' + m;
    }
    txt += ampm;
    return (
      provider.getFirstNameWithSalutation() +
      ', ' +
      txt +
      ' ' +
      lead.consultationInfo.getShortDate()
    );
  }
  getConsultationGroupIntroFirstMessage(
    provider: DoctorListingProfile,
    lead: Lead
  ) {
    return (
      'Dear ' +
      lead.patientName +
      ', It is our pleasure in introducing you to ' +
      provider.getFirstNameWithSalutation() +
      '.\n\n' +
      provider.getProfileLink()
    );
  }
  getConsultationGroupIntroSecondMessage(
    provider: DoctorListingProfile,
    lead: Lead
  ) {
    var termForInteraction = provider.isPsychologist
      ? 'session'
      : 'consultation';

    return (
      'Please use this group/WhatsApp to communicate before, during & after your ' +
      termForInteraction +
      ' at *' +
      lead.consultationInfo.getStartTimeIn12HoursFormat() +
      ' ' +
      lead.consultationInfo.getDisplayDay() +
      '*.\n\n' +
      '*Next Steps:*\n' +
      'Please message & wait for ' +
      provider.getFirstNameWithSalutation() +
      ' to initiate the call at the time of ' +
      termForInteraction +
      '. For your privacy, we will exit the group now. Good Luck!\n\nBest Wishes,\nCare Team - DocVita :)'
    );
  }
}
