import { Component, OnInit , Input, NgZone } from '@angular/core';
import { ScheduleService } from 'src/app/manage-schedule-interface/services/schedule/schedule.service';
import { DoctorListingProfile } from 'src/app/shared/models-ts/Actors/Doctor/DoctorListingProfile';
import { DayjsHelper } from 'src/app/shared/models-ts/Helpers/dayjsHelper';

@Component({
  selector: 'schedule-timeslot-cell',
  templateUrl: './schedule-timeslot-cell.component.html',
  styleUrls: ['./schedule-timeslot-cell.component.scss']
})
export class ScheduleTimeslotCellComponent implements OnInit {

  @Input() timeslot:any; 
  @Input() date:string; 
  @Input() providerProfile: DoctorListingProfile;

  dayjsHelper = new DayjsHelper();

  slotToBlock: string;
  blockingSlotLoader: boolean;

  slotToUnBlock: string;
  unblockingSlotLoader: boolean;

  showSuccessCheck:boolean;

  constructor(
    private _scheduleService: ScheduleService,
    private _ngZone: NgZone
  ) { }

  ngOnInit(): void {
    // this.playSuccessGif();
  }

  getTimeInhhmma(HHmm: string) {
    return this.dayjsHelper.HHmmtohhmma(HHmm);
  }

  confirmBlockSlot(date: string, timeslot) {
    this.slotToBlock = date + timeslot['startTime'];
  }

  clearBlockSlot() {
    this.slotToBlock = '';
  }

  async blockSlot(date: string, timeslot) {
    try {
      const body = {
        date: date,
        startTime: timeslot['startTime'],
        endTime: timeslot['endTime'],
        providerId: this.providerProfile?.id
      }
      this.blockingSlotLoader = true;
      const blockSlot = await this._scheduleService.blockTimeslot(body);
      if (blockSlot) {
        
        timeslot['isAvailable'] = false;
        this.clearBlockSlot();
        this.playSuccessGif()
      }
      this.blockingSlotLoader = false;
      // console.log(blockSlot)
    } catch (error) {
      // handle error
    }
  }

  confirmUnBlockSlot(date: string, timeslot) {
    this.slotToUnBlock = date + timeslot['startTime'];
  }

  clearUnBlockSlot() {
    this.slotToUnBlock = '';
  }

  async unblockSlot(date: string, timeslot) {
    try {
      const body = {
        date: date,
        startTime: timeslot['startTime'],
        endTime: timeslot['endTime'],
        providerId: this.providerProfile?.id
      }
      this.unblockingSlotLoader = true;
      const unblockSlot = await this._scheduleService.unblockTimeslot(body);
      if (unblockSlot) {
        timeslot['isAvailable'] = true;
        this.clearUnBlockSlot();
        this.unblockingSlotLoader = false;
        this.playSuccessGif();
        return true
      } else {
        this.unblockingSlotLoader = false;
        return false
      }
      // console.log(blockSlot)
    } catch (error) {
      this.unblockingSlotLoader = false;
      return false
    }
  }

  isInPast(date: string, startTime: string) {
    const currentDateTime = new Date().getTime();
    const dateToCheck = new Date(date + "T" + startTime + ":00").getTime();
    if (currentDateTime > dateToCheck) {
      return true;
    } else {
      return false;
    }
  }

  async playSuccessGif(){
    try {
      this.showSuccessCheck = true;
      await this.timeout(1300)
      this.showSuccessCheck = false;
    } catch (error) {
      this.showSuccessCheck = false;
    }
  }

  private timeout(millis:number){
    return new Promise((res)=>{return setTimeout(res,millis)})
  }

}
