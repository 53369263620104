<div class="modal-header">
    <h5 class="modal-title">Confirm Cancellation</h5>
    <button type="button" class="close" (click)="onDismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p>{{title}}</p>
    <label for="reason">Cancellation By:</label>
    <ng-select [items]="cancellationReasons" bindLabel="label" bindValue="value" placeholder="Select reason"
        [(ngModel)]="selectedReason">
    </ng-select>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="onDismiss()">Close</button>
    <button type="button" class="btn btn-primary" (click)="onConfirm()">Confirm Cancellation</button>
</div>