import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WaTemplateMessagesService {
  constructor(private _http: HttpClient) {}

  sendClientConfirmationForAppointment(
    countryCode: string,
    number: string,
    appointmentId: string
  ) {
    return new Promise<void>((resolve, reject) => {
      let url = environment['wa-client-appointment-confirmation'];
      let body: any = {
        appointmentId: appointmentId,
        recipient: countryCode+number,
      };
      this._http.post(url, body).subscribe((resp) => {
        if (resp && resp['statusCode'] == 200 && resp['body'] != null) {
          resolve(resp['body']);
        } else {
          reject();
        }
      });
    });
  }

  sendClientNoShowForAppointment(
    countryCode: string,
    number: string,
    appointmentId: string
  ) {
    return new Promise<void>((resolve, reject) => {
      let url = environment['wa-client-appointment-no-show'];
      let body: any = {
        appointmentId: appointmentId,
        recipient: countryCode+number,
      };
      this._http.post(url, body).subscribe((resp) => {
        if (resp && resp['statusCode'] == 200 && resp['body'] != null) {
          resolve(resp['body']);
        } else {
          reject();
        }
      });
    });
  }

  sendClientNotJoinedForAppointment(
    countryCode: string,
    number: string,
    appointmentId: string
  ) {
    return new Promise<void>((resolve, reject) => {
      let url = environment['wa-client-appointment-client-not-joined'];
      let body: any = {
        appointmentId: appointmentId,
        recipient: countryCode+number,
      };
      this._http.post(url, body).subscribe((resp) => {
        if (resp && resp['statusCode'] == 200 && resp['body'] != null) {
          resolve(resp['body']);
        } else {
          reject();
        }
      });
    });
  }

  sendProviderConfirmationForAppointment(
    countryCode: string,
    number: string,
    appointmentId: string
  ) {
    return new Promise<void>((resolve, reject) => {
      let url = environment['wa-provider-appointment-confirmation'];
      let body: any = {
        appointmentId: appointmentId,
        recipient: countryCode + number,
      };
      this._http.post(url, body).subscribe((resp) => {
        if (resp && resp['statusCode'] == 200 && resp['body'] != null) {
          resolve(resp['body']);
        } else {
          reject();
        }
      });
    });
  }

  sendClientRescheduleConfirmationForAppointment(
    appointmentId: string,
    recipient: string
  ) {
    return new Promise<void>((resolve, reject) => {
      let url = environment['wa-client-reschedule-confirmation'];
      let body: any = {
        appointmentId,
        recipient, //mobile number
      };
      this._http.post(url, body).subscribe((resp) => {
        if (resp && resp['statusCode'] == 200 && resp['body'] != null) {
          resolve(resp['body']);
        } else {
          reject();
        }
      });
    });
  }

  sendProviderRescheduleConfirmationForAppointment(
    appointmentId: string,
    recipient: string
  ) {
    return new Promise<void>((resolve, reject) => {
      let url = environment['wa-provider-reschedule-confirmation'];
      let body: any = {
        appointmentId,
        recipient,
      };
      this._http.post(url, body).subscribe((resp) => {
        if (resp && resp['statusCode'] == 200 && resp['body'] != null) {
          resolve(resp['body']);
        } else {
          reject();
        }
      });
    });
  }

  sendWAShareLink(recipient: string, leadId: string) {
    return new Promise<void>((resolve, reject) => {
      let url = environment['wa-share-link-template'];
      let body: any = {
        leadId: leadId,
        recipient: '' + recipient,
      };
      this._http.post(url, body).subscribe((resp) => {
        if (resp && resp['statusCode'] == 200 && resp['body'] != null) {
          resolve(resp['body']);
        } else {
          reject();
        }
      });
    });
  }

  sendWAShareLinkWithProvider(recipient: string, leadId: string) {
    return new Promise<void>((resolve, reject) => {
      let url = environment['wa-share-link-provider-template'];
      let body: any = {
        leadId: leadId,
        recipient: '' + recipient,
      };
      this._http.post(url, body).subscribe((resp) => {
        if (resp && resp['statusCode'] == 200 && resp['body'] != null) {
          resolve(resp['body']);
        } else {
          reject();
        }
      });
    });
  }

  sendWAClientFeedback(recipient: string, leadId: string) {
    return new Promise<void>((resolve, reject) => {
      let url = environment['wa-client-feedback-template'];
      let body: any = {
        leadId: leadId,
        recipient: '' + recipient,
      };
      this._http.post(url, body).subscribe((resp) => {
        if (resp && resp['statusCode'] == 200 && resp['body'] != null) {
          resolve(resp['body']);
        } else {
          reject();
        }
      });
    });
  }
}
