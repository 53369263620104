<button class="btn btn-sm btn-outline-primary border-0 d-flex justify-content-center align-items-center"
    (click)="openModal()">
    Manual Credit Entry
</button>

<ng-template #manualCreditEntry let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Manual Credit Entry</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()" style="outline: none;">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body d-flex flex-column justify-content-center w-100">
        <div class="card rounded mt-1 p-2">
            <h5>Details:</h5>
            <hr class="m-1 p-0">
            <div><span style="font-weight: 500;">Name:</span> {{leadDetails.patientName?leadDetails.patientName:"-"}}
            </div>
            <div><span style="font-weight: 500;">Contact:</span>
                {{leadDetails.patientContactInfo?('+'+leadDetails.patientContactInfo.countryCode+'-'+leadDetails.patientContactInfo.number):""}}
            </div>
            <div><span style="font-weight: 500;">Amount Received:</span>
                {{leadDetails.consultationInfo.totalAmountPaid}} {{currencyToSymbol(leadDetails.consultationInfo?.currency)}}</div>

        </div>
        <div class="d-flex align-items-center mt-2">
            <h6 class="me-3 form-row-header">Credit Source</h6>
            <div dropdown class="btn-group d-inline-block w-100">
                <button id="button-animated" dropdownToggle type="button"
                    class="btn btn-sm btn-outline-secondary dropdown-toggle" aria-controls="dropdown-animated">
                    {{ creditSource || "Select Credit Source" }}
                </button>
                <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu" role="menu"
                    aria-labelledby="button-animated">
                    <li role="menuitem" *ngFor="let source of paymentSourceArray">
                        <a class="dropdown-item" (click)="creditSourceChanged(source)">
                            {{ source }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="d-flex align-items-center mt-2">
            <h6 class="me-3 form-row-header">Payment Id</h6>
            <input type="text" class="form-control" style="width: 180px;" [(ngModel)]="paymentId">
            <button *ngIf="creditSource == 'Razorpay'" (click)="fetchRazorpayTransactionInfo()"  class="btn btn-sm btn-primary">
                Fetch
            </button>
        </div>
        <ng-container *ngIf="creditSource != null && creditSource.length > 0 && (creditSource != 'Razorpay' || (creditSource == 'Razorpay' && infoFetchedOnce))">
            <div class="d-flex align-items-center mt-2">
                <h6 class="me-3 form-row-header">Credit Amount ({{currencyToSymbol(currency)}})</h6>
                <div class="d-flex">
                    <input type="text" class="form-control me-1" style="width: 100px;" [(ngModel)]="creditAmount">
                    <currency-dropdown [defaultValue]="this.currency" (selectedCurrency)="setCurrency($event)"></currency-dropdown>
                </div>
                
            </div>
            <div class="d-flex align-items-start mt-2">
                <h6 class="me-3 form-row-header">Credited On</h6>
                <div>
                    <input type="date" class="form-control mb-2" placeholder="" style="font-size: 13px;"
                        [(ngModel)]="creditedOnDate" required>
                    <input type="time" class="form-control mb-2" placeholder="" style="font-size: 13px;"
                        [(ngModel)]="creditedOnTime" required>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="modal-footer">
        <button type="button" [disabled]="isLoading" class="btn btn-outline-dark"
            (click)="createEntry()">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isLoading"></span>
            Add Credit
        </button>
    </div>
</ng-template>