<button class="btn btn-sm btn-outline-primary border-0" *ngIf="showButton" (click)="openModal();">
    Check Status
</button>

<ng-template #cgrmStatus let-modal>
    <div class="modal-header">
      <h4 class="modal-title">
        Cashgram Link Status
      </h4>
      <div class="ms-auto d-flex align-items-center">
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="modal-body d-flex flex-column justify-content-center align-items-center p-3" style="position: relative;">
        <ng-container *ngIf="!loader&&statusData&&statusData['cashgramStatus']">
            <div style="font-size: 15px;">
                <span style="font-weight: 600;" *ngIf="statusData['cashgramStatus']">
                    Status :
                </span>
                <span>
                    {{statusData['cashgramStatus']}}
                </span>
            </div>
            <div style="font-size: 15px;" *ngIf="statusData['referenceId']">
                <span style="font-weight: 600;">
                    Ref Id :
                </span>
                <span>
                    {{statusData['referenceId']}}
                </span>
            </div>
            <div style="font-size: 15px;" *ngIf="statusData['cashgramId']">
                <span style="font-weight: 600;">
                    Cashgram Id :
                </span>
                <span>
                    {{statusData['cashgramId']}}
                </span>
            </div>
            <div style="font-size: 15px;" *ngIf="statusData['cashgramLink']">
                <span style="font-weight: 600;">
                    Cashgram Link :
                </span>
                <span>
                    {{statusData['cashgramLink']}}
                </span>
            </div>
            <div style="font-size: 15px;" *ngIf="statusData['reason']">
                <span style="font-weight: 600;">
                    Reason :
                </span>
                <span>
                    {{statusData['reason']}}
                </span>
            </div>
        </ng-container>
        <div *ngIf="!loader&&!statusData['cashgramStatus']" class="p-4">
            No status
        </div>
        <div class="d-flex justify-content-center p-4" *ngIf="loader">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>
  </ng-template>