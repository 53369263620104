import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import firebase from 'firebase';
import * as env from '../../../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class ClaimService {
  constructor(private _http: HttpClient) {}

  getAllClaims(limit, status?: string) {
    if (status != null && status.length > 0) {
      return firebase
        .firestore()
        .collection('profile_claims')
        .where('status', '==', status)
        .orderBy('createdOn', 'desc')
        .limit(limit);
    } else {
      return firebase
        .firestore()
        .collection('profile_claims')
        .orderBy('createdOn', 'desc')
        .limit(limit);
    }
  }

  getClaimDetailsRef(claimId: string) {
    return firebase.firestore().collection('profile_claims').doc(claimId);
  }

  getClaims(id: string) {
    return firebase
      .firestore()
      .collection('profile_claims')
      .where('doctorProfileId', '==', id);
  }

  processingClaim(claimId: string) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection('profile_claims')
        .doc(claimId)
        .update({
          status: 'Processing',
          processingOn: new Date().getTime(),
        })
        .then(() => {
          resolve({});
        })
        .catch((err) => {
          console.error(err);
          reject();
        });
    });
  }

  declineClaim(claimId: string) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection('profile_claims')
        .doc(claimId)
        .update({
          status: 'Denied',
          deniedOn: new Date().getTime(),
        })
        .then(() => {
          resolve({});
        })
        .catch((err) => {
          reject();
        });
    });
  }

  approveClaim(claimId: string, id: string) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection('profile_claims')
        .doc(claimId)
        .update({
          status: 'Approved',
          approvedOn: new Date().getTime(),
        })
        .then(() => {
          return firebase
            .firestore()
            .collection('profiles_for_listing')
            .doc(id)
            .update({
              isProfileClaimed: true,
              profileClaimedOn: new Date().getTime(),
            });
        })
        .then(() => {
          resolve({});
        })
        .catch((err) => {
          reject();
        });
    });
  }

  deleteClaim(claimId: string, doctorProfileId: string) {
    return new Promise<void>((resolve, reject) => {
      firebase
        .firestore()
        .collection('profile_claims')
        .doc(claimId)
        .delete()
        .then(() => {
          resolve();
        })
        .catch((err) => {
          console.error(err);
          reject();
        });
    });
  }

  createClaim(
    governmentDocuments: any[],
    medicalDocuments: any[],
    countryCode: string,
    mobileNumber: string,
    email: string,
    doctorProfileId: string,
    heroId: string
  ) {
    return new Promise((resolve, reject) => {
      let json = {
        governmentDocuments: governmentDocuments,
        medicalDocuments: medicalDocuments,
        doctorProfileId: doctorProfileId,
        mobileNumber: mobileNumber,
        countryCode: countryCode,
        email: email,
        heroId: heroId,
      };
      let url = env.environment['create-claim'];
      this._http.post(url, json).subscribe(
        (res: Response) => {
          resolve(res);
        },
        (err) => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  changeClaimStatusToSubmitted(claimId) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection('profile_claims')
        .doc(claimId)
        .update({
          status: 'Submitted',
          submittedOn: new Date().getTime(),
        })
        .then(() => {
          resolve({});
        })
        .catch((err) => {
          reject();
        });
    });
  }

  uploadMedicalProofs(medicalDocs: any[], claimId) {
    return new Promise((resolve, reject) => {
      let json = {
        medicalDocuments: medicalDocs,
        claimId: claimId,
      };
      let url = env.environment['upload-medical-proofs-to-claims'];
      this._http.post(url, json).subscribe(
        (res: Response) => {
          resolve(res);
        },
        (err) => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  uploadGovernmentIdProofs(govDocs: any[], claimId) {
    return new Promise((resolve, reject) => {
      let json = {
        govDocuments: govDocs,
        claimId: claimId,
      };
      let url = env.environment['upload-government-proofs-to-claims'];
      this._http.post(url, json).subscribe(
        (res: Response) => {
          resolve(res);
        },
        (err) => {
          console.log(err);
          reject(err);
        }
      );
    });
  }
}
