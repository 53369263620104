<div class="mb-5">
  <app-navbar (backClicked)="backClicked()" [title]="title"></app-navbar>
</div>

<div class="d-flex">
  <div class="col-md-4">
    <app-doc-master-list
      (selectedDoctor)="onSelectedDoctorClicked($event)"
    ></app-doc-master-list>
  </div>

  <div class="col-md-8" style="overflow-y: scroll; height: calc(100vh - 150px)">
    <app-provider-header
      *ngIf="selectedDoctor != null"
      class="mb-1 w-100"
      [selectedDoctor]="selectedDoctor"
    ></app-provider-header>
    <router-outlet></router-outlet>
  </div>
</div>
