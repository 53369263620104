import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { classToPlain, plainToClass } from 'class-transformer';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault('Asia/Kolkata');
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { DoctorListingProfile } from '../../models-ts/Actors/Doctor/DoctorListingProfile';
import { User } from '../../models-ts/Actors/User/User';
import { AppointmentInvite } from '../../models-ts/Entities/AppointmentInvite/AppointmentInvite';
import { OrganisationConnection } from '../../models-ts/Relationships/OrganisationConnection';
import { AppointmentInviteService } from '../../services/appointment-invite/appointment-invite.service';
import { DoctorsService } from '../../services/doctors/doctors.service';
import { AppointmentInviteType, AppointmentType, ServiceTypes } from '../../models-ts/Enums/Enums';
import { Appointment } from '../../models-ts/Entities/Appointment';
import { CRMUser } from '../../models-ts/Actors/User/CRMUser';
import { CountrywiseFeeCalc, Currency } from '../../models-ts/Helpers/countrywiseFeeCalc';
import { PatientRelationshipService } from '../../services/patient/patient-relationship.service';
@Component({
  selector: 'create-invite-modal',
  templateUrl: './create-invite-modal.component.html',
  styleUrls: ['./create-invite-modal.component.scss'],
})
export class CreateInviteModalComponent implements OnInit {
  modalRef?: BsModalRef;
  @Input() showButton: boolean = true;
  @Input() patientId: string;
  @Input() doctorId: string;
  @Input() currentHeroInfo: CRMUser = new CRMUser();
  patientProfile: User = new User();
  doctorProfile: DoctorListingProfile = new DoctorListingProfile();
  _date: string;
  @Input() set date(val: string) {
    this._date = val;
  }
  get date() {
    return this._date;
  }
  @Input() time: string;
  _suggestedTime: string;
  @Input() set suggestedTime(val: string) {
    if (val) this._suggestedTime = val;
  }

  get suggestedTime() {
    return this._suggestedTime;
  }
  amount: number = null;
  currency: Currency = Currency.INR;
  duration: number = null;
  inviteType: string;
  inviteTypes = Object.keys(AppointmentInviteType);
  sendingLoader: boolean = false;
  updateMode: boolean = false;
  amtfetching: boolean = false;
  selectedInvite: AppointmentInvite = new AppointmentInvite();
  quickConnections: OrganisationConnection[] = [];
  showAvailableTimeslots: boolean = true;
  allowTimeEdit: boolean = false;
  currentSessionType: ServiceTypes = ServiceTypes.individual;
  practiceSelection: { key: string, value: string };
  isInPerson: boolean = false;

  isScheduled: boolean = false;
  scheduledFor: {
    date?: string;
    time?: string
  } = {};

  constructor(
    private modalService: BsModalService,
    private _toastrService: ToastrService,
    private _doctorService: DoctorsService,
    private _apptInviteService: AppointmentInviteService,
    private _patientRelationshipService: PatientRelationshipService
  ) { }

  ngOnInit(): void { }
  setPatientFromSearch(event) {
    this.patientProfile = new User();
    if (event && event['docvitaId']) {
      this.patientProfile = event;
      // this.getOrganizaitonNameForQuickContact();
      // console.log(this.patientProfile)
    }
  }
  setProviderFromSearch(event) {
    this.doctorProfile = new DoctorListingProfile();
    if (event && event['id']) {
      this.doctorProfile = event;
      this.isInPerson = false;
      this.practiceSelection = null;
      this.initAmountnDuration();
    }
  }
  initForm() {
    this.date = this.date ? this.date : new Date().toISOString().split('T')[0];
    this.inviteType = AppointmentInviteType.regularbooking;
    this.amount = null;
    this.currency = Currency.INR;
    this.initAmountnDuration();
  }
  getOrganizaitonNameForQuickContact() {
    this.quickConnections = [];
    let map = this.patientProfile?.organisationConnections;
    if (map) {
      map.forEach((val, key) => {
        this._doctorService
          .getProviderProfile(key)
          .then((profile) => {
            this.patientProfile.organisationConnections.get(
              key
            ).organisationName = profile.fullName();
            this.patientProfile.organisationConnections.get(
              key
            ).organisationId = key;
          })
          .catch((err) => { });
      });
      this.patientProfile.organisationConnections.forEach((val) => {
        if (val) {
          this.quickConnections.push(val);
        }
      });
      this.quickConnections.sort((a, b) => {
        return a.organisationId.localeCompare(b.organisationId);
      });
    }
  }
  initAmountnDuration() {
    this.duration = this.doctorProfile.appointmentDuration
      ? this.doctorProfile.appointmentDuration
      : null;
      this.getFeeForSessionType();
  }

  manageListFetchState(event: string) {
    if (event == 'fetch_start') {
      this.amtfetching = true;
    } else if (event == 'fetch_end') {
      this.amtfetching = false;
    }
  }

  async fetchLatestAppointmentAmount(list: Appointment[]) {
    console.log("getFeeSuggestion");
    const amountWithCurrency = await this._patientRelationshipService.getFeeSuggestion(this.patientProfile.docvitaId,this.doctorProfile.id, this.currentSessionType)
    if(amountWithCurrency) {
      this.amount = amountWithCurrency.amount;
      this.currency = amountWithCurrency.currency;
    } else if (list && list.length > 0) {
      // let latestAppt = list[0];
      let _amt = null;
      for (let i = 0; i < list.length; i++) {
        if (list[i].amount != null && list[i].amount > 0) {
          _amt = list[i].amount;
          break;
        } else {
          continue;
        }
      }
      if (_amt) {
        this.amount = _amt;
      } else {
        this.setDoctorConfigAmt();
      }
    } else {
      this.setDoctorConfigAmt();
    }
  }

  selectApptTypeAndPractice(val: {
    isInPerson: boolean,
    practiceSelection: { key: string, value: string }
  }) {
    this.isInPerson = val.isInPerson;
    if(this.isInPerson) {
      this.practiceSelection = val.practiceSelection;
    } else {
      this.practiceSelection = null;
    }
  }

  selectSessionType(val: ServiceTypes) {
    // console.log("selectSessionType");
    this.currentSessionType = val;
    this.getFeeForSessionType();
  }

  async getFeeForSessionType() {
    // console.log("getFeeSuggestion");
    const amountWithCurrency = await this._patientRelationshipService.getFeeSuggestion(this.patientProfile.docvitaId,this.doctorProfile.id, this.currentSessionType)
    // console.log("getFeeSuggestion", amountWithCurrency);
    if(amountWithCurrency) {
      this.amount = amountWithCurrency.amount;
      this.currency = amountWithCurrency.currency;
    } else {
      const servicewiseFeeMap =
        this.doctorProfile.onlineConsultationConfig.servicewiseFeesMap;
      const serviceFee = servicewiseFeeMap?.get(
        this.currentSessionType
      );
      if (serviceFee && serviceFee.isActive) {
        const fee = serviceFee.fee;
        const currency = serviceFee.currency;
        const cc = new CountrywiseFeeCalc().currencyToCountry(currency);
        const countryWiseFee = new CountrywiseFeeCalc().fee(fee, cc, this.doctorProfile.getMasterSpecialization());
        this.amount = countryWiseFee.amount;
        this.currency = countryWiseFee.unit;
      }
    }
  }

  setDoctorConfigAmt() {
    if (
      this.doctorProfile &&
      this.doctorProfile.id &&
      this.doctorProfile.onlineConsultationConfig &&
      this.amtfetching == false
    ) {
      if (this.currency == Currency.INR) {
        this.amount = this.doctorProfile.onlineConsultationConfig.fee
          ? this.doctorProfile.onlineConsultationConfig.fee
          : null;
      } else {
        this.amount = null;
      }
    } else {
      this.amount = null;
    }
  }

  @ViewChild('inviteModal') inviteModal;
  openModal(
    invite?: AppointmentInvite,
    doctorProfile?: DoctorListingProfile,
    patientProfile?: User
  ) {
    this.initForm();
    if (invite && invite.id) {
      this.updateMode = true;
      this.selectedInvite = plainToClass(
        AppointmentInvite,
        classToPlain(invite)
      );
      this.doctorProfile = doctorProfile
        ? doctorProfile
        : new DoctorListingProfile();
      this.patientProfile = patientProfile ? patientProfile : new User();
      this.date = invite.inviteDate.slice();
      this.time = invite.inviteTime.slice();
      this.amount = invite.amount;
      this.currency = invite.currency ? invite.currency : Currency.INR;
      this.duration = invite.duration;
      this.inviteType = invite.type;
    } else {
      this.selectedInvite = new AppointmentInvite();
      this.updateMode = false;
    }

    this.modalRef = this.modalService.show(this.inviteModal);
  }
  closeModal() {
    this.modalRef.hide();
  }
  @ViewChild('apptClashModal') apptClashModal;
  createInvite() {
    if (
      this.doctorProfile &&
      this.doctorProfile.id &&
      this.patientProfile &&
      this.patientProfile.docvitaId &&
      this.currentHeroInfo &&
      this.currentHeroInfo.docvitaId &&
      this.date.length > 0 &&
      this.time.length > 0 &&
      this.inviteType &&
      this.inviteType.length > 0 &&
      !isNaN(this.amount)
    ) {
      this.sendingLoader = true;
      let invite = new AppointmentInvite();
      invite.clientId = this.patientProfile.docvitaId;
      invite.providerId = this.doctorProfile.id;
      invite.patientName = this.patientProfile.name;
      invite.doctorName = this.doctorProfile.fullName();
      invite.createdById = this.currentHeroInfo.docvitaId;
      invite.createdByName = this.currentHeroInfo.name;
      invite.inviteDate = this.date;
      invite.inviteTime = this.time;
      invite.amount = +this.amount;
      invite.currency = this.currency;

      if (this.currency != Currency.INR) {
        invite.providerDisplayAmount = new CountrywiseFeeCalc().fee(
          this.doctorProfile.onlineConsultationConfig.fee,
          null,
          this.doctorProfile.getMasterSpecialization()
        ).amount;
        invite.providerDisplayCurrency = new CountrywiseFeeCalc().fee(
          this.doctorProfile.onlineConsultationConfig.fee,
          null,
          this.doctorProfile.getMasterSpecialization()
        ).unit;
      }
      invite.duration = +this.duration;
      invite.type = AppointmentInviteType[this.inviteType];
      invite.sessionType = this.currentSessionType;
      invite.appointmentType = this.isInPerson ? AppointmentType.inPerson : AppointmentType.online;
      if (this.practiceSelection) {
        invite.practiceId = this.practiceSelection.key;
        invite.practiceName = this.practiceSelection.value;
      }
      let _timeslot =
        this.time +
        '-' +
        dayjs.tz(+dayjs.tz(new Date(this.date + ' ' + this.time)).valueOf() + (+this.duration * 60 * 1000))
          .format('HH:mm');

      this.apptClashModal
        .openConflictPromise(
          this.doctorProfile.id,
          this.date,
          _timeslot,
          this.doctorProfile.fullName()
        )
        .then((hasConflicts) => {
          if (hasConflicts) {
            this.sendingLoader = false;
            this.apptClashModal.openModal();
          } else {
            this.continueInviteFlow(invite);
          }
        })
        .catch((err) => {
          this.continueInviteFlow(invite);
        });
    } else {
      this._toastrService.warning('Incomplete form');
    }
  }
  continueInviteFlow(invite: AppointmentInvite) {
    this.sendingLoader = true;
    this._apptInviteService
      .createInvite(invite)
      .then((res) => {
        if (res) {
          this._toastrService.success('Invite created & sent successfully!');
          this.closeModal();
        } else {
          this._toastrService.error('Failed to create invite..');
        }
        this.sendingLoader = false;
      })
      .catch((err) => {
        this._toastrService.error('Failed to create invite..');
        this.sendingLoader = false;
      });
  }

  modifyInvite() {
    if (
      this.doctorProfile &&
      this.doctorProfile.id &&
      this.patientProfile &&
      this.patientProfile.docvitaId &&
      this.currentHeroInfo &&
      this.currentHeroInfo.docvitaId &&
      this.date.length > 0 &&
      this.time.length > 0 &&
      this.inviteType &&
      this.inviteType.length > 0 &&
      !isNaN(this.amount)
    ) {
      this.sendingLoader = true;
      let invite = new AppointmentInvite();
      invite.duration = +this.duration;
      invite.amount = +this.amount;
      invite.currency = this.currency;
      invite.inviteDate = this.date;
      invite.inviteTime = this.time;
      invite.type = AppointmentInviteType[this.inviteType];
      let updateBody = JSON.parse(JSON.stringify(classToPlain(invite)));
      let inviteId = this.selectedInvite.id;
      let _timeslot =
        this.time +
        '-' +
        dayjs(new Date(this.date + ' ' + this.time))
          .add(+this.duration, 'minutes')
          .format('HH:mm');
      this.apptClashModal
        .openConflictPromise(
          this.doctorProfile.id,
          this.date,
          _timeslot,
          this.doctorProfile.fullName()
        )
        .then((hasConflicts) => {
          if (hasConflicts) {
            this.sendingLoader = false;
            this.apptClashModal.openModal();
          } else {
            this.continueModifyFlow(inviteId, updateBody);
          }
        })
        .catch((err) => {
          this.continueModifyFlow(inviteId, updateBody);
        });
    } else {
      this._toastrService.warning('Incomplete form');
    }
  }
  continueModifyFlow(inviteId, updateBody) {
    this.sendingLoader = true;
    this._apptInviteService
      .modifyInvite(inviteId, updateBody, this.currentHeroInfo)
      .then((res) => {
        if (res) {
          this._toastrService.success('Invite updated successfully!');
          this.closeModal();
        } else {
          this._toastrService.error('Failed to update invite..');
        }
        this.sendingLoader = false;
      })
      .catch((err) => {
        this._toastrService.error('Failed to update invite..');
        this.sendingLoader = false;
      });
  }
  setDuration(mins: number) {
    this.duration = mins;
  }
  selectSlot(event) {
    if (event && event['startTime']) {
      this.time = event['startTime'];
      this.toggleProviderTimeslots(false);
      this.allowTimeEdit = false;
    }
  }
  get invpatientName() {
    return this.selectedInvite && this.selectedInvite.patientName
      ? this.selectedInvite.patientName
      : '-';
  }
  get invdoctorName() {
    return this.selectedInvite && this.selectedInvite.doctorName
      ? this.selectedInvite.doctorName
      : '-';
  }
  get invDateTime() {
    if (
      this.selectedInvite &&
      this.selectedInvite.inviteDate &&
      this.selectedInvite.inviteTime
    ) {
      let date = this.selectedInvite.inviteDate;
      let time = this.selectedInvite.inviteTime;
      return dayjs(new Date(date + ' ' + time)).format(
        'DD/MM/YYYY, hh:mm a'
      );
    } else {
      return '-';
    }
  }
  get invAmt() {
    return this.selectedInvite && this.selectedInvite.amount
      ? '₹' + this.selectedInvite.amount
      : '-';
  }
  get invDuration() {
    return this.selectedInvite && this.selectedInvite.duration
      ? this.selectedInvite.duration + ' mins'
      : '-';
  }
  getInviteType(type) {
    if (type == AppointmentInviteType.nextfollowup) {
      return 'Follow Up';
    } else if (type == AppointmentInviteType.regularbooking) {
      return 'Booking';
    } else {
      return '';
    }
  }
  timeToMin(hm: any) {
    let a = hm.split(':');
    let minutes = +a[0] * 60 + +a[1];
    return minutes;
  }

  minToTime(mins: any) {
    let m = mins % 60;
    let h = ((mins - m) / 60).toString();
    var HHMM =
      (h.length > 1 ? h : '0' + h) + ':' + (m < 10 ? '0' : '') + m.toString();
    return HHMM;
  }

  allAvailableSlots(slots: any[]) {
    if (this.suggestedTime) {
      for (let s of slots) {
        if (s["startTime"] == this.suggestedTime) {
          this.time = this.suggestedTime;
        }
      }
    }
  }
  setCurrency(val: Currency) {
    this.currency = val;
  }
  currencyToSymbol(currency: Currency) {
    return new CountrywiseFeeCalc().currencyToSymbol(currency);
  }

  appointmentDateScroller(direction, scheduledForBool?: boolean) {
    let dateVal: string = scheduledForBool ? this.scheduledFor.date: this.date;
    if (direction == '+' && dateVal) {
      dateVal = dayjs(
        new Date(dateVal)
      )
        .add(1, 'day')
        .format('YYYY-MM-DD');
    } else if (direction == '-' && dateVal) {
      dateVal = dayjs(
        new Date(dateVal)
      )
        .subtract(1, 'day')
        .format('YYYY-MM-DD');
    } else {
      //cool
    }
    if(scheduledForBool) {
      this.scheduledFor.date = dateVal;
    } else {
      this.date = dateVal;
    }
  }
  get DateInDay() {
    let date = this.date;
    if (date) {
      return dayjs(date).format('dddd');
    } else {
      return '';
    }
  }
  get ScheduledForDateInDay() {
    let date = this.scheduledFor.date;
    if (date) {
      return dayjs(date).format('dddd');
    } else {
      return '';
    }
  }
  toggleProviderTimeslots(bool: boolean) {
    this.showAvailableTimeslots = bool;
  }
  editTimeManually() {
    this.toggleProviderTimeslots(false);
    this.allowTimeEdit = true;
    this.time = null;
  }

  get standardNatInNatPricing() {
    if (this.doctorProfile && this.doctorProfile.onlineConsultationConfig && this.doctorProfile.onlineConsultationConfig.fee != null) {
      const masterSpecialization = this.doctorProfile.getMasterSpecialization();
      const fee = this.doctorProfile.onlineConsultationConfig.fee;
      const in_fees = new CountrywiseFeeCalc().fee(fee, "IN", masterSpecialization)
      const us_fees = new CountrywiseFeeCalc().fee(fee, "US", masterSpecialization)
      const ca_fees = new CountrywiseFeeCalc().fee(fee, "CA", masterSpecialization)
      const othr_fees = new CountrywiseFeeCalc().fee(fee, null, masterSpecialization)
      let fees_string = '(IN) ' + in_fees.amount + ' ' + in_fees.unit + ' • (US) ' + us_fees.amount + ' ' + us_fees.unit + ' • (CA) ' + ca_fees.amount + ' ' + ca_fees.unit + ' • (Other) ' + othr_fees.amount + ' ' + othr_fees.unit
      return fees_string;
    } else {
      return "-"
    }
  }
}
