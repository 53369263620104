import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CancellationByType } from '../../models-ts/Enums/Enums';
import { Lead } from '../../models-ts/Entities/Lead';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'confirm-cancellation-modal',
  templateUrl: './confirm-cancellation-modal.component.html',
  styleUrl: './confirm-cancellation-modal.component.scss'
})
export class ConfirmCancellationModalComponent {
  @Output() confirm = new EventEmitter<string>();
  @Output() dismiss = new EventEmitter<void>();

  _lead:Lead;
  @Input() set lead(value: Lead) {
    this._lead = value;
    this.title = 'Are you sure you want to cancel the appointment of ' +
      value.consultationInfo.patientName +
      ' with ' +
      value.consultationInfo.doctorName +
      ' on ' +
      value.consultationInfo.date +
      ',' +
      value.consultationInfo.timeslot.substring(0, 5) +
      '?'
    // Additional logic when lead is set can be added here
  }

  get lead(): Lead {
    return this._lead;
  }
  title: string;


  cancellationReasons = [
    { label: 'Provider Cancelled', value: CancellationByType.provider },
    { label: 'Client Cancelled', value: CancellationByType.client },
    { label: 'DocVita Team Cancelled', value: CancellationByType.docvita_team }
  ];

  selectedReason: CancellationByType;

  constructor(private _toastrService: ToastrService) {}

  onConfirm() {
    if(this.selectedReason == null) {
      this._toastrService.warning("Please select cancellation by");
    } else {
      this.confirm.emit(this.selectedReason);
    }
  }

  onDismiss() {
    this.dismiss.emit();
  }
}
