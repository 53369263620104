<div class="modal-header" *ngIf="!hideTopbar">
  <h4 class="modal-title" id="modal-basic-title">🏥 Patient Info</h4>
  <div class="ms-auto d-flex align-items-center">
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>
<div class="modal-body d-flex flex-column position-relative">
  <div class="d-flex flex-column align-items-end position-absolute"
    style="top: 0.4rem; right: 0.25rem;row-gap: 0.25rem;">
    <app-patient-registration [patient]="client"></app-patient-registration>
    <create-invite-modal [date]="suggestedDateForInvite" [doctorId]="doctorIdForInvite"
      [suggestedTime]="suggestedTimeForInvite" [currentHeroInfo]="currentHeroInfo" [patientId]="client.docvitaId">
    </create-invite-modal>
    <button class="btn btn-sm btn-outline-primary border-0" (click)="newClientBooking.openModal()">
      <i class="feather icon-calendar" style="font-size: 12px"></i>
      New Booking
    </button>
    <button class="btn btn-sm btn-outline-primary border-0" (click)="cashgramRefunds.openModal()">
      <i class="feather icon-link-2" style="font-size: 12px"></i>
      Refund Link
    </button>
    <app-client-credit-entry [clientId]="client.docvitaId"></app-client-credit-entry>
  </div>

  <h6 class="text-muted">Patient:</h6>
  <div style="width: fit-content;font-size: 14px;" class="mb-1 cursor-pointer pii-text fw-600"
    (click)="copy(client.name)">
    <span>{{ client.name ? client.name : "-" }}</span>
    <span>{{ client.gender ? ' ('+client.gender+')' : "" }}</span>
  </div>

  <div *ngIf="client && client.docvitaId" class="mt-1">
    <div *ngFor="let c of client.getAllContactNumbers()" class="d-flex align-items-center pii-text"
      style="font-size: 1em">
      <span>
        <span class="pointer" (click)="copy(c.number)">{{ c ? "+" + c.countryCode + "-" + c.number : "" }}
        </span>
        <span style="color: #777">
          {{
          c.isWhatsappContactNumber ? "(✅ WhatsApp)" : "(No WhatsApp)"
          }}
        </span>
        <span class="pointer" style="color: #777" *ngIf="isWingman" (click)="openChat(c.countryCode + c.number)">
          {{ c.isWhatsappContactNumber ? "(✅ WA)" : "(No WA)" }}
        </span>
      </span>
      <button class="btn btn-sm btn-outline-primary border-0" tooltip="Open WhatsApp Chat"
        (click)="openChatInCanvas(c)">
        <i class="la la-whatsapp font-18"></i>
      </button>
      <button class="btn btn-sm btn-outline-primary border-0" (click)="callClient(c)"> <i
          class="la la-phone font-18"></i> </button>
      <br>
    </div>
    <div *ngFor="let emailItem of client.getEmails()" class="d-flex align-items-center pii-text mt-1"
      style="font-size: 1em">
      <span class="pointer" (click)="copy(emailItem.email)">{{ emailItem ? emailItem.email : "" }}
      </span>
      <br>
    </div>
  </div>

  <div style="width: fit-content" class="mb-1 cursor-pointer pii-text mt-2" (click)="copy(client.docvitaId)">
    DocvitaId :
    {{ client.docvitaId ? client.docvitaId : "" }}
  </div>
  <div style="width: fit-content" class="mb-1 cursor-pointer pii-text mt-2">
    Registered On :
    {{ client.createdOn ? (client.createdOn | date:'dd MMM yy, hh:mma') : "" }} | Country of Visit:
    {{client.latestCountryOfVisit}}
  </div>
  <div class="d-flex align-items-center my-2">
    <span>💰Wallet : </span>
    <client-wallet class="ms-1" [patientId]="client.docvitaId" (walletVal)="getWalletBalance($event)"></client-wallet>
  </div>

  <tabset>
    <tab heading="Appointments" class="p-2">
      <span *ngIf="isLoadingAppointments" class="spinner-border spinner-border-sm me-2" role="status"
        aria-hidden="true"></span>
      <div *ngIf="!isLoadingAppointments && allLeads.length == 0">
        💤 No appointments
      </div>
      <div class="d-flex flex-column font-14 fw-400 fc-grey" *ngIf="!isLoadingAppointments">
        <div style="max-height: 200px; overflow-y: scroll">
          <div *ngFor="let appointment of allLeads; let i = index" class="w-100 d-flex align-items-center mb-1">
            {{ appointment.consultationInfo.getDisplayDayLong() }},
            {{ appointment.consultationInfo?.getStartTimeIn12HoursFormat() }} with
            {{ appointment.consultationInfo?.doctorName }}
            <!-- <span class="text-info">{{
              appointment.consultationinfo?.amount ? "("+currencyToSymbol(appointment)+" "+ appointment.consultationinfo?.amount + ")" : ""
              }}</span> -->
            <span class="text-info ms-2" *ngIf="appointment.netIncomingBalancePaidByClient().currency == 'INR'">
              {{ appointment.netIncomingBalancePaidByClient().displayVal }}
            </span>
            <span class="text-info ms-2" *ngIf="appointment.netIncomingBalancePaidByClient().currency != 'INR'">
              <span *ngIf="appointment.netIncomingBalancePaidByClient().amount > 0">
                {{ appointment.netIncomingBalancePaidByClient().amount > 0 ?
                appointment.netIncomingBalanceForProvider().displayVal :
                appointment.netIncomingBalancePaidByClient().displayVal }}
              </span> ({{appointment.netIncomingBalancePaidByClient().displayVal}})
            </span>
            <span *ngIf="appointment.consultationInfo?.status" class="badge bg-primary ms-2"
              [ngClass]="{ 'bg-danger': appointment.consultationInfo?.status == 'CANCELLED', 'bg-dark': appointment.consultationInfo?.status == 'NOSHOW' }">{{
              appointment.consultationInfo?.status | lowercase }}</span>
            <span *ngIf="appointment.consultationInfo?.type" class="badge bg-secondary text-dark ms-2">{{
              appointment.consultationInfo?.type | lowercase }}</span>
            <span class="badge bg-info cursor-pointer ms-auto me-1"
              (click)="openAppointmentInNewTab(appointment)">Open</span>
          </div>
        </div>
      </div>
    </tab>

    <tab heading="Active Membership Plans" class="p-2" style="max-height: 200px; overflow-y: scroll;font-size: 14px;">
      <patient-membership-details [patientId]="client.docvitaId"></patient-membership-details>
    </tab>
    <tab heading="Fee Config" class="p-2" style="height: 400px; overflow-y: scroll;font-size: 14px;">
      <app-client-relationship-fee-config [client]="client"></app-client-relationship-fee-config>
    </tab>
  </tabset>

  <!-- <div class="my-1"><b>Active Membership Plans:</b></div> -->
  <!-- <div class="w-100 mt-1 mb-2" style="height: 1.2px; background-color: #ecf4f8"></div> -->
  <!-- <div class="mb-1"><b>Appointments:</b></div> -->

</div>
<div class="modal-footer"></div>

<new-client-booking [showButton]="false" [patientId]="clientId" #newClientBooking>
</new-client-booking>

<create-cashgram-refund-link [patientId]="clientId" [currentHeroInfo]="currentHeroInfo" [showButton]="false"
  #cashgramRefunds></create-cashgram-refund-link>

<offcanvas-chat [showButton]="false" #chatoffcanvas></offcanvas-chat>