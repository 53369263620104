<button class="btn btn-sm btn-outline-primary border-0 d-flex justify-content-center align-items-center"
    (click)="openModal()">
    Client Credit Entry
</button>

<ng-template #clientCreditEntry let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Client Credit Entry</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()" style="outline: none;">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body d-flex flex-column justify-content-center w-100">
        <div class="card rounded mt-1 p-2">
            <app-patient-search [patientId]="clientId" style="width: 100%" placement="left" tooltip="Fetch patient info" class="mx-2 ms-auto"
                [listMode]="false" (getPatient)="setPatientFromSearch($event)"></app-patient-search>
                <div class="mt-2 d-flex">
                    <input type="number" [placeholder]="currencyToSymbol(currency) +' Amount'"  [(ngModel)]="creditAmount" class="form-control me-1" />
                    <currency-dropdown [defaultValue]="this.currency" (selectedCurrency)="setCurrency($event)"></currency-dropdown>
                </div>
            
            <input type="text" placeholder="Transfer ID" [(ngModel)]="paymentId" class="form-control mt-2" />
            <div class="d-flex align-items-center mt-2">
                <h6 class="me-3 form-row-header">Credit Source</h6>
                <div dropdown class="btn-group d-inline-block w-100">
                    <button id="button-animated" dropdownToggle type="button"
                        class="btn btn-sm btn-outline-secondary dropdown-toggle" aria-controls="dropdown-animated">
                        {{ creditSource || "Select Credit Source" }}
                    </button>
                    <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu" role="menu"
                        aria-labelledby="button-animated">
                        <li role="menuitem" *ngFor="let source of paymentSourceArray">
                            <a class="dropdown-item" (click)="creditSourceChanged(source)">
                                {{ source }}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="d-flex align-items-start mt-2">
                <h6 class="me-3 form-row-header">Credited On</h6>
                <div>
                    <input type="date" class="form-control mb-2" placeholder="" style="font-size: 13px;"
                        [(ngModel)]="creditedOnDate" required>
                    <input type="time" class="form-control mb-2" placeholder="" style="font-size: 13px;"
                        [(ngModel)]="creditedOnTime" required>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" [disabled]="isLoading" class="btn btn-outline-dark" (click)="createEntry()">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isLoading"></span>
            Add Credit
        </button>
    </div>
</ng-template>