<div class="d-flex align-items-center justify-content-between mb-2 mx-2">
    <h2>
        Upcoming Availability
    </h2>
    <div>
        <button class="btn btn-outline-primary border-0 p-0" [disabled]="isToday" (click)="scrollDates('prev')">
            <img class="date_change_icon" src="../../assets/icons/chevron-left.svg" />
        </button>
        <button class="btn btn-outline-primary border-0 p-0" (click)="scrollDates('next')">
            <img class="date_change_icon" src="../../assets/icons/chevron-right.svg" />
        </button>
    </div>
</div>
<div class="white-card d-flex flex-column">
    <div class="days-container">
        <ng-container *ngIf="isDesktop">
            <div class="day-column col1" *ngFor="let date of dates">
                <ng-container *ngTemplateOutlet="dayColumn;context:{date:date}"></ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="!isDesktop">
            <div class="day-column col1" *ngFor="let date of dates | slice:0:1">
                <ng-container *ngTemplateOutlet="dayColumn;context:{date:date}"></ng-container>
            </div>
        </ng-container>
    </div>
</div>
<div class="d-flex justify-content-start mt-2 mx-2">
    <span class="help-text">
        All times are in IST (Indian Standard Time).
    </span>
</div>

<ng-template let-date="date" #dayColumn>
    <div class="day-row">
        <!-- <h3>Fri, 7 Apr</h3> -->
        <h3>{{date | date:'EEE, d MMM'}}</h3>
        <div class="more_action_dropdown" *ngIf="scheduleMap[date]&&!loadingSchedule">
            <img class="more_action_icon" src="../../assets/icons/more_icon.svg" />
            <div class="more_action_dropdown_content">
                <div class="dropdown_item" (click)="openBlockAllSlotsPopup(date)">Close All Open Slots</div>
            </div>
        </div>
    </div>

    <div class="no-slots-box" *ngIf="(scheduleMap[date]&&scheduleMap[date].length==0)&&!loadingSchedule">
        <p>Looks like you’re taking a break from saving the world today!</p>
        <p class="mb-0">It’s time to kick back, relax, and indulge in some well-deserved self-care. 😊</p>
    </div>
    <ng-container *ngFor="let timeslot of scheduleMap[date];trackBy:trackById.bind(this,date)">
        <schedule-timeslot-cell [timeslot]="timeslot" [date]="date" [providerProfile]="providerProfile"></schedule-timeslot-cell>
    </ng-container>

    <ng-container *ngIf="addingNewTime&&addingNewTime==date">
        <div class="d-flex align-items-center mt-2">
            <input type="time" class="form-control me-2" style="width: 145px" [disabled]="addingNewTimeLoader"
                [(ngModel)]="newSlotStartTime" />
            <div class="d-flex gap-2 ms-auto align-items-center">
                <ng-container *ngIf="!addingNewTimeLoader">
                    <button class="btn btn-primary btn-sm" (click)="saveNewOpenSlot(date)">save</button>
                    <button class="btn btn-light btn-sm" (click)="discardNewOpenSlot()">discard</button>
                </ng-container>
                <ng-container *ngIf="addingNewTimeLoader">
                    <div class="spinner-border spinner-border-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>

    <button class="btn btn-outline-primary border-0 mt-2"
        *ngIf="scheduleMap[date] && !(addingNewTime&&addingNewTime==date)" (click)="addNewOpenSlot(date)">
        {{(scheduleMap[date]&&scheduleMap[date].length>0)?'+ Add more times':'+ Add times'}}
    </button>
    <div *ngIf="newTimeErr&&(addingNewTime&&addingNewTime==date)" class="font-12 text-danger my-2 mx-2">
        {{newTimeErr}}
    </div>

    <div class="d-flex justify-content-center mt-2" *ngIf="loadingSchedule && !scheduleMap[date]">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</ng-template>

<block-all-slots-popup #blockAllSlotsPopup></block-all-slots-popup>

