<ng-template #addonPopup>
    <div class="modal-header">
      <h4 class="modal-title pull-left">{{titleOverride?titleOverride:'Accept Addon Credits'}}</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body position-relative">
        <ng-container *ngIf="showWalletPopup">
            <ng-container *ngTemplateOutlet="walletWarning"></ng-container>    
        </ng-container>
    
        <ng-container *ngIf="!paymentLink">
            <div class="p-2 border rounded d-flex flex-column mb-3" style="row-gap: 0.25rem;font-size: 13px;">
                <div>
                    <b>Appointment Date & Time :</b> {{apptDateTime}}
                </div>
                <div>
                    <b>Provider :</b> {{providerName}}
                </div>
                <div>
                    <b>Patient :</b> {{patientName}}
                </div>
                <div class="d-flex align-items-center">
                    <b>Wallet :</b>
                    <client-wallet class="ms-1" [patientId]="patient?.docvitaId" (walletVal)="getWalletBalance($event)"></client-wallet>
                </div>
                
            </div>
            <ng-container *ngTemplateOutlet="paymentModeSelection"></ng-container>
            <div style="font-weight: 600" class="mt-3">Amount({{currencySymbol}}):</div>
            <div class="d-flex align-items-center w-100">
              <input
                type="number"
                placeholder="Amount"
                class="form-control w-100 me-1"
                [(ngModel)]="paymentLinkForm['amount']"
              />
              <currency-dropdown [defaultValue]="paymentLinkForm['currency']" (selectedCurrency)="setCurrency($event)" [isDisabled]="paymentSource != 'stripe'&&!payViaWallet&&!payViaPartnerWallet"></currency-dropdown>
            </div>

            <div class="form-check mt-2" *ngIf="!payViaPartnerWallet">
              <input class="form-check-input" type="checkbox" value="" id="walletCheck" [(ngModel)]="payViaWallet" [disabled]="!enablePayviaWallet">
              <label class="form-check-label ms-1" for="walletCheck">
                Pay via wallet ?
              </label>
            </div>
          
            <ng-container *ngIf="!payViaWallet&&!payViaPartnerWallet">
                  <div style="font-weight: 600" class="mt-3">
                    Payment Description:
                    <span class="text-muted" style="font-size: 13px">{{
                      paymentSource && (paymentSource == "cashfree"||paymentSource == "stripe") ? "(required)" : "(optional)"
                    }}</span>
                  </div>
                  <textarea
                    rows="2"
                    placeholder="Payment Description"
                    class="form-control"
                    [(ngModel)]="paymentLinkForm['description']"
                  ></textarea>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="paymentLink">
            <h5>
                ✅ Share payment link
            </h5>
            <div class="input-group mt-3 mb-3 w-100">
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="paymentLink"
                  placeholder="Payment Link"
                  disabled="true"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-primary"
                    type="button"
                    (click)="copyPaymentLink()"
                  >
                    <i style="font-size: 14px" class="material-icons me-1"
                      >content_copy</i
                    >
                    Copy
                  </button>
                </div>
              </div>
        </ng-container>
    </div>
    <div class="modal-footer" *ngIf="!showWalletPopup">
        <button class="btn btn-outline-dark" (click)="closeModal()">
            Close
        </button>
        <button class="btn btn-outline-primary ms-1" *ngIf="!paymentLink&&!payViaWallet&&!payViaPartnerWallet" [disabled]="loader" (click)="checkAndCreatePaylink()">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loader"></span>
            Create Paylink
        </button>
        <button class="btn btn-outline-info" (click)="debitViaWallet()" *ngIf="payViaWallet&&!payViaPartnerWallet" [disabled]="walletLoader">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="walletLoader"></span>
            Debit From Wallet
        </button>
        <button class="btn btn-outline-info" (click)="debitViaPartnerWallet()" *ngIf="payViaPartnerWallet" [disabled]="walletLoader">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="walletLoader"></span>
            Debit From Partner Wallet
        </button>
    </div>
</ng-template>

<ng-template #paymentModeSelection>
    <div class="w-100 d-flex align-items-center justify-content-center">
      <button
        class="w-100 btn btn-sm btn-outline-primary"
        [ngClass]="{ 'btn-primary text-white': paymentSource == 'cashfree' }"
        style="border-radius: 5px 0px 0px 5px; border-right: 0"
        [disabled]="payViaWallet&&payViaPartnerWallet"
        (click)="togglePaymentSource('cashfree')"
      >
        Cashfree
      </button>
      <button
        class="w-100 btn btn-sm btn-outline-primary"
        [ngClass]="{ 'btn-primary text-white': paymentSource == 'razorpay' }"
        style="border-radius: 0px; border-right: 0"
        [disabled]="payViaWallet&&payViaPartnerWallet"
        (click)="togglePaymentSource('razorpay')"
      >
        Razorpay
      </button>
      <button
        class="w-100 btn btn-sm btn-outline-primary"
        [ngClass]="{ 'btn-primary text-white': paymentSource == 'stripe' }"
        style="border-radius: 0px 5px 5px 0px"
        [disabled]="payViaWallet&&payViaPartnerWallet"
        (click)="togglePaymentSource('stripe')"
      >
        Stripe
      </button>
    </div>
</ng-template>

<ng-template #walletWarning>
    <div class="w-100 position-absolute p-4" style="height: 100%;top: 0px;left:0px;background-color: rgba(255, 255, 255, 0.944);z-index: 600;">
        <div class="card w-100 p-3 mt-5 border" style="font-size: 15px;">
            <div class="d-flex align-items-center">
                <b>Wallet : </b>
                <client-wallet class="ms-1" [patientId]="patient.docvitaId" (walletVal)="getWalletBalance($event)"></client-wallet>
            </div>
            <span class="mt-2">
                The client's wallet has the additional balance of <span class="text-primary">{{amountWithCurrency}}</span>. Would you like to debit from their wallet?
            </span>
        </div>
        <div class="d-flex align-items-center justify-content-end w-100 mt-2">
            <button class="btn btn-outline-info" (click)="debitViaWallet()" [disabled]="walletLoader||loader">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="walletLoader"></span>
                Debit From Wallet
            </button>
            <button class="btn btn-outline-primary ms-2" [disabled]="loader||walletLoader" (click)="createPaymentLink()">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loader"></span>
                Create Paylink
            </button>
            <button class="btn btn-outline-dark ms-2" (click)="resetWalletPopup()" [disabled]="walletLoader||loader">
                Cancel
            </button>
        </div>
    </div>
</ng-template>

<partner-wallet [partnerId]="lead?.consultationInfo?.partnerId" [showWallet]="false" (walletVal)="getPartnerWalletBalance($event)"></partner-wallet>


  