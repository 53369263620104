import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class TitleMetaService {

  constructor(private _titleService:Title, private _metaService:Meta) { 
    // console.log("TitleMetaService")
  }

  setTitle(str:string){
    this._titleService.setTitle(str);
  }

  setTitleDescription(title:string, description:string){
    this._titleService.setTitle(title)
    this._metaService.updateTag({ name:'description',content:description})
  }

  setBasicPageInfo(title:string, description:string, index:boolean, follow:boolean){
    this._titleService.setTitle(title)
    this._metaService.updateTag({ name:'description',content:description})
    this.robotTxtFunc(index,follow);
  }

  robotTxtFunc(index:boolean,follow:boolean){
    let str="";
    if(index){
      str += "index,"  
    }else{
      str += "noindex,"  
    }
    if(follow){
      str += "follow"  
    }else{
      str += "nofollow"  
    }
    this._metaService.addTag({ name:'robots',content: str})
  }

  ogTagsSetup(title:string, description:string, url:string ,imageUrl?:string, imageAlt?:string,index?:boolean,follow?:boolean){
    this._metaService.updateTag({ property: 'og:type', content: "website" })
    this._metaService.updateTag({ property: 'og:description', content: description })
    this._metaService.updateTag({ property: 'og:url', content: url })
    this._metaService.updateTag({ property: 'og:title', content: title })
    if(imageUrl) {
      this._metaService.updateTag({ property:'og:image',content:imageUrl})
      this._metaService.updateTag({ property:'og:image:secure_url',content:imageUrl})
    }
    if(imageAlt) {
      this._metaService.updateTag({ property:'og:image:alt',content:imageAlt})
    }
    this.setTitleDescription(title, description);
    if(index != null && follow != null) {
      this.robotTxtFunc(index, follow);
    }
  }
}
