<button
  class="btn btn-outline-primary border-0"
  *ngIf="showButton"
  (click)="openModal()"
>
  <i class="la la-calendar-plus font-24"></i>
</button>

<!-- <button class="btn btn-sm btn-outline-primary"> -->
<!-- <i style="font-size: 15px" class="material-icons me-1">
        confirmation_number
    </i> -->
<!-- + Book Appointment -->
<!-- </button> -->

<ng-template #appointment let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Book Appointment</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closeModal()"
      style="outline: none"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body d-flex flex-column justify-content-center w-100">
    <!-- get patient -->
    <div *ngIf="bookingSteps == 1">
      <div class="d-flex w-100 mb-3">
        <h5>Choose Patient</h5>
        <button class="btn btn-sm btn-info ms-auto me-2" (click)="setStep(2)">
          + New Patient
        </button>
      </div>
      <app-patient-search
        [patientId]="patientId"
        [listMode]="true"
        [searchText]="textForPatientSearch"
        (getPatient)="setPatient($event)"
      >
      </app-patient-search>
      <div
        class="card rounded p-2 mt-4"
        *ngIf="leadDetails && leadDetails.patientName"
      >
        <div class="d-flex w-100">
          <h5>Lead Details:</h5>
        </div>
        <hr class="m-1 p-0" />
        <div>
          <div>
            <span style="font-weight: 500">Lead Name:</span>
            {{ leadDetails.patientName ? leadDetails.patientName : "-" }}
          </div>
          <div>
            <span style="font-weight: 500">Lead Contact:</span>
            {{
              leadDetails.patientContactInfo
                ? "+" +
                  leadDetails.patientContactInfo.countryCode +
                  "-" +
                  leadDetails.patientContactInfo.number
                : ""
            }}
          </div>
        </div>
      </div>
    </div>

    <!-- create new patient -->
    <div *ngIf="bookingSteps == 2">
      <div class="d-flex align-items-center mb-4">
        <button
          class="btn btn-sm btn-outline-dark border-0 me-2"
          (click)="setStep(1)"
        >
          ❮
        </button>
        <h4>New Patient</h4>
      </div>
      <div>
        <div class="d-flex w-100">
          <div class="form-group w-50">
            <label>Name*</label>
            <input
              type="text"
              class="form-control"
              placeholder="Patient name"
              [(ngModel)]="newPatientForm['name']"
              required
            />
          </div>
          <div class="form-group w-50 ms-2">
            <label>Gender</label>
            <ng-select
              class="w-100"
              placeholder="Select gender"
              [(ngModel)]="newPatientForm['gender']"
            >
              <ng-option [value]="'male'">Male</ng-option>
              <ng-option [value]="'female'">Female</ng-option>
            </ng-select>
          </div>
        </div>

        <div class="d-flex flex-column card p-1 form-group">
          <div class="w-100 d-flex mb-1">
            <div>Contact(s)</div>
            <div
              (click)="addNewContact()"
              class="text-primary ms-auto"
              style="
                width: 1.5rem;
                height: 1.5rem;
                font-size: 1.2em;
                font-weight: 500;
                cursor: pointer;
                text-align: center;
                line-height: 1;
              "
            >
              +
            </div>
          </div>
          <div
            *ngFor="let item of newPatientForm['contacts']; let i = index"
            class="d-flex flex-column"
          >
            <div class="d-flex align-items-center mt-1">
              <div style="font-size: 1em; font-weight: 500" class="ms-1">
                {{ i + 1 }}.
              </div>
              <input
                type="number"
                class="form-control me-1 ms-2"
                style="width: 5rem; padding: 0.5rem"
                placeholder=""
                [(ngModel)]="item['countryCode']"
                required
              />
              <input
                type="number"
                class="form-control w-75"
                placeholder="Contact number"
                required
                [(ngModel)]="item['number']"
                required
              />
              <div
                class="btn btn-outline-danger border-0 ms-1"
                (click)="removeContact(i)"
              >
                &times;
              </div>
            </div>
            <div style="font-size: 0.9em" class="ms-4 mt-1">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  [(ngModel)]="item['isPrimaryContactNumber']"
                />
                <label class="form-check-label ms-1"
                  >isPrimaryContactNumber</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  [(ngModel)]="item['isWhatsappContactNumber']"
                />
                <label class="form-check-label ms-1"
                  >isWhatsappContactNumber</label
                >
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex flex-column card p-1 form-group">
          <div class="w-100 d-flex mb-1">
            <div>Email(s)</div>
            <div
              (click)="addNewEmail()"
              class="text-primary ms-auto"
              style="
                width: 1.5rem;
                height: 1.5rem;
                font-size: 1.2em;
                font-weight: 500;
                cursor: pointer;
                text-align: center;
                line-height: 1;
              "
            >
              +
            </div>
          </div>
          <div
            *ngFor="let item of newPatientForm['emails']; let i = index"
            class="d-flex flex-column"
          >
            <div class="d-flex align-items-center mt-1">
              <div style="font-size: 1em; font-weight: 500" class="ms-1">
                {{ i + 1 }}.
              </div>
              <input
                type="email"
                class="form-control ms-2 w-75"
                placeholder="Email"
                [(ngModel)]="item['email']"
              />
              <div
                class="btn btn-outline-danger border-0 ms-1"
                (click)="removeEmail(i)"
              >
                &times;
              </div>
            </div>
            <div style="font-size: 0.9em" class="ms-4 mt-1">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  [(ngModel)]="item['isPrimary']"
                />
                <label class="form-check-label ms-1">isPrimaryEmail</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- book-appointment with doctor -->
    <div *ngIf="bookingSteps == 3">
      <div class="d-flex w-100">
        <!-- <div class="d-flex flex-column w-100 me-2" *ngIf="leadDetails&&leadDetails.docvitaId==undefined">
                        <h5>Patient:</h5>
                        <app-patient-search (getPatient)="setPatient($event)"></app-patient-search>
                    </div> -->
      </div>
      <div class="d-flex justify-content-between mt-2">
        <div class="d-flex flex-column" style="width: 40%">
          <provider-available-slots
            [doctorProfile]="doctorProfile"
            [date]="selectedDate"
            (selectedSlot)="selectSlot($event)"
          ></provider-available-slots>
          <provider-appointments
            [doctorProfile]="doctorProfile"
            [date]="selectedDate"
          ></provider-appointments>
          <div class="line"></div>
          <provider-patient-appointments
            [doctorProfile]="doctorProfile"
            [patientProfile]="patientProfile"
            (listFetchState)="manageListFetchState($event)" 
          ></provider-patient-appointments>
        </div>
        <div
          class="d-flex flex-column card ms-3 p-3 mt-1 rounded"
          style="width: 60%"
        >
          <div class="card rounded mb-0 p-2">
            <div class="d-flex w-100">
              <h5>Patient Details:</h5>
              <button
                class="btn btn-sm btn-outline-primary border-0 ms-auto"
                (click)="setStep(1)"
              >
                Change?
              </button>
            </div>
            <hr class="m-1 p-0" />
            <!-- <div *ngIf="leadDetails&&leadDetails.patientName">
                                <div><span style="font-weight: 500;">Lead Name:</span> {{leadDetails.patientName?leadDetails.patientName:"-"}}</div>
                                <div><span style="font-weight: 500;">Lead Contact:</span> {{leadDetails.patientContactInfo?('+'+leadDetails.patientContactInfo.countryCode+'-'+leadDetails.patientContactInfo.number):""}}</div>
                            </div> -->
            <div *ngIf="patientProfile && patientProfile.name">
              <div class="pii-text">
                <span style="font-weight: 500">Name:</span>
                {{ patientProfile.name ? patientProfile.name : "-" }}
              </div>
              <div class="pii-text">
                <span style="font-weight: 500">Contact:</span>
                {{
                  patientProfile.primaryContactNumber
                    ? "+" +
                      patientProfile.primaryContactNumber.countryCode +
                      "-" +
                      patientProfile.primaryContactNumber.number
                    : ""
                }}
              </div>
              <div
                *ngIf="patientProfile && patientProfile.organisationConnections"
                class="mt-2"
              >
                <span style="font-weight: 500">Quick Connections:</span>
                <div class="connections-list-container">
                    <div
                    [ngClass]="{
                      'slot-selected-2': doctorId == doc.organisationId
                    }"
                    *ngFor="let doc of quickConnections"
                    class="card m-1 p-1 pointer"
                    (click)="docSearchRef.searchDoctor(doc.organisationId)"
                  >
                    {{
                      doc.organisationName
                        ? doc.organisationName
                        : doc.organisationId
                    }}
                  </div>
                </div>
                
              </div>
            </div>
            <!-- <div><span style="font-weight: 500;">City:</span> {{leadDetails.city}}</div> -->
          </div>
          <div class="card rounded p-2 mb-0 mt-3">
            <h5>Doctor Details:</h5>
            <hr class="m-1 p-0" />
            <div class="d-flex flex-column w-100 mb-2 mt-2">
              <app-doctor-search
                [providerId]="doctorId"
                [searchText]="doctorProfile.fullName()"
                (getProvider)="setProvider($event)"
                #docSearchRef
              ></app-doctor-search>
            </div>
            <div *ngIf="doctorProfile && doctorProfile.id">
              <div>
                <span style="font-weight: 500">Name:</span>
                {{ doctorProfile.name ? doctorProfile.fullName() : "-" }}
              </div>
              <div>
                <span style="font-weight: 500">Id:</span>
                {{ doctorProfile.id ? doctorProfile.id : "" }}
              </div>
              <div *ngIf="doctorProfile&&doctorProfile.id&&doctorProfile.onlineConsultationConfig" class="standard-details-container mt-3">
                <span *ngIf="doctorProfile.onlineConsultationConfig.fee">Standard fees: ₹{{doctorProfile.onlineConsultationConfig.fee}}</span>
                <span>
                    |
                </span>
                <span *ngIf="doctorProfile.appointmentDuration">Standard duration: {{doctorProfile.appointmentDuration}} mins</span>
              </div>
            </div>
            <!-- <div><span style="font-weight: 500;">City:</span> {{leadDetails.city}}</div> -->
          </div>
          <h6 class="mt-3">Date:</h6>
          <div class="d-flex">
            <input
              type="date"
              class="form-control"
              style="width: 180px"
              [(ngModel)]="selectedDate"
            />
            <!-- date scroller arrows -->
            <div class="d-flex ms-1">
              <button class="btn btn-outline-primary btn-sm border-0 ps-0 pe-0" (click)="appointmentDateScroller('-')"> <i class="feather icon-chevron-left" style="font-size: 16px;"></i> </button>
              <button class="btn btn-outline-primary btn-sm border-0 ps-0 pe-0" (click)="appointmentDateScroller('+')"> <i class="feather icon-chevron-right" style="font-size: 16px;"></i> </button>
          </div>
          </div>
          <div class="d-flex mt-3">
            <div>
              <h6>Time:</h6>
              <input
                type="time"
                class="form-control"
                style="width: 180px"
                [(ngModel)]="selectedTime"
              />
            </div>
            <div class="ms-2">
              <h6>Duration:(Min)</h6>
              <input
                type="number"
                class="form-control"
                style="width: 180px"
                [(ngModel)]="selectedSlotLength"
              />
              <div class="d-flex mt-1" style="font-size: 14px">
                <span
                  class="me-2 p-1 card mb-0 slot"
                  *ngFor="let min of [15, 30, 45]"
                  (click)="setSlotLength(min)"
                  >{{ min }} min</span
                >
              </div>
            </div>
          </div>
          <div class="d-flex mt-3 w-100">
            <div class="position-relative">
              <h6>Amount(₹)</h6>
              <input
                type="number"
                class="form-control"
                style="width: 180px"
                [(ngModel)]="selectedAmount"
              />
              <div class="text-muted" style="font-size: 0.85em;position: absolute;top: 65px;">
                <ng-container *ngIf="amtfetching">
                  Fetching Amount...
                </ng-container>
              </div>
              <div>Client's balance: ₹ {{walletBalance}}</div>
              <div class="text-muted" style="font-size: 0.85em;position: absolute;top: 65px;">
                <ng-container *ngIf="isWalletBalanceLoading">
                  Fetching Wallet Balance...
                </ng-container>
              </div>
              <div class="form-check mb-2 mt-2" style="font-size: 14px">
                <input
                  [disabled]="disablePayViaWallet"
                  class="form-check-input"
                  type="checkbox"
                  id="payViaWallet"
                  [(ngModel)]="payViaWallet"
                />
                <label class="form-check-label ms-1" for="payViaWallet">
                  Pay Via Wallet?
                </label>
              </div>
            </div>
            <div class="ms-2 w-100" *ngIf="!payViaWallet">
              <div class="form-check mb-2 mt-2" style="font-size: 14px">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="flexCheckDefault"
                  [(ngModel)]="alreadyPaid"
                />
                <label class="form-check-label ms-1" for="flexCheckDefault">
                  Already paid?
                </label>
              </div>
              <form [formGroup]="payInfoform" *ngIf="alreadyPaid">
                <div
                  class="card p-1 d-flex flex-column"
                  style="font-size: 13px"
                >
                  <div class="mt-2">
                    <b>Payment Id:</b>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Payment Id"
                      style="font-size: 13px"
                      formControlName="paymentId"
                      required
                    />
                  </div>
                  <div class="mt-2">
                    <b>Payment Source:</b>
                    <div dropdown class="btn-group d-inline-block w-100">
                      <button
                        id="button-animated"
                        dropdownToggle
                        type="button"
                        class="btn btn-sm btn-outline-secondary dropdown-toggle"
                        aria-controls="dropdown-animated"
                      >
                        {{ selectedPaymentSource || "Select Payment Source" }}
                      </button>
                      <ul
                        id="dropdown-animated"
                        *dropdownMenu
                        class="dropdown-menu"
                        role="menu"
                        aria-labelledby="button-animated"
                      >
                        <li
                          role="menuitem"
                          *ngFor="let source of PaymentSourceArray"
                        >
                          <a
                            class="dropdown-item"
                            (click)="paymentSourceChanged(source)"
                          >
                            {{ source }}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="mt-2">
                    <b>Payment Done On:</b>
                    <input
                      type="date"
                      class="form-control mb-2"
                      placeholder=""
                      style="font-size: 13px"
                      formControlName="paymentDoneDate"
                      required
                    />
                    <input
                      type="time"
                      class="form-control mb-2"
                      placeholder=""
                      style="font-size: 13px"
                      formControlName="paymentDoneTime"
                      required
                    />
                  </div>
                  <!-- <div class="mt-2">
                                        <b>Total Amount Paid(₹):</b>
                                        <input type="text" class="form-control" placeholder="" style="font-size: 13px;"
                                            formControlName="totalAmountPaid" required>
                                    </div> -->
                  <button
                    class="btn btn-sm btn-outline-primary ms-auto mt-2 p-0 ps-1 pe-1"
                    style="font-size: 12px"
                    (click)="payInfoform.reset()"
                  >
                    clear
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="bookAppointment()"
      *ngIf="bookingSteps == 3"
    >
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
        *ngIf="bookingLoader"
      ></span>
      Book
    </button>
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="setStep(3)"
      *ngIf="bookingSteps == 1"
      [disabled]="patientProfile?.docvitaId == undefined"
    >
      Next ➔
    </button>
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="createPatient()"
      *ngIf="bookingSteps == 2"
    >
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
        *ngIf="bookingLoader"
      ></span>
      Create & Continue Booking ➔
    </button>
  </div>
</ng-template>

<clashing-appointments-modal #apptClashModal></clashing-appointments-modal>