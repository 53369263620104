import { Expose } from "class-transformer";
import { Appointment } from "./Appointment";

export class PaylinkDetails {
  @Expose() id: string; //payment link id
  @Expose() amount: number;
  @Expose() created_at: number;
  @Expose() updated_at: number;
  @Expose() description: string;
  @Expose() short_url: string;
  @Expose() status: string;
  @Expose() consultationInfo: PayLinkAppointment;
  @Expose() reassignmentInfo: any;
  @Expose() customer: any;
  @Expose() completedOn: number;
  @Expose() createdById: string;
  @Expose() createdByName: string;
  @Expose() type: PaylinkType;
  @Expose() leadId: string;
  @Expose() providerId: string;
  @Expose() patientId: string;
  @Expose() appointmentId: string;
  @Expose() markedAsCancelled: ManualCancellation;
}

class PayLinkAppointment extends Appointment {
  @Expose() patientsArray: any[];
}

export class ManualCancellation {
  @Expose() updatedById: string;
  @Expose() updatedByName: string;
  @Expose() updatedOn: number;
  @Expose() bool: boolean;
}
export enum PaylinkType {
  BOOKING = "BOOKING",
  REASSIGN = "REASSIGN",
  ADDON = "ADDON",
  CREDIT_PURCHASE = "CREDIT_PURCHASE",
  SESSION_TYPE_CHANGE = "SESSION_TYPE_CHANGE",
}
