<button class="btn btn-sm btn-outline-primary" *ngIf="!viewOnlyMode&&showButton" (click)="openModal();">
    + Payment-Link
</button>

<button *ngIf="viewOnlyMode&&showButton" class="btn btn-sm btn-outline-primary" (click)="viewOnlyopenModal();">
    💸 Payment Links
</button>

<ng-template #paylink let-modal>
    <div class="modal-header">
        <h4 class="modal-title" *ngIf="pageNumber==1">{{getTitle}}</h4>
        <h4 class="modal-title" *ngIf="pageNumber==3">Payment Link</h4>
        <h4 class="modal-title" *ngIf="pageNumber==4">💸 All Payment links</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()" style="outline: none;">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body d-flex flex-column justify-content-center w-100">
        <!-- payment form -->
        <ng-container *ngIf="pageNumber==1">
            <div class="d-flex w-100 mb-3">
                <button class="btn btn-sm btn-primary ms-auto me-2" (click)="getAllPaymentLinks()">💸 View Payment
                    Links</button>
            </div>
            <div class="mt-2 w-100 d-flex align-items-center justify-content-center">
                <button class="w-100 btn btn btn-outline-primary" [disabled]="!isBookingMode" [ngClass]="{'btn-primary': paymentSource=='cashfree'}"
                    style="border-radius: 5px 0px 0px 5px;" (click)="togglePaymentSource('cashfree')">
                    <img src="../../../../assets/Cashfree-Dark.svg" alt="" style="height: 2rem;">
                </button>
                <button class="w-100 btn btn-outline-primary" [ngClass]="{'btn-primary': paymentSource=='razorpay'}"
                    style="border-radius: 0px 5px 5px 0px;" (click)="togglePaymentSource('razorpay')">
                    <img src="../../../../assets/Razorpay_logo.svg" alt="" style="height: 2rem;">
                </button>
            </div>
            <div style="font-weight:500;" class="mt-3">Patient:</div>
            <div class="d-flex align-items-center w-100">
                <app-patient-search [patientId]="lead.consultationInfo?.patientId" (getPatient)="setPatient($event)" class="w-100">
                </app-patient-search>
                <button class="btn btn-sm btn-info ms-2" style="width: 10rem;" *ngIf="isBookingMode" (click)="newPatientCreator()">+ New
                    Patient</button>
            </div>

            <div class="w-100 d-flex align-items-center mt-3">
                <div class="w-50">
                    <div style="font-weight:500;">Customer Name:</div>
                    <input type="text" placeholder="Name" class="form-control"
                        [(ngModel)]="paymentLinkForm['customerName']">
                </div>
                <div class="ms-2 w-50">
                    <div style="font-weight:500;">Customer Email:</div>
                    <input type="text" placeholder="Email" class="form-control"
                        [(ngModel)]="paymentLinkForm['customerEmail']">
                </div>
            </div>
            <div class="mt-3">
                <div style="font-weight:500;">Customer Phone:</div>
                <div class="d-flex">
                    <input type="tel" placeholder="CC" style="width:5rem;" class="form-control"
                        [(ngModel)]="paymentLinkForm['customerContact'].countryCode">
                    <input type="tel" placeholder="Number" class="form-control ms-2"
                        [(ngModel)]="paymentLinkForm['customerContact'].number">
                </div>
            </div>
            <ng-container *ngIf="isReassignMode">
                <ng-container *ngTemplateOutlet="providerForm"></ng-container>
            </ng-container>
            <ng-container *ngIf="!isReassignMode">
                <div class="d-flex flex-column position-relative" class="mt-3">
                    <div style="font-weight:500;" >
                        Amount(₹):
                    </div>
                    <input type="number" placeholder="Amount" class="form-control" style="width: 180px;"
                        [(ngModel)]="paymentLinkForm['amount']">
                    <div class="text-muted" style="font-size: 0.85em;position: absolute;top: 50px;left: 190px;">
                        <ng-container *ngIf="amtfetching">
                            Fetching Amount...
                        </ng-container>
                    </div>
                </div>
                
                <div style="font-weight:500;" class="mt-3">Payment Description: <span class="text-muted"
                        style="font-size: 13px;">{{paymentSource&&paymentSource=='cashfree'?'(required)':'(optional)'}}</span>
                </div>
                <textarea rows="2" placeholder="Description (auto generated, if field empty.)" class="form-control"
                    [(ngModel)]="paymentLinkForm['description']"></textarea>
            </ng-container>
            <ng-container *ngIf="isReassignMode">
                <ng-container *ngIf="!showReassignOptions">
                    <div class="d-flex flex-column position-relative" class="mt-3">
                        <div style="font-weight:500;" >
                            Excess Amount(₹):
                            <span class="text-muted ms-1" style="font-size: 13px;">(Existing balance: ₹{{lead.netAmountReceived?lead.netAmountReceived:0}})</span>
                        </div>
                        <input type="number" placeholder="Amount" class="form-control" style="width: 180px;"
                            [(ngModel)]="paymentLinkForm['amount']">
                            
                    </div>
                    <div style="font-weight:500;" class="mt-3">Payment Description: <span class="text-muted"
                            style="font-size: 13px;">{{paymentSource&&paymentSource=='cashfree'?'(required)':'(optional)'}}</span>
                    </div>
                    <textarea rows="2" placeholder="Description (auto generated, if field empty.)" class="form-control"
                        [(ngModel)]="paymentLinkForm['description']"></textarea>
                </ng-container>
                <ng-container *ngIf="showReassignOptions">
                    <div class="alert alert-primary w-100 mt-3" role="alert">
                        {{noticeText}}
                    </div>
                    <div class="d-flex flex-column w-100">
                        <button class="btn btn-outline-info mt-1" (click)="setReassignmentOptionChoice('reassign')">
                            Reassign Directly
                        </button>
                        <button class="btn btn-outline-primary mt-2" *ngIf="doctorProfile.onlineConsultationConfig?.fee" (click)="setReassignmentOptionChoice('provider-fee')">
                            Create Payment Link with provider fee ₹{{doctorProfile.onlineConsultationConfig?.fee}}
                        </button>
                        <button class="btn btn-outline-dark mt-2" (click)="setReassignmentOptionChoice('manual')">
                            Set amount manually
                        </button>
                    </div>
                </ng-container>
            </ng-container>
            <div class="form-check form-switch mt-3" *ngIf="isBookingMode">
                <input [(ngModel)]="creditSwitch" type="checkbox" class="form-check-input" id="credit-switch" />
                <label class="form-check-label ms-2" for="credit-switch" style="font-size: 0.9em;">
                    Add money in client's wallet
                </label>
            </div>
            <div class="form-check form-switch mt-3" *ngIf="isBookingMode">
                <input [(ngModel)]="bookingSwitch" type="checkbox" class="form-check-input" id="appt-switch"
                    (ngModelChange)="toggleBookingSwitch()" />
                <label class="form-check-label ms-2" for="appt-switch" style="font-size: 0.9em;">
                    Book Appointment after payment?
                </label>
            </div>
            <ng-container *ngIf="this.bookingSwitch">
                <hr/>
                <h5>Booking Details:</h5>
                <ng-container *ngTemplateOutlet="providerForm"></ng-container>
            </ng-container>
        </ng-container>

        <!-- create new patient -->
        <ng-container *ngIf="pageNumber==2">
            <div class="d-flex align-items-center mb-4">
                <button class="btn btn-sm btn-outline-dark border-0 me-2" (click)="gotoPage(1)">❮</button>
                <h5>New Patient</h5>
            </div>
            <div>
                <div class="d-flex w-100">
                    <div class="form-group w-50">
                        <label>Name*</label>
                        <input type="text" class="form-control" placeholder="Patient name"
                            [(ngModel)]="newPatientForm['name']" required>
                    </div>
                    <div class="form-group w-50 ms-2">
                        <label>Gender</label>
                        <ng-select class="w-100" placeholder="Select gender" [(ngModel)]="newPatientForm['gender']">
                            <ng-option [value]="'male'">Male</ng-option>
                            <ng-option [value]="'female'">Female</ng-option>
                        </ng-select>
                    </div>
                </div>

                <div class="d-flex flex-column card p-1 form-group">
                    <div class="w-100 d-flex mb-1">
                        <div>Contact(s)</div>
                        <div (click)="addNewContact()" class="text-primary ms-auto"
                            style="width: 1.5rem;height: 1.5rem;font-size: 1.2em;font-weight: 500;cursor: pointer;text-align: center;line-height: 1;">
                            +</div>
                    </div>
                    <div *ngFor="let item of newPatientForm['contacts']; let i = index;" class="d-flex flex-column">
                        <div class="d-flex align-items-center mt-1">
                            <div style="font-size: 1em;font-weight: 500;" class="ms-1">{{i+1}}. </div>
                            <input type="number" class="form-control me-1 ms-2" style="width: 5rem; padding: 0.5rem"
                                placeholder="" [(ngModel)]="item['countryCode']" required />
                            <input type="number" class="form-control w-75" placeholder="Contact number" required
                                [(ngModel)]="item['number']" required />
                            <div class="btn btn-outline-danger border-0 ms-1" (click)="removeContact(i)">&times;</div>
                        </div>
                        <div style="font-size: 0.9em;" class="ms-4 mt-1">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox"
                                    [(ngModel)]="item['isPrimaryContactNumber']">
                                <label class="form-check-label ms-1">isPrimaryContactNumber</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox"
                                    [(ngModel)]="item['isWhatsappContactNumber']">
                                <label class="form-check-label ms-1">isWhatsappContactNumber</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex flex-column card p-1 form-group">
                    <div class="w-100 d-flex mb-1">
                        <div>Email(s)</div>
                        <div (click)="addNewEmail()" class="text-primary ms-auto"
                            style="width: 1.5rem;height: 1.5rem;font-size: 1.2em;font-weight: 500;cursor: pointer;text-align: center;line-height: 1;">
                            +</div>
                    </div>
                    <div *ngFor="let item of newPatientForm['emails']; let i = index;" class="d-flex flex-column">
                        <div class="d-flex align-items-center mt-1">
                            <div style="font-size: 1em;font-weight: 500;" class="ms-1">{{i+1}}. </div>
                            <input type="email" class="form-control ms-2 w-75" placeholder="Email"
                                [(ngModel)]="item['email']">
                            <div class="btn btn-outline-danger border-0 ms-1" (click)="removeEmail(i)">&times;</div>
                        </div>
                        <div style="font-size: 0.9em;" class="ms-4 mt-1">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="item['isPrimary']">
                                <label class="form-check-label ms-1">isPrimaryEmail</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </ng-container>

        <!-- copy payment link details -->
        <ng-container *ngIf="pageNumber==3">
            <div class="w-100 d-flex flex-column align-items-center justify-content-center">
                <div class="border rounded d-flex flex-column p-2 w-75 align-items-center" style="font-size: 15px;">
                    <div><span
                            class="wt-500 me-2">Patient:</span>{{paymentLinkForm["customerName"]?paymentLinkForm["customerName"]:""}}
                    </div>
                    <div><span
                            class="wt-500 me-2">Contact:</span>{{paymentLinkForm["customerContact"]&&paymentLinkForm["customerContact"]["number"]?'+'+paymentLinkForm["customerContact"]["countryCode"]+'-'+paymentLinkForm["customerContact"]["number"]:""}}
                    </div>
                    <div class="d-flex" *ngIf="(isBookingMode||isReassignMode)&&appointmentBookingForm['time']">
                        <div class="wt-500 me-2" style="font-size: 13px;">{{isReassignMode?"Reassignment:":"Booking Details:"}}</div>
                        <div class="d-flex flex-column ms-2" style="font-size: 13px;">
                            <div><span
                                    class="wt-500 me-2">Doctor:</span>{{doctorProfile&&doctorProfile.fullName()?doctorProfile.fullName():""}}
                            </div>
                            <div><span
                                    class="wt-500 me-2">Date|Time:</span>{{getBookingDateTime}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="input-group mt-3 w-75">
                    <input type="text" class="form-control" [(ngModel)]="paymentLink" placeholder="Payment Link"
                        disabled=true>
                    <div class="input-group-append">
                        <button class="btn btn-outline-info" type="button" (click)="copyPaymentLink()">
                            <i style="font-size: 14px;" class="material-icons me-1">content_copy</i>
                            Copy
                        </button>
                    </div>
                </div>
                <div class="d-flex mt-3">
                    <button class="btn btn-sm btn-outline-dark" (click)="newPaymentLink()">
                        <i style="font-size: 14px;" class="material-icons me-1">chevron_left</i>
                        New payment link
                    </button>
                    <button class="btn btn-sm btn-outline-primary ms-2" (click)="viewAllPayments()">
                        View all payment links
                        <i style="font-size: 14px;" class="material-icons me-1">chevron_right</i>
                    </button>
                </div>
            </div>
        </ng-container>

        <!-- all payment links -->
        <ng-container *ngIf="pageNumber==4">
            <div class="d-flex align-items-center mb-3">
                <button *ngIf="viewOnlyMode==false" class="btn btn-sm btn-outline-dark border-0 me-2"
                    (click)="gotoPage(1)">❮</button>
                <ng-container *ngTemplateOutlet="
                    paymentLinkFilterTemplate;
                ">
                </ng-container>
            </div>

            <div class="payments-list-container" *ngIf="allPaymentLinks&&allPaymentLinks.length>0">
                <div *ngFor="let item of allPaymentLinks" class="border rounded p-2 mt-1 mb-1 d-flex flex-column w-100"
                    style="font-size: 14px;">
                    <div class="d-flex align-items-center mb-1">
                        <div *ngIf="item.source&&(item.source=='stripe'||item.source=='Stripe')" class="wt-500 border rounded p-1"
                        style="color: #862a7c;width: fit-content;">
                        Stripe
                        </div>
                        <div *ngIf="item.source&&(item.source=='razorpay'||item.source=='Razorpay')" class="wt-500 border rounded p-1"
                        style="color: #062654;width: fit-content;">
                        Razorpay
                        </div>
                        <div *ngIf="item.source&&(item.source=='cashfree'||item.source=='Cashfree')" class="wt-500 border rounded p-1"
                            style="color: #6930ca;width: fit-content;">
                            Cashfree
                        </div>
                        <div class="wt-500 border rounded p-1 text-primary ms-2"
                            style="width: fit-content;font-size: 10px;font-weight: 600;">
                            {{item.type?item.type:(item['consultationInfo']?"BOOKING":"")}}
                        </div>
                    </div>
                    
                    <div class="w-100 d-flex align-items-center">
                        <span class="wt-500 text-success me-1">{{item.amount?((item.currency&&item.currency=='USD'?"$ ":"₹ ") + item.amount):""}}</span>
                        <span class="wt-500">{{item.customer?item.customer["name"]:""}}</span>
                        <span class="text-muted ms-2">{{item.created_at?(item.created_at|date:'short'):""}}</span>
                        <span *ngIf="item.status"
                            [class]="getStatusStyle(item.status)+' ms-auto'">{{item.status}}</span>
                    </div>
                    <div class="w-100 d-flex mt-2" style="font-size: 14px;">
                        <div class="text-muted">
                            Contact: {{item.customer?item.customer["contact"]:""}}
                            <i style="font-size: 12px;" class="material-icons ms-1 pointer text-primary"
                                (click)="copy(item.customer?item.customer['contact']:'')">content_copy</i>
                        </div>
                        <div class="text-muted ms-auto" *ngIf="item.customer&&item.customer['email']">
                            Email: {{item.customer?item.customer["email"]:""}}
                            <i style="font-size: 12px;" class="material-icons ms-1 pointer text-primary"
                                (click)="copy(item.customer?item.customer['email']:'')">content_copy</i>
                        </div>
                    </div>
                    <div class="w-100 d-flex flex-column mt-1" style="font-size: 12px;">
                        <div class="text-muted" *ngIf="item.description">
                            Description: {{item.description}}
                            <i style="font-size: 12px;" class="material-icons ms-1 pointer text-primary"
                                (click)="copy(item.description)">content_copy</i>
                        </div>
                        <div class="text-muted" style="font-size: 12px;" *ngIf="item.id">
                            Link Id: {{item.id}}
                            <i style="font-size: 12px;" class="material-icons ms-1 pointer text-primary"
                                (click)="copy(item.id)">content_copy</i>
                        </div>
                        <div class="text-muted" *ngIf="item.paymentId">
                            Payment Id: {{item.paymentId}}
                            <i style="font-size: 12px;" class="material-icons ms-1 pointer text-primary"
                                (click)="copy(item.id)">content_copy</i>
                        </div>
                        <div class="text-muted" *ngIf="item.completedOn">
                            CompletedOn: {{item.completedOn?(item.completedOn|date:'short'):""}}
                        </div>
                    </div>
                    <div class="w-100 d-flex justify-content-end mt-2" style="font-size: 12px;">
                        <div class="text-muted border rounded p-1 me-1">
                            {{item.short_url}}
                        </div>
                        <button class="btn btn-sm btn-outline-primary" (click)="copy(item.short_url)">
                            <i style="font-size: 12px;" class="material-icons me-1">content_copy</i>
                            copy link
                        </button>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center w-100 mt-3">
                <div class="spinner-border" role="status" *ngIf="allLinksLoader">
                    <span class="sr-only">Loading...</span>
                </div>
                <h5 class="text-muted" *ngIf="noPaymentLinks">No Links 💤</h5>
            </div>
        </ng-container>
    </div>
    <div class="modal-footer">
        <button class="btn btn-outline-dark me-2" (click)="closeModal()">
            Close
        </button>

        <button type="button" class="btn btn-outline-dark" [disabled]="btnLoader" (click)="createPaymentLink()" *ngIf="pageNumber==1&&isBookingMode">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="btnLoader"></span>
            Create PayLink
        </button>
        <button type="button" class="btn btn-outline-dark" [disabled]="btnLoader" (click)="createPaymentLink()" *ngIf="pageNumber==1&&isReassignMode&&!showReassignOptions">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="btnLoader"></span>
            Create Reassignment Paylink
        </button>
        <button type="button" class="btn btn-outline-dark" [disabled]="btnLoader" (click)="createPaymentLink()" *ngIf="pageNumber==1&&isAddonMode">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="btnLoader"></span>
            Create Addon Paylink
        </button>

        <button type="button" class="btn btn-outline-dark" [disabled]="btnLoader" (click)="createPatient()" *ngIf="pageNumber==2">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="btnLoader"></span>
            Create & Continue ➔
        </button>
    </div>
</ng-template>

<ng-template #paymentLinkFilterTemplate>
    <div dropdown class="btn-group d-inline-block mx-2">
        <button id="button-animated" dropdownToggle type="button" class="btn btn-outline-secondary dropdown-toggle"
            aria-controls="dropdown-animated">
            Show {{selectedFilter}}
        </button>

        <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
            aria-labelledby="button-animated">
            <li role="menuitem" (click)="changeViewPaymentLinkFilter('all')">
                <a class="dropdown-item p-3">
                    All
                </a>
            </li>
            <li role="menuitem" (click)="changeViewPaymentLinkFilter('paid')">
                <a class="dropdown-item p-3">
                    Only Paid
                </a>
            </li>
            <li role="menuitem" (click)="changeViewPaymentLinkFilter('unpaid')">
                <a class="dropdown-item p-3">
                    Only Unpaid
                </a>
            </li>
        </ul>
    </div>
</ng-template>
<ng-template #providerForm>
    <div style="position: relative;">
        <div class="doctor-appt-details">
            <div class="w-100 d-flex flex-column" class="doctor-appt-details-card">
                <provider-available-slots [doctorProfile]="doctorProfile"
                    [date]="appointmentBookingForm['date']" (selectedSlot)="selectSlot($event)">
                </provider-available-slots>
                <provider-appointments [doctorProfile]="doctorProfile"
                    [date]="appointmentBookingForm['date']"></provider-appointments>
            </div>

            <div class="w-100 d-flex flex-column" style="margin-top: 1rem;"
                *ngIf="doctorProfile&&doctorProfile.id&&patientProfile&&patientProfile.docvitaId"
                class="doctor-appt-details-card">
                <provider-patient-appointments 
                [doctorProfile]="doctorProfile"
                [patientProfile]="patientProfile">
                </provider-patient-appointments>
            </div>
        </div>
    </div>
    <div style="font-weight:500;position: relative;" class="mt-3">
        {{isReassignMode?"New Provider:":"Provider:"}}
    </div>
    <app-doctor-search (getProvider)="setProvider($event)" #docSearchRef></app-doctor-search>
    <ng-container *ngIf="patientProfile&&patientProfile.organisationConnections">
        <div class="ms-auto me-auto">or</div>
        <div class="border p-2 rounded mt-3" style="font-size: 14px;">
            <div style="font-weight:500;">Doctor Connections:</div>
            <div class="connections-list-container">
                <div [ngClass]="{'slot-selected-2': doctorId==doc.organisationId}"
                *ngFor="let doc of quickConnections" class="card m-1 p-1 pointer w-100"
                (click)="docSearchRef.searchDoctor(doc.organisationId)">
                {{doc.organisationName?doc.organisationName:doc.organisationId}}
                </div>
            </div>
            
        </div>
    </ng-container>

    <div *ngIf="doctorProfile&&doctorProfile.id&&doctorProfile.onlineConsultationConfig"
        class="standard-details-container">
        <span *ngIf="doctorProfile.onlineConsultationConfig.fee">Standard fees:
            ₹{{doctorProfile.onlineConsultationConfig.fee}}</span>
        <span>
            |
        </span>
        <span *ngIf="doctorProfile.appointmentDuration">Standard duration:
            {{doctorProfile.appointmentDuration}} mins</span>
    </div>

    <div style="font-weight:500;width: fit-content;" class="mt-3" tooltip="Date of the appointment">Appt. Date:</div>
    <input type="date" class="form-control" style="width: 180px;"
        [(ngModel)]="this.appointmentBookingForm['date']">
    <div class="d-flex mt-3">
        <div>
            <div style="font-weight:500;">Time:</div>
            <input type="time" class="form-control" style="width: 180px;"
                [(ngModel)]="this.appointmentBookingForm['time']">
        </div>
        <div class="ms-2">
            <div style="font-weight:500;">Duration:(Min)</div>
            <input type="number" class="form-control" style="width: 180px;"
                [(ngModel)]="this.appointmentBookingForm['slotLength']">
            <div class="d-flex mt-1" style="font-size: 14px;">
                <span class="me-2 p-1 card slot mb-0" *ngFor="let min of [15,30,45]"
                    (click)="setSlotLength(min)">{{min}} min</span>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #directReassignPopup let-modal>
    <div class="modal-body">
        <div class="w-100 p-2">
            {{noticeText}}
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-sm btn-outline-dark me-2" (click)="closeNoticeModal()">
            Cancel
        </button>
        <button class="btn btn-sm btn-outline-info me-2" (click)="closeNoticeModal()">
            Reassign Directly
        </button>
        <button class="btn btn-sm btn-outline-primary me-2" (click)="closeNoticeModal()">
            Create Payment Link with provider fee
        </button>
    </div>
</ng-template>
<clashing-appointments-modal #apptClashModal></clashing-appointments-modal>

