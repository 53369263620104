export enum ChatMessageChannel {
    whatsapp = "whatsapp",
    docvita = "docvita"
}


export enum EscalationType {
    refund = "refund",
    providerNoShow = "providerNoShow",
}

export enum ChatListItemAssociatedProfileType {
    provider = "provider",
    client = "client",
}

export enum ActionRelatedTo {
    provider = "provider",
    client = "client",
    docvita_team = "docvita_team",
}

export enum ChatMessageStatus {
    draft = "draft",
    deleted = "deleted",
    failed = "failed",
    sent = "sent",
    received = "received",
    delivered = "delivered",
    accepted = "accepted",
    seen = "seen",
}

export enum WAMessageTypes {
    text = "text",
    image = "image",
    document = "document",
    audio = "audio",
    video = "video",
    location = "location",
    sticker = "sticker",
    contacts = "contacts",
    template = "template",
    richTemplate = "richTemplate",
    buttonTemplate = "buttonTemplate",
    interactive_button = "interactive_button",
    interactive_list = "interactive_list",
    consultation_info = "consultation_info",
    any_media = "any_media",
    time_separator = "time_separator"
}