import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { LedgerService } from 'src/app/ledger/services/ledger.service';
import { Lead } from '../../models-ts/Entities/Lead';
import { CountrywiseFeeCalc, Currency } from '../../models-ts/Helpers/countrywiseFeeCalc';
import { CashgramRefundsService } from '../../services/cashgram-refunds/cashgram-refunds.service';

@Component({
  selector: 'issue-dvc-credits',
  templateUrl: './issue-dvc-credits.component.html',
  styleUrls: ['./issue-dvc-credits.component.scss']
})
export class IssueDvcCreditsComponent implements OnInit {

  @Input() lead: Lead = new Lead();

  modalRef: BsModalRef;
  loader:boolean = false;
  currency:Currency = Currency.INR;
  amount:number=0;
  checkingCgActiveLinks:boolean = false;
  hasActiveLinks:boolean = false;
  constructor(
    private modalService: BsModalService,
    private ledgerService: LedgerService,
    private toastr:ToastrService,
    private cgRefundService: CashgramRefundsService
  ) { }

  ngOnInit(): void {
  }

  @ViewChild('dvcCreditsModal') dvcCreditsModal;

  openModal() {
    this.checkIfCashgramHasActiveLinks();
    this.amount=null;
    this.currency = Currency.INR;
    if(this.lead&&this.lead.id && this.lead.netIncomingBalancePaidByClient() && this.lead.netIncomingBalancePaidByClient().amount > 0){
      this.amount = this.lead.netIncomingBalancePaidByClient().amount;
      this.currency = this.lead.netIncomingBalancePaidByClient().currency;
    }
    this.modalRef = this.modalService.show(this.dvcCreditsModal)
  }

  closeModal() {
    this.modalRef.hide();
  }

  async checkIfCashgramHasActiveLinks(){
    try {
      this.checkingCgActiveLinks = true;
      const hasActiveRefundLinks = await this.cgRefundService.checkIfAnyActiveCGLinks(this.lead?.id);
      if(hasActiveRefundLinks){
        this.hasActiveLinks = true;
      }else{
        this.hasActiveLinks = false;
      }
      this.checkingCgActiveLinks = false;
    } catch (error) {
      this.checkingCgActiveLinks = false;
      this.hasActiveLinks = false;
    }
  }
  get displayAmount() {
    return new CountrywiseFeeCalc().currencyToSymbol(this.lead.netIncomingBalancePaidByClient().currency) + ' ' + this.lead.netIncomingBalancePaidByClient().amount;
  }
  get isValidAmountForTransfer() {
    if (this.lead && this.lead.netIncomingBalancePaidByClient() && this.lead.netIncomingBalancePaidByClient().amount > 0) {
      return true;
    } else {
      return false;
    }
  }
  async initiateTransfer() {
    if (this.isValidAmountForTransfer && this.amount!=null && +this.amount>0 && this.currency) {
      try {
        this.loader = true;
        const res = await this.ledgerService.refundFromAppointmentToWalletCredit(this.lead.id,this.lead.consultationInfo.patientId,+this.amount,this.currency)
        this.toastr.success("Transfer complete!")
        this.closeModal()
        this.loader = false;
      } catch (error) {
        this.toastr.error("Failed to transfer..")
        this.loader = false;
      }
    }
  }

  async initiateTransferToPartnerWallet() {
    if (this.isValidAmountForTransfer && this.hasPartnerId && this.amount!=null && +this.amount>0 && this.currency) {
      try {
        this.loader = true;
        const res = await this.ledgerService.appointmentWalletToPartnerWallet(this.lead.id,this.lead.consultationInfo.partnerId,+this.amount,this.currency)
        this.toastr.success("Transfer complete!")
        this.closeModal()
        this.loader = false;
      } catch (error) {
        this.toastr.error("Failed to transfer..")
        this.loader = false;
      }
    }
  }

  async initiateTransferToPlanWallet() {
    if (this.isValidAmountForTransfer && this.lead.consultationInfo && this.lead.consultationInfo.planPurchaseId && this.amount!=null && +this.amount>0 && this.currency) {
      try {
        this.loader = true;
        const res = await this.ledgerService.appointmentWalletToPlanWallet(this.lead.id,this.lead.consultationInfo.planPurchaseId,+this.amount,this.currency)
        this.toastr.success("Transfer complete!")
        this.closeModal()
        this.loader = false;
      } catch (error) {
        this.toastr.error("Failed to transfer..")
        this.loader = false;
      }
    }
  }

  setCurrency(curr:Currency){
    this.currency = curr
  }

  get hasPartnerId(){
    if(this.lead&&this.lead.id&&this.lead.consultationInfo&&this.lead.consultationInfo.partnerId!=null){
      return true;
    }else{
      return false;
    }
  }

  get checkIfPlanBooking(){
    if(this.lead&&this.lead.consultationInfo&&(this.lead.consultationInfo.paymentSource=='PlanPurchaseWallet'||this.lead.consultationInfo.planPurchaseId)){
      return true;
    }else{
      return false;
    }
  }
}
