import { Expose } from "class-transformer";
import "reflect-metadata";
export class ContactNumber {
  @Expose() contactNumberId: string;

  @Expose() countryCode: string;

  @Expose() number: string;

  @Expose() ext: string;

  @Expose() isPrimaryContactNumber: boolean;

  @Expose() isPublicContactNumber: boolean;

  @Expose() isWhatsappContactNumber: boolean;

  @Expose() receiveConfirmations: boolean;

  @Expose() smsCommunicationOn: boolean;
  
  @Expose() whatsappCommunicationOn: boolean;

  @Expose() likelyToAnswer: string;

  fullNumber() {
    if (this.countryCode != null && this.number != null) {
      return "+" + this.countryCode + "-" + this.number;
    } else if (this.number != null) {
      return this.number;
    } else {
      return "";
    }
  }

  get displayMaskedNumber() {
    let str: string = "+"+this.countryCode+"-"+this.maskNumber(this.number,3);
    return str;
  }

  private maskNumber(number: string, visibleLastDigits: number) {
    let string = '';
    for (let i = 0; i < number.length; i++) {
      if (i <= 0 || i >= number.length - visibleLastDigits) {
        string += number[i];
      } else {
        string += 'x';
      }
    }
    return string;
  }
}
