import { Expose, Type } from "class-transformer";
import { AppointmentType, AppointmentPlatform, ServiceTypes, CalendarEventVersion } from "../Enums/Enums";
import * as dayjs from "dayjs";
import * as timezone from "dayjs/plugin/timezone";
import * as utc from "dayjs/plugin/utc";
import { TransactionSource } from "../Enums/pay";
import { ProviderCalendarEventInfo } from "./ProviderCalendarEventInfo";
import { PatientCalendarEventInfo } from "./PatientCalendarEventInfo";
import { ActionDoneBy } from "../Protocols/ActionDoneBy";
import { Currency } from "../Helpers/countrywiseFeeCalc";
import { BooleanAction } from "../Protocols/BooleanAction";
import { DiscountDetails } from "./DiscountDetails";
import { AmountWithCurrency } from "../Protocols/AmountWithCurrency";
import { AmountCurrencyForPaymentId } from "../Protocols/AmountCurrencyForPaymentId";
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault("Asia/Kolkata");

export class Appointment {
	@Expose() appointmentId: string;
	@Expose() doctorName: string;
	@Expose() type: AppointmentType;
	@Expose() sessionType: ServiceTypes;//optional - individual / couple / family

	@Expose() locationId: string;
	@Expose() locationName: string;
	@Expose() locationDescription: string; //city , locality or other short address details
	@Expose() locationGoogleMapsLink: string;

	@Expose() organisationId: string;
	@Expose() organisationName: string;
	@Expose() timeslot: string;
	@Expose() timezone: string; //This is the timezone which is preferred by DocVita.
	//Client and provider will have their own preferred timezone
	@Expose() purposeOfVisitTitle: string;
	@Expose() purposeOfVisitDescription: string;
	@Expose() purposeOfVisitShortForm: string;
	@Expose() purposeOfVisitId: string;
	@Expose() status: string;
	@Expose() noteFromPatient: string;
	@Expose() reasonForDecliningRequest: string;
	@Expose() reasonForAppointmentCancellation: string;
	@Expose() patientId: string;
	@Expose() patientName: string;
	@Expose() patientGender: string;
	@Expose() patientPrimaryContactNumber: string;
	@Expose() patientPrimaryEmail: string;
	@Expose() partnerId: string;
	@Expose() partnerWalletAmount: number;
	@Expose() getStartedSessionId: string;
	@Expose() date: string;
	@Expose() requestedAppointmentId: string;
	@Expose() doctorId: string;
	@Expose() billingId: string;
	@Expose() paymentId: string;
	@Expose() paymentSource: TransactionSource;
	@Expose() amount: number;
	@Expose() totalAmountPaid: number;
	@Expose() timeOfArrival: number;
	@Expose() queueNumber: number;
	@Expose() token: number;
	@Expose() startVisitTimestamp: number;
	@Expose() endVisitTimestamp: number;
	@Expose() uniqueNumber: string;

	@Type(()=> BooleanAction)
	@Expose() invoiceGenerated: BooleanAction;
	
	//Video Call Variables - not in use anymore
	@Expose() videoCallPatientConnectedOn: number;
	@Expose() videoCallPatientDisconnectedOn: number;
	@Expose() videoCallProviderConnectedOn: number;
	@Expose() videoCallProviderDisconnectedOn: number;


	//for partial wallet payment booking	
	@Expose() partialPaymentViaWallet:boolean;

	@Type(()=>AmountCurrencyForPaymentId)
	@Expose() partialPaymentViaWalletAmount: AmountCurrencyForPaymentId;

	@Type(()=>AmountCurrencyForPaymentId)
	@Expose() partialPaymentViaCheckoutTxnAmount: AmountCurrencyForPaymentId;

	// @Type(()=>AmountCurrencyForPaymentId)
	// @Expose() partialPaymentViaPartnerWalletAmount: AmountCurrencyForPaymentId;


	@Expose() expiresOn: number; // when the scheduled status appointment expires;

	@Expose() isFirstDocVitaAppointment: boolean;

	@Expose() conversionTracking: any; //Optional

	@Expose() isFeeWaivedOff: boolean; //Optional
	@Expose() feeWaivedOffByName: string; //Optional
	@Expose() feeWaivedOffOn: number; //Optional

	@Expose() modeOfAppointment: string;
	@Expose() category: string;
	@Expose() paymentDoneOn: number;
	@Expose() version: string;

	@Expose() acceptedAmount: number;
	@Expose() acceptedAmountCurrency: Currency;
	@Expose() excRate: number;

	@Expose() currency: Currency;

	@Expose() providerDisplayAmount: number;
	@Expose() providerDisplayCurrency: Currency;

	@Expose() providerAppointmentCount: number;
	@Expose() docvitaAppointmentCount: number;

	@Expose() calendarEventVersion: CalendarEventVersion;

	@Type(() => ProviderCalendarEventInfo)
	@Expose()
	providerCalendarEventInfo: ProviderCalendarEventInfo;

	@Type(() => PatientCalendarEventInfo)
	@Expose()
	patientCalendarEventInfo: PatientCalendarEventInfo;

	@Expose() platform: AppointmentPlatform;

	@Expose() followupDate: string; //2022-03-05
	@Expose() followupTime: string; //13:00

	@Expose() initiator: ActionDoneBy;
	@Expose() rescheduler: ActionDoneBy;
	
	@Type(() => ActionDoneBy)
	@Expose() expiredBy: ActionDoneBy;

	@Expose() markedNoShow: BooleanAction;
	@Expose() markedPendingChangeAppointment: BooleanAction;

	@Expose() startDate: string;
	@Expose() startTime: string;
	@Expose() endDate: string;
	@Expose() endTime: string;

	@Expose() planPurchaseId: string;

	@Type(()=> DiscountDetails)
	@Expose() discountDetails:DiscountDetails;

	get isFirstAppointmentWithCurrentProvider(){
		if(this.providerAppointmentCount == 1){
			return true
		}
		return false;
	}

	getStartTime(): string {
		if (this.timeslot) {
			return this.timeslot.substr(0, 5);
		} else {
			return "-";
		}
	}

	getEndTime(): string {
		if (this.timeslot) {
			return this.timeslot.substr(6, 11);
		} else {
			return "-";
		}
	}

	getShortDate(): string {
		return dayjs.tz(this.date).format("D MMM");
	}

	getStartTimeIn12HoursFormat(): string {
		try {
			return dayjs
				.tz(this.date + " " + this.getStartTime())
				.format("h:mma");
		} catch (error) {
			console.error(error, this.date, this.getStartTime());
		}
	}

	getFollowupTimeIn12HoursFormat(): string {
		if(this.followupTime) {
			return dayjs
			.tz(this.followupDate + " " + this.followupTime)
			.format("h:mma");
		} else {
			return "N/A";
		}
	}

	getFollowupDateShortFormat(): string {
		if(this.followupDate) {
			return dayjs.tz(this.followupDate).format("D MMM");
		} else {
			return "N/A";
		}
	}

	getDuration(): number {
		let startTime = this.timeslot.substring(0, 5);
		let endTime = this.timeslot.substring(6, 11);
		let diff: number = 0;
		if (endTime < startTime && endTime >= "00:00") {
			let sthrs = +startTime.substring(0, 2);
			let stmins = +startTime.substring(3, 5);
			let endhrs = +endTime.substring(0, 2);
			let endmins = +endTime.substring(3, 5);
			diff = (23 - sthrs) * 60 + (60 - stmins) + endhrs * 60 + endmins;
		} else {
			let sthrs = +startTime.substring(0, 2);
			let stmins = +startTime.substring(3, 5);
			let endhrs = +endTime.substring(0, 2);
			let endmins = +endTime.substring(3, 5);
			diff = (endhrs - sthrs) * 60 + (endmins - stmins);
		}
		return diff;
	}

	/**
	 * Returns today tomorrow or DD MMM
	 */
	getDisplayDay(): string {
		let today = dayjs.tz(new Date().getTime()).format("YYYY-MM-DD");
		let tomorrow = dayjs
			.tz(new Date().getTime() + 24 * 3600 * 1000)
			.format("YYYY-MM-DD");
		if (this.date != null) {
			switch (this.date) {
				case today: {
					return "today";
				}
				case tomorrow: {
					return "tomorrow";
				}
				default:
					return dayjs.tz(this.date).format("D MMM");
			}
		} else {
			return "-";
		}
	}

	ordinal_suffix_of(i: number) {
		let j = i % 10,
			k = i % 100;
		if (j == 1 && k != 11) {
			return i + "st";
		}
		if (j == 2 && k != 12) {
			return i + "nd";
		}
		if (j == 3 && k != 13) {
			return i + "rd";
		}
		return i + "th";
	}

	/**
	 * Returns today tomorrow or EEE, DD MMM YYYY
	 */
	getDisplayDayLong(): string {
		let today = dayjs.tz(new Date().getTime()).format("YYYY-MM-DD");
		let tomorrow = dayjs
			.tz(new Date().getTime() + 24 * 3600 * 1000)
			.format("YYYY-MM-DD");
		if (this.date != null) {
			switch (this.date) {
				case today: {
					return "today, " + dayjs.tz(this.date).format("ddd, DD MMM YYYY");
				}
				case tomorrow: {
					return "tomorrow, " + dayjs.tz(this.date).format("ddd, DD MMM YYYY");
				}
				default:
					return dayjs.tz(this.date).format("ddd, DD MMM YYYY");
			}
		} else {
			return "-";
		}
	}

	getDisplayTimeShort(): string {
		let today = dayjs.tz(new Date().getTime()).format("YYYY-MM-DD");
		let tomorrow = dayjs
			.tz(new Date().getTime() + 24 * 3600 * 1000)
			.format("YYYY-MM-DD");
		const startTime = this.timeslot?.split('-')[0]
		if (this.date != null&&startTime != null) {
			switch (this.date) {
				case today: {
					return "Today - " + dayjs.tz(this.date+' '+startTime).format("hh:mm a");
				}
				case tomorrow: {
					return "Tomorrow - " + dayjs.tz(this.date+' '+startTime).format("hh:mm a");
				}
				default:
					return dayjs.tz(this.date+' '+startTime).format("DD MMM - hh:mm a");
			}
		} else {
			return "-";
		}
	}

	getDDMMMYYYYBookingDate(): string {
		return  dayjs.tz(this.date).format("DD MMM YYYY");
	}

	getDisplayDayAppointmentConfirmation(): string {
		let today = dayjs.tz(new Date().getTime()).format("YYYY-MM-DD");
		let tomorrow = dayjs
			.tz(new Date().getTime() + 24 * 3600 * 1000)
			.format("YYYY-MM-DD");
		let actualDate = dayjs.tz(this.date).format("D MMM");
		if (this.date != null) {
			switch (this.date) {
				case today: {
					return "Today - " + actualDate;
				}
				case tomorrow: {
					return "Tomorrow - " + actualDate;
				}
				default:
					return dayjs.tz(this.date).format("D MMM");
			}
		} else {
			return "-";
		}
	}

	getProviderAppointmentCountText(): string {
		let count = this.providerAppointmentCount;
		if (count == null) {
			return "";
		}
		var suffix = "";
		switch (count) {
			case 1:
				suffix = "st";
				break;
			case 2:
				suffix = "nd";
				break;
			case 3:
				suffix = "rd";
				break;
			default:
				suffix = "th";
				break;
		}
		return count + suffix;
	}

	getDisplayDateTime(): string {
		return this.getStartTimeIn12HoursFormat() + " " + this.getDisplayDay();
	}

	isFeeWaivedOffForThisAppointment(): boolean {
		return this.isFeeWaivedOff != null && this.isFeeWaivedOff;
	}

	isPaymentDone(): boolean {
		if (this.isFeeWaivedOffForThisAppointment()) {
			return true;
		}
		return (
			this.totalAmountPaid != null &&
			this.totalAmountPaid > 0 &&
			this.totalAmountPaid == this.amount
		);
	}
}
