<div class="">
  <div class="d-flex mt-3 align-items-center">
    <h2>Linked Bank Account</h2>
    <button
      type="button"
      class="btn btn-sm btn-info ms-auto"
      (click)="openModal(linkbank)"
    >
      Link Bank
    </button>
    <button
      type="button"
      *ngIf="currentHeroInfo?.hasSpecialAccessWithTeam"
      class="btn btn-sm btn-info ms-auto"
      (click)="downloadReport()"
    >
      Download Report
    </button>
  </div>
  <!-- <div class="d-flex flex-column align-items-start card p-4 w-50 position-relative">
    <div style="position: absolute;right: 0.5rem;top:0.5rem;" class="d-flex align-items-center">
      <div style="font-size:13px;font-style: italic;" class="me-2" *ngIf="doctorProfile.bulkPayoutsOnly==undefined">
        * Details not saved
      </div>
      <button class="btn btn-outline-primary btn-sm"  [disabled]="saveLoader" (click)="updateProfilePayoutDetails()"> 
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="saveLoader"></span>
        save
      </button>
    </div>
    
    <div class="form-check form-switch">
      <input
        [(ngModel)]="bulkPayoutBool"
        type="checkbox"
        class="form-check-input"
        id="multi-spec-switch"
      />
      <label class="form-check-label ms-2" for="multi-spec-switch" style="font-size: 0.9em;">
        Bulk payouts only?
      </label>
    </div>
    <label  for="payout-comments" class="mt-2" style="font-size: 15px;">Payout Schedule: </label>
    <textarea id="payout-comments" rows="4" class="w-100 form-control" placeholder="Schedule comments" [(ngModel)]="payoutScheduleTextField" ></textarea>
  </div>
  <div>
    <div id="payout_config_dropdown" class="d-flex flex-column my-2" style="width: fit-content;" *ngIf="payoutPlanOptions">
      <label class="mb-1">Choose Payout Plan For India</label>
      <select
        class="form-control mb-1"
        [(ngModel)]="payoutPlanSelectionIndia"
      >
        <option *ngFor="let key of payoutPlanOptions"  [selected]="key == payoutPlanSelectionIndia" [value]="key">{{key}}</option>
      </select>

      <label class="mb-1">Choose Payout Plan For Overseas</label>
      <select
        class="form-control mb-1"
        [(ngModel)]="payoutPlanSelectionOverseas"
      >
        <option *ngFor="let key of payoutPlanOptions"  [selected]="key == payoutPlanSelectionOverseas" [value]="key">{{key}}</option>
      </select>
      <button class="btn btn-sm btn-outline-primary" (click)="savePayoutPlanSelection()">Save Payout Config</button>
    </div>
    <button class="btn btn-sm btn-outline-primary" (click)="providerPayoutAs50ForFirstSession()">Mark as 50 First Session</button>
  </div> -->
  <div class="card mt-3" *ngFor="let bank of linkedBankDetails">
    <div class="card-body">
      <div class="d-flex">
        <h5 class="card-title">{{ bank.bankName }}</h5>
        <button
          type="button "
          class="btn-danger ms-auto"
          (click)="removeAccount(bank.beneficiaryId)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-trash"
            viewBox="0 0 16 16"
          >
            <path
              d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"
            />
            <path
              fill-rule="evenodd"
              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
            />
          </svg>
        </button>
      </div>
      <div class="d-flex">
        <div class="col-2">Name</div>
        <div>:</div>
        <div class="col-9" style="font-weight: bold">{{ bank.name }}</div>
      </div>
      <div class="d-flex">
        <div class="col-2">Beneficiary Id</div>
        <div>:</div>
        <div class="col-9" style="font-weight: bold">{{ bank.beneficiaryId }}</div>
      </div>
      <div class="d-flex">
        <div class="col-2">Razorpay AccountId</div>
        <div>:</div>
        <div class="col-9" style="font-weight: bold">{{ bank.razorpayAccountId? bank.razorpayAccountId : "-" }}</div>
      </div>
      <div class="d-flex">
        <input type="text" [(ngModel)]="bank.razorpayAccountId" class="form-control me-2" />
        <button (click)="updateRazorpayAccountID(bank)" class="btn btn-sm btn-primary">Update Razorpay Account ID</button>
      </div>
      <div class="d-flex">
        <div class="col-2">Account Number</div>
        <div>:</div>
        <div class="col-9" style="font-weight: bold">
          {{ bank.bankAccount }}
        </div>
      </div>
      <div class="d-flex">
        <div class="col-2">IFSC</div>
        <div>:</div>
        <div class="col-9" style="font-weight: bold">
          {{ bank.ifsc }}
        </div>
      </div>
      <div class="d-flex">
        <div class="col-2">Vpa</div>
        <div>:</div>
        <div class="col-9" style="font-weight: bold">
          {{ bank.vpa }}
        </div>
      </div>
      <div class="d-flex">
        <div class="col-2">Email</div>
        <div>:</div>
        <div class="col-9" style="font-weight: bold">
          {{ bank.email }}
        </div>
      </div>
      <div class="d-flex">
        <div class="col-2">Phone</div>
        <div>:</div>
        <div class="col-9" style="font-weight: bold">
          {{ bank.phone }}
        </div>
      </div>
      <div class="d-flex">
        <div class="col-2">Primary Address</div>
        <div>:</div>
        <div class="col-9" style="font-weight: bold">
          {{ bank.address1 }}
        </div>
      </div>
      <div class="d-flex">
        <div class="col-2">Secondary Address</div>
        <div>:</div>
        <div class="col-9" style="font-weight: bold">
          {{ bank.address2 }}
        </div>
      </div>
      <div class="d-flex">
        <div class="col-2">City</div>
        <div>:</div>
        <div class="col-9" style="font-weight: bold">
          {{ bank.city }}
        </div>
      </div>
      <div class="d-flex">
        <div class="col-2">State</div>
        <div>:</div>
        <div class="col-9" style="font-weight: bold">
          {{ bank.state }}
        </div>
      </div>
      <div class="d-flex">
        <div class="col-2">Pincode</div>
        <div>:</div>
        <div class="col-9" style="font-weight: bold">
          {{ bank.pincode }}
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div
  class="modal fade"
  id="addModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="addModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="addModalLabel">Link Bank Account</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div
          class="d-flex flex-column p-3 mt-3"
          style="border: 1px solid #bbbbbb; border-radius: 8px"
        >
          <div class="form">
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="name" class="control-label"
                  >Name of Bank A/C Holder *</label
                >
                <input
                  type="text"
                  id="name"
                  class="form-control"
                  placeholder="Name of Bank Holder"
                  [(ngModel)]="beneficiary.name"
                  required
                />
              </div>
              <div class="form-group col-md-6">
                <label for="bankName" class="control-label"
                  >Name of Bank</label
                >
                <input
                  type="text"
                  id="bankName"
                  class="form-control"
                  placeholder="Name of Bank "
                  [(ngModel)]="beneficiary.bankName"
                  required
                />
              </div>
              <div class="form-group col-md-6">
                <label for="acNumber">Account Number</label>
                <input
                  type="string"
                  class="form-control"
                  id="acNumber"
                  placeholder="Account Number"
                  [(ngModel)]="beneficiary.bankAccount"
                  required
                />
              </div>
              <div class="form-group col-md-6">
                <label for="ifsc">IFSC Code</label>
                <input
                  type="text"
                  class="form-control"
                  id="ifsc"
                  placeholder="IFSC Code"
                  [(ngModel)]="beneficiary.ifsc"
                  required
                />
              </div>

              <div class="form-group col-md-6">
                <label for="contactNumber">Contact Number *</label>
                <input
                  type="number"
                  class="form-control"
                  id="contactNumber"
                  placeholder="Enter your Contact Number"
                  [(ngModel)]="beneficiary.phone"
                  required
                />
              </div>
              <div class="form-group col-md-6">
                <label for="email">Email *</label>
                <input
                  type="text"
                  class="form-control"
                  id="email"
                  placeholder="Enter your email"
                  [(ngModel)]="beneficiary.email"
                  required
                />
              </div>
              <div class="form-group col-md-6">
                <label for="address1">Address 1 *</label>
                <input
                  type="text"
                  class="form-control"
                  id="address1"
                  placeholder="Enter primary address"
                  [(ngModel)]="beneficiary.address1"
                  required
                />
              </div>
              <div class="form-group col-md-6">
                <label for="address2">Address 2 </label>
                <input
                  type="text"
                  class="form-control"
                  id="address2"
                  placeholder="Enter secondary address"
                  [(ngModel)]="beneficiary.address2"
                  required
                />
              </div>
              <div class="form-group col-md-6">
                <label for="vpa">UPI</label>
                <input
                  type="text"
                  class="form-control"
                  id="vpa"
                  placeholder="Enter your VPA"
                  [(ngModel)]="beneficiary.vpa"
                  required
                />
              </div>
              <div class="form-group col-md-6">
                <label for="city">City </label>
                <input
                  type="text"
                  class="form-control"
                  id="city"
                  placeholder="Enter your City"
                  [(ngModel)]="beneficiary.city"
                  required
                />
              </div>
              <div class="form-group col-md-6">
                <label for="state">State </label>
                <input
                  type="text"
                  class="form-control"
                  id="state"
                  placeholder="Enter your State"
                  [(ngModel)]="beneficiary.state"
                  required
                />
              </div>
              <div class="form-group col-md-6">
                <label for="pincode">Pincode</label>
                <input
                  type="text"
                  class="form-control"
                  id="pincode"
                  placeholder="Enter your pincode"
                  [(ngModel)]="beneficiary.pincode"
                  required
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="addBeneficiaryDetails()"
        >
          Save changes
        </button>
      </div>
    </div>
  </div>
</div> -->


<ng-template #linkbank let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Link Bank Account</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()" style="outline: none;">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div
          class="d-flex flex-column p-3 mt-3"
          style="border: 1px solid #bbbbbb; border-radius: 8px"
        >
          <div class="form">
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="name" class="control-label"
                  >Name of Bank A/C Holder *</label
                >
                <input
                  type="text"
                  id="name"
                  class="form-control"
                  placeholder="Name of Bank Holder"
                  [(ngModel)]="beneficiary.name"
                  required
                />
              </div>
              <div class="form-group col-md-6">
                <label for="bankName" class="control-label"
                  >Name of Bank</label
                >
                <input
                  type="text"
                  id="bankName"
                  class="form-control"
                  placeholder="Name of Bank "
                  [(ngModel)]="beneficiary.bankName"
                  required
                />
              </div>
              <div class="form-group col-md-6">
                <label for="acNumber">Account Number</label>
                <input
                  type="string"
                  class="form-control"
                  id="acNumber"
                  placeholder="Account Number"
                  [(ngModel)]="beneficiary.bankAccount"
                  required
                />
              </div>
              <div class="form-group col-md-6">
                <label for="ifsc">IFSC Code</label>
                <input
                  type="text"
                  class="form-control"
                  id="ifsc"
                  placeholder="IFSC Code"
                  [(ngModel)]="beneficiary.ifsc"
                  required
                />
              </div>

              <div class="form-group col-md-6">
                <label for="contactNumber">Contact Number *</label>
                <input
                  type="number"
                  class="form-control"
                  id="contactNumber"
                  placeholder="Enter your Contact Number"
                  [(ngModel)]="beneficiary.phone"
                  required
                />
              </div>
              <div class="form-group col-md-6">
                <label for="email">Email *</label>
                <input
                  type="text"
                  class="form-control"
                  id="email"
                  placeholder="Enter your email"
                  [(ngModel)]="beneficiary.email"
                  required
                />
              </div>
              <div class="form-group col-md-6">
                <label for="address1">Address 1 *</label>
                <input
                  type="text"
                  class="form-control"
                  id="address1"
                  placeholder="Enter primary address"
                  [(ngModel)]="beneficiary.address1"
                  required
                />
              </div>
              <!-- <div class="form-group col-md-6">
                <label for="address2">Address 2 </label>
                <input
                  type="text"
                  class="form-control"
                  id="address2"
                  placeholder="Enter secondary address"
                  [(ngModel)]="beneficiary.address2"
                  required
                />
              </div> -->
              <div class="form-group col-md-6">
                <label for="vpa">UPI</label>
                <input
                  type="text"
                  class="form-control"
                  id="vpa"
                  placeholder="Enter your VPA"
                  [(ngModel)]="beneficiary.vpa"
                  required
                />
              </div>
              <div class="form-group col-md-6">
                <label for="city">City </label>
                <input
                  type="text"
                  class="form-control"
                  id="city"
                  placeholder="Enter your City"
                  [(ngModel)]="beneficiary.city"
                  required
                />
              </div>
              <!-- <div class="form-group col-md-6">
                <label for="state">State </label>
                <input
                  type="text"
                  class="form-control"
                  id="state"
                  placeholder="Enter your State"
                  [(ngModel)]="beneficiary.state"
                  required
                />
              </div> -->
              <!-- <div class="form-group col-md-6">
                <label for="pincode">Pincode</label>
                <input
                  type="text"
                  class="form-control"
                  id="pincode"
                  placeholder="Enter your pincode"
                  [(ngModel)]="beneficiary.pincode"
                  required
                />
              </div> -->
            </div>
          </div>
        </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="addBeneficiaryDetails()">Save</button>
  </div>
</ng-template>
