<div class="w-100 d-flex flex-column">
    <div class="d-flex align-items-center">
        <textarea class="form-control w-100" placeholder="Add your comment here" (keyup.enter)="addNote()" [(ngModel)]="newNote"></textarea>
        <button style="min-width: fit-content;" class="btn btn-outline-primary ms-2" [disabled]="noteLoader" *ngIf="!selectedNoteId" (click)="addNote()">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="noteLoader"></span>
            Save Comment
        </button>
    </div>
    <div class="w-100 d-flex flex-column mt-3">
        <ng-container *ngIf="plainLeadComments.length > 0">
            <h6>Comments</h6>
            <div class="d-flex border rounded mb-2 p-2" *ngFor="let note of plainLeadComments">
                <div class="w-100 pb-3 position-relative me-1">
                    {{note.note}}
                    <div class="text-muted position-absolute" style="font-size: 12px;bottom:0px;right:5px;">
                        By <span class="text-primary">{{note.createdByName}}</span>, on <span class="text-primary">{{note.createdOn|date:'medium'}} </span> <b>{{note.updatedOn?"(edited)":""}}</b>
                    </div>
                </div>
                
                <div class="d-flex flex-column" style="row-gap: 0.25rem;">
                    <!-- <button class="p-0 p-1 btn btn-sm btn-outline-primary border-0" (click)="openNoteModal(note.value,note.key)">
                        <i class="feather icon-edit-2"></i>
                    </button> -->
                    <!-- <button class="p-0 p-1 btn btn-sm btn-outline-danger border-0" [disabled]="noteLoader" (click)="deleteNote(note.key)">
                        <i class="feather icon-trash"></i>
                    </button> -->
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="plainLeadComments.length == 0">
            <div style="text-align: center;" class="text-muted ms-auto me-auto my-3">
                No comments yet 💤. <br>Be the first one to enter the comment for this appointment
            </div>
        </ng-container>
    </div>
</div>

<ng-template #addNoteModal>
    <div class="modal-header">
      <h4 class="modal-title pull-left" *ngIf="!selectedNoteId">Add Note</h4>
      <h4 class="modal-title pull-left" *ngIf="selectedNoteId">Update Note</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="closeNoteModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <textarea class="form-control w-100" placeholder="New note" [(ngModel)]="newNote"></textarea>
    </div>
    <div class="modal-footer">
        <button class="btn btn-outline-secondary" (click)="closeNoteModal()">
            Close
        </button>
        <button class="btn btn-outline-primary ms-1" [disabled]="noteLoader" *ngIf="!selectedNoteId" (click)="addNote()">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="noteLoader"></span>
            Add Note
        </button>
        <button class="btn btn-outline-primary ms-1" [disabled]="noteLoader" *ngIf="selectedNoteId" (click)="updateNote()">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="noteLoader"></span>
            Update Note
        </button>
    </div>
</ng-template>

