<div style="position: relative" class="mt-2 fw-600">
    {{
      !doctorProfile || !doctorProfile.id
        ? "Select Provider:"
        : "Provider:"
    }}
  </div>
  <ng-container *ngIf="!doctorProfile || !doctorProfile.id">
    <app-doctor-search
        [providerId]="providerId"
      (getProvider)="setProvider($event)"
      #docSearchRef
    ></app-doctor-search>
  </ng-container>
  <div
    class="doctor-connection-tile border py-1 px-2 w-100"
    *ngIf="doctorProfile && doctorProfile.id"
  >
    <img
      [src]="doctorProfile.photoUrl"
      alt="doc-img"
      style="min-width: 40px; width: 40px; height: 40px"
      class="avatar avatar-circle avatar-image"
    />
    <div class="d-flex flex-column ms-3" style="font-size: 14px">
      <div>
        {{ doctorProfile.fullName() ? doctorProfile.fullName() : "-" }}
      </div>
      <div class="text-muted">
        {{
          doctorProfile.getMasterSpecializationDisplay()
            ? doctorProfile.getMasterSpecializationDisplay()
            : ""
        }}
      </div>
    </div>
    <div
      class="ms-auto pointer fw-600"
      style="font-size: 14px"
      *ngIf="!disableClearing"
      (click)="clearProviderSelection()"
    >
      &times;
    </div>
  </div>
  <ng-container
    *ngIf="
      patientProfile &&
      patientProfile.organisationConnections &&
      doctorProfile &&
      !doctorProfile.id
    "
  >
    <div
      class="mb-2"
      style="font-size: 14px"
      *ngIf="connectedProfiles && connectedProfiles.length > 0"
    >
      <div style="font-weight: 500" class="mt-2 text-muted">
        Doctor Connections:
      </div>
      <div class="connections-list-container-horizontal">
        <div
          [ngClass]="{
            'slot-selected-2': doctorProfile.id == doc.id
          }"
          *ngFor="let doc of connectedProfiles"
          class="border m-1 py-1 px-2 pointer doctor-connection-tile"
          (click)="setProvider(doc)"
        >
          <img
            [src]="doc.photoUrl"
            alt="doc-img"
            style="min-width: 35px; width: 35px; height: 35px"
            class="avatar avatar-circle avatar-image"
          />
          <div class="d-flex flex-column ms-2" style="font-size: 12px">
            <div>
              {{ doc.fullName() ? doc.fullName() : "-" }}
            </div>
            <div class="text-muted">
              {{
                doc.getMasterSpecializationDisplay()
                  ? doc.getMasterSpecializationDisplay()
                  : ""
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>