import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as urls from '../../../../environments/environment';
import * as env from '../../../../environments/environment';

import { User } from '../../models-ts/Actors/User/User';
import { ContactNumber } from '../../models-ts/Actors/Person/ContactNumber';
import { BehaviorSubject } from 'rxjs';
import { OrganisationConnection } from '../../models-ts/Relationships/OrganisationConnection';
import { DoctorsService } from '../doctors/doctors.service';
import { DoctorListingProfile } from '../../models-ts/Actors/Doctor/DoctorListingProfile';
@Injectable({
  providedIn: 'root',
})
export class PatientService {
  cachedPatientMap: BehaviorSubject<{}>;
  constructor(
    private _http: HttpClient,
    private _doctorService: DoctorsService
  ) {
    this.cachedPatientMap = new BehaviorSubject(null);
  }

  //patient data service funtions
  updatePatientCacheMap(patient: User) {
    this.cachedPatientMap[patient.docvitaId] = patient;
    this.cachedPatientMap.next(this.cachedPatientMap);
  }

  getPatientFromCacheMap(id): User {
    return this.cachedPatientMap[id];
  }

  getPatientsList() {
    return firebase.firestore().collection('docvita_patient_profiles');
  }

  async searchPatientByKeyword(keyword: string, connectedProviderId?: string) {
    try {
      //Sanitize keyword
      keyword = keyword.split("+").join("");
      let url = env.listingUrl + '/v1/crm-search/patient/search-patient-by-keyword' + '?keyword=' + keyword;;
      if (connectedProviderId) {
        url += '&connectedProviderId=' + connectedProviderId
      }
      const data = await this._http.get(url).toPromise()
      if (data && data['statusCode'] == 200 && data['body'] && data['body']['profiles']) {
        return data['body']['profiles'];
      } else {
        return null;
      }
    } catch (error) {
      console.log(error)
      return null;
    }
  }

  async getPatients(limit?: number) {
    try {
      let url = env.listingUrl + '/v1/crm-search/patient/get-patients-list';
      if (limit) {
        url += "?limit=" + limit;
      }
      const data = await this._http.get(url).toPromise()
      if (data && data['statusCode'] == 200 && data['body'] && data['body']['profiles']) {
        return data['body']['profiles']
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  createPatientProfile(formData) {
    if (formData) {
      formData['dateOfBirth'] = '';
      return new Promise<{}>((resolve, reject) => {
        let val = this._http
          .post(urls.environment['create-patient-profile'], formData)
          .subscribe(
            (res) => {
              if (val != null) {
                resolve(res);
              }
            },
            (err) => {
              console.log(err);
              reject(err);
            }
          );
      });
    }
  }

  getPatientById(id) {
    return firebase
      .firestore()
      .collection('docvita_patient_profiles')
      .doc(id)
      .get();
  }

  checkIfNumberExists(number) {
    return firebase
      .firestore()
      .collection('docvita_patient_profiles')
      .where('primaryContactNumber.number', '==', number)
      .get();
  }

  updatePatientProfile(formData) {
    return new Promise<{}>((resolve, reject) => {
      if (formData['docvitaId']) {
        let body = { patientId: formData['docvitaId'], jsonVal: formData };
        let val = this._http
          .post(urls.environment['update-patient-profile-no-merge'], body)
          .subscribe(
            (res) => {
              if (val != null) {
                resolve(res);
              }
            },
            (err) => {
              console.log(err);
              reject(err);
            }
          );
      } else {
        reject();
      }
    });
  }

  internationalClient(docvitaId: string, providerId: string, bool: boolean) {
    return new Promise<{}>((resolve, reject) => {
      if (docvitaId != null && bool != null) {
        let body = { docvitaId: docvitaId, providerId: providerId, isInternationalClient: bool };
        let val = this._http
          .post(urls.environment['mark-as-international-client'], body)
          .subscribe(
            (res) => {
              if (val != null) {
                resolve(res);
              }
            },
            (err) => {
              console.log(err);
              reject(err);
            }
          );
      } else {
        reject();
      }
    });
  }

  async getOrganizaitonNameForQuickContact(client?: User) {
    let quickConnections: OrganisationConnection[] = [];
    let profiles: DoctorListingProfile[] = [];
    let profilesMap = new Map<string, DoctorListingProfile>();
    let map = client?.organisationConnections;
    let promises: Promise<DoctorListingProfile>[] = [];
    if (map) {
      map.forEach((val, key) => {
       promises.push(this._doctorService.getProviderProfile(key));
      });

      const resp = await Promise.all(promises);
      resp?.forEach((profile)=>{
        const key = profile.id;
        profilesMap.set(key, profile);
        client.organisationConnections.get(
          key
        ).organisationName = profile.fullName();
        client.organisationConnections.get(
          key
        ).organisationId = key;
      });

      client.organisationConnections.forEach((val) => {
        if (val) {
          quickConnections.push(val);
        }
      });
      
      quickConnections.sort((a, b) => {
        if(a?.lastInteractedOn || b?.lastInteractedOn) {
          return b.lastInteractedOn - a.lastInteractedOn;
        } else if(a?.organisationId || b?.organisationId) {
          return a.organisationId.localeCompare(b.organisationId)
        } else {
          return 0;
        }
      });

      quickConnections.forEach((c)=>{
        profiles.push(
          profilesMap.get(c.organisationId)
        );
      });
    }
    return {
      quickConnections: quickConnections,
      profiles: profiles
    };
  }

  // createPatientProfile(
  //   name: string,
  //   gender: string,
  //   countryCode: string,
  //   mobileNumber: string,
  //   organisationId: string,
  //   organisationName: string,
  //   doctorId: string,
  //   doctorName: string
  // ): Promise<{}> {
  //   return new Promise<{}>((resolve, reject) => {
  //     let body = {};
  //     let patient = new User();
  //     patient.name = name;
  //     patient.gender = gender;
  //     patient.primaryContactNumber = new ContactNumber();
  //     patient.primaryContactNumber.countryCode = countryCode;
  //     patient.primaryContactNumber.number = mobileNumber;
  //     body['patientProfileBody'] = patient;
  //     body['connectedById'] = doctorId;
  //     body['connectedByName'] = doctorName;
  //     body['organisationId'] = organisationId;
  //     body['organisationName'] = organisationName;
  //     // console.log(body);
  //     let val = this._http
  //       .post(urls.environment['patient-profile'], body)
  //       .subscribe(
  //         (res) => {
  //           if (val != null) {
  //             resolve(res);
  //           }
  //         },
  //         (err) => {
  //           console.log(err);
  //           reject(err);
  //         }
  //       );
  //   });
  // }
}
