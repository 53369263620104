import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as env from '../../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  constructor(private http: HttpClient) {}

  sendOTP(mobileNumber: string, countryCode: string) {
    return new Promise((resolve, reject) => {
      let json = {
        primaryContactNumber: {
          number: mobileNumber,
          countryCode: countryCode,
        },
      };
      let url = env.environment['send-otp'];
      this.http.post(url, json).subscribe(
        (res: Response) => {
          resolve(res);
        },
        (err) => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  resendOTP(mobileNumber: string, countryCode: string) {
    return new Promise((resolve, reject) => {
      let json = {
        primaryContactNumber: {
          number: mobileNumber,
          countryCode: countryCode,
        },
      };
      let url = env.environment['resend-otp'];
      this.http.post(url, json).subscribe(
        (res: Response) => {
          resolve(res);
        },
        (err) => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  getToken(id: string) {
    return new Promise((resolve, reject) => {
      let json = {
        id: id,
      };
      let url = env.environment['get-token'];
      this.http.post(url, json).subscribe(
        (res: Response) => {
          resolve(res);
        },
        (err) => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  verifyOTP(mobileNumber: string, countryCode: string, otp: number) {
    return new Promise((resolve, reject) => {
      let json = {
        mobileNumber: mobileNumber,
        countryCode: countryCode,
        otp: otp,
      };
      let url = env.environment['verify-otp'];
      this.http.post(url, json).subscribe(
        (res: Response) => {
          resolve(res);
        },
        (err) => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  verifyGAuth(email: string) {
    return new Promise((resolve, reject) => {
      let json = {
        email,
      };
      let url = env.environment['verify-gauth'];
      this.http
        .post(url, json)
        .toPromise()
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  recheckAuth() {
    return new Promise((resolve, reject) => {
      let url = env.environment['reauth-check'];
      this.http
        .post(url,{})
        .toPromise()
        .then((resp: any) => {
          if(resp && resp.statusCode == 200 && resp.body && resp.body.success) {
            resolve(resp);
          } else {
            reject();
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }


  createAuthAcc(formData: any) {
    return new Promise((resolve, reject) => {
      let json = JSON.parse(JSON.stringify(formData));
      let url = env.environment['create-auth-acc'];
      this.http
        .post(url, json)
        .toPromise()
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
