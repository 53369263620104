<div class="d-flex flex-column align-items-start">
  <h6>Add Date Overrides</h6>
  <p class="text-muted">
    Add dates when provider's availability changes from their weekly hours
  </p>
  <button class="btn btn-outline-primary w-100" type="button" (click)="openModal()">
    Add a date override
  </button>
</div>

<!-- The Modal View -->
<ng-template #scheduleOverrideModal>
  <!-- Modal Header -->
  <div class="modal-header border-0">
    <h5 class="modal-title text-center w-100">
      Select the date(s) you want to assign specific hours
    </h5>
  </div>

  <!-- Calendar -->

  <div class="modal-body d-flex flex-column w-100 p-0 px-2">
    <div class="w-100 d-flex justify-content-center">
      <bs-datepicker-inline (bsValueChange)="dateValue($event)" [bsValue]="bsInlineValue" [bsConfig]="bsConfig"></bs-datepicker-inline>
    </div>

    <!-- Available div -->
    <div class="d-flex flex-column w-100 pt-3 bg-light">
      <div class="d-flex py-2 px-2 light justify-content-center align-items-center">
        <h6 class="text-center pb-0 mb-0">New slots to override</h6>
        <button class="btn btn-sm btn-outline-primary align-items-center justify-content-center ms-3 p-0 px-1" (click)="addSlot()">
          <i class="feather icon-plus"></i>
        </button>
      </div>

      <div class="d-flex justify-content-center pb-2 bg-light">
        <div class="d-flex flex-column">
          <div
            class="d-flex align-items-center mx-1 px-3 py-1"
            *ngFor="let slot of overrideSlots;let i=index;"
          >
            <input
              type="time"
              class="form-control me-2"
              style="width: 145px"
              [(ngModel)]="slot['startTime']"
            />
            <input
              type="time"
              class="form-control me-2"
              style="width: 145px"
              [(ngModel)]="slot['endTime']"
            />

            <button type="button" class="btn btn-outline-danger border-0 btn-sm px-1" (click)="deleteSlot(i,slot['id'])">
              <i class="feather icon-trash-2"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="d-flex modal-footer justify-content-center" style="background-color: white;">
        <button class="btn btn-outline-danger" (click)="modalRef.hide()">
          Cancel
        </button>
        <button class="btn btn-outline-primary" (click)="addOverrideSlot()" [disabled]="loader">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loader"></span>
          Apply
        </button>
      </div>
    </div>
  </div>

  <!-- </div> -->
</ng-template>