<div class="w-100 mb-5 p-2">
    <div class="w-100 d-flex mb-4">
      <h2 class="mt-3">Module Config</h2>
    </div>
  
    <div class="w-100 d-flex align-items-center border p-2">
        <div class="w-75">
            <b>Chat module</b> - <span class="text-muted">Enable chat module for client app and docvita one</span>
        </div>
        <div class="ms-auto">
            <button class="btn btn-primary btn-sm" (click)="enableChatModule(true)" *ngIf="!profileData?.hasChatModuleAccess?.bool&&!updatingChatModule">
                Enable
            </button>
            <button class="btn btn-danger btn-sm" (click)="enableChatModule(false)" *ngIf="profileData?.hasChatModuleAccess?.bool&&!updatingChatModule">
                Disable
            </button>
            <div class="spinner-border spinner-border-sm" role="status" *ngIf="updatingChatModule">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>
</div>