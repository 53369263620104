import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DoctorListingProfile } from '../../models-ts/Actors/Doctor/DoctorListingProfile';
import { User } from '../../models-ts/Actors/User/User';
import { LeadFollowUp } from '../../models-ts/Entities/LeadFollowUp';
import firebase from 'firebase/app';
import 'firebase/database';
import { FollowupService } from 'src/app/patient-follow-up/services/followup.service';
import { classToPlain, plainToClass } from 'class-transformer';
import { DoctorSearchComponent } from '../doctor-search/doctor-search.component';
import { PatientSearchComponent } from '../patient-search/patient-search.component';
@Component({
  selector: 'app-create-followup',
  templateUrl: './create-followup.component.html',
  styleUrls: ['./create-followup.component.css'],
})
export class CreateFollowupComponent implements OnInit {
  @Input() showButton: boolean = true;
  createFollowUpLoader: boolean = false;
  patientProfile: User = new User();
  doctorProfile: DoctorListingProfile = new DoctorListingProfile();
  date: string;
  time: string;
  comment: string;
  @Input() leadId: string;

  @Input()
  set providerInput(data: DoctorListingProfile) {
    if (data && data.id) {
      this.doctorProfile = plainToClass(
        DoctorListingProfile,
        classToPlain(data)
      );
    }
  }

  @Input()
  set patientInput(data: User) {
    if (data && data.docvitaId) {
      this.patientProfile = plainToClass(User, classToPlain(data));
    }
  }
  @Input() patientId: string;
  @Input() doctorId: string;

  // @ViewChild(DoctorSearchComponent) private doctorSearch: DoctorSearchComponent;
  // @ViewChild(PatientSearchComponent) private patientSearch: PatientSearchComponent;
  constructor(
    private modalService: BsModalService,
    private _followUpService: FollowupService
  ) {}

  ngOnInit(): void {
    this.date = null;
    this.time = null;
  }
  setPatientFromSearch(event) {
    this.patientProfile = new User();
    if (event && event['docvitaId']) {
      this.patientProfile = event;
      // console.log(this.patientProfile)
    }
  }
  setProviderFromSearch(event) {
    this.doctorProfile = new DoctorListingProfile();
    if (event && event['id']) {
      this.doctorProfile = event;
      // console.log(this.doctorProfile)
    }
  }
  resetProvider() {
    this.doctorProfile = new DoctorListingProfile();
  }
  resetPatient() {
    this.patientProfile = new User();
  }
  @ViewChild('followUp') followUpModal;
  openModal(size?) {
    this.date = null;
    this.time = null;
    // if(this.doctorId!=undefined){
    //   this.doctorSearch.searchDoctor(this.doctorId);
    // }
    // if(this.patientId!=undefined){
    //   this.patientSearch.searchPatient(this.patientId);
    // }
    this.modalService.show(this.followUpModal);
  }
  createFollowUp() {
    if (
      !(
        this.doctorProfile &&
        this.doctorProfile.id.length > 0 &&
        this.patientProfile &&
        this.patientProfile.docvitaId
      )
    ) {
      return;
    }
    if (this.date && this.date.length > 9) {
      let data = new LeadFollowUp();
      data.createdOn = new Date().getTime();
      data.id = firebase.database().ref().push().key;
      if (this.time && this.time.length > 3) {
        data.followUpDate = new Date(this.date + ' ' + this.time).getTime();
      } else {
        data.followUpDate = new Date(this.date + ' 00:01').getTime();
      }
      data.comments = this.comment;
      data.status = 'CREATED';
      if (this.leadId != undefined) {
        data.linkedLeadId = this.leadId;
      }
      data.metaData = {
        doctorName: this.doctorProfile.fullName(),
        doctorId: this.doctorProfile.id,
        patientName: this.patientProfile.name,
        patientId: this.patientProfile.docvitaId,
      };
      if (this.patientProfile.getPrimaryContactNumbers().length > 0) {
        let number = this.patientProfile.getPrimaryContactNumbers()[0];
        data.metaData['patientContact'] = number.countryCode + number.number;
      } else if (
        this.patientProfile.primaryContactNumber &&
        this.patientProfile.primaryContactNumber.number
      ) {
        let number = this.patientProfile.primaryContactNumber;
        data.metaData['patientContact'] = number.countryCode + number.number;
      }
      this.createFollowUpLoader = true;
      let jsonData = JSON.parse(JSON.stringify(classToPlain(data)));
      this._followUpService
        .updateFollowUp(jsonData)
        .then(() => {
          this.createFollowUpLoader = false;
          this.modalService.hide();
        })
        .catch((err) => {
          console.log(err);
          this.createFollowUpLoader = true;
        });
    }
  }
  closeModal() {
    this.modalService.hide();
  }
}
