import { Expose } from 'class-transformer';
import { PaymentPurpose } from '../../Enums/pay';
export class PurposeItem {
    @Expose() type: PaymentPurpose;
    @Expose() id: string;

    public getDisplayPurpose(): string{
        switch (this.type) {
            case PaymentPurpose.Consultation:
              return "Consultation";
            case PaymentPurpose.LabTest:
              return "Lab Test";
            case PaymentPurpose.Medicines:
              return "Medicines";
            case PaymentPurpose.Treatment:
              return "Treatment";
            default:
              return "Other";
          }
    }
}