import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { environment } from 'src/environments/environment';

@Injectable()
export class ClientWalletService {

  constructor(private _http: HttpClient) { }

  getClientBalance(clientId: string) {
    return new Promise<number>((resolve, reject)=> {
      let url = environment["get-client-wallet-balance"];
      url += "?clientId="+clientId;
      this._http.get(url).toPromise()
      .then((res) => {
        if (
          res &&
          res['statusCode'] == 200 &&
          res['body'] &&
          res['body']['success'] == true &&
          res['body']['balance']
        ) {
          resolve(+res['body']['balance']);
        } else {
          reject(null);
        }
      })
      .catch((err) => {
        console.log(err);
        reject(null);
      });
    })
  }
  getClientWallet(clientId: string) {
    return new Promise<{}>((resolve, reject)=> {
      let url = environment["get-client-wallet"];
      url += "?clientId="+clientId;
      this._http.get(url).toPromise()
      .then((res) => {
        if (
          res &&
          res['statusCode'] == 200 &&
          res['body'] &&
          res['body']['success'] == true &&
          res['body']['wallet']
        ) {
          resolve(res['body']['wallet']);
        } else {
          reject(null);
        }
      })
      .catch((err) => {
        console.log(err);
        reject(null);
      });
    })
  }

  getPartnerWallet(partnerId: string) {
    return new Promise<{}>((resolve, reject)=> {
      let url = environment["get-partner-wallet"];
      url += "?partnerId="+partnerId;
      this._http.get(url).toPromise()
      .then((res) => {
        if (
          res &&
          res['statusCode'] == 200 &&
          res['body'] &&
          res['body']['success'] == true &&
          res['body']['wallet']
        ) {
          resolve(res['body']['wallet']);
        } else {
          reject(null);
        }
      })
      .catch((err) => {
        console.log(err);
        reject(null);
      });
    })
  }
}
