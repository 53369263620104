import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { plainToClass } from 'class-transformer';
import { DoctorListingProfile } from '../../models-ts/Actors/Doctor/DoctorListingProfile';
import { User } from '../../models-ts/Actors/User/User';
import { ReschedulingRequest } from '../../models-ts/Entities/ReschedulingRequest';
import {
  ReschedulingRequestType,
  ReschedulingRequestStatus,
} from '../../models-ts/Enums/Enums';
import { RescheduleRequestService } from '../../services/reschedule-request/reschedule-request.service';

@Component({
  selector: 'view-reschedule-requests',
  templateUrl: './view-reschedule-requests.component.html',
  styleUrls: ['./view-reschedule-requests.component.css'],
})
export class ViewRescheduleRequestsComponent implements OnInit {
  @ViewChild('viewRescheduleRequests') viewRescheduleRequests;
  @Input() showButton: boolean = true;
  @Input() patientId: string;
  @Input() doctorId: string;
  @Input() requestType: ReschedulingRequestType;
  @Input() status: ReschedulingRequestStatus;
  reschedulingTypes = Object.keys(ReschedulingRequestType);
  reschedulingStatuses = Object.keys(ReschedulingRequestStatus);
  patientProfile: User = new User();
  doctorProfile: DoctorListingProfile = new DoctorListingProfile();
  rescheduleRequestList: ReschedulingRequest[] = [];
  isLoadingList: boolean = false;
  constructor(
    private modalService: BsModalService,
    private _rRService: RescheduleRequestService
  ) {}

  ngOnInit(): void {}
  openModal() {
    this.modalService.show(this.viewRescheduleRequests, { class: 'modal-xl' });
  }

  setPatientFromSearch(event) {
    this.patientProfile = new User();
    if (event && event['docvitaId']) {
      this.patientProfile = event;
    } else {
      this.patientProfile = new User();
    }
    this.getRescheduleRequestList();
  }
  setProviderFromSearch(event) {
    this.doctorProfile = new DoctorListingProfile();
    if (event && event['id']) {
      this.doctorProfile = event;
    } else {
      this.doctorProfile = new DoctorListingProfile();
    }
    this.getRescheduleRequestList();
  }
  getRescheduleRequestList() {
    this.rescheduleRequestList = [];
    if (
      this.doctorProfile &&
      this.doctorProfile.id &&
      this.patientProfile &&
      this.patientProfile.docvitaId
    ) {
      this.isLoadingList = true;
      this._rRService
        .getRescheduleRequests(
          this.patientProfile.docvitaId,
          this.doctorProfile.id,
          null
        )
        .get()
        .then((snap) => {
          if (!snap.empty) {
            let list: ReschedulingRequest[] = [];
            snap.forEach((doc) => {
              let data = plainToClass(ReschedulingRequest, doc.data());
              list.push(data);
            });
            this.isLoadingList = false;
            this.rescheduleRequestList = list;
          } else {
            this.rescheduleRequestList = [];
            this.isLoadingList = false;
          }
        })
        .catch((err) => {
          this.isLoadingList = false;
          this.rescheduleRequestList = [];
        });
    } else {
      this.isLoadingList = false;
      this.rescheduleRequestList = [];
    }
  }

  get filteredRequests() {
    let list: ReschedulingRequest[] = [...this.rescheduleRequestList];
    if (this.requestType) {
      list = list.filter((da) => da && da.type == this.requestType);
    }
    if (this.status) {
      list = list.filter((da) => da && da.status == this.status);
    }
    return list;
  }

  _toReadable(type) {
    return type
      .toString()
      .split('_')
      .map((da) => da[0].toUpperCase() + da.slice(1, da.length))
      .join(' ');
  }
  closeModal() {
    this.modalService.hide();
  }
}
