<button *ngIf="showButton" class="btn btn-sm btn-outline-primary border-0 py-1" (click)="openModal()">
  Reschedule
</button>
<!-- 
<a
  class="dropdown-item"
  *ngIf="showButton"
  (click)="openModal()"
  style="padding: 10px 15px"
>
  Reschedule
</a> -->

<ng-template #resappointment let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Reschedule Appointment</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()" style="outline: none">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body d-flex flex-column justify-content-center w-100">
    <ng-container *ngIf="!gotoApptStep">
      <div class="d-flex justify-content-between">
        <div class="d-flex flex-column" style="width: 40%">
          <provider-available-slots [doctorProfile]="doctorProfile" [date]="selectedDate"
            (selectedSlot)="selectSlot($event)"></provider-available-slots>
          <provider-appointments [doctorProfile]="doctorProfile" [date]="selectedDate">
          </provider-appointments>
        </div>
        <div class="d-flex flex-column card ms-3 mt-1 p-3 rounded" style="width: 60%">
          <div class="card mb-0 rounded mt-1 p-2">
            <h5>Appointment Details:</h5>
            <hr class="m-1 p-0" />
            <div>
              <span style="font-weight: 500">Patient Name:</span>
              {{ leadDetails.patientName ? leadDetails.patientName : "-" }}
            </div>
            <div>
              <span style="font-weight: 500" class="pii-text">Patient Contact:</span>
              {{
              leadDetails.patientContactInfo
              ? "+" +
              leadDetails.patientContactInfo.countryCode +
              "-" +
              leadDetails.patientContactInfo.number
              : "-"
              }}
            </div>
            <div>
              <span style="font-weight: 500">Date & Time:</span>
              {{ displayCurrentApptDateTime }}
            </div>
            <div>
              <span style="font-weight: 500">Category:</span>
              {{ leadDetails.leadCategory ? leadDetails.leadCategory : "-" }}
            </div>
            <!-- <div><span style="font-weight: 500;">City:</span> {{leadDetails.city}}</div> -->
          </div>
          <h6 class="mt-3">Doctor:</h6>
          <ng-container *ngIf="!doctorProfile || !doctorProfile.name">
            <app-doctor-search [providerId]="leadDetails.docvitaId" (getProvider)="setProvider($event)">
            </app-doctor-search>
          </ng-container>
          <div
            class="doctor-connection-tile border py-1 px-2 w-100"
            *ngIf="doctorProfile && doctorProfile.id"
          >
            <img
              [src]="doctorProfile.photoUrl"
              alt="doc-img"
              style="min-width: 40px; width: 40px; height: 40px"
              class="avatar avatar-circle avatar-image"
            />
            <div class="d-flex flex-column ms-3" style="font-size: 14px">
              <div>
                {{ doctorProfile.fullName() ? doctorProfile.fullName() : "-" }}
              </div>
              <div class="text-muted">
                {{
                  doctorProfile.getMasterSpecializationDisplay()
                    ? doctorProfile.getMasterSpecializationDisplay()
                    : ""
                }}
              </div>
            </div>
          </div>
          <div *ngIf="
          doctorProfile &&
          doctorProfile.id &&
          doctorProfile.onlineConsultationConfig
        " class="standard-details-container">
            <span *ngIf="doctorProfile.onlineConsultationConfig.fee"><b>Std. fees:</b> {{ standardNatInNatPricing
              }}</span>
            <span *ngIf="doctorProfile.appointmentDuration"><b>Std. duration:</b> {{ doctorProfile.appointmentDuration
              }}
              mins</span>
          </div>
          <h6 class="mt-3">Date:</h6>
          <input type="date" class="form-control" style="width: 180px" [(ngModel)]="selectedDate" />
          <div class="d-flex mt-3">
            <div>
              <h6>Time:</h6>
              <input type="time" class="form-control" style="width: 180px" [(ngModel)]="selectedTime" />
            </div>
            <div class="ms-2">
              <h6>Duration:(Min)</h6>
              <input type="number" class="form-control" style="width: 180px" [(ngModel)]="selectedSlotLength" />
              <div class="d-flex mt-1" style="font-size: 14px">
                <span class="me-2 p-1 card mb-0 slot" *ngFor="let min of [15, 30, 45]" (click)="setSlotLength(min)">{{
                  min }} min</span>
              </div>
            </div>
          </div>
          <div class="my-3">
            <h6>Rescheduling Type (required):</h6>
            <ng-select class="w-100" placeholder="Select type" [(ngModel)]="rescheduleRequestType">
              <ng-option [value]="item.value" *ngFor="let item of reschedulingTypes">{{item.title}}</ng-option>
           </ng-select>
            <!-- <select class="form-control" [(ngModel)]="rescheduleRequestType">
              <option *ngFor="let item of reschedulingTypes" [value]="item.value">{{ item.title }}</option>
            </select> -->
            <span *ngIf="rescheduleRequestType == null">Please select the rescheduling request type</span>
          </div>
          <div class="ms-2 form-check form-switch">
            <input type="checkbox" class="form-check-input" id="sendwaconfirmations"
              [(ngModel)]="sendWhatsAppConfirmations" />
            <label class="form-check-label ms-2 text-muted" for="sendwaconfirmations">
              Send WhatsApp Confirmations on rescheduling
            </label>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="gotoApptStep">
      <div class="d-flex flex-column align-items-center w-100 p-2" style="min-height: 16rem">
        <div style="font-size: 1.5em">
          ✅ Appointment rescheduled successfully!
        </div>

        <!-- <div class="mt-3 text-muted" style="font-size: 1.2em;">
                        What would you like to do next?
                    </div> -->
        <div class="w-100 d-flex justify-content-center mt-2" *ngIf="isLoadingNewLeads">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div *ngIf="!isLoadingNewLeads" class="text-muted mt-3">
          <span>Rescheduled {{ patientProfile.getFirstName() + "'s" }} appointment
            with {{ doctorProfile.getFirstNameWithSalutation() }} for
            {{ getAppointmentDateTime() }}</span>
        </div>
        <app-next-steps-master class="mt-2" *ngIf="!isLoadingNewLeads" [showProfileDetails]="false" [lead]="leadDetails"
          [currentHeroInfo]="currentHeroInfo" [provider]="doctorProfile" [patient]="patientProfile"
          [sections]="[afterReschedulingSection]"></app-next-steps-master>
        <div class="mt-4 d-flex justify-content-center w-100">
          <button class="btn btn-outline-primary me-2" *ngIf="showGotoButton" (click)="gotoRescheduledAppointment()">
            Goto Appointment 👉🏻
          </button>
          <button class="btn btn-outline-dark" (click)="closeModal()">
            Close
          </button>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="modal-footer" *ngIf="!gotoApptStep">
    <button type="button" class="btn btn-outline-primary" [disabled]="updateLoader" (click)="rescheduleAppointment()">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="updateLoader"></span>
      Reschedule
    </button>
    <button (click)="closeModal()" *ngIf="!updateLoader" class="btn btn-outline-secondary ms-3">
      Close
    </button>
  </div>
</ng-template>

<clashing-appointments-modal #apptClashModal></clashing-appointments-modal>