import { Expose } from "class-transformer";

export class ClientOwnership {
  @Expose()
  organisationId: string;
  @Expose()
  source: string;
  @Expose() 
  createdOn: number;
  @Expose() 
  updatedOn: number;
  @Expose()
  owner: string;//'docvita' or organisationId

  isDocVitaClient() {
    return this.owner == "docvita";
  }
}
