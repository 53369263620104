import { Expose, plainToClass, Transform } from "class-transformer";
import { OnlineConsultationPlatform, ServiceTypes } from "../Enums/Enums";
import { Currency } from "../Helpers/countrywiseFeeCalc";
import { ConsultationPlatformConfig } from "./ConsultationPlatformConfig";

export class ServiceFeeConfig {

  @Expose()
  type: ServiceTypes;
  
  @Expose()
  fee: number;

  @Expose()
  currency: Currency;

  @Expose()
  appointmentDuration: number;//In minutes

  @Expose()
  isActive: boolean;//if this is false that means they are not doing this type of service

}

export class OnlineConsultationSettings {
  @Expose()
  fee: number;

  @Expose()
  appointmentDuration: number;//In minutes

  @Expose()
  acceptAppointmentDiff: number;//In minutes

  @Expose()
  availableOnWhatsappVideo: boolean;

  @Expose()
  includeInPersonVisitAt: string[];

  @Expose() autoConfirmBookings:boolean;

  @Expose()
  @Transform(
    (value) => {
      if (value) {
        let map = new Map<ServiceTypes, ServiceFeeConfig>();
        for (let entry of Object.entries(value)){
          map.set(entry[0] as ServiceTypes, plainToClass(ServiceFeeConfig, entry[1]));
        }
        return map;
      } else {
        return new Map<ServiceTypes, ServiceFeeConfig>();
      }
    },
    { toClassOnly: true }
  )
  servicewiseFeesMap: Map<ServiceTypes,ServiceFeeConfig>

  @Expose() onlineConsultationPlatforms: OnlineConsultationPlatform[];

  @Expose()
  @Transform(
    (value) => {
      if (value) {
        let map = new Map<string, ConsultationPlatformConfig>();
        for (let entry of Object.entries(value))
          map.set(entry[0], plainToClass(ConsultationPlatformConfig, entry[1]));
        return map;
      } else {
        return new Map<string, ConsultationPlatformConfig>();
      }
    },
    { toClassOnly: true }
  )
  onlineConsultationPlatformConfig:Map<string,ConsultationPlatformConfig>; 

  get familyFeeConfig() {
    if(this.servicewiseFeesMap?.has(ServiceTypes.family) 
    &&  this.servicewiseFeesMap.get(ServiceTypes.family).isActive) {
      return this.servicewiseFeesMap.get(ServiceTypes.family);
    } else {
      return null;
    }
  }

  get coupleFeeConfig() {
    if(this.servicewiseFeesMap?.has(ServiceTypes.couple)
    &&  this.servicewiseFeesMap.get(ServiceTypes.couple).isActive) {
      return this.servicewiseFeesMap.get(ServiceTypes.couple);
    } else {
      return null;
    }
  }

  get individualFeeConfig() {
    if(this.servicewiseFeesMap?.has(ServiceTypes.individual)
    &&  this.servicewiseFeesMap.get(ServiceTypes.individual).isActive) {
      return this.servicewiseFeesMap.get(ServiceTypes.individual);
    } else {
      let obj = new ServiceFeeConfig();
      obj.fee = this.fee;
      obj.currency = Currency.INR;
      obj.appointmentDuration = this.appointmentDuration;
      return obj;
    }
  }

  get displayPlatforms() {
    if (this.onlineConsultationPlatforms && this.onlineConsultationPlatforms.length > 0) {
      let displayArr: string[] = [];
      let platforms = [OnlineConsultationPlatform.googlemeet, OnlineConsultationPlatform.whatsapp,
      OnlineConsultationPlatform.zoom, OnlineConsultationPlatform.phone];
      for (let p of platforms) {
        if (this.onlineConsultationPlatforms.includes(p)) {
          switch (p) {
            case OnlineConsultationPlatform.googlemeet:
              displayArr.push("Google Meet");
              break;
            case OnlineConsultationPlatform.zoom:
              displayArr.push("Zoom");
              break;
            case OnlineConsultationPlatform.phone:
              displayArr.push("Phone");
              break;
            case OnlineConsultationPlatform.whatsapp:
              displayArr.push("Whatsapp");
              break;
          }
        }
      }
      if(displayArr.length > 0) {
        return displayArr.join("/");
      }else{
        return "Zoom/WhatsApp/Phone";
      }
    } else {
      return "Zoom/WhatsApp/Phone";
    }
  }

  get displayPlatformsCSVWithOr() {
    if (this.onlineConsultationPlatforms && this.onlineConsultationPlatforms.length > 0) {
      let displayArr: string[] = [];
      let platforms = [OnlineConsultationPlatform.zoom, OnlineConsultationPlatform.whatsapp,
      OnlineConsultationPlatform.googlemeet, OnlineConsultationPlatform.phone];
      for (let p of platforms) {
        if (this.onlineConsultationPlatforms.includes(p)) {
          switch (p) {
            case OnlineConsultationPlatform.googlemeet:
              displayArr.push("Google Meet");
              break;
            case OnlineConsultationPlatform.zoom:
              displayArr.push("Zoom");
              break;
            case OnlineConsultationPlatform.phone:
              displayArr.push("Phone");
              break;
            case OnlineConsultationPlatform.whatsapp:
              displayArr.push("Whatsapp");
              break;
          }
        }
      }
      if(displayArr.length > 0) {
        if(displayArr.length == 1) {
          return displayArr.join(", ");
        }
        if(displayArr.length == 2) {
          return displayArr.join(" or ");
        } else {
          const last = displayArr[displayArr.length-1];
          let str = displayArr[0];
          for(let i = 1; i< displayArr.length-1;i++) {
            str += ", "+ displayArr[i];
          }
          str += " or "+last;
          return str;
        }
      }else{
        return "Zoom, WhatsApp, Phone";
      }
    } else {
      return "Zoom, WhatsApp, Phone";
    }
  }
}
