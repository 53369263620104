<app-navbar (backClicked)="backClicked()" [title]="title"></app-navbar>
<div class="p-2">
    <div class="btn-group" dropdown>
        <button id="button-basic" dropdownToggle type="button" class="btn btn-outline-primary dropdown-toggle"
                aria-controls="dropdown-basic" style="width: 160px;">
            {{selectedFilter?listFilterOptions[selectedFilter]:"Filter By"}} <span class="caret"></span>
        </button>
        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu"
            role="menu" aria-labelledby="button-basic">
          <li role="menuitem" *ngFor="let item of listFilterOptions|keyvalue:returnZero;" (click)="selectFilter(item.key)"><a class="dropdown-item">{{listFilterOptions[item.key]}}</a></li>
        </ul>
      </div>
</div>
<div class="main-container">
    
    <div class="leads-container">
        <div class="leads-list">
            <div [ngClass]="{
        lead_selected: selectedLeadId == lead._id
      }" class="lead" *ngFor="let lead of list; let i = index" [ngStyle]="{
          'border-top': selectedLeadId == lead._id && i == 0 ? '1px solid #777' : '0'
        }" (click)="onLeadSelect(lead,i)">
                <!-- <b>+{{lead.patientContactInfo.countryCode}} {{lead.patientContactInfo.number}}</b> - <span style="font-weight: 400;">{{lead.status}}</span> - {{lead.createdOn | date:"medium"}} -->
         
                <div class="me-2" style="font-weight: bold">{{ i + 1 }}.</div>
                <div class="d-flex flex-column w-100">
                    <div class="d-flex align-items-center">
                        <span style="font-weight: 600">
                        {{ lead.customerName ? lead.customerName : "-" }}
                        </span>
                        <!-- <span style="color: #777;">
                        , {{ lead.patientContactInfo.number }}
                        </span> -->
                        <span style="font-weight: 600" class="ms-auto">
                            ₹{{ lead.orderAmount ? lead.orderAmount : "-" }}
                        </span>
                    </div>
                    <div class="d-flex align-items-center justify-content-between">
                        <span style="font-size: 14px">
                        <span class="badge me-2 bg-primary">                            
                            {{lead?.consultationInfo?.paymentId ? "PENDING_PAYMENT": lead.bookingStatus? lead.bookingStatus: "" }}
                        </span>
                        
                        {{ lead.metaData && lead.metaData.doctorName ? lead.metaData.doctorName : "-" }}
                        </span>
                        <!-- <span style="font-size: 14px; color: grey">
                        {{ lead.createdOn | date: "h:mm a, LLL d" }}
                        </span>z -->
                    </div>
                    <div>
                        <span style="font-size: 14px; color: black">
                        Order Date & Time:
                        <b>
                            {{ lead && lead.createdOn ? (lead.createdOn|date:"dd MMM") : "-" }}, {{ lead && lead.createdOn ? (lead.createdOn|date:"hh:mm a") : "-" }}
                        </b>
                        </span>
                    </div>
                    <div class="d-flex align-items-center">
                        <span *ngIf="lead.reTargetStep&&lead.reTargetStep.bool" class="badge bg-info">Retargeted</span>
                        <span *ngIf="lead.apptBookedStep&&lead.apptBookedStep.bool" class="badge bg-success ms-1">Booked</span>
                    </div>
                </div>

                <!-- <div class="d-flex flex-column w-100">
                    <div class="d-flex justify-content-between align-items-center">
                        <span style="font-weight: 600">
                            {{ lead.customerName ? lead.customerName : "-" }} ➔ {{ lead.metaData && lead.metaData.doctorName ? lead.metaData.doctorName : "-" }}
                        </span>
                     
                        <span style="font-weight: 600">
                            Order Amount: ₹{{ lead.orderAmount ? lead.orderAmount : "-" }}
                        </span>
                    </div>
                  
                    <div class="d-flex justify-content-between align-items-center mt-2">
                      
                        <span style="font-weight: 600">
                            {{ lead.metaData && lead.metaData.date ? (lead.metaData.date|date:"dd-MM-yyyy") : "-" }}, {{ lead.metaData && lead.metaData.timeslot ? lead.metaData.timeslot : "-" }}
                        </span>
                      
                    </div>
                </div> -->
            </div>
            <div class="spinner-border" role="status" *ngIf="isLoading">
                <span class="sr-only">Loading...</span>
            </div>
            <h5 class="text-muted" *ngIf="list != null && list.length == 0&&!isLoading">No Leads</h5>
        </div>
        <div class="lead-details-container d-flex flex-column pt-2" *ngIf="showDetailsContainer">
            <ng-container *ngIf="showDetails">
                <div class="w-100 d-flex flex-column p-2">
                    <div
                    *ngIf="leadDetails != null"
                    id="lead-info"
                    class="lead-info d-flex flex-column mt-2"
                    >
                    <h5 class="mb-1">Patient Info:</h5>
                    <div class="d-flex align-items-center justify-content-between">
                    <div>
                    <span class="font-18 fw-500 pe-2">{{ leadDetails["customerName"] }} </span> |
                    <span class="fw-400 px-1">{{ "+" + leadDetails["customerPhone"] }}
    
                    <ng-container *ngIf="leadDetails['customerPhone']">
                        <!-- <offcanvas-chat class="ms-2" [chatItemId]="'whatsapp'+leadDetails['customerPhone']"></offcanvas-chat> -->
                        <button
                            class="btn btn-sm btn-outline-primary border-0"
                            tooltip="Open WhatsApp Chat"
                            (click)="openChatInCanvas(leadDetails['customerPhone'])"
                            >
                            <i class="la la-whatsapp font-18"></i>
                        </button>
                    </ng-container>
    
                    <button class="btn btn-sm btn-outline-primary border-0 ms-2"
                        (click)="openPatientInfo()" *ngIf="clientIdExists">
                        View Details
                    </button>
                    <button class="btn btn-sm btn-outline-primary border-0 ms-2"
                        (click)="psearch.openSearchModal()" *ngIf="!clientIdExists">
                        View Details
                    </button>
                    <!-- {{ lead.isVerified ? "(Verified)" : "(Unverified)" }} -->
                    </span>
                    </div>
                    <div class="d-flex align-items-center">
                    <button
                    type="button"
                    class="
                    btn btn-sm btn-outline-primary
                    d-flex
                    align-items-center
                    border-0
                    mx-1
                    "
                    (click)="copyLeadContactInfo()"
                    >
                    <i style="font-size: 15px" class="material-icons me-1">content_copy</i>
                    Copy
                    </button>
                    <button
                    type="button"
                    class="
                    btn btn-sm btn-outline-primary
                    d-flex
                    align-items-center
                    border-0
                    mx-1
                    "
                    (click)="tryToOpenWhatsAppChat()"
                    >
                    <i style="font-size: 15px" class="material-icons me-1">message</i>
                    Message
                    </button>
                    <button
                    type="button"
                    class="
                    btn btn-sm btn-outline-primary
                    d-flex
                    align-items-center
                    border-0
                    mx-1
                    "
                    (click)="saveLeadContact()"
                    >
                    <i style="font-size: 15px" class="material-icons me-1">contact_phone</i>
                    Save Contact
                    </button>
                    </div>
                    </div>
                    <div class="font-14 fw-400 fc-grey">
                    <!-- <span>{{ lead.cityNameForDisplay }}, </span> -->
                    <span><b>Booking Date & Time: </b> {{ leadDetails.metaData && leadDetails.metaData.date ? (leadDetails.metaData.date|date:"dd MMM") : "-" }}, {{ leadDetails.metaData && leadDetails.metaData.timeslot ? tConvert(leadDetails.metaData.timeslot.split('-')[0]) : "-" }}</span>
                    <div style="font-size: 14px;" class="d-flex flex-column mt-1">
                        <span *ngIf="leadDetails.metaData&&leadDetails.metaData.isFirstAppointmentWithCurrentProvider!=null">
                          First Appointment With Current Provider : <b>{{leadDetails.metaData.isFirstAppointmentWithCurrentProvider?"Yes":"No"}}</b>
                        </span>
                      </div>
                    </div>
                    <!-- <div class="mt-2 font-14 fw-400" *ngIf="lead.enquiryMessage">
                        <span class="fw-500">Message: </span>
                        <span>{{ lead.enquiryMessage }}</span>
                    </div> -->
                    </div>
                    <div class="d-flex flex-column mt-2 w-100 provider-info">
                        <h5 class="mb-1">Provider:</h5>
                        <div class="d-flex">
                            <span class="font-18 fw-500 pe-2">{{leadDetails?.metaData?.doctorName}} </span>
                            <div class="d-flex ms-auto">
                                <button type="button"
                                class="
                                btn btn-sm btn-outline-primary
                                d-flex
                                align-items-center
                                border-0
                                mx-1
                                "
                                (click)="copyProviderID()"
                                >
                                <i style="font-size: 15px" class="material-icons me-1">content_copy</i>
                                Copy ID
                                </button>
            
                                <button
                                type="button"
                                class="
                                btn btn-sm btn-outline-primary
                                d-flex
                                align-items-center
                                border-0
                                mx-1
                                "
                                (click)="gotoDoctorProfile()"
                                [disabled]="
                                leadDetails.metaData.doctorId == null ||
                                leadDetails.metaData.doctorId == undefined ||
                                leadDetails.metaData.doctorId.length == 0
                                "
                                >
                                <i style="font-size: 15px" class="material-icons me-1"
                                >account_circle</i
                                >
                                View Profile
                                </button>
                            </div>
                        </div>
                        
                    </div>
                </div>
                
                <div class="w-100 d-flex flex-wrap">
                    <div class="lead-details">
                        <h5 class="mb-0">Order Details</h5>
                        <hr />
                        <div>
                            <span style="font-weight: 500">Order CreatedOn: </span>{{ leadDetails.createdOn ? (leadDetails.createdOn|date:"dd/MM/yy, hh:mm a") : "-" }}
                        </div>
                        <div>
                            <span style="font-weight: 500">Order ID: </span>{{ leadDetails.orderId ? leadDetails.orderId : "-" }}
                        </div>
                        <div>
                            <span style="font-weight: 500">Order Amount: </span>{{ leadDetails.orderAmount ? "₹ "+leadDetails.orderAmount : "-" }}
                        </div>
                        <div>
                            <span style="font-weight: 500">Booking Type: </span>{{ leadDetails.metaData && leadDetails.metaData.bookingType && leadDetails.metaData.bookingType == 'instantConsult' ? "Instant Consult" : "Scheduled" }}
                        </div>
                        
                        <div class="text-muted">
                            |
                        </div>
                        <div>
                          <span style="font-weight: 500">Customer Name: </span>{{ leadDetails.customerName ? leadDetails.customerName : "-" }}
                        </div>
                        <!-- <div>
                            <span style="font-weight: 500">Customer Email: </span>{{ leadDetails.customerEmail ? leadDetails.customerEmail : "-" }}
                        </div> -->
                        <div>
                            <span style="font-weight: 500">Customer Phone: </span>{{ leadDetails.customerPhone ? leadDetails.customerPhone : "-" }}
                        </div>
                                            
                    </div>
                    <div class="lead-details">
                        <h5 class="mb-0">Next Steps</h5>
                        <hr />
                        <app-book-appointment [patientId]="currentPatientId" [doctorId]="currentDoctorId" [bookingDate]="currentLeadBookingDate" [bookingTime]="currentLeadBookingTime"></app-book-appointment>
                        <div class="d-flex align-items-center mt-3">
                            <button class="btn btn-primary btn-sm" *ngIf="leadDetails.reTargetStep==undefined||leadDetails.reTargetStep.bool==false" (click)="reTargetSent(true)">Retargeted Client?</button>
                            <button class="btn btn-success btn-sm" *ngIf="leadDetails.reTargetStep&&leadDetails.reTargetStep.bool==true" (click)="reTargetSent(false)">Retargeted Client on {{leadDetails.reTargetStep.updatedOn|date:'short'}}✔</button>
    
                            <button class="btn btn-primary btn-sm ms-2" *ngIf="leadDetails.apptBookedStep==undefined||leadDetails.apptBookedStep.bool==false" (click)="appointmentBookedSent(true)">Appointment Booked?</button>
                            <button class="btn btn-success btn-sm ms-2" *ngIf="leadDetails.apptBookedStep&&leadDetails.apptBookedStep.bool==true" (click)="appointmentBookedSent(false)">Appointment Booked on {{leadDetails.apptBookedStep.updatedOn|date:'short'}}✔</button>
                        </div>
                        
                        <ng-container *ngIf="leadDetails.reTargetStep==undefined||leadDetails.reTargetStep.bool==false">
                            <div class="d-flex align-items-center w-100 mt-2">
                                <span style="font-weight: 500">Could not complete booking </span>
                                <i style="font-size: 15px;" (click)="copyIncompleteBooking()" class="material-icons text-primary ms-1 cursor-pointer">content_copy</i>
                                <i style="font-size: 15px;" (click)="wpIncompleteBooking()" class="material-icons text-primary ms-1 cursor-pointer">message</i>
                                <button class="btn btn-sm btn-outline-primary border-0 d-flex align-items-center ms-auto" [ngClass]="{'btn-outline-success': leadDetails.bookingFailedStep&&leadDetails.bookingFailedStep.bool}" (click)="bookingFailedMessageSent()">
                                    <i style="font-size: 15px" class="material-icons me-1">done</i>
                                    {{leadDetails.bookingFailedStep&&leadDetails.bookingFailedStep.bool?nextStepMeta(leadDetails.bookingFailedStep):"done"}}
                                </button>
                            </div>
                            <div class="d-flex align-items-center w-100">
                                <span style="font-weight: 500">Resume booking </span>
                                <i style="font-size: 15px;" *ngIf="!copyLoader" (click)="copyResumeBooking()" class="material-icons text-primary ms-1 cursor-pointer">content_copy</i>
                                <div class="spinner-border spinner-border-sm ms-1" role="status" *ngIf="copyLoader||wpLoader">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <i style="font-size: 15px;" *ngIf="!wpLoader" (click)="wpResumeBooking()" class="material-icons text-primary ms-1 cursor-pointer">message</i>
                                <button class="btn btn-sm btn-outline-primary border-0 d-flex align-items-center ms-auto" [ngClass]="{'btn-outline-success': leadDetails.resumeBookingStep&&leadDetails.resumeBookingStep.bool}" (click)="resumeBookingMessageSent()">
                                    <i style="font-size: 15px" class="material-icons me-1">done</i>
                                    {{leadDetails.resumeBookingStep&&leadDetails.resumeBookingStep.bool?nextStepMeta(leadDetails.resumeBookingStep):"done"}}
                                </button>
                            </div>
                            <div class="d-flex align-items-center w-100">
                                <span style="font-weight: 500">Reply "yes" to auto book </span>
                                <i style="font-size: 15px;" (click)="copyReplyYes()" class="material-icons text-primary ms-1 cursor-pointer">content_copy</i>
                                <i style="font-size: 15px;" (click)="wpReplyYes()" class="material-icons text-primary ms-1 cursor-pointer">message</i>
                                <button class="btn btn-sm btn-outline-primary border-0 d-flex align-items-center ms-auto" [ngClass]="{'btn-outline-success': leadDetails.autoBookStep&&leadDetails.autoBookStep.bool}" (click)="autoBookMessageSent()">
                                    <i style="font-size: 15px" class="material-icons me-1">done</i>
                                    {{leadDetails.autoBookStep&&leadDetails.autoBookStep.bool?nextStepMeta(leadDetails.autoBookStep):"done"}}
                                </button>
                            </div>
                            <div class="d-flex align-items-center w-100 mt-2">
                                <button class="btn btn-sm btn-outline-primary d-flex align-items-center" [disabled]="retargetMessageLoader" (click)="sendRetargetMessageToClient()" *ngIf="leadDetails.customerPhone">
                                    <i class="feather icon-message-square me-2"></i>
                                    Send Retarget Message to Client
                                    <span class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true" *ngIf="retargetMessageLoader"></span>
                                </button>
                                <!-- <button class="btn btn-sm btn-outline-primary border-0 d-flex align-items-center ms-auto" [ngClass]="{'btn-outline-success': leadDetails.autoBookStep&&leadDetails.autoBookStep.bool}" (click)="autoBookMessageSent()">
                                    <i style="font-size: 15px" class="material-icons me-1">done</i>
                                    {{leadDetails.autoBookStep&&leadDetails.autoBookStep.bool?nextStepMeta(leadDetails.autoBookStep):"done"}}
                                </button> -->
                            </div>
                        </ng-container>
                        
                        <hr />
                        <div class="d-flex align-items-center w-100">
                            <span style="font-weight: 500">International Number Support</span>
                            <i style="font-size: 15px;" (click)="copyInternationalNumberSupport()" class="material-icons text-primary ms-1 cursor-pointer">content_copy</i>
                            <i style="font-size: 15px;" (click)="wpInternationalNumberSupport()" class="material-icons text-primary ms-1 cursor-pointer">message</i>
                        </div>
                        <div class="d-flex align-items-center w-100">
                            <span style="font-weight: 500">Care Coordinator Intro</span>
                            <i style="font-size: 15px;" (click)="copyCareCoordinatorIntro()" class="material-icons text-primary ms-1 cursor-pointer">content_copy</i>
                            <i style="font-size: 15px;" (click)="wpCareCoordinatorIntro()" class="material-icons text-primary ms-1 cursor-pointer">message</i>
                        </div>
                        <div class="d-flex align-items-center w-100">
                            <span style="font-weight: 500">Ask for Client Info</span>
                            <i style="font-size: 15px;" (click)="copyAskClientInfo()" class="material-icons text-primary ms-1 cursor-pointer">content_copy</i>
                            <i style="font-size: 15px;" (click)="wpAskClientInfo()" class="material-icons text-primary ms-1 cursor-pointer">message</i>
                        </div>
                        <div class="d-flex align-items-center w-100">
                            <span style="font-weight: 500">Share Payment Link</span>
                            <i style="font-size: 15px;" (click)="copySharePaymentLink()" class="material-icons text-primary ms-1 cursor-pointer">content_copy</i>
                            <i style="font-size: 15px;" (click)="wpSharePaymentLink()" class="material-icons text-primary ms-1 cursor-pointer">message</i>
                        </div>
                    </div>
                    <div class="lead-details">
                        <h5 class="mb-0">Appointment Details</h5>
                        <hr />
                        <div>
                            <span style="font-weight: 500">Appointment ID: </span
                            > {{ leadDetails.metaData && leadDetails.metaData.appointmentId ? leadDetails.metaData.appointmentId : "-" }}
                        </div>
                        <div>
                            <span style="font-weight: 500">Appointment Date: </span
                            > {{ leadDetails.metaData && leadDetails.metaData.date ? (leadDetails.metaData.date|date:"dd-MM-yyyy") : "-" }}
                        </div>
                        <div>
                            <span style="font-weight: 500">Appointment timeslot: </span
                            > {{ leadDetails.metaData && leadDetails.metaData.timeslot ? leadDetails.metaData.timeslot : "-" }}
                        </div>
                        <div class="text-muted">
                            |
                        </div>
                        <div>
                            <span style="font-weight: 500">Doctor Name: </span
                            > {{ leadDetails.metaData && leadDetails.metaData.doctorName ? leadDetails.metaData.doctorName : "-" }}
                        </div>
                        <div>
                            <span style="font-weight: 500">Purpose: </span
                            > {{ leadDetails.metaData && leadDetails.metaData.purposeOfVisitTitle ? leadDetails.metaData.purposeOfVisitTitle : "-" }}
                        </div>
                        
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="!showDetails&&(!leadDetails||!leadDetails._id)">
                <span class="text-muted">
                    Select a Lead
                </span>
            </ng-container>
            

        </div>
    </div>
</div>

<patient-search-v2 [showButton]="false" [searchTerm]="leadDetails&&leadDetails['customerPhone']?leadDetails['customerPhone']:''" #psearch></patient-search-v2>
<offcanvas-chat class="ms-2" [showButton]="false" #chatoffcanvas1></offcanvas-chat>
