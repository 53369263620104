import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { User } from '../../models-ts/Actors/User/User';
import { Lead } from '../../models-ts/Entities/Lead';
import { CashgramRefund } from '../../models-ts/Entities/CashgramRefund/CashgramRefund';
import { CashgramRefundStatus } from '../../models-ts/Entities/CashgramRefund/Enums';
import { CopyClipboardService } from '../../services/copy-clipboard/copy-clipboard.service';
import { CashgramRefundsService } from '../../services/cashgram-refunds/cashgram-refunds.service';
import { classToPlain, plainToClass } from 'class-transformer';
import { CRMUser } from '../../models-ts/Actors/User/CRMUser';
import { Currency } from '../../models-ts/Helpers/countrywiseFeeCalc';

@Component({
  selector: 'create-cashgram-refund-link',
  templateUrl: './create-cashgram-refund-link.component.html',
  styleUrls: ['./create-cashgram-refund-link.component.scss'],
})
export class CreateCashgramRefundLinkComponent implements OnInit {
  _patientId: string;
  @Input() set patientId(val: string) {
    this._patientId = val;
  }
  get patientId(): string {
    return this._patientId;
  }

  @Input() lead: Lead = new Lead();
  @Input() showButton: boolean = false;
  @Input() currentHeroInfo: CRMUser = new CRMUser();
  @Input() viewOnlyLinks: boolean = false;
  @ViewChild('cgrmModal') cgrmModal;
  listSub: any;
  modalRef: BsModalRef;
  creatingLinkLoader: boolean = false;
  linksLoader: boolean = false;
  patientProfile: User = new User();
  refundLinkForm: any;
  isCountryCode91: boolean = true;
  page: number = 1;
  refundLink: string;
  cashgramRefunds: CashgramRefund[] = [];
  debitFromWallet:boolean = false;
  wallet:Map<Currency,number> = new Map<Currency,number>();
  constructor(
    private modalService: BsModalService,
    private clipboardService: CopyClipboardService,
    private _toastr: ToastrService,
    private _crgmService: CashgramRefundsService
  ) {}
  ngOnInit(): void {}

  openModal() {
    this.initModalState();
    this.modalRef = this.modalService.show(this.cgrmModal);
  }

  closeModal() {
    this.modalRef.hide();
  }

  initModalState() {
    this.gotoPage(1);
    this.initRefundLinkForm();
  }

  initRefundLinkForm() {
    this.debitFromWallet = false;
    this.isCountryCode91 = true;
    this.refundLinkForm = {
      amount: null,
      remarks: '',
      clientName: '',
      clientEmail: '',
      clientPhone: '',
    };
    this.viewOnlyLinks = false;
    this.refundLink = '';
    if (this.lead && this.lead.consultationInfo && this.lead.netIncomingBalancePaidByClient() && this.lead.netIncomingBalancePaidByClient().amount!=null) {
      this.refundLinkForm['amount'] = this.lead.netIncomingBalancePaidByClient().amount;
    }
  }

  refundFormValidator() {
    if (
      this.refundLinkForm['amount'] &&
      this.refundLinkForm['clientName'] &&
      this.refundLinkForm['clientName'].length > 0 &&
      this.refundLinkForm['clientPhone'] &&
      this.refundLinkForm['clientPhone'].length == 10 &&
      this.patientProfile &&
      this.patientProfile.docvitaId &&
      this.isCountryCode91 == true &&
      this.currentHeroInfo &&
      this.currentHeroInfo.docvitaId
    ) {
      return true;
    } else {
      return false;
    }
  }

  createLink() {
    if (this.refundFormValidator()) {
      let refund = new CashgramRefund();

      refund.clientId = this.patientProfile.docvitaId;
      refund.createdById = this.currentHeroInfo.docvitaId;
      refund.createdByName = this.currentHeroInfo.name;
      refund.amount = +this.refundLinkForm['amount'];
      refund.remarks = this.refundLinkForm['remarks']
        ? this.refundLinkForm['remarks']
        : '';
      refund.clientName = this.refundLinkForm['clientName'];
      refund.clientPhone = this.refundLinkForm['clientPhone'];
      refund.clientEmail = this.refundLinkForm['clientEmail']
        ? this.refundLinkForm['clientEmail']
        : '';
      if (this.lead && this.lead.consultationInfo) {
        refund.leadId = this.lead.id;
        refund.appointmentId = this.lead.consultationInfo
          ? this.lead.consultationInfo.appointmentId
          : '';
      }

      if(this.debitFromWallet){
        refund.isWalletRefund = true;
      }

      this.creatingLinkLoader = true;
      this._crgmService
        .createRefundLink(refund)
        .then((data) => {
          if (data) {
            let _data = plainToClass(CashgramRefund, data);
            this.refundLink = _data.cashgramLinkInfo.cashgramLink;
            this.gotoPage(2);
          } else {
            this._toastr.error('Failed to create refund link.');
          }
          this.creatingLinkLoader = false;
        })
        .catch((err) => {
          this.creatingLinkLoader = false;
          this._toastr.error('Failed to create refund link.');
        });
    } else {
      this._toastr.warning('Form is missing some details.');
    }
  }

  viewLinksOnlyMode() {
    this.viewOnlyLinks = true;
    this.viewAllRefunds();
    this.modalRef = this.modalService.show(this.cgrmModal);
  }

  viewAllRefunds() {
    this.gotoPage(3);
    this.getAllRefundLinks();
  }

  getAllRefundLinks() {
    if (this.listSub) {
      this.listSub();
    }
    this.cashgramRefunds = [];
    this.linksLoader = true;
    this.listSub = this._crgmService.getRefundLinks().onSnapshot(
      (snap) => {
        if (!snap.empty) {
          this.cashgramRefunds = [];
          snap.forEach((doc) => {
            let data = plainToClass(CashgramRefund, doc.data());
            this.cashgramRefunds.push(data);
          });
          this.linksLoader = false;
        } else {
          this.linksLoader = false;
          this.cashgramRefunds = [];
        }
      },
      (err) => {
        this.linksLoader = false;
        this.cashgramRefunds = [];
      }
    );
  }

  sanitizeClientNameForCashgramRefund(name: string) {
    name = name.split(".").join("");
    return name;
  }

  setPatient(event) {
    if (event) {
      this.patientProfile = event;
      // console.log(event);
      this.refundLinkForm['clientName'] = this.sanitizeClientNameForCashgramRefund(this.patientProfile.name);
      if (
        this.patientProfile.contactNumbers &&
        this.patientProfile.getPrimaryContactNumbers() &&
        this.patientProfile.getPrimaryContactNumbers().length > 0
      ) {
        let contact = this.patientProfile.getPrimaryContactNumbers()[0];
        if (contact.countryCode == '91') {
          this.isCountryCode91 = true;
          this.refundLinkForm['clientPhone'] = contact.number;
        } else {
          this.isCountryCode91 = false;
        }
      } else if (
        this.patientProfile.primaryContactNumber &&
        this.patientProfile.primaryContactNumber.number
      ) {
        let contact = this.patientProfile.primaryContactNumber;
        if (contact.countryCode == '91') {
          this.isCountryCode91 = true;
          this.refundLinkForm['clientPhone'] = contact.number;
        } else {
          this.isCountryCode91 = false;
        }
      }

      if (
        this.patientProfile.emails &&
        this.patientProfile.getPrimaryEmail() &&
        this.patientProfile.getPrimaryEmail().email
      ) {
        this.refundLinkForm['clientEmail'] =
          this.patientProfile.getPrimaryEmail().email;
      } else if (
        this.patientProfile.emails &&
        this.patientProfile.getEmails() &&
        this.patientProfile.getEmails().length > 0
      ) {
        this.refundLinkForm['clientEmail'] =
          this.patientProfile.getEmails()[0].email;
      } else if (this.patientProfile.primaryEmail) {
        this.refundLinkForm['clientEmail'] = this.patientProfile.primaryEmail;
      }

      // console.log('customer name: ', this.refundLinkForm['clientName']);
      // console.log('customer contact: ', this.refundLinkForm['clientPhone']);
      // console.log('customer email: ', this.refundLinkForm['clientEmail']);
    }
  }

  getStatusStyle(status) {
    if (status == CashgramRefundStatus.CREATED) {
      return 'badge bg-primary';
    } else if (status == CashgramRefundStatus.REDEEMED) {
      return 'badge bg-success';
    } else if (status == CashgramRefundStatus.DEACTIVATED) {
      return 'badge bg-danger';
    } else if (status == CashgramRefundStatus.TRANSFER_REVERSAL) {
      return 'badge bg-info';
    } else if (status == CashgramRefundStatus.EXPIRED) {
      return 'badge bg-secondary';
    } else {
      return 'badge bg-light text-dark';
    }
  }
  copy(text) {
    if (text) {
      this.clipboardService.copyToClipoard(text);
    }
  }

  gotoPage(page: number) {
    this.page = page;
  }
  copyRefundLink() {
    this.clipboardService.copyToClipoard(this.refundLink);
  }

  get disablePayViaWallet() {
    return (
      +this.refundLinkForm['amount'] == null || this.wallet == null ||
      this.wallet.size == 0 ||
      (this.wallet && this.wallet.size>0 && this.wallet.get(Currency.INR) != null && this.wallet.get(Currency.INR) < +this.refundLinkForm['amount'])
    );
  }

  getWalletBalance(wallet:Map<Currency,number>){
    if(wallet.size>0){
      this.wallet = wallet;
    }else{
      this.wallet = new Map<Currency,number>();
    }
  }

}
