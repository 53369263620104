import { Component, OnInit } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { DoctorListingProfile } from 'src/app/shared/models-ts/Actors/Doctor/DoctorListingProfile';
import { AuthUserInfoService } from 'src/app/shared/services/auth-user-info/auth-user-info.service';
import { DoctorDataService } from 'src/app/shared/services/doctor-data/doctor-data.service';
import { DoctorsService } from 'src/app/shared/services/doctors/doctors.service';
import { Claim } from '../../shared/models-ts/Entities/Claim';
import { ClaimService } from '../../shared/services/claim/claim.service';

@Component({
  selector: 'app-doc-profile-claim',
  templateUrl: './doc-profile-claim.component.html',
  styleUrls: ['./doc-profile-claim.component.css'],
})
export class DocProfileClaimComponent implements OnInit {
  claims: Claim[] = [];
  doctorProfile: DoctorListingProfile;
  dataLayerSubscription: Subscription;
  mobileNumber: string;
  countryCode: string;
  email: string;
  filesForGovernmentIDs: any[] = [];
  filesForMedicalRegistrations: any[] = [];
  currentHeroInfo: any;
  constructor(
    private _claimService: ClaimService,
    private _toastrService: ToastrService,
    private _doctorDataService: DoctorDataService,
    private _authUserInfoService: AuthUserInfoService,
    private _doctorService: DoctorsService
  ) {}

  ngOnInit(): void {
    this.currentHeroInfo = this._authUserInfoService.getUserInfo();
    this.dataLayerSubscription = this._doctorDataService.currentSelection.subscribe(
      (data) => {
        if (data != null && data['memberSelected'] != null) {
          this.doctorProfile = data['memberSelected'];
          if (this.doctorProfile != null && this.doctorProfile.id) {
            if(this.doctorProfile.emails!=undefined||this.doctorProfile.emails!=null){
              let emails = [];
              this.doctorProfile.emails.forEach(em=>{
                emails.push(em.email);
              })
              this.email = emails[0];
            }
            if(this.doctorProfile.contactNumbers){
              this.mobileNumber = this.doctorProfile.getAllContactNumbers()[0].number;
              this.countryCode = this.doctorProfile.getAllContactNumbers()[0].countryCode;
            }
            // if (
            //   this.doctorProfile.primaryContactNumber &&
            //   this.doctorProfile.primaryContactNumber.number
            // ) {
            //   this.mobileNumber = +this.doctorProfile.primaryContactNumber
            //     .number;
            // }
            // if (this.doctorProfile.email) {
            //   this.email = this.doctorProfile.email;
            // }
            this.getClaims();
          }
        }
      }
    );
  }

  fileMedicalRegistrationProgress(fileInput: any) {
    console.log(fileInput.target.files.length);
    let promises = [];
    for (let f of <File[]>fileInput.target.files) {
      let reader = new FileReader();
      reader.readAsDataURL(f);
      reader.onload = (_event) => {
        if (reader.error) {
          console.error(reader.error);
        }
        console.log('on load done', reader);
        if (reader.result) {
          this.filesForMedicalRegistrations.push({
            name: f.name,
            data: f,
            obj: reader.result,
          });
        }
      };
    }
    fileInput.target.value = null;
  }

  fileGovernmentIDProgress(fileInput: any) {
    console.log(fileInput.target.files);
    for (let f of <File[]>fileInput.target.files) {
      let reader = new FileReader();
      reader.readAsDataURL(f);
      reader.onload = (_event) => {
        if (reader.result) {
          this.filesForGovernmentIDs.push({
            name: f.name,
            data: f,
            obj: reader.result,
          });
        }
      };
    }
    fileInput.target.value = null;
  }

  removeFileFromGovernmentIdList(file) {
    let idx = this.filesForGovernmentIDs.indexOf(file);
    this.filesForGovernmentIDs.splice(idx, 1);
  }

  removeFileFromMedicalRegistrationDocsList(file) {
    let idx = this.filesForMedicalRegistrations.indexOf(file);
    this.filesForMedicalRegistrations.splice(idx, 1);
  }

  getClaims() {
    this._claimService
      .getClaims(this.doctorProfile.id)
      .onSnapshot((querySnapshot) => {
        this.claims = [];
        querySnapshot.forEach((doc) => {
          if (doc.exists) {
            let obj = plainToClass(Claim, doc.data(), {
              excludeExtraneousValues: true,
            });
            obj.id = doc.id;
            this.claims.push(obj);
          }
        });
      });
  }

  approveClaim(c: Claim) {
    this._claimService
      .approveClaim(c.id, this.doctorProfile.id)
      .then(() => {
        this._toastrService.success('Claim approved successfully');
        this.updateUserStats(
          this.currentHeroInfo.docvitaId,
          this.doctorProfile.id,
          'c_approve'
        );
      })
      .catch((err) => {
        console.error(err);
        this._toastrService.error('Something went wrong');
      });
  }

  declineClaim(c: Claim) {
    this._claimService
      .declineClaim(c.id)
      .then(() => {
        this._toastrService.success('Claim declined');
        this.updateUserStats(
          this.currentHeroInfo.docvitaId,
          this.doctorProfile.id,
          'c_deny'
        );
      })
      .catch((err) => {
        console.error(err);
        this._toastrService.error('Something went wrong');
      });
  }

  createClaimClicked() {
    if (
      this.email != null &&
      this.email.length > 0 &&
      this.mobileNumber != null &&
      this.mobileNumber.toString().length == 10
    ) {
      this._toastrService.info('Saving...');
      let govArray = [];
      this.filesForGovernmentIDs.forEach((jsonVal) => {
        govArray.push(jsonVal['obj']);
      });
      let medArray = [];
      this.filesForMedicalRegistrations.forEach((jsonVal) => {
        medArray.push(jsonVal['obj']);
      });
      this._claimService
        .createClaim(
          govArray,
          medArray,
          this.countryCode,
          this.mobileNumber,
          this.email,
          this.doctorProfile.id,
          this._authUserInfoService.getUserInfo().docvitaId
        )
        .then((resp) => {
          if (
            resp != null &&
            resp['statusCode'] == 200 &&
            resp['body'] != null &&
            resp['body']['success']
          ) {
            this._toastrService.success('Claim created');
            this.clearFormValues();
          } else {
            this._toastrService.error('Something went wrong');
          }
        })
        .catch((err) => {
          console.error(err);
          this._toastrService.error('Something went wrong');
        });
    } else {
      this._toastrService.warning('Please enter valid email & mobile number');
    }
  }

  clearFormValues() {
    this.filesForGovernmentIDs = [];
    this.filesForMedicalRegistrations = [];
  }

  processingClaim(c: Claim) {
    this._claimService
      .processingClaim(c.id)
      .then(() => {
        this._toastrService.success('Processing claim');
        this.updateUserStats(
          this.currentHeroInfo.docvitaId,
          this.doctorProfile.id,
          'c_inprocess'
        );
      })
      .catch((err) => {
        console.error(err);
        this._toastrService.error('Something went wrong');
      });
  }

  updateUserStats(heroId, docId, reqType) {
    this._doctorService
      .updateDocvitaUserStats(heroId, docId, reqType)
      .subscribe((data) => {
        if (data['statusCode'] == 200) {
          console.log('User stats updated!');
        } else {
          console.log('User stats did not update!');
        }
      });
  }
}
