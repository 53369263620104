import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as urls from '../../../../environments/environment';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Appointment } from '../../models-ts/Entities/Appointment';
import { plainToClass } from 'class-transformer';
import { Lead } from '../../models-ts/Entities/Lead';
import { CRMUser } from '../../models-ts/Actors/User/CRMUser';
@Injectable({
  providedIn: 'root',
})
export class AppointmentService {
  constructor(private _http: HttpClient) {}

  getAppointmentsForPatient(docvitaId) {
    let body = {
      params: {
        docvitaId,
        useLead:"1"
      },
    };
    return this._http.get(urls.environment['get-all-appointments'], body);
  }

  bookAppointment(body) {
    body = JSON.parse(JSON.stringify(body));
    return new Promise((resolve, reject) => {
      this._http.post(urls.environment['book-appointment'], body).subscribe(
        (res: Response) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  rescheduleAppointment(body) {
    body = JSON.parse(JSON.stringify(body));
    return new Promise((resolve, reject) => {
      this._http
        .post(urls.environment['reschedule-appointment'], body)
        .subscribe(
          (res: Response) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
  reassignAppointment(body) {
    body = JSON.parse(JSON.stringify(body));
    return new Promise((resolve, reject) => {
      this._http
        .post(urls.environment['reassign-appointment'], body)
        .toPromise()
        .then((resp) => {
          if (
            resp &&
            resp['statusCode'] == 200 &&
            resp['body']['success'] == true
          ) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  checkIfAppointmentConflicting(
    doctorId: string,
    date: string,
    timeslot: string,
    leadId?: string
  ) {
    let body = { doctorId, date, timeslot };
    if (leadId) body['currentLeadId'] = leadId;
    return new Promise((resolve, reject) => {
      this._http
        .post(urls.environment['clashing-appointment'], body)
        .toPromise()
        .then((resp) => {
          if (
            resp &&
            resp['statusCode'] == 200 &&
            resp['body']['success'] == true &&
            resp['body']['data']
          ) {
            resolve(resp['body']['data']);
          } else {
            resolve(null);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  getLeadByAppointmentId(appointmentId: string): Promise<Lead> {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection('patient_leads')
        .where('consultationInfo.appointmentId', '==', appointmentId)
        .get()
        .then((snap) => {
          if (!snap.empty) {
            let data = snap.docs[0].data() ? snap.docs[0].data() : null;
            if (data) {
              let leadDetail = plainToClass(Lead, data);
              leadDetail.id = snap.docs[0].id;
              resolve(leadDetail);
            } else {
              resolve(null);
            }
          } else {
            resolve(null);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  leadByAppointmentIdRef(appointmentId: string) {
    return firebase
      .firestore()
      .collection('patient_leads')
      .where('consultationInfo.appointmentId', '==', appointmentId);
  }

  async markAsNoShow(leadId:string,boolValue:boolean,crmUser:CRMUser) {
    if(leadId&&crmUser&&crmUser.docvitaId){
      const url = urls.listingUrl + '/v1/lead/operations/mark-no-show'
      const jsonVal = {
        leadId,
        boolValue,
        updatedById:crmUser.docvitaId,
        updatedByName:crmUser.name
      }
      const results = await this._http.post(url,jsonVal).toPromise();
      if(results && results['statusCode'] == 200 && results['body']['success'] == true){
        return true;
      }else{
        return null;
      }
    }else{
      return null;
    }
  }

  async markAsPendingAppointment(leadId:string,boolValue:boolean,crmUser:CRMUser) {
    if(leadId&&crmUser&&crmUser.docvitaId){
      const url = urls.listingUrl + '/v1/lead/operations/mark-pending-change-appointment'
      const jsonVal = {
        leadId,
        boolValue,
        updatedById:crmUser.docvitaId,
        updatedByName:crmUser.name
      }
      const results = await this._http.post(url,jsonVal).toPromise();
      if(results && results['statusCode'] == 200 && results['body']['success'] == true){
        return true;
      }else{
        return null;
      }
    }else{
      return null;
    }
  }

  async getNDayScheduleForProvider(dates:string[],providerId:string, practiceId?: string){
    try {
      if(!dates||dates?.length==0||!providerId){
        throw new Error("missing_params");
      }
      const url = urls.listingUrl + '/v1/provider-slots/get-slots-for-schedule-management';
      const body = {
        dates,
        providerId
      }
      if(practiceId) {
        body['practiceId'] = practiceId;
      }
      const getSchedule = await this._http.post(url,body).toPromise()
      if(getSchedule && getSchedule['statusCode']==200 && getSchedule['body'] && getSchedule['body']['success'] && getSchedule['body']['data']){
        return getSchedule['body']['data'];
      }
      throw new Error("no_schedule");
      
    } catch (error) {
      console.log(error)
      throw error;
    }
  }

  async directBookingFromScheduledAppointment(body:any){
    try {
      const url = urls.baseUrl + '/v3/appointment/schedule-post-payment';
      body = JSON.parse(JSON.stringify(body));
      console.log('direct schedule booking')
      const bookResponse = await this._http.post(url,body).toPromise();
      return bookResponse;
    } catch (error) {
      throw error;
    }
  }

  async expireScheduledSession(appointmentId:string){
    try {
      const url = urls.listingUrl + '/v1/next-session-schedule/expire-specific-appt';
      const body = {
        appointmentId: appointmentId
      }
      const response = await this._http.post(url,body).toPromise();
      if(response && response['statusCode'] == 200 && response['body'] && response['body']['success']){
        return true; 
      }else{
        throw new Error('failed_toExpire')
      }
      
    } catch (error) {
      console.error(error);
      throw error;      
    }
  }




}
