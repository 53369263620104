import { Expose } from "class-transformer";
import { TransactionSource } from "../Enums/pay";
import { LedgerItem } from "./LedgerItem";
export class DocVitaEarningLedgerItem extends LedgerItem {
	@Expose() transactionDetails: string;
	@Expose() clientId: string;
	@Expose() appointmentId: string;
	@Expose() providerId: string;
	@Expose() id: string;
	@Expose() leadId: string;
	@Expose() source: TransactionSource;
	@Expose() transferId: string;
	@Expose() modeOfPayment: string; //Enum needed
	@Expose() modeOfPaymentDetails: any; //Enum needed
	@Expose() ledgerId: string;
}
