import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault('Asia/Kolkata');
import firebase from 'firebase/app';
import 'firebase/database';
import { DoctorListingProfile } from '../../models-ts/Actors/Doctor/DoctorListingProfile';
import { DoctorsService } from 'src/app/shared/services/doctors/doctors.service'
import { ToastrService } from 'ngx-toastr';
import { DayjsHelper } from '../../models-ts/Helpers/dayjsHelper';
@Component({
  selector: 'app-schedule-override-modal',
  templateUrl: './schedule-override-modal.component.html',
  styleUrls: ['./schedule-override-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ScheduleOverrideModalComponent implements OnInit {
  modalRef: BsModalRef;
  bsInlineValue: Date = new Date();
  bsConfig: Partial<BsDatepickerConfig>;
  loader:boolean = false;
  slotsToDeleteFromDb:string[] = []
  @Input() doctorProfile: DoctorListingProfile = new DoctorListingProfile();

  constructor(
    private modalService: BsModalService,
    private doctorService: DoctorsService,
    private toastrService:ToastrService
  ) { }

  overrideSlots: any[] = [];

  addSlot(startTime?:string,endTime?:string,id?:string) {
    this.overrideSlots.push({
      startTime: (startTime?startTime:null),
      endTime: (endTime?endTime:null),
      id:(id?id:firebase.database().ref().push().key)
    });
  }

  deleteSlot(index: number,id:string) {
    if(this.doctorProfile.openSlots&&Object.keys(this.doctorProfile.openSlots).length>0&&this.doctorProfile.openSlots[id]!=null&&!this.slotsToDeleteFromDb.includes(id)){
      this.slotsToDeleteFromDb.push(id)
      console.log(this.slotsToDeleteFromDb)
    }
    
    this.overrideSlots.splice(index, 1);
  }

  ngOnInit(): void {

  }

  @ViewChild('scheduleOverrideModal') scheduleOverrideModal;

  openModal(date?:string) {
    this.initForm(date);
    this.bsConfig = Object.assign({},{ containerClass: 'theme-blue custom-class' });
    this.modalRef = this.modalService.show(this.scheduleOverrideModal);
  }

  initForm(date?:string){
    this.slotsToDeleteFromDb = []
    this.overrideSlots = []
    this.dateValue(date?new Date(date):new Date())
  }

  populateSlotOverrides(date:string) {
    this.overrideSlots = []
    if (this.doctorProfile && this.doctorProfile.id && this.doctorProfile.openSlots) {
      for (const slot in this.doctorProfile.openSlots) {
        const openslot = this.doctorProfile.openSlots[slot]
        if (openslot && openslot['date'] && openslot['timeslot'] && openslot['date'] == date) {
          const st = openslot['timeslot'].split('-')[0]
          const et = openslot['timeslot'].split('-')[1]
          const id = slot;
          this.addSlot(st,et,id)
        }
      }
    }
    if(this.overrideSlots.length==0){
      if(this.doctorProfile.schedule && this.doctorProfile.schedule.length>0){
        const dayVal = dayjs(date).format("ddd")
        for(const sch of this.doctorProfile.schedule){
          if(sch.days.length>0&&sch.days.includes(dayVal)){
            this.addSlot(sch.startTime,sch.endTime)
          }
        }
      }
    }
    if(this.overrideSlots.length==0){
      //empty schedule
      this.addSlot()
    }
    if(this.overrideSlots.length>0){
      this.overrideSlots.sort((a,b)=>{
        return a['startTime'].localeCompare(b['startTime'])
      })
    }
    
  }

  dateValue(event) {
    this.bsInlineValue = event;
    if(event){
      this.populateSlotOverrides(dayjs(this.bsInlineValue).format("YYYY-MM-DD"))
    }
  }

  async addOverrideSlot() {
    if (this.bsInlineValue!=null) {
      const date = dayjs(this.bsInlineValue).format("YYYY-MM-DD");
      let openSlotsPromises = []
      for (const slots of this.overrideSlots) {
        if (slots && slots['startTime'] != null && slots['endTime'] != null) {
          const key = slots['id'];
          const openSlot = {
            date: date,
            timeslot: slots['startTime'] + "-" + slots['endTime'],
            type: "online"
          }
          openSlotsPromises.push(this.doctorService.updateOpenSlot(this.doctorProfile.id, key, openSlot))
        }
      }
      if(this.slotsToDeleteFromDb.length>0){
        this.slotsToDeleteFromDb.forEach(id=>{
          openSlotsPromises.push(this.doctorService.deleteOpenSlot(this.doctorProfile.id, id))
        })
      }
      if (openSlotsPromises && openSlotsPromises.length > 0) {
        try {
          this.loader = true
          await Promise.all(openSlotsPromises)
          this.loader = false;
          this.modalRef.hide()
          this.toastrService.success("Added override slots!")
        } catch (error) {
          this.loader = false;
          this.toastrService.error("Failed to update slots.")
        }
      }else{
        this.loader = false;
        this.toastrService.error("No slots");
      }
    }
  }

  closeModal() {
    this.modalService.hide(this.scheduleOverrideModal);
  }

  returnZero() {
    return 0;
  }
}
