<div class="d-flex justify-content-between align-items-center py-3">
  <p class="mb-0">
    <b>Note:</b>&nbsp;After you're finished making the changes, please do not
    forget to save the changes by pressing the button on the right.
  </p>
  <button class="btn btn-sm btn-primary" (click)="update()">
    Save Changes
  </button>
</div>
<div class="d-flex row border m-1">
  <div
    class="col-xl-6 col-lg-6 col-md-12 col-sm-12 p-2 border-right"
    id="registration"
  >
    <div class="d-flex align-items-center justify-content-between my-2">
      <h2 class="mb-0">Registration</h2>
      <div>
        <button
          class="d-flex btn btn-sm btn-outline-primary border-0"
          (click)="addNewRegistationRow()"
        >
          Add new +
        </button>
      </div>
    </div>
    <div
      class="form-row form-group required"
      *ngFor="let registration of registrationDetails; let i = index"
    >
      <div class="col-6">
        <label for="regNo" class="control-label">Reg. No</label>
        <input
          type="text"
          class="form-control"
          placeholder="Registration Number"
          [id]="'regNo' + i"
          [(ngModel)]="registrationDetails[i].number"
          [value]="registration.number"
          [disabled]="isMedicalRegistrationVerified()"
        />
      </div>
      <div class="col-5">
        <label for="yor" class="control-label">Year</label>
        <input
          type="number"
          class="form-control"
          placeholder="Year"
          [id]="'yor' + i"
          [(ngModel)]="registrationDetails[i].yearOfRegistration"
          [value]="registration.yearOfRegistration"
          [disabled]="isMedicalRegistrationVerified()"
        />
      </div>
      <div class="col-1">
        <label for=""></label>
        <button
          class="d-flex btn btn-sm btn-outline-danger border-0 mt-2"
          style="margin-left: auto"
          (click)="deleteRegistationRow(i)"
          [disabled]="isMedicalRegistrationVerified()"
        >
          <span class="material-icons"> delete </span>
        </button>
      </div>
      <div class="col-12 mt-2">
        <label for="council">Council Name</label>
        <input
          type="text"
          class="form-control"
          placeholder="Council Name"
          [id]="'council' + i"
          [(ngModel)]="registrationDetails[i].council"
          [value]="registration.council"
          [disabled]="isMedicalRegistrationVerified()"
        />
      </div>
    </div>
  </div>
  <div
    class="d-flex flex-column col-xl-6 col-lg-6 col-md-12 col-sm-12 p-2"
    id="medical-registration-verification"
  >
    <div class="d-flex align-items-center justify-content-between my-2">
      <h2 class="mb-0">{{ getTitleName() }}</h2>
      <div class="d-flex flex-column">
        <button
          *ngIf="
            doctorProfile != null &&
            !doctorProfile.isMedicalRegistrationVerified &&
            currentHeroInfo != null &&
            currentHeroInfo != null &&
            currentHeroInfo.hasApproverPermission() &&
            (!fragment || fragment == 'verify')
          "
          class="d-flex btn btn-sm btn-outline-success border-0 me-2"
          (click)="markAsverifiedClicked()"
        >
          Mark as Verified
        </button>
        <h6
          *ngIf="isMedicalRegistrationVerified()"
          class="d-flex me-2 text-success"
        >
          Medical Registration Verified
        </h6>
      </div>
    </div>
    <div
      class="d-flex flex-row justify-content-between mb-3"
      *ngIf="!fragment || fragment == 'upload'"
    >
      <!-- <span class="me-2">Upload Documents</span> -->
      <!-- <label for="reg-files-upload" class="file_style">
        Upload Documents
      </label> -->
      <input
        type="file"
        id="reg-files-upload"
        name="Upload Documents"
        (change)="fileProgress($event)"
        accept="image/x-png,image/jpeg,application/pdf"
        multiple
      />
    </div>

    <div
      *ngIf="
        doctorProfile != null &&
        doctorProfile.getMedicalRegistrationProofUrlsArray().length > 0
      "
      class="d-flex flex-column mt-2"
      id="currentDocs"
    >
      <div
        class="mb-2 d-flex flex-row align-items-center"
        *ngFor="
          let fileInfo of doctorProfile.getMedicalRegistrationProofUrlsArray();
          let i = index
        "
      >
        <a target="_blank" [href]="fileInfo.url">
          Uploaded Document #{{ i + 1 }}</a
        >

        <div>
          <button
            (click)="openImage(fileInfo.url)"
            class="ms-2 btn btn-sm btn-outline-info border-0"
          >
            <span class="material-icons"> remove_red_eye </span>
          </button>
        </div>
        <div>
          <button
            (click)="removeDocument(fileInfo)"
            class="ms-2 btn btn-sm btn-outline-danger border-0"
            [disabled]="isMedicalRegistrationVerified()"
          >
            <span class="material-icons"> delete </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="d-flex justify-content-between align-items-start mt-2">
  <div id="note">
    To verify a medical registration, visit:
    <a
      target="_blank"
      href="https://www.nmc.org.in/information-desk/indian-medical-register"
    >
      Indian Medical Register Search
    </a>
  </div>
  <div
    *ngIf="
      doctorProfile != null &&
      !doctorProfile.isMedicalRegistrationVerified &&
      currentHeroInfo != null &&
      currentHeroInfo != null &&
      currentHeroInfo.hasApproverPermission()
    "
    class="d-flex flex-row align-items-center"
  >
    <input
      type="checkbox"
      id="can-not-verify-medical-registration"
      name="can-not-verify-medical-registration"
      class="me-2"
      checked="false"
      title="Can not verify"
      (ngModelChange)="
        checkboxConfirmationDialog(
          internTags['cant_verify_medical_registration']['key'],
          $event
        )
      "
      [(ngModel)]="isMedicalRegistrationCannotBeVerified"
    />
    <span for="can-not-verify-medical-registration">{{
      internTags["cant_verify_medical_registration"]["value"]
    }}</span>
  </div>
</div>

<div
  id="education"
  class="m-1 mt-4 border p-2"
  style="margin-bottom: 50px"
  *ngIf="!fragment"
>
  <div class="d-flex justify-content-between align-items-center py-2">
    <h2 class="mb-0">Education</h2>
    <div>
      <button
        class="d-flex btn btn-sm btn-outline-primary border-0"
        (click)="addNewEducationRow()"
      >
        + Add new
      </button>
    </div>
  </div>

  <div
    class="form-row form-group required d-flex flex-column w-100"
    style="padding: 8px 12px"
    *ngFor="let education of educationDetails; let i = index"
  >
    <div class="mb-2">
      <label for="degree" class="control-label">Degree</label>
      <ng-select
        [(ngModel)]="educationDetails[i].degree"
        maxSelectedItems="1"
        placeholder="Degree"
        [addTag]="addCustomDegree"
        (input)="setForDegreeSearch($event)"
      >
        <ng-option *ngFor="let d of degreeMasterList" [value]="d.title">
          {{ d.title }}
        </ng-option>
      </ng-select>
    </div>
    <div class="d-flex">
      <div style="width: 70%">
        <label for="college">College</label>
        <ng-select
        [(ngModel)]="educationDetails[i].instituteName"
        maxSelectedItems="1"
        [addTag]="addCustomCollege"
        placeholder="College"
        (input)="setForCollegeSearch($event)"
        >
        <ng-option *ngFor="let d of collegeMasterList" [value]="d.title">
          {{ d.title }}
        </ng-option>
      </ng-select>
      </div>
      <div class="col-2">
        <label for="year">Year</label>
        <input
          type="number"
          class="form-control"
          placeholder="Year"
          [id]="'year' + i"
          [(ngModel)]="educationDetails[i].yearOfPassing"
          [value]="education.yearOfPassing"
        />
      </div>
      <div class="col-1">
        <label for=""></label>
        <button
          class="d-flex btn btn-sm btn-outline-danger border-0 mt-2"
          style="margin-left: auto"
          (click)="deleteEducationRow(i)"
        >
          <span class="material-icons"> delete </span>
        </button>
      </div>
    </div>
  </div>
</div>
<ngx-spinner></ngx-spinner>

<div
  class="modal fade"
  id="imageModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="imageModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="imageModalLabel">Image Preview</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <lib-ngx-image-zoom
          [thumbImage]="myFullresImage"
          [fullImage]="myFullresImage"
        ></lib-ngx-image-zoom>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
