<button class="btn btn-sm btn-outline-primary" (click)="setPatientFromSearch()">
    {{btnText}}
</button>

<ng-template #patientInfo let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        🏥Patient Info 
      </h4>
      <div class="ms-auto d-flex align-items-center">
        <app-book-appointment [patient]="modalPatientProfile" class="me-1"></app-book-appointment>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="w-100 d-flex justify-content-center" *ngIf="isLoadingPatient">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div class="modal-body d-flex flex-column position-relative" *ngIf="!isLoadingPatient&&modalPatientProfile.docvitaId">
      <!-- <app-patient-registration [patient]="modalPatientProfile" class="position-absolute" style="top: 0.5rem;right:0.5rem"></app-patient-registration> -->
      <div style="width: fit-content;" class="mb-1 cursor-pointer" (click)="copy(modalPatientProfile.name)">Name : {{modalPatientProfile.name?modalPatientProfile.name:"-"}}</div>
      <div class="mb-1 ">Gender : {{modalPatientProfile.gender?modalPatientProfile.gender:"-"}}</div>
      <div style="width: fit-content;" class="mb-1 cursor-pointer" (click)="copyContact(modalPatientProfile)">Contact : {{patientContactInfo(modalPatientProfile)?('+'+patientContactInfo(modalPatientProfile).countryCode+'-'+patientContactInfo(modalPatientProfile).number):"-"}}</div>
      <div style="width: fit-content;" class="mb-1 cursor-pointer" (click)="copy(modalPatientProfile.primaryEmail)">Email : {{modalPatientProfile.primaryEmail?modalPatientProfile.primaryEmail:"-"}}</div>
      <div style="width: fit-content;" class="mb-1 cursor-pointer" (click)="copy(modalPatientProfile.docvitaId)">DocvitaId : {{modalPatientProfile.docvitaId?modalPatientProfile.docvitaId:""}}</div>
      <div class="w-100 mt-1 mb-1" style="height: 1.2px; background-color:rgb(224, 224, 224)"></div>
      <div class="mb-1"><b>Appointments:</b></div>
      <span *ngIf="isLoadingAppointments" class="spinner-border spinner-border-sm me-2" role="status"
          aria-hidden="true"></span>
      <div *ngIf="!isLoadingAppointments&&allAppointments.length==0">💤 No appointments</div>
      <div class="d-flex flex-column font-14 fw-400 fc-grey" *ngIf="!isLoadingAppointments">
        <ul style="max-height: 200px;overflow-y: scroll;">
          <li *ngFor="let appointment of allAppointments; let i = index;" class="w-100 d-flex align-items-center mb-1">
            {{appointment.getShortDate()}}, {{appointment.getStartTimeIn12HoursFormat()}} with
            {{appointment.doctorName}} <span class="text-info">{{appointment.amount?'(₹ '+appointment.amount+')':''}}</span> 
            <span *ngIf="appointment.status" class="badge bg-primary ms-2" [ngClass]="{'bg-danger': appointment.status=='CANCELLED'}">{{appointment.status|lowercase}}</span>
            <span class="badge bg-info cursor-pointer ms-auto me-1" (click)="openAppointmentInNewTab(appointment)">Open</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="modal-footer">
      
    </div>
  </ng-template>