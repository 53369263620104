import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { environment } from 'src/environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgSelectModule } from '@ng-select/ng-select';
import firebase from 'firebase';
import { HttpTokenInterceptorService} from './services/http-token-interceptor.service';
import { DocProfileListingComponent } from './components/doc-profile-listing/doc-profile-listing.component';
import { DocProfileBasicInfoComponent } from './components/doc-profile-basic-info/doc-profile-basic-info.component';
import { DocProfileEducationComponent } from './components/doc-profile-education/doc-profile-education.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DocProfilePracticesComponent } from './components/doc-profile-practices/doc-profile-practices.component';
import { ToastrModule } from 'ngx-toastr';
import { DocProfileClaimComponent } from './components/doc-profile-claim/doc-profile-claim.component';
import { HomeComponent } from './components/home/home.component';
import { DoctorBasicUpdateListingComponent } from './components/doctor-basic-update-listing/doctor-basic-update-listing.component';
import { DoctorEducationUpdateListingComponent } from './components/doctor-education-update-listing/doctor-education-update-listing.component';
import { SharedModule } from './shared/shared.module';
import { ManageLoginCredentialsComponent } from './components/manage-login-credentials/manage-login-credentials.component';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { ManageScheduleInterfaceModule } from './manage-schedule-interface/manage-schedule-interface.module';
import { PhotoUploadListingComponent } from './components/photo-upload/photo-upload-listing/photo-upload-listing.component';
import { PhotoUploadDetailsComponent } from './components/photo-upload/photo-upload-details/photo-upload-details.component';
import { VerifyApproveComponent } from './components/form-control/verify-approve/verify-approve.component';
import { PhoneEmailComponent } from './components/form-control/phone-email/phone-email.component';
import { ContactNumbersComponent } from './components/form-control/contact-numbers/contact-numbers.component';
import { EmailsComponent } from './components/form-control/emails/emails.component';
import { DocProfilePaymentsComponent } from './components/doc-profile-payments/doc-profile-payments.component';
import { DocBlockedSlotsComponent } from './components/doc-blocked-slots/doc-blocked-slots.component';
import { DocSlotsComponent } from './components/doc-slots/doc-slots.component';
import { DocOpenSlotsComponent } from './components/doc-open-slots/doc-open-slots.component';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { DocProfileNewSlotsComponent } from './components/doc-profile-new-slots/doc-profile-new-slots.component';
import { DocMembershipPlansComponent } from './components/doc-membership-plans/doc-membership-plans.component';
import { DocMembershipPlanModifyComponent } from './components/doc-membership-plans/components/doc-membership-plan-modify/doc-membership-plan-modify.component';
import { DocMembershipPlanGeneratorComponent } from './components/doc-membership-plans/components/doc-membership-plan-generator/doc-membership-plan-generator.component';
import { DocProfileModuleConfigsComponent } from './components/doc-profile-module-configs/doc-profile-module-configs.component';
import { DocProfileFeeConfigComponent } from './components/doc-profile-fee-config/doc-profile-fee-config.component';
import { ProviderAvailabilityComponent } from './components/provider-availability/provider-availability.component';

@NgModule({
  declarations: [
    AppComponent,
    DocProfileListingComponent,
    DocProfileBasicInfoComponent,
    DocProfileEducationComponent,
    DocProfilePracticesComponent,
    DocProfileClaimComponent,
    HomeComponent,
    DoctorBasicUpdateListingComponent,
    DoctorEducationUpdateListingComponent,
    ManageLoginCredentialsComponent,
    PhotoUploadListingComponent,
    PhotoUploadDetailsComponent,
    VerifyApproveComponent,
    PhoneEmailComponent,
    ContactNumbersComponent,
    EmailsComponent,
    DocProfilePaymentsComponent,
    DocBlockedSlotsComponent,
    DocSlotsComponent,
    DocOpenSlotsComponent,
    DocProfileNewSlotsComponent,
    DocMembershipPlansComponent,
    DocMembershipPlanModifyComponent,
    DocMembershipPlanGeneratorComponent,
    DocProfileModuleConfigsComponent,
    DocProfileFeeConfigComponent,
    ProviderAvailabilityComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    HttpClientModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
    }),
    SharedModule,
    NgxImageZoomModule,
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    // TooltipModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    ManageScheduleInterfaceModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptorService,
      multi: true
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    if (firebase.apps.length == 0) {
      this.initializeApp();
    }
  }

  initializeApp(): void {
    firebase.initializeApp(environment.firebaseConfig);
  }
}
