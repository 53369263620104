import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthUserInfoService } from 'src/app/shared/services/auth-user-info/auth-user-info.service';
import firebase from 'firebase';
import { CRMUser } from '../shared/models-ts/Actors/User/CRMUser';
import * as dayjs from 'dayjs';
import { plainToClass } from 'class-transformer';
import { HealthHeroAccessLevels } from 'src/app/shared/models-ts/Relationships/HealthHeroInfo';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { PaginationModule } from 'ngx-bootstrap/pagination';

@Component({
  selector: 'app-crm-hero-list',
  templateUrl: './crm-hero-list.component.html',
  styleUrls: ['./crm-hero-list.component.css'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CommonModule, PaginationModule, SharedModule]
})
export class CrmHeroListComponent implements OnInit {
  title = 'CRM Hero List';
  heroes: CRMUser[] = [];
  page: number = 0;
  pageSize: number = 10;
  fetching = false;
  fetchStats = false;
  currentSelectedHero: CRMUser;
  selectedRoles: string[] = [];
  roles: string[] = [
    'inputter',
    'verifier',
    'approver',
    'admin',
    'docvita_team',
    'docvita_admin',
    'crm_access',
  ];
  createDoctorProfileOnNewRecommendation: boolean = false;
  hero_logs: any[] = [];
  currentSelectedHeroStats: {} = {};
  access: {} = {
    canAccessDashboard: false,
    canAccessDoctorNetwork: false,
    canAccessMedicalRegList: false,
    canAccessBasicInfoList: false,
    canAccessPhotoUploadList: false,
    canManageClaims: false,
    canAssignProfiles: false,
    canManageCredentials: false,
    canAccessClinicNetwork: false,
    canAccessHospitalNetwork: false,
  };
  // hoveredDate: NgbDate | null = null;

  // fromDate: NgbDate | null;
  // toDate: NgbDate | null;
  startTimestamp: number;
  endTimestamp: number;
  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _authUserInfoService: AuthUserInfoService,
    private _toastr: ToastrService
  ) // private calendar: NgbCalendar,
  // public formatter: NgbDateParserFormatter
  {}

  ngOnInit(): void {
    this.getCRMUsers();
  }

  // getCurrentRange() {
  //   this.fromDate = this.calendar.getToday();
  //   this.toDate = this.calendar.getNext(this.calendar.getToday(), 'd', 1);
  //   this.startTimestamp = new Date(
  //     this.formatter.format(this.fromDate)
  //   ).getTime();
  //   this.endTimestamp = new Date(this.formatter.format(this.toDate)).getTime();
  // }

  // onDateSelection(date: NgbDate) {
  //   if (!this.fromDate && !this.toDate) {
  //     this.fromDate = date;
  //   } else if (
  //     this.fromDate &&
  //     !this.toDate &&
  //     date &&
  //     date.after(this.fromDate)
  //   ) {
  //     this.toDate = date;
  //   } else {
  //     this.toDate = null;
  //     this.fromDate = date;
  //   }
  //   this.startTimestamp = new Date(
  //     this.formatter.format(this.fromDate)
  //   ).getTime();
  //   this.endTimestamp = new Date(this.formatter.format(this.toDate)).getTime();
  //   this.getStats();
  // }

  // isHovered(date: NgbDate) {
  //   return (
  //     this.fromDate &&
  //     !this.toDate &&
  //     this.hoveredDate &&
  //     date.after(this.fromDate) &&
  //     date.before(this.hoveredDate)
  //   );
  // }

  // isInside(date: NgbDate) {
  //   return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  // }

  // isRange(date: NgbDate) {
  //   return (
  //     date.equals(this.fromDate) ||
  //     (this.toDate && date.equals(this.toDate)) ||
  //     this.isInside(date) ||
  //     this.isHovered(date)
  //   );
  // }

  // validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
  //   const parsed = this.formatter.parse(input);
  //   return parsed && this.calendar.isValid(NgbDate.from(parsed))
  //     ? NgbDate.from(parsed)
  //     : currentValue;
  // }

  backClicked() {
    this._router.navigate(['../'], { relativeTo: this._activatedRoute });
  }

  getCRMUsers() {
    this.fetching = true;
    firebase
      .firestore()
      .collection('crm-users')
      .get()
      .then((heroes) => {
        heroes.forEach((hero) => {
          this.heroes.push(plainToClass(CRMUser, hero.data()));
        });
        this.fetching = false;
      });
  }

  getHeroActionLogs() {
    this.fetchStats = true;
    firebase
      .database()
      .ref('health_hero_actions_log')
      .orderByChild('healthHeroId')
      .equalTo(this.currentSelectedHero.docvitaId)
      .once('value')
      .then((snapshshot) => {
        this.hero_logs = [];
        snapshshot.forEach((data) => {
          this.hero_logs.push(data.val());
        });
        this.fetchStats = false;
        // this.getStats();
      });
  }

  // getStats() {
  //   let typeMap = {
  //     profilePhotoUpload: 'Profile Photo Upload',
  //     medicalRegistrationFileUpload: 'Medical Registration Upload',
  //   };
  //   this.currentSelectedHeroStats = {};
  //   for (let log of this.hero_logs) {
  //     let type = typeMap[log.type];
  //     if (Number.isNaN(this.endTimestamp)) {
  //       // let currentSelectedDate = this.formatter.format(this.fromDate);

  //       // let date1 = new Date(currentSelectedDate);
  //       let date2 = new Date(dayjs(log.createdOn).format('YYYY-MM-DD'));
  //       if (date1.getTime() == date2.getTime()) {
  //         if (this.currentSelectedHeroStats[type]) {
  //           this.currentSelectedHeroStats[type] += log.noOfFiles;
  //         } else {
  //           this.currentSelectedHeroStats[type] = log.noOfFiles;
  //         }
  //       }
  //     } else if (
  //       this.startTimestamp <= log.createdOn &&
  //       this.endTimestamp >= log.createdOn
  //     ) {
  //       if (this.currentSelectedHeroStats[type]) {
  //         this.currentSelectedHeroStats[type] += log.noOfFiles;
  //       } else {
  //         this.currentSelectedHeroStats[type] = log.noOfFiles;
  //       }
  //     }
  //   }
  // }

  onSelectedHeroClicked(hero) {
    this.currentSelectedHero = hero;
    this.getHeroActionLogs();
    this.selectedRoles = [];
    this.access = {
      canAccessDashboard: false,
      canAccessDoctorNetwork: false,
      canAccessMedicalRegList: false,
      canAccessBasicInfoList: false,
      canAccessPhotoUploadList: false,
      canManageClaims: false,
      canAssignProfiles: false,
      canManageCredentials: false,
      canAccessClinicNetwork: false,
      canAccessHospitalNetwork: false,
    };
    this.createDoctorProfileOnNewRecommendation = false;
    this.currentSelectedHeroStats = {};
    if (
      this.currentSelectedHero.roles &&
      this.currentSelectedHero.roles.length > 0
    ) {
      this.selectedRoles = this.currentSelectedHero.roles;
    }
    if (
      this.currentSelectedHero
        .createDoctorProfileOnNewRecommendation === true
    ) {
      this.createDoctorProfileOnNewRecommendation = true;
    }
    if (
      this.currentSelectedHero.access &&
      Object.keys(this.currentSelectedHero.access).length > 0
    ) {
      for (let key in this.currentSelectedHero.access) {
        if (this.currentSelectedHero.access[key] === true) {
          this.access[key] = true;
        }
      }
    }
  }

  onUpdatePermissions() {
    let updateJson = {
      roles: this.selectedRoles,
      createDoctorProfileOnNewRecommendation:
        this.createDoctorProfileOnNewRecommendation,
      access: this.access,
    };
    firebase
      .firestore()
      .collection('crm-users')
      .doc(this.currentSelectedHero.docvitaId)
      .set(updateJson, { merge: true })
      .then(() => {
        this.currentSelectedHero.roles = this.selectedRoles;
        this.currentSelectedHero.createDoctorProfileOnNewRecommendation =
          this.createDoctorProfileOnNewRecommendation;
        this.currentSelectedHero.access = plainToClass(HealthHeroAccessLevels, this.access);
        this._toastr.success('Updated Successfully');
      });
  }
}
