import { Component, Input, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AppointmentService } from '../../services/appointment/appointment.service';
import { DoctorsService } from '../../services/doctors/doctors.service';
import {
  LeadPayout,
  LeadPayoutStatus,
} from 'src/app/shared/models-ts/Entities/LeadPayout';
import { Lead } from '../../models-ts/Entities/Lead';
@Component({
  selector: 'app-make-refund',
  templateUrl: './make-refund.component.html',
  styleUrls: ['./make-refund.component.css'],
})
export class MakeRefundComponent implements OnInit {
  @Input() leadPayout: LeadPayout;
  @Input() leadDetails: Lead;
  refundAmount: number;
  constructor(
    private modalService: BsModalService,
    private _toastrService: ToastrService,
    private _doctorService: DoctorsService,
    private _appointmentService: AppointmentService
  ) {}

  ngOnInit(): void {}
  openModal(content) {
    this.modalService.show(content, { class: 'modal-md', backdrop: 'static' });
    this.refundAmount = this.leadDetails.consultationInfo.totalAmountPaid;
  }

  closeModal() {
    this.modalService.hide();
  }

  initiateRefund() {}
}
