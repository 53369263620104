<button class="btn btn-sm btn-outline-primary border-0 d-flex justify-content-center align-items-center"
  *ngIf="showButton" (click)="openModal();">
  <i style="font-size: 15px" class="material-icons me-1">
    insert_invitation
  </i>
  New Invite
</button>

<ng-template #inviteModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">
      {{updateMode?"Update Invite":"Create Invitation"}}
    </h4>
    <div class="ms-auto d-flex align-items-center">
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body d-flex flex-column" style="position: relative;">
    <div class="doctor-appt-details doctor-appt-details-card">
      <provider-appointments [doctorProfile]="doctorProfile" [date]="date"></provider-appointments>
      <provider-patient-appointments [doctorProfile]="doctorProfile" [patientProfile]="patientProfile"
        (listFetchState)="manageListFetchState($event)" class="mt-3">
      </provider-patient-appointments>
    </div>

    <ng-container *ngIf="!updateMode">
      <div class="fw-600">
        {{
        !patientProfile || !patientProfile.docvitaId
        ? "Select Client:"
        : "Client:"
        }}
      </div>
      <app-patient-search [patientId]="patientId" (getPatient)="setPatientFromSearch($event)"></app-patient-search>

      <new-provider-select [patientProfile]="patientProfile" [providerId]="doctorId"
        (getProvider)="setProviderFromSearch($event)" class="mt-3"></new-provider-select>

      <div *ngIf="
        doctorProfile &&
        doctorProfile.id &&
        doctorProfile.onlineConsultationConfig
      " class="standard-details-container">
        <span *ngIf="doctorProfile.onlineConsultationConfig.fee"><b>Std. fees:</b> {{ standardNatInNatPricing }}</span>
        <span *ngIf="doctorProfile.appointmentDuration"><b>Std. duration:</b> {{ doctorProfile.appointmentDuration }}
          mins</span>
      </div>
    </ng-container>
    <ng-container *ngIf="updateMode">
      <div class="card rounded mt-1 p-2" *ngIf="selectedInvite&&selectedInvite.id">
        <h6>Invite Details:</h6>
        <hr class="m-1 p-0">
        <div class="d-flex flex-column" style="font-size: 0.8em; row-gap: 0.25rem;">
          <div><span style="font-weight: 500;">Patient Name:</span> {{ invpatientName}}</div>
          <div><span style="font-weight: 500;">Doctor Name:</span> {{ invdoctorName}}</div>
          <div><span style="font-weight: 500;">Date & Time:</span> {{invDateTime}}</div>
          <div><span style="font-weight: 500;">Amount:</span> {{invAmt}}</div>
          <div><span style="font-weight: 500;">Duration:</span> {{invDuration}}</div>
        </div>
      </div>
    </ng-container>

    <app-appt-type-selector [provider]="doctorProfile"
      (selection)="selectApptTypeAndPractice($event)"></app-appt-type-selector>
    <app-session-type-selector [params]="{provider: doctorProfile,selectedMembershipPlan: null, selection: currentSessionType}"
      (selection)="selectSessionType($event)"></app-session-type-selector>
    <div style="font-weight:500;font-size: 0.9em;" class="mt-3">Invite Type:</div>
    <ng-select class="w-100" placeholder="Select type" [(ngModel)]="inviteType">
      <ng-option [value]="type" *ngFor="let type of inviteTypes">{{getInviteType(type)}}</ng-option>
    </ng-select>
    <div class="d-flex mt-3">
      <div class="d-flex flex-column position-relative me-3">
        <div style="font-weight: 500">Amount({{currencyToSymbol(currency)}}):</div>
        <div class="d-flex flex-column">
          <input type="number" placeholder="Amount" class="form-control" style="width: 180px" [(ngModel)]="amount" />
          <currency-dropdown class="ms-auto mt-2" [defaultValue]="currency" (selectedCurrency)="setCurrency($event)">
          </currency-dropdown>
        </div>
      </div>
      <div class="ms-5">
        <div style="font-weight: 500">Duration:(Min)</div>
        <input type="number" class="form-control" style="width: 180px" [(ngModel)]="duration" />
        <div class="d-flex mt-1" style="font-size: 14px">
          <span class="me-2 p-1 card slot mb-0" *ngFor="let min of [15, 30, 45]" (click)="setDuration(min)">{{ min }}
            min</span>
        </div>
      </div>
    </div>

    <div class="d-flex mt-3">
      <div class="d-flex flex-column">
        <div class="d-flex" style="font-weight: 500; width: 180px" tooltip="Date of the appointment">
          <div>Date:</div>
          <div class="text-muted ms-auto">
            {{ DateInDay }}
          </div>
        </div>
        <div class="d-flex">
          <input type="date" class="form-control" style="width: 180px" [(ngModel)]="date" />
          <!-- date scroller arrows -->
          <div class="d-flex ms-1">
            <button class="btn btn-outline-primary btn-sm border-0 ps-0 pe-0" (click)="appointmentDateScroller('-')">
              <i class="feather icon-chevron-left" style="font-size: 16px"></i>
            </button>
            <button class="btn btn-outline-primary btn-sm border-0 ps-0 pe-0" (click)="appointmentDateScroller('+')">
              <i class="feather icon-chevron-right" style="font-size: 16px"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="ms-4" *ngIf="!showAvailableTimeslots || time">
        <div style="font-weight: 500">Time:</div>
        <input type="time" class="form-control" [disabled]="!allowTimeEdit" style="width: 180px" [(ngModel)]="time" />
      </div>
    </div>

    <div class="mt-3 w-100">
      <ng-container *ngIf="showAvailableTimeslots && doctorProfile && doctorProfile.id">
        <provider-available-slots [practiceId]="practiceSelection?.key" [doctorProfile]="doctorProfile" [date]="date" (selectedSlot)="selectSlot($event)"
          [disableContainer]="true" (allSlots)="allAvailableSlots($event)">
        </provider-available-slots>
        <button class="btn btn-outline-primary border-0 btn-sm w-100 mt-1" (click)="editTimeManually()">
          Choose another time
        </button>
      </ng-container>

      <button class="btn btn-outline-primary border-0 btn-sm w-100 mt-1"
        *ngIf="!showAvailableTimeslots && doctorProfile && doctorProfile.id" (click)="toggleProviderTimeslots(true)">
        Show available times
      </button>
    </div>

    <hr/>
    <div class="d-flex flex-row" id="schedule-invite-section" *ngIf="isScheduled">
      <div class="d-flex flex-column">
        <div>
          <h6>Schedule this invite?</h6>
          <!-- Add a switch here. -->
        </div>
        <div class="d-flex" style="font-weight: 500; width: 180px" tooltip="Date of the appointment">
          <div>Date:</div>
          <div class="text-muted ms-auto">
            {{ ScheduledForDateInDay }}
          </div>
        </div>
        <div class="d-flex">
          <input type="date" class="form-control" style="width: 180px" [(ngModel)]="scheduledFor.date" />
          <!-- date scroller arrows -->
          <div class="d-flex ms-1">
            <button class="btn btn-outline-primary btn-sm border-0 ps-0 pe-0" (click)="appointmentDateScroller('-', true)">
              <i class="feather icon-chevron-left" style="font-size: 16px"></i>
            </button>
            <button class="btn btn-outline-primary btn-sm border-0 ps-0 pe-0" (click)="appointmentDateScroller('+', true)">
              <i class="feather icon-chevron-right" style="font-size: 16px"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="ms-4">
        <div style="font-weight: 500">Time:</div>
        <input type="time" class="form-control" style="width: 180px" [(ngModel)]="scheduledFor.time" />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-dark me-2" (click)="closeModal()">
      Close
    </button>
    <button type="button" class="btn btn-outline-primary" (click)="createInvite()" *ngIf="!updateMode"
      [disabled]="sendingLoader">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="sendingLoader"></span>
      Send Invite
    </button>
    <button type="button" class="btn btn-outline-primary" (click)="modifyInvite()" *ngIf="updateMode"
      [disabled]="sendingLoader">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="sendingLoader"></span>
      Modify Invite
    </button>
  </div>
</ng-template>

<clashing-appointments-modal #apptClashModal></clashing-appointments-modal>