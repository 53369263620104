import { Expose, plainToClass, Transform, Type } from "class-transformer";
import { ContactNumber } from "../Actors/Person/ContactNumber";
import { DoctorPracticeRelationship } from "../Relationships/DoctorPracticeRelationship";
import { ProfileLinks } from "./ProfileLinks";

export class PracticeListingProfile {
  @Expose() id: string;
  @Expose() name: string;
  @Expose() type: string;
  @Expose() email: string;
  @Expose() tagline: string;
  @Expose() addressLine1: string;
  @Expose() addressLine2: string;
  @Expose() locality: string;
  @Expose() city: string;
  @Expose() pincode: string;
  @Expose() state: string;
  @Expose() country: string;
  @Expose() googleMapLink: string;
  @Expose() logoUrl: string;
  @Expose() establishmentYear: string;
  @Type(() => ContactNumber)
  @Expose()
  publicContactNumber: ContactNumber = new ContactNumber();

  @Type(() => ProfileLinks)
  @Expose()
  profileLinks: ProfileLinks = new ProfileLinks();

  @Expose() uniqueTag: string;

  @Expose()
  @Transform(
    (value) => {
      if (value) {
        let map = new Map<string, DoctorPracticeRelationship>();
        for (let entry of Object.entries(value))
          map.set(entry[0], plainToClass(DoctorPracticeRelationship, entry[1]));
        return map;
      } else {
        return new Map<string, DoctorPracticeRelationship>();
      }
    },
    { toClassOnly: true }
  )
  associatedDoctors: Map<string, DoctorPracticeRelationship>;

  fullAddress(): string {
    let str = "";
    if (this.addressLine1 != null && this.addressLine1.trim().length > 0) {
      str += this.addressLine1;
    }
    if (this.addressLine2 != null && this.addressLine2.trim().length > 0) {
      if(str.length > 0) str += ", " + this.addressLine2;
      else str += this.addressLine2;
    }
    if (this.locality != null && this.locality.trim().length > 0) {
      if(str.length > 0) str += ", " + this.locality;
      else str += this.locality;
    }
    if (this.city != null && this.city.length > 0) {
      if(str.length > 0) str += ", " + this.city;
      else str += this.city;
    }
    if (this.pincode != null && this.pincode.length > 0) {
      if(str.length > 0) str += ", " + this.pincode;
      else str += this.pincode;
    }
    if (this.state != null && this.state.length > 0) {
      if(str.length > 0) str += ", " + this.state;
      else str += this.state;
    }
    if (this.country != null && this.country.length > 0) {
      if(str.length > 0) str += ", " + this.country;
      else str += this.country;
    }
    return str;
  }

  localityAndCity(): string {
    let str = "";
    if (this.locality != null && this.locality.length > 0) {
      str += this.locality;
    }
    if (this.city != null && this.city.length > 0) {
      if (str.length > 0) {
        str += ", ";
      }
      str += this.city;
    }
    return str;
  }
}
