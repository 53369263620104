import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import {plainToClass} from 'class-transformer';
import { ToastrService } from 'ngx-toastr';
import { DoctorListingProfile } from 'src/app/shared/models-ts/Actors/Doctor/DoctorListingProfile';
import { ContactNumber } from 'src/app/shared/models-ts/Actors/Person/ContactNumber';
import { DoctorsService } from 'src/app/shared/services/doctors/doctors.service';
declare var $: any;

@Component({
  selector: 'app-contact-numbers',
  templateUrl: './contact-numbers.component.html',
  styleUrls: ['./contact-numbers.component.css']
})
export class ContactNumbersComponent implements OnInit {
  _profileData: DoctorListingProfile
  @Input() set profileData (val: DoctorListingProfile){
    if(val.contactNumbers!=undefined||this.profileData.contactNumbers!=null){
      this.contactNumbers = val.getContactNumbersWithExtension();
    }else{
      this.contactNumbers = new Array();
    }
    this._profileData = val;
  }
  get profileData(): DoctorListingProfile {
    return this._profileData;
  }
  @Output() contactMap:EventEmitter<any> = new EventEmitter<any>(); 

  contactNumbers: ContactNumber[] = new Array();
  valid: boolean = true;  

  type: string;
  isPrimaryContactNumber: boolean;
  isPublicContactNumber: boolean;
  isWhatsappContactNumber: boolean;
  source: string;
  displayNumber: string;
  curr_index:number;

  ngOnChanges(changes: SimpleChanges) {
    this.profileData = changes["profileData"].currentValue;
    // console.log("currentVlue",this.profileData)
  }

  constructor(private _toastrService: ToastrService,  private _doctorService: DoctorsService,) { }

  ngOnInit(): void {
    this.contactNumbers = [];
    if(this.profileData.contactNumbers!=undefined||this.profileData.contactNumbers!=null){
      this.contactNumbers = this.profileData.getContactNumbersWithExtension();
    }
  }

  addPhoneField(){
    let newContact = new ContactNumber();
    newContact['countryCode'] = '91';
    this.contactNumbers.push(newContact);
  }
  deletePhoneField(i){
    let countryCode = this.contactNumbers[i].countryCode;
    let numberToDelete = this.contactNumbers[i].number;
    if(this.profileData.contactNumbers&&numberToDelete!=undefined&&this.profileData.contactNumbers.get(countryCode).has(numberToDelete.toString())){
      this.contactNumbers.splice(i,1);
      this._doctorService.deleteContactNumber(this.profileData.id,countryCode,numberToDelete).then(res=>{
            this._toastrService.success('✨Deleted Contact info!');
      }).catch(err=>{
        console.log(err)
          this._toastrService.error('Failed to delete!')
      })
    }else{
      this.contactNumbers.splice(i,1);
    }
  }
  print(){
    console.log(this.contactNumbers)
    this.getContactMap()
  }

  getContactMap(){
    let phoneMap = new Map<string, Map<string,ContactNumber>>();
    if(this.contactNumbers && this.contactNumbers.length>0){
      
      for (let phone of this.contactNumbers){ 
        if(phone.number.toString().length<5){
            this._toastrService.warning("Invalid phone number!")
            this.valid = false;
            break;
        } else{
          this.valid = true;
          let map = new Map<string,ContactNumber>();
          phone.number = phone.number+""
          phone.countryCode = phone.countryCode+""
          if(phoneMap.get(phone.countryCode)){
            map = phoneMap.get(phone.countryCode)
          }
          map.set(phone.number,phone);
          phoneMap.set(phone.countryCode,map)
        }
      }
      
    }else{
      phoneMap = null;
    }
    // console.log('phone map',phoneMap);
    this.contactMap.emit({contactNumbers:phoneMap,valid:this.valid});
  }

  // openModal(phone,index){
  //   this.displayNumber = phone['number']?phone['number']:'';
  //   this.type = phone['type']?phone['type']:'';
  //   this.isPrimaryContactNumber = phone['isPrimaryContactNumber']?phone['isPrimaryContactNumber']:false;
  //   this.isPublicContactNumber = phone['isPublicContactNumber']?phone['isPublicContactNumber']:false;
  //   this.isWhatsappContactNumber = phone['isWhatsappContactNumber']?phone['isWhatsappContactNumber']:false;
  //   this.source = phone['source']?phone['source']:'';
  //   this.curr_index = index;
  //   console.log("index is",this.curr_index)
  //   $('#editContacts').modal('show');
  // }

  // saveChangesOfModal(){
  //   console.log("index is",this.curr_index)
  //   this.contactNumbers[this.curr_index]['type'] = this.type==undefined?"mobile":this.type;
  //   this.contactNumbers[this.curr_index]['source'] = this.source;
  //   this.contactNumbers[this.curr_index]['isPrimaryContactNumber'] = this.isPrimaryContactNumber;
  //   this.contactNumbers[this.curr_index]['isPublicContactNumber'] = this.isPublicContactNumber;
  //   this.contactNumbers[this.curr_index]['isWhatsappContactNumber'] = this.isWhatsappContactNumber;
  //   this._toastrService.warning('Saved changes locally!');
  //   $('#editContacts').modal('hide');
  // }

}
