import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as urls from '../../../../environments/environment';
import { CashgramRefund } from '../../models-ts/Entities/CashgramRefund/CashgramRefund';
import { classToPlain } from 'class-transformer';
import firebase from 'firebase';
import 'firebase/firestore';
import { RefundSource } from '../../models-ts/Enums/pay';
@Injectable({
  providedIn: 'root',
})
export class CashgramRefundsService {
  constructor(private _http: HttpClient) {}
  createRefundLink(body: CashgramRefund) {
    return new Promise((resolve, reject) => {
      let _body = JSON.parse(JSON.stringify(classToPlain(body)));
      let url = urls.environment['cashgram-refund-create'];
      this._http
        .post(url, _body)
        .toPromise()
        .then((res) => {
          if (
            res &&
            res['statusCode'] == 200 &&
            res['body'] &&
            res['body']['success'] == true &&
            res['body']['data']
          ) {
            resolve(res['body']['data']);
          } else {
            resolve(null);
          }
        })
        .catch((err) => {
          console.log(err);
          resolve(null);
        });
    });
  }

  getRefundStatus(cashgramId: string) {
    return new Promise((resolve, reject) => {
      let url = urls.environment['cashgram-refund-status'] + '?cashgramId=' + cashgramId;
      this._http
        .get(url)
        .toPromise()
        .then((res) => {
          if (
            res &&
            res['statusCode'] == 200 &&
            res['body'] &&
            res['body']['success'] == true &&
            res['body']['data']
          ) {
            resolve(res['body']['data']);
          } else {
            resolve(null);
          }
        })
        .catch((err) => {
          resolve(null);
        });
    });
  }

  deactivateCashgramLink(cashgramId: string) {
    return new Promise((resolve, reject) => {
      let url =
        urls.environment['cashgram-refund-deactivate'] + '?cashgramId=' + cashgramId;
      this._http
        .get(url)
        .toPromise()
        .then((res) => {
          if (
            res &&
            res['statusCode'] == 200 &&
            res['body'] &&
            res['body']['success'] == true
          ) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((err) => {
          resolve(false);
        });
    });
  }

  getRefundLinks(leadId?: string) {
    let ref = firebase
      .firestore()
      .collection('cashgram_refund_links')
      .orderBy('createdOn', 'desc');
    if (leadId) {
      ref = ref.where('leadId', '==', leadId);
    }
    // if (appointmentId) {
    //   ref = ref.where('appointmentId', '==', appointmentId);
    // }
    // if (clientId) {
    //   ref = ref.where('clientId', '==', clientId);
    // }
    return ref;
  }

  async checkIfAnyActiveCGLinks(leadId:string){
    try {
      const snap = await firebase.firestore().collection('cashgram_refund_links').where('leadId', '==', leadId).where('status','==','CREATED').get();
      if(!snap.empty){
        return true;
      }else{
        return false;
      }
    } catch (error) {
      console.log(error)
      return false;
    }
  }

  generateLedgerEntriesForRedeemedRefund(leadId: string, clientId: string,amount: number, 
    transferId: string, source: RefundSource, createdOn?:number) {
    let url = urls.environment["actual-refund-for-lead"];
    let body: any = {leadId, clientId, amount, transferId, source, createdOn};
    return this._http.post(url, body).toPromise();
  }
}
