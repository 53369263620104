import { Expose } from "class-transformer";

export class Address {
  @Expose()
  adressId: string;

  @Expose()
  line1: string;

  @Expose()
  line2: string;

  @Expose()
  city: string;

  @Expose()
  state: string;

  @Expose()
  zipCode: string;

  @Expose()
  country: Number;

  @Expose()
  locality: string;

  @Expose()
  googleMapLocationUrl: string;

  @Expose()
  primaryContactPhone: string;
}
