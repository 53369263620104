import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DoctorListingProfile } from '../../models-ts/Actors/Doctor/DoctorListingProfile';
import { User } from '../../models-ts/Actors/User/User';
import { AuthUserInfoService } from '../../services/auth-user-info/auth-user-info.service';
import { DoctorsService } from '../../services/doctors/doctors.service';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { CRMUser } from '../../models-ts/Actors/User/CRMUser';
declare var $: any;
@Component({
  selector: 'app-profile-photo',
  templateUrl: './profile-photo.component.html',
  styleUrls: ['./profile-photo.component.css'],
})
export class ProfilePhotoComponent implements OnInit {
  _listingProfile: DoctorListingProfile = new DoctorListingProfile();
  @Input() set listingProfile(val: DoctorListingProfile) {
    this.currentPhotoUrl = null;
    if (val.photoUrl) {
      this.currentPhotoUrl = val.photoUrl + '?rev=' + new Date().getTime();
    }
    this._listingProfile = val;
  }
  get listingProfile(): DoctorListingProfile {
    return this._listingProfile;
  }

  @Output()
  photoDetails: EventEmitter<any> = new EventEmitter<any>();

  imageChangedEvent: any = '';
  croppedImage: any = '';

  photoUrl: any;
  extension: any;
  logoUploaded = false;
  currentHeroInfo = new CRMUser();
  canvasRotation = 0;
  currentPhotoUrl: string;
  transform: ImageTransform = {};
  constructor(
    private _doctorService: DoctorsService,
    private _toastr: ToastrService,
    private _authUserInfoService: AuthUserInfoService
  ) {
    this.currentHeroInfo = this._authUserInfoService.getUserInfo();
  }

  ngOnInit(): void {}

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    $('#cropModal').modal('show');
  }

  clearFileSelection() {
    $("#files").val('');
  }

  imageCropped(event: ImageCroppedEvent) {
    console.log("imageCropped:event.base64")
    this.croppedImage = event.base64;
  }
  

  saveChanges() {
    this.closeModal();
    this.photoUrl = this.croppedImage;
    this.extension =
      '.' +
      this.photoUrl.substring(
        this.photoUrl.indexOf('/') + 1,
        this.photoUrl.indexOf(';base64')
      );
    this.photoDetails.emit({
      photoUrl: this.photoUrl,
      extension: this.extension,
    });
    this.uploadPhoto();
  }

  imageLoaded(image: HTMLImageElement) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  fileProgress(fileInput: any) {
    let self = this;
    let imageData;
    imageData = <File>fileInput.target.files[0];
    var mimeType = imageData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var myCanvas = document.createElement('canvas');
    var ctx = myCanvas.getContext('2d');
    var img = new Image();
    img.onload = function () {
      myCanvas.width = img.width;
      myCanvas.height = img.height;

      ctx.drawImage(img, 0, 0);

      self.photoUrl = myCanvas.toDataURL('image/jpeg');
      self.extension =
        '.' +
        self.photoUrl.substring(
          self.photoUrl.indexOf('/') + 1,
          self.photoUrl.indexOf(';base64')
        );
      self.uploadPhoto();
      self.logoUploaded = true;
    };

    img.src = URL.createObjectURL(fileInput.target.files[0]);
    fileInput.target.value = null;
  }

  uploadPhoto() {
    this._doctorService
      .uploadPhoto(this.photoUrl, this.listingProfile.id, this.extension)
      .subscribe((data) => {
        this.updateUserStats(
          this.currentHeroInfo.docvitaId,
          this.listingProfile.id,
          'photo'
        );
        this._toastr.success('Photo updated successfully');
      });
  }

  updateUserStats(heroId, docId, reqType) {
    this._doctorService
      .updateDocvitaUserStats(heroId, docId, reqType)
      .subscribe((data) => {
        if (data['statusCode'] == 200) {
          console.log('User stats updated!');
        } else {
          console.log('User stats did not update!');
        }
      });
  }

  onDeleteConfirmation(id) {
    $(id).modal('show');
  }

  deleteImage() {
    if (this.listingProfile && this.listingProfile.id) {
      this._doctorService
        .deleteProfileImage(this.listingProfile.id)
        .then(() => {
          $('#deleteImageModal').modal('hide');
          this._toastr.success('Profile image deleted Successfully');
        });
    }
  }

  closeModal() {
    $('#cropModal').modal('hide');
  }
}
