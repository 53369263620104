// Angular Service Example
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../environments/environment';
import { P2PParticipantInfo, P2PParticipantType } from "../shared/models-ts/Entities/P2PCall/P2PParticipantInfo";
import { classToPlain } from 'class-transformer';
import { CRMUser } from '../shared/models-ts/Actors/User/CRMUser';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class P2PCallService {

    constructor(private http: HttpClient, private _toastr: ToastrService) { }

    async callFromHQ(crmUser: CRMUser, to: P2PParticipantInfo) {
        let from = new P2PParticipantInfo();
        from.id = crmUser.docvitaId;
        from.type = P2PParticipantType.dvTeam;
        from.name = crmUser.name;
        from.phoneNumber = crmUser.primaryContactNumber.countryCode+crmUser.primaryContactNumber.number;
        let bool = confirm(`Are you sure you want to call ${to.name} (${to.type}) on this number: ${to.phoneNumber}?\nYou will recieve call on the following number first: ${from.phoneNumber}. Once you pick up - system will dial the ${to.type}'s number and connect you.`)
        if(bool) {
            this._toastr.info("Dialing it now...");
            await this.makeCall(from, to).toPromise();
            return;
        } else {
            return;
        }
    }

    makeCall(from: P2PParticipantInfo, to: P2PParticipantInfo): Observable<any> {
        const url = environment.cloudFunctionsUrl + "/p2pMakeCallAPI";
        return this.http.post(url, { "from": classToPlain(from), "to": classToPlain(to) });
    }
}
