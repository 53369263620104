import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss'],
})
export class InfoCardComponent implements OnInit {
  @Input() title: string = '';
  @Input() infoArray: [string, string][] = [];
  @Input() materialIconName: string;

  constructor() {}

  ngOnInit(): void {}

  isBool(data) {
    return typeof data === 'boolean';
  }

  yesNo(value) {
    if (value) {
      return 'Yes';
    } else {
      return 'No';
    }
  }
}
