<button class="btn btn-sm btn-outline-primary border-0" *ngIf="showButton" (click)="openModal();">
      Create Refund Link
</button>
  
  <ng-template #cgrmModal let-modal>
      <div class="modal-header">
        <h4 class="modal-title">
          Create Refund Link
        </h4>
        <div class="ms-auto d-flex align-items-center">
          <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div class="modal-body d-flex flex-column" style="position: relative;">
        <ng-container *ngIf="page==1">
            <!-- <div class="d-flex w-100 mb-1">
                <button class="btn btn-sm btn-primary ms-auto me-2" (click)="viewAllRefunds()">💸 View Refund Links </button>
            </div> -->
            <div class="ms-auto me-auto d-flex align-items-center">
                <img src="../../../../assets/Cashfree-Dark.svg" alt="cashfree banner" class="cg-banner">
                <div class="cg-title ms-2">
                    Cashgram
                </div>
            </div>
            

            <div style="font-weight:500;" class="mt-3">Patient:</div>
            <div class="d-flex align-items-center w-100">
                <app-patient-search [patientId]="patientId" (getPatient)="setPatient($event)" class="w-100">
                </app-patient-search>
                <!-- <button class="btn btn-sm btn-info ms-2" style="width: 10rem;" (click)="newPatientCreator()">+ New Patient</button> -->
            </div>
            <div class="mt-1 text-danger" style="font-size: 14px;" *ngIf="!isCountryCode91">
                *Client has an international contact number which is not supported by the refund service.
            </div>

            <div class="mt-3 d-flex align-items-center">
                <div class="fw-600">
                    Wallet :
                </div>
                <client-wallet class="ms-2" [patientId]="patientProfile.docvitaId" (walletVal)="getWalletBalance($event)"></client-wallet>
            </div>
        
            <div class="w-100 d-flex align-items-center mt-3">
                <div class="w-50">
                    <div style="font-weight:500;">Customer Name:</div>
                    <input type="text" placeholder="Name" class="form-control" [(ngModel)]="refundLinkForm['clientName']">
                </div>
                <div class="ms-2 w-50">
                    <div style="font-weight:500;">Customer Email: <span class="text-muted" style="font-size: 13px;">(optional)</span></div>
                    <input type="text" placeholder="Email" class="form-control" [(ngModel)]="refundLinkForm['clientEmail']">
                </div>
                
            </div>
            <div class="mt-3">
                <div style="font-weight:500;">Customer Phone:</div>
                <input type="tel" placeholder="Number" class="form-control" [(ngModel)]="refundLinkForm['clientPhone']">
            </div>
            <div class="d-flex flex-column" class="mt-3">
                <div style="width: 180px;">
                    <div style="font-weight:500;">Amount(₹):</div>
                    <input type="number" placeholder="Amount" class="form-control" [(ngModel)]="refundLinkForm['amount']">
                </div>
                
                <div class="form-check m-0 mt-2">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="debitFromWallet" [disabled]="disablePayViaWallet" id="flexCheckDefault">
                    <label class="form-check-label ms-1" for="flexCheckDefault">
                      Pay Via Wallet?
                    </label>
                </div>
            </div>
            
            <ng-container *ngIf="!debitFromWallet">
                <div style="font-weight:500;" class="mt-3">Description: <span class="text-muted" style="font-size: 13px;" >(optional)</span></div>
                <textarea rows="2" placeholder="Description" class="form-control" [(ngModel)]="refundLinkForm['remarks']"></textarea>
            </ng-container>

        </ng-container>
        <ng-container *ngIf="page==2">
            <div class="p-2 w-100 d-flex flex-column align-items-center justify-content-center">

                <div style="font-size: 18px;font-weight: 500;">🔗 Refund Link Created!</div>

                <!-- <div class="card rounded d-flex flex-column p-2 mt-2 mb-0 w-100" style="font-size: 15px;">
                    <div><span class="wt-500 me-2">Client:</span>{{this.refundLinkForm["clientName"]?this.refundLinkForm["clientName"]:""}}</div>
                    <div><span class="wt-500 me-2">Contact:</span>{{this.refundLinkForm["clientPhone"]?this.refundLinkForm["clientPhone"]:""}}</div>
                    <div><span class="wt-500 me-2">Email:</span>{{this.refundLinkForm["clientEmail"]?this.refundLinkForm["clientEmail"]:""}}</div>
                </div> -->
                
                <div class="input-group flex-fill mt-3 w-75">
                    <input type="text" [(ngModel)]="refundLink" class="form-control border-radius-0"
                        placeholder="Refund Link" aria-label="refund Link" [disabled]="true">
                    <button class="btn btn-outline-secondary border-radius-0" type="button"
                        (click)="copyRefundLink()"><i class="feather icon-copy"></i> Copy</button>
                </div>

                <div class="d-flex mt-4">
                    <button class="btn btn-sm btn-outline-dark d-flex align-items-center" (click)="initModalState()">
                        <i style="font-size: 14px;" class="material-icons me-1">chevron_left</i>
                        <span> New Refund link </span>
                    </button>
                    <button class="btn btn-sm btn-outline-primary ms-2 d-flex align-items-center" (click)="viewAllRefunds()">
                       <span> View all refunds </span>
                        <i style="font-size: 14px;" class="material-icons">chevron_right</i>
                    </button>
                </div>

            </div>
        </ng-container>

        <ng-container *ngIf="page==3">
            <div class="d-flex align-items-center mb-3" *ngIf="viewOnlyLinks==false">
                <button class="btn btn-sm btn-outline-dark border-0 me-2" (click)="initModalState()">❮</button>
            </div>
            <div class="list-container" *ngIf="cashgramRefunds&&cashgramRefunds.length>0&&!linksLoader">

                <div *ngFor="let item of cashgramRefunds" class="border rounded p-2 mt-1 mb-1 d-flex flex-column w-100" style="font-size: 14px;">
                    <div class="w-100 mb-2 d-flex align-items-center">
                        <div class="wt-500 border rounded p-1" style="color: #6930ca;width: fit-content;">
                            Cashgram
                        </div>
                        <span class="text-muted ms-2" *ngIf="!item.updateOn">{{item.createdOn?'Created On '+(item.createdOn|date:'dd/MM/yy, hh:mm a'):"-"}}</span>
                        <span class="text-muted ms-2" *ngIf="item.updateOn">{{item.createdOn?'Updated On '+(item.updatedOn|date:'dd/MM/yy, hh:mm a'):"-"}}</span>
                        <cashgram-status-check [cashgramId]="item.id" [showButton]="true" class="ms-auto"></cashgram-status-check>
                    </div>
                    <div class="w-100 d-flex align-items-center">
                        <span class="wt-500 text-success me-1">{{item.amount?("₹ "+item.amount):""}}</span>
                        <span class="wt-500">{{item.clientName?item.clientName:""}}</span>
                        <span *ngIf="item.status" [class]="getStatusStyle(item.status)+' ms-auto'">{{item.status | lowercase}}</span>
                    </div>
                    <div class="w-100 d-flex mt-2" style="font-size: 14px;">
                        <div class="text-muted" >
                            Contact: {{item.clientPhone?item.clientPhone:""}}
                            <i style="font-size: 12px;" class="material-icons ms-1 pointer text-primary" (click)="copy(item.clientPhone?item.clientPhone:'')">content_copy</i>
                        </div>
                        <div class="text-muted ms-auto" *ngIf="item.clientEmail">
                            Email: {{item.clientEmail?item.clientEmail:""}}
                            <i style="font-size: 12px;" class="material-icons ms-1 pointer text-primary" (click)="copy(item.clientEmail?item.clientEmail:'')">content_copy</i>
                        </div>
                    </div>
                    <div class="w-100 d-flex flex-column mt-2 border-top pt-2" style="font-size: 12px;">
                        <div class="text-muted" *ngIf="item.remarks">
                            Remarks: {{item.remarks}}
                            <i style="font-size: 12px;" class="material-icons ms-1 pointer text-primary" (click)="copy(item.remarks)">content_copy</i>
                        </div>
                        <div class="text-muted" style="font-size: 12px;" *ngIf="item.id">
                            Cashgram Id: {{item.id}}
                            <i style="font-size: 12px;" class="material-icons ms-1 pointer text-primary" (click)="copy(item.id)">content_copy</i>
                        </div>
                    </div>
                    <div class="w-100 d-flex justify-content-end mt-2" style="font-size: 12px;" *ngIf="item.cashgramLinkInfo&&item.cashgramLinkInfo.cashgramLink">
                        <div class="text-muted border rounded p-1 me-1">
                            {{item.cashgramLinkInfo.cashgramLink}}
                        </div>
                        <button class="btn btn-sm btn-outline-primary" (click)="copy(item.cashgramLinkInfo.cashgramLink)"> 
                            <i style="font-size: 12px;" class="material-icons me-1">content_copy</i>
                            copy link
                        </button>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column align-items-center justify-content-center w-100 m-4">
                <div class="spinner-border" role="status" *ngIf="linksLoader">
                  <span class="sr-only">Loading...</span>
                </div>
                <h5 class="text-muted" *ngIf="!linksLoader&&cashgramRefunds.length==0">No Refund Links 💤</h5>
            </div>
        </ng-container>

      </div>
      <div class="modal-footer" *ngIf="page==1">
        <button class="btn btn-outline-dark me-2" (click)="closeModal()">
          Close
        </button>
        <button type="button" class="btn btn-outline-primary" *ngIf="!debitFromWallet" (click)="createLink()" [disabled]="creatingLinkLoader||!isCountryCode91">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                *ngIf="creatingLinkLoader"></span>
            Create
        </button>
        <button type="button" class="btn btn-outline-primary" *ngIf="debitFromWallet" (click)="createLink()" [disabled]="creatingLinkLoader">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                *ngIf="creatingLinkLoader"></span>
            Refund from Wallet
        </button>
      </div>
    </ng-template>