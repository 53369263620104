<ng-template #ledgerPathUpdater>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Edit Ledger</h4>
        <div class="ms-auto d-flex align-items-center">
          <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
    </div>
    <div class="modal-body">
        <div class="d-flex align-items-center justify-content-between">
            <div>
                <h6>Credit</h6>
                <div class="text-muted">
                    {{ ledgerItem.credit}}
                </div>
            </div>
            <div>
                <h6>Debit</h6>
                <div class="text-muted">
                    {{ ledgerItem.debit}}
                </div>
            </div>
            <div>
                <h6>Currency</h6>
                <div class="text-muted">
                    {{ ledgerItem.currency?ledgerItem.currency:'-'}}
                </div>
            </div>
            <div>
                <h6>Date & Time</h6>
                <div class="text-muted">
                    {{ ledgerItem.displayDateTime?ledgerItem.displayDateTime:'-' }}
                </div>
            </div>
        </div>
        <div class="mt-3">
            <h6>Txn Details</h6>
            <div class="text-muted">
                {{ ledgerItem.credit > 0? ledgerItem.source: ledgerItem.transactionDetails}}
            </div>
        </div>
        <div class="d-flex align-items-center mt-4">
            <div>
                <h6>Update Amount</h6>
                <input type="number" class="form-control" [(ngModel)]="updateAmount">
            </div>
            <div class="ms-3">
                <h6>Update Currency</h6>
                <currency-dropdown [defaultValue]="this.currency" (selectedCurrency)="setCurrency($event)"></currency-dropdown>
            </div>
        </div>
        
    </div>
    <div class="modal-footer">
        <button class="btn btn-outline-primary" (click)="updateLedgerEntries()" [disabled]="updatingLoader">
            <span class="spinner-border spinner-border-sm" role="status" *ngIf="updatingLoader" aria-hidden="true"></span>
            Update
        </button>
        <button class="btn btn-outline-dark ms-2" (click)="closeModal()">
            Close
        </button>
    </div>
</ng-template>