import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {
  accountManagersList,
  city,
  languages,
  specializations,
  tagsList,
} from '../../constants/constants';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-doctor-filter',
  templateUrl: './doctor-filter.component.html',
  styleUrls: ['./doctor-filter.component.css'],
})
export class DoctorFilterComponent implements OnInit {
  @Output()
  populateDoctors: EventEmitter<any> = new EventEmitter<any>();
  city = city;
  id: string;
  specialization = specializations;
  languages = languages;
  searchText: string = '';
  selectedCity: string = '';
  selectedSpecialization: string = '';

  newFilter: any = {
    photoUrl: '',
    showClaimReadyDoctorProfilesOnlyWithPendingInvitation: '',
    email: '',
    searchText: '',
    selectedCity: '',
    selectedSpecialization: '',
    medicalRegistrationVerified: '',
    medicalRegistrationUploaded: '',
    showInListing: '',
    isProfileClaimed: '',
    publicContactNumber: '',
    primaryContactNumber: '',
    isClaimProfileEmailInviteSent: '',
    isClaimProfileWAInviteSent: '',
    isFounderInviteViaEmailSent: '',
    isFounderInviteViaWhatsappSent: '',
    tags: [],
    accountManagers: [],
    limit: 200,
  };
  accountManagerSuggestionList: string[] = accountManagersList;
  tagsSuggestionList: string[] = tagsList;
  searchFormControl = new UntypedFormControl();

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _toastr: ToastrService,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.getExistingFilters();
    this.searchFormControl.valueChanges
      .pipe(
        debounceTime(750),
        switchMap(async (changedValue) => this.searchTextChanged(changedValue))
      )
      .subscribe((val) => val);
  }

  saveFilter() {
    let filter = {
      selectedCity: this.selectedCity,
      selectedSpecialization: this.selectedSpecialization,
      advanceFilters: this.newFilter,
    };
    if (
      filter['advanceFilters']['tags'] &&
      filter['advanceFilters']['tags'].length > 0
    ) {
      filter['advanceFilters']['tags'] =
        filter['advanceFilters']['tags'].join(',');
    } else {
      filter['advanceFilters']['tags'] = '';
      this.newFilter['tags'] = [];
    }
    if (
      filter['advanceFilters']['accountManagers'] &&
      filter['advanceFilters']['accountManagers'].length > 0
    ) {
      filter['advanceFilters']['accountManagers'] =
        filter['advanceFilters']['accountManagers'].join(',');
    } else {
      filter['advanceFilters']['accountManagers'] = '';
      this.newFilter['accountManagers'] = [];
    }
    // console.log('SAVE FILTER', filter);

    this._router.navigate([], {
      relativeTo: this._activatedRoute,
      queryParams: {
        city: filter.selectedCity,
        specialization: filter.selectedSpecialization,
        ...filter.advanceFilters,
      },
    });
  }

  getExistingFilters() {
    this._activatedRoute.queryParams.subscribe((params) => {
      if (params['city']) {
        this.selectedCity = params['city'];
        this.newFilter['selectedCity'] = params['city'];
      } else {
        this.newFilter['selectedCity'] = '';
      }
      if (params['specialization']) {
        this.selectedSpecialization = params['specialization'];
        this.newFilter['selectedSpecialization'] = params['specialization'];
      } else {
        this.newFilter['selectedSpecialization'] = '';
      }
      if (params['photoUrl']) {
        this.newFilter['photoUrl'] =
          params['photoUrl'] == 'true'
            ? true
            : params['photoUrl'] == ''
            ? ''
            : false;
      }
      if (params['showClaimReadyDoctorProfilesOnlyWithPendingInvitation']) {
        this.newFilter[
          'showClaimReadyDoctorProfilesOnlyWithPendingInvitation'
        ] =
          params['showClaimReadyDoctorProfilesOnlyWithPendingInvitation'] ==
          'true'
            ? true
            : params['showClaimReadyDoctorProfilesOnlyWithPendingInvitation'] ==
              ''
            ? ''
            : false;
      }
      if (params['email']) {
        this.newFilter['email'] =
          params['email'] == 'true' ? true : params['email'] == '' ? '' : false;
      }
      if (params['medicalRegistrationVerified']) {
        this.newFilter['medicalRegistrationVerified'] =
          params['medicalRegistrationVerified'] == 'true'
            ? true
            : params['medicalRegistrationVerified'] == ''
            ? ''
            : false;
      }
      if (params['medicalRegistrationUploaded']) {
        this.newFilter['medicalRegistrationUploaded'] =
          params['medicalRegistrationUploaded'] == 'true'
            ? true
            : params['medicalRegistrationUploaded'] == ''
            ? ''
            : false;
      }
      if (params['showInListing']) {
        this.newFilter['showInListing'] =
          params['showInListing'] == 'true'
            ? true
            : params['showInListing'] == ''
            ? ''
            : false;
      }
      if (params['isProfileClaimed']) {
        this.newFilter['isProfileClaimed'] =
          params['isProfileClaimed'] == 'true'
            ? true
            : params['isProfileClaimed'] == ''
            ? ''
            : false;
      }
      if (params['isClaimProfileEmailInviteSent']) {
        this.newFilter['isClaimProfileEmailInviteSent'] =
          params['isClaimProfileEmailInviteSent'] == 'true'
            ? true
            : params['isClaimProfileEmailInviteSent'] == ''
            ? ''
            : false;
      }
      if (params['isClaimProfileWAInviteSent']) {
        this.newFilter['isClaimProfileWAInviteSent'] =
          params['isClaimProfileWAInviteSent'] == 'true'
            ? true
            : params['isClaimProfileWAInviteSent'] == ''
            ? ''
            : false;
      }
      if (params['isFounderInviteViaWhatsappSent']) {
        this.newFilter['isFounderInviteViaWhatsappSent'] =
          params['isFounderInviteViaWhatsappSent'] == 'true'
            ? true
            : params['isFounderInviteViaWhatsappSent'] == ''
            ? ''
            : false;
      }
      if (params['isFounderInviteViaEmailSent']) {
        this.newFilter['isFounderInviteViaEmailSent'] =
          params['isFounderInviteViaEmailSent'] == 'true'
            ? true
            : params['isFounderInviteViaEmailSent'] == ''
            ? ''
            : false;
      }
      if (params['publicContactNumber']) {
        this.newFilter['publicContactNumber'] =
          params['publicContactNumber'] == 'true'
            ? true
            : params['publicContactNumber'] == ''
            ? ''
            : false;
      }
      if (params['primaryContactNumber']) {
        this.newFilter['primaryContactNumber'] =
          params['primaryContactNumber'] == 'true'
            ? true
            : params['primaryContactNumber'] == ''
            ? ''
            : false;
      }
      if (params['tags'] != null && params['tags'].length > 0) {
        this.newFilter['tags'] = params['tags'].split(',');
      }
      if (
        params['accountManagers'] != null &&
        params['accountManagers'].length > 0
      ) {
        this.newFilter['accountManagers'] =
          params['accountManagers'].split(',');
      }
      if (params['limit']) {
        this.newFilter['limit'] = params['limit'];
      }
      this.populateDoctors.emit(this.newFilter);
    });
  }

  onCityChange(e) {
    if (e && e.label) {
      this.selectedCity = e.label;
    }
    this.getDoctorProfiles();
  }

  onSpecialityChange(e) {
    if (e && e.label) {
      this.selectedSpecialization = e.label;
    }
    this.getDoctorProfiles();
  }

  clearFilters() {
    this.newFilter.photoUrl = '';
    this.newFilter.showClaimReadyDoctorProfilesOnlyWithPendingInvitation = '';
    this.newFilter.email = '';
    this.newFilter.medicalRegistrationVerified = '';
    this.newFilter.medicalRegistrationUploaded = '';
    this.newFilter.limit = 100;
    this.newFilter.publicContactNumber = '';
    this.newFilter.primaryContactNumber = '';
    this.newFilter.showInListing = '';
    this.newFilter.isProfileClaimed = '';
    this.newFilter.isClaimProfileEmailInviteSent = '';
    this.newFilter.isClaimProfileWAInviteSent = '';
    this.newFilter.isFounderInviteViaWhatsappSent = '';
    this.newFilter.isFounderInviteViaEmailSent = '';
    this.saveFilter();
  }

  searchTextChanged(val: string) {
    this.searchText = val.trim();
    this.newFilter['searchText'] = val;
    return this.populateDoctors.emit(this.newFilter);
  }

  getDoctorProfiles() {
    if (this.newFilter.limit == null || this.newFilter.limit > 9999) {
      this._toastr.error('Limit can not be more than 9999');
    } else {
      this.saveFilter();
    }
  }

  onDropdownFilterChange(event) {
    setTimeout(() => {
      this.saveFilter();
    }, 200);
  }
  openModal(content) {
    this.modalService.show(content);
  }
  closeModal() {
    this.modalService.hide();
  }
}
