import { Exclude, Expose, Transform, Type, plainToClass } from "class-transformer";
import { AmountWithCurrency } from "../Protocols/AmountWithCurrency";
import { AppointmentType, ServiceTypes } from "../Enums/Enums";
import { DayjsHelper } from "../Helpers/dayjsHelper";


export enum CRProviderClientStatus {
    ongoing = "Ongoing",
    terminated = "Terminated",
    onPlan = "onPlan",
    asNeeded = "As Needed"
}

export class AmountCurrencyWithUpdatedOn extends AmountWithCurrency {
    @Expose() updatedOn: number;
    @Expose() updatedById: string;
    @Expose() updatedByName: string;
}

export class CRProviderConnectionInfo {
    @Expose() status: CRProviderClientStatus;
    @Expose() startDate: string;
    @Expose() providerId: string;
    @Transform(
        (value) => {
          if (value) {
            let map = new Map<ServiceTypes, AmountCurrencyWithUpdatedOn>();
            for (let entry of Object.entries(value)) {
              map.set(entry[0] as ServiceTypes, plainToClass(AmountCurrencyWithUpdatedOn, entry[1]));
            }
            return map;
          } else {
            return new Map<ServiceTypes, AmountCurrencyWithUpdatedOn>();
          }
        },
        { toClassOnly: true }
    )
    @Expose() feeConfig: Map<ServiceTypes, AmountCurrencyWithUpdatedOn>;
    @Expose() preferencesNote: string;
}

export class CRAppointment {
    @Expose() leadId: string;
    @Expose() date: string;
    @Expose() providerId: string;
    @Expose() providerName: string;
    @Expose() startTime: string;
    @Expose() timeslot: string;
    @Expose() status: string;
    @Type(() => AmountWithCurrency)
    @Expose() feePaid: AmountWithCurrency;
    @Expose() mode: AppointmentType;
    @Expose() sessionType: ServiceTypes; // Could be more specific depending on your use case
}

export class CRMembershipPlan {
    @Expose() date: string;
    @Expose() planPurchaseId: string;
    @Expose() providerId: string;
    @Expose() feePaid: AmountWithCurrency;
    @Expose() sessionType: ServiceTypes;
    @Expose() sessionsLeft: number;
}


export class ClientRelationship {
    @Expose() clientId: string; // Assuming clientID is a property for reference and not the actual document ID
    @Expose() name: string;
    @Expose() pronoun: string;
    @Expose() gender: string;
    @Transform(
        (value) => {
            if (value) {
                let map = new Map<string, CRProviderConnectionInfo>();
                for (let entry of Object.entries(value)) {
                    map.set(entry[0], plainToClass(CRProviderConnectionInfo, entry[1]));
                }
                return map;
            } else {
                return new Map<string, CRProviderConnectionInfo>();
            }
        },
        { toClassOnly: true }
    )
    @Expose() connectedProviders: Map<string, CRProviderConnectionInfo> = new Map();

    @Expose() createdOn: number;//First appt
    @Expose() lastApptDate: string;
    @Expose() firstApptDate: string;

    @Exclude() selectedProviderId: string;//If a specific provider is selected for viewing the relationship in context

    @Transform(
        (value) => {
            if (value) {
                let map = new Map<string, CRAppointment>();
                for (let entry of Object.entries(value)) {
                    map.set(entry[0], plainToClass(CRAppointment, entry[1]));
                }
                return map;
            } else {
                return new Map<string, CRAppointment>();
            }
        },
        { toClassOnly: true }
    )
    @Expose() appointments: Map<string, CRAppointment> = new Map();

    @Transform(
        (value) => {
            if (value) {
                let map = new Map<string, CRMembershipPlan>();
                for (let entry of Object.entries(value)) {
                    map.set(entry[0], plainToClass(CRMembershipPlan, entry[1]));
                }
                return map;
            } else {
                return new Map<string, CRMembershipPlan>();
            }
        },
        { toClassOnly: true }
    )
    plans: Map<string, CRMembershipPlan> = new Map();

    @Exclude() displayAppts: CRAppointment[] = [];
    @Exclude() totalSessions: number = 0;
    @Exclude() currentStatus: CRProviderClientStatus;
    @Exclude() firstAppt: CRAppointment;
    @Exclude() futureAppt: CRAppointment;
    @Exclude() isFutureApptBooked: boolean;


    /**
     * Init function to precalc. values of things with proper context
     * @returns void
     */
    init(providerId?: string) {
        this.selectedProviderId = providerId;
        this.displayAppts = this.getAppts();
        this.totalSessions = this.getTotalSessions();
        this.isFutureApptBooked = this.getLatestBookedAppt() ? true : false;
        this.firstAppt = this.getFirstAppt();
        this.futureAppt = this.getLatestBookedAppt();
        this.currentStatus = this.status();
    }

    getAppts() {
        let appts: CRAppointment[] = [];
        this.appointments?.forEach((v) => {
            if (this.selectedProviderId == null || v.providerId == this.selectedProviderId) {
                appts.push(v);
            }
        });
        appts = appts.sort((a, b) => {
            return (a.date + a.startTime).localeCompare(b.date + b.startTime);
        });
        return appts;
    }

    getLatestBookedAppt() {
        const appts = this.getAppts();
        if (appts && appts.length > 0) {
            const appt = appts[appts.length - 1];
            if (appt.date.localeCompare(new DayjsHelper('Asia/Kolkata').todayYYYYMMDD()) >= 0 && appt.status == "CONFIRMED" && appt.feePaid?.amount > 0) {
                return appt;
            }
            else {
                return null;
            }
        } else {
            return null;
        }

    }

    getFirstAppt() {
        const appts = this.getAppts();
        if (appts && appts.length > 0) {
            const appt = appts[appts.length - 1];
            if (appt.date.localeCompare(new DayjsHelper('Asia/Kolkata').todayYYYYMMDD()) >= 0) {
                return appt;
            }
            else {
                return null;
            }
        } else {
            return null;
        }
    }

    mostRecentInteraction(providerId?: string) {

    }

    getTotalSessions() {
        if (this.selectedProviderId) {
            const count = this.displayAppts.length;
            return count;
        } else {
            return this.appointments?.size ? this.appointments.size : 0;
        }
    }

    status() {
        if (this.selectedProviderId && this.connectedProviders?.get(this.selectedProviderId)) {
            return this.connectedProviders?.get(this.selectedProviderId).status;
        } else {
            let s: CRProviderClientStatus = null;
            let statusPriority = {
                [CRProviderClientStatus.ongoing]: 5,
                [CRProviderClientStatus.terminated]: 0,
                [CRProviderClientStatus.onPlan]: 6,
                [CRProviderClientStatus.asNeeded]: 4,
            }
            if (this.connectedProviders) {
                this.connectedProviders?.forEach((item) => {
                    if (item.status && (s == null || statusPriority[item.status] > statusPriority[s])) {
                        s = item.status;
                    }
                })
            }
            if (s == null) {
                s = CRProviderClientStatus.ongoing;
            }
            return s;
        }
    }
}
