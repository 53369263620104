import { Expose, Type } from "class-transformer";

export class HealthHeroAccessLevels {
  @Expose()
  canAccessDashboard: boolean;
  @Expose()
  canAccessDoctorNetwork: boolean;
  @Expose()
  canAccessClinicNetwork: boolean;
  @Expose()
  canAccessHospitalNetwork: boolean;
  @Expose()
  canAccessMedicalRegList: boolean;
  @Expose()
  canAccessBasicInfoList: boolean;
  @Expose()
  canAccessPhotoUploadList: boolean;
  @Expose()
  canManageClaims: boolean;
  @Expose()
  canManageCredentials: boolean;
  @Expose()
  canAssignProfiles: boolean;
}

export class HealthHeroInfo {
  @Expose()
  enrolledOn: number;

  @Expose()
  isEnrolled: boolean;

  @Expose()
  healthHeroId: string;

  @Expose()
  roles: string[] = []; //inputter, verifier, admin, docvita_admin, crm_access

  @Type(() => HealthHeroAccessLevels)
  @Expose()
  access: HealthHeroAccessLevels = new HealthHeroAccessLevels();

  @Expose()
  createDoctorProfileOnNewRecommendation: boolean;

  isDocVitaAdmin() {
    return this.roles != null && this.roles.includes("docvita_admin");
  }

  hasApproverPermission() {
    return this.roles != null && (this.roles.includes("approver") || this.roles.includes("docvita_admin"));
  }

}
