import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inrToUsd'
})
export class InrToUsdPipe implements PipeTransform {

  transform(value: number): unknown {
    if(value && value>0){
      return value/73.55;
    }
    return 0;
  }

}
