import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault('Asia/Kolkata');
import { DoctorListingProfile } from '../../models-ts/Actors/Doctor/DoctorListingProfile';
import { ScheduleHelper } from '../../models-ts/Actors/Doctor/ScheduleHelper';
import { AppointmentService } from '../../services/appointment/appointment.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'provider-available-slots',
  templateUrl: './provider-available-slots.component.html',
  styleUrls: ['./provider-available-slots.component.css'],
})
export class ProviderAvailableSlotsComponent implements OnInit, OnDestroy {
  invokeSlotsCall: Subject<any> = new Subject();
  invokeSlotsCallSub: Subscription;

  @Input() set doctorProfile(val: DoctorListingProfile) {
    this._doctorProfile = val;
    this.invokeSlotsCall.next();
  }
  @Input() set date(val: string) {
    this._date = val;
    this.invokeSlotsCall.next();
  }
  _practiceId: string; //This will be passed in case of in person time slots fetching
  @Input() set practiceId(val: string) {
    this._practiceId = val;
    this.invokeSlotsCall.next();
  }
  get practiceId() {
    return this._practiceId;
  }
  @Input() disableContainer: boolean = false;

  @Output() selectedSlot: EventEmitter<any> = new EventEmitter<any>();
  @Output() allSlots: EventEmitter<any[]> = new EventEmitter<any>();

  _date: string = new Date().toISOString().split('T')[0];
  _doctorProfile: DoctorListingProfile = new DoctorListingProfile();
  currentSlots: any[] = [];

  _slotLoader:boolean = false;
  set slotLoader(val:boolean){
    this._slotLoader = val;
    this.loadingSlots.emit(val)
  };
  get slotLoader(){
    return this._slotLoader;
  }

  @Output() loadingSlots:EventEmitter<boolean> = new EventEmitter<boolean>();

  noSlots: boolean = false;
  _selectedSlot: any;

  
  constructor(
    private appointmentService: AppointmentService
  ) { }

  ngOnInit(): void {
    
    this.invokeSlotsCallSub = this.invokeSlotsCall.pipe(debounceTime(100)).subscribe(()=>{
      this.populateSlots()
    })

    this.invokeSlotsCall.next();
  }

  ngOnDestroy(): void {
    if(this.invokeSlotsCallSub){
      this.invokeSlotsCallSub.unsubscribe()
    }
  }
  

  practiceName(practiceId: string): string {
    return practiceId && this._doctorProfile?.associatedPractices?.has(practiceId) ? 
      this._doctorProfile.associatedPractices.get(practiceId).name : "In Person Appointment";
  }

  async populateSlots() {
    try {
      this.currentSlots = [];
      this.noSlots = false;
      if (
        this._doctorProfile &&
        this._doctorProfile.id &&
        this._date
      ) {
        this.slotLoader = true;
        const slots = await this.appointmentService.getNDayScheduleForProvider([this._date], this._doctorProfile?.id, this.practiceId)
        if(slots == null || slots[this._date] == null){
          return;
        }else{
          this.currentSlots = slots[this._date] 
          if (this.currentSlots.length == 0) {
            this.noSlots = true;
          }
          this.slotLoader = false;
        }
      } else {
        this.noSlots = true;
        this.slotLoader = false;
      }
      this.allSlots.emit(this.currentSlots);
    } catch (error) {
      const errMessage = error['message'] || 'unknown_error'
      switch(errMessage){
        case 'no_schedule':
        case 'not_the_latest_call':
        case 'missing_params':
        default:{
          this.slotLoader = false;
        }
      }
    }
  }

  selectSlot(slot) {
    this._selectedSlot = slot;
    this.selectedSlot.emit(slot);
  }
  getTimeInHHmma(timestring: string) {
    let hrs = +timestring.substring(0, 2);
    let min = +timestring.substring(3, 5);
    let p = timestring.substring(6, 8);
    if (p.toLowerCase().includes('pm')) {
      hrs += 12;
    }
    let d = new Date();
    d.setHours(hrs);
    d.setMinutes(min);
    let val = dayjs(d).format('hh:mm a');
    return val;
  }
}
