<div class="">
  <h2 class="mt-3">Login credentials</h2>
  <div
    class="d-flex flex-column p-3"
    style="border: 1px solid #bbbbbb; border-radius: 8px"
  >
    <h4>Create Login Credentials</h4>
    <div class="form-row">
      <div class="form-group col-md-5">
        <label for="">Contact Number *</label>
        <div class="d-flex">
          
          <input
            type="number"
            class="form-control me-2"
            id=""
            placeholder="Country Code"
            [(ngModel)]="countryCode"
            required
          />
          <input
            type="number"
            class="form-control"
            id=""
            placeholder="Contact Number"
            [(ngModel)]="mobileNumber"
            required
          />
        </div>
      </div>
      <div class="form-group col-md-5">
        <label for="inputEmail">Email *</label>
        <input
          type="email"
          class="form-control"
          id="inputEmail"
          placeholder="Email"
          [(ngModel)]="email"
          email
          required
        />
      </div>
      <div class="form-group col-md-5">
        <label for="type">Type *</label>
        <select [(ngModel)]="type" class="form-control">
          <option *ngFor="let role of roles">{{ role }}</option>
        </select>
      </div>
      <div class="form-group col-md-5">
        <label for="">Name *</label>
        <input
          type="text"
          class="form-control"
          id=""
          placeholder="Name"
          [(ngModel)]="name"
          required
        />
      </div>
    </div>

    <div>
      <button
        [disabled]="creatingCredentials"
        (click)="createLoginCredentials()"
        class="mb-2 btn btn-sm btn-primary"
      >
        {{creatingCredentials ? 'Creating ...' : 'Create Credentials'}}
      </button>
    </div>
  </div>

  <div class="mt-5" *ngIf="profiles.length > 0">
    <h4>Profiles</h4>
    <div class="card mt-3" *ngFor="let p of profiles">
      <div class="card-body">
        <div><b>Name</b> : {{ p.name }}</div>
        <div><b>Email</b> : {{ p.primaryEmail }}</div>
        <div><b>Mobile Number</b> : +{{p.primaryContactNumber.countryCode}}-{{ p.primaryContactNumber.number }}</div>
        <div><b>Type</b> : {{ p.type }}</div>
      </div>
    </div>
  </div>
</div>
