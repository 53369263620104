import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Lead } from '../../models-ts/Entities/Lead';
import { LeadNote } from '../../models-ts/Entities/LeadNote';
import { LeadCommentsService } from 'src/app/shared/services/lead-comments/lead-comments.service';
import { CRMUser } from '../../models-ts/Actors/User/CRMUser';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'lead-comments',
  templateUrl: './lead-comments.component.html',
  styleUrls: ['./lead-comments.component.scss']
})
export class LeadCommentsComponent implements OnInit {
  @Input() set lead(val:Lead){
    this._lead = val;
  }
  get lead(){
    return this._lead;
  }
  @Input() crmHero:CRMUser = new CRMUser();
  
  noteModalRef:BsModalRef;
  newNote:string;
  noteLoader:boolean = false;
  selectedNoteId:string;
  _lead:Lead = new Lead()

  constructor(
    private modalService:BsModalService,
    private leadCommentService:LeadCommentsService,
    private toastrService:ToastrService
  ) { }

  ngOnInit(): void {
  }

  @ViewChild('addNoteModal') addNoteModal;
  openNoteModal(note?:LeadNote,noteId?:string){
    if(note&&note.note && noteId){
      this.newNote = note.note;
      this.selectedNoteId = noteId;
    }else{
      this.newNote = null;
      this.selectedNoteId = null;
    }
    
    this.noteModalRef = this.modalService.show(this.addNoteModal)
  }

  closeNoteModal(){
    this.noteModalRef.hide();
  }

  async addNote(){
    if(this.newNote&&this.newNote.length>0&&this.lead&&this.lead.id&&this.crmHero&&this.crmHero.docvitaId){
      this.noteLoader = true;
      try {
        const addedNote = await this.leadCommentService.addNote(this.newNote,this.crmHero.name,this.crmHero.docvitaId,this.lead.id);  
        if(addedNote){
          this.noteLoader = false;
          this.toastrService.success("Note added!")
          this.newNote = null;
          // this.closeNoteModal();
        }else{
          throw new Error("invalid")
        }
      } catch (error) {
        this.toastrService.error("Failed to add note..")
        this.noteLoader = false;
      }
    }else{
      this.toastrService.error("Missing details..")
      this.noteLoader = false;
    }
  }

  async deleteNote(noteId:string){
    if(this.lead.id && noteId){
      try {
        this.noteLoader = true;
        const deletedNote = await this.leadCommentService.deleteNote(noteId,this.lead.id);  
        if(deletedNote){
          this.noteLoader = false;
          this.toastrService.success("Note deleted!")
          this.closeNoteModal();
        }else{
          throw new Error("invalid")
        }
      } catch (error) {
        this.noteLoader = false;
        this.toastrService.error("Failed to delete note..")
      }
    }else{
      this.noteLoader = false;
      this.toastrService.error("Missing details..")
    }
  }

  async updateNote(){
    if(this.newNote&&this.newNote.length>0&&this.lead&&this.lead.id&&this.crmHero&&this.crmHero.docvitaId){
      this.noteLoader = true;
      try {
        const updatedNote = await this.leadCommentService.updateNote(this.selectedNoteId,this.newNote,this.crmHero.docvitaId,this.crmHero.name,this.lead.id);  
        if(updatedNote){
          this.noteLoader = false;
          this.toastrService.success("Note added!")
          this.closeNoteModal();
        }else{
          throw new Error("invalid")
        }
      } catch (error) {
        this.toastrService.error("Failed to add note..")
        this.noteLoader = false;
      }
    }else{
      this.toastrService.error("Missing details..")
      this.noteLoader = false;
    }
  }

  get plainLeadComments(){
    let notes: LeadNote[] = [];
    if(this.lead && this.lead.leadNotes) {
      notes = [...this.lead.leadNotes.values()];
    }
    notes = notes.sort((a,b)=>{
      return b.createdOn - a.createdOn;
    })
    return notes;
  }

  returnzero(){
    return 0;
  }

}
