import { Expose } from "class-transformer";
import { TransactionSource } from "../Enums/pay";
import { LedgerItem } from "./LedgerItem";
export class ProviderLedgerItem extends LedgerItem {
	@Expose() transactionDetails: string;
	@Expose() clientId: string;
	@Expose() appointmentId: string;
	@Expose() providerId: string;
	@Expose() id: string;
	@Expose() leadId: string;
	@Expose() ledgerId: string;
	@Expose() bankTransferId: string;
	@Expose() bankTransferMeta: any;
	@Expose() currentBalance: number;
	@Expose() appointmentDate: string;
}
