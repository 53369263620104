export enum StepsSection {
    ClientProviderConfirmation,
    CareCoordinatorIntro,
    ConnectClientProvider,
    AskFeedback,
    WishLuckForNextTime,
    ShareFutureBookingLink,
  }
  
  export enum CheckListActionKey {
    ClientConfirmationWAPunchline = 'ClientConfirmationWAPunchline',
    ClientConfirmationWA = 'ClientConfirmationWA',
    ProviderConfirmationWA = 'ProviderConfirmationWA',
    ShareAppointmentJoiningInfoClient = 'ShareAppointmentJoiningInfoClient',
    ClientGoodLuckWA = 'ClientGoodLuckWA',
    CoordinatorIntroWA = 'CoordinatorIntroWA',
    CoordinatorInstructionToConnect = 'CoordinatorInstructionToConnect',
    AskForClientFeedback = 'AskForClientFeedback',
    ThankClientForGivingFeedback = 'ThankClientForGivingFeedback',
    PrescriptionShareWA = 'PrescriptionShareWA',
    AskForFollowUpWA = 'AskForFollowUpWA',
    RequestToRescheduledClient = 'RequestToRescheduledClient',
    InformProviderOfRescheduling = 'InformProviderOfRescheduling',
    InformClientOfRescheduling = 'InformClientOfRescheduling',
    ThankYouForRescheduling = 'ThankYouForRescheduling',
    FileSharingWithProvider = 'FileSharingWithProvider',
    TextSharingWithProvider = 'TextSharingWithProvider',
    RequestDroppedToProvider = 'RequestDroppedToProvider',
    FeedbackRequest = 'FeedbackRequest',
    FeedbackReceived = 'FeedbackReceived',
    ShareProviderProfileLink = 'ShareProviderProfileLink',
    PreliminaryCallRequestToProvider = 'PreliminaryCallRequestToProvider',
    CreateWAGroupTitle = 'CreateWAGroupTitle',
    CreateWAGroupTitleIntro = 'CreateWAGroupIntro',
    CreateWAGroupInstruction = 'CreateWAGroupInstruction',
    CreateWAGroupExit = 'CreateWAGroupExit',
    InternationalNumberSupport = 'InternationalNumberSupport',
    CareCoordinatorIntroOnly = 'CareCoordinatorIntroOnly',
    AskForInfoInternationalClient = 'AskForInfoInternationalClient',
    SharePaymentLinkWithInternationalClient = 'SharePaymentLinkWithInternationalClient',
    SharePaymentLinkWithClient = 'SharePaymentLinkWithClient',
    ShareAppointmentInviteLinkWithClient = 'ShareAppointmentBookingLinkWithClient',
    RequestForExtendedSessionPayment = 'RequestForExtendedSessionPayment',
  }
  
  export class CheckListEntity {
    key: CheckListActionKey;
    title: string;
    // successTitle: string;
    message: string;
    type:UserType;
    newWAButton: boolean;
    onClickFunc: Function;
    checkboxToggleFunc: Function;
    isDone: boolean;
    updatedOn: number;
    updatedByName: string;
    constructor(key: CheckListActionKey, title: string, message: string,type?:UserType, newWAButton?:  boolean) {
      this.key = key;
      this.title = title;
      if(newWAButton != null) {
        this.newWAButton = newWAButton;
      }
      // this.successTitle = successTitle;
      this.message = message;
      if(type){
        this.type = type;
      }
    }
  }
  
  export class CheckListSection {
    sectionTitle: string;
    entities: CheckListEntity[];
  
    constructor(sectionTitle: string, entities: CheckListEntity[]) {
      this.sectionTitle = sectionTitle;
      this.entities = entities;
    }
  }

  export enum UserType{
    client="client",
    provider="provider"
  }