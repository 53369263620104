<app-navbar (backClicked)="backClicked()" [title]="title"></app-navbar>

<!-- 
<div class="d-flex w-100">
  <button
    class="btn btn-sm btn-outline-info mb-3 ms-auto me-3"
    (click)="openNewUserRegistration()"
  >
    New Doctor +
  </button>
</div> -->

<div class="d-flex pt-4" style="background-color: #f4f5fb">
  <!-- <input
    id="track-keyup"
    (keyup)="navigateDoctors($event)"
    style="
      width: 0px;
      height: 0px;
      outline: none;
      padding: 0;
      border: 0;
      margin: 0;
      position: absolute;
    "
  /> -->
  <div class="col-md-4">
    <app-doc-master-list
      (isLoading)="isLoadingChanged($event)"
      (selectedDoctor)="onSelectedDoctorClicked($event)"
      (newDoctorClick)="openNewUserRegistration()"
    >
    </app-doc-master-list>
  </div>

  <div
    *ngIf="id != null && isLoading"
    class="card col-md-8 p-2"
    style="overflow-y: scroll; height: calc(100vh - 150px)"
  >
    Fetching Profile...
  </div>

  <div
    *ngIf="id == null"
    class="card col-md-8 p-2"
    style="overflow-y: scroll; height: calc(100vh - 150px)"
  >
    Select a profile from the list
  </div>

  <div
    *ngIf="!isLoading"
    class="col-md-8 ps-0"
    style="overflow-y: scroll; height: calc(100vh - 150px)"
  >
    <div [hidden]="!selectedDoctor">
      <app-provider-header
        class="mb-2 mt-2 w-100"
        style="background-color: white"
        [showActions]="true"
        [selectedDoctor]="selectedDoctor"
      >
      </app-provider-header>
      <div style="background-color: white" class="px-2 py-1">
        <ul
          class="nav nav-tabs mt-2"
          style="cursor: pointer; background-color: white"
          id="nav-tab"
          role="tablist"
        >
          <li class="nav-item" role="presentation" *ngIf="!isNewDoctor">
            <a
              class="nav-link"
              (click)="routerToBasicInfo()"
              id="info-tab"
              data-toggle="tab"
              aria-controls="info"
              >Basic Information</a
            >
          </li>
          <li class="nav-item" role="presentation" *ngIf="!isNewDoctor">
            <a
              class="nav-link"
              (click)="routerToEducation()"
              id="education-tab"
              data-toggle="tab"
              aria-controls="education"
              >Education & Registration</a
            >
          </li>
          <li class="nav-item" role="presentation" *ngIf="!isNewDoctor">
            <a
              class="nav-link"
              (click)="routerToPractices()"
              id="practices-tab"
              data-toggle="tab"
              aria-controls="practices"
              >Practices</a
            >
          </li>
          <!-- <li class="nav-item" role="presentation" *ngIf="!isNewDoctor">
            <a
              class="nav-link"
              (click)="routerToSchedule()"
              id="schedule-tab"
              data-toggle="tab"
              aria-controls="schedule"
              >Schedule</a
            >
          </li> -->
          <li class="nav-item" role="presentation" *ngIf="!isNewDoctor">
            <a
              class="nav-link"
              (click)="routerToDVOneUIForSlots()"
              id="newSlots"
              data-toggle="tab"
              aria-controls="newSlots"
              >Schedule New</a
            >
          </li>
          <li class="nav-item" role="presentation" *ngIf="!isNewDoctor">
            <a
              class="nav-link"
              (click)="routerToClaim()"
              id="claim-tab"
              data-toggle="tab"
              aria-controls="claim"
              >Claim & Credentials </a
            >
          </li>
          <li class="nav-item" role="presentation" *ngIf="!isNewDoctor">
            <a
              class="nav-link"
              (click)="routerToFeeConfig()"
              id="fee-config-tab"
              data-toggle="tab"
              aria-controls="fee-config">
              Fee Config
            </a>
          </li>
          <li
            class="nav-item"
            role="presentation"
            *ngIf="
              !isNewDoctor &&
              currentHeroInfo != null &&
              currentHeroInfo != null &&
              currentHeroInfo.access.canManageCredentials
            "
          >
            <a
              class="nav-link"
              (click)="routeToBankDetails()"
              id="bank-tab"
              data-toggle="tab"
              aria-controls="bank"
              >Link Bank</a
            >
          </li>
        </ul>

        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="registerUserModal"
  tabindex="-1"
  style="z-index: 1500"
  aria-labelledby="registerUserModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="registerUserModalLabel">
          New Doctor Registration
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h2 class="mb-4 mt-1">Basic Information</h2>

        <div class="form-row">
          <!-- <div class="col-md-3">
            <label class="file" *ngIf="!newRegPhotoUrl">
              <span style="font-size: 14px">+</span> <br />
              <span style="font-size: 8px">Upload</span><br />
              <span style="font-size: 8px">Photograph</span>
              <input
                type="file"
                id="files"
                (change)="fileProgress($event)"
                accept="image/x-png,image/jpeg"
                style="height: 150px"
              />
            </label>
            <div
              style="z-index: 1000; position: relative"
              *ngIf="newRegPhotoUrl"
            >
              <input
                type="file"
                (change)="fileProgress($event)"
                accept="image/x-png,image/jpeg"
                style="
                  background-color: tomato !important;
                  width: 100%;
                  height: 150px;
                "
              />
              <img
                [src]="newRegPhotoUrl"
                alt="Hero Image"
                width="100%"
                class="style__photo"
                type="file"
                height="150px"
              />
            </div>
          </div> -->
          <!-- <div class="col-md-3">
            <app-profile-photo [listingProfile]="newDoctorForm" (photoDetails)="updatePhotoChanges($event)">
            </app-profile-photo>
          </div> -->

          <div class="col-md-9">
            <div class="form-row">
              <div class="form-group col-md-3 required">
                <label for="_inputState" class="control-label">Title</label>
                <select
                  id="_inputState"
                  class="form-control"
                  [(ngModel)]="newDoctorForm.salutation"
                  required
                >
                  <option selected value="Dr.">Dr.</option>
                  <option value="Mr.">Mr.</option>
                  <option value="Mrs.">Mrs.</option>
                  <option value="Ms.">Ms.</option>
                </select>
              </div>
              <div class="form-group col-md-9 required">
                <label for="_nameInput" class="control-label">Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="_nameInput"
                  placeholder="Enter Doctor's Name"
                  [(ngModel)]="newDoctorForm.name"
                  required
                />
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-3 required">
                <label for="_genderSelect">Gender</label>
                <select
                  id="_genderSelect"
                  class="form-control"
                  [(ngModel)]="newDoctorForm.gender"
                  required
                >
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </div>
              <div class="form-group col-md-9 required">
                <label for="_cityInput" class="control-label">City</label>
                <ng-select
                  [addTag]="true"
                  id="_cityInput"
                  placeholder="Select city"
                  [(ngModel)]="newDoctorForm.city"
                >
                  <ng-option *ngFor="let c of city" [value]="c">{{
                    c
                  }}</ng-option>
                </ng-select>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="form-row">
          <div class="form-group col-md-6 required">
            <label for="_personaPhoneInp">Personal contact</label>
            <div class="d-flex">
              <input type="text" class="form-control me-1" value="+91" style="width: 3rem; padding: 0.5rem" disabled />
              <input type="number" class="form-control" id="_personaPhoneInp" placeholder="Personal contact number"
                [(ngModel)]="newDoctorForm.primaryContactNumber.number" required />
            </div>
          </div>
          <div class="form-group col-md-6 required">
            <label for="_publicContactInp">Public Contact</label>
            <div class="d-flex">
              <input type="text" class="form-control me-1" value="+91" style="width: 3rem; padding: 0.5rem" disabled />
              <input type="number" class="form-control" id="_publicContactInp" placeholder="Public contact number"
                [(ngModel)]="newDoctorForm.publicContactNumber.number" required />
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="_whatsappInp">Whatsapp Contact</label>
            <div class="d-flex">
              <input type="text" class="form-control me-1" value="+91" style="width: 3rem; padding: 0.5rem" disabled />
              <input type="number" class="form-control" id="_whatsappInp" placeholder="Whatsapp contact number"
                [(ngModel)]="newDoctorForm.whatsappContactNumber.number" />
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="_inputEmail">Email</label>
            <input type="email" class="form-control" id="_inputEmail" placeholder="Email"
              [(ngModel)]="newDoctorForm.email" email required />
          </div>
        </div> -->

        <!-- <app-contact-numbers [profileData]="newDoctorForm" (contactMap)="updateContactNumbers($event)" #getContactMap></app-contact-numbers>
        <app-emails [profileData]="newDoctorForm" (emailMap)="updateEmails($event)" #getEmailMap></app-emails> -->

        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="_spec_select">Specializations</label>
            <ng-select
              id="_spec_select"
              [multiple]="true"
              [addTag]="true"
              placeholder="Select Specialization(s)"
              [(ngModel)]="newDoctorForm.specializations"
            >
              <ng-option
                *ngFor="let spec of specialization"
                [value]="spec.name"
                >{{ spec.name }}</ng-option
              >
            </ng-select>
          </div>

          <div class="form-group col-md-6">
            <label for="_lang_select">Languages</label>
            <ng-select
              id="_lang_select"
              [multiple]="true"
              [addTag]="true"
              placeholder="Select Languages"
              [(ngModel)]="newDoctorForm.languages"
            >
              <ng-option *ngFor="let lang of languages" [value]="lang">{{
                lang
              }}</ng-option>
            </ng-select>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-6 required">
            <label for="expInYears">Experience In Years</label>
            <input
              type="number"
              class="form-control"
              id="expInYears"
              placeholder="Experience in years"
              [(ngModel)]="newDoctorForm.experienceInYears"
            />
          </div>
        </div>

        <!-- <div class="form-row">
          <div class="col-md-10">
            <div class="form-group">
              <label for="about">About</label>
              <textarea type="text" class="form-control" id="about" placeholder="About"
                [(ngModel)]="newDoctorForm.about">
              </textarea>
            </div>
          </div>
          <div class="col-2">
            <button class="btn btn-sm btn-info mt-5" (click)="autoFillAbout()">
              Auto
            </button>
          </div>
        </div>

        <h2 class="mb-4 mt-3">Profile Links</h2>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="_ownWebsiteInp">Own Website</label>
            <input type="text" class="form-control" id="_ownWebsiteInp" placeholder="Paste URL Link here"
              [(ngModel)]="newDoctorForm.profileLinks.ownWebsiteUrl" />
          </div>
          <div class="form-group col-md-6">
            <label for="_googleInp">Google</label>
            <input type="text" class="form-control" id="_googleInp" placeholder="Paste URL Link here"
              [(ngModel)]="newDoctorForm.profileLinks.googleProfileUrl" />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="_PractoInp">Practo</label>
            <input type="text" class="form-control" id="_PractoInp" placeholder="Paste URL Link here"
              [(ngModel)]="newDoctorForm.profileLinks.practoProfileUrl" />
          </div>
          <div class="form-group col-md-6">
            <label for="_facebookInp">Facebook</label>
            <input type="text" class="form-control" id="_facebookInp" placeholder="Paste URL Link here"
              [(ngModel)]="newDoctorForm.profileLinks.facebookProfileUrl" />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="_justDialInp">JustDial</label>
            <input type="text" class="form-control" id="_justDialInp" placeholder="Paste URL Link here"
              [(ngModel)]="newDoctorForm.profileLinks.justdialProfileUrl" />
          </div>
          <div class="form-group col-md-6">
            <label for="_InstagramInp">Instagram</label>
            <input type="text" class="form-control" id="_InstagramInp" placeholder="Paste URL Link here"
              [(ngModel)]="newDoctorForm.profileLinks.instagramProfileUrl" />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="_LinkedInInp">LinkedIn</label>
            <input type="text" class="form-control" id="_LinkedInInp" placeholder="Paste URL Link here"
              [(ngModel)]="newDoctorForm.profileLinks.linkedinProfileUrl" />
          </div>
          <div class="form-group col-md-6">
            <label for="_TwitterInp">Twitter</label>
            <input type="text" class="form-control" id="_TwitterInp" placeholder="Paste URL Link here"
              [(ngModel)]="newDoctorForm.profileLinks.twitterProfileUrl" />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="_MyUpchaarInp">MyUpchaar</label>
            <input type="text" class="form-control" id="_MyUpchaarInp" placeholder="Paste URL Link here"
              [(ngModel)]="newDoctorForm.profileLinks.myupcharProfileUrl" />
          </div>
          <div class="form-group col-md-6">
            <label for="_DocPrimeInp">DocPrime</label>
            <input type="text" class="form-control" id="_DocPrimeInp" placeholder="Paste URL Link here"
              [(ngModel)]="newDoctorForm.profileLinks.docprimeProfileUrl" />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="_KiviHealthInp">Kivi Health</label>
            <input type="text" class="form-control" id="_KiviHealthInp" placeholder="Paste URL Link here"
              [(ngModel)]="newDoctorForm.profileLinks.kivihealthProfileUrl" />
          </div>
          <div class="form-group col-md-6">
            <label for="_DenteeInp">Dentee</label>
            <input type="text" class="form-control" id="_DenteeInp" placeholder="Paste URL Link here"
              [(ngModel)]="newDoctorForm.profileLinks.denteeProfileUrl" />
          </div>
        </div> -->

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            (click)="updateNewDoctor()"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-spinner></ngx-spinner>
