import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DoctorListingProfile } from 'src/app/shared/models-ts/Actors/Doctor/DoctorListingProfile';
import { ServiceTypes } from 'src/app/shared/models-ts/Enums/Enums';
import { MembershipPlansService } from '../../membership-plans.service';
declare var bootstrap: any;
@Component({
  selector: 'app-doc-membership-plan-generator',
  templateUrl: './doc-membership-plan-generator.component.html',
  styleUrls: ['./doc-membership-plan-generator.component.scss']
})
export class DocMembershipPlanGeneratorComponent implements OnInit {

  title: string;
  noOfWeeks: number;
  multiplier: number;
  isMostPopular: boolean;
  providerProfile: DoctorListingProfile;
  sessionTypes = [
    ServiceTypes.individual,
    ServiceTypes.family,
    ServiceTypes.couple,
  ]
  @Output() refreshPlans = new EventEmitter<void>();
  offcanvas;
  sessionTypeArray: ServiceTypes[] = [];
  constructor(
    private _toastrService: ToastrService,
    private _membershipPlansService: MembershipPlansService
  ) { }

  ngOnInit(): void {
  }

  async save() {
    if (this.sessionTypeArray != null && this.sessionTypeArray.length > 0 && this.multiplier != null) {
      for(let s of this.sessionTypeArray) {
        await this._membershipPlansService.generatePlan(this.providerProfile.id, this.multiplier, this.isMostPopular,
          this.noOfWeeks, s, 7 * this.noOfWeeks);
      }
      this.refreshPlans.emit();
      this._toastrService.success("Plan saved!")
      this.closeCanvas();
    } else {
      this._toastrService.error("Please fill all the details");
    }
  }

  closeCanvas() {
    if (this.offcanvas) {
      this.offcanvas.hide();
    }
  }

  public open(providerProfile: DoctorListingProfile, noOfWeeks: number, isMostPopular: boolean) {
    this.providerProfile = providerProfile;
    this.noOfWeeks = noOfWeeks;
    this.multiplier = null;
    this.sessionTypeArray = [];
    this.isMostPopular = isMostPopular;
    this.title = `Generate ${noOfWeeks} weeks plan`;
    var myOffcanvas = document.getElementById('offcanvas-for-membership-plan-generator')
    this.offcanvas = new bootstrap.Offcanvas(myOffcanvas, { backdrop: true, scroll: true })
    this.offcanvas.show();
  }

}
