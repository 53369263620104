import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DoctorListingProfile } from '../../models-ts/Actors/Doctor/DoctorListingProfile';
import { DoctorsService } from '../../services/doctors/doctors.service';
import { DoctorsSearchService } from '../../services/doctors/doctors-search.service';

@Component({
  selector: 'app-doctor-search',
  templateUrl: './doctor-search.component.html',
  styleUrls: ['./doctor-search.component.css'],
})
export class DoctorSearchComponent implements OnInit {
  doctorProfilesList: DoctorListingProfile[] = [];
  searchSub = new Subject<string>();
  searchText: string;
  @Input()
  set providerId(val: string) {
    this.searchDoctor(val);
  }
  @Input() customPlaceholder: string = 'Filter by Specialist';
  @Input() set _searchText(value: string) {
    this.searchDoctor(value);
  }
  @Output() getProvider: EventEmitter<DoctorListingProfile> =
    new EventEmitter<DoctorListingProfile>();

  constructor(
    private _doctorService: DoctorsService,
    private _doctorSearchService: DoctorsSearchService
  ) {}

  ngOnInit(): void {
    this.searchSub.pipe(debounceTime(1000)).subscribe((val) => {
      this.searchDoctor(val);
    });
  }

  ngAfterViewInit() {
    this.initSeq();
  }

  searchDoctor(searchTerm: string) {
    // console.log('Searching: ', name);
    this.doctorProfilesList = [];
    if (searchTerm && searchTerm.length > 0) {
      if(searchTerm[0]=='+'){
        searchTerm=searchTerm.slice(1)
      }
      if(isNaN(+searchTerm)){
        console.log('here')
        this.fetchByName(searchTerm);
      }else{
        console.log('heree')
        this.fetchByNumber(searchTerm);
      }
    }
  }

  fetchByName(name:string){
    this._doctorSearchService
        .doctorSearchByNameId(name)
        .then((res) => {
          if (res) {
            res.forEach((val) => {
              let json = val;
              console.log(json);
              this.doctorProfilesList.push(
                plainToClass(DoctorListingProfile, json)
              );
            });
          }
          if (this.doctorProfilesList && this.doctorProfilesList.length == 1) {
            let _ = this.doctorProfilesList[0];
            this.searchText = _.fullName();
            this.getProvider.emit(_);
          }
        })
        .catch((err) => {
          console.log(err);
        });
  }

  fetchByNumber(number:string){
    this._doctorSearchService.getDoctorByNumber(number).then((res)=>{
      if(res&&res.length>0){
        res.forEach(val=>{
          this.doctorProfilesList.push(
            plainToClass(DoctorListingProfile, val)
          );
        })
        if (this.doctorProfilesList && this.doctorProfilesList.length == 1) {
          let _ = this.doctorProfilesList[0];
          this.searchText = _.fullName();
          this.getProvider.emit(_);
        }
      }else{

      }
    }).catch(err=>{
      console.log(err);
    })
  }

  setSearch(event) {
    let _ = event.target.value;
    this.searchSub.next(_);
  }

  setDoctor() {
    if (this.doctorProfilesList.length > 0) {
      let _ = this.doctorProfilesList.filter(
        (da) => da.id == this.searchText
      )[0];
      // console.log(_)
      if (_ && _.fullName()) {
        this.searchText = _.fullName();
      }
      this.getProvider.emit(_);
    }
  }

  initSeq() {
    if (this.searchText) {
      // console.log(this.searchText)
      this.searchSub.next(this.searchText);
    }
  }
}
