<button class="btn btn-sm btn-outline-primary border-0 d-flex justify-content-center align-items-center" *ngIf="showButton" (click)="openModal();">
  <i style="font-size: 15px" class="material-icons me-1">
    event_available
  </i>
    New Reschedule Request
</button>

<ng-template #rescheduleRequestModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title">
        {{updateMode?"Update Request":"Reschedule Request"}}
      </h4>
      <div class="ms-auto d-flex align-items-center">
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="modal-body d-flex flex-column" style="position: relative;">
        <div class="doctor-appt-details doctor-appt-details-card">
          <provider-available-slots [doctorProfile]="doctorProfile" [date]="date" (selectedSlot)="selectSlot($event)"></provider-available-slots>
          <provider-appointments [doctorProfile]="doctorProfile" [date]="date"></provider-appointments>
        </div>
        <div class="card rounded mt-1 mb-2 p-2" *ngIf="lead&&lead.consultationInfo">
            <h6>Appointment Details:</h6>
            <hr class="m-1 p-0">
            <div class="d-flex flex-column" style="font-size: 0.8em; row-gap: 0.25rem;">
                <div><span style="font-weight: 500;">Appointment Id:</span> {{appointmentId}}</div>
                <div><span style="font-weight: 500;">Date & Time:</span> {{apptDateTime}}</div>
                <div><span style="font-weight: 500;">Patient Name:</span> {{ patientName}}</div>
                <div><span style="font-weight: 500;">Doctor Name:</span> {{ doctorName}}</div>
                <div><span style="font-weight: 500;" class="pii-text">Patient Contact:</span> {{patientContact}}</div>
                <div><span style="font-weight: 500;">Category:</span> {{lead.leadCategory?lead.leadCategory:"-"}}</div>
            </div>
        </div>
        <div class="card rounded mt-1 mb-2 p-2" *ngIf="updateMode&&rescheduleRequest&&rescheduleRequest.id">
          <h6>Original Request Details:</h6>
          <hr class="m-1 p-0">
          <div class="d-flex flex-column" style="font-size: 0.8em; row-gap: 0.25rem;">
              
            <div><span style="font-weight: 500;">Suggested Date & Time:</span> {{suggestedDateTime}}</div>
            <div><span style="font-weight: 500;">Initiated By:</span> {{displayRequestType(rescheduleRequest.type)}}</div>
          </div>
      </div>
        <!-- <div style="font-weight:500;font-size: 0.9em;" class="mt-2 mb-1">Patient:</div>
        <app-patient-search [patientId]="patientId"  (getPatient)="setPatientFromSearch($event)"></app-patient-search>
        <div style="font-weight:500;font-size: 0.9em;" class="mt-3 mb-1">Doctor:</div>
        <app-doctor-search [providerId]="doctorId" (getProvider)="setProviderFromSearch($event)"></app-doctor-search> -->

        <div class="form-group mt-3" *ngIf="!updateMode">
            <div style="font-weight:500;font-size: 0.9em;" class="mb-1">Request Type:</div>
            <ng-select class="w-100" placeholder="Select type" [(ngModel)]="requestType">
                <ng-option [value]="type" *ngFor="let type of reschedulingTypes">{{displayRequestType(type)}}</ng-option>
            </ng-select>
        </div>

        <div style="font-weight:500;font-size: 0.9em;" class="mt-1">Suggested Reschedule Date & Time:</div>
        <div class="d-flex align-items-center mt-1">
            <input type="date" class="form-control" [(ngModel)]="date">
            <input type="time" class="form-control ms-3" [(ngModel)]="time">
        </div>

        <!-- <div class="form-check d-flex align-items-center" *ngIf="updateMode">
          <input class="form-check-input" type="checkbox" [(ngModel)]="sendRequestAgainCheck" id="sendWaCheck">
          <label class="form-check-label mt-2" for="sendWaCheck" style="font-size: 0.9em;">
            Send modified request to {{rescheduleRequest.type=='provider_to_client'?' patient':' doctor'}} via whatsapp?
          </label>
        </div> -->
    </div>
    <div class="modal-footer">
      <button class="btn btn-outline-dark me-2" (click)="closeModal()">
        Close
      </button>
        <button type="button" class="btn btn-outline-primary" (click)="createRequest()" *ngIf="!updateMode" [disabled]="sendingLoader">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                *ngIf="sendingLoader"></span>
            Send Request
        </button>
        <button type="button" class="btn btn-outline-primary" (click)="modifyRequest()" *ngIf="updateMode" [disabled]="sendingLoader">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
              *ngIf="sendingLoader"></span>
          Update Request
      </button>
    </div>
  </ng-template>

  <clashing-appointments-modal #apptClashModal></clashing-appointments-modal>