import { Expose, plainToClass, Transform } from "class-transformer";
import { ServiceTypes } from "../../Enums/Enums";
import { Currency } from "../../Helpers/countrywiseFeeCalc";
import { AmountWithCurrency } from "../../Protocols/AmountWithCurrency";

//This is to define the membership plans for each provider
export class MembershipPlan {
  @Expose() id: string;
  @Expose() providerId: string;
  @Expose() createdOn: number;
  @Expose() updatedOn: number;
  @Expose() multiplier: number;//Just for storing
  @Expose() validity: number;
  @Expose() validityUnit: string; //minute/day/month/year/
  @Expose() noOfSessions: number;
  @Expose() sessionDurationInMinutes: number;
  @Expose() isEmailSupportOn: boolean;
  @Expose() isCareManagerSupportOn: boolean;
  @Expose() isMostPopular: boolean;
  @Expose() sessionType: ServiceTypes;
  @Expose()
  @Transform(
    (value) => {
      if (value) {
        let map = new Map<string, CountrywisePricingItem>();
        for (let entry of Object.entries(value)) {
          map.set(entry[0], plainToClass(CountrywisePricingItem, entry[1]));
        }
        return map;
      } else {
        return new Map<string, CountrywisePricingItem>();
      }
    },
    { toClassOnly: true }
  )
  countrywisePricing: Map<string, CountrywisePricingItem> = new Map();

  get validityDisplayValue() {
    return this.validity + " " + this.validityUnit + "s";
  }

  get validityDisplayValueInWeeks() {
    let val = this.validity;
    if(this.validityUnit == "day") {
      val = Math.floor(val/7);
    }
    return val;
  }

  getCountrywisePricing(country: string) {
    if (this.countrywisePricing.has(country)) {
      return this.countrywisePricing.get(country);
    } else if(this.countrywisePricing.get("zz")) {
      return this.countrywisePricing.get("ZZ");
    }
    else {
      return this.countrywisePricing.get("ZZ");
    }
  }

  getEffectiveSessionPrice(country: string) {
    if (this.countrywisePricing.has(country)) {
      const obj = this.countrywisePricing.get(country);
      const amount: number = Math.ceil(obj.sellingPrice / this.noOfSessions);
      const val = new AmountWithCurrency();
      val.amount = amount;
      val.currency = obj.currency;
      return val.displayVal;
    } else {
      return null;
    }
  }
}

export class CountrywisePricingItem {
  @Expose() country: string;
  @Expose() currency: Currency;
  @Expose() listPrice: number;
  @Expose() sellingPrice: number;
  @Expose() additionalSessionCost: number;
  @Expose() sellingPricePerSessionInINR: number;

  get listPriceDisplayVal() {
    const obj = new AmountWithCurrency();
    obj.amount = this.listPrice;
    obj.currency = this.currency;
    return obj.displayVal;
  }
  get sellingPriceDisplayVal() {
    const obj = new AmountWithCurrency();
    obj.amount = this.sellingPrice;
    obj.currency = this.currency;
    return obj.displayVal;
  }
  get additionalSessionDisplayVal() {
    const obj = new AmountWithCurrency();
    obj.amount = this.additionalSessionCost;
    obj.currency = this.currency;
    return obj.displayVal;
  }
}