import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MembershipPlanPurchased } from '../../models-ts/Entities/MembershipPlan/MembershipPlanPurchased';
import { DayjsHelper } from '../../models-ts/Helpers/dayjsHelper';
import { MembershipDetailsService } from '../../services/membership-details/membership-details.service';

@Component({
  selector: 'patient-membership-booking-selection',
  templateUrl: './patient-membership-booking-selection.component.html',
  styleUrls: ['./patient-membership-booking-selection.component.scss']
})
export class PatientMembershipBookingSelectionComponent implements OnInit {
  @Input() patientId:string;
  @Input() set providerId(val:string){
    this.getPatientMembershipDetails(val)
  }
  @Input() viewMode:boolean = false;
  loading:boolean = false;

  plansPurchased:MembershipPlanPurchased[] = [];

  planSelected:MembershipPlanPurchased = new MembershipPlanPurchased();

  @Output() selectedPlan:EventEmitter<MembershipPlanPurchased> = new EventEmitter<MembershipPlanPurchased>();

  constructor(private membershipDetailsService:MembershipDetailsService) { }

  async getPatientMembershipDetails(providerId:string){
    if(providerId&&providerId.length>0&&this.patientId&&this.patientId.length>0){
      try {
        this.loading = true;
        this.plansPurchased = []
        const plans = await this.membershipDetailsService.patientMembershipPlans(this.patientId,providerId);
        this.plansPurchased = plans.filter(p=>{return p?.pendingSessionBalance>0});
        if(this.plansPurchased&&this.plansPurchased.length==1){
          this.selectPlan(this.plansPurchased[0])
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error)
      }
    }else{
      this.plansPurchased = []
      this.loading = false;
    }
  }

  selectPlan(plan:MembershipPlanPurchased){
    if(this.viewMode) return
    this.planSelected = plan;
    this.selectedPlan.emit(plan);
  }

  unselectPlan(){
    this.planSelected = new MembershipPlanPurchased();
    this.selectedPlan.emit(new MembershipPlanPurchased());
  }

  ngOnInit(): void {
  }
}
