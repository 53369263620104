<div class="d-flex">
  <button *ngIf="!isVerified" type="button" class="btn btn-outline-primary" [disabled]="isVerified"
    (click)="verifyDoctor()">
    Verify ✔
  </button>
  <div class="mb-0" *ngIf="isVerified" class="text-success ms-2">Verified</div>
  <button *ngIf="!isApproved" type="button" class="btn btn-outline-success ms-2" [disabled]="isApproved"
    (click)="approveDoctor()">
    Approve 📜
  </button>
  <div class="mb-0 d-flex align-items-center justify-content-center" *ngIf="isApproved" class="text-success ms-2">
    | Visible on docvita.com <button type="button" class="btn btn-outline-danger ms-2" (click)="disapproveDoctor()">
      Disaprrove ❌
    </button>
  </div>

  <ng-container *ngIf="!isApproved">
    <button *ngIf="!isSearchable" type="button" class="btn btn-outline-success ms-2" [disabled]="isSearchable"
      (click)="searchableDoctor(true)">
      Show In Search
    </button>
    <div class="mb-0 d-flex align-items-center justify-content-center" *ngIf="isSearchable" class="text-success ms-2">
        | Searchable on docvita.com <button type="button" class="btn btn-outline-danger ms-2"
        (click)="searchableDoctor(false)">
        Not Searchable ❌
      </button>
    </div>
  </ng-container>
</div>