<button class="btn btn-sm btn-outline-primary border-0 d-flex justify-content-center align-items-center" (click)="openModal(refund)">
    <i style="font-size: 15px" class="material-icons me-1">
        reply
    </i>
     Refund
</button>

<ng-template #refund let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Initiate Refund</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()" style="outline: none;">
            <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div class="modal-body d-flex flex-column justify-content-center w-100">
            <div class="card rounded mt-1 p-2">
                <h5>Details:</h5>
                <hr class="m-1 p-0">
                <div><span style="font-weight: 500;">Name:</span> {{leadDetails.patientName?leadDetails.patientName:"-"}}</div>
                <div><span style="font-weight: 500;">Contact:</span> {{leadDetails.patientContactInfo?('+'+leadDetails.patientContactInfo.countryCode+'-'+leadDetails.patientContactInfo.number):""}}</div>
                <div><span style="font-weight: 500;">Amount Received:</span> {{leadDetails.consultationInfo.totalAmountPaid}}</div>

            </div>
            <h6 class="mt-3">Refund Amount(₹)</h6>
            <input type="number" class="form-control" style="width: 180px;" [(ngModel)]="refundAmount">
            
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" (click)="initiateRefund()">Refund</button>
        </div>
</ng-template>