import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppUpdatesService {

  constructor(private httpClient:HttpClient) { }

  forceRefreshApp(){
    const headers = new HttpHeaders().set('Cache-Control', 'no-cache').set('Pragma', 'no-cache');
    this.httpClient.get("", { headers, responseType: "text" }).subscribe(() => location.reload());
  }
}
