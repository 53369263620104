import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { ClientLedgerItem } from 'src/app/shared/models-ts/Protocols/ClientLedgerItem';
import { ProviderLedgerItem } from 'src/app/shared/models-ts/Protocols/ProviderLedgerItem';
import { MoneyInLedgerItem } from '../../shared/models-ts/Protocols/MoneyInLedgerItem';
import { environment as env, environment } from 'src/environments/environment';
import { DocVitaEarningLedgerItem } from 'src/app/shared/models-ts/Protocols/DocVitaEarningLedgerItem';
import { TransactionSource } from 'src/app/shared/models-ts/Enums/pay';
import { Currency } from 'src/app/shared/models-ts/Helpers/countrywiseFeeCalc';
@Injectable()
export class LedgerService {
  constructor(private http: HttpClient) { }

  getMoneyInLedger() {
    return new Promise<MoneyInLedgerItem[]>((resolve, reject) => {
      let arr: MoneyInLedgerItem[] = [];
      firebase
        .firestore()
        .collection('money-in-ledger')
        .orderBy('createdOn', 'desc')
        .get()
        .then((snap) => {
          if (!snap.empty) {
            snap.docs.forEach((doc) => {
              if (doc.exists) {
                let m = plainToClass(MoneyInLedgerItem, doc.data());
                arr.push(m);
              }
            });
          }
          resolve(arr);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getDocVitaEarningsLedger() {
    return new Promise<DocVitaEarningLedgerItem[]>((resolve, reject) => {
      let arr: DocVitaEarningLedgerItem[] = [];
      firebase
        .firestore()
        .collection('docvita-earning-ledger')
        .orderBy('createdOn', 'desc')
        .get()
        .then((snap) => {
          if (!snap.empty) {
            snap.docs.forEach((doc) => {
              if (doc.exists) {
                let m = plainToClass(DocVitaEarningLedgerItem, doc.data());
                arr.push(m);
              }
            });
          }
          resolve(arr);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getClientLedger(clientId: string) {
    return new Promise<ClientLedgerItem[]>((resolve, reject) => {
      let arr: ClientLedgerItem[] = [];
      firebase
        .firestore()
        .collection('client-ledger')
        .where("clientId", "==", clientId)
        .orderBy('createdOn', 'desc')
        .get()
        .then((snap) => {
          if (!snap.empty) {
            snap.docs.forEach((doc) => {
              if (doc.exists) {
                let m = plainToClass(ClientLedgerItem, doc.data());
                arr.push(m);
              }
            });
          }
          resolve(arr);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getProviderLedger(providerId: string) {
    return new Promise<ProviderLedgerItem[]>((resolve, reject) => {
      let arr: ProviderLedgerItem[] = [];
      firebase
        .firestore()
        .collection('provider-ledger')
        .where('providerId', '==', providerId)
        .orderBy('createdOn', 'asc')
        .get()
        .then((snap) => {
          if (!snap.empty) {
            snap.docs.forEach((doc) => {
              if (doc.exists) {
                let m = plainToClass(ProviderLedgerItem, doc.data());
                arr.push(m);
              }
            });
          }
          resolve(arr);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getProviderLedgerAllEntries() {
    return new Promise<ProviderLedgerItem[]>((resolve, reject) => {
      let arr: ProviderLedgerItem[] = [];
      firebase
        .firestore()
        .collection('provider-ledger')
        .orderBy('createdOn', 'desc')
        .get()
        .then((snap) => {
          if (!snap.empty) {
            snap.docs.forEach((doc) => {
              if (doc.exists) {
                let m = plainToClass(ProviderLedgerItem, doc.data());
                if (m.createdOn > 1644690599000) {
                  arr.push(m);
                }
              }
            });
          }
          resolve(arr);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  customAppointmentCreditEntry(leadId: string, ledgerItem: any) {
    return new Promise((resolve, reject) => {
      let body = { leadId, ledgerItem };
      let url = env['custom-appointment-credit-entry'];
      this.http
        .post(url, body)
        .toPromise()
        .then((res) => {
          if (
            res &&
            res['statusCode'] == 200 &&
            res['body']['success'] == true
          ) {
            resolve(true);
          } else {
            reject();
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  creditPurchaseEntryToWallet(clientId: string,
    amount: number,
    paymentSource: string,
    paymentId: string,
    createdOn: number, currency: Currency) {
    return new Promise((resolve, reject) => {
      let body = { clientId, amount, paymentSource, paymentId, createdOn ,currency };
      let url = env['add-client-wallet-balance'];
      this.http
        .post(url, body)
        .toPromise()
        .then((res) => {
          if (
            res &&
            res['statusCode'] == 200 &&
            res['body']['success'] == true
          ) {
            resolve(true);
          } else {
            reject();
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  getOrderInfoRZP(orderId: string) {
    return new Promise((resolve, reject) => {
      let url = environment["rzp-get-order-info"]
      this.http.post(url, { orderId }).subscribe((resp) => {
        console.log(resp, resp["body"], resp["body"]["data"])
        if (resp && resp["body"] && resp["body"]["data"]) {
          resolve(resp["body"]["data"])
        } else {
          reject();
        }
      }, (err) => {
        console.error(err);
        reject(err);
      })
    })
  }

  fixOldAppointmentWithWalletCredit(leadId: string) {
    return new Promise((resolve, reject) => {
      let url = environment["ledger-fix-wallet-balance-appointment-updation"]
      this.http.post(url, { leadId }).subscribe((resp) => {
        console.log(resp, resp["body"], resp["body"]["data"])
        if (resp && resp["body"] && resp["body"]["data"]) {
          resolve(resp["body"]["data"])
        } else {
          reject();
        }
      }, (err) => {
        console.error(err);
        reject(err);
      })
    })
  }

  walletToWalletTransfer(senderClientId: string, receiverClientId: string, amount: number, createdOn?: number) {
    let url = environment["wallet-to-wallet-transfer"];
    if (senderClientId && receiverClientId && amount) {
      this.http.post(url, { senderClientId, receiverClientId, amount, createdOn }).subscribe(resp => {
        if (resp) {
          console.log(resp);
        }
      })
    } else {

    }
  }

  partialAppointmentWithWalletCredit(leadId: string, amount: number, currency?:Currency,createdOn?:number) {
    return new Promise((resolve, reject) => {
      let url = environment["ledger-fix-partial-wallet-balance-appointment-updation"]
      let body = { leadId, amount }
      if (currency != null) body['currency'] = currency;
      if (createdOn != null) body['createdOn'] = createdOn;
      this.http.post(url, body).subscribe((resp) => {
        if (resp && resp["body"] && resp["body"]["success"]) {
          resolve(resp["body"])
        } else {
          reject();
        }
      }, (err) => {
        console.error(err);
        reject(err);
      })
    })
  }

  partnerWalletToAppointmentWallet(leadId: string,partnerId:string, amount: number, currency:Currency) {
    return new Promise((resolve, reject) => {
      let url = environment["partner-wallet-to-appointment-wallet"]
      let body = { leadId, partnerId, amount, currency }
      this.http.post(url, body).subscribe((resp) => {
        if (resp && resp["body"] && resp["body"]["success"]) {
          resolve(resp["body"])
        } else {
          reject();
        }
      }, (err) => {
        console.error(err);
        reject(err);
      })
    })
  }

  appointmentWalletToPartnerWallet(leadId: string, partnerId:string, amount: number, currency:Currency) {
    return new Promise((resolve, reject) => {
      let url = environment["appointment-wallet-to-partner-wallet"]
      let body = { leadId, partnerId, amount, currency }
      this.http.post(url, body).subscribe((resp) => {
        if (resp && resp["body"] && resp["body"]["success"]) {
          resolve(resp["body"])
        } else {
          reject();
        }
      }, (err) => {
        console.error(err);
        reject(err);
      })
    })
  }

  appointmentWalletToPlanWallet(leadId: string, planPurchaseId:string, amount: number, currency:Currency) {
    return new Promise((resolve, reject) => {
      let url = environment["appointment-wallet-to-plan-wallet"]
      let body = { leadId, planPurchaseId, amount, currency }
      this.http.post(url, body).subscribe((resp) => {
        if (resp && resp["body"] && resp["body"]["success"]) {
          resolve(resp["body"])
        } else {
          reject();
        }
      }, (err) => {
        console.error(err);
        reject(err);
      })
    })
  }

  refundFromAppointmentToWalletCredit(leadId: string, clientId: string, amount: number, currency?:Currency, acceptedAmount?:number, acceptedAmountCurrency?:Currency, excRate?:number, providerDisplayAmount?:number, providerDisplayCurrency?:Currency) {
    return new Promise((resolve, reject) => {
      let url = environment["refund-to-wallet-from-appointment-entry"]
      let body = { leadId, clientId, amount }
      if (acceptedAmount != null) body['acceptedAmount'] = acceptedAmount;
			if (acceptedAmountCurrency)
				body['acceptedAmountCurrency'] = acceptedAmountCurrency;
			if (excRate != null) body['excRate'] = excRate;
			if (currency != null) body['currency'] = currency;
			if (providerDisplayAmount != null)
				body['providerDisplayAmount'] = providerDisplayAmount;
			if (providerDisplayCurrency != null)
				body['providerDisplayCurrency'] = providerDisplayCurrency;
      this.http.post(url, body).subscribe((resp) => {
        if (resp && resp["body"] && resp["body"]["success"]) {
          resolve(resp["body"])
        } else {
          reject();
        }
      }, (err) => {
        console.error(err);
        reject(err);
      })
    })
  }

  async updateLedgerEntriesTrack(leadLedgerItemId:string, leadId:string, updatedAmount:number, updatedCurrency:Currency){
    try {
      const url = environment['update-ledger-entries-path']
      let body = { leadLedgerItemId, leadId, updatedAmount, updatedCurrency }
      body = JSON.parse(JSON.stringify(body))
      const resp = await this.http.post(url, body).toPromise()
      if(resp&&resp['statusCode']==200&&resp['body']&&resp['body']['success']){
        return true;
      }else{
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async manualPayoutEntry(providerId:string, utr:string, amount:number, currency:Currency, createdOn: number, creditSource: string){
    try {
      const url = environment['provider-ledger-manual-transfer-entry']
      let body = { providerId, utr, amount, currency, createdOn, creditSource };
      body["meta"] = {
        utr: utr,
        status: "SUCCESS",
        settlementAmount: amount,
        subCode: "200",
        updatedOn: createdOn,
        transferMode: "banktransfer",
        message: "Transfer completed successfully",
        source: creditSource
      }
      body = JSON.parse(JSON.stringify(body))
      const resp = await this.http.post(url, body).toPromise()
      if(resp&&resp['statusCode']==200&&resp['body']&&resp['body']['success']){
        return true;
      }else{
        return null;
      }
    } catch (error) {
      return null;
    }
  }
}
