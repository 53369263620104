import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { ToastrService } from 'ngx-toastr';
import { from, Subscription } from 'rxjs';
import { DoctorListingProfile } from 'src/app/shared/models-ts/Actors/Doctor/DoctorListingProfile';
import { User } from 'src/app/shared/models-ts/Actors/User/User';
import { PaymentTransaction } from 'src/app/shared/models-ts/Entities/PaymentTransaction/PaymentTransaction';
import { AuthUserInfoService } from 'src/app/shared/services/auth-user-info/auth-user-info.service';
import { DoctorDataService } from 'src/app/shared/services/doctor-data/doctor-data.service';
import { DoctorsService } from 'src/app/shared/services/doctors/doctors.service';
import { CopyClipboardService } from '../../shared/services/copy-clipboard/copy-clipboard.service';
import { Location } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PatientLeadsBackendService } from 'src/app/patient-leads/services/patient-leads-backend.service';
import { CRMUser } from 'src/app/shared/models-ts/Actors/User/CRMUser';
declare var $: any;
@Component({
  selector: 'app-doc-profile-payments',
  templateUrl: './doc-profile-payments.component.html',
  styleUrls: ['./doc-profile-payments.component.css'],
})
export class DocProfilePaymentsComponent implements OnInit, OnDestroy {
  currentTransaction: PaymentTransaction = new PaymentTransaction();
  allTransactions: PaymentTransaction[] = new Array();
  currentHeroInfo: CRMUser;
  dataLayerSubscription: Subscription;
  profileData: DoctorListingProfile;
  displayPaymentLink: string;
  modalLoader: boolean = false;
  showPaymentLink: boolean = false;
  isLoading: boolean = false;
  noPayments: boolean = false;
  purposeTags = ['Consultation', 'Treatment', 'LabTest', 'Medicines'];
  selectedPurposeTags: string[] = [];
  formInputSettlementAmount: number;
  isInitiatedTransfer: boolean = false;

  constructor(
    private _doctorDataService: DoctorDataService,
    private _authUserInfoService: AuthUserInfoService,
    private _doctorService: DoctorsService,
    private _activatedRoute: ActivatedRoute,
    private _toastr: ToastrService,
    private _copyToClipboardService: CopyClipboardService,
    private location: Location,
    private modalService: BsModalService,
    private _patientLeadsBackendService: PatientLeadsBackendService
  ) {}

  ngOnInit(): void {
    this.currentHeroInfo = this._authUserInfoService.getUserInfo();
    this.dataLayerSubscription =
      this._doctorDataService.currentSelection.subscribe((data) => {
        if (data != null && data['memberSelected'] != null) {
          this.profileData = data['memberSelected'];
          this.getAllTransactions();
        }
      });
    this._activatedRoute.queryParams.subscribe((params) => {
      let cname = params['cname'];
      let cnumber = params['cnumber'];
      if ((cname && cname.length > 0) || (cnumber && cnumber.length > 0)) {
        this.createNewPayment(cname, cnumber);
        this.location.replaceState(
          `/profile-listing/${this.profileData.id}/payments`
        );
      }
    });
  }

  getAllTransactions() {
    this.isLoading = true;
    this.allTransactions = [];
    this._doctorService.getPaymentTransactions(this.profileData.id).onSnapshot(
      (snap) => {
        if (!snap.empty) {
          snap.forEach((data) => {
            let _ = plainToClass(PaymentTransaction, data.data());
            this.allTransactions.push(_);
          });
          this.isLoading = false;
          this.noPayments = false;
          // console.log(this.allTransactions);
        } else {
          this.isLoading = false;
          this.noPayments = true;
        }
      },
      (err) => {
        console.log(err);
        this.isLoading = false;
        this._toastr.error('Failed to fetch payments!');
      }
    );
  }

  createNewPayment(cname?: string, cnumber?: string) {
    this.currentTransaction = new PaymentTransaction();
    if (cname && cname.length > 0)
      this.currentTransaction.customerInfo.name = cname;
    if (cnumber && cnumber.length > 0)
      this.currentTransaction.customerInfo.contactNumber.number = cnumber;
    this.selectedPurposeTags = [];
    this.currentTransaction.providerAccountInfo.id = this.profileData.id;
    this.currentTransaction.customerInfo.contactNumber.countryCode = '91';
    if (
      this.currentTransaction.customerInfo.email == null ||
      this.currentTransaction.customerInfo.email.length == 0
    ) {
      this.currentTransaction.customerInfo.email = 'payments@docvita.in';
    }
    this.showPaymentLink = false;
    this.displayPaymentLink = '';
    $('#paymentsModal').modal('show');
  }

  makePaymentRequest() {
    if (
      (this.currentTransaction.customerInfo.contactNumber.number &&
        this.currentTransaction.customerInfo.contactNumber.number.length ==
          0) ||
      (this.currentTransaction.customerInfo.contactNumber.number &&
        this.currentTransaction.customerInfo.contactNumber.number.toString()
          .length != 10) ||
      this.selectedPurposeTags.length == 0 ||
      this.currentTransaction.amount == null
    ) {
      this._toastr.error('Form details missing or incorrect!');
    } else {
      if (this.currentTransaction.customerInfo.name == undefined)
        this.currentTransaction.customerInfo.name = '';
      if (this.currentTransaction.customerInfo.email == undefined)
        this.currentTransaction.customerInfo.email = 'payments@docvita.in';
      if (this.currentTransaction.description == undefined)
        this.currentTransaction.description = '';
      // console.log("transaction det.: ",this.currentTransaction)
      this.modalLoader = true;
      this._doctorService
        .createPayLink(
          this.currentTransaction,
          this.currentHeroInfo,
          this.selectedPurposeTags
        )
        .subscribe(
          (res) => {
            if (
              res['statusCode'] == 200 &&
              res['body']['success'] == true &&
              res['body']['data']
            ) {
              this.modalLoader = false;
              this.showPaymentLink = true;
              let data = res['body']['data'];
              this.displayPaymentLink = data['url'];
            } else {
              this.modalLoader = false;
              this.showPaymentLink = false;
              this._toastr.error('Payment link failed to generate!');
            }
          },
          (err) => {
            this.modalLoader = false;
            this.showPaymentLink = false;
            this._toastr.error('Payment link failed to generate!');
            console.log(err);
          }
        );
    }
  }

  copyInfo(data) {
    if (data != null || data.length > 0) {
      this._copyToClipboardService.copyToClipoard(data);
    } else {
      this._toastr.error('Failed to Copy!');
    }
  }

  openIntiateTransferModal(content, t: PaymentTransaction) {
    this.currentTransaction = t;
    this.formInputSettlementAmount = 0;
    this.modalService.show(content, { ariaLabelledBy: 'modal-basic-title' });
  }

  confirmIntiateTransferModal(content) {
    if (
      this.formInputSettlementAmount == null ||
      this.formInputSettlementAmount == 0 ||
      this.formInputSettlementAmount > this.currentTransaction.amount
    ) {
      this._toastr.error('Amount is invalid');
    } else {
      this.modalService.show(content, { ariaLabelledBy: 'modal-basic-title' });
      // .then(
      //   (result) => {
      //     // this.closeResult = `Closed with: ${result}`;
      //     this.isInitiatedTransfer = false;
      //   },
      //   (reason) => {
      //     this.isInitiatedTransfer = false;
      //     // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      //   }
      // );
    }
  }
  closeModal() {
    this.isInitiatedTransfer = false;
    this.modalService.hide();
  }

  initiateTransfer() {
    if (this.currentTransaction) {
      this.isInitiatedTransfer = true;
      this._patientLeadsBackendService
        .initiatePaymentLink(
          this.currentTransaction.id,
          this.formInputSettlementAmount
        )
        .then((resp) => {
          let statusCode = resp['statusCode'];
          switch (statusCode) {
            case 200: {
              this._toastr.success('Initiated successfully');
              this.modalService.hide();
              break;
            }
            case 912:
            case 913:
            case 914:
            case 915:
              this._toastr.error(resp['body']['message']);
              this.modalService.hide();
              break;
            default:
              this.modalService.hide();
          }
          this.isInitiatedTransfer = false;
        })
        .catch((err) => {
          console.error(err);
          this.isInitiatedTransfer = false;
        });
    } else {
      this.isInitiatedTransfer = false;
    }
  }

  refreshTransferStatus(t: PaymentTransaction) {
    if (t && t.getLatestPayout) {
      this.isInitiatedTransfer = true;
      this._patientLeadsBackendService
        .refreshTransferStatusPaymentLink(t.id, t.getLatestPayout.transferId)
        .then((resp) => {
          let statusCode = resp['statusCode'];
          switch (statusCode) {
            case 200: {
              this._toastr.success('Initiated successfully');
              this.isInitiatedTransfer = true;
              break;
            }
            case 912:
            case 913:
            case 914:
            case 915:
              this._toastr.error(resp['body']['message']);
              this.isInitiatedTransfer = true;
              break;
            default:
              this.isInitiatedTransfer = true;
          }
        })
        .catch((err) => {
          console.error(err);
          this.isInitiatedTransfer = false;
        });
    }
  }

  ngOnDestroy() {
    this.dataLayerSubscription.unsubscribe();
  }
}
