<div class="w-100 position-relative">
    <!-- booked slots  -->
    <ng-container *ngIf="timeslot && timeslot['reason']=='appointment-booking'">
        <div class="time-row time-row-booked">
            <span class="time time-booked">
                <span>
                    <!-- 10:00 AM -->
                    {{ getTimeInhhmma(timeslot['startTime']) || '' }}
                </span>
                <!-- <img class="more_action_icon" src="../../../../assets/icons/more_icon.svg" /> -->
            </span>
            <span class="status status-booked">
                Booked
            </span>
        </div>
    </ng-container>

    <!-- appt schedule blocked slots  -->
    <ng-container *ngIf="timeslot && timeslot['reason']=='appointment-schedule'">
        <div class="time-row time-row-booked">
            <span class="time time-booked">
                <span>
                    <!-- 10:00 AM -->
                    {{ getTimeInhhmma(timeslot['startTime']) || '' }}
                </span>
                <!-- <img class="more_action_icon" src="../../../../assets/icons/more_icon.svg" /> -->
            </span>
            <span class="status status-booked">
                Scheduled
            </span>
        </div>
    </ng-container>

    <!-- open slots  -->
    <ng-container *ngIf="timeslot && timeslot['isAvailable']">

        <ng-container *ngIf="!(slotToBlock&&slotToBlock==(date+timeslot['startTime']))">
            <div class="time-row " (click)="confirmBlockSlot(date,timeslot)">
                <span class="time time-open">
                    <span>
                        <!-- 11:00 AM -->
                        {{ getTimeInhhmma(timeslot['startTime']) || '' }}
                    </span>
                </span>

                <span class="status status-open">
                    Open
                    <!-- <img class="more_action_icon" src="../../../../assets/icons/more_icon.svg" /> -->
                </span>

                <span class="action">
                    Close this slot
                </span>
            </div>
        </ng-container>

        <ng-container *ngIf="slotToBlock&&slotToBlock==(date+timeslot['startTime'])">
            <div class="time-row">
                <span class="time time-action">
                    <!--Close 11:00 AM ?-->
                    Close {{ getTimeInhhmma(timeslot['startTime']) || '' }} ?
                </span>

                <span class="d-flex gap-3" *ngIf="!blockingSlotLoader">
                    <a class="slot__action" (click)="blockSlot(date,timeslot)">Yes</a>
                    <a class="slot__action" (click)="clearBlockSlot()">No</a>
                </span>
                <div class="spinner-border spinner-border-sm" role="status" *ngIf="blockingSlotLoader">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </ng-container>
    </ng-container>

    <!-- blocked slots  -->
    <ng-container
        *ngIf="timeslot && !timeslot['isAvailable'] && timeslot['reason']!='appointment-booking' && timeslot['reason']!='appointment-schedule'">
        
            <ng-container *ngIf="!(slotToUnBlock&&slotToUnBlock==(date+timeslot['startTime']))">
                <div class="time-row" (click)="confirmUnBlockSlot(date,timeslot)">
                    <span class="time time-closed">
                        <!-- 12:00 PM -->
                        {{ getTimeInhhmma(timeslot['startTime']) || '' }}
                    </span>
                    <span class="status status-closed"
                        [style]="((timeslot['reason']=='past')||isInPast(date,timeslot['startTime']))?'display:block':''">
                        Closed
                    </span>
                    <span class="action" *ngIf="!(timeslot['reason']=='past'||isInPast(date,timeslot['startTime']))">
                        Open this slot
                    </span>
                </div>
            </ng-container>
            
            <ng-container *ngIf="slotToUnBlock&&slotToUnBlock==(date+timeslot['startTime'])">
                <div class="time-row">
                    <span class="time time-action">
                        <!--Close 11:00 AM ?-->
                        Open {{ getTimeInhhmma(timeslot['startTime']) || '' }} ?
                    </span>
            
                    <span class="d-flex gap-3" *ngIf="!unblockingSlotLoader">
                        <a class="slot__action" (click)="unblockSlot(date,timeslot)">Yes</a>
                        <a class="slot__action" (click)="clearUnBlockSlot()">No</a>
                    </span>
                    <div class="spinner-border spinner-border-sm" role="status" *ngIf="unblockingSlotLoader">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </ng-container>
    </ng-container>

    <div class="success-gif-bg" *ngIf="showSuccessCheck">
        <img src="../../../assets/gifs/success_check_anim.gif" alt="success-gif" style="width: 20px;aspect-ratio: 1;">
    </div>


</div>
