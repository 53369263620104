<div class="btn-group" dropdown>
    <button id="button-animated" dropdownToggle type="button" class="btn btn-outline-primary dropdown-toggle"
        aria-controls="dropdown-animated"> 
        {{_selectedOption?_selectedOption:''}} 
        <span class="caret"></span>
    </button>
    <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-animated">
        <li role="menuitem" *ngFor="let item of dateOptions|keyvalue:returnZro;">
            <a class="dropdown-item" (click)="selectOption(item.key)">{{item.value}}</a>
        </li>
    </ul>
</div>