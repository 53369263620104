import { Expose } from "class-transformer";
import { CountrywiseFeeCalc, Currency } from "../Helpers/countrywiseFeeCalc";

export class AmountWithCurrency {
  @Expose() amount: number;
  @Expose() currency: Currency = Currency.INR;

  get symbol() {
    return new CountrywiseFeeCalc().currencyToSymbol(this.currency);
  }

  get displayVal() {
    return (
      this.amount > 0 ? (new CountrywiseFeeCalc().currencyToSymbol(this.currency) + this.amount): ""
    );
  }
}
