<button class="btn btn-sm btn-outline-primary" *ngIf="showButton" (click)="openModal();">
    View Reschedule Requests
</button>

<ng-template #viewRescheduleRequests let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">
        Reschedule Requests
      </h5>
      <div class="ms-auto d-flex align-items-center">
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="modal-body d-flex flex-column">
        <div class="d-flex align-items-center flex-wrap">
            <div class="d-flex flex-column m-1">
                <div style="font-weight:500;font-size: 0.75em;">Doctor:</div>
                <app-doctor-search style="width: 19rem;" [providerId]="doctorId" (getProvider)="setProviderFromSearch($event)"></app-doctor-search>
            </div>
            <div class="d-flex flex-column m-1">
                <div style="font-weight:500;font-size: 0.75em;">Patient:</div>
                <app-patient-search style="width: 19rem;" [patientId]="patientId"  (getPatient)="setPatientFromSearch($event)"></app-patient-search>
            </div>
            <div class="d-flex flex-column m-1">
                <div style="font-weight:500;font-size: 0.75em;">Request Type:</div>
                <ng-select style="width: 12rem;" placeholder="Select request type" [(ngModel)]="requestType">
                    <ng-option [value]="type" *ngFor="let type of reschedulingTypes">{{_toReadable(type)}}</ng-option>
                </ng-select>
            </div>
            <div class="d-flex flex-column m-1">
                <div style="font-weight:500;font-size: 0.75em;">Status:</div>
                <ng-select style="width: 12rem;" placeholder="Select request status" [(ngModel)]="status">
                    <ng-option [value]="stat" *ngFor="let stat of reschedulingStatuses">{{_toReadable(stat)}}</ng-option>
                </ng-select>
            </div>
            
        </div>
        
        <div class="d-flex flex-column align-items-center w-100 mt-3" style="min-height: 400px;">
            <ng-container *ngIf="!isLoadingList">
                <div class="list-outer-container"> 
                    <div class="card p-3 w-100" *ngFor="let item of filteredRequests">
                        <div>
                           {{item.createdOn}} 
                        </div>
                    </div>
                </div>
            </ng-container>
            
            <div class="spinner-border text-primary" style="margin-top: 6rem;" role="status" *ngIf="isLoadingList">
                <span class="sr-only">Loading...</span>
            </div>
            <div *ngIf="rescheduleRequestList.length==0&&!isLoadingList" style="margin-top: 6rem;">
                No Requests ☕️
            </div>
        </div>
    </div>
    
  </ng-template>