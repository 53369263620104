import { Expose } from "class-transformer";
import * as dayjs from "dayjs";
import * as timezone from "dayjs/plugin/timezone";
import * as utc from "dayjs/plugin/utc";
import { Currency } from "../Helpers/countrywiseFeeCalc";
import { AmountWithCurrency } from "./AmountWithCurrency";
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault("Asia/Kolkata");
export class LedgerItem {
	@Expose() createdOn: number;
	@Expose() debit: number;
	@Expose() credit: number;
	@Expose() currency: Currency;
	@Expose() acceptedAmount: number;
	@Expose() acceptedAmountCurrency: Currency;
	@Expose() excRate: number;
	@Expose() providerDisplayAmount: number;
	@Expose() providerDisplayCurrency: Currency;

	get displayDebitAmountWithCurrency(): AmountWithCurrency {
		let obj = new AmountWithCurrency();
		obj.amount = this.debit;
		obj.currency =  this.currency;
		return obj;
	}
	get displayCreditAmountWithCurrency(): AmountWithCurrency {
		let obj = new AmountWithCurrency();
		obj.amount = this.credit;
		obj.currency =  this.currency;
		return obj;
	}

	get providerDisplayAmountHelper(): AmountWithCurrency {
		let obj = new AmountWithCurrency();
		obj.amount = this.providerDisplayAmount? this.providerDisplayAmount: (this.credit > 0? this.credit: null);
		obj.currency =  this.providerDisplayCurrency? this.providerDisplayCurrency: Currency.INR;
		return obj;
	}

	get displayAmountPaidByClientHelper(): AmountWithCurrency {
		let obj = new AmountWithCurrency();
		obj.amount = this.credit > 0? this.credit: null;
		obj.currency =  this.currency? this.currency: Currency.INR;
		return obj;
	}

	get actualAmountInINRHelper(): AmountWithCurrency {
		let obj = new AmountWithCurrency();
		if(this.acceptedAmountCurrency == Currency.INR && this.acceptedAmount > 0) {
			obj.amount = this.acceptedAmount;	
		} else {
			obj.amount = this.credit;
		}
		obj.currency =  Currency.INR;
		return obj;
	}

	get displayDate(): string {
		return this.createdOn
			? dayjs(new Date(this.createdOn)).format("DD MMM YYYY")
			: "";
	}

	get dateYYYYMMDD(): string {
		return this.createdOn
			? dayjs(this.createdOn).format("YYYY-MM-DD")
			: "";
	}

	get displayDateTime(): string {
		return this.createdOn
			? dayjs(new Date(this.createdOn)).format(
					"DD MMM YYYY hh:mm a"
			  )
			: "";
	}
}
