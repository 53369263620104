import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';
import { AuthApiService } from './auth/services/auth-api.service';
import { AuthUserInfoService } from './shared/services/auth-user-info/auth-user-info.service';
import { TextBlurService } from './shared/services/text-blur/text-blur.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  constructor(
    private _authUserInfoService: AuthUserInfoService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _textBlur:TextBlurService,
    private _toastrService: ToastrService,
    private _authApiService: AuthApiService
  ) {}

  ngOnInit() {
    let route = window.location.href.split('/').pop();
    if (route == 'agora' || route == 'zoomverify' || route == '' || route == null) {
    } else {
      firebase.auth().onAuthStateChanged(
        (user) => {
          if (
            user == null ||
            this._authUserInfoService.getUserInfo() == null ||
            this._authUserInfoService.getUserInfo().docvitaId == null
          ) {
            this._router.navigateByUrl('auth');
          } else {
            if(this._authUserInfoService.getUserInfo() && this._authUserInfoService.getUserInfo().suspended) {
              this._authUserInfoService.signout();
              this._toastrService.error("User account suspended. Please contact admin")
            } else if( this._authUserInfoService.getUserInfo() && this._authUserInfoService.getUserInfo()["healthHeroInfo"] != null) {
              this._authUserInfoService.signout();
            }else {
              this._authUserInfoService.getHealthHeroProfile(user.uid);
            }
          }
        },
        (error) => {
          console.error(error);
        }
      );
    }
    this.reauthCheck();
    this.blurTextHandler();
  }

  reauthCheck() {
    this._authApiService.recheckAuth()
    .then(()=>{
      //Nothing
    }).catch(err=>{
      this._authUserInfoService.signout();
    })
  }

  blurTextHandler(){
    let blurBool = this._textBlur.getBlurState();
    if(blurBool){
      var css = `.pii-text { color: transparent;text-shadow: 0 0 8px #000;}`;
      var head = document.head || document.getElementsByTagName('head')[0];
      var style = document.createElement('style');
      head.appendChild(style);
      style.appendChild(document.createTextNode(css));
    }
  }
}
