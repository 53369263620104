<button class="btn btn-outline-primary border-1 d-flex align-items-center" (click)="openSearchModal()"
  *ngIf="showButton">
  <i class="feather icon-search fw-600"></i>
  <div>
    <span class="ms-2">Search Client</span>
  </div>
</button>

<ng-template #patientSearch let-modal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      <i class="feather icon-search fw-600 me-2"></i>
      Client Search
    </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body d-flex flex-column w-100" style="min-height: 300px">
    <app-patient-search [searchText]="searchTerm" [specificProvider]="true" [focusOnInit]="true"
      (getPatient)="setPatientFromSearch($event)"></app-patient-search>
  </div>
</ng-template>

<ng-template #patientInfo let-modal>
  <app-patient-info (close)="closeModal2()" [clientId]="modalPatientProfile.docvitaId"
    [client]="modalPatientProfile"></app-patient-info>
</ng-template>