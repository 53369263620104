import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { DoctorListingProfile } from 'src/app/shared/models-ts/Actors/Doctor/DoctorListingProfile';
import { User } from 'src/app/shared/models-ts/Actors/User/User';
import { AuthUserInfoService } from 'src/app/shared/services/auth-user-info/auth-user-info.service';
import { DoctorDataService } from 'src/app/shared/services/doctor-data/doctor-data.service';
import { DoctorsService } from 'src/app/shared/services/doctors/doctors.service';
import firebase from 'firebase/app';
import 'firebase/database';
import { BsModalService } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import { CRMUser } from 'src/app/shared/models-ts/Actors/User/CRMUser';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault('Asia/Kolkata');
@Component({
  selector: 'app-doc-open-slots',
  templateUrl: './doc-open-slots.component.html',
  styleUrls: ['./doc-open-slots.component.css'],
})
export class DocOpenSlotsComponent implements OnInit {
  profileData: DoctorListingProfile = new DoctorListingProfile();
  currentHeroInfo: CRMUser;
  dataLayerSubscription: Subscription;
  openSlots: any[];
  selectedSlot: any;
  loader: boolean = false;
  fromDate: string;
  toDate: string;
  updateMode: boolean = false;
  constructor(
    private _doctorDataService: DoctorDataService,
    private _authUserInfoService: AuthUserInfoService,
    private _doctorService: DoctorsService,
    private _toastr: ToastrService,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.currentHeroInfo = this._authUserInfoService.getUserInfo();
    this.openSlots = [];
    this.dataLayerSubscription =
      this._doctorDataService.currentSelection.subscribe((data) => {
        this.openSlots = [];
        if (data != null && data['memberSelected'] != null) {
          this.profileData = data['memberSelected'];
          if (this.profileData.openSlots) {
            let _ = Object.assign({}, this.profileData.openSlots);
            for (let i in _) {
              let __ = {
                date: _[i]['date'],
                stime: _[i]['timeslot'].split('-')[0],
                etime: _[i]['timeslot'].split('-')[1],
                type: _[i]['type'],
                id: i,
              };

              this.openSlots.push(__);
            }
          }
        }
      });
  }

  addOpenSlot(content) {
    let key = firebase.database().ref().push().key;
    let data = {
      date: '',
      stime: '',
      etime: '',
      type: 'online',
    };
    this.openModal(content, data, key, false);
  }

  update(key) {
    let _ = {};
    _['date'] = moment(this.openSlots[key]['date']).format('YYYY-MM-DD');
    _['timeslot'] =
      this.openSlots[key]['stime'] + '-' + this.openSlots[key]['etime'];
    _['type'] = 'online';
    this._doctorService
      .updateOpenSlot(this.profileData.id, key, _)
      .then(() => {
        this._toastr.success('Updated open slot.');
      })
      .catch((err) => {
        console.log(err);
        this._toastr.error('Failed to update.');
      });
  }

  delete(key) {
    if (key) {
      if (window.confirm('Confirm Delete?')) {
        this.openSlots.splice(key, 1);
        this._doctorService
          .deleteOpenSlot(this.profileData.id, key)
          .then(() => {
            this._toastr.success('Open slot deleted.');
          })
          .catch((err) => {
            console.log(err);
            this._toastr.error('Failed to delete.');
          });
      }
    }
  }

  openModal(content, data, key, updateMode) {
    this.updateMode = updateMode;
    this.selectedSlot = JSON.parse(JSON.stringify(data));
    if (this.selectedSlot['date'] != undefined) {
      this.selectedSlot['date'] = dayjs(new Date()).format(
        'YYYY-MM-DD'
      );
    }

    this.selectedSlot['id'] = key;
    this.modalService.show(content, { ariaLabelledBy: 'modal-basic-title' });
  }

  updateSlot() {
    let promises = [];
    if (
      this.selectedSlot['endDate'] &&
      this.selectedSlot['endDate'].length > 0
    ) {
      let now = moment(this.selectedSlot['date']);
      let endDate = moment(this.selectedSlot['endDate']);
      while (now.isSameOrBefore(endDate)) {
        let date = now.format('YYYY-MM-DD');
        let key = firebase.database().ref().push().key;
        let _ = {};
        _['date'] = date;
        _['timeslot'] =
          this.selectedSlot['stime'] + '-' + this.selectedSlot['etime'];
        _['type'] = 'online';
        promises.push(
          this._doctorService.updateOpenSlot(this.profileData.id, key, _)
        );
        // promises.push(_);
        now.add(1, 'days');
      }
    } else {
      let _ = {};
      _['date'] = this.selectedSlot['date'];
      _['timeslot'] =
        this.selectedSlot['stime'] + '-' + this.selectedSlot['etime'];
      _['type'] = 'online';
      promises.push(
        this._doctorService.updateOpenSlot(
          this.profileData.id,
          this.selectedSlot['id'],
          _
        )
      );
      // promises.push(_);
    }
    // console.log(this.selectedSlot['id']);
    // console.log(promises);
    this.loader = true;
    Promise.all(promises)
      .then(() => {
        this.loader = false;
        this._toastr.success('Updated.');
        this.closeModal();
      })
      .catch((err) => {
        console.log(err);
        this.loader = false;
        this._toastr.error('Failed to update.');
      });
  }

  closeModal() {
    this.modalService.hide();
  }

  returnZero() {
    return 0;
  }

  applyFilter(): any[] {
    let _ = this.openSlots;
    if (this.fromDate && this.fromDate.length > 9) {
      _ = _.filter((da) => da && da['date'] >= this.fromDate);
    }
    if (this.toDate && this.toDate.length > 9) {
      _ = _.filter((da) => da && da['date'] <= this.toDate);
    }
    _.sort((a, b) => a.date.split('-').join('') - b.date.split('-').join(''));
    return _;
  }

  clearDate() {
    this.toDate = '';
    this.fromDate = '';
  }

  ngOnDestroy() {
    this.dataLayerSubscription.unsubscribe();
  }
  appointmentDateScroller(direction, dateType) {
    if (direction == '+' && this.selectedSlot[dateType]) {
      this.selectedSlot[dateType] = dayjs(
        new Date(this.selectedSlot[dateType])
      )
        .add(1, 'day')
        .format('YYYY-MM-DD');
    } else if (direction == '-' && this.selectedSlot[dateType]) {
      this.selectedSlot[dateType] = dayjs(
        new Date(this.selectedSlot[dateType])
      )
        .subtract(1, 'day')
        .format('YYYY-MM-DD');
    } else {
      //cool
    }
  }
}
