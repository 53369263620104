import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { DoctorListingProfile } from 'src/app/shared/models-ts/Actors/Doctor/DoctorListingProfile';
import { CRMUser } from 'src/app/shared/models-ts/Actors/User/CRMUser';
import { ServiceTypes } from 'src/app/shared/models-ts/Enums/Enums';
import { AuthUserInfoService } from 'src/app/shared/services/auth-user-info/auth-user-info.service';
import { DoctorDataService } from 'src/app/shared/services/doctor-data/doctor-data.service';
import { DoctorsService } from 'src/app/shared/services/doctors/doctors.service';

@Component({
  selector: 'app-doc-profile-fee-config',
  templateUrl: './doc-profile-fee-config.component.html',
  styleUrls: ['./doc-profile-fee-config.component.scss']
})
export class DocProfileFeeConfigComponent implements OnInit {
  dataLayerSubscription: Subscription;
  currentHeroInfo: CRMUser = new CRMUser();
  profileData:DoctorListingProfile = new DoctorListingProfile();

  serviceTypeOptions = [ServiceTypes.individual, ServiceTypes.couple, ServiceTypes.family];

  serviceWisePlainFeesMap = {
    [ServiceTypes.individual]: {fee:null,isActive:false},
    [ServiceTypes.couple]: {fee:null,isActive:false},
    [ServiceTypes.family]: {fee:null,isActive:false}
  }

  queerAffirmative:boolean = false;
  listInGSWT:boolean = false;

  updateLoader:boolean = false;

  constructor(
    private _doctorDataService: DoctorDataService,
    private _authUserInfoService: AuthUserInfoService,
    private _toastrService: ToastrService,
    private _doctorService: DoctorsService
  ) { }

  ngOnInit(): void {
    this.currentHeroInfo = this._authUserInfoService.getUserInfo();
    this.dataLayerSubscription =
      this._doctorDataService.currentSelection.subscribe((data) => {
        if (data != null && data['memberSelected'] != null) {
          this.profileData = data['memberSelected'];

          const servicewiseFeeMap = this.profileData?.onlineConsultationConfig?.servicewiseFeesMap;
          this.queerAffirmative = this.profileData?.otherInfo?.queerAffirmative || false;
          this.listInGSWT = this.profileData?.listInGSWT || false;
          for(const i of this.serviceTypeOptions){
            const iFee = servicewiseFeeMap?.get(ServiceTypes[i])?.fee || null;
            const iIsActive = servicewiseFeeMap?.get(ServiceTypes[i])?.isActive || false;
            this.serviceWisePlainFeesMap[i]['fee'] = iFee;
            this.serviceWisePlainFeesMap[i]['isActive'] = iIsActive;
          }
        }
      });
  }

  get isPsychologist(){
    return (this.profileData?.isPsychologist)?true:false;
  }

  async updateProviderFeeConfig(){
    this.updateLoader = true;
    let finalServiceWiseFeeMap:{} = {}
    const duration = this.profileData?.onlineConsultationConfig?.appointmentDuration || 60;
    for(const i in this.serviceWisePlainFeesMap){
      if(this.serviceWisePlainFeesMap[i]['fee'] == null && this.serviceWisePlainFeesMap[i]['isActive']){
        this._toastrService.error('Type '+ i + ' isActive but no fee')
        this.updateLoader = false;
        return;
      }else if(this.serviceWisePlainFeesMap[i]['fee'] && (this.serviceWisePlainFeesMap[i]['fee'] < 0)){
        this._toastrService.error('Fee for '+ i + ' session is invalid')
        this.updateLoader = false;
        return;
      }else if(this.serviceWisePlainFeesMap[i]['fee']){
        finalServiceWiseFeeMap[i] = {
          type: i,
          fee: +this.serviceWisePlainFeesMap[i]['fee'],
          currency: "INR",
          appointmentDuration: duration,
          isActive: this.serviceWisePlainFeesMap[i]['isActive']
        };
      }else{
        //do nothing
      }
    }

    const currentServicewiseFeeMap = this.profileData?.onlineConsultationConfig?.servicewiseFeesMap;
    if(currentServicewiseFeeMap && currentServicewiseFeeMap.size > 0 && Object.keys(finalServiceWiseFeeMap).length == 0){
      if(!window.confirm('The previous fee config has details. Are you sure you want to clear all details?')){
        return;
      }      
    }
    // console.log(finalServiceWiseFeeMap);
    // console.log(this.queerAffirmative)
    try {
      const updateFormDetails = await this._doctorService.feeConfigUpdate(this.profileData?.id,finalServiceWiseFeeMap,this.queerAffirmative, this.listInGSWT)  
      this.updateLoader = false;
      this._toastrService.success('Updated Fee Config Details!')
    } catch (error) {
      const errMessage = error['message'] || 'Unknown Error';
      this._toastrService.error(errMessage)
      this.updateLoader = false;
    }
    
  }

}
