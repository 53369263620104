<ng-template #dvcCreditsModal>
    <div class="modal-header">
      <h4 class="modal-title pull-left">💸 Transfer Credits To Wallet</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <ng-container *ngIf="checkingCgActiveLinks">
            <div class="d-flex flex-column align-items-center w-100 mt-3">
                <div class="spinner-border spinner-border-sm text-muted" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <div class="text-muted mt-1">Checking for active refund links ..</div>
            </div>
        </ng-container>
        <ng-container *ngIf="hasActiveLinks && !checkingCgActiveLinks">
            <p class="mt-1">The appointment has active cashgram refund links. Cannot refund to wallet directly</p>
        </ng-container>
        <ng-container *ngIf="!hasActiveLinks && !checkingCgActiveLinks">
            <h6>Available Balance: <span class="text-primary">{{lead.netIncomingBalancePaidByClient()?displayAmount:'-'}}</span></h6>
            <h6 class="mt-1">Issue Amount:</h6>
            <div class="d-flex">
                <input type="number" class="form-control me-1" style="width: 180px;" placeholder="Amount" [(ngModel)]="amount">
                <currency-dropdown [defaultValue]="currency" (selectedCurrency)="setCurrency($event)"></currency-dropdown>
            </div>
            <p *ngIf="isValidAmountForTransfer&&!hasPartnerId&&!checkIfPlanBooking" class="mt-1">Would you like to tranfer credits to <b>client's</b> wallet?</p>
            <p *ngIf="isValidAmountForTransfer&&hasPartnerId&&!checkIfPlanBooking" class="mt-1">Would you like to tranfer credits to <b>partner's</b> wallet?</p>
            <p *ngIf="isValidAmountForTransfer&&checkIfPlanBooking" class="mt-1">Would you like to tranfer credits to <b>membership plan's</b> wallet?</p>
            <p *ngIf="!isValidAmountForTransfer" class="mt-1">Insufficient credits for transfer..</p>
        </ng-container>
    </div>
    <div class="modal-footer">
        <button class="btn btn-outline-secondary" (click)="closeModal()">
            Close
        </button>
        <ng-container *ngIf="!hasActiveLinks && !checkingCgActiveLinks">
            <button class="btn btn-outline-primary ms-1" *ngIf="!hasPartnerId&&!checkIfPlanBooking" [disabled]="!isValidAmountForTransfer||loader" (click)="initiateTransfer()">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loader"></span>
                Transfer
            </button>
            <button class="btn btn-outline-primary ms-1" *ngIf="hasPartnerId&&!checkIfPlanBooking" [disabled]="!isValidAmountForTransfer||loader" (click)="initiateTransferToPartnerWallet()">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loader"></span>
                Transfer To Partner Wallet
            </button>
            <button class="btn btn-outline-primary ms-1" *ngIf="checkIfPlanBooking" [disabled]="!isValidAmountForTransfer||loader" (click)="initiateTransferToPlanWallet()">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loader"></span>
                Transfer To Plan Wallet
            </button>
        </ng-container>
    </div>
</ng-template>