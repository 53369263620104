import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-common-phrases',
  templateUrl: './common-phrases.component.html',
  styleUrls: ['./common-phrases.component.css'],
})
export class CommonPhrasesComponent implements OnInit {
  @ViewChild('commonPhrasesModal') commonPhrasesModal;

  constructor(
    private _modalService: BsModalService,
    private _toastrService: ToastrService
  ) {}

  ngOnInit(): void {}

  open() {
    this._modalService.show(this.commonPhrasesModal, {
      class: 'modal-lg',
    });
  }

  closeModal() {}
}
