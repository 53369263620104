import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Lead } from 'src/app/shared/models-ts/Entities/Lead';
import { RefundSource } from 'src/app/shared/models-ts/Enums/pay';
import { Currency } from 'src/app/shared/models-ts/Helpers/countrywiseFeeCalc';
import { environment } from 'src/environments/environment';

@Injectable()
export class RefundService {

  constructor(private _http: HttpClient) { }

  refreshOrAssignCFRefundIdInLead(lead:Lead, refundId: string) {
    return new Promise<void>((resolve, reject) => {
      let url = environment['refresh-cf-refund-info'];
      let body:any = {
        params: {
          leadId: lead.id,
          refundId: refundId,
        }
      }
      this._http.get(url,body).subscribe((resp)=>{
        if(resp && resp["statusCode"] == 200 && resp["body"] != null &&
          resp["body"]["success"]) {
            resolve();
        }else{
          reject();
        }
      });
    })  
  }
  saveManualRefundIdInLead(lead:Lead, refundId: string, 
    txAmount: number, 
    refundAmount: number, 
    refundSource: string,
    processed: string,
    processedOn: number,
    currency: Currency
    ) {
    return new Promise<void>((resolve, reject) => {
      let url = environment['save-manual-refund-info'];
      let body:any = {
        leadId: lead.id,
        refundId: refundId,
        processedOn: processedOn,
        processed: processed,
        refundSource:refundSource,
        refundAmount: refundAmount,
        txAmount: txAmount,
        currency: currency
      }
      this._http.post(url,body).subscribe((resp)=>{
        if(resp && resp["statusCode"] == 200 && resp["body"] != null &&
          resp["body"]["success"]) {
            resolve();
        }else{
          reject();
        }
      });
    })  
  }

}
