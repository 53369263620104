import { Expose } from "class-transformer";
import "reflect-metadata";
export class Email {
  @Expose() email: string;
  @Expose() isPrimary: boolean;
  @Expose() isVerified: boolean;
  @Expose() isSlackConnected: boolean;
  @Expose() isGAuthConnected: boolean;
  @Expose() emailCommunicationOn: boolean;
}
