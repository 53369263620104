import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

//components
import { DocProfileListingComponent } from './components/doc-profile-listing/doc-profile-listing.component';
import { DocProfilePracticesComponent } from './components/doc-profile-practices/doc-profile-practices.component';
import { DocProfileBasicInfoComponent } from './components/doc-profile-basic-info/doc-profile-basic-info.component';
import { DocProfileEducationComponent } from './components/doc-profile-education/doc-profile-education.component';
import { DocProfileClaimComponent } from './components/doc-profile-claim/doc-profile-claim.component';
import { DocProfilePaymentsComponent } from './components/doc-profile-payments/doc-profile-payments.component';
import { HomeComponent } from './components/home/home.component';
import { LinkBankComponent } from './shared/reusable-components/link-bank/link-bank.component';
import { CrmHeroListComponent } from './crm-hero-list/crm-hero-list.component';
import { DocSlotsComponent } from './components/doc-slots/doc-slots.component';
import { BookingStartedComponent } from './patient-leads/components/booking-started/booking-started.component';
import { AuthGuardService as AuthGuard } from 'src/app/shared/services/auth-guard/auth-guard.service';
import { DocProfileNewSlotsComponent } from './components/doc-profile-new-slots/doc-profile-new-slots.component';
import { DocMembershipPlansComponent } from './components/doc-membership-plans/doc-membership-plans.component';
import { DocProfileModuleConfigsComponent } from './components/doc-profile-module-configs/doc-profile-module-configs.component';
import { DocProfileFeeConfigComponent } from './components/doc-profile-fee-config/doc-profile-fee-config.component';
import { ProviderAvailabilityComponent } from './components/provider-availability/provider-availability.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'client-attribution',
    loadChildren: () =>
      import('./client-attribution/client-attribution.module').then(
        (m) => m.ClientAttributionModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'provider-data',
    loadChildren: () =>
      import('./provider-data/provider-data.module').then(
        (m) => m.ProviderDataModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'help-center',
    loadChildren: () =>
      import(
        './help-center/help-center.module'
      ).then((m) => m.HelpCenterModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'payouts',
    loadChildren: () =>
      import('./payouts/payouts.module').then((m) => m.PayoutsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'chats',
    loadChildren: () =>
      import('./chat-support/chat-support.module').then(
        (m) => m.ChatSupportModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'ledger',
    loadChildren: () =>
      import('./ledger/ledger.module').then((m) => m.LedgerModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'patient-bookings',
    loadChildren: () =>
      import('./patient-leads/patient-leads.module').then(
        (m) => m.PatientLeadsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'patient-followups',
    loadChildren: () =>
      import('./patient-follow-up/patient-follow-up.module').then(
        (m) => m.PatientFollowUpModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'profile-listing',
    canActivate: [AuthGuard],
    component: DocProfileListingComponent,
    children: [
      {
        path: ':id',
        redirectTo: ':id/basicInfo',
        pathMatch: 'full',
      },
      {
        path: ':id/basicInfo',
        component: DocProfileBasicInfoComponent,
      },
      {
        path: ':id/education',
        component: DocProfileEducationComponent,
      },
      {
        path: ':id/membership-plans',
        component: DocMembershipPlansComponent,
      },
      {
        path: ':id/practices',
        component: DocProfilePracticesComponent,
      },
      {
        path: ':id/claim',
        component: DocProfileClaimComponent,
      },
      {
        path: ':id/payments',
        component: DocProfilePaymentsComponent,
      },
      {
        path: ':id/link-bank',
        component: LinkBankComponent,
      },
      {
        path: ':id/slots',
        component: DocSlotsComponent,
      },
      {
        path: ':id/schedule-v2',
        component: DocProfileNewSlotsComponent,
      },
      {
        path: ':id/schedule-v3',
        component: ProviderAvailabilityComponent,
      },
      {
        path: ':id/module-config',
        component: DocProfileModuleConfigsComponent,
      },
      {
        path: ':id/fee-config',
        component: DocProfileFeeConfigComponent,
      },
    ],
  },
  {
    path: 'crm-user-list',
    component: CrmHeroListComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'get-started-sessions',
    loadChildren: () => import('./get-started-sessions/get-started-sessions.module').then((m) => m.GetStartedSessionsModule),
  },
  {
    path: 'booking-started',
    component: BookingStartedComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "booking-started/:id",
    component: BookingStartedComponent,
  },
  {
    path: 'stats',
    loadChildren: () =>
      import('./stats/stats.module').then((m) => m.StatsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'clients',
    loadChildren: () =>
      import('./clients/clients.module').then((m) => m.ClientsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'retention-dashboard',
    loadChildren: () =>
      import('./retention-dashboard/retention-dashboard.module').then((m) => m.RetentionDashboardModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'payment-links',
    loadChildren: () =>
      import('./appt-payment-links/appt-payment-links.module').then(
        (m) => m.ApptPaymentLinksModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'patient-invites',
    loadChildren: () =>
      import('./patient-invites/patient-invites.module').then(
        (m) => m.PatientInvitesModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'psi',
    loadChildren: () =>
      import('./pagespeed-insights/pagespeed-insights.module').then(
        (m) => m.PagespeedInsightsModule
      ),
    canActivate: [AuthGuard],
  },
  /**
   * Testing module has whatsapp template testing feature
   */
  {
    path: 'testing',
    loadChildren: () =>
      import('./testing/testing.module').then(
        (m) => m.TestingModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'provider-client-relationship',
    loadChildren: () =>
      import('./provider-client-relationship/provider-client-relationship.module').then(
        (m) => m.ProviderClientRelationshipModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'image-to-webp',
    loadChildren: () =>
      import('./image-formatter-tool/image-formatter-tool.module').then(
        (m) => m.ImageFormatterToolModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'outgoing-calls',
    loadChildren: () =>
      import('./p2p-calls/p2p-calls.module').then(
        (m) => m.P2pCallsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: 'home',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
