<div class="offcanvas offcanvas-end" style="width: 700px!important;" tabindex="-1"
    id="offcanvas-for-membership-plan-generator" aria-labelledby="offcanvasRightLabel">
    <div class="offcanvas-header">
        <div class="d-flex flex-column">
            <h6>
                {{title}}
            </h6>
            <button (click)="save()" class="btn btn-sm btn-primary">Save</button>
        </div>
        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body" style="overflow-y: auto;">
        <div class="d-flex mb-2">
            <span class="field-label">
                Session type:
            </span>
            <ng-select id="planSessionType" 
                [multiple]="true"
                [closeOnSelect]="false"
                [(ngModel)]="sessionTypeArray" class="w-100" name="sessiontype">
                <ng-option *ngFor="let c of sessionTypes" [value]="c">{{ c | titlecase }}</ng-option>
            </ng-select>
        </div>

        <div class="d-flex mb-2">
            <span class="field-label">
                Multiplier:
            </span>
            <input type="number" class="ms-2 form-control" [(ngModel)]="multiplier" placeholder="fee multiplier">
        </div>
        <!-- <ng-select [(ngModel)]="selectionListedInArray" [multiple]="true" bindValue="urlPath" bindLabel="title"
        [closeOnSelect]="false" (add)="addListedInItem($event)" (remove)="removeListedInItem($event)"
        [items]="listedInSuggestionArray" placeholder="Choose Listed In Groups">
      </ng-select> -->
    </div>
</div>