import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'practiceFilter',
})
export class PracticeFilterPipe implements PipeTransform {
  transform(items: any[], type: string): any[] {
    if (!items) return [];
    if (!type) return items;
    type = type.toLowerCase();
    return items.filter((it) => {
      if (it.type.toLowerCase().includes(type)) {
        return true;
      } else {
        return false;
      }
    });
  }
}
