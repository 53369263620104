import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { classToPlain, plainToClass } from 'class-transformer';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { DoctorListingProfile } from 'src/app/shared/models-ts/Actors/Doctor/DoctorListingProfile';
import { ContactNumber } from 'src/app/shared/models-ts/Actors/Person/ContactNumber';
import { PracticeListingProfile } from 'src/app/shared/models-ts/Entities/PracticeListingProfile';
import { ProfileLinks } from 'src/app/shared/models-ts/Entities/ProfileLinks';
import { DoctorPracticeRelationship } from 'src/app/shared/models-ts/Relationships/DoctorPracticeRelationship';
import { DoctorDataService } from 'src/app/shared/services/doctor-data/doctor-data.service';
import { DoctorsService } from 'src/app/shared/services/doctors/doctors.service';
import { ToastrService } from 'ngx-toastr';
import { city, state } from 'src/app/shared/constants/constants';
import { AuthUserInfoService } from 'src/app/shared/services/auth-user-info/auth-user-info.service';

declare var $: any;
@Component({
  selector: 'app-doc-profile-practices',
  templateUrl: './doc-profile-practices.component.html',
  styleUrls: ['./doc-profile-practices.component.css'],
})
export class DocProfilePracticesComponent implements OnInit {
  doctorProfile: DoctorListingProfile;
  dataLayerSubscription: Subscription;
  editMode: boolean = false;
  city: any[] = city;
  state: any[] = state;
  associatedPractices: any[] = [];
  defaultLogoUrl: string =
    'https://default-docvita-images.s3.ap-south-1.amazonaws.com/default-doctor-image.png';
  photoUrl: any;
  currentSelectedPractice: PracticeListingProfile = new PracticeListingProfile();
  publicContactNumber: ContactNumber = new ContactNumber();
  profileLinks: ProfileLinks = new ProfileLinks();
  clinicConnection = new DoctorPracticeRelationship();
  extension: any;
  currentDoctorId: string;
  logoUploaded: boolean = false;
  allPractices: any[] = [];
  currentSelectedType: string;
  connectCurrenteSelectPractice: string;
  connectExistingPractice = new DoctorPracticeRelationship();
  currentHeroInfo: any;
  connectedDoctors: any[] = [];
  constructor(
    private _doctorDataService: DoctorDataService,
    private _doctorService: DoctorsService,
    private _spinner: NgxSpinnerService,
    private _activatedRoute: ActivatedRoute,
    private _toastr: ToastrService,
    private _authUserInfoService: AuthUserInfoService
  ) {}

  ngOnInit(): void {
    this.currentHeroInfo = this._authUserInfoService.getUserInfo();
    this._activatedRoute.parent.params.subscribe((params) => {
      this.currentDoctorId = params['id'];
    });
    this.dataLayerSubscription = this._doctorDataService.currentSelection.subscribe(
      (data) => {
        if (data != null && data['memberSelected'] != null) {
          this.restFomData();
          this.doctorProfile = data['memberSelected'];
          this.getAssociatedPractices();
          this.getAllPractices();
        }
      }
    );
  }

  restFomData() {
    this.currentSelectedPractice = new PracticeListingProfile();
    this.publicContactNumber = new ContactNumber();
    this.profileLinks = new ProfileLinks();
    this.clinicConnection = new DoctorPracticeRelationship();
    this.connectExistingPractice = new DoctorPracticeRelationship();
    this.connectedDoctors = [];
    this.associatedPractices = [];
    this.allPractices = [];
    this.logoUploaded = false;
  }

  getDoctorProfile() {
    this._doctorService.getProfileById(this.doctorProfile.id).then((data) => {
      data.forEach((elemenet) => {
        this.doctorProfile = plainToClass(
          DoctorListingProfile,
          elemenet.data(),
          {
            excludeExtraneousValues: true,
          }
        );
      });
      this._doctorDataService.updateCurrentDoctor(this.doctorProfile);
      this.getAssociatedPractices();
    });
  }

  getAssociatedPractices() {
    this.associatedPractices.length = 0;
    this.associatedPractices = [];
    if (this.doctorProfile.associatedPractices) {
      this.doctorProfile.associatedPractices.forEach((practice) => {
        this.associatedPractices.push(practice);
      });
    }
  }

  // fileProgress(fileInput: any) {
  //   let imageData;
  //   imageData = <File>fileInput.target.files[0];
  //   fileInput.target.value = null;
  //   this.extension = imageData['name'].match(/\.[0-9a-z]+$/i)[0];
  //   var mimeType = imageData.type;
  //   if (mimeType.match(/image\/*/) == null) {
  //     return;
  //   }

  //   var reader = new FileReader();
  //   reader.readAsDataURL(imageData);
  //   reader.onload = (_event) => {
  //     this.photoUrl = reader.result;
  //     this.logoUploaded = true;
  //   };
  // }

  fileProgress(fileInput: any) {
    let self = this;
    let imageData;
    imageData = <File>fileInput.target.files[0];
    // this.extension = self.photoUrl.match(/\.[0-9a-z]+$/i);
    var mimeType = imageData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var myCanvas = document.createElement('canvas');
    var ctx = myCanvas.getContext('2d');
    var img = new Image();
    img.onload = function () {
      myCanvas.width = img.width;
      myCanvas.height = img.height;

      ctx.drawImage(img, 0, 0);

      self.photoUrl = myCanvas.toDataURL('image/jpeg');
      self.extension =
        '.' +
        self.photoUrl.substring(
          self.photoUrl.indexOf('/') + 1,
          self.photoUrl.indexOf(';base64')
        );
      self.logoUploaded = true;
    };

    img.src = URL.createObjectURL(fileInput.target.files[0]);
    fileInput.target.value = null;
  }

  updatePractice(i) {
    let practice = this.associatedPractices[i];
    // let obj = {
    //   acceptingAppointmentsOnDocVita: practice.acceptingAppointmentsOnDocVita,
    //   fee: practice.fee,
    //   relationship: practice.relationship,
    // };
    this._doctorService
      .updateClinicMinorDetails(practice.id, this.doctorProfile.id, practice)
      .then(() => {
        this._toastr.success('Updated Successfully');
        this.updateUserStats(
          this.currentHeroInfo.docvitaId,
          this.doctorProfile.id,
          'update'
        );
      });
  }

  editPractice() {
    this.currentSelectedPractice.profileLinks = this.profileLinks;

    if (this.publicContactNumber && this.publicContactNumber.number) {
      this.currentSelectedPractice.publicContactNumber = this.publicContactNumber;
      this.currentSelectedPractice.publicContactNumber.countryCode = '91';
      this.currentSelectedPractice.publicContactNumber.number =
        this.publicContactNumber.number + '';
    }
    if (
      this.currentSelectedPractice.state &&
      this.currentSelectedPractice.state['label']
    ) {
      this.currentSelectedPractice.state = this.currentSelectedPractice.state[
        'label'
      ];
    }
    if (
      this.currentSelectedPractice.city &&
      this.currentSelectedPractice.city['label']
    ) {
      this.currentSelectedPractice.city = this.currentSelectedPractice.city[
        'label'
      ];
    }
    //update
    if (this.validateRegistrationForm()) {
      let plainObject = JSON.parse(
        JSON.stringify(classToPlain(this.currentSelectedPractice))
      );
      console.log("saved stuuff",plainObject)
      if (this.currentSelectedPractice.id) {
        let connectionObj = {
          ...this.clinicConnection,
          name: this.currentSelectedPractice.name,
          locality: this.currentSelectedPractice.locality,
          type: this.currentSelectedPractice.type,
        };
        this._doctorService
          .editPractices(
            this.currentSelectedPractice.id,
            plainObject,
            connectionObj,
            this.connectedDoctors
          )
          .then(() => {
            if (this.logoUploaded) {
              this._doctorService
                .uploadClinicLogo(
                  this.photoUrl,
                  this.currentSelectedPractice.id,
                  this.extension
                )
                .subscribe((data) => {
                  this.updateUserStats(
                    this.currentHeroInfo.docvitaId,
                    this.doctorProfile.id,
                    'photo'
                  );
                  this.getDoctorProfile();
                  this._toastr.success('Photo uploaded!');
                  this._toastr.success('Updated Successfully');
                  $('#registerPracticesModal').modal('hide');
                });
            } else {
              this.getDoctorProfile();
              this._toastr.success('Updated Successfully');
              $('#registerPracticesModal').modal('hide');
            }
            this.updateUserStats(
              this.currentHeroInfo.docvitaId,
              this.doctorProfile.id,
              'update'
            );
          });
      } else {
        //register
        let connectionObj = {
          ...this.clinicConnection,
          name: this.currentSelectedPractice.name,
          locality: this.currentSelectedPractice.locality,
          type: this.currentSelectedPractice.type,
        };
        this._doctorService
          .newPractices(plainObject, connectionObj, this.doctorProfile)
          .then((clinicId) => {
            if (this.logoUploaded) {
              this._doctorService
                .uploadClinicLogo(this.photoUrl, clinicId, this.extension)
                .subscribe((data) => {
                  this.getDoctorProfile();
                  $('#registerPracticesModal').modal('hide');
                  this._toastr.success('Registered Successfully');
                });
            } else {
              this.getDoctorProfile();
              $('#registerPracticesModal').modal('hide');
              this._toastr.success('Registered Successfully');
            }
          });
      }
    } else {
      this._toastr.error('Please fill the required fields');
    }
  }

  validateRegistrationForm() {
    if (
      this.currentSelectedPractice.name &&
      this.currentSelectedPractice.name.length > 0 &&
      this.currentSelectedPractice.type &&
      this.currentSelectedPractice.type.length > 0 &&
      this.currentSelectedPractice.addressLine1 &&
      this.currentSelectedPractice.addressLine1.length > 0 &&
      this.currentSelectedPractice.city &&
      this.currentSelectedPractice.city.length > 0 &&
      this.currentSelectedPractice.state &&
      this.currentSelectedPractice.state.length > 0 &&
      this.currentSelectedPractice.locality &&
      this.currentSelectedPractice.locality.length > 0 &&
      this.currentSelectedPractice.pincode
    ) {
      return true;
    }
    return false;
  }

  deletePractice(practiceId, i) {
    this._doctorService
      .deletePractice(practiceId, this.doctorProfile.id)
      .subscribe((data) => {
        if (data['statusCode'] == 200) {
          this.getDoctorProfile();
          this._toastr.success('Deleted Successfully');
        }
      });
  }

  editClicked(practiceId) {
    this.editMode = true;
    this._doctorService.getPractice(practiceId).then((practice) => {
      this.currentSelectedPractice = plainToClass(
        PracticeListingProfile,
        practice.data(),
        {
          excludeExtraneousValues: false,
        }
      );
      this.photoUrl = this.currentSelectedPractice.logoUrl
        ? this.currentSelectedPractice.logoUrl
        : null;

      this.publicContactNumber = this.currentSelectedPractice
        .publicContactNumber
        ? plainToClass(
            ContactNumber,
            this.currentSelectedPractice.publicContactNumber
          )
        : new ContactNumber();
      this.profileLinks = this.currentSelectedPractice.profileLinks
        ? plainToClass(ProfileLinks, this.currentSelectedPractice.profileLinks)
        : new ProfileLinks();
      if (
        this.currentSelectedPractice.associatedDoctors &&
        this.currentSelectedPractice.associatedDoctors.size > 0
      ) {
        this.connectedDoctors = Array.from(
          this.currentSelectedPractice.associatedDoctors.keys()
        );
      }
      $('#registerPracticesModal').modal('show');
    });
  }

  registerNewPractice() {
    this.editMode = false;
    this.currentSelectedPractice = new PracticeListingProfile();
    this.publicContactNumber = new ContactNumber();
    this.profileLinks = new ProfileLinks();
    this.clinicConnection = new DoctorPracticeRelationship();
    this.clinicConnection.relationship = 'owner';
    this.clinicConnection.acceptingAppointmentsOnDocVita = true;
    this.photoUrl = null;
    $('#registerPracticesModal').modal('show');
  }

  getAllPractices() {
    this.allPractices = [];
    this._doctorService.getAllClinicAndHospital().then((result) => {
      result.forEach((docSnapshot) => {
        this.allPractices.push(docSnapshot.data());
      });
      this.currentSelectedType = 'clinic';
    });
  }

  connectPracticeModalOpen() {
    this.connectExistingPractice = new DoctorPracticeRelationship();
    this.connectCurrenteSelectPractice = this.allPractices[0].id;
    this.connectExistingPractice.relationship = 'owner';
    this.connectExistingPractice.acceptingAppointmentsOnDocVita = true;
    $('#connectPracticesModal').modal('show');
  }

  connectPractice() {
    if (!this.connectExistingPractice.fee) {
      this._toastr.error('Please enter the fee');
      return;
    }
    let selectedPractice;
    for (let practice of this.allPractices) {
      if (this.connectCurrenteSelectPractice == practice.id) {
        selectedPractice = practice;
      }
    }
    if (selectedPractice['locality']) {
      this.connectExistingPractice.locality = selectedPractice.locality;
    }
    if (selectedPractice['googleMapLink']) {
      this.connectExistingPractice.googleMapLink = selectedPractice.googleMapLink;
    }
    if (selectedPractice['name']) {
      this.connectExistingPractice.name = selectedPractice.name;
    }
    this.connectExistingPractice.type = this.currentSelectedType;

    this._doctorService
      .updateClinicMinorDetails(
        selectedPractice['id'],
        this.doctorProfile.id,
        this.connectExistingPractice
      )
      .then(() => {
        this.getDoctorProfile();
        $('#connectPracticesModal').modal('hide');
        this._toastr.success('Connected Successully');
        this.updateUserStats(
          this.currentHeroInfo.docvitaId,
          this.doctorProfile.id,
          'update'
        );
      });
  }

  updateUserStats(heroId, docId, reqType) {
    if (heroId && docId && reqType) {
      this._doctorService
        .updateDocvitaUserStats(heroId, docId, reqType)
        .subscribe((data) => {
          if (data['statusCode'] == 200) {
            console.log('User stats updated!');
          } else {
            console.log('User stats did not update!');
          }
        });
    }
  }
}
