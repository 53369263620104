import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CashgramRefundsService } from '../../services/cashgram-refunds/cashgram-refunds.service';

@Component({
  selector: 'cashgram-status-check',
  templateUrl: './cashgram-status-check.component.html',
  styleUrls: ['./cashgram-status-check.component.scss'],
})
export class CashgramStatusCheckComponent implements OnInit {
  @Input() cashgramId: string;
  @Input() showButton: boolean = false;
  @ViewChild('cgrmStatus') cgrmStatusModal;

  modalRef: BsModalRef;
  statusData: any = {};
  loader: boolean = false;
  constructor(
    private modalService: BsModalService,
    private _crgmService: CashgramRefundsService
  ) {}

  ngOnInit(): void {}
  openModal() {
    this.statusData = {};
    this.getStatus();
    this.modalRef = this.modalService.show(this.cgrmStatusModal);
  }
  closeModal() {
    this.modalRef.hide();
  }
  getStatus() {
    this.loader = true;
    this._crgmService
      .getRefundStatus(this.cashgramId)
      .then((data) => {
        if (data) {
          this.statusData = data;
          this.loader = false;
        } else {
          this.statusData = {};
          this.loader = false;
        }
      })
      .catch((err) => {
        this.statusData = {};
        this.loader = false;
      });
  }
}
