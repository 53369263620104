import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThreeDayScheduleComponent } from './components/three-day-schedule/three-day-schedule.component';
import { WorkingHoursComponent } from './components/working-hours/working-hours.component';
import { BlockAllSlotsPopupComponent } from './components/three-day-schedule/block-all-slots-popup/block-all-slots-popup.component';
import { ScheduleTimeslotCellComponent } from './components/three-day-schedule/schedule-timeslot-cell/schedule-timeslot-cell.component';
import { FormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

@NgModule({
  declarations: [
    ThreeDayScheduleComponent,
    WorkingHoursComponent,
    BlockAllSlotsPopupComponent,
    ScheduleTimeslotCellComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    BsDropdownModule,
  ],
  exports: [
    ThreeDayScheduleComponent,
    WorkingHoursComponent,
    BlockAllSlotsPopupComponent,
    ScheduleTimeslotCellComponent
  ]
})
export class ManageScheduleInterfaceModule { }
