<button class="btn btn-sm btn-outline-primary border-0 d-flex justify-content-center align-items-center"
    *ngIf="showButton" (click)="openModal();">
    <i style="font-size: 15px" class="material-icons me-1">
        event_available
    </i>
    View All Invites
</button>

<ng-template #allInvitesModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title">
            🙏 Patient Invites
        </h4>
        <div class="ms-auto d-flex align-items-center">
            <button type="button" class="close" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <div class="modal-body d-flex flex-column" style="position: relative;">
        <div class="list-container" *ngIf="inviteList&&inviteList.length>0&&!isLoadingList">
            <div *ngFor="let item of inviteList" class="border rounded p-2 mt-1 mb-1 d-flex flex-column w-100"
                style="font-size: 14px;">
                <div class="w-100 d-flex align-items-center" style="font-size: 0.9em;">
                    <span class="text-muted">{{item.createdOn?'Created On '+(item.createdOn|date:'dd/MM/yy, hh:mm
                        a'):"-"}}</span>
                    <span class="ms-2">{{item.type?("("+item.type+")"):""}}</span>
                    <span *ngIf="item.status" class="ms-auto badge"
                        [ngClass]="{'bg-primary': item['status']&&item['status']=='created','bg-success': item['status']&&(item['status']=='booked'||item['status']=='accepted'),'bg-danger': item['status']&&item['status']=='cancelled'}">
                        {{item.status}}
                    </span>
                </div>
                <div style="font-size: 0.9em;">
                    <span class="text-muted">Created By: {{item.createdByName}}</span>
                </div>
                <div class="d-flex justify-content-between align-items-center w-100 mt-2">
                    <div class="d-flex flex-column">
                        <div style="font-size: 0.8em;font-weight: 600;">
                            Doctor
                        </div>
                        <div style="font-size: 0.9em;">
                            {{item['doctorName']?item['doctorName']:'-'}}
                        </div>
                    </div>
                    <div>
                        <i style="font-size: 15px" class="material-icons">
                            send
                        </i>
                    </div>
                    <div class="d-flex flex-column">
                        <div style="font-size: 0.8em;font-weight: 600;">
                            Patient
                        </div>
                        <div style="font-size: 0.9em;">
                            {{item['patientName']?item['patientName']:'-'}}
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-between w-100 mt-2">
                    <div class="d-flex flex-column">
                        <div style="font-size: 0.8em;font-weight: 600;">
                            Appointment date & time:
                        </div>
                        <div style="font-size: 0.9em;">
                            {{consultationDateTime(item)}}
                        </div>
                    </div>
                    <div class="d-flex flex-column">
                        <div style="font-size: 0.8em;font-weight: 600;">
                            Amount (₹):
                        </div>
                        <div style="font-size: 0.9em;">
                            {{item['amount']?(item['amount']):'-'}}
                        </div>
                    </div>
                    <div class="d-flex flex-column">
                        <div style="font-size: 0.8em;font-weight: 600;">
                            Duration (mins):
                        </div>
                        <div style="font-size: 0.9em;">
                            {{item['duration']?(item['duration']):'-'}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center w-100 mt-3">
            <div class="spinner-border" role="status" *ngIf="isLoadingList">
                <span class="sr-only">Loading...</span>
            </div>
            <h5 class="text-muted" *ngIf="!isLoadingList&&inviteList.length==0">No Invites 💤</h5>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-outline-dark me-2" (click)="closeModal()">
            Close
        </button>
    </div>
</ng-template>