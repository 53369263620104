import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ScheduleService } from 'src/app/manage-schedule-interface/services/schedule/schedule.service';
import { DayjsHelper } from 'src/app/shared/models-ts/Helpers/dayjsHelper';

@Component({
  selector: 'block-all-slots-popup',
  templateUrl: './block-all-slots-popup.component.html',
  styleUrls: ['./block-all-slots-popup.component.scss']
})
export class BlockAllSlotsPopupComponent implements OnInit {

  private modalTitle:string = 'Block All slots'
  private modalbody:string

  private modalRef: BsModalRef;
  private scheduleSlots:any[]
  private date:string;
  private providerId:string;

  private btnLoader:boolean = false;
  private canBlock:boolean = false;

  constructor(
    private modalService:BsModalService,
    private _scheduleService: ScheduleService
  ) { }

  ngOnInit(): void {
  }

  @ViewChild('blockAllSlots') private blockAllSlots;

  openModal(date:string, providerId:string,scheduleSlots:any[]){
    if(!date || !providerId){
      return;
    }
    this.date = date;
    this.providerId = providerId;
    this.scheduleSlots = scheduleSlots;
    this.setModalLanguage()
    
    this.modalRef = this.modalService.show(this.blockAllSlots,{backdrop:'static'})
  }

  closeModal(){
    this.modalRef.hide();
  }

  private setModalLanguage(){
    const checkIfSlotsAvailable = this.scheduleSlots?.filter(v=>v&&v['isAvailable']) 
    if(!checkIfSlotsAvailable || checkIfSlotsAvailable.length==0){
      this.modalbody = "There are no open time slots for "+ new DayjsHelper().YYYYMMDDToEEEMMMd(this.date);
      this.canBlock = false;
    }else{
      this.modalbody = "Are you sure you want to close all open slots for "+new DayjsHelper().YYYYMMDDToEEEMMMd(this.date)+"?"
      this.canBlock = true;
    }
  }

  private async closeAllOpenSlots(){
    const checkIfSlotsAvailable = this.scheduleSlots?.filter(v=>v&&v['isAvailable']) 
    if(!checkIfSlotsAvailable || checkIfSlotsAvailable.length==0){
      return;
    }
    try {
      this.btnLoader = true;
      const blocked = await this._scheduleService.blockAllSlots(this.date,this.providerId)
      if(blocked){
        this.btnLoader = false;
        this.closeModal()
      }else{
        this.btnLoader = false;
      }
    } catch (error) {
      this.btnLoader = false;
      // handle err
    }
  }

}
