import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { classToPlain, plainToClass } from 'class-transformer';
import { ToastrService } from 'ngx-toastr';
import { ContactNumber } from '../../models-ts/Actors/Person/ContactNumber';
import { Email } from '../../models-ts/Actors/Person/Email';
import { User } from '../../models-ts/Actors/User/User';
import { Lead } from '../../models-ts/Entities/Lead';
import { DoctorsService } from '../../services/doctors/doctors.service';
import { PatientService } from '../../services/patient/patient.service';
import parsePhoneNumber from 'libphonenumber-js';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-patient-registration',
  templateUrl: './patient-registration.component.html',
  styleUrls: ['./patient-registration.component.css'],
})
export class PatientRegistrationComponent implements OnInit {
  @Input() leadDetails: Lead = new Lead();
  @Input() searchText: string;
  @Input() patient: User = new User();
  @Input() showNewPatientBackButton: boolean = true;
  @Input() isNewPatient: boolean = false;
  @Input() showButton: boolean = true;
  @Output() updatedPatient: EventEmitter<any> = new EventEmitter<any>();
  patientProfile: User = new User();
  bookingSteps: number = 1;
  newPatientForm;
  textForPatientSearch;
  bookingLoader: boolean = false;
  notifyContactNotSaved: boolean = false;
  notifyEmailNotSaved: boolean = false;
  modalRef: BsModalRef;
  numberEntrySub = new Subject<any>();
  constructor(
    private modalService: BsModalService,
    private _toastrService: ToastrService,
    private _doctorService: DoctorsService,
    private _patientService: PatientService
  ) {}

  ngOnInit(): void {
    this.numberEntrySub.pipe(debounceTime(800)).subscribe(
      (val) => {
        if (val && val['number'] != null && val['index'] != null) {
          this.numberParser(val['number'], val['index']);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  @ViewChild('patientReg') patientReg;
  openModal(content) {
    this.bookingLoader = false;
    this.notifyContactNotSaved = false;
    this.notifyEmailNotSaved = false;
    this.bookingSteps = 1;
    this.patientProfile = new User();
    this.newPatientForm = {
      name: '',
      gender: '',
      contacts: [
        {
          countryCode: '',
          number: '',
          isPrimaryContactNumber: true,
          isWhatsappContactNumber: true,
          smsCommunicationOn: true,
          whatsappCommunicationOn: true,
        },
      ],
      emails: [{ email: '', isPrimary: true, emailCommunicationOn: true }],
    };
    if (this.leadDetails && this.leadDetails.docvitaId) {
      this.setPatientForm();
    }
    if (
      this.patient &&
      this.patient.docvitaId &&
      this.patient.docvitaId.length > 0
    ) {
      this.patientProfile = this.patient;
      this.setStep(3);
    }
    if (this.isNewPatient) {
      this.showNewPatientBackButton = false;
      this.setStep(2);
    }
    if (content) {
      this.modalRef = this.modalService.show(content, {
        class: 'modal-lg',
        backdrop: 'static',
      });
    } else {
      this.modalRef = this.modalService.show(this.patientReg, {
        class: 'modal-lg',
        backdrop: 'static',
      });
    }
  }

  addNewEmail(): void {
    this.newPatientForm['emails'].push({
      email: '',
      isPrimary: false,
      emailCommunicationOn: true,
    });
  }

  addNewContact(): void {
    this.newPatientForm['contacts'].push({
      countryCode: '',
      number: '',
      isPrimaryContactNumber: false,
      isWhatsappContactNumber: false,
      smsCommunicationOn: true,
      whatsappCommunicationOn: true,
    });
  }
  removeEmail(index) {
    if (
      this.newPatientForm['emails'] &&
      this.newPatientForm['emails'].length > 1
    ) {
      this.newPatientForm['emails'].splice(index, 1);
    } else {
      this.newPatientForm['emails'][index] = { email: '', isPrimary: false };
    }
  }
  removeContact(index) {
    if (
      this.newPatientForm['contacts'] &&
      this.newPatientForm['contacts'].length > 1
    ) {
      this.newPatientForm['contacts'].splice(index, 1);
    } else {
      this.newPatientForm['contacts'][index] = {
        countryCode: '',
        number: '',
        isPrimaryContactNumber: false,
        isWhatsappContactNumber: false,
        smsCommunicationOn: true,
        whatsappCommunicationOn: true,
      };
    }
  }
  setPatient(event) {
    if (event) {
      this.patientProfile = event;
    }
  }

  setStep(stepNumber: number) {
    this.bookingSteps = stepNumber;
    if (stepNumber == 2) {
      this.patientProfile = new User();
      this.setPatientForm();
    }
    if (stepNumber == 3) {
      this.transformPatientProfile();
    }
  }

  setPatientForm() {
    let _patientName =
      this.leadDetails && this.leadDetails.patientName
        ? this.leadDetails.patientName
        : '';
    let _countryCode =
      this.leadDetails &&
      this.leadDetails.patientContactInfo &&
      this.leadDetails.patientContactInfo.countryCode
        ? this.leadDetails.patientContactInfo.countryCode
        : '';
    let _number =
      this.leadDetails &&
      this.leadDetails.patientContactInfo &&
      this.leadDetails.patientContactInfo.number
        ? this.leadDetails.patientContactInfo.number
        : '';
    if (this.leadDetails) {
      this.textForPatientSearch =
        _number?.length > 0
          ? _number
          : _patientName?.length > 0
          ? _patientName
          : undefined;
    }
    if (this.searchText && this.searchText.length > 0) {
      this.textForPatientSearch = this.searchText;
    }

    this.newPatientForm = {
      name: _patientName,
      gender: '',
      contacts: [
        {
          countryCode: _countryCode,
          number: _number,
          isPrimaryContactNumber: true,
          isWhatsappContactNumber: true,
          smsCommunicationOn: true,
          whatsappCommunicationOn: true,
        },
      ],
      emails: [{ email: '', isPrimary: true, emailCommunicationOn: true }],
    };
  }

  transformPatientProfile() {
    if (this.patientProfile.docvitaId) {
      // console.log(this.patientProfile)
      this.newPatientForm['name'] = this.patientProfile.name;
      this.newPatientForm['gender'] = this.patientProfile.gender
        ? this.patientProfile.gender
        : '';
      let contacts = [];
      let emails = [];
      if (this.patientProfile.emails && this.patientProfile.emails.size > 0) {
        for (let i of Object.values(classToPlain(this.patientProfile.emails))) {
          emails.push(i);
        }
      }
      if (
        this.patientProfile.contactNumbers &&
        this.patientProfile.contactNumbers.size > 0
      ) {
        for (let i of Object.values(
          classToPlain(this.patientProfile.contactNumbers)
        )) {
          for (let j of Object.values(classToPlain(i))) {
            contacts.push(j);
          }
        }
      }
      if (emails.length > 0) {
        this.newPatientForm['emails'] = emails;
      } else if (
        this.patientProfile.primaryEmail &&
        this.patientProfile.primaryEmail.length > 0
      ) {
        this.notifyContactNotSaved = true;
        this.newPatientForm['emails'] = [
          {
            email: this.patientProfile.primaryEmail,
            isPrimary: true,
            emailCommunicationOn: true,
          },
        ];
      } else {
        this.newPatientForm['emails'] = [
          { email: '', isPrimary: false, emailCommunicationOn: true },
        ];
      }

      if (contacts.length > 0) {
        this.newPatientForm['contacts'] = contacts;
      } else if (
        this.patientProfile.primaryContactNumber &&
        this.patientProfile.primaryContactNumber.number &&
        this.patientProfile.primaryContactNumber.number.length > 0
      ) {
        this.notifyContactNotSaved = true;
        let num = this.patientProfile.primaryContactNumber;
        this.newPatientForm['contacts'] = [
          {
            countryCode: num.countryCode,
            number: num.number,
            isPrimaryContactNumber: true,
            isWhatsappContactNumber: true,
            smsCommunicationOn: true,
            whatsappCommunicationOn: true,
          },
        ];
      } else {
        this.newPatientForm['contacts'] = [
          {
            countryCode: '',
            number: '',
            isPrimaryContactNumber: false,
            isWhatsappContactNumber: false,
            smsCommunicationOn: true,
            whatsappCommunicationOn: true,
          },
        ];
      }
    }
  }

  createPatient() {
    let _ = this.newPatientForm;
    let isValid = true;
    let newContactMap = {};
    let newEmailMap = {};
    //for back-compat
    let mobileNumber = '';
    let countryCode = '';
    let email = '';

    if (_['gender'] == null) _['gender'] = '';

    if (_['contacts']) {
      if (mobileNumber.length < 1 && _['contacts'].length == 1) {
        mobileNumber = _['contacts'][0]['number'].toString();
        countryCode = _['contacts'][0]['countryCode'].toString();
      }
      for (let i of _['contacts']) {
        if (
          i['countryCode'].toString().length > 0 &&
          i['number'].toString().length > 0
        ) {
          let nc = new ContactNumber();
          nc.countryCode = i['countryCode'].toString();
          nc.number = i['number'].toString();
          nc.isPrimaryContactNumber = i['isPrimaryContactNumber'];
          nc.isWhatsappContactNumber = i['isWhatsappContactNumber'];
          nc.smsCommunicationOn = i['smsCommunicationOn'];
          nc.whatsappCommunicationOn = i['whatsappCommunicationOn'];
          if (newContactMap[i['countryCode']]) {
            newContactMap[i['countryCode']][i['number']] = this.jsonParser(
              classToPlain(nc)
            );
          } else {
            newContactMap[i['countryCode']] = {};
            newContactMap[i['countryCode']][i['number']] = this.jsonParser(
              classToPlain(nc)
            );
          }
          if (i['isPrimaryContactNumber'] && mobileNumber.length < 1) {
            mobileNumber = i['number'].toString();
            countryCode = i['countryCode'].toString();
          }
        } else {
          isValid = false;
          break;
        }
      }
    }
    if (_['emails']) {
      if (email.length < 1 && _['emails'].length == 1) {
        email = _['emails'][0]['email'];
      }
      for (let i of _['emails']) {
        if (i['email'].length > 0) {
          let ne = new Email();
          ne.email = i['email'];
          ne.isPrimary = i['isPrimary'];
          ne.emailCommunicationOn = i['emailCommunicationOn'];
          newEmailMap[i['email']] = this.jsonParser(classToPlain(ne));
          if (i['isPrimary'] && email.length < 1) {
            email = i['email'];
          }
        }
        // else{
        //   isValid=false;
        //   break;
        // }
      }
    }
    if (isValid) {
      if (
        this.patientProfile.docvitaId &&
        this.patientProfile.docvitaId.length > 0
      ) {
        let c_patientProfile = classToPlain(this.patientProfile);
        if (this.patientProfile.primaryContactNumber) {
          c_patientProfile['primaryContactNumber']['countryCode'] = countryCode;
          c_patientProfile['primaryContactNumber']['number'] = mobileNumber;
        } else {
          c_patientProfile['primaryContactNumber'] = {};
          c_patientProfile['primaryContactNumber']['countryCode'] = countryCode;
          c_patientProfile['primaryContactNumber']['number'] = mobileNumber;
        }
        if (this.patientProfile.primaryEmail) {
          c_patientProfile['primaryEmail'] = email;
        }
        c_patientProfile['emails'] = classToPlain(newEmailMap);
        c_patientProfile['contactNumbers'] = classToPlain(newContactMap);
        c_patientProfile['name'] = _['name'];
        c_patientProfile['gender'] = _['gender'];
        if (c_patientProfile['name'].length > 0) {
          this.updateExisitingPatient(this.jsonParser(c_patientProfile));
        } else {
          this._toastrService.warning('Missing details');
        }
      } else {
        let body = {
          name: _['name'],
          gender: _['gender'],
          contactNumbers: this.jsonParser(classToPlain(newContactMap)),
          emails: this.jsonParser(classToPlain(newEmailMap)),
          countryCode: countryCode,
          mobileNumber: mobileNumber,
          email: email,
        };
        // console.log(body)
        if (body['name'].length > 0) {
          this.createPatientFinal(body);
        } else {
          this._toastrService.warning('Missing details');
        }
      }
    } else {
      this._toastrService.warning('Invalid details');
    }
  }

  jsonParser(data) {
    return JSON.parse(JSON.stringify(data));
  }

  createPatientFinal(body) {
    this.bookingLoader = true;
    this._patientService
      .createPatientProfile(body)
      .then((res) => {
        this.bookingLoader = false;
        this._toastrService.success('Patient Created!');
        this.closeModal();
      })
      .catch((err) => {
        this.bookingLoader = false;
        console.log(err);
        this._toastrService.error('Patient Failed to create!');
      });
  }
  updateExisitingPatient(body) {
    // console.log('Updated patient:', body);
    this.bookingLoader = true;
    this._patientService
      .updatePatientProfile(body)
      .then((res) => {
        this.updatedPatient.emit();
        this.bookingLoader = false;
        this._toastrService.success('Patient Updated!');
        this.closeModal();
      })
      .catch((err) => {
        this.bookingLoader = false;
        console.log(err);
        this._toastrService.error('Patient Failed to update!');
      });
  }

  closeModal() {
    this.modalRef.hide();
  }
  setNumberToSub(event, index) {
    if (event) {
      let number = event.target.value;
      let valObj = {
        number: number + '',
        index: +index,
      };
      this.numberEntrySub.next(valObj);
    } else {
    }
  }
  numberParser(number: string, index: number) {
    if (number && number.length > 0) {
      number = number.trim().split(' ').join('');
      let _phoneNumber = parsePhoneNumber('+' + number);
      if (number && number.length > 0 && _phoneNumber.isValid()) {
        let _countryCode = _phoneNumber.countryCallingCode;
        let _number = _phoneNumber.nationalNumber.trim().split(' ').join('');
        if (
          this.newPatientForm &&
          this.newPatientForm['contacts'] &&
          this.newPatientForm['contacts'][index]
        ) {
          this.newPatientForm['contacts'][index]['number'] = _number + '';
          this.newPatientForm['contacts'][index]['countryCode'] =
            _countryCode + '';
          console.log('final :', _countryCode, _number);
        }
      }
    }
  }
}
