import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { LedgerService } from 'src/app/ledger/services/ledger.service';
import { PaymentSourcesArray } from '../../models-ts/constants/payment-constants';
import { Lead } from '../../models-ts/Entities/Lead';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
import { Appointment } from '../../models-ts/Entities/Appointment';
import { CountrywiseFeeCalc, Currency } from '../../models-ts/Helpers/countrywiseFeeCalc';
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault('Asia/Kolkata');

@Component({
  selector: 'manual-credit-entry',
  templateUrl: './manual-credit-entry.component.html',
  styleUrls: ['./manual-credit-entry.component.scss'],
  providers: [LedgerService],
})
export class ManualCreditEntryComponent implements OnInit {
  @Input() leadDetails: Lead = new Lead();
  modalRef: BsModalRef;
  isLoading: boolean = false;
  creditAmount: number;
  creditSource: string;
  creditedOnDate: string;
  creditedOnTime: string;
  currency:Currency = Currency.INR;
  paymentId: string;
  paymentSourceArray: string[] = PaymentSourcesArray;
  isFetchingInfo: boolean = false;
  infoFetchedOnce: boolean = false;
  constructor(
    private modalService: BsModalService,
    private toastr: ToastrService,
    private ledgerService: LedgerService,
  ) {}
  ngOnInit(): void {}
  @ViewChild('manualCreditEntry') manualCreditEntry;
  openModal() {
    this, this.initForm();
    this.modalRef = this.modalService.show(this.manualCreditEntry);
  }
  initForm() {
    this.creditAmount = null;
    this.creditSource = null;
    this.creditedOnDate = null;
    this.creditedOnTime = null;
    this.paymentId = null;
    this.infoFetchedOnce = false;
    this.isFetchingInfo = false;
  }
  closeModal() {
    this.modalRef.hide();
  }
  creditSourceChanged(source: string) {
    this.creditSource = source;
  }
  createEntry() {
    if (this.validateFormEntry()) {
      let ledgerItem = {};
      ledgerItem['credit'] = +this.creditAmount;
      ledgerItem['debit'] = 0;
      ledgerItem['doctorId'] = this.leadDetails.consultationInfo.doctorId;
      ledgerItem['patientId'] = this.leadDetails.consultationInfo.patientId;
      ledgerItem['appointmentId'] =
        this.leadDetails.consultationInfo.appointmentId;
      ledgerItem['paymentSource'] = this.creditSource;
      ledgerItem['paymentId'] = this.paymentId;
      ledgerItem['createdOn'] = new Date(
        this.creditedOnDate + ' ' + this.creditedOnTime
      ).getTime();
      ledgerItem['currency'] = this.currency;
      this.isLoading = true;
      this.ledgerService
        .customAppointmentCreditEntry(this.leadDetails.id, ledgerItem)
        .then((res) => {
          this.isLoading = false;
          this.toastr.success('Amount updated to ledger!');
          this.closeModal();
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
          this.toastr.error('Failed to update!');
        });
    } else {
      this.toastr.warning('Invalid or Missing details');
    }
  }
  validateFormEntry() {
    if (
      this.creditAmount &&
      this.paymentId &&
      this.paymentId.length > 0 &&
      this.creditSource &&
      this.creditSource.length > 0 &&
      this.creditedOnDate &&
      this.creditedOnDate.length > 0 &&
      this.creditedOnTime &&
      this.creditedOnTime.length > 0 &&
      this.leadDetails &&
      this.leadDetails.consultationInfo
    ) {
      return true;
    } else {
      return false;
    }
  }

  fetchRazorpayTransactionInfo() {
    if(this.paymentId) {
      this.isFetchingInfo = true;
      this.ledgerService.getOrderInfoRZP(this.paymentId).then((val)=>{
        if(val) {
          this.creditAmount = +val["amount_paid"]/100
          let createdAt = +val["created_at"] * 1000;
          this.creditedOnDate = dayjs.tz(createdAt).format("YYYY-MM-DD")
          this.creditedOnTime = dayjs.tz(createdAt).format("HH:mm")
        }
        this.isFetchingInfo = false;
        this.infoFetchedOnce = true;
      }).catch(err=>{
        console.error(err);
        this.isFetchingInfo = false;
      });
    }
  }
  currencyToSymbol(currency:Currency){
    return new CountrywiseFeeCalc().currencyToSymbol(currency);
  }
  setCurrency(val:Currency){
    this.currency = val;
  }
}
