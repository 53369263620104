import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { DoctorListingProfile } from 'src/app/shared/models-ts/Actors/Doctor/DoctorListingProfile';
import { CRMUser } from 'src/app/shared/models-ts/Actors/User/CRMUser';
import { User } from 'src/app/shared/models-ts/Actors/User/User';
import { Lead } from 'src/app/shared/models-ts/Entities/Lead';

import { CheckListSection } from '../next-steps/next-steps.models';

@Component({
  selector: 'app-next-step-modal',
  templateUrl: './next-step-modal.component.html',
  styleUrls: ['./next-step-modal.component.css'],
})
export class NextStepModalComponent implements OnInit {
  @ViewChild('nextStepsModal') nextStepsModal;
  sections: CheckListSection[] = [];
  @Input() lead: Lead;
  currentHeroInfo: CRMUser;
  provider: DoctorListingProfile = new DoctorListingProfile();
  patient: User = new User();

  constructor(private _modalService: BsModalService) {}

  ngOnInit(): void {}

  open(
    sections: CheckListSection[],
    currentHeroInfo: CRMUser,
    provider: DoctorListingProfile,
    patient: User
  ) {
    this.sections = sections;
    this.provider = provider;
    this.patient = patient;
    this.currentHeroInfo = currentHeroInfo;
    if (this._modalService.getModalsCount() > 0) {
      this._modalService.hide();
    }

    this._modalService.show(this.nextStepsModal, {
      class: 'modal-lg',
    });
  }
}
