import { Component, OnInit, ViewChild } from '@angular/core';
import { classToPlain, plainToClass } from 'class-transformer';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { LedgerService } from 'src/app/ledger/services/ledger.service';
import { TransactionSource } from '../../models-ts/Enums/pay';
import { Currency } from '../../models-ts/Helpers/countrywiseFeeCalc';
import { AppointmentLedgerItem } from '../../models-ts/Protocols/AppointmentLedgerItem';
import { LedgerItem } from '../../models-ts/Protocols/LedgerItem';

@Component({
  selector: 'update-ledger-path',
  templateUrl: './update-ledger-path.component.html',
  styleUrls: ['./update-ledger-path.component.scss']
})
export class UpdateLedgerPathComponent implements OnInit {
  modalRef:BsModalRef;

  updateAmount:number;
  currency:Currency = Currency.INR;
  ledgerItem:AppointmentLedgerItem = new AppointmentLedgerItem();
  updatingLoader:boolean = false;
  leadId:string;
  constructor(
    private modalService:BsModalService,
    private toastrService:ToastrService,
    private ledgerService:LedgerService
  ) { }

  @ViewChild('ledgerPathUpdater') ledgerPathUpdater;
  ngOnInit(): void {
  }

  openModal(ledgerItem?:AppointmentLedgerItem,leadId?:string){
    if(ledgerItem){
      this.ledgerItem = plainToClass(AppointmentLedgerItem,classToPlain(ledgerItem))
      console.log(this.ledgerItem)
      if(ledgerItem.credit>0){
        this.updateAmount = ledgerItem.credit
      }else if(ledgerItem.debit>0){
        this.updateAmount = ledgerItem.debit
      }else{
        this.updateAmount = null;
      }
      if(ledgerItem.transactionDetails&&ledgerItem.transactionDetails.includes('Payout')){
        this.currency = Currency.INR;
      }else{
        this.currency = ledgerItem.currency
      }
      
    }else{
      this.updateAmount = null;
      this.currency = Currency.INR;
    }
    if(leadId){
      this.leadId = leadId
    }else{
      this.leadId = null;
    }
    this.modalRef = this.modalService.show(this.ledgerPathUpdater);
  }

  closeModal(){
    this.modalRef.hide();
  }
  setCurrency(val:Currency){
    this.currency = val;
  }

  async updateLedgerEntries(){
    this.updatingLoader = true;
    if(this.updateAmount!=null&&this.currency!=null){
      const case_msg = this.getUpdateCase();
      if(case_msg&&window.confirm(case_msg)){
        try {
          const update = await this.ledgerService.updateLedgerEntriesTrack(this.ledgerItem.id,this.leadId,this.updateAmount,this.currency)
          this.updatingLoader = false;
          if(update){
            this.toastrService.success("Updated successfully")
            this.closeModal();
          }else{
            this.toastrService.error("Failed to update entries..")
          }
        } catch (error) {
          this.updatingLoader = false;
          this.toastrService.error("Failed to update entries..")
        }
      }else{
        this.toastrService.warning('Cancelled updation')
        this.updatingLoader = false;
      }
    }else{
      this.updatingLoader = false;
      this.toastrService.warning('Invalid entries..')
    }
  }  

  getUpdateCase(){
    if(this.ledgerItem){
      let msg = '';
      if(
        this.ledgerItem.source==TransactionSource.DocVitaWallet&&
        this.ledgerItem.credit&&
        this.ledgerItem.debit==0&&
        this.ledgerItem.transferId
      ){
        msg = 'CASE => Paid Via DocvitaWallet\n\nUpdate path:\n* appointment ledger credit \n* Client ledger debit'
      }
      else if(
        this.ledgerItem.source==TransactionSource.PartnerWallet&&
        this.ledgerItem.credit&&
        this.ledgerItem.debit==0&&
        this.ledgerItem.transferId
      ){
        msg = 'CASE => Paid Via PartnerWallet\n\nUpdate path:\n* appointment ledger credit \n* Partner ledger debit'
      }
      else if(
        this.ledgerItem.source==TransactionSource.AppointmentWallet&&
        this.ledgerItem.debit&&
        this.ledgerItem.credit==0&&
        this.ledgerItem.transactionDetails&&
        this.ledgerItem.transactionDetails.includes('Refunded')&&
        this.ledgerItem.transactionDetails.includes('client')&&
        this.ledgerItem.transactionDetails.includes('wallet')&&
        this.ledgerItem.transferId
      ){
        msg = 'CASE => Refunded to DocvitaWallet\n\nUpdate path:\n* appointment ledger debit \n* Client ledger credit'
      }
      else if(
        this.ledgerItem.source==TransactionSource.AppointmentWallet&&
        this.ledgerItem.debit&&
        this.ledgerItem.credit==0&&
        this.ledgerItem.transactionDetails&&
        this.ledgerItem.transactionDetails.includes('Refunded')&&
        this.ledgerItem.transactionDetails.includes('partner')&&
        this.ledgerItem.transactionDetails.includes('wallet')&&
        this.ledgerItem.ledgerId
      ){
        msg = 'CASE => Refunded to PartnerWallet\n\nUpdate path:\n* appointment ledger debit \n* Partner ledger credit'
      }
      else if(
        (this.ledgerItem.source==TransactionSource.Razorpay||
          this.ledgerItem.source==TransactionSource.Razorpay.toString().toLowerCase()||
          this.ledgerItem.source==TransactionSource.Cashfree||
          this.ledgerItem.source==TransactionSource.Cashfree.toString().toLowerCase()||
          this.ledgerItem.source==TransactionSource.Stripe||
          this.ledgerItem.source==TransactionSource.Stripe.toString().toLowerCase()||
          this.ledgerItem.source==TransactionSource.GPay||
          this.ledgerItem.source==TransactionSource.HDFCBANK||
          this.ledgerItem.source==TransactionSource.Paytm||
          this.ledgerItem.source==TransactionSource.Wise)&&
          this.ledgerItem.credit&&
          this.ledgerItem.debit==0&&
          this.ledgerItem.transferId
      ){
        msg = 'CASE => Paid Via '+this.ledgerItem.source+'\n\nUpdate path:\n* appointment ledger credit \n* Money in credit \n* Money in debit'
      }
      else if(
        this.ledgerItem.transactionDetails&&
        this.ledgerItem.transactionDetails.includes('Payout')&&
        this.ledgerItem.transactionDetails.includes('Provider')&&
        this.ledgerItem.id
      ){
        msg = 'CASE => Payout to provider\n\nUpdate path:\n* appointment ledger debit \n* provider ledger credit'
      }
      else if(
        this.ledgerItem.transactionDetails&&
        this.ledgerItem.transactionDetails.includes('Payout')&&
        this.ledgerItem.transactionDetails.includes('DocVita')&&
        this.ledgerItem.id
      ){
        msg = 'CASE => Payout to docvita\n\nUpdate path:\n* appointment ledger debit \n* docvita earnings credit'
      }else{
        msg = null;
      }
      if(msg){
        msg+= "\n\nAre you sure you want to update?"
      }
      return msg;
    }else{
      return null;
    }
  }

}
