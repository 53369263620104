import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as env from '../../../../environments/environment';
import { DoctorsService } from '../doctors/doctors.service';
@Injectable({
  providedIn: 'root'
})
export class MedicalRegistrationApiService {

  constructor(private _http:HttpClient, private _doctorsService: DoctorsService) { }

  markAsVerified(id: string) {
    return new Promise((resolve,reject)=>{
      this._doctorsService.medicalRegistrationVerificationApproval(id).then(()=>{
        resolve({});
      }).catch(err=>{
        console.error(err);
        reject({});
      })
    })
  }

  uploadFiles(files,id: string, heroId: string) {
    return new Promise((resolve,reject)=>{
      let json = {
        "medicalDocuments": [files], 
        "doctorProfileId": id,
        "heroId": heroId
      }
      let url = env.environment['medical-registration-docs-upload'];
      this._http.post(url,json).subscribe(
        (res: Response) => {
          resolve(res);
        },
        (err) => {
          console.log(err);
          reject(err);
        }
      );
    })
  }

  removeFile(id: string, documentId: string) {
    return new Promise((resolve,reject)=>{
      this._doctorsService.removeMedicalRegistrationProofFile(id,documentId).then(()=>{
        resolve({});
      }).catch(err=>{
        console.error(err);
        reject({});
      })
    })
  }
}
