import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as urls from '../../../../environments/environment';
import firebase from 'firebase';
import 'firebase/firestore';
@Injectable({
  providedIn: 'root',
})
export class MedRecordsService {
  constructor(private _http: HttpClient) {}
  uploadDocuments(files: any) {
    return new Promise((resolve, reject) => {
      let body = {
        files: files,
      };
      body = JSON.parse(JSON.stringify(body));

      let url = urls.environment['med-records-upload'];
      this._http
        .post(url, body)
        .toPromise()
        .then((res) => {
          if (
            res &&
            res['statusCode'] == 200 &&
            res['body']['success'] == true
          ) {
            resolve({ success: true, data: res['body']['data'] });
          } else {
            resolve({ success: false, data: res['body']['data'] });
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  deleteDocument(documentId: string, fileId: string) {
    return new Promise((resolve, reject) => {
      let body = {
        documentId,
        fileId,
      };
      body = JSON.parse(JSON.stringify(body));

      let url = urls.environment['med-records-delete'];
      this._http
        .post(url, body)
        .toPromise()
        .then((res) => {
          if (
            res &&
            res['statusCode'] == 200 &&
            res['body']['success'] == true
          ) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  updateDocument(documentId: string, jsonVal: any) {
    return new Promise((resolve, reject) => {
      let body = {
        documentId,
        jsonVal,
      };
      body = JSON.parse(JSON.stringify(body));

      let url = urls.environment['med-records-update'];
      this._http
        .post(url, body)
        .toPromise()
        .then((res) => {
          if (
            res &&
            res['statusCode'] == 200 &&
            res['body']['success'] == true
          ) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getMedicalRecords(leadId?: string, clientId?: string) {
    let ref = firebase
      .firestore()
      .collection('client_medical_records')
      .orderBy('uploadedOn', 'desc');
    if (leadId) {
      ref = ref.where('leadId', '==', leadId);
    }
    if (clientId) {
      ref = ref.where('clientId', '==', clientId);
    }
    return ref;
  }
}
