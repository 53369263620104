<!-- <div class="d-flex flex-column gap-2 w-100 font-14" *ngIf="!loading&&plansPurchased&&plansPurchased.length>0"> -->
    <!-- <div class="border p-2" *ngFor="let item of plansPurchased;let i = index;"> -->
        
        <!-- <div class="d-flex w-100 gap-1">
            <span class="fw-600">{{item?.planDetails?.validity}} days plan</span> -> <span class="text-primary">{{item?.pendingSessionBalance}} / {{item?.planDetails?.noOfSessions}}</span> sessions remaining <span class="ms-auto btn-sm btn-outline-danger">[expires on {{item?.planDetails?.planExpiresOnDate}}]</span> 
        </div>
        <div>
            [Per Session Cost: <span class="text-success">{{item?.planDetails?.pricing?.sellingPricePerSessionInINR?item?.planDetails?.pricing?.sellingPricePerSessionInINR:'-'}} INR</span>, Total Amount Paid: <span class="text-success">{{item?.planDetails?.pricing?.sellingPriceDisplayVal}}</span>] [Session Type: {{item?.planDetails?.sessionType}}]
        </div> -->
    <!-- </div> -->
<!-- </div> -->
<div class="border w-100 py-3 px-2 d-flex align-items-center gap-1 font-12" *ngIf="planSelected&&planSelected.planId">
    <span class="fw-600">{{planSelected?.planDetails?.validity}} days plan</span> -> <span class="text-primary">{{planSelected?.pendingSessionBalance}} / {{planSelected?.planDetails?.noOfSessions}}</span> sessions left <span class="text-muted">({{planSelected?.planDetails?.sessionType}})</span> <span class="ms-2 font-12 text-danger">[expiry {{planSelected?.planExpiryDateReadable}}]</span> <span class="ms-auto pointer fw-600" style="font-size: 14px" (click)="unselectPlan()">&times;</span> 
</div>

<div dropdown class="btn-group d-inline-block my-2 w-100" *ngIf="!loading&&plansPurchased&&plansPurchased.length>0&&!(planSelected&&planSelected.planId)" >
    <button id="button-animated" dropdownToggle type="button" class="btn btn-outline-secondary dropdown-toggle bg-white w-100 m-0"
        aria-controls="dropdown-animated">
        <ng-container *ngIf="!planSelected||!planSelected.planId">
            {{viewMode?'View Active plans':'Choose Plan'}}
        </ng-container>
        <ng-container *ngIf="planSelected&&planSelected.planId">
            <span class="fw-600">{{planSelected?.planDetails?.validity}} days plan</span> -> <span class="text-primary">{{planSelected?.pendingSessionBalance}} / {{planSelected?.planDetails?.noOfSessions}}</span> sessions left <span class="text-muted">({{planSelected?.planDetails?.sessionType}})</span> <span class="ms-2 font-12 text-danger">[expiry {{planSelected?.planExpiryDateReadable}}]</span> 
        </ng-container>
    </button>

    <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu dropdown-menu-left" role="menu"
        aria-labelledby="button-animated" style="max-height:250px;overflow-y:auto;">
        <li role="menuitem" *ngFor="let item of plansPurchased;let i = index;">
            <a class="dropdown-item p-3 w-100 d-flex gap-2" (click)="selectPlan(item)">
                <span class="fw-600">{{item?.planDetails?.validity}} days plan</span> -> <span class="text-primary">{{item?.pendingSessionBalance}} / {{item?.planDetails?.noOfSessions}}</span> sessions left <span class="text-muted">({{item?.planDetails?.sessionType}})</span> <span class="ms-auto font-12 text-danger">[expiry {{item?.planExpiryDateReadable}}]</span> 
            </a>
        </li>
    </ul>
</div>

<div *ngIf="loading" class="d-flex align-items-center text-muted font-12">
    Checking for active plans
    <div class="spinner-border spinner-border-sm ms-2" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>


<div *ngIf="!loading&&plansPurchased.length==0" class="text-muted">
    No active plans 🍀
</div>
