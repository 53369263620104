<div class="mb-5">
  <app-navbar (backClicked)="backClicked()" [title]="title"></app-navbar>
</div>

<div class="d-flex">
  <div class="col-md-4">
    <app-doc-master-list
      (selectedDoctor)="onSelectedDoctorClicked($event)"
    ></app-doc-master-list>
  </div>

  <div class="col-md-8" style="overflow-y: scroll; height: calc(100vh - 150px)">
    <!-- <ul
      class="nav nav-tabs"
      style="cursor: pointer"
      id="nav-tab"
      role="tablist"
    >
      <li class="nav-item" role="presentation" *ngIf="!isNewDoctor">
        <a
          class="nav-link"
          (click)="routerToBasicInfo()"
          id="info-tab"
          data-toggle="tab"
          aria-controls="info"
          >Basic Information</a
        >
      </li>
    </ul> -->

    <router-outlet></router-outlet>
  </div>
</div>
