<div class="w-100 p-2">
  <table class="table table-hover" *ngIf="!apptTimeState.inPast" >
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Done?</th>
        <th scope="col">Task / Communication</th>
        <th scope="col">Actions</th>
        <th scope="col">Remarks</th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let t of tasks; let i = index"
        [ngStyle]="{ opacity: t.isDone ? '0.4' : '1' }"
      >
        <th scope="row">
          <div class="d-flex flex-column h-100">
            {{ i + 1 }}
          </div>
        </th>
        <!-- [id]="'allCheck' + i"   -->
        <!-- (change)="selectAll($event, i)" -->
        <td>
          <div class="d-flex flex-column h-100">
            <input
              class="form-check-input"
              type="checkbox"
              [(ngModel)]="t.isDone"
              (change)="
                t.checkboxToggleFunc(t.key, $event.target.checked, context)
              "
            />
          </div>
        </td>
        <td>
          <span class="me-2">
            {{ t.title }}
            <!-- <button class="btn btn-sm btn-outline-primary border-0">
              <i class="feather icon-copy me-2"></i>
            </button> -->
          </span>
        </td>
        <td>
          <div class="d-flex flex-column">
            <ng-container *ngIf="t.type && t.type == 'client'">
              <button
                class="btn btn-sm btn-outline-primary border-0 d-flex align-items-center my-1"
                placement="top"
                [tooltip]="'Client: ' + number"
                *ngFor="let number of patientWaNumbers"
                (click)="t.onClickFunc(context, t, number)"
              >
                <i class="feather icon-message-square me-2"></i> Send Message to
                +{{ number }}
              </button>
            </ng-container>
            <ng-container *ngIf="t.type && t.type == 'provider'">
              <button
                class="btn btn-sm btn-outline-primary border-0 d-flex align-items-center my-1"
                placement="top"
                [tooltip]="'Provider: ' + number"
                *ngFor="let number of providerWaNumbers"
                (click)="t.onClickFunc(context, t, number)"
              >
                <i class="feather icon-message-square me-2"></i> Send Message to
                +{{ number }}
              </button>
            </ng-container>
          </div>
        </td>
        <td>
          Updated by: {{ t.updatedByName }} <br />at
          {{ t.updatedOn | date: "medium" }}
        </td>
      </tr>
    </tbody>
  </table>
</div>

<ng-template #checklistSection let-section="section">
  <div class="d-flex flex-column py-2 border-top">
    <span
      (click)="openSectionPopup([section])"
      style="font-size: 14px; font-weight: 450"
      >{{ section.sectionTitle }}</span
    >
    <ng-container *ngFor="let item of section.entities">
      <ng-container
        *ngTemplateOutlet="
          checklistSectionEntity;
          context: { checkListEntity: item }
        "
      ></ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #checklistSectionEntity let-checkListEntity="checkListEntity">
  <div class="d-flex flex-row align-items-center">
    <ng-container
      *ngTemplateOutlet="
        radioButtonTemplate;
        context: { key: checkListEntity.key }
      "
    >
    </ng-container>
    <span style="font-size: 14px; font-weight: 400">
      {{ checkListEntity.title }}
    </span>
    <button
      type="button"
      class="btn btn-sm btn-outline-primary d-flex align-items-center border-0 mx-1"
      (click)="copyTheDamnThing(checkListEntity.message)"
    >
      <i style="font-size: 15px" class="material-icons me-1">content_copy</i>
    </button>
  </div>
</ng-template>

<ng-template #radioButtonTemplate let-key="key">
  <div class="pointer">
    <i
      *ngIf="!currentChecklistAction(key)"
      (click)="recordEvent(key, !currentChecklistAction(key))"
      class="material-icons me-1 task-item-radio"
      >radio_button_unchecked</i
    >
    <i
      *ngIf="currentChecklistAction(key)"
      (click)="recordEvent(key, !currentChecklistAction(key))"
      class="material-icons me-1 task-item-radio"
      >radio_button_checked</i
    >
  </div>
</ng-template>

<app-next-step-modal [lead]="lead"></app-next-step-modal>

<app-common-phrases></app-common-phrases>
