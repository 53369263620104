import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { DoctorDataService } from 'src/app/shared/services/doctor-data/doctor-data.service';
import { Provider } from 'src/app/shared/models-ts/Actors/Provider/Provider';
import { DoctorListingProfile } from 'src/app/shared/models-ts/Actors/Doctor/DoctorListingProfile';
import { DoctorsService } from '../../shared/services/doctors/doctors.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Education } from 'src/app/shared/models-ts/Entities/Education';
import { MedicalRegistration } from 'src/app/shared/models-ts/Entities/MedicalRegistration';
import { MedicalRegistrationApiService } from '../../shared/services/medical-registration/medical-registration-api.service';
import { AuthUserInfoService } from 'src/app/shared/services/auth-user-info/auth-user-info.service';
import { ActivatedRoute } from '@angular/router';
declare var $: any;
import { internTags } from 'src/app/shared/constants/constants';
import { debounceTime } from 'rxjs/operators';
import { DoctorsSearchService } from 'src/app/shared/services/doctors/doctors-search.service';
@Component({
  selector: 'app-doc-profile-education',
  templateUrl: './doc-profile-education.component.html',
  styleUrls: ['./doc-profile-education.component.css'],
})
export class DocProfileEducationComponent implements OnInit,OnDestroy {
  doctorProfile: DoctorListingProfile;
  dataLayerSubscription: Subscription;
  search1: Subscription;
  search2: Subscription;
  currentHeroInfo: any;
  selectedDegree: any[];
  dropdownList: any[] = [];
  educationDetails: any[] = [];
  registrationDetails: any[] = [];
  internTags: {} = internTags;
  fragment: string;
  degree = [
    { key: 'MDS ', value: 'MDS ' },
    { key: 'BDS', value: 'BDS' },
  ];
  filesForUpload: any[] = [];
  myFullresImage: string;
  isMedicalRegistrationCannotBeVerified: boolean = false;

  degreeSub = new Subject<string>();
  degreeMasterList=[];

  collegeSub = new Subject<string>();
  collegeMasterList=[];


  constructor(
    private _doctorDataService: DoctorDataService,
    private _doctorService: DoctorsService,
    private _doctorSearchService: DoctorsSearchService,
    private _activatedRoute: ActivatedRoute,
    private _spinner: NgxSpinnerService,
    private _toastr: ToastrService,
    private _medicalRegistrationApiService: MedicalRegistrationApiService,
    private _authUserInfoService: AuthUserInfoService
  ) {}

  ngOnInit(): void {
    this.currentHeroInfo = this._authUserInfoService.getUserInfo();
    this._activatedRoute.fragment.subscribe((fragment) => {
      this.fragment = fragment;
    });
    this.dataLayerSubscription = this._doctorDataService.currentSelection.subscribe(
      (data) => {
        if (data != null && data['memberSelected'] != null) {
          this.educationDetails = [];
          this.registrationDetails = [];
          this.doctorProfile = data['memberSelected'];
          this.getEducationDetails();
          this.getRegistationDetails();
          this.isMedicalRegistrationCannotBeVerified =
            this.doctorProfile.internTags != null &&
            this.doctorProfile.internTags.includes(
              'cant_verify_medical_registration'
            );
        }
      }
    );

    this.search1 = this.degreeSub.pipe((debounceTime(800))).subscribe(val=>{this.searchDegree(val)})
    this.search2 = this.collegeSub.pipe((debounceTime(800))).subscribe(val=>{this.searchCollege(val)})
  }

  ngOnDestroy(){
    this.search1.unsubscribe();
    this.search2.unsubscribe();
    this.dataLayerSubscription.unsubscribe();
  }

  openImage(url) {
    this.myFullresImage = null;
    this.myFullresImage = url;
    $('#imageModal').modal('show');
  }

  getEducationDetails() {
    if (
      this.doctorProfile.education &&
      this.doctorProfile.education.length > 0
    ) {
      this.educationDetails = this.doctorProfile.education;
    }
  }

  addNewEducationRow() {
    let obj = {
      degree: '',
      instituteName: '',
      yearOfPassing: '',
      isVerified: false,
    };
    this.educationDetails.push(obj);
  }

  deleteEducationRow(index) {
    this.educationDetails.splice(index, 1);
  }

  getRegistationDetails() {
    if (
      this.doctorProfile.registration &&
      this.doctorProfile.registration.length > 0
    ) {
      this.registrationDetails = this.doctorProfile.registration;
    }
  }

  addNewRegistationRow() {
    let obj = {
      number: '',
      council: '',
      yearOfRegistration: '',
    };
    this.registrationDetails.push(obj);
  }

  deleteRegistationRow(index) {
    this.registrationDetails.splice(index, 1);
  }

  updateUserStats(heroId, docId, reqType) {
    this._doctorService
      .updateDocvitaUserStats(heroId, docId, reqType)
      .subscribe((data) => {
        if (data['statusCode'] == 200) {
          console.log('User stats updated!');
        } else {
          console.log('User stats did not update!');
        }
      });
  }

  update() {
    if (
      (this.educationDetails && this.educationDetails.length > 0) ||
      (this.registrationDetails && this.registrationDetails.length > 0)
    ) {
      this.educationDetails = this.educationDetails.filter((element) => {
        return element.degree.length != 0 || element.instituteName.length != 0;
      });
      this.registrationDetails = this.registrationDetails.filter(
        (element: MedicalRegistration) => {
          let yor = element.yearOfRegistration + '';
          let regNo = element.number + '';
          return (
            yor.length != 0 && yor.length == 4 && regNo && regNo.length > 0
          );
        }
      );

      this._doctorService
        .updateEducationAndRegistration(
          this.educationDetails,
          this.registrationDetails,
          this.doctorProfile.id
        )
        .subscribe((data) => {
          this._toastr.success('Updated Successfully');
          this.updateUserStats(
            this.currentHeroInfo.docvitaId,
            this.doctorProfile.id,
            'update'
          );
        });
    }
  }

  markAsverifiedClicked() {
    this._medicalRegistrationApiService
      .markAsVerified(this.doctorProfile.id)
      .then(() => {
        this._toastr.success('Medical Registration Verified Successfully');
      })
      .catch((err) => {
        console.error(err);
        this._toastr.error('Something went wrong');
      });
  }

  fileProgress(fileInput: any) {
    for (let f of <File[]>fileInput.target.files) {
      this.filesForUpload.push({
        name: f.name,
        data: f,
      });
      var reader = new FileReader();
      reader.readAsDataURL(f);
      reader.onload = (_event) => {
        if (
          this.doctorProfile != null &&
          this._authUserInfoService.getUserInfo() != null &&
          this._authUserInfoService.getUserInfo().docvitaId != null
        ) {
          this._toastr.info('Uploading : ' + f.name);
          this._medicalRegistrationApiService
            .uploadFiles(
              reader.result,
              this.doctorProfile.id,
              this._authUserInfoService.getUserInfo().docvitaId
            )
            .then(() => {
              this._toastr.success('Upload successful for: ' + f.name);
            })
            .catch((err) => {
              console.error(err);
              this._toastr.error('Upload failed for: ' + f.name);
            });
        }
      };
    }
    fileInput.target.value = null;
  }

  removeDocument(document) {
    this._medicalRegistrationApiService
      .removeFile(this.doctorProfile.id, document.id)
      .then(() => {
        this._toastr.success('Document removed successfully');
      })
      .catch((err) => {
        console.error(err);
        this._toastr.error('Something went wrong');
      });
  }

  getTitleName() {
    if (!this.fragment || this.fragment == 'verify') {
      return 'Verification';
    } else if (this.fragment == 'upload') {
      return 'Upload Documents';
    }
  }

  addCustomDegree = (term) => {
    let _ = {title:term}
    this._doctorService.updateDegreeToMasterList(_).then().catch(err=>{})
    return term
  };

  addCustomCollege = (term) => {
    let _ = {title:term}
    this._doctorService.updateCollegeToMasterList(_).then().catch(err=>{})
    return term;
  };

  isMedicalRegistrationVerified() {
    return (
      this.doctorProfile != null &&
      this.doctorProfile.isMedicalRegistrationVerified
    );
  }

  updateInternalTags(tag: string, addTag: boolean) {
    let promise: Promise<any>;
    if (addTag) {
      //Add query
      promise = this._doctorService.updateDoctorProfileInternTags(
        tag,
        this.doctorProfile.id
      );
    } else {
      //Remove tag
      promise = this._doctorService.removeDoctorProfileInternTags(
        tag,
        this.doctorProfile.id
      );
    }
    promise
      .then(() => {
        this.isMedicalRegistrationCannotBeVerified = addTag;
        this._toastr.success('Updated successfully.');
      })
      .catch((err) => {
        console.error(err);
        this._toastr.error('Something went wrong');
      });
  }

  checkboxConfirmationDialog(dbKey: string, event: boolean) {
    console.log(dbKey);
    let oldVal = this.doctorProfile.internTags;
    if (event == false) {
      if (window.confirm('Are you sure to undo this?')) {
        this.updateInternalTags(dbKey, event);
      } else {
        setTimeout(() => {
          this.doctorProfile.internTags = oldVal;
        }, 200);
      }
    } else {
      this.updateInternalTags(dbKey, event);
    }
  }

  checkTag() {
    if (
      this.doctorProfile.internTags &&
      this.doctorProfile.internTags.length > 0
    ) {
      return this.doctorProfile.internTags.includes(
        internTags.cant_verify_medical_registration.key
      );
    }
  }

  setForDegreeSearch(degree){
    let _ = degree.target.value;
    this.degreeSub.next(_);
  }

  searchDegree(searchVal){
    this.degreeMasterList = [];
    this._doctorSearchService.searchDegreeByName(searchVal).then(res=>{
      if (res != null) {
        res.forEach((val) => {
          let json = val;
          this.degreeMasterList.push(json); 
        });
        // console.log(res.hits.hits)
      }
    }).catch(err=>{
      console.log(err);
    })
  }

  setForCollegeSearch(college){
    let _ = college.target.value;
    this.collegeSub.next(_);
  }

  searchCollege(searchVal){
    this.collegeMasterList = [];
    this._doctorSearchService.searchCollegeByName(searchVal).then(res=>{
      if (res != null) {
        res.forEach((val) => {
          let json = val;
          this.collegeMasterList.push(json); 
        });
      }
    }).catch(err=>{
      console.log(err);
    })
  }
}
