<button class="btn btn-outline-primary btn-sm border-0" type="button" [data-bs-toggle]="'offcanvasForChats'+number" data-bs-target="#offcanvasForChats" aria-controls="offcanvas-for-chats" tooltip="Show chat" placement="bottom" *ngIf="showButton">
    <i class="la la-whatsapp font-18"></i>
</button>

<div class="offcanvas offcanvas-end" tabindex="-1" [id]="'offcanvasForChats'+number" aria-labelledby="offcanvasRightLabel" style="min-width: 500px;">
  <div class="offcanvas-header">
    <div class="d-flex w-100">
        <h5 class="offcanvas-title" id="offcanvasRightLabel">Chats</h5>
        <button class="btn btn-sm btn-outline-primary border-0 ms-auto me-2 d-flex align-items-center" tooltip="Open in docvita chat" placement="left" (click)="openWhatsAppChat()">
            Open Chat
            <i class="feather icon-arrow-right ms-1"></i>
        </button>
    </div>
    
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <div *ngIf="_chatItemId&&!invalidChatId" class="w-100" style="height: 100%;">
        <app-chat-window [leadChatListItemId]="_chatItemId" [inFollowups]="true"></app-chat-window>
    </div>
    <div *ngIf="invalidChatId" class="text-muted">
      No Chat available
    </div>
  </div>
</div>