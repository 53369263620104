import { Type, Expose, Transform, plainToClass } from "class-transformer";
import { ContactNumber } from "./ContactNumber";
import { Address } from "./Address";
import { AgeObject } from "./AgeObject";
import { Email } from "./Email";
import { EmergencyContact } from "./EmergencyContact";
import { BooleanAction } from "../../Protocols/BooleanAction";

export class Person {
  @Expose() docvitaId: string;

  @Expose() name: string;

  @Expose() gender: string;
  
  @Expose() pronoun: string;

  @Expose() dateOfBirth: string;

  @Expose() salutation: string;

  @Expose() language: string;

  @Expose() status: string;

  @Expose() primaryEmail: string;

  @Expose()
  photoUrl: string;

  @Expose()
  city: string;

  @Expose()
  sourceOfRegistration: string;

  @Expose()
  @Type(() => ContactNumber)
  primaryContactNumber: ContactNumber;

  @Expose()
  @Type(() => Address)
  primaryAdress: Address;

  @Expose()
  @Type(() => EmergencyContact)
  emergencyContacts: EmergencyContact[] = [];

  @Expose()
  @Transform(
    (value) => {
      let masterMap: Map<string, Map<string, ContactNumber>> = new Map<
        string,
        Map<string, ContactNumber>
      >();
      if (value) {
        for (let entryMap of Object.entries(value)) {
          let map = new Map<string, ContactNumber>();
          if (masterMap.has(entryMap[0])) {
            map = masterMap.get(entryMap[0]);
          }
          for (let c of Object.entries(entryMap[1])) {
            map.set(c[0], plainToClass(ContactNumber, c[1]));
          }
          masterMap.set(entryMap[0], map);
        }
      }
      return masterMap;
    },
    { toClassOnly: true }
  )
  contactNumbers: Map<string, Map<string, ContactNumber>> = new Map<
    string,
    Map<string, ContactNumber>
  >();

  @Expose()
  @Transform(
    (value) => {
      if (value) {
        let map = new Map<string, Email>();
        for (let entry of Object.entries(value)) {
          map.set(entry[0], plainToClass(Email, entry[1]));
        }
        return map;
      } else {
        return new Map<string, Email>();
      }
    },
    { toClassOnly: true }
  )
  emails: Map<string, Email> = new Map<string, Email>();

  @Expose() latestCountryOfVisit: string;

  @Expose() relationOf: string;

  @Expose() relationTypeName: string;

  @Expose() createdOn: number;

  @Expose() dnd_consent: boolean;

  @Expose()
  leadSource: string; //optional

  @Expose() country:string;

  @Expose() timezone:string;

  @Expose()
  @Type(() => AgeObject)
  ageObject: AgeObject;

  @Expose()
  @Type(() => BooleanAction)
  hasChatModuleAccess: BooleanAction = new BooleanAction();

  getAgeInYears() {
    if(this.ageObject && this.ageObject.years) {
      return this.ageObject.years;
    } else {
      return null;
    }
  }

  getAllContactNumbers() {
    return getAllContactNumbers(this);
  }
  getPrimaryContactNumbers() {
    return getPrimaryContactNumbers(this);
  }
  getWhatsappContactNumbers() {
    return getWhatsappContactNumbers(this);
  }
  getPrimaryEmail() {
    return getPrimaryEmail(this);
  }
  getEmails() {
    return getEmails(this);
  }
}

export function getAllContactNumbers(profile: Person) {
  let arr: ContactNumber[] = [];
  if (profile.contactNumbers) {
    profile.contactNumbers.forEach((val) => {
      val.forEach((c) => {
        arr.push(c);
      });
    });
  }
  if (profile.primaryContactNumber && profile.primaryContactNumber.number) {
    let number = profile.primaryContactNumber;
    let numberComp = arr.filter((da) => da && da.number == number.number);
    if (numberComp && numberComp.length == 0) {
      arr.push(profile.primaryContactNumber);
    }
  }
  return arr;
}

export function getPrimaryContactNumbers(profile: Person) {
  let arr: ContactNumber[] = [];
  if (profile.contactNumbers) {
    if (profile.contactNumbers.size == 1) {
      profile.contactNumbers.forEach((val) => {
        if (val.size == 1) {
          val.forEach((c) => {
            arr.push(c);
          });
        } else {
          val.forEach((c) => {
            if (c.isPrimaryContactNumber) {
              arr.push(c);
            }
          });
        }
      });
    } else {
      profile.contactNumbers.forEach((val) => {
        val.forEach((c) => {
          if (c.isPrimaryContactNumber) {
            arr.push(c);
          }
        });
      });
    }
  }
  if (
    arr.length == 0 &&
    profile.primaryContactNumber &&
    profile.primaryContactNumber.number &&
    profile.primaryContactNumber.number.length > 0
  ) {
    arr.push(profile.primaryContactNumber);
  }
  return arr;
}

export function getWhatsappContactNumbers(profile: Person) {
  let arr: ContactNumber[] = [];
  if (profile.contactNumbers) {
    if (profile.contactNumbers.size == 1) {
      profile.contactNumbers.forEach((val) => {
        if (val.size == 1) {
          val.forEach((c) => {
            arr.push(c);
          });
        } else {
          val.forEach((c) => {
            if (c.isWhatsappContactNumber) {
              arr.push(c);
            }
          });
        }
      });
    } else {
      profile.contactNumbers.forEach((val) => {
        val.forEach((c) => {
          if (c.isWhatsappContactNumber) {
            arr.push(c);
          }
        });
      });
    }
  }
  if (
    arr.length == 0 &&
    profile.primaryContactNumber &&
    profile.primaryContactNumber.number &&
    profile.primaryContactNumber.number.length > 0
  ) {
    arr.push(profile.primaryContactNumber);
  }
  return arr;
}

export function getPrimaryEmail(profile: Person) {
  if (profile.emails) {
    let email: Email = new Email();
    if (profile.emails.size == 1) {
      profile.emails.forEach((val) => {
        email = val;
      });
    } else {
      profile.emails.forEach((val) => {
        if (val.isPrimary) {
          email = val;
        }
      });
    }
    if (
      profile.emails.size == 0 &&
      profile.primaryEmail &&
      profile.primaryEmail.length > 0
    ) {
      email.email = profile.primaryEmail;
    }
    return email;
  } else {
    return null;
  }
}

export function getEmails(profile: Person) {
  if (profile.emails) {
    let emails: Email[] = [];
    if (profile.emails.size > 0) {
      profile.emails.forEach((val) => {
        emails.push(val);
      });
    }
    return emails;
  } else {
    return null;
  }
}


export class TimezoneObj{
    label: string;
    tzCode: string;
    name: string;
    utc: string;
}