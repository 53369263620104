import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthUserInfoService } from 'src/app/shared/services/auth-user-info/auth-user-info.service';
import { CRMUser } from 'src/app/shared/models-ts/Actors/User/CRMUser';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {
  @Input() title: string;
  @Input() bgWhite: boolean;
  @Output() backClicked: EventEmitter<void> = new EventEmitter();
  currentHeroInfo: CRMUser = new CRMUser();
  constructor(private _authUserInfoService: AuthUserInfoService) {}

  ngOnInit(): void {
    this.currentHeroInfo = this._authUserInfoService.getUserInfo();
  }

  back() {
    this.backClicked.emit();
  }

  logoutCurrentUser() {
    this._authUserInfoService.signout();
  }
}
