import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {plainToClass} from 'class-transformer';
import { ToastrService } from 'ngx-toastr';
import { DoctorListingProfile } from 'src/app/shared/models-ts/Actors/Doctor/DoctorListingProfile';
import { Email } from 'src/app/shared/models-ts/Actors/Person/Email';
import { DoctorsService } from 'src/app/shared/services/doctors/doctors.service';
declare var $: any;
@Component({
  selector: 'app-emails',
  templateUrl: './emails.component.html',
  styleUrls: ['./emails.component.css']
})
export class EmailsComponent implements OnInit {

  _profileData: DoctorListingProfile
  @Input() set profileData (val: DoctorListingProfile){
    this.emails = [];
    if(val.emails){
      this.emails = Array.from(val.emails.values());
    }
    this._profileData = val;
  }
  get profileData(): DoctorListingProfile {
    return this._profileData;
  }
  @Output() emailMap:EventEmitter<any> = new EventEmitter<any>(); 
  emails: Email[] = new Array();
  valid: boolean = true;

  displayEmail: string;
  source: string;
  index;

  constructor(private _toastrService: ToastrService, private _doctorService: DoctorsService,) { }

  ngOnInit(): void {
    this.emails = [];
    if(this.profileData.emails){
      this.emails = Array.from(this.profileData.emails.values());
    }
  }

  addEmailField(){
    this.emails.push(new Email());
  }
  deleteEmailField(x){
    let emailToDelete = this.emails[x].email;
    if(emailToDelete!=undefined&&this.profileData.emails&&this.profileData.emails.has(emailToDelete)){
    this.emails.splice(x,1);
    this._doctorService.deleteEmails(this.profileData.id,emailToDelete).then(res=>{
          this._toastrService.success('✨Deleted Email info!');
    }).catch(err=>{
        this._toastrService.error('Failed to delete!')
    })
    }else{
      this.emails.splice(x,1);
    }
    
  }

  getEmailMap(){
    let emailMap = new Map<string, Email>();
    if(this.emails && this.emails.length>0){
      for (let email of this.emails) {
        if(!email.email.includes('@')){
          this._toastrService.warning("Invalid email!")
          this.valid = false;
          break;
        }
        else{
          this.valid = true;
          emailMap[email.email] = email;
        }
      }
    }else{
      emailMap = null;
    }
    // console.log("final emails map",{emails:emailMap,valid:this.valid});
    this.emailMap.emit({emails:emailMap,valid:this.valid});
  }

  // openModal(email,index){
  //   this.source = email['source']?email['source']:'';
  //   this.displayEmail = email['email']?email['email']:'';
  //   this.index = index;
  //   $('#editEmails').modal('show');
  // }

  // saveChangesOfModal(){
  //   this.emails[this.index]['source'] = this.source;
  //   this._toastrService.warning('Saved changes locally!');
  //   $('#editEmails').modal('hide');
  // }
}
