import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as urls from '../../../../environments/environment';
import * as env from '../../../../environments/environment';

import firebase from 'firebase';
import { classToPlain, plainToClass } from 'class-transformer';
import { Wallet } from '../../models-ts/Entities/Wallet';
import { AuthUserInfoService } from '../auth-user-info/auth-user-info.service';
import { PaymentTransaction } from 'src/app/shared/models-ts/Entities/PaymentTransaction/PaymentTransaction';
import { User } from '../../models-ts/Actors/User/User';
import { Lead } from '../../models-ts/Entities/Lead';
import { ListedIn } from '../../models-ts/Entities/ListedIn';
import { ReasonOfVisit } from '../../models-ts/Entities/ReasonOfVisit';
import { DoctorListingProfile } from '../../models-ts/Actors/Doctor/DoctorListingProfile';
import { CRMUser } from '../../models-ts/Actors/User/CRMUser';
import { DayjsHelper } from '../../models-ts/Helpers/dayjsHelper';
@Injectable({
  providedIn: 'root',
})
export class DoctorsService {
  providerProfileCache: Map<string, DoctorListingProfile> = new Map();

  constructor(
    private _http: HttpClient,
    private _authUserInfoService: AuthUserInfoService,
  ) { }

  specializationArray(val: string) {
    let array = [val];
    switch (val.toLowerCase()) {
      case 'gynecologist': {
        array.push('Gynaecologist');
        break;
      }
      case 'gynaecologist': {
        array.push('Gynecologist');
        break;
      }
      case 'pediatrician': {
        array.push('Paediatrician');
        break;
      }
      case 'paediatrician': {
        array.push('Pediatrician');
        break;
      }
    }
    return array;
  }

  getProfileForListing(specialisations, city, limit) {
    if (
      specialisations &&
      specialisations.length > 0 &&
      city &&
      city.length > 0
    ) {
      return firebase
        .firestore()
        .collection('profiles_for_listing')
        .where('type', '==', 'doctor')
        .where('city', '==', city)
        .where(
          'specializations',
          'array-contains-any',
          this.specializationArray(specialisations)
        )
        .orderBy('createdOn', 'desc')
        .limitToLast(limit);
    } else if (city && city.length > 0) {
      return firebase
        .firestore()
        .collection('profiles_for_listing')
        .where('type', '==', 'doctor')
        .where('city', '==', city)
        .orderBy('createdOn', 'desc')
        .limitToLast(limit);
    } else if (specialisations && specialisations.length > 0) {
      return firebase
        .firestore()
        .collection('profiles_for_listing')
        .where('type', '==', 'doctor')
        .where(
          'specializations',
          'array-contains-any',
          this.specializationArray(specialisations)
        )
        .orderBy('createdOn', 'desc')
        .limitToLast(limit);
    } else {
      return firebase
        .firestore()
        .collection('profiles_for_listing')
        .where('type', '==', 'doctor')
        .orderBy('createdOn', 'desc')
        .limitToLast(limit);
    }
  }

  getProfileById(id) {
    return firebase
      .firestore()
      .collection('profiles_for_listing')
      .where('id', '==', id)
      .get();
  }

  getDocProfileById(id) {
    return firebase
      .firestore()
      .collection('profiles_for_listing')
      .doc(id)
      .get();
  }
  getProviderProfile(id: string) {
    if (this.providerProfileCache.has(id)) {
      this.fetchFreshProfile(id);
      return Promise.resolve(this.providerProfileCache.get(id));
    } else {
      return this.fetchFreshProfile(id);
    }
  }
  private fetchFreshProfile(id: string) {
    return new Promise<DoctorListingProfile>((resolve, reject) => {
      this.getProfileById(id)
        .then((snap) => {
          let providerProfile: DoctorListingProfile;
          if (!snap.empty && snap.size == 1) {
            providerProfile = plainToClass(
              DoctorListingProfile,
              snap.docs[0].data(),
              { excludeExtraneousValues: false }
            );
            this.providerProfileCache.set(providerProfile.id, providerProfile);
            resolve(providerProfile);
          } else {
            reject();
          }
        })
        .catch((err) => {
          console.error(err);
          reject();
        });
    });
  }

  getProfileByIdLive(id) {
    return firebase.firestore().collection('profiles_for_listing').doc(id);
  }

  getProfileWalletById(ids) {
    return firebase
      .firestore()
      .collection('wallet')
      .where('id', 'in', ids)
      .get();
  }

  getMultiProfileListing(ids) {
    return firebase
      .firestore()
      .collection('profiles_for_listing')
      .where('id', 'in', ids)
      .get();
  }

  getClaimById(id) {
    return firebase.firestore().collection('profile_claims').doc(id).get();
  }

  getHeroProfile(heroId) {
    return firebase
      .firestore()
      .collection('docvita_patient_profiles')
      .doc(heroId)
      .get();
  }

  getAllHeroProfile() {
    return firebase
      .firestore()
      .collection('docvita_patient_profiles')
      .where('healthHeroInfo.isEnrolled', '==', true)
      .get();
  }

  initializeNewWallet(docId) {
    return new Promise((resolve, reject) => {
      let json = {
        id: docId,
      };
      let url = env.environment['initialize-new-wallet'];
      this._http.post(url, json).subscribe(
        (res: Response) => {
          resolve(res);
        },
        (err) => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  updateWalletInfo(walletId, walletData) {
    if (walletId && walletData) {
      return new Promise((resolve, reject) => {
        firebase
          .firestore()
          .collection('wallet')
          .doc(walletId)
          .update(walletData)
          .then(() => {
            resolve({});
          })
          .catch((err) => {
            console.error(err);
            reject();
          });
      });
    }
  }

  getAllHeroProfileWithCRMAccess() {
    return firebase
      .firestore()
      .collection('docvita_patient_profiles')
      .where('healthHeroInfo.isEnrolled', '==', true)
      .where('healthHeroInfo.roles', 'array-contains', 'crm_access')
      .get();
  }

  updateBasicInformation(basicInfo, id, contactEmail?: any) {
    return new Promise((resolve, reject) => {
      let body = JSON.parse(JSON.stringify(basicInfo));
      firebase
        .firestore()
        .collection('profiles_for_listing')
        .doc(id)
        .set(body, { merge: true })
        .then(() => {
          if (contactEmail != null) {
            return firebase
              .firestore()
              .collection('profiles_for_listing')
              .doc(id)
              .update(contactEmail);
          }
        })
        .then(() => {
          return resolve(true);
        })
        .catch((err) => {
          return reject();
        });
    });
  }

  updateListedIn(
    listedInMap: Map<string, ListedIn>,
    oldSelection: Map<string, ListedIn>,
    id: string
  ) {
    console.log(listedInMap, oldSelection, id);
    let finalJSON: any = {};
    listedInMap.forEach((val) => {
      finalJSON['listedIn.' + val.urlPath] = JSON.parse(
        JSON.stringify({ ...classToPlain(val) })
      );
    });
    if (oldSelection) {
      oldSelection.forEach((val) => {
        if (!listedInMap.has(val.urlPath)) {
          finalJSON['listedIn.' + val.urlPath] =
            firebase.firestore.FieldValue.delete();
        }
      });
    }
    console.log(finalJSON);
    return firebase
      .firestore()
      .collection('profiles_for_listing')
      .doc(id)
      .update(finalJSON);
  }

  updateReasonOfVisit(
    reasonsOfVisitMap: Map<string, ReasonOfVisit>,
    oldSelection: Map<string, ReasonOfVisit>,
    id: string
  ) {
    let finalJSON: any = {};
    reasonsOfVisitMap.forEach((val) => {
      finalJSON['reasonsOfVisit.' + val.urlPath] = JSON.parse(
        JSON.stringify({ ...classToPlain(val) })
      );
    });
    if (oldSelection) {
      oldSelection.forEach((val) => {
        if (!reasonsOfVisitMap.has(val.urlPath)) {
          finalJSON['reasonsOfVisit.' + val.urlPath] =
            firebase.firestore.FieldValue.delete();
        }
      });
    }
    console.log(finalJSON);
    return firebase
      .firestore()
      .collection('profiles_for_listing')
      .doc(id)
      .update(finalJSON);
  }

  deleteEmails(doc_id, del_key) {
    return firebase
      .firestore()
      .collection('profiles_for_listing')
      .doc(doc_id)
      .set(
        { emails: { [del_key]: firebase.firestore.FieldValue.delete() } },
        { merge: true }
      );
  }

  deleteContactNumber(doc_id, country_code, del_key) {
    return firebase
      .firestore()
      .collection('profiles_for_listing')
      .doc(doc_id)
      .set(
        {
          contactNumbers: {
            [country_code]: {
              [del_key]: firebase.firestore.FieldValue.delete(),
            },
          },
        },
        { merge: true }
      );
  }

  assignProfile(heroId: string, doctorId: string[], assignedFor: string) {
    let body = {
      heroId,
      doctorId,
      assignedFor,
    };
    return this._http.post(urls.environment['assign-profiles'], body);
  }

  addBeneficiary(body: any) {
    return this._http.post(urls.environment['add-beneficiary'], body);
  }
  removeBeneficiary(beneId: string) {
    let body = {
      beneficiaryId: beneId,
    };
    console.log(body);
    return this._http.post(urls.environment['remove-beneficiary'], body);
  }

  updateScheduleInformation(schedule, id) {
    schedule = JSON.parse(JSON.stringify(schedule))
    return firebase
      .firestore()
      .collection('profiles_for_listing')
      .doc(id)
      .set({ schedule: schedule }, { merge: true });
  }

  updateEducationAndRegistration(education, registration, id) {
    let body = {
      education,
      registration,
      id,
    };
    return this._http.post(
      urls.environment['update-education-registration'],
      body
    );
  }

  updateDegreeToMasterList(data) {
    if (data) {
      data['id'] = firebase.database().ref().push().key;
      data['code'] = Math.floor(1000 + Math.random() * 9000);
      data['isApproved'] = false;
      // console.log(data);
      let fire_ref = firebase
        .firestore()
        .collection('provider_education_degree_master_list')
        .doc(data['id'])
        .set(data, { merge: true });
      // let index_ref = this._algoliaService.providerEducationDegreeMasterIndex().saveObject({ ...data, objectID: data['id'] })
      return Promise.all([fire_ref]);
    }
  }

  updateCollegeToMasterList(data) {
    if (data) {
      data['id'] = firebase.database().ref().push().key;
      data['code'] = Math.floor(1000 + Math.random() * 9000);
      data['isApproved'] = false;
      // console.log(data);
      let fire_ref = firebase
        .firestore()
        .collection('provider_college_master_list')
        .doc(data['id'])
        .set(data, { merge: true });
      // let index_ref = this._algoliaService.providerCollegeMasterIndex().saveObject({ ...data, objectID: data['id'] })
      return Promise.all([fire_ref]);
    }
  }

  approveDoctorProfile(doctorId) {
    let obj = {
      showInListing: true,
      showInListingApprovedOn: new Date().getTime(),
    };
    return firebase
      .firestore()
      .collection('profiles_for_listing')
      .doc(doctorId)
      .set(obj, { merge: true });
  }

  searchableDoctorProfile(doctorId: string, isSearchable: boolean) {
    let obj = {
      showInSearch: isSearchable,
      showInSearchApprovedOn: new Date().getTime(),
    };
    return firebase
      .firestore()
      .collection('profiles_for_listing')
      .doc(doctorId)
      .set(obj, { merge: true });
  }

  disapproveDoctorProfile(doctorId) {
    let obj = {
      showInListing: false,
      showInListingApprovedOn: new Date().getTime(),
    };
    return firebase
      .firestore()
      .collection('profiles_for_listing')
      .doc(doctorId)
      .set(obj, { merge: true });
  }

  removeMedicalRegistrationProofFile(doctorId: string, documentId: string) {
    let temp = {};
    temp['medicalRegistrationProofUrls.' + documentId] =
      firebase.firestore.FieldValue.delete();
    return firebase
      .firestore()
      .collection('profiles_for_listing')
      .doc(doctorId)
      .update(temp);
  }

  medicalRegistrationVerificationApproval(doctorId) {
    let obj = {
      isMedicalRegistrationVerified: true,
      medicalRegistrationVerifiedOn: new Date().getTime(),
    };
    return firebase
      .firestore()
      .collection('profiles_for_listing')
      .doc(doctorId)
      .set(obj, { merge: true });
  }

  verifyDoctorProfile(doctorId) {
    let obj = {
      isProfileVerifiedByDocVita: true,
      profileVerifiedOn: new Date().getTime(),
    };
    return firebase
      .firestore()
      .collection('profiles_for_listing')
      .doc(doctorId)
      .set(obj, { merge: true });
  }

  getPractice(practiceId) {
    return firebase
      .firestore()
      .collection('profiles_for_listing')
      .doc(practiceId)
      .get();
  }

  deletePractice(clinicId, memberId) {
    let body = {
      clinicId,
      memberId,
    };
    return this._http.post(urls.environment['unlink-clinic'], body);
  }

  updateClinicMinorDetails(clinicId, memberId, data) {
    let associatedPractices = {
      associatedPractices: {
        [clinicId]: { ...data, id: clinicId },
      },
    };

    let associatedDoctors = {
      associatedDoctors: {
        [memberId]: { ...data, id: memberId },
      },
    };

    return firebase
      .firestore()
      .collection('profiles_for_listing')
      .doc(memberId)
      .set(JSON.parse(JSON.stringify(associatedPractices)), { merge: true })
      .then(() => {
        return firebase
          .firestore()
          .collection('profiles_for_listing')
          .doc(clinicId)
          .set(JSON.parse(JSON.stringify(associatedDoctors)), { merge: true });
      });
  }

  editPractices(practiceId, data, connectionObj, connectedDoctors) {
    if (connectedDoctors.length == 0) {
      return firebase
        .firestore()
        .collection('profiles_for_listing')
        .doc(practiceId)
        .set(JSON.parse(JSON.stringify(data)), { merge: true });
    } else {
      return firebase
        .firestore()
        .collection('profiles_for_listing')
        .doc(practiceId)
        .set(JSON.parse(JSON.stringify(data)), { merge: true })
        .then(() => {
          let associatedPractices = {
            associatedPractices: {
              [practiceId]: { ...connectionObj, id: practiceId },
            },
          };
          const batch = firebase.firestore().batch();
          connectedDoctors.forEach((id: string) => {
            const ref = firebase
              .firestore()
              .collection('profiles_for_listing')
              .doc(id);

            batch.set(ref, JSON.parse(JSON.stringify(associatedPractices)), {
              merge: true,
            });
          });

          return batch.commit();
        });
    }
  }

  deleteDoctor(doctorId) {
    let body = {
      id: doctorId,
    };
    return this._http.post(urls.environment['provider-profile-deletion'], body).toPromise();
  }

  unlinkMultipleClinic(memberId, associatedPractices) {
    let body = {
      memberId,
      associatedPractices,
    };
    return this._http.post(urls.environment['unlink-mutliple-clinic'], body);
  }

  providerPayoutAs50ForFirstSession(providerId: string) {
    let body = {
      providerId,
    };
    return this._http.post(urls.environment['provider-payout-config-50'], body);
  }

  newPractices(data, connectionObj, memberData) {
    let clinicId = firebase.database().ref().push().key;
    data['id'] = clinicId;
    data['uniqueTag'] = clinicId;
    let clinicConnectionObj = {
      ...connectionObj,
      id: memberData['id'],
    };
    let memberConnectionObj = {
      associatedPractices: {
        [clinicId]: {
          ...connectionObj,
          id: clinicId,
        },
      },
    };
    clinicConnectionObj['name'] = memberData['name'];

    data['associatedDoctors'] = {
      [memberData['id']]: clinicConnectionObj,
    };

    return firebase
      .firestore()
      .collection('profiles_for_listing')
      .doc(clinicId)
      .set(JSON.parse(JSON.stringify(data)))
      .then(() => {
        return firebase
          .firestore()
          .collection('profiles_for_listing')
          .doc(memberData['id'])
          .set(JSON.parse(JSON.stringify(memberConnectionObj)), { merge: true })
          .then(() => {
            return clinicId;
          });
      });
  }

  newClinic(data, clinicId) {
    data['id'] = clinicId;
    data['uniqueTag'] = clinicId;

    return firebase
      .firestore()
      .collection('profiles_for_listing')
      .doc(clinicId)
      .set(JSON.parse(JSON.stringify(data)))
      .then(() => {
        return clinicId;
      });
  }

  async deleteProfileImage(id) {
    try {
      await firebase
        .firestore()
        .collection('profiles_for_listing')
        .doc(id)
        .update({
          photoUrl: firebase.firestore.FieldValue.delete(),
        });
      const deleteThumbnailUrl = env.environment.cloudFunctionsUrl + "/deleteProviderProfileResizedImage"
      const body = {
        providerId: id
      }
      await this._http.post(deleteThumbnailUrl, body).toPromise();
      return true;
    } catch (error) {
      return null;
    }
  }

  generateAbout(docId) {
    return this._http.post(urls.environment['generate-about'], { id: docId });
  }

  generateUniqueTag(docId: string, uniqueTag?: string) {
    let jsonVal: any = { id: docId };
    if (uniqueTag.length > 0 && docId != uniqueTag) {
      jsonVal['suggestedSlug'] = uniqueTag;
    }
    return this._http.post(urls.environment['generate-unique-tag'], jsonVal);
  }

  updateDocvitaUserStats(heroId, docId, reqType) {
    let body = {
      uid: heroId,
      docId: docId,
      reqType: reqType,
    };
    return this._http.post(urls.environment['docvita-user-stats'], body);
  }

  uploadClinicLogo(file, id, ext) {
    let body = {
      file,
      id,
      ext,
    };
    return this._http.post(urls.environment['upload-logo'], body);
  }

  uploadPhoto(file, id, ext) {
    let body = {
      file,
      id,
      ext,
    };
    if (this._authUserInfoService.getUserInfo()) {
      body['heroId'] = this._authUserInfoService.getUserInfo().docvitaId;
    }
    return this._http.post(urls.environment['upload-photo'], body);
  }

  async getAllClinicAndHospital() {
    const ref = firebase.firestore().collection('profiles_for_listing');
    const clinic = ref.where('type', '==', 'clinic').get();
    const hospital = ref.where('type', '==', 'hospital').get();
    const centre = ref.where('type', '==', 'centre').get();

    const [clinicQuerySnapshot, hospitalQuerySnapshot, centreQuerySnapshot] = await Promise.all([
      clinic,
      hospital,
      centre
    ]);

    const clinicArray = clinicQuerySnapshot.docs;
    const hospitalArray = hospitalQuerySnapshot.docs;
    const centreArray = centreQuerySnapshot.docs;

    return [].concat(clinicArray, hospitalArray, centreArray);
  }

  updateDoctorProfileInternTags(tag: string, doctorId: string) {
    return firebase
      .firestore()
      .collection('profiles_for_listing')
      .doc(doctorId)
      .update({
        internTags: firebase.firestore.FieldValue.arrayUnion(tag),
      });
  }

  removeDoctorProfileInternTags(tag: string, doctorId: string) {
    return firebase
      .firestore()
      .collection('profiles_for_listing')
      .doc(doctorId)
      .update({
        internTags: firebase.firestore.FieldValue.arrayRemove(tag),
      });
  }

  getProfileAnalytics(profileId: string) {
    return firebase
      .firestore()
      .collection('analytics')
      .where('profileId', '==', profileId)
      .orderBy('createdOn', 'desc')
      .get();
  }

  getPatientLead(id: string) {
    return new Promise<Lead>((resolve, reject) => {
      firebase
        .firestore()
        .collection('patient_leads')
        .doc(id)
        .get()
        .then((doc) => {
          return resolve(
            plainToClass(Lead, doc.data(), { excludeExtraneousValues: false })
          );
        })
        .catch((err) => {
          console.error(err);
          reject();
        });
    });
  }

  getPatientFollowUps(
    startDate?: string,
    endDate?: string,
    status?: string,
    searchQuery?: any,
    lastVisibleDoc?: any,
    listLimit?: number
  ) {
    let ref = firebase
      .firestore()
      .collection('patient_leads')
      .orderBy('consultationInfo.followupDate', 'desc');

    if (searchQuery && Object.keys(searchQuery).length > 0) {
      ref = ref.where(searchQuery['key'], '==', searchQuery['value']);
    }

    if (status != null && status.length > 0) {
      if (status == 'Booked') {
        ref = ref.where('status', '==', 'BOOKED');
      } else if (status == 'Call') {
        ref = ref.where('status', '==', 'CALL_NOW');
      } else if (status == 'Callback') {
        ref = ref.where('status', '==', 'CALLBACK_LATER');
      }
    }

    if (startDate != null && startDate.length > 0) {
      ref = ref.where('consultationInfo.followupDate', '>=', startDate);
    }
    if (endDate != null && endDate.length > 0) {
      ref = ref.where('consultationInfo.followupDate', '<=', endDate);
    }
    if (lastVisibleDoc) {
      ref = ref.startAfter(lastVisibleDoc);
    }
    if (listLimit) {
      ref = ref.limit(listLimit);
    }
    return ref;
  }

  getPatientLeads(
    startDate?: string,
    endDate?: string,
    status?: string,
    order?: string,
    searchQuery?: any,
    lastVisibleDoc?: any,
    listLimit?: number
  ) {
    let ref = firebase
      .firestore()
      .collection('patient_leads').orderBy("desc", "desc");
    // console.log('startDate: '+startDate,'endDate:'+endDate)
    if (
      order != null &&
      (order == 'bookingDate' || order == 'queue') &&
      startDate != null &&
      endDate != null
    ) {
      ref = firebase
        .firestore()
        .collection('patient_leads')
        .orderBy('consultationInfo.date', 'desc');
    } else {
      ref = firebase
        .firestore()
        .collection('patient_leads')
        .orderBy('createdOn', 'desc');
    }
    // if(appointmentId&&appointmentId.length>0){
    //   return ref.where('consultationInfo.appointmentId','==',appointmentId);
    // }
    if (searchQuery && Object.keys(searchQuery).length > 0) {
      ref = ref.where(searchQuery['key'], '==', searchQuery['value']);
    }

    if (status != null && status.length > 0) {
      if (status == 'Booked') {
        ref = ref.where('status', '==', 'BOOKED');
      } else if (status == 'Call') {
        ref = ref.where('status', '==', 'CALL_NOW');
      } else if (status == 'Callback') {
        ref = ref.where('status', '==', 'CALLBACK_LATER');
      }
    }
    // if(startDate!=null&&endDate!=null&&startDate==endDate){
    //   if(order!=null&&order=='bookingDate'){
    //     return ref.where('consultationInfo.date',"==",startDate)
    //   }
    // }
    if (startDate != null && startDate.length > 0) {
      if (order != null && (order == 'bookingDate' || order == 'queue')) {
        ref = ref.where('consultationInfo.date', '>=', startDate);
      } else {
        let d = new Date(startDate);
        d.setHours(0, 0, 0, 0);
        const val = d.getTime();
        const val2 = new DayjsHelper("Asia/Kolkata").YYYYMMDDHHmmssToUnix(startDate, "00:00:00");
        const currentTimezoneDiff = d.getTimezoneOffset() * 60 * 1000; //minus
        const istDiff = 330 * 1000 * 60; //ist diff
        ref = ref.where('createdOn', '>=', val2 - currentTimezoneDiff - istDiff);
        // console.log("st", startDate,val,d, val2,val2-currentTimezoneDiff-istDiff)
      }
    }
    if (endDate != null && endDate.length > 0) {
      if (order != null && (order == 'bookingDate' || order == 'queue')) {
        ref = ref.where('consultationInfo.date', '<=', endDate);
      } else {
        let d = new Date(endDate);
        d.setHours(23, 59, 59, 99);
        const val = d.getTime();
        const currentTimezoneDiff = d.getTimezoneOffset() * 60 * 1000; //minus
        const istDiff = 330 * 1000 * 60; //ist diff
        const val2 = new DayjsHelper("Asia/Kolkata").YYYYMMDDHHmmssToUnix(endDate, "23:59:59");
        ref = ref.where('createdOn', '<=', val2 - currentTimezoneDiff - istDiff);
        // console.log("ed",endDate,val, d, val2, val2-currentTimezoneDiff-istDiff)
      }
    }
    if (lastVisibleDoc) {
      ref = ref.startAfter(lastVisibleDoc);
    }
    if (listLimit) {
      ref = ref.limit(listLimit);
    }
    return ref;
  }

  getAllBookedLeads(docId?: string, limit?: number) {
    let ref = firebase
      .firestore()
      .collection('patient_leads')
      .orderBy('createdOn', 'desc')
      .where('status', '==', 'BOOKED');
    if (docId && docId.length > 0) {
      console.log('doc id', docId);
      ref = ref.where('consultationInfo.doctorId', '==', docId);
    }
    if (limit) {
      console.log('limit', limit);
      ref = ref.limit(limit);
    }
    return ref;
  }

  fetchLeadsForProvider(docId: string, fromDate?: string, toDate?: string) {
    let ref = firebase
      .firestore()
      .collection('patient_leads')
      .orderBy('consultationInfo.date', 'desc')
      .where('status', '==', 'BOOKED');
    if (docId && docId.length > 0) {
      ref = ref.where('consultationInfo.doctorId', '==', docId);
    }
    if (fromDate && fromDate.length > 0) {
      ref = ref.where('consultationInfo.date', '<=', fromDate);
    }
    if (toDate && toDate.length > 0) {
      ref = ref.where('consultationInfo.date', '>=', toDate);
    }
    return ref;
  }

  getPaymentTransactions(doctorId: string) {
    return firebase
      .firestore()
      .collection('collect_payment_transactions')
      .where('providerAccountInfo.id', '==', doctorId)
      .orderBy('createdOn', 'desc')
      .limit(40);
  }

  getAllPaymentTransactions(
    name: string,
    fromDate: string,
    toDate: string,
    limit?: number
  ) {
    let ref;
    if (name && name.length > 0) {
      ref = firebase
        .firestore()
        .collection('collect_payment_transactions')
        .where('createdByName', '==', name)
        .orderBy('createdOn', 'desc');
    } else {
      ref = firebase
        .firestore()
        .collection('collect_payment_transactions')
        .orderBy('createdOn', 'desc');
    }

    if (fromDate?.length > 0) {
      ref = ref.where(
        'createdOn',
        '>=',
        new Date(fromDate + 'T00:01').getTime()
      );
    }
    if (toDate?.length > 0) {
      ref = ref.where('createdOn', '<=', new Date(toDate + 'T23:59').getTime());
    }

    if (limit) {
      ref = ref.limit(limit);
    } else {
      ref = ref.limit(40);
    }

    return ref;
  }

  createPayLink(
    paymentConfig: PaymentTransaction,
    currentHeroInfo: CRMUser,
    purposeTags: string[],
    createdOn?: number
  ) {
    let body = {
      amount: paymentConfig.amount,
      description: paymentConfig.description,
      purposeTags: purposeTags,
      createdById: currentHeroInfo.docvitaId,
      createdByName: currentHeroInfo.name,
      createdOn:
        createdOn != null && createdOn > 0 ? createdOn : new Date().getTime(),
      providerAccountInfoId: paymentConfig.providerAccountInfo.id,
      customerInfo: paymentConfig.customerInfo,
    };
    return this._http.post(urls.environment['create-pay-link'], body);
  }

  getAppointments(docId: string, begin?: string, end?: string) {
    if (docId) {
      let lastMonth = new Date();
      lastMonth.setMonth(lastMonth.getMonth() - 1);
      let _lastMonth = lastMonth.toISOString().split('T')[0];
      let today = new Date().toISOString().split('T')[0];
      if (begin && end) {
        today = end;
        _lastMonth = begin;
      }
      if (today == _lastMonth) {
        return firebase
          .firestore()
          .collection('organisation_data')
          .doc(docId)
          .collection('organisation_appointments')
          .where(firebase.firestore.FieldPath.documentId(), '==', today)
          .get();
      } else {
        return firebase
          .firestore()
          .collection('organisation_data')
          .doc(docId)
          .collection('organisation_appointments')
          .where(firebase.firestore.FieldPath.documentId(), '<=', today)
          .where(firebase.firestore.FieldPath.documentId(), '>=', _lastMonth)
          .get();
      }
    }
  }

  getAppointmentByDate(docId: string, date) {
    return firebase
      .firestore()
      .collection('organisation_data')
      .doc(docId)
      .collection('organisation_appointments')
      .where(firebase.firestore.FieldPath.documentId(), '==', date)
      .get();
  }

  updateBlockedSlot(docId, slotId, slot) {
    return new Promise((resolve, reject) => {
      let _ = {
        blockedSlots: {
          [slotId]: slot,
        },
      };
      firebase
        .firestore()
        .collection('profiles_for_listing')
        .doc(docId)
        .set(_, { merge: true })
        .then(() => {
          resolve(true);
        })
        .catch((err) => {
          console.log(err);
          resolve(false);
        });
    });
  }

  deleteBlockedSlot(docId, slotId) {
    let _ = {};
    _[`blockedSlots.${slotId}`] = firebase.firestore.FieldValue.delete();
    return firebase
      .firestore()
      .collection('profiles_for_listing')
      .doc(docId)
      .update(_);
  }

  updateOpenSlot(docId, slotId, slot) {
    return new Promise((resolve, reject) => {
      let _ = {
        openSlots: {
          [slotId]: slot,
        },
      };
      firebase
        .firestore()
        .collection('profiles_for_listing')
        .doc(docId)
        .set(_, { merge: true })
        .then(() => {
          resolve(true);
        })
        .catch((err) => {
          console.log(err);
          resolve(false);
        });
    });
  }

  deleteOpenSlot(docId, slotId) {
    let _ = {};
    _[`openSlots.${slotId}`] = firebase.firestore.FieldValue.delete();
    return firebase
      .firestore()
      .collection('profiles_for_listing')
      .doc(docId)
      .update(_);
  }

  getAccessToken(code: string, state: string) {
    return new Promise((resolve, reject) => {
      // let url = `http://localhost:3005/v1/zoom-service/get-zoom-auth-token?code=${code}&state=${state}`;
      let url = `${urls.environment['get-zoom-access-token']}?code=${code}&state=${state}`;
      this._http.post(url, {}).subscribe(
        (res: Response) => {
          resolve(res);
        },
        (err) => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  createGoogleMeeting(
    doctorId,
    doctorName,
    doctorEmail,
    date,
    startTime,
    endTime,
    summary,
    description
  ) {
    let body = {
      doctorId,
      doctorName,
      doctorEmail,
      date,
      startTime,
      endTime,
      summary,
      description,
    };
    return new Promise((resolve, reject) => {
      let url = urls.environment['create-google-meeting'];
      // let url = "http://localhost:3005/v1/calendar-meeting-service/create-google-meeting";
      this._http.post(url, body).subscribe(
        (res: Response) => {
          resolve(res);
        },
        (err) => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  updateMeetingInfo(
    eventId,
    calendarId,
    createMeetLink,
    description,
    location,
    providerId?
  ) {
    let body = {
      eventId,
      createMeetLink,
      description,
      location,
    };
    if (calendarId) {
      body['calendarId'] = calendarId;
    }
    if (providerId) {
      body['providerId'] = providerId;
    }
    return new Promise((resolve, reject) => {
      let url = urls.environment['update-meeting-info'];
      // let url = "http://localhost:3005/v1/calendar-meeting-service/update-meeting";
      this._http.post(url, body).subscribe(
        (res: Response) => {
          resolve(res);
        },
        (err) => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  updateDoctorProfilePayoutDetails(
    bulkPayoutsOnly: boolean,
    payoutScheduleComments: string,
    docId: string
  ) {
    return new Promise((resolve, reject) => {
      let json = {
        bulkPayoutsOnly: bulkPayoutsOnly,
        payoutScheduleComments: payoutScheduleComments
          ? payoutScheduleComments
          : '',
      };
      firebase
        .firestore()
        .collection('profiles_for_listing')
        .doc(docId)
        .set(json, { merge: true })
        .then(() => {
          resolve(true);
        })
        .catch((err) => {
          resolve(false);
        });
    });
  }

  async feeConfigUpdate(providerId: string, servicewiseFeeMap: {}, queerAffirmative: boolean, listInGSWT?: boolean) {
    try {
      if (!providerId) {
        throw Error('Incomplete form details')
      }
      let finalJSON = {};
      finalJSON["onlineConsultationConfig"] = {};
      if (listInGSWT) finalJSON["listInGSWT"] = listInGSWT;
      finalJSON["onlineConsultationConfig"]["servicewiseFeesMap"] = servicewiseFeeMap ? servicewiseFeeMap : {};

      if (servicewiseFeeMap && servicewiseFeeMap['individual'] && servicewiseFeeMap['individual']['fee'] && servicewiseFeeMap['individual']['isActive']) {
        finalJSON["onlineConsultationConfig"]['fee'] = +servicewiseFeeMap['individual']['fee']
      }

      if (queerAffirmative != null) {
        finalJSON["otherInfo"] = {}
        finalJSON["otherInfo"]["queerAffirmative"] = queerAffirmative;
      }

      await firebase.firestore().collection('profiles_for_listing').doc(providerId).set(finalJSON, { merge: true })
      return true;
    } catch (error) {
      throw error;
    }
  }
}
