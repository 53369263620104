import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { classToPlain, plainToClass } from 'class-transformer';
//models
import { DoctorListingProfile } from '../../shared/models-ts/Actors/Doctor/DoctorListingProfile';
import { ContactNumber } from '../../shared/models-ts/Actors/Person/ContactNumber';
import { OnlineConsultationSettings } from '../../shared/models-ts/Entities/OnlineConsultationSettings';
import { ProfileLinks } from '../../shared/models-ts/Entities/ProfileLinks';
//services
import { DoctorsService } from 'src/app/shared/services/doctors/doctors.service';
import { DoctorDataService } from 'src/app/shared/services/doctor-data/doctor-data.service';
import { AuthUserInfoService } from 'src/app/shared/services/auth-user-info/auth-user-info.service';
import {
  city,
  languages,
  specializations,
} from 'src/app/shared/constants/constants';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DoctorPracticeRelationship } from 'src/app/shared/models-ts/Relationships/DoctorPracticeRelationship';
import { environment } from 'src/environments/environment';
import { Email } from 'src/app/shared/models-ts/Actors/Person/Email';
import { PhoneEmailComponent } from '../form-control/phone-email/phone-email.component';
import { EmailsComponent } from '../form-control/emails/emails.component';
import { ContactNumbersComponent } from '../form-control/contact-numbers/contact-numbers.component';
import { listedInSuggestions } from 'src/app/shared/models-ts/constants/listedInSuggestions';
import { reasonOfVisitSuggestions } from 'src/app/shared/models-ts/constants/reasonOfVisitSuggestions';
import { ListedIn } from 'src/app/shared/models-ts/Entities/ListedIn';
import { ReasonOfVisit } from 'src/app/shared/models-ts/Entities/ReasonOfVisit';
import { CopyClipboardService } from '../../shared/services/copy-clipboard/copy-clipboard.service';
import { OnlineConsultationPlatform, WACallInitiatorEnum } from 'src/app/shared/models-ts/Enums/Enums';
import { ConsultationPlatformConfig } from 'src/app/shared/models-ts/Entities/ConsultationPlatformConfig';

declare var $: any;

@Component({
  selector: 'app-doc-profile-basic-info',
  templateUrl: './doc-profile-basic-info.component.html',
  styleUrls: ['./doc-profile-basic-info.component.css'],
})
export class DocProfileBasicInfoComponent implements OnInit, OnDestroy {
  dataLayerSubscription: Subscription;
  city: any[] = city;
  languages: any[] = languages;
  specializations: any[] = [
    { id: 'psychiatrist', name: 'Psychiatrist' },
    { id: 'psychologist', name: 'Psychologist' },
    { id: 'clinical-psychologist', name: 'Clinical Psychologist' },
    { id: 'counselling-psychologist', name: 'Counselling Psychologist' },
    { id: 'life-coach', name: 'Life Coach' },
  ];
  associatedPractices: any = [];
  docId: string;
  profileData: DoctorListingProfile = new DoctorListingProfile();
  whatsappContactInfo: ContactNumber = new ContactNumber();
  primaryContactInfo: ContactNumber = new ContactNumber();
  publicContactInfo: ContactNumber = new ContactNumber();

  profileLinks: ProfileLinks = new ProfileLinks();
  onlineConsultationConfig: OnlineConsultationSettings =
    new OnlineConsultationSettings();
  photoUrl: any;
  extension: any;
  logoUploaded = false;
  currentHeroInfo: any;
  docvitaProfileLink: string = '-';
  uniqueTagInputStr: string = '';
  contactsValid: boolean = true;
  emailsValid: boolean = true;
  mobileAppLastAccess: string;
  selectionListedInArray: string[] = [];
  selectionListedInMap: Map<string, ListedIn> = new Map<string, ListedIn>();
  listedInSuggestionArray: any[] = [];
  selectionReasonsOfVisitArray: string[] = [];
  selectionReasonsOfVisitMap: Map<string, ReasonOfVisit> = new Map<
    string,
    ReasonOfVisit
  >();
  reasonOfVisitSuggestionArray: any[] = [];
  offersOnlineConsultationOnPlatform: any = {};
  onlineConsultationOnPlatformOptions: OnlineConsultationPlatform[] = [
    OnlineConsultationPlatform.whatsapp,
    OnlineConsultationPlatform.zoom,
    OnlineConsultationPlatform.googlemeet,
    OnlineConsultationPlatform.phone,
  ];
  onlineConsultationPlatform = OnlineConsultationPlatform;
  waContactNumber: ContactNumber = new ContactNumber();
  onlineConsultationPlatformConfig: any = {};
  dynMessage="Checking this will ensure dynamic meeting link generation.";
  staticLinkMessage="This meeting link will be shared when an appointment is booked with this provider.";
  constructor(
    private _doctorDataService: DoctorDataService,
    private _doctorService: DoctorsService,
    private _toastr: ToastrService,
    private _authUserInfoService: AuthUserInfoService,
    private _router: Router,
    private _copyToClipboardService: CopyClipboardService
  ) { 
  }

  ngOnInit(): void {
    this.listedInSuggestionArray = listedInSuggestions;
    this.reasonOfVisitSuggestionArray = reasonOfVisitSuggestions;
    this.currentHeroInfo = this._authUserInfoService.getUserInfo();

    this.dataLayerSubscription =
      this._doctorDataService.currentSelection.subscribe((data) => {
        this.associatedPractices = [];
        this.profileData = new DoctorListingProfile();
        this.whatsappContactInfo = new ContactNumber();
        this.primaryContactInfo = new ContactNumber();
        this.publicContactInfo = new ContactNumber();

        this.onlineConsultationConfig = new OnlineConsultationSettings();
        this.logoUploaded = false;
        if (data != null && data['memberSelected'] != null) {
          this.profileData = data['memberSelected'];
          console.log(this.profileData);
          if (this.profileData.gender) {
            this.profileData.gender =
              this.profileData.gender.toLocaleLowerCase();
          }
          this.docId = this.profileData.id;
          let city = 'india';
          if (this.profileData.city) {
            city = this.profileData.getCityForUrl();
          }
          this.docvitaProfileLink =
            environment.iframeListingBaseUrl +
            '/' +
            city +
            '/' +
            this.profileData.type +
            '/' +
            this.profileData.uniqueTag;
          this.photoUrl = this.profileData.photoUrl
            ? this.profileData.photoUrl + `?cache=${new Date()}`
            : '';
          // if (this.profileData.whatsappContactNumber) {
          //   this.whatsappContactInfo = this.profileData.whatsappContactNumber;
          // }
          // if (this.profileData.primaryContactNumber) {
          //   this.primaryContactInfo = this.profileData.primaryContactNumber;
          // }
          // if (this.profileData.publicContactNumber) {
          //   this.publicContactInfo = this.profileData.publicContactNumber;
          // }
          // if (this.profileData.showInListing) {
          //   this.isApproved = this.profileData.showInListing;
          // } else {
          //   this.isApproved = false;
          // }

          // if (this.profileData.whatsappContactNumber && this.profileData.whatsappContactNumber.number.length>1) {
          //   this.whatsappContactInfo = this.profileData.whatsappContactNumber;
          //   this.contactNumbers = [...this.contactNumbers,this.whatsappContactInfo]
          // }
          // if (this.profileData.primaryContactNumber && this.profileData.primaryContactNumber.number.length>1) {
          //   this.primaryContactInfo = this.profileData.primaryContactNumber;
          //   this.contactNumbers = [...this.contactNumbers,this.primaryContactInfo]
          // }
          // if (this.profileData.publicContactNumber && this.profileData.publicContactNumber.number.length>1) {
          //   this.publicContactInfo = this.profileData.publicContactNumber;
          //   this.contactNumbers = [...this.contactNumbers,this.publicContactInfo]
          // }
          // console.log(this.profileData.contactNumbers)
          if (this.profileData.onlineConsultationConfig) {
            this.onlineConsultationConfig =
              this.profileData.onlineConsultationConfig;
          }
          if (this.profileData.profileLinks) {
            this.profileLinks = this.profileData.profileLinks;
          }
          this.uniqueTagInputStr = this.profileData.uniqueTag;
          if (this.profileData.associatedPractices) {
            for (let i of this.profileData.associatedPractices) {
              this.associatedPractices.push({ key: i[0], value: i[1]['name'] });
            }
          }
          this.initializeOnlineConsultationPlatformsConfigMap();
          this.initializeOnlineConsultationPlatformsMap();
          
          if (this.profileData?.onlineConsultationConfig?.onlineConsultationPlatforms) {
            for (let p of this.profileData.onlineConsultationConfig.onlineConsultationPlatforms) {
              this.offersOnlineConsultationOnPlatform[p] = true;
              let _ = this.profileData.onlineConsultationConfig.onlineConsultationPlatformConfig;
              if(p == OnlineConsultationPlatform.whatsapp) {
                if(_.get(p).waContactNumber) this.waContactNumber = _.get(p).waContactNumber
              }
              if(_&&_.size>0&&_.get(p)){
                this.onlineConsultationPlatformConfig[p] = classToPlain(_.get(p))
              }
            }
          }
         

          if (this.profileData.listedIn) {
            this.profileData.listedIn.forEach((val, key) => {
              this.selectionListedInArray.push(val.urlPath);
              this.selectionListedInMap.set(val.urlPath, val);
            });
            this.selectionListedInArray.sort((a, b) => {
              return a.localeCompare(b);
            });
          }
          this.selectionReasonsOfVisitArray = [];
          if (this.profileData.reasonsOfVisit) {
            this.profileData.reasonsOfVisit.forEach((val, key) => {
              this.selectionReasonsOfVisitArray.push(val.urlPath);
              this.selectionReasonsOfVisitMap.set(val.urlPath, val);
            });
            this.selectionReasonsOfVisitArray.sort((a, b) => {
              return a.localeCompare(b);
            });
          }
        }
      });
    this._doctorService
      .getProfileAnalytics(this.profileData.id)
      .then((snap) => {
        if (!snap.empty) {
          let data = snap.docs[0].data();

          this.mobileAppLastAccess = data['createdOn'];
        } else {
          this.mobileAppLastAccess = null;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  customTrackBy(index: number, obj: any): any {
    return index;
  }

  @ViewChild(EmailsComponent) private emailC: EmailsComponent;
  @ViewChild(ContactNumbersComponent) private contactC: ContactNumbersComponent;

  copyDocVitaProfileLink() {
    if (this.docvitaProfileLink != '-') {
      this._copyToClipboardService.copyToClipoard(this.docvitaProfileLink);
    } else {
      this._toastr.error('Error in finding profile link!');
    }
  }

  initializeOnlineConsultationPlatformsMap() {
    this.offersOnlineConsultationOnPlatform = {};
    this.offersOnlineConsultationOnPlatform[OnlineConsultationPlatform.zoom] = false;
    this.offersOnlineConsultationOnPlatform[OnlineConsultationPlatform.whatsapp] = false;
    this.offersOnlineConsultationOnPlatform[OnlineConsultationPlatform.googlemeet] = false;
    this.offersOnlineConsultationOnPlatform[OnlineConsultationPlatform.phone] = false;
  }

  initializeOnlineConsultationPlatformsConfigMap() {
    this.onlineConsultationPlatformConfig = {};
    this.onlineConsultationPlatformConfig[OnlineConsultationPlatform.zoom] = { isPrimary:false,isDynamic:false,url:"",meetingId:"",password:""};
    this.onlineConsultationPlatformConfig[OnlineConsultationPlatform.whatsapp] = { isPrimary:false, waContactNumber: {countryCode: "", number: ""}};
    this.onlineConsultationPlatformConfig[OnlineConsultationPlatform.googlemeet] = { isPrimary:false,isDynamic:false,url:""};
    this.onlineConsultationPlatformConfig[OnlineConsultationPlatform.phone] = { isPrimary:false};
  }

  updateEmails(event) {
    if (event['valid'] == true) {
      this.emailsValid = true;
      if (event['emails'] != null) {
        this.profileData.emails = event['emails'];
      }
    } else {
      this.emailsValid = false;
    }
  }

  getPlatformTitle(val: OnlineConsultationPlatform) {
    switch (val) {
      case OnlineConsultationPlatform.googlemeet:
        return "Google Meet";
      case OnlineConsultationPlatform.zoom:
        return "Zoom";
      case OnlineConsultationPlatform.whatsapp:
        return "Whatsapp";
      case OnlineConsultationPlatform.phone:
        return "Phone";
      default:
        return "";
    }
  }

  updateContactNumbers(event) {
    // console.log('update event contacts: ',event)
    if (event['valid'] == true) {
      console.log('update event contacts: ', event['contactNumbers']);
      this.contactsValid = true;
      if (event['contactNumbers'] != null) {
        this.profileData.contactNumbers = event['contactNumbers'];
      }
    } else {
      this.contactsValid = false;
    }
  }

  updateBasicInformation() {
    this.contactC.getContactMap();
    this.emailC.getEmailMap();
    // if (this.primaryContactInfo && this.primaryContactInfo.number) {
    //   this.primaryContactInfo.number = this.primaryContactInfo.number + '';
    // }
    // if (this.publicContactInfo && this.publicContactInfo.number) {
    //   this.publicContactInfo.number = this.publicContactInfo.number + '';
    // }
    // if (this.whatsappContactInfo && this.whatsappContactInfo.number) {
    //   this.whatsappContactInfo.number = this.whatsappContactInfo.number + '';
    // }
    if (
      this.profileData['name'] &&
      this.profileData['name'].length > 0 &&
      this.profileData['city'] &&
      this.emailsValid &&
      this.contactsValid
    ) {
      this.profileData['city'] = this.profileData['city']['label']
        ? this.profileData['city']['label']
        : this.profileData['city'];
      if (this.profileData['specializations']) {
        for (let i = 0; i < this.profileData['specializations'].length; i++) {
          this.profileData['specializations'][i] = this.profileData[
            'specializations'
          ][i]['label']
            ? this.profileData['specializations'][i]['label']
            : this.profileData['specializations'][i];
        }
      }
      if (this.profileData['languages']) {
        for (let i = 0; i < this.profileData['languages'].length; i++) {
          this.profileData['languages'][i] = this.profileData['languages'][i][
            'label'
          ]
            ? this.profileData['languages'][i]['label']
            : this.profileData['languages'][i];
        }
      }
      // if (this.profileData['primaryContactNumber']) {
      //   this.profileData['primaryContactNumber'] = this.primaryContactInfo;
      // }

      // this.profileData['profileLinks'] = this.profileLinks;
      // if (this.profileData['publicContactNumber']) {
      //   this.profileData['publicContactNumber'] = this.publicContactInfo;
      // }

      // if (
      //   this.whatsappContactInfo &&
      //   this.whatsappContactInfo.number &&
      //   this.whatsappContactInfo.number.length == 10
      // ) {
      //   this.profileData['whatsappContactNumber'] = this.whatsappContactInfo;
      // }

      // if (this.onlineConsultationConfig.fee)
      //   this.profileData['onlineConsultationConfig'] =
      //     this.onlineConsultationConfig;
      this._toastr.info('Saving...');
      let finalJSON = {};
      let contactEmailJSON={}
      if(this.profileData.salutation) finalJSON['salutation'] = this.profileData.salutation;
      finalJSON['name'] = this.profileData.name;
      finalJSON['pronoun'] = this.profileData.pronoun;
      if (this.profileData.type) {
        finalJSON['type'] = this.profileData.type;
      }
      finalJSON['gender'] = this.profileData.gender;
      finalJSON['city'] = this.profileData.city['label']
        ? this.profileData.city['label']
        : this.profileData.city;
      if (this.profileData.experienceInYears) {
        finalJSON['experienceInYears'] =
          this.profileData.experienceInYears + '';
      }

      finalJSON['about'] = this.profileData.about;
      if (this.profileData.emails) {
        contactEmailJSON['emails'] = JSON.parse(JSON.stringify(this.profileData.emails));
      }

      if (this.profileData.contactNumbers) {
        contactEmailJSON['contactNumbers'] = JSON.parse(JSON.stringify(classToPlain(this.profileData.contactNumbers)));
      }

      if (this.profileData.languages)
        finalJSON['languages'] = this.profileData.languages;
      if (this.profileData.listedInCities)
        finalJSON['listedInCities'] = this.profileData.listedInCities;
      if (this.profileData.specializations)
        finalJSON['specializations'] = this.profileData.specializations;
      finalJSON['profileLinks'] = this.profileData.profileLinks;
      let temp = {};
      for (let a in finalJSON['profileLinks']) {
        if (finalJSON['profileLinks'][a])
          temp[a] = finalJSON['profileLinks'][a];
      }

      finalJSON['profileLinks'] = temp;
      if (this.onlineConsultationConfig.fee) {
        finalJSON['onlineConsultationConfig'] = {};
        finalJSON['onlineConsultationConfig']['fee'] =
          this.onlineConsultationConfig.fee;
        if (this.onlineConsultationConfig.appointmentDuration) {
          finalJSON['onlineConsultationConfig']['appointmentDuration'] =
            this.onlineConsultationConfig.appointmentDuration;
        }
        if (this.onlineConsultationConfig.acceptAppointmentDiff) {
          finalJSON['onlineConsultationConfig']['acceptAppointmentDiff'] =
            this.onlineConsultationConfig.acceptAppointmentDiff;
        }
        finalJSON['onlineConsultationConfig']['availableOnWhatsappVideo'] =
          this.onlineConsultationConfig.availableOnWhatsappVideo;
        finalJSON['onlineConsultationConfig']['autoConfirmBookings'] =
          this.onlineConsultationConfig.autoConfirmBookings;
        if (this.onlineConsultationConfig.includeInPersonVisitAt)
          finalJSON['onlineConsultationConfig']['includeInPersonVisitAt'] =
            this.onlineConsultationConfig.includeInPersonVisitAt;
        if (this.offersOnlineConsultationOnPlatform) {
          finalJSON['onlineConsultationConfig']['onlineConsultationPlatforms'] = [];
          finalJSON['onlineConsultationConfig']['onlineConsultationPlatformConfig'] = {}
          // console.log(this.offersOnlineConsultationOnPlatform, this.onlineConsultationConfig);
          for (let k in this.offersOnlineConsultationOnPlatform) {
            if (this.offersOnlineConsultationOnPlatform[k]) {
              finalJSON['onlineConsultationConfig']['onlineConsultationPlatforms'].push(k)
              if(k == OnlineConsultationPlatform.whatsapp 
                && this.onlineConsultationPlatformConfig[k]
                && this.waContactNumber.countryCode 
                && this.waContactNumber.countryCode.length > 0
                && this.waContactNumber.number
                && this.waContactNumber.number.length > 0
              ) {
                this.onlineConsultationPlatformConfig[k]["waContactNumber"] = classToPlain(this.waContactNumber);
              }
              finalJSON['onlineConsultationConfig']['onlineConsultationPlatformConfig'][k] = this.onlineConsultationPlatformConfig[k]
            }
            if(this.offersOnlineConsultationOnPlatform[k] == false){
              finalJSON['onlineConsultationConfig']['onlineConsultationPlatformConfig'][k] = {};
            }
            
          }
        }
      }else{
        this._toastr.error("Consultation fee is missing..")
        return;
      }
      
      this._doctorService
        .updateBasicInformation(finalJSON, this.docId,contactEmailJSON)
        .then((res) => {
          this._toastr.success('Yay! Your information is saved & secured!');
        })
        .catch((err) => console.log(err));
    } else {
      this._toastr.warning('Some information is missing');
    }
  }

  // autoFillAbout() {
  //   if (
  //     this.profileData.salutation &&
  //     this.profileData.name &&
  //     this.profileData.experienceInYears
  //   ) {
  //     let about =
  //       this.profileData.salutation +
  //       ' ' +
  //       this.profileData.name +
  //       ' is a highly qualified and experienced doctor with an overall experience of ' +
  //       this.profileData.experienceInYears +
  //       ' years.';

  //     this.profileData.about = about;
  //   } else {
  //     this._toastr.error(
  //       'Salutation, Name and Exp. in years required, for auto to work.'
  //     );
  //   }
  // }

  autoFillAbout() {
    this._doctorService.generateAbout(this.profileData.id).subscribe(
      (res) => {
        if (res['statusCode'] == 200 && res['body']['success'] == true) {
          this._toastr.success('Generated about!');
        } else {
          this._toastr.error('Failed to generate about!');
        }
      },
      (err) => {
        this._toastr.error('Failed to generate about!');
      }
    );
  }

  openGooglePage() {
    if (this.profileData.name) {
      let _url = `https://www.google.com/search?q=${this.profileData.salutation
        }+${this.profileData.name
          .split(' ')
          .join('+')}+${this.profileData.specializations[0]
            .split(' ')
            .join('+')}+${this.profileData.city.split(' ').join('+')}`;
      window.open(_url, '_blank');
    }
  }

  openUrl(url) {
    if (url) {
      window.open(url, '_blank');
    }
  }

  uploadPhoto(event) {
    this.logoUploaded = true;
    this.photoUrl = event.photoUrl;
    this.extension = event.extension;
    this._doctorService
      .uploadPhoto(this.photoUrl, this.profileData.id, this.extension)
      .subscribe((data) => {
        this.updateUserStats(
          this.currentHeroInfo.docvitaId,
          this.docId,
          'photo'
        );
        this._toastr.success('Photo updated successfully');
      });
  }

  addCustomListedInCity = (term) => term;

  // approveDoctor() {
  //   this._doctorService
  //     .approveDoctorProfile(this.docId)
  //     .then((res) => {
  //       this.updateUserStats(
  //         this.currentHeroInfo.docvitaId,
  //         this.docId,
  //         'approve'
  //       );
  //       this._toastr.success('Profile Approved!');
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       this._toastr.error('Failed to approve. Try again!');
  //     });
  // }

  // verifyDoctor() {
  //   this._doctorService
  //     .verifyDoctorProfile(this.docId)
  //     .then((res) => {
  //       this.updateUserStats(
  //         this.currentHeroInfo.docvitaId,
  //         this.docId,
  //         'verify'
  //       );
  //       this._toastr.success('Profile Verified!');
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       this._toastr.error('Failed to verify. Try again!');
  //     });
  // }

  fileProgress(fileInput: any) {
    let self = this;
    let imageData;
    imageData = <File>fileInput.target.files[0];
    // this.extension = self.photoUrl.match(/\.[0-9a-z]+$/i);
    var mimeType = imageData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var myCanvas = document.createElement('canvas');
    var ctx = myCanvas.getContext('2d');
    var img = new Image();
    img.onload = function () {
      myCanvas.width = img.width;
      myCanvas.height = img.height;

      ctx.drawImage(img, 0, 0);

      self.photoUrl = myCanvas.toDataURL('image/jpeg');
      self.extension =
        '.' +
        self.photoUrl.substring(
          self.photoUrl.indexOf('/') + 1,
          self.photoUrl.indexOf(';base64')
        );
      self.uploadPhoto('1');
      self.logoUploaded = true;
    };

    img.src = URL.createObjectURL(fileInput.target.files[0]);
    fileInput.target.value = null;
  }

  updateUserStats(heroId, docId, reqType) {
    this._doctorService
      .updateDocvitaUserStats(heroId, docId, reqType)
      .subscribe((data) => {
        if (data['statusCode'] == 200) {
          console.log('User stats updated!');
        } else {
          console.log('User stats did not update!');
        }
      });
  }

  deleteProfile() {
    $('#deleteModal').modal('hide');
    let practiceIds = [];
    let memberId = this.profileData.id;
    if (this.associatedPractices.length > 0) {
      this.associatedPractices.forEach((element) => {
        practiceIds.push(element.key);
      });
    }
    // console.log(practiceIds);
    this._doctorService.deleteDoctor(memberId).then(() => {
      if (practiceIds.length == 0) {
        this._toastr.success('Profile Deleted successfully');
        $(`#info-tab`).removeClass('active');
        this._router.navigate(['profile-listing/']);
      } else {
        this._doctorService
          .unlinkMultipleClinic(memberId, practiceIds)
          .subscribe((data) => {
            $(`#info-tab`).removeClass('active');
            this._router.navigate(['profile-listing/']);
            this._toastr.success('Profile Deleted successfully');
          });
      }
    });
  }

  generateUniqueTag() {
    if (this.uniqueTagInputStr != null) {
      this._doctorService
        .generateUniqueTag(this.profileData.id, this.uniqueTagInputStr)
        .subscribe(
          (res) => {
            if (res['statusCode'] == 200 && res['body']['success'] == true) {
              this._toastr.success('Generated unique tag!');
            } else {
              this._toastr.error('Failed to generate unique tag!');
            }
          },
          (err) => {
            this._toastr.error('Failed to generate unique tag!');
          }
        );
    } else {
      this._toastr.error('Enter Unique tag');
    }
  }

  copyProfileForCSV() {
    let str = '';
    str += this.profileData.id + '\t';
    str += this.profileData.fullName() + '\t';
    if (
      this.profileData.specializations != null &&
      this.profileData.specializations.length > 0
    ) {
      str += this.profileData.specializations[0] + '\t';
    } else {
      str += '\t';
    }
    str += this.profileData.city + '\t';
    if (
      this.profileData.specializations != null &&
      this.profileData.specializations.length > 0
    ) {
      str += this.profileData.specializations.join(',') + '\t';
    } else {
      str += '\t';
    }
    if (
      this.profileData.profileLinks != null &&
      this.profileData.profileLinks.practoProfileUrl != null
    ) {
      str += this.profileData.profileLinks.practoProfileUrl + '\t';
    } else {
      str += '\t';
    }
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = str;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this._toastr.success('Copied to clipboard!');
  }

  onDeleteConfirmation(id) {
    $(id).modal('show');
  }

  onDeleteConfirmationClose(id) {
    $(id).modal('hide');
  }

  deleteImage() {
    this._doctorService.deleteProfileImage(this.profileData.id).then(() => {
      $('#deleteImageModal').modal('hide');
      this._toastr.success('Profile image deleted Successfully');
    });
  }

  addListedInItem(val: any) {
    let listedInObject = new ListedIn();
    listedInObject.title = val.title;
    listedInObject.urlPath = val.urlPath;
    this.selectionListedInMap.set(listedInObject.urlPath, listedInObject);
    console.log(this.selectionListedInMap);
  }

  removeListedInItem(val: any) {
    if (val && val.value) {
      this.selectionListedInMap.delete(val.value.urlPath);
      console.log(this.selectionListedInMap);
    } else {
      this._toastr.error(
        'Please refresh the page. Something went wrong in listed in selection'
      );
    }
  }

  addReasonOfVisitItem(val: any) {
    let reasonOfVisitObject = new ReasonOfVisit();
    reasonOfVisitObject.title = val.title;
    reasonOfVisitObject.urlPath = val.urlPath;
    this.selectionReasonsOfVisitMap.set(
      reasonOfVisitObject.urlPath,
      reasonOfVisitObject
    );
    console.log(this.selectionReasonsOfVisitMap);
  }

  removeReasonOfVisitItem(val: any) {
    if (val && val.value) {
      this.selectionReasonsOfVisitMap.delete(val.value.urlPath);
      console.log(this.selectionReasonsOfVisitMap);
    } else {
      this._toastr.error(
        'Please refresh the page. Something went wrong in listed in selection'
      );
    }
  }

  getReasonFromSuggesstion(urlPath: string) {
    return this.reasonOfVisitSuggestionArray.find((v) => v.urlPath == urlPath);
  }

  saveListedInValues() {
    this._toastr.info('Saving...');
    this._doctorService
      .updateListedIn(
        this.selectionListedInMap,
        this.profileData.listedIn,
        this.profileData.id
      )
      .then(() => {
        this._toastr.success('Listed in updated successfully.');
      })
      .catch((err) => {
        console.error(err);
        this._toastr.error('Failed to save listed in field. Please try again.');
      });
  }

  saveReasonOfVisitValues() {
    this._toastr.info('Saving...');
    this._doctorService
      .updateReasonOfVisit(
        this.selectionReasonsOfVisitMap,
        this.profileData.reasonsOfVisit,
        this.profileData.id
      )
      .then(() => {
        this._toastr.success('Listed in updated successfully.');
      })
      .catch((err) => {
        console.error(err);
        this._toastr.error('Failed to save listed in field. Please try again.');
      });
  }

  toggleVisibilityForReason(path: string) {
    if (this.selectionReasonsOfVisitMap.has(path)) {
      let temp = this.selectionReasonsOfVisitMap.get(path);
      temp.display = !temp.display;
      this.selectionReasonsOfVisitMap.set(temp.urlPath, temp);
    }
  }

  makePrimaryConsult(event,platform){
    console.log(event,platform)
    
    for(let i in this.onlineConsultationPlatformConfig){
      if(event&&i!=platform){
        this.onlineConsultationPlatformConfig[i]['isPrimary'] = false;
      }
    }
  }

  platformToggle(event,platform) {
    console.log(this.onlineConsultationPlatformConfig)
  }

  ngOnDestroy() {
    this.dataLayerSubscription.unsubscribe();
  }
}
