<div class="d-flex align-items-center my-2 gap-3" *ngIf="practiceOptions && practiceOptions?.length > 0">
    <div class="d-flex align-items-center">
        <input class="form-check-input me-2" type="checkbox"
            [(ngModel)]="isInPerson">
        <label class="form-check-label" style="white-space: nowrap;">
            In-Person?
        </label>
    </div>
    <ng-container *ngIf="isInPerson">
        <select class="form-control" [(ngModel)]="practiceSelection.key">
            <option [value]="item.key" [selected]="item.key == practiceSelection?.key"
                *ngFor="let item of practiceOptions">{{item.value}}</option>
        </select>
    </ng-container>
</div>
