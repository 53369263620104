import { Component, Input } from '@angular/core';
import { User } from '../../models-ts/Actors/User/User';
import { OrganisationConnection } from '../../models-ts/Relationships/OrganisationConnection';
import { DoctorsService } from '../../services/doctors/doctors.service';
import { PatientService } from '../../services/patient/patient.service';
import { PatientRelationshipService } from '../../services/patient/patient-relationship.service';
import { ClientRelationship } from '../../models-ts/Entities/client-relationship';
import { ServiceTypes } from '../../models-ts/Enums/Enums';
import { CountrywiseFeeCalc, Currency } from '../../models-ts/Helpers/countrywiseFeeCalc';
import { classToPlain } from 'class-transformer';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-client-relationship-fee-config',
  templateUrl: './client-relationship-fee-config.component.html',
  styleUrl: './client-relationship-fee-config.component.scss'
})
export class ClientRelationshipFeeConfigComponent {
  quickConnections: OrganisationConnection[] = [];
  _currentSelectedConnection: OrganisationConnection;
  set currentSelectedConnection(val: OrganisationConnection) {
    this._currentSelectedConnection = val;
    console.log(val);
    if (val) {
      this.populateUIBasedOnSelection();
    }
  }
  get currentSelectedConnection() {
    return this._currentSelectedConnection;
  }
  serviceOptions: ServiceTypes[] = [ServiceTypes.individual, ServiceTypes.couple, ServiceTypes.family];
  formSelection: {
    serviceType: ServiceTypes,
    amount: number,
    currency: Currency
  } = {
      serviceType: null,
      amount: null,
      currency: Currency.INR
    };
  standardNatInNatPricingStr: string;
  isSaving = false;
  clientRelation: ClientRelationship;
  _client: User;
  @Input() set client(val: User) {
    this._client = val;
    if (val && val.docvitaId) {
      this.populateUI(val);
    }
  }

  get client() {
    return this._client;
  }

  constructor(
    private _patientService: PatientService,
    private _doctorsService: DoctorsService,
    private _toastrService: ToastrService,
    private _patientRelationshipService: PatientRelationshipService
  ) { }

  selectServiceType() {
    if (this.formSelection.serviceType) {
      const feeConfig = this.clientRelation.connectedProviders.get(this.currentSelectedConnection.organisationId)?.feeConfig;
      if (feeConfig && feeConfig.has(this.formSelection.serviceType)) {
        const obj = feeConfig.get(this.formSelection.serviceType);
        this.formSelection.amount = obj.amount;
        this.formSelection.currency = obj.currency;
      }
    }
  }

  get providerSelected() {
    return this.currentSelectedConnection != null && this.currentSelectedConnection.organisationId != null;
  }

  async populateUIBasedOnSelection() {
    // console.log(this.currentSelectedConnection.organisationId);
    this.formSelection = {
      serviceType: null,
      amount: null,
      currency: Currency.INR
    };
    this.standardNatInNatPricingStr = await this.standardNatInNatPricing(this.currentSelectedConnection.organisationId);
  }

  async populateUI(client: User) {
    try {
      this.quickConnections = (await this._patientService.getOrganizaitonNameForQuickContact(client)).quickConnections;
      this.clientRelation = await this._patientRelationshipService.getClientRelationship(client.docvitaId);
      // console.log(this.clientRelation)
    } catch (error) {
      console.error(error);
    }
  }

  // format
  async standardNatInNatPricing(doctorProfileId: string) {
    const doctorProfile = await this._doctorsService.getProviderProfile(doctorProfileId);
    if (doctorProfile && doctorProfile.onlineConsultationConfig && doctorProfile.onlineConsultationConfig.fee != null) {
      const masterSpecialization = doctorProfile.getMasterSpecialization();
      const fee = doctorProfile.onlineConsultationConfig.fee;
      const in_fees = new CountrywiseFeeCalc().fee(fee, "IN", masterSpecialization)
      const us_fees = new CountrywiseFeeCalc().fee(fee, "US", masterSpecialization)
      const ca_fees = new CountrywiseFeeCalc().fee(fee, "CA", masterSpecialization)
      const othr_fees = new CountrywiseFeeCalc().fee(fee, null, masterSpecialization)
      let fees_string = '(IN) ' + in_fees.amount + ' ' + in_fees.unit + ' • (US) ' + us_fees.amount + ' ' + us_fees.unit + ' • (CA) ' + ca_fees.amount + ' ' + ca_fees.unit + ' • (Other) ' + othr_fees.amount + ' ' + othr_fees.unit
      return fees_string;
    } else {
      return "-"
    }
  }

  selectedCurrency(c: Currency) {
    this.formSelection.currency = c;
  }

  async save() {
    this.isSaving = true;
    this._toastrService.info("Saving...");
    await this._patientRelationshipService.saveFee(this.client.docvitaId, this.currentSelectedConnection.organisationId,
      this.formSelection.amount, this.formSelection.currency, this.formSelection.serviceType);
    this.isSaving = false;
    this._toastrService.success("Saved");
  }


}
