import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

declare var bootstrap: any;
@Component({
  selector: 'offcanvas-chat',
  templateUrl: './offcanvas-chat.component.html',
  styleUrls: ['./offcanvas-chat.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OffcanvasChatComponent implements OnInit {
  
  @Input() set chatItemId(val:string){
    this._chatItemId = val;
  };
  _chatItemId:string;
  @Input() showButton:boolean = true;

  number = '_' + Math.random().toString(36).slice(2, 9);

  constructor() { }

  ngOnInit(): void {
  }

  openWhatsAppChat() {
    window.open(
      location.origin + '/chats/'+this._chatItemId,
      '_blank'
    );
  }

  public open(chatItemId:string) {
    // console.log(chatItemId)
    
    this._chatItemId = chatItemId;
    let myOffcanvas = document.getElementById('offcanvasForChats'+this.number)
    let offcanvas = new bootstrap.Offcanvas(myOffcanvas, { backdrop: true, scroll: true })
    offcanvas.hide();
    offcanvas.show();
  }

  get invalidChatId(){
    if(this._chatItemId&&this._chatItemId.length>9){
      return false
    }else{
      return true
    }
  }

}
