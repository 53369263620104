import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { User } from '../../models-ts/Actors/User/User';
import { Lead } from '../../models-ts/Entities/Lead';
import { ReschedulingRequest } from '../../models-ts/Entities/ReschedulingRequest';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault('Asia/Kolkata');
import {
  ReschedulingRequestType,
  ReschedulingRequestStatus,
} from '../../models-ts/Enums/Enums';
import { ToastrService } from 'ngx-toastr';
import { DoctorListingProfile } from '../../models-ts/Actors/Doctor/DoctorListingProfile';
import { RescheduleRequestService } from '../../services/reschedule-request/reschedule-request.service';
import { classToPlain, plainToClass } from 'class-transformer';
import { CRMUser } from '../../models-ts/Actors/User/CRMUser';
@Component({
  selector: 'rescheduling-request-modal',
  templateUrl: './rescheduling-request.component.html',
  styleUrls: ['./rescheduling-request.component.css'],
})
export class ReschedulingRequestComponent implements OnInit {
  @ViewChild('rescheduleRequestModal', { read: TemplateRef })
  rescheduleRequestModal: TemplateRef<any>;
  @Input() showButton: boolean = true;
  @Input() lead: Lead = new Lead();
  @Input() doctorProfile: DoctorListingProfile = new DoctorListingProfile();
  @Input() currentHeroInfo: CRMUser = new CRMUser();
  @Input() rescheduleRequest: ReschedulingRequest = new ReschedulingRequest();
  // reschedulingTypes = Object.keys(ReschedulingRequestType);
  reschedulingTypes = [ReschedulingRequestType.provider_to_client, ReschedulingRequestType.client_to_provider, ReschedulingRequestType.docvita_team];
  date: string;
  time: string;
  requestType: ReschedulingRequestType;
  updateMode: boolean = false;
  sendRequestAgainCheck: boolean = false;

  sendingLoader: boolean = false;

  constructor(
    private modalService: BsModalService,
    private _toastr: ToastrService,
    private _rRService: RescheduleRequestService
  ) {}

  ngOnInit(): void {}

  openModal(reschedulingRequest?: ReschedulingRequest) {
    this.rescheduleRequest = new ReschedulingRequest();
    if (reschedulingRequest && reschedulingRequest.id) {
      this.rescheduleRequest = plainToClass(
        ReschedulingRequest,
        classToPlain(reschedulingRequest)
      );
    }
    this.initForm();
    this.modalService.show(this.rescheduleRequestModal, { class: 'modal-md' });
  }
  closeModal() {
    this.modalService.hide();
  }

  initForm() {
    this.updateMode = false;
    this.date = new Date().toISOString().split('T')[0];
    this.time = new Date().toLocaleTimeString().slice(0, 5);
    this.requestType = ReschedulingRequestType.provider_to_client;
    if (this.rescheduleRequest && this.rescheduleRequest.id) {
      this.updateMode = true;
      this.sendRequestAgainCheck = false;
      this.date = this.rescheduleRequest.suggestedDate.slice();
      this.time = this.rescheduleRequest.suggestedTime.slice();
      this.requestType = this.rescheduleRequest.type;
    }
  }
  @ViewChild('apptClashModal') apptClashModal;
  createRequest() {
    if (this.validateForm()) {
      let newRequest = new ReschedulingRequest();
      newRequest.clientId = this.lead.consultationInfo.patientId;
      newRequest.providerId = this.lead.consultationInfo.doctorId;
      newRequest.leadId = this.lead.id;
      newRequest.appointmentId = this.lead.consultationInfo.appointmentId;
      newRequest.date = this.lead.consultationInfo.date;
      newRequest.timeslot = this.lead.consultationInfo.timeslot;
      newRequest.suggestedDate = this.date;
      newRequest.suggestedTime = this.time;
      newRequest.type = this.requestType;
      newRequest.createdById = this.currentHeroInfo.docvitaId;
      newRequest.createdByName = this.currentHeroInfo.name;
      newRequest.doctorName = this.lead.consultationInfo.doctorName;
      newRequest.patientName = this.lead.consultationInfo.patientName;

      let _timeslot =
        this.time +
        '-' +
        dayjs(new Date(this.date + ' ' + this.time))
          .add(+this.doctorProfile.appointmentDuration, 'minutes')
          .format('HH:mm');

      this.sendingLoader = true;
      this.apptClashModal
        .openConflictPromise(
          this.doctorProfile.id,
          this.date,
          _timeslot,
          this.doctorProfile.fullName(),
          this.lead.id
        )
        .then((hasConflicts) => {
          if (hasConflicts) {
            this.sendingLoader = false;
            this.apptClashModal.openModal();
          } else {
            this.continueRequestFlow(newRequest);
          }
        })
        .catch((err) => {
          this.continueRequestFlow(newRequest);
        });
    } else {
      this._toastr.warning('Form incomplete!');
    }
  }
  continueRequestFlow(newRequest: ReschedulingRequest) {
    this.sendingLoader = true;
    this._rRService
      .rescheduleRequest(newRequest)
      .then((res) => {
        this.sendingLoader = false;
        if (res) {
          this._toastr.success('Request Sent!');
          this.closeModal();
        } else {
          this._toastr.error('Request failed to send..');
        }
      })
      .catch((err) => {
        this.sendingLoader = false;
        this._toastr.error('Request failed to send..');
      });
  }

  modifyRequest() {
    if (this.validateForm()) {
      let copyRequest = plainToClass(
        ReschedulingRequest,
        classToPlain(this.rescheduleRequest)
      );
      copyRequest.suggestedDate = this.date;
      copyRequest.suggestedTime = this.time;
      copyRequest.updatedOn = new Date().getTime();
      copyRequest.updatedById = this.currentHeroInfo.docvitaId;
      copyRequest.updatedByName = this.currentHeroInfo.name;

      let _timeslot =
        this.time +
        '-' +
        dayjs(new Date(this.date + ' ' + this.time))
          .add(+this.doctorProfile.appointmentDuration, 'minutes')
          .format('HH:mm');
      this.sendingLoader = true;
      this.apptClashModal
        .openConflictPromise(
          this.doctorProfile.id,
          this.date,
          _timeslot,
          this.doctorProfile.fullName(),
          this.lead.id
        )
        .then((hasConflicts) => {
          if (hasConflicts) {
            this.sendingLoader = false;
            this.apptClashModal.openModal();
          } else {
            this.continueModifyRequestFlow(copyRequest);
          }
        })
        .catch((err) => {
          this.continueModifyRequestFlow(copyRequest);
        });
    } else {
      this._toastr.warning('Form incomplete!');
    }
  }

  continueModifyRequestFlow(copyRequest: ReschedulingRequest) {
    this.sendingLoader = true;
    this._rRService
      .modifyRescheduleRequest(copyRequest)
      .then((resp) => {
        if (resp) {
          this._toastr.success('Request Updated!');
          this.closeModal();
        } else {
          this._toastr.error('Request failed to update..');
        }
        this.sendingLoader = false;
      })
      .catch((err) => {
        this.sendingLoader = false;
        this._toastr.error('Request failed to update..');
      });
  }

  validateForm() {
    if (
      this.date &&
      this.date.length > 0 &&
      this.time &&
      this.time.length > 0 &&
      this.requestType != null &&
      this.lead &&
      this.lead.consultationInfo &&
      this.lead.id &&
      this.currentHeroInfo &&
      this.currentHeroInfo.docvitaId
    ) {
      return true;
    } else {
      return false;
    }
  }

  selectSlot(event) {
    if (event && event['startTime']) {
      this.time = event['startTime'];
    }
  }

  get patientName() {
    return this.lead.patientName ? this.lead.patientName : '-';
  }
  get doctorName() {
    return this.lead.doctorName ? this.lead.doctorName : '-';
  }
  get patientContact() {
    return this.lead.patientContactInfo
      ? '+' +
          this.lead.patientContactInfo.countryCode +
          '-' +
          this.lead.patientContactInfo.number
      : '-';
  }
  get appointmentId() {
    return this.lead.consultationInfo &&
      this.lead.consultationInfo.appointmentId
      ? this.lead.consultationInfo.appointmentId
      : '-';
  }
  get apptDateTime() {
    if (
      this.lead &&
      this.lead.consultationInfo &&
      this.lead.consultationInfo.date &&
      this.lead.consultationInfo.timeslot
    ) {
      let date = this.lead.consultationInfo.date;
      let time = this.lead.consultationInfo.timeslot.split('-')[0];
      return dayjs(new Date(date + ' ' + time)).format(
        'DD-MM-YYYY, hh:mm a'
      );
    } else {
      return '-';
    }
  }
  get suggestedDateTime() {
    if (
      this.rescheduleRequest &&
      this.rescheduleRequest.suggestedDate &&
      this.rescheduleRequest.suggestedTime
    ) {
      let date = this.rescheduleRequest.suggestedDate;
      let time = this.rescheduleRequest.suggestedTime;
      return dayjs(new Date(date + ' ' + time)).format(
        'DD/MM/YYYY, hh:mm a'
      );
    } else {
      return '-';
    }
  }
  displayRequestType(type) {
    return type
      .toString()
      .split('_')
      .map((da) => da[0].toUpperCase() + da.slice(1, da.length))
      .join(' ');
  }

  timeToMin(hm: any) {
    let a = hm.split(':');
    let minutes = +a[0] * 60 + +a[1];
    return minutes;
  }

  minToTime(mins: any) {
    let m = mins % 60;
    let h = ((mins - m) / 60).toString();
    var HHMM =
      (h.length > 1 ? h : '0' + h) + ':' + (m < 10 ? '0' : '') + m.toString();
    return HHMM;
  }
}
