import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DoctorListingProfile } from 'src/app/shared/models-ts/Actors/Doctor/DoctorListingProfile';
import { AuthUserInfoService } from 'src/app/shared/services/auth-user-info/auth-user-info.service';
import { DoctorDataService } from 'src/app/shared/services/doctor-data/doctor-data.service';
import { DoctorsService } from 'src/app/shared/services/doctors/doctors.service';

@Component({
  selector: 'app-photo-upload-listing',
  templateUrl: './photo-upload-listing.component.html',
  styleUrls: ['./photo-upload-listing.component.css']
})
export class PhotoUploadListingComponent implements OnInit {

  selectedDoctor: DoctorListingProfile = new DoctorListingProfile();
  id: string;
  title: string = "Doctor Profile - Photo Upload";
  constructor(
    private _router:Router,
    private _activatedRoute:ActivatedRoute,
    private _doctorDataService: DoctorDataService,
    private _authUserInfoService: AuthUserInfoService,
    private _doctorService: DoctorsService,
    private _toastr: ToastrService
  ) { }

  ngOnInit(): void {
  }

  onSelectedDoctorClicked(doctor: DoctorListingProfile) {
    this.selectedDoctor = doctor;
    this.id = this.selectedDoctor.id;
    if (this.selectedDoctor != null && this.selectedDoctor.id != null) {
      this._doctorDataService.updateCurrentDoctor(this.selectedDoctor);
    }
    this._router.navigate(
      [`data-entry/doctor-profile-photo/${this.selectedDoctor.id}`],
      { queryParamsHandling: 'preserve', preserveFragment: true }
    );
  }

  fileChangeEvent(fileInput) {
    console.log(fileInput);
    let filesForUpload: any[] = [];
    for (let f of <File[]>fileInput.target.files) {
      let id = f.name.split(".")[0];
      console.log(id);
      filesForUpload.push({
        name: f.name,
        data: f,
      });
      let reader = new FileReader();
      reader.readAsDataURL(f);
      reader.onload = (_event) => {
        this._doctorService
        .uploadPhoto(reader.result, id, ".jpeg")
        .subscribe((data) => {
          this.updateUserStats(
            this._authUserInfoService.getUserInfo().docvitaId,
            id,
            'photo'
          );
          this._toastr.success('Photo updated successfully');
        });
      }
    }
    fileInput.target.value = null;
  }

  updateUserStats(heroId, docId, reqType) {
    this._doctorService
      .updateDocvitaUserStats(heroId, docId, reqType)
      .subscribe((data) => {
        if (data['statusCode'] == 200) {
          console.log('User stats updated!');
        } else {
          console.log('User stats did not update!');
        }
      });
  }


  backClicked() {
    this._router.navigate(['../'], { relativeTo: this._activatedRoute });
  }

}
