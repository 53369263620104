import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {plainToClass} from 'class-transformer';
import { ToastrService } from 'ngx-toastr';
import { DoctorListingProfile } from 'src/app/shared/models-ts/Actors/Doctor/DoctorListingProfile';
import { ContactNumber } from 'src/app/shared/models-ts/Actors/Person/ContactNumber';
import { Email } from 'src/app/shared/models-ts/Actors/Person/Email';

@Component({
  selector: 'app-phone-email',
  templateUrl: './phone-email.component.html',
  styleUrls: ['./phone-email.component.css']
})
export class PhoneEmailComponent implements OnInit {

  @Input() profileData: DoctorListingProfile;

  @Output() phone_email:EventEmitter<any> = new EventEmitter<any>(); 
  
  contactNumbers: ContactNumber[] = new Array();

  emails: Email[] = new Array();
  valid: boolean = true;

  constructor(private _toastrService: ToastrService,) { }

  ngOnInit(): void {
    this.contactNumbers = [];
    this.emails = [];
    if(this.profileData.contactNumbers){
      for(let cn in this.profileData.contactNumbers){
        this.contactNumbers = Array.from(this.profileData.contactNumbers[cn].values())
      }
    }
    if(this.profileData.emails){
      this.emails = Array.from(this.profileData.emails.values());
    }
  }

  addEmailField(){
    this.emails.push(new Email());
  }
  deleteEmailField(x){
    this.emails.splice(x,1)
  }

  addPhoneField(){
    let newContact = new ContactNumber();
    newContact['countryCode'] = '91';
    this.contactNumbers.push(newContact);
  }
  deletePhoneField(i){
    this.contactNumbers.splice(i,1);
  }

  getPhoneEmail(){
      let emailMap = new Map<string, Email>();
      let phoneMap = new Map<string, Map<string,ContactNumber>>();
      if(this.emails && this.emails.length>0){
        for (let email of this.emails) {
          if(!email.email.includes('@')){
            this._toastrService.warning("Invalid email!")
            this.valid = false;
            break;
          }
          else{
            this.valid = true;
            emailMap[email.email] = email;
          }
        }
      }else{
        emailMap = null;
      }
      if(this.contactNumbers && this.contactNumbers.length>0){
        let map = new Map<string,ContactNumber>();
        for (let phone of this.contactNumbers){ 
          if(phone.number.toString().length!=10){
              this._toastrService.warning("Invalid phone number!")
              this.valid = false;
              break;
          } else{
            this.valid = true;
            map[phone.number] = phone;
            phoneMap[phone.countryCode] = map;
          }
        }
        
      }else{
        phoneMap = null;
      }

      this.phone_email.emit({contactNumbers:phoneMap,emails:emailMap,valid:this.valid});
  }

}
