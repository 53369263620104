<div class="d-flex flex-column w-100">
    <div class="card mt-2">
        <div class="card-body">
            <div>DocVita Profile Link: <br> <a href="{{doctorProfile.getProfileLink()}}" target="_blank">{{doctorProfile.getProfileLink()}}</a></div>
            <div *ngIf="doctorProfile.profileLinks != null">Practo Profile Link: <br> <a href="{{doctorProfile.profileLinks.practoProfileUrl}}" target="_blank">{{doctorProfile.profileLinks.practoProfileUrl}}</a></div>
        </div>
    </div>
    <div class="d-flex">
        <h4 class="mt-3">Upload Doctor's Profile Photo</h4>
        <app-verify-approve [profileData]="doctorProfile" class="ms-auto mt-3"></app-verify-approve>
    </div>
    
    <h6 class="mt-2">Photo guidelines</h6>
    <ul>
        <li>Photo should not have any watermarks</li>
        <li>Photo should not be blurry</li>
        <li>Upload Professional photograph of the doctor</li>
    </ul>
    <div class="col-md-4 mt-4" style="height: 400px;">
        <app-profile-photo [listingProfile]="doctorProfile" (photoDetails)="updatePhotoChanges($event)"></app-profile-photo>
    </div>
    <div class="form-check mt-3">
        <input class="form-check-input" type="checkbox" id="photoNotFound" (ngModelChange)="updateCheckBox(internTags['profile_photograph_not_found']['key'],$event)" [(ngModel)]="photographCheck">
        <label class="form-check-label" for="photoNotFound">
          {{internTags['profile_photograph_not_found']['value']}}
        </label>
    </div>
</div>