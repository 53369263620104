import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { classToPlain } from 'class-transformer';

import { ChatMessage } from 'src/app/shared/models-ts/Entities/chat/ChatMessage';
import {
  ChatMessageStatus,
  WAMessageTypes,
} from 'src/app/shared/models-ts/Enums/chatEnum';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
import { ChatMessagesService } from '../services/chat-messages.service';
import { Router } from '@angular/router';
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault('Asia/Kolkata');
@Component({
  selector: 'app-chat-message-cell',
  templateUrl: './chat-message-cell.component.html',
  styleUrls: ['./chat-message-cell.component.scss'],
})
export class ChatMessageCellComponent implements OnInit {
  _message: ChatMessage;
  @Input() set message(val: ChatMessage) {
    this._message = val;
    this.isInbound = val.isInbound;
    this.messageCellDisplayTime = val.messageCellDisplayTime;
    if(val?.context?.parentId) {
      this.fetchReplyMessage(val?.context?.parentId)
    }
    setTimeout(() => {
      let ele = document.getElementById(this.message.messageId);
      if (ele) {
        this.observer.observe(ele);
      } else {
      }
    }, 110);
  }
  get message(): ChatMessage {
    return this._message;
  }

  _indexVal: number;
  get indexVal(): number {
    return this._indexVal;
  }

  @Input() set indexVal(val: number) {
    this._indexVal = val;
    this.startObserving();
  }

  @Input() lastIndex: boolean;
  @Input() firstIndex: boolean;
  @Input() highLightId: string;

  @Output() loadPrev = new EventEmitter<void>();
  @Output() loadNext = new EventEmitter<void>();
  @Output() gotoReplyMessage = new EventEmitter<string>();
  observer: IntersectionObserver;
  hasComeInViewAtLeastOnce: boolean = false;
  isInbound: boolean;
  messageCellDisplayTime: string;

  chatMessageStatus = ChatMessageStatus;

  replyMessage: ChatMessage;
  replyMessagePreview: string;

  constructor(
    private _chatMessageService: ChatMessagesService,
    private _router:Router
  ) {}

  ngOnInit(): void {}

  getFileIcon(messageType: WAMessageTypes) {
    let icon;
    switch (messageType) {
      case WAMessageTypes.image: {
        icon = 'icon-image';
        break;
      }
      case WAMessageTypes.document: {
        icon = 'icon-file-text';
        break;
      }
      case WAMessageTypes.audio: {
        icon = 'icon-music';
        break;
      }
      case WAMessageTypes.video: {
        icon = 'icon-video';
        break;
      }
      default: {
        icon = null;
        break;
      }
    }
    return icon;
  }
  get readBy(): string {
    let readByStr = 'Read By:';
    if (this.message.readReceipts && this.message.readReceipts.size > 0) {
      this.message.readReceipts.forEach((val, key) => {
        readByStr +=
          ' ' +
          val.updatedByName +
          '(' +
          dayjs(new Date(val.updatedOn)).format('hh:mma') +
          '),';
      });
    } else {
      readByStr = this.message.status;
    }
    return readByStr;
  }

  fetchReplyMessage(messageId: string) {
    this._chatMessageService.fetchSingleMessage(messageId).then((m)=>{
      if(m) {
        this.replyMessage = m;
        this.replyMessagePreview = m.replyMessagePreview;
      } else {

      }
    }).catch(err=>{
      console.error(err);  
    })
  }

  startObserving() {
    // console.log("startObserving", this.indexVal)
    if (this.observer != null) {
      this.observer.disconnect();
    }
    let tick = new Date().getTime();
    this.observer = new IntersectionObserver(
      (entries, opts) => {
        entries.forEach((entry) => {
          // console.log('ratio', entry.intersectionRatio);
          // console.log("entry.isIntersecting", this.indexVal, entry.isIntersecting)
          if (entry.isIntersecting && this.lastIndex) {
            this.hasComeInViewAtLeastOnce = true;
            tick = new Date().getTime();
            this.loadPrev.next();
          } else if (entry.isIntersecting && this.firstIndex) {
            this.hasComeInViewAtLeastOnce = true;
            // console.log('isIntersecting', this.indexVal, this.lastIndex);
            tick = new Date().getTime();
            this.loadNext.next();
          } else if (this.hasComeInViewAtLeastOnce) {
            let time = new Date().getTime();
          }
          return true;
        });
      },
      {
        root: null, // default is the viewport
        threshold: 0.5, // percentage of taregt's visible area. Triggers "onIntersection"
      }
    );
  }
  
  goToMessageReply(){
    this.gotoReplyMessage.emit(this.message.context.parentId)
  }
}
