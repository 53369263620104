import { Person } from "../Person/Person";
import {
  Expose,
  Transform,
  plainToClass,
  Type,
  Exclude,
} from "class-transformer";
import { OrganisationConnection } from "../../Relationships/OrganisationConnection";
import { HealthHeroInfo } from "../../Relationships/HealthHeroInfo";
import { ClientOwnership } from "../../Relationships/ClientOwnership";

export class PartnerConnection {
  @Expose() 
  id: string;

  @Expose() 
  name: string;

  @Expose()
  isAdmin: boolean;
}

export class User extends Person {

  @Expose() blocked: boolean;
  @Expose()
  isOnApp: boolean;

  @Expose()
  uniqueID: string;

  @Expose()
  isInternationalClient: boolean;

  @Expose()
  internationalClientForProviders: string[] = []; //provider ids


  @Expose()
  @Type(() => HealthHeroInfo)
  healthHeroInfo: HealthHeroInfo;

  @Expose()
  @Transform(
    (value) => {
      if (value) {
        let map = new Map<string, OrganisationConnection>();
        for (let entry of Object.entries(value)) {
          map.set(entry[0], plainToClass(OrganisationConnection, entry[1]));
        }
        return map;
      } else {
        return new Map<string, OrganisationConnection>();
      }
    },
    { toClassOnly: true }
  )
  organisationConnections: Map<string, OrganisationConnection>;

  @Expose()
  @Transform(
    (value) => {
      if (value) {
        let map = new Map<string, PartnerConnection>();
        for (let entry of Object.entries(value)) {
          map.set(entry[0], plainToClass(PartnerConnection, entry[1]));
        }
        return map;
      } else {
        return new Map<string, PartnerConnection>();
      }
    },
    { toClassOnly: true }
  )
  partners: Map<string, PartnerConnection>;

  @Expose()
  @Transform(
    (value) => {
      if (value) {
        let map = new Map<string, ClientOwnership>();
        for (let entry of Object.entries(value)) {
          map.set(entry[0], plainToClass(ClientOwnership, entry[1]));
        }
        return map;
      } else {
        return new Map<string, ClientOwnership>();
      }
    },
    { toClassOnly: true }
  )
  clientOwnership: Map<string, ClientOwnership>;

  getPrimaryPartner(): PartnerConnection {
    if(this.partners && this.partners.size > 0) {
      let temp: PartnerConnection;
      this.partners.forEach((val)=>{
        if(temp == null) {
          temp = val;
        }
      })
      return temp;
    } else {
      return null;
    }
  }

  internationalClientHelper(providerId: string): boolean {
    return (this.internationalClientForProviders?this.internationalClientForProviders.includes(providerId): false);
  }

  getOrganisationId(): string {
    let organisationId = undefined;
    this.organisationConnections.forEach((element) => {
      if (element.organisationId) {
        organisationId = element.organisationId;
      }
    });
    return organisationId;
  }

  getFirstName() {
    let str;
    if (this.name != null) {
      let name = this.name;
      if (name.includes(".") && !name.includes(". ")) {
        name = name.split(".").join(". ");
      }
      let arr = name.split(" ");
      let trimmedArr: string[] = [];
      for (let a of arr) {
        let val = a.toLowerCase();
        if(val == "mrs" || val == "mr" || val == "dr") {
          //No action required
        }else {
          trimmedArr.push(a.trim());
        }
      }
      for (let t of trimmedArr) {
        if (
          str == null &&
          t.length > 2 &&
          !t.includes("(") &&
          !t.includes(")") &&
          !t.includes(".")
        ) {
          str = t;
        }
      }
    }
    if(str == null) {
      return this.name;
    }
    return str;
  }

  get genderAgeDisplay(): string {
    let str: string = "";
    if (this.gender != null && this.gender.trim().length > 0) {
      str += this.gender.substr(0, 1).toUpperCase() + this.gender.substr(1);
    }
    if (this.dateOfBirth != null && this.dateOfBirth.length > 0) {
      let ageInYrs: number =
        new Date().getFullYear() - new Date(this.dateOfBirth).getFullYear();
      if (ageInYrs > 0) {
        if (str == null || str.length == 0) {
          str = ageInYrs + " yrs old";
        } else {
          str += ", " + ageInYrs + " yrs old";
        }
      }
    } else if(this.getAgeInYears()) {
      let ageInYrs: number = this.getAgeInYears();
      if (str == null || str.length == 0) {
        str = ageInYrs + " yrs old";
      } else {
        str += ", " + ageInYrs + " yrs old";
      }
    }
    if (str == null || str.length == 0) {
      return "-";
    } else {
      return str;
    }
  }

  isHealthHero() {
    return this.healthHeroInfo != null && this.healthHeroInfo.isEnrolled;
  }
}

// @Type(() => OrganisationConnection)
// organisationConnections: Map<string, OrganisationConnection>;
