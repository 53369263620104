import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-doc-slots',
  templateUrl: './doc-slots.component.html',
  styleUrls: ['./doc-slots.component.css']
})
export class DocSlotsComponent implements OnInit {
  currentSlotTab="block";
  constructor(
    private router:Router,
    private route:ActivatedRoute
  ) { }

  ngOnInit(): void {
    let tab = this.route.snapshot.queryParamMap.get('st');
    if(tab){
      this.currentSlotTab = tab;
    }
    
  }
  changeTab(tabName:string){
    this.currentSlotTab=tabName;
    this.router.navigate( [], { queryParams: {st:tabName} } )
  }

}
