import {TransactionSource} from "../Enums/pay";
export const PaymentSourcesArray: string[] = [
    TransactionSource.Cashfree, 
    TransactionSource.Razorpay, 
    TransactionSource.HDFCBANK,
    TransactionSource.GPay,
    TransactionSource.Wise,
    TransactionSource.Paytm,
    TransactionSource.PayPal,
    TransactionSource.Stripe,
    TransactionSource.DocVitaWallet,
    TransactionSource.DocVitaPromoCredit,
    TransactionSource.PlanPurchaseWallet,
    TransactionSource.DOCUBE
];