<div class="h-100" style="overflow-y: scroll;">
  <div class="d-flex align-items-center justify-content-between px-3 pt-3">
    <img src="https://s3.ap-south-1.amazonaws.com/docvita.com/docvita_logo_transparent_bg.png" alt="logo"
      class="__navbarLogoStyles" />
    <div class="__logoutLink" (click)="logoutCurrentUser()">🥺 Logout</div>
  </div>
  <div class="d-flex align-items-center justify-content-between p-3">
    <h2 class="mb-0" style="font-size: 24px">
      Hey <b *ngIf="currentHeroInfo&&currentHeroInfo.name">{{ currentHeroInfo.name }}</b> 👋🏻, Nice
      to see you!
    </h2>
    <div class="ms-auto d-flex align-items-center me-5">
      <button class="btn btn-sm btn-outline-primary" title="Hard refresh your app" (click)="reloadApp()">
        Reload App
      </button>
      <div class="form-check form-switch m-0 ms-3">
        <input [(ngModel)]="textBlur" type="checkbox" class="form-check-input" id="multi-spec-switch"
          (ngModelChange)="toggleTextBlur()" />
        <label class="form-check-label ms-2" for="multi-spec-switch" style="font-size: 0.9em;">
          Privacy Mode
        </label>
      </div>
    </div>
  
    <div class="d-flex align-items-center p-2 border" style="border-radius: 4px">
      <span style="
          width: 8px;
          height: 8px;
          background-color: #22f414;
          border-radius: 50%;
          margin-right: 6px;
        ">
      </span>
      <span style="font-size: 18px; color: #555"> Internal Systems </span>
    </div>
  </div>
  
  <div class="d-flex w-100 flex-wrap align-items-start" id="frequentOptions-grid">
    <div class="card mx-2 my-2 __cardStyles" (click)="goTo(opt['route'], opt['fragment'])"
      style="width: 24%; height: 9rem; cursor: pointer" *ngFor="let opt of availableFrequentOptions">
      <div class="card-body">
        <h5 class="card-title">{{ opt.title }}</h5>
        <p class="card-text">{{ opt.subtitle }}</p>
      </div>
    </div>
  </div>
  
  <div class="d-flex w-100 flex-wrap align-items-start" id="options-grid">
    <div class="card mx-2 my-2 __cardStyles" (click)="goTo(opt['route'], opt['fragment'])"
      style="width: 18%; height: 9rem; cursor: pointer" *ngFor="let opt of availableOptions">
      <div class="card-body">
        <h5 class="card-title">{{ opt.title }}</h5>
        <p class="card-text">{{ opt.subtitle }}</p>
      </div>
    </div>
  </div>
  
  <h4 class="ms-2">Quick Links</h4>
  <div class="container">
    <div class="item" *ngIf="currentHeroInfo&&currentHeroInfo.hasSpecialAccess">
      <div class="card p-2">
        <div class="dropbtn" [id]="'provider-payout'+'_btn'" (click)="toggle('provider-payout')">
          Provider Payouts
          <img class="dropbtn-icon" src="https://docvita-icons.s3.ap-south-1.amazonaws.com/icons/chevron_down_2x.svg" />
        </div>
        <div class="content" [id]="'provider-payout'+'_content'">
          <a [routerLink]="['/ledger/provider']">Ledger</a>
          <a [routerLink]="['/payouts/import-money-transfers-entries']">Razorpay Payout Import</a>
          <a [routerLink]="['/payouts/cf-transfers']">Cashfree Transfers</a>
          <a [routerLink]="['/payouts/message']">Send WA Message for Payout</a>
        </div>
      </div>
    </div>
    
    <div class="item">
      <div class="card p-2">
        <div class="dropbtn" [id]="'payments-invites'+'_btn'" (click)="toggle('payments-invites')">
          Payments, Invites & Bookings
          <img class="dropbtn-icon" src="https://docvita-icons.s3.ap-south-1.amazonaws.com/icons/chevron_down_2x.svg" />
        </div>
        <div class="content" [id]="'payments-invites'+'_content'">
          <a [routerLink]="['/payment-links']">Payment Links</a>
          <a [routerLink]="['/patient-invites']">Manage Invites</a>
          <a [routerLink]="['/patient-followups']">Followups</a>
          <a [routerLink]="['/get-started-sessions']">GSWT Sessions</a>
          <a [routerLink]="['/booking-started']">Booking Started</a>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="card p-2">
        <div class="dropbtn" [id]="'others'+'_btn'" (click)="toggle('others')">
          Others
          <img class="dropbtn-icon" src="https://docvita-icons.s3.ap-south-1.amazonaws.com/icons/chevron_down_2x.svg" />
        </div>
        <div class="content" [id]="'others'+'_content'">
          <a [routerLink]="['/image-to-webp']">WebP Converter</a>
          <a [routerLink]="['/outgoing-calls']">Outgoing Calls</a>
        </div>
      </div>
    </div>
    <div class="item" *ngIf="currentHeroInfo&&currentHeroInfo.hasSpecialAccess">
      <div class="card p-2">
        <div class="dropbtn" [id]="'founders'+'_btn'" (click)="toggle('founders')">
          Founders
          <img class="dropbtn-icon" src="https://docvita-icons.s3.ap-south-1.amazonaws.com/icons/chevron_down_2x.svg" />
        </div>
        <div class="content" [id]="'founders'+'_content'">
          <a [routerLink]="['/retention-dashboard']">Retention Dashboard</a>
          <a [routerLink]="['/provider-data/dv-appts-data']">Monthly Sheet & Perf Sheet Import</a>
          <a [routerLink]="['/provider-data/reports']">Download CSV Reports</a>
          <a [routerLink]="['/stats/booking-pattern']">Booking Pattern</a>
          <a [routerLink]="['/stats/merge-patient-profiles']">Merge Patient Profile</a>
          <a [routerLink]="['/crm-user-list']">HQ User List</a>
          <a [routerLink]="['/provider-client-relationship']">Provider Client Relationship</a>
        </div>
      </div>
    </div>
  </div>
</div>