<div class="w-100 mb-5 p-2">
    <div class="w-100 d-flex mb-4 align-items-center">
      <h2 class="mt-3">Fee Config</h2>
      <button type="button" class="btn btn-sm btn-primary btn-sm ms-auto"[disabled]="updateLoader" (click)="updateProviderFeeConfig()"> 
        <span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true" *ngIf="updateLoader"></span>
        Save changes 
      </button>
    </div>
  
    <div class="w-100 d-flex flex-column gap-2">
        <div class="d-flex align-items-center gap-2" style="height: 45px;" *ngFor="let item of serviceTypeOptions">
            <div class="form-check m-0" style="width: 150px;">
              <input class="form-check-input" type="checkbox" [id]="'check_'+item" [(ngModel)]="serviceWisePlainFeesMap[item]['isActive']">
              <label class="form-check-label ms-2" [for]="'check_'+item">
                {{item | titlecase}} ? 
              </label>
            </div>

            <ng-container *ngIf="serviceWisePlainFeesMap[item]['isActive']">
                <input type="number" class="form-control" style="width: 200px;" min="0" [placeholder]="item+' '+'fee'" [(ngModel)]="serviceWisePlainFeesMap[item]['fee']">
                <span> INR </span>
            </ng-container>
        </div>

        <div class="form-check m-0 mt-2">
            <input class="form-check-input" type="checkbox" id="check_queer_affirmative" [(ngModel)]="queerAffirmative">
            <label class="form-check-label ms-2" for="check_queer_affirmative">
              is Queer Affirmative ? 
            </label>
        </div>
        <div class="form-check m-0 mt-2">
            <input class="form-check-input" type="checkbox" id="check_gswt" [(ngModel)]="listInGSWT">
            <label class="form-check-label ms-2" for="check_gswt">
              show in GSWT ? 
            </label>
        </div>
    </div>

    <!-- <h5 class="text-muted" *ngIf="!isPsychologist"> Multi-Service Type fees are only supported by Psychologists</h5> -->
</div>