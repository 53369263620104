import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DayjsHelper } from '../../models-ts/Helpers/dayjsHelper';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault('Asia/Kolkata');
@Component({
  selector: 'date-picker-v2',
  templateUrl: './date-picker-v2.component.html',
  styleUrls: ['./date-picker-v2.component.scss']
})
export class DatePickerV2Component implements OnInit {
  // toDateVal: Date = new Date();

  // @Input() set defaultDate(val){
  //   this.toDateVal = val;
  // }

  // @Output() dateVal:EventEmitter<Date> =  new EventEmitter<Date>();
  
  constructor() { }

  ngOnInit(): void {
  }

  // get dayJsHelper(){
  //   return new DayjsHelper();
  // }

  // setSelectedDate(event) {
  //   if (event) {
  //     this.toDateVal = event;
  //     this.dateVal.emit(event)
  //   }
  // }

  // changeDate(val: number) {
  //   if(val>0){
  //     // this.toDateVal = dayjs(this.toDateVal).add(1,'day')
  //   }
  // }

}
