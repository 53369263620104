<div class="offcanvas offcanvas-end" style="width: 700px!important;" tabindex="-1"
    id="offcanvas-for-membership-plan-modify" aria-labelledby="offcanvasRightLabel">
    <div class="offcanvas-header">
        <div class="d-flex flex-column">
            <h6>
                {{title}}
            </h6>
            <button (click)="save()" class="btn btn-sm btn-primary">Save</button>
        </div>
        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body" style="overflow-y: auto;">
        <div class="d-flex mb-2">
            <span class="field-label">
                Validity:
            </span>
            <input type="number" class="ms-2 form-control" [(ngModel)]="plan.validity" placeholder="(in days)">
        </div>
        <div class="d-flex mb-2">
            <span class="field-label">
                No of sessions:
            </span>
            <input type="number" class="ms-2 form-control" [(ngModel)]="plan.noOfSessions" placeholder="# sessions">
        </div>
        <div class="d-flex mb-2">
            <span class="field-label">
                Session Duration:
            </span>
            <input type="number" class="ms-2 form-control" [(ngModel)]="plan.sessionDurationInMinutes" placeholder="duration (in minutes)">
        </div>
        <div class="d-flex mb-2">
            <span class="field-label">
                Session type:
            </span>
            <ng-select id="planSessionType" [(ngModel)]="plan.sessionType" class="w-100"
                (ngModelChange)="selectedSessionTypeFunc($event)" name="sessiontype">
                <ng-option *ngFor="let c of sessionTypes" [value]="c">{{ c }}</ng-option>
            </ng-select>
        </div>
        <div class="d-flex mb-2">
            <div class="form-check form-switch w-50">
                <input type="checkbox" class="form-check-input" id="customSwitch6" [(ngModel)]="plan.isMostPopular" />
                <label class="form-check-label ms-2" for="customSwitch6">Most popular</label>
            </div>
        </div>
        <div class="d-flex mb-2">
            <div class="form-check form-switch w-50">
                <input type="checkbox" class="form-check-input" id="customSwitch7"  [(ngModel)]="plan.isEmailSupportOn"/>
                <label class="form-check-label ms-2" for="customSwitch7">Email support</label>
            </div>
        </div>
        <div class="d-flex mb-2">
            <div class="form-check form-switch w-50">
                <input type="checkbox" class="form-check-input" id="customSwitch8"  [(ngModel)]="plan.isCareManagerSupportOn"/>
                <label class="form-check-label ms-2" for="customSwitch8">Care Management Support</label>
            </div>
        </div>
        <div class="d-flex flex-column">
            <div class="d-flex justify-content-between">
                <span>Pricing</span>
                <button class="btn btn-sm btn-primary" (click)="addNewCountry()">Add New Country</button>
            </div>
            <!-- India Pricing -->
            <div class="mb-2">
                <ng-container
                    *ngTemplateOutlet="countrywisePricingItemTemplate; context: {item: indiaPricingItem, deletionAllowed: false}">
                </ng-container>
            </div>
            <!-- Default international pricing in INR -->
            <div class="mb-2">
                <ng-container
                    *ngTemplateOutlet="countrywisePricingItemTemplate; context: {item: defaultInternationalPricingItem, deletionAllowed: false}">
                </ng-container>
            </div>
            <!-- Add other country pricing -->
            <div *ngFor="let item of countrywisePricingArr; let i=index;">
                <ng-container
                    *ngTemplateOutlet="countrywisePricingItemTemplate; context: {item: item, idx: i, deletionAllowed: true}">
                </ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #countrywisePricingItemTemplate let-item="item" let-idx="idx" let-deletionAllowed="deletionAllowed">
    <div class="d-flex flex-column mb-2">
        <div class="d-flex flex-row">
            <!-- <span *ngIf="idx != null" class="me-2">{{idx+1}}</span> -->
            <div style="width: 200px!important">
                <ng-select id="country" [(ngModel)]="item.country" class="w-100 ms-2" name="country">
                    <ng-option *ngFor="let c of countries" [value]="c.code">{{ c.name }}</ng-option>
                </ng-select>
            </div>
            <ng-select id="currencySelection" style="width: 100px;" [(ngModel)]="item.currency" class="w-100"
                name="currency">
                <ng-option *ngFor="let c of currencies" [value]="c">{{ c }}</ng-option>
            </ng-select>
        </div>
        <div class="d-flex flex-row mt-1">
            <input type="number" class="form-control ms-1" [(ngModel)]="item.listPrice" placeholder="list price">
            <input type="number" class="form-control ms-1" [(ngModel)]="item.sellingPrice" placeholder="selling price">
            <input type="number" class="form-control ms-1" [(ngModel)]="item.additionalSessionCost" placeholder="additional session cost">
            <input type="number" class="form-control ms-1" [(ngModel)]="item.sellingPricePerSessionInINR" placeholder="sellingPricePerSessionInINR">
        </div>
        <button *ngIf="deletionAllowed" (click)="removeCountrywisePricingItem(idx)" style="width: fit-content;"
            class="btn btn-sm btn-danger my-1"> Remove </button>
    </div>

</ng-template>