<button
  class="btn btn-sm btn-outline-primary border-0 d-flex justify-content-center align-items-center"
  *ngIf="showButton"
  style="border-width: 1px"
  (click)="openModal(patientReg)"
>
  <!-- <i style="font-size: 15px" class="material-icons me-1">
        confirmation_number
    </i> -->
  Edit Profile
</button>

<ng-template #patientReg let-modal>
  <div class="modal-header">
    <h4 class="modal-title" *ngIf="patientProfile && patientProfile.docvitaId">
      Update Patient Information
    </h4>
    <h4
      class="modal-title"
      *ngIf="!patientProfile || !patientProfile.docvitaId"
    >
      Patient Registration
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closeModal()"
      style="outline: none"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body d-flex flex-column justify-content-center w-100">
    <!-- get patient -->
    <div *ngIf="bookingSteps == 1">
      <div class="d-flex w-100 mb-3">
        <h5>Choose Patient</h5>
        <button
          class="btn btn-sm btn-info ms-auto"
          (click)="setStep(2)"
          *ngIf="bookingSteps == 1"
        >
          + New Patient
        </button>
      </div>
      <app-patient-search
        [listMode]="true"
        [searchText]="textForPatientSearch"
        (getPatient)="setPatient($event)"
      >
      </app-patient-search>
      <div
        class="card rounded p-2 mt-4"
        *ngIf="leadDetails && leadDetails.patientName"
      >
        <div class="d-flex w-100">
          <h5>Current Lead Details:</h5>
        </div>
        <hr class="m-1 p-0" />
        <div>
          <div>
            <span style="font-weight: 500">Lead Name:</span>
            {{ leadDetails.patientName ? leadDetails.patientName : "-" }}
          </div>
          <div>
            <span style="font-weight: 500">Lead Contact:</span>
            {{
              leadDetails.patientContactInfo
                ? "+" +
                  leadDetails.patientContactInfo.countryCode +
                  "-" +
                  leadDetails.patientContactInfo.number
                : ""
            }}
          </div>
        </div>
      </div>
    </div>

    <!-- create new patient -->
    <div *ngIf="bookingSteps == 2 || bookingSteps == 3">
      <div class="d-flex align-items-center mb-4">
        <button
          class="btn btn-sm btn-outline-dark border-0 me-2"
          *ngIf="showNewPatientBackButton"
          (click)="setStep(1)"
        >
          ❮
        </button>
        <h4>Patient Info</h4>
      </div>
      <div>
        <div class="d-flex w-100">
          <div class="form-group w-50">
            <label>Name*</label>
            <input
              type="text"
              class="form-control"
              placeholder="Patient name"
              [(ngModel)]="newPatientForm['name']"
              required
            />
          </div>
          <div class="form-group w-50 ms-2">
            <label>Gender</label>
            <ng-select
              class="w-100"
              placeholder="Select gender"
              [(ngModel)]="newPatientForm['gender']"
            >
              <ng-option [value]="'male'">Male</ng-option>
              <ng-option [value]="'female'">Female</ng-option>
            </ng-select>
          </div>
        </div>

        <div class="d-flex flex-column card p-1 form-group">
          <div class="w-100 d-flex mb-1">
            <div>Contact(s)</div>
            <div class="ms-auto d-flex align-items-center">
              <div
                *ngIf="notifyContactNotSaved"
                class="text-warning"
                style="font-size: 0.8em"
              >
                * Update to save contacts in multiple contacts collection
              </div>
              <div (click)="addNewContact()" class="text-primary plus">+</div>
            </div>
          </div>
          <div
            *ngFor="let item of newPatientForm['contacts']; let i = index"
            class="d-flex flex-column"
          >
            <div class="d-flex align-items-center mt-1">
              <div style="font-size: 1em; font-weight: 500" class="ms-1">
                {{ i + 1 }}.
              </div>
              <input
                type="number"
                class="form-control me-1 ms-2"
                style="width: 5rem; padding: 0.5rem"
                placeholder=""
                [(ngModel)]="item['countryCode']"
                required
              />
              <input
                type="number"
                class="form-control w-75"
                (input)="setNumberToSub($event, i)"
                placeholder="Contact number"
                required
                [(ngModel)]="item['number']"
                required
              />
              <div
                class="btn btn-outline-danger border-0 ms-1"
                (click)="removeContact(i)"
              >
                &times;
              </div>
            </div>
            <div style="font-size: 0.9em" class="ms-4 mt-1">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  [(ngModel)]="item['isPrimaryContactNumber']"
                />
                <label class="form-check-label ms-1"
                  >isPrimaryContactNumber</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  [(ngModel)]="item['isWhatsappContactNumber']"
                />
                <label class="form-check-label ms-1"
                  >isWhatsappContactNumber</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  [(ngModel)]="item['whatsappCommunicationOn']"
                />
                <label class="form-check-label ms-1"
                  >whatsappCommunicationOn</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  [(ngModel)]="item['smsCommunicationOn']"
                />
                <label class="form-check-label ms-1">smsCommunicationOn</label>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex flex-column card p-1 form-group">
          <div class="w-100 d-flex mb-1">
            <div>Email(s)</div>
            <div class="ms-auto d-flex align-items-center">
              <div
                *ngIf="notifyEmailNotSaved"
                class="text-warning"
                style="font-size: 0.8em"
              >
                * Update to save emails in multiple emails collection
              </div>
              <div (click)="addNewEmail()" class="text-primary ms-auto plus">
                +
              </div>
            </div>
          </div>
          <div
            *ngFor="let item of newPatientForm['emails']; let i = index"
            class="d-flex flex-column"
          >
            <div class="d-flex align-items-center mt-1">
              <div style="font-size: 1em; font-weight: 500" class="ms-1">
                {{ i + 1 }}.
              </div>
              <input
                type="email"
                class="form-control ms-2 w-75"
                placeholder="Email"
                [(ngModel)]="item['email']"
              />
              <div
                class="btn btn-outline-danger border-0 ms-1"
                (click)="removeEmail(i)"
              >
                &times;
              </div>
            </div>
            <div style="font-size: 0.9em" class="ms-4 mt-1">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  [(ngModel)]="item['isPrimary']"
                />
                <label class="form-check-label ms-1">isPrimaryEmail</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  [(ngModel)]="item['emailCommunicationOn']"
                />
                <label class="form-check-label ms-1"
                  >emailCommunicationOn</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="setStep(3)"
      *ngIf="bookingSteps == 1"
      [hidden]="patientProfile?.docvitaId == undefined"
    >
      Update Selection ➔
    </button>
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="createPatient()"
      *ngIf="bookingSteps == 2"
    >
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
        *ngIf="bookingLoader"
      ></span>
      Create Patient Profile ➔
    </button>
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="createPatient()"
      *ngIf="bookingSteps == 3"
    >
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
        *ngIf="bookingLoader"
      ></span>
      Update Patient Profile ➔
    </button>
  </div>
</ng-template>
