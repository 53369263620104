<div class="d-flex justify-content-between align-items-center border-bottom" style="padding: 0.5rem 1rem" [ngClass]="{'bg-white': bgWhite}">
    <div class="me-1 d-flex align-items-center" (click)="back()">
        <i class="las la-arrow-circle-left __back_button me-2" style="font-size: 2.3em" routerLinkActive="router-link-active"></i>
        <img src="https://s3.ap-south-1.amazonaws.com/docvita.com/docvita_logo_transparent_bg.png" alt="logo" class="__navbarLogoStyles" />
    </div>

    <h1 class="mb-0" style="font-size: 25px" [innerHtml]="title"></h1>

    <div class="d-flex flex-column">
        <div class="d-flex flex-column ms-auto" style="color: rgb(105, 105, 105); font-size: 0.9em">
            <span>
        Current User:
        <b *ngIf="currentHeroInfo.name">{{ currentHeroInfo.name }}</b></span
      >
      <div
        class="__logoutLink ms-auto"
        style="font-size: 0.8em"
        (click)="logoutCurrentUser()"
      >
        Logout?
      </div>
    </div>
  </div>
</div>