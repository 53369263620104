import { Expose } from "class-transformer";

export class AgeObject {

  @Expose() 
  saveOn: number; //Millis

  @Expose()
  years: number;

  @Expose()
  months: number;

  @Expose()
  days: number;
}
