import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault('Asia/Kolkata');

export interface DateInfo{
  startDate:Date;
  endDate:Date;
  startDateMillis:number;
  endDateMillis:number;
}

@Component({
  selector: 'quick-date-dropdown',
  templateUrl: './quick-date-dropdown.component.html',
  styleUrls: ['./quick-date-dropdown.component.scss']
})
export class QuickDateDropdownComponent implements OnInit {

  @Output() dateVal:EventEmitter<DateInfo> = new EventEmitter<DateInfo>();

  @Input() set selectedOption(val:string){
    this.selectOption(val)
  };
  get selectedOption(){
    return this._selectedOption;
  }
  _selectedOption:string = "Select Date";

  dateOptions:any = {
    'today':'Today',
    'yesterday':'Yesterday',
    'last-24-hr':'Last 24 Hrs',
    'last-2-days':'Last 2 days',
    'last-3-days':'Last 3 days'
  }

  constructor() { }

  ngOnInit(): void {
  }
  returnZro(){
    return 0;
  }

  selectOption(key:string){
    if(this.dateOptions[key]){
      this._selectedOption = this.dateOptions[key];
    }
    switch (key) {
      case 'today':{
        const today = dayjs().format('YYYY-MM-DD');
        const date:DateInfo = {
          startDate: dayjs(today+' '+'00:00:00').toDate(),
          endDate: dayjs(today+' '+'23:59:00').toDate(),
          startDateMillis: dayjs(today+' '+'00:00:00').toDate().getTime(),
          endDateMillis: dayjs(today+' '+'23:59:00').toDate().getTime()
        }
        this.dateVal.emit(date)
        break;
      }
        
      case 'yesterday':{
        const yesterday = dayjs().subtract(1,'day').format('YYYY-MM-DD');
        const date:DateInfo = {
          startDate: dayjs(yesterday+' '+'00:00:00').toDate(),
          endDate: dayjs(yesterday+' '+'23:59:00').toDate(),
          startDateMillis: dayjs(yesterday+' '+'00:00:00').toDate().getTime(),
          endDateMillis: dayjs(yesterday+' '+'23:59:00').toDate().getTime()
        }
        this.dateVal.emit(date)
        break;
      }
      
      case 'last-24-hr':{
        const _24hr = dayjs().subtract(24,'hour').format('YYYY-MM-DD');
        const today = dayjs().format('YYYY-MM-DD');
        const date:DateInfo = {
          startDate: dayjs(_24hr+' '+'00:00:00').toDate(),
          endDate: dayjs(today+' '+'23:59:00').toDate(),
          startDateMillis: dayjs(_24hr+' '+'00:00:00').toDate().getTime(),
          endDateMillis: dayjs(today+' '+'23:59:00').toDate().getTime()
        }
        this.dateVal.emit(date)
        break;
      }
      
      case 'last-2-days':{
        const _2days = dayjs().subtract(2,'day').format('YYYY-MM-DD');
        const today = dayjs().format('YYYY-MM-DD');
        const date:DateInfo = {
          startDate: dayjs(_2days+' '+'00:00:00').toDate(),
          endDate: dayjs(today+' '+'23:59:00').toDate(),
          startDateMillis: dayjs(_2days+' '+'00:00:00').toDate().getTime(),
          endDateMillis: dayjs(today+' '+'23:59:00').toDate().getTime()
        }
        this.dateVal.emit(date)
        break;
      }
      
      case 'last-3-days':{
        const _3days = dayjs().subtract(3,'day').format('YYYY-MM-DD');
        const today = dayjs().format('YYYY-MM-DD');
        const date:DateInfo = {
          startDate: dayjs(_3days+' '+'00:00:00').toDate(),
          endDate: dayjs(today+' '+'23:59:00').toDate(),
          startDateMillis: dayjs(_3days+' '+'00:00:00').toDate().getTime(),
          endDateMillis: dayjs(today+' '+'23:59:00').toDate().getTime()
        }
        this.dateVal.emit(date)
        break;
      }

      default:{
        this.dateVal.emit(null)
        break;
      }
    }
  }

}
