import { Injectable } from '@angular/core';
import { classToPlain, plainToClass } from 'class-transformer';
import { User } from '../../models-ts/Actors/User/User';
import firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { CRMUser } from '../../models-ts/Actors/User/CRMUser';
@Injectable({
  providedIn: 'root',
})
export class AuthUserInfoService {
  constructor(private _toastrService: ToastrService, private router: Router) {}

  saveUserInfo(user: CRMUser) {
    localStorage.setItem('loggedInUser', JSON.stringify(classToPlain(user)));
  }

  getUserInfo(): CRMUser {
    return plainToClass(CRMUser, JSON.parse(localStorage.getItem('loggedInUser')),{excludeExtraneousValues: false});
  }

  getHealthHeroProfile(id: string) {
    firebase
      .firestore()
      .collection('crm-users')
      .doc(id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          let user = plainToClass(CRMUser, doc.data(), {
            excludeExtraneousValues: false,
          });
          this.saveUserInfo(user);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  isUserSignedIn() {
    if (firebase.auth().currentUser != null) {
      return true;
    } else if (this.getUserInfo() != null && this.getUserInfo().suspended) {
      this.signout();
      return false;
    }else if (this.getUserInfo() != null) {
      return true;
    }
    return false;
  }

  signout() {
    firebase.auth().signOut();
    localStorage.removeItem('loggedInUser');
    this.router.navigate(['auth']);
    this._toastrService.success('Logged out successfully');
  }
}
