<ng-template #webviewComponentModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title">You are browsing...</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()" style="outline: none;">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body d-flex flex-column justify-content-center w-100">
        <a target="_blank" [href]="url">{{url}}</a>
        <span *ngIf="isIFrameLoading" class="m-2">Loading...</span>
        <iframe frameBorder="0" [src]="url | safe" height="500" (load)="iframeLoaded()" ></iframe>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="closeModal()">
        Close
    </button>
    </div>
</ng-template>