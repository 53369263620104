import { Schedule } from "../../Entities/Schedule";
import { DoctorListingProfile } from "./DoctorListingProfile";
import { PracticeListingProfile } from "../../Entities/PracticeListingProfile";
import { ContactNumber } from "../Person/ContactNumber";
import * as dayjs from "dayjs";
import * as timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);
import * as utc from "dayjs/plugin/utc";
import { DoctorShortProfile } from "./DoctorShortProfile";
import { classToPlain, plainToClass } from "class-transformer";
import { reasonOfVisitSuggestions } from "../../constants/reasonOfVisitSuggestions";
import {
	masterSpecializationConversion,
	masterSpecializationConversionDisplayValue,
} from "../../Helpers/masterSpecializationMap";
import { User } from "../User/User";
dayjs.extend(utc);
dayjs.tz.setDefault("Asia/Kolkata");

export function getOfflineConsultationTimings(
	practiceId: string,
	listingProfile: DoctorListingProfile
) {
	let offlineTimingArray = [];
	let videoCallSchedule: Schedule[] = [];
	let videoCallTimingObject: any = {};
	if (listingProfile.schedule != null) {
		for (let item of listingProfile.schedule) {
			if (
				item.availableAtPractices != null &&
				item.availableAtPractices.get(practiceId) == true
			) {
				videoCallSchedule.push(item);
			}
		}
		videoCallSchedule.sort((a, b) => {
			return a.getSortingKey() - b.getSortingKey();
		});
		//get all the days
		for (let item of videoCallSchedule) {
			videoCallTimingObject[item.getDaysDisplayString()] = [];
		}
		//push the timingArray to days
		for (let item of videoCallSchedule) {
			videoCallTimingObject[item.getDaysDisplayString()].push(
				convertHHMMTOHHMMAA(item.startTime) +
				" - " +
				convertHHMMTOHHMMAA(item.endTime)
			);
		}

		//sort the timingArray
		for (let item in videoCallTimingObject) {
			videoCallTimingObject[item] = videoCallTimingObject[item].sort(
				(a: any, b: any) => {
					if (a != null && b != null) {
						return convertHHMMAATOHHMM(b.substr(0, 8)).localeCompare(
							convertHHMMAATOHHMM(a.substr(0, 8))
						);
					}
					return 0;
				}
			);
		}

		for (let d of Object.keys(videoCallTimingObject)) {
			let obj: any = {};
			obj["day"] = d;
			obj["time"] = videoCallTimingObject[d];
			offlineTimingArray.push(obj);
		}
	}
	return offlineTimingArray;
}

export function getOnlineConsultationTimings(
	listingProfile: DoctorListingProfile
) {
	let videoTimingArray = [];
	if (listingProfile.isAvailableOnVideoCall()) {
		let videoCallSchedule: Schedule[] = listingProfile.videoCallSchedule();
		let videoCallTimingObject: any = {};
		videoCallSchedule.sort((a, b) => {
			return a.getSortingKey() - b.getSortingKey();
		});
		//get all the days
		for (let item of videoCallSchedule) {
			videoCallTimingObject[item.getDaysDisplayString()] = [];
		}
		//push the timingArray to days
		for (let item of videoCallSchedule) {
			videoCallTimingObject[item.getDaysDisplayString()].push(
				convertHHMMTOHHMMAA(item.startTime) +
				" - " +
				convertHHMMTOHHMMAA(item.endTime)
			);
		}

		//sort the timingArray
		for (let item in videoCallTimingObject) {
			videoCallTimingObject[item] = videoCallTimingObject[item].sort(
				(a: any, b: any) => {
					if (a != null && b != null) {
						return convertHHMMAATOHHMM(b.substr(0, 8)).localeCompare(
							convertHHMMAATOHHMM(a.substr(0, 8))
						);
					}
					return 0;
				}
			);
		}

		for (let d of Object.keys(videoCallTimingObject)) {
			let obj: any = {};
			obj["day"] = d;
			obj["time"] = videoCallTimingObject[d];
			videoTimingArray.push(obj);
		}
	}
	return videoTimingArray;
}

export function extractShortProfile(full: DoctorListingProfile) {
	let short: DoctorShortProfile = new DoctorShortProfile();
	short = plainToClass(DoctorShortProfile, classToPlain(full));
	short.providerFullName = full.fullName();
	short.onlineFees = full.getOnlineFees();
	short.isPsychologist = full.isPsychologist;
	short.instantconsultFees = full.getInstantconsultFees();
	short.instantconsultFeesListedPrice = full.getInstantconsultFeesListedPrice();
	short.isInstantBookingOn = full.isInstantBookingOn();
	short.spokenLanguagesSeparatedByWithComma =
		full.getSpokenLanguagesSeparatedByComma();
	short.profileLink =
		full.getProfileLink();
	short.specializationDisplay = full.displaySpecializationsText();
	short.masterSpecialization = full.getMasterSpecialization();
	short.isAcceptingOnlineBookingsOnDocVita =
		full.isAcceptingBookingsOnDocVita();
	short.isAcceptingInPersonBookingsOnDocVita =
		full.isAcceptingInPersonBookingsOnDocVita();
	short.reasonsOfVisitDisplayArray = [];
	if (full.reasonsOfVisit) {
		short.reasonsOfVisit = full.reasonsOfVisit;
		full.reasonsOfVisit.forEach((val) => {
			let extractedReasonObj = reasonOfVisitSuggestions.find(
				(v) => v.urlPath == val.urlPath
			);
			if (extractedReasonObj) {
				for (let r of extractedReasonObj.reason) {
					short.reasonsOfVisitDisplayArray.push(r);
				}
			}
		});
		short.reasonsOfVisitDisplayArray = short.reasonsOfVisitDisplayArray.sort(
			(a, b) => {
				return a.localeCompare(b);
			}
		);
	}
	return short;
}

export function updateFeeInProfile(optionsMap: any, profile: DoctorListingProfile) {
	let listingProfile: any = classToPlain(profile);
	if (optionsMap) {
		if (listingProfile && listingProfile['onlineConsultationConfig'] 
			&& listingProfile['onlineConsultationConfig']['servicewiseFeesMap']) {
			const map = listingProfile['onlineConsultationConfig']['servicewiseFeesMap'];
			for (let service in map) {
				if (optionsMap && optionsMap[service] && listingProfile['onlineConsultationConfig']['servicewiseFeesMap'][service]) {
					listingProfile['onlineConsultationConfig']['servicewiseFeesMap'][service]['fee'] = optionsMap[service]['amount'];
					listingProfile['onlineConsultationConfig']['servicewiseFeesMap'][service]['currency'] = optionsMap[service]['currency'];
				}
			}
		}
	}
	return plainToClass(DoctorListingProfile,listingProfile);
}

function convertHHMMTOHHMMAA(str: string) {
	return dayjs(
		dayjs(new Date()).format("YYYY-MM-DD") + " " + str
	).format("hh:mm a");
}

function convertHHMMAATOHHMM(str: string) {
	let hrs = +str.substr(0, 2);
	let min = +str.substr(3, 5);
	let p = str.substr(6, 8);
	if (p.toLowerCase().includes("pm")) {
		hrs += 12;
	}
	let d = new Date();
	d.setHours(hrs);
	d.setMinutes(min);
	let val = dayjs(d).format("HH:mm");
	return val;
}

export function getFirstName(doctorProfile: DoctorListingProfile): string {
	let str;
	if (doctorProfile.name != null) {
		let name = doctorProfile.name;
		if (name.includes(".") && !name.includes(". ")) {
			name = name.split(".").join(". ");
		}
		let arr = name.split(" ");
		let trimmedArr: string[] = [];
		for (let a of arr) {
			trimmedArr.push(a.trim());
		}
		for (let t of trimmedArr) {
			if (
				str == null &&
				t.length > 2 &&
				!t.includes("(") &&
				!t.includes(")") &&
				!t.includes(".")
			) {
				str = t;
			}
		}
	}
	return str;
}

export function getLastName(doctorProfile: DoctorListingProfile): string {
	let str;
	if (doctorProfile.name != null) {
		let name = doctorProfile.name;
		if (name.includes(".") && !name.includes(". ")) {
			name = name.split(".").join(". ");
		}
		let arr = name.split(" ");
		let trimmedArr: string[] = [];
		for (let a of arr) {
			trimmedArr.push(a.trim());
		}
		for (let t of trimmedArr.reverse()) {
			if (
				str == null &&
				t.length > 2 &&
				!t.includes("(") &&
				!t.includes(")") &&
				!t.includes(".")
			) {
				str = t;
			}
		}
	}
	return str;
}

export function getMasterSpecialization(
	doctorProfile: DoctorListingProfile
): string {
	let str = "";
	let arr = doctorProfile.displaySpecializations();
	if (arr.length > 0) {
		let tempArr = masterSpecializationConversion(arr);
		// console.log("tempArr",tempArr);
		if (tempArr.length > 0) {
			str = tempArr[0];
		} else if (arr.length > 0) {
			str = arr[0];
		}
	}
	return str;
}

export function getMasterSpecializationDisplayValue(
	doctorProfile: DoctorListingProfile
): string {
	let str = "";
	let arr = doctorProfile.displaySpecializations();
	if (arr.length > 0) {
		let tempArr = masterSpecializationConversionDisplayValue(arr);
		// console.log("tempArr Display",tempArr);
		if (tempArr.length > 0) {
			str = tempArr[0];
		} else if (arr.length > 0) {
			str = arr[0];
		}
	}
	return str;
}

export function getPracticesWithInPersonConsultationBookingOn(
	doctorProfile: DoctorListingProfile
): PracticeListingProfile[] {
	let arr: PracticeListingProfile[] = [];
	if (doctorProfile.practices != null) {
		for (let p of doctorProfile.practices) {
			if (doctorProfile.isAcceptingBookingsOnDocVita(p.id)) {
				arr.push(p);
			}
		}
	}
	return arr;
}

export function displayTextForPracticesWithInPersonConsultationBookingOn(
	doctorProfile: DoctorListingProfile,
	connectorWord?: string
): string {
	if (connectorWord == null) {
		connectorWord = "and";
	}
	let arr = getPracticesWithInPersonConsultationBookingOn(doctorProfile);
	let str = "";
	if (arr.length > 0) {
		for (let i = 0; i < arr.length; i++) {
			if (i == 0) {
				str += arr[i].name;
			} else if (i == arr.length - 1) {
				str += " and " + arr[i].name;
			} else {
				str += ", " + arr[i].name;
			}
		}
	}
	return str;
}

export function getPracticesWithInPersonConsultationBookingOff(
	doctorProfile: DoctorListingProfile
): PracticeListingProfile[] {
	let arr: PracticeListingProfile[] = [];
	if (doctorProfile.practices != null) {
		for (let p of doctorProfile.practices) {
			if (!doctorProfile.isAcceptingBookingsOnDocVita(p.id)) {
				arr.push(p);
			}
		}
	}
	return arr;
}

export function displayTextForPracticesWithInPersonConsultationBookingOff(
	doctorProfile: DoctorListingProfile,
	connectorWord?: string
): string {
	if (connectorWord == null) {
		connectorWord = "and";
	}
	let arr = getPracticesWithInPersonConsultationBookingOff(doctorProfile);
	let str = "";
	if (arr.length > 0) {
		for (let i = 0; i < arr.length; i++) {
			if (i == 0) {
				str += arr[i].name;
			} else if (i == arr.length - 1) {
				str += " " + connectorWord + " " + arr[i].name;
			} else {
				str += ", " + arr[i].name;
			}
		}
	}
	return str;
}

export function getExtension(
	contactNumber: ContactNumber,
	doctorProfile: DoctorListingProfile
): string {
	let ext: string = null;
	doctorProfile.callExtensions.forEach((extObj) => {
		if (
			contactNumber.countryCode == extObj.countryCode &&
			contactNumber.number == extObj.number
		) {
			ext = extObj.ext;
		}
	});
	return ext;
}

export function getAllContactNumbers(doctorProfile: DoctorListingProfile) {
	let arr: ContactNumber[] = [];
	if (doctorProfile.contactNumbers) {
		doctorProfile.contactNumbers.forEach((val) => {
			val.forEach((c) => {
				arr.push(c);
			});
		});
	}
	if (
		doctorProfile.primaryContactNumber &&
		doctorProfile.primaryContactNumber.number
	) {
		let number = doctorProfile.primaryContactNumber;
		let numberComp = arr.filter((da) => da && da.number == number.number);
		if (numberComp && numberComp.length == 0) {
			arr.push(doctorProfile.primaryContactNumber);
		}
	}
	return arr;
}

export function getAllWhatsAppContactNumbers(
	doctorProfile: DoctorListingProfile
) {
	let arr: ContactNumber[] = [];
	if (doctorProfile.contactNumbers) {
		doctorProfile.contactNumbers.forEach((val) => {
			val.forEach((c) => {
				if (c.isWhatsappContactNumber) arr.push(c);
			});
		});
	}
	return arr;
}

export function getContactNumbersWithExtension(
	doctorProfile: DoctorListingProfile
) {
	let arr: ContactNumber[] = [];
	if (doctorProfile.contactNumbers) {
		doctorProfile.contactNumbers.forEach((val) => {
			val.forEach((c) => {
				let ext = getExtension(c, doctorProfile);
				if (ext) {
					c.ext = ext;
				}
				arr.push(c);
			});
		});
	}
	return arr;
}

export function getPublicContactNumbers(doctorProfile: DoctorListingProfile) {
	let arr: ContactNumber[] = [];
	if (doctorProfile.contactNumbers) {
		doctorProfile.contactNumbers.forEach((val) => {
			val.forEach((c) => {
				if (c.isPublicContactNumber) {
					arr.push(c);
				}
			});
		});
	}
	return arr;
}

export function getPrimaryContactNumbers(doctorProfile: DoctorListingProfile) {
	let arr: ContactNumber[] = [];
	if (doctorProfile.contactNumbers) {
		doctorProfile.contactNumbers.forEach((val) => {
			val.forEach((c) => {
				if (c.isPrimaryContactNumber) {
					arr.push(c);
				}
			});
		});
	}
	return arr;
}

export function getPublicContactNumbersWithExtension(
	doctorProfile: DoctorListingProfile
) {
	let pubNumberArr = getPublicContactNumbers(doctorProfile);
	let arr: ContactNumber[] = [];
	if (doctorProfile.callExtensions) {
		doctorProfile.callExtensions.forEach((extObj) => {
			pubNumberArr.forEach((c) => {
				if (c.countryCode == extObj.countryCode && c.number == extObj.number) {
					c.ext = extObj.ext;
					arr.push(c);
				}
			});
		});
	}
	return arr;
}

export function specializationDisplayText(val: string) {
	switch (val) {
		case "ent-specialist": {
			return "ENT specialist";
		}
		default: {
			let array = val.split("-");
			let str = "";
			for (let a of array) {
				if (str.length == 0) {
					str += a.substr(0, 1).toUpperCase() + a.substr(1);
				} else {
					str += " " + a.substr(0, 1).toUpperCase() + a.substr(1);
				}
			}
			return str;
		}
	}
}

export function getPublicContactNumbersWithoutExtension(
	doctorProfile: DoctorListingProfile
) {
	let pubNumberArr = getPublicContactNumbers(doctorProfile);
	let arr: ContactNumber[] = [];
	if (doctorProfile.callExtensions) {
		pubNumberArr.forEach((c) => {
			let hasExtension = false;
			doctorProfile.callExtensions.forEach((extObj) => {
				if (
					c.countryCode == extObj.countryCode &&
					c.number == extObj.number &&
					!hasExtension
				) {
					hasExtension = true;
				}
			});
			if (!hasExtension) arr.push(c);
		});
	}
	return arr;
}
