import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppointmentInvite } from '../../models-ts/Entities/AppointmentInvite/AppointmentInvite';
import { classToPlain } from 'class-transformer';
import * as urls from '../../../../environments/environment';
import { User } from '../../models-ts/Actors/User/User';
import firebase from 'firebase';
import 'firebase/firestore';
import { CRMUser } from '../../models-ts/Actors/User/CRMUser';
@Injectable({
  providedIn: 'root',
})
export class AppointmentInviteService {
  constructor(private _httpHelper: HttpClient) {}
  getInvites(patientId: string, providerId: string) {
    let ref = firebase
      .firestore()
      .collection('appointment_invites')
      .orderBy('createdOn', 'desc');
    if (patientId) {
      ref = ref.where('clientId', '==', patientId);
    }
    if (providerId) {
      ref = ref.where('providerId', '==', providerId);
    }
    return ref;
  }
  getAllInvites() {
    return firebase
      .firestore()
      .collection('appointment_invites')
      .orderBy('createdOn', 'desc');
  }
  createInvite(body: AppointmentInvite) {
    return new Promise((resolve, reject) => {
      let _body = JSON.parse(JSON.stringify(classToPlain(body)));
      let url = urls.environment['appointment-invite-create'];
      this._httpHelper
        .post(url, _body)
        .toPromise()
        .then((res) => {
          if (
            res &&
            res['statusCode'] == 200 &&
            res['body'] &&
            res['body']['success'] == true
          ) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((err) => {
          console.log(err);
          resolve(false);
        });
    });
  }
  modifyInvite(inviteId: string, updateBody: any, currentHeroInfo: CRMUser) {
    return new Promise((resolve, reject) => {
      if (
        inviteId &&
        updateBody &&
        currentHeroInfo &&
        currentHeroInfo.docvitaId
      ) {
        let _body = {
          inviteId,
          updateBody,
          modifiedByName: currentHeroInfo.name,
          modifiedById: currentHeroInfo.docvitaId,
        };
        this._httpHelper
          .post(urls.environment['appointment-invite-modify'], _body)
          .toPromise()
          .then((res) => {
            if (
              res &&
              res['statusCode'] == 200 &&
              res['body'] &&
              res['body']['success'] == true
            ) {
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch((err) => {
            resolve(false);
          });
      } else {
        resolve(false);
      }
    });
  }
  cancelInvite(inviteId: string) {
    return new Promise((resolve, reject) => {
      let body = {
        inviteId,
      };
      this._httpHelper
        .post(urls.environment['appointment-invite-cancel'], body)
        .toPromise()
        .then((res) => {
          if (
            res &&
            res['statusCode'] == 200 &&
            res['body'] &&
            res['body']['success'] == true
          ) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((err) => {
          resolve(false);
        });
    });
  }
  updateInviteJson(id: string, jsonVal) {
    return new Promise((resolve, reject) => {
      let body = {
        inviteId: id,
        jsonVal,
      };
      this._httpHelper
        .post(urls.environment['appointment-invite-update-json'], body)
        .toPromise()
        .then((res) => {
          if (
            res &&
            res['statusCode'] == 200 &&
            res['body'] &&
            res['body']['success'] == true
          ) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((err) => {
          resolve(false);
        });
    });
  }
}
