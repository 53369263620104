import { Expose } from "class-transformer";

export class DoctorPracticeRelationship {
  @Expose() id: string;
  @Expose() relationship: string;
  @Expose() type: string;
  @Expose() fee: number;
  @Expose() acceptingAppointmentsOnDocVita: boolean = false;
  @Expose() name: string;
  @Expose() locality: string;
  @Expose() googleMapLink: string;
  @Expose() orderWeight: string;
}
