import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-webview',
  templateUrl: './webview.component.html',
  styleUrls: ['./webview.component.css'],
})
export class WebviewComponent implements OnInit {
  @ViewChild('webviewComponentModal') webviewComponentModal;
  url: string;
  autoCloseTimeInMillis: number; //In millis

  isIFrameLoading = false;
  constructor(private _modalService: BsModalService) {}

  ngOnInit(): void {}

  open(url: string, autoCloseTimeInMillis?: number) {
    this.url = url;
    this.isIFrameLoading = true;
    this._modalService.show(this.webviewComponentModal, {
      class: 'modal-lg custom-modal',
    });
    if (autoCloseTimeInMillis) {
      this.autoCloseTimeInMillis = autoCloseTimeInMillis;
      setTimeout(() => {
        this.closeModal();
      }, autoCloseTimeInMillis);
    }
  }

  iframeLoaded() {
    this.isIFrameLoading = false;
  }

  closeModal() {
    this._modalService.hide();
  }
}
