<div class="top-section">
    <div class="route-label">Set your availability</div>
    
    
</div>

<div class="main-wrapper">

    <p class="white-card mt-3 mb-0">
        <span style="font-weight: 500">Note</span>
        : Each appointment is {{appointmentDuration}} minutes in duration, and appointment
        start times are shown in increments of {{slotDuration}} minutes.
    </p>

    <div class="mt-3">
        <app-three-day-schedule [providerProfile]="providerProfile"></app-three-day-schedule>
    </div>
    <div class="mt-5">
        <app-working-hours [providerProfile]="providerProfile"></app-working-hours>
    </div>

    <div class="d-flex flex-column mt-2">
        <div class="d-flex justify-content-between align-items-center">
            <h5>
                Schedule Config
            </h5>
            <button class="btn btn-primary" (click)="saveChangesForConfig()">
                <span *ngIf="isSaving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Save Changes
            </button>
        </div>
        <div class="d-flex flex-column">
            <div class="d-flex flex-wrap mt-2">
                <div class="form-check form-check-inline" *ngFor="let item of scheduleConfig|keyvalue:returnZero;">
                    <input class="form-check-input" type="checkbox" [id]="item.key" [value]="item.key"
                        [checked]="item.value.bool" (change)="setSpecificConfig(item.key,$event.target.checked)">
                    <label class="form-check-label ms-1" [for]="item.key">{{item.value.display}}</label>
                </div>
            </div>
            <div class="d-flex flex-row">
                <h5>
                    Slot Length (mins)
                </h5>
                <input type="number" class="form-control mt-2" placeholder="(min)" [(ngModel)]="scheduleGenericSlotLength" />
            </div>
            <div class="form-row">
                <div class="col-md-4">
                  <label for="consultFees">Appointment Length (minutes)</label>
                  <input type="number" class="form-control" id="appointmentDuration" placeholder="Appointment length (in minutes)"
                    [(ngModel)]="appointmentDurationLength" />
                </div>
                <div class="col-md-4">
                  <label for="consultFees"> Accept Appointment Time Diff. (minutes)</label>
                  <input type="number" class="form-control" id="acceptAppointmentDiff"
                    placeholder="Accept Appointment Time Diff. (in minutes)"
                    [(ngModel)]="acceptAppointmentDiffLength" />
                </div>
              </div>
        </div>

    </div>
    <div class="m-5 p-5"></div>
</div>