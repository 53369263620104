import { Expose, plainToClass, Transform, Type } from "class-transformer";
import { TransactionStatus, TransactionType } from "../../Enums/pay";
import { CustomerInfo } from "./CustomerInfo";
import { ProviderAccountInfo } from "./ProviderAccountInfo";
import { PurposeItem } from "./PurposeItem";
import { LogItem } from "./LogItem";
import { PGOrderDetails } from "./PGOrderDetails";
import { LeadPayout, LeadPayoutStatus } from "../LeadPayout";
import { LeadTransfer } from "../LeadTransfer";

export class PaymentTransaction {
  @Expose() acceptedPaymentModes: string[] = [];
  @Expose() id: string;
  @Expose() transactionStatus: TransactionStatus; //Enum
  @Expose() amount: number; //In INR
  @Expose() createdById: string;
  @Expose() createdByName: string;
  @Expose() createdOn: number;
  @Expose() description: string;
  @Expose() pg: string;
  @Expose() updatedOn: number;
  @Expose() url: string;
  @Expose() typeOfTransaction: TransactionType; //Enum

  @Expose() metadata: Map<string, any> = new Map();

  @Expose()
  @Transform(
    (value) => {
      if (value) {
        let map = new Map<string, PGOrderDetails>();
        for (let entry of Object.entries(value)) {
          let temp: any = entry[1];
          if (temp["orderAmount"]) temp["orderAmount"] = +temp["orderAmount"];
          map.set(
            entry[0],
            plainToClass(PGOrderDetails, temp, {
              excludeExtraneousValues: false,
            })
          );
        }
        return map;
      } else {
        return new Map<string, PGOrderDetails>();
      }
    },
    { toClassOnly: true }
  )
  pgOrderDetails: Map<string, PGOrderDetails> = new Map();

  @Expose()
  @Transform(
    (value) => {
      if (value) {
        let map = new Map<string, PurposeItem>();
        for (let entry of Object.entries(value))
          map.set(entry[0], plainToClass(PurposeItem, entry[1]));
        return map;
      } else {
        return new Map<string, PurposeItem>();
      }
    },
    { toClassOnly: true }
  )
  purposeItemsMap: Map<string, PurposeItem> = new Map();

  @Expose()
  @Transform(
    (value) => {
      if (value) {
        let map = new Map<string, LogItem>();
        for (let entry of Object.entries(value))
          map.set(entry[0], plainToClass(LogItem, entry[1]));
        return map;
      } else {
        return new Map<string, LogItem>();
      }
    },
    { toClassOnly: true }
  )
  logs: Map<string, LogItem> = new Map();

  @Type(() => CustomerInfo)
  @Expose()
  customerInfo: CustomerInfo = new CustomerInfo();

  @Type(() => ProviderAccountInfo)
  @Expose()
  providerAccountInfo: ProviderAccountInfo = new ProviderAccountInfo();

  @Expose()
  @Transform(
    (value) => {
      if (value) {
        let map = new Map<string, LeadPayout>();
        for (let entry of Object.entries(value)) {
          map.set(entry[0], plainToClass(LeadPayout, entry[1]));
        }
        return map;
      } else {
        return new Map<string, LeadPayout>();
      }
    },
    { toClassOnly: true }
  )
  payouts: Map<string,LeadPayout> = new Map<string, LeadPayout>();

  @Expose()
  @Transform(
    (value) => {
      if (value) {
        let map = new Map<string, LeadTransfer>();
        for (let entry of Object.entries(value)) {
          map.set(entry[0], plainToClass(LeadTransfer, entry[1]));
        }
        return map;
      } else {
        return new Map<string, LeadTransfer>();
      }
    },
    { toClassOnly: true }
  )
  transfers: Map<string,LeadTransfer> = new Map<string, LeadTransfer>();

public get isPayoutSettlementDone(): boolean {
    let bool: boolean = false;
    if(this.payouts.size > 0) {
      this.payouts.forEach((val, key) => {
        if (!bool && val.status == LeadPayoutStatus.SUCCESS) {
          bool = true;
        }
      }); 
    }
    if(this.transfers.size > 0) {
      this.transfers.forEach((val, key) => {
        if (!bool && val.isSettlementDone()) {
          bool = true;
        }
      }); 
    }
    return bool;
  }

  public get getLatestPayout(): LeadPayout {
    let obj:LeadPayout;
    this.payouts.forEach((val,key) => {
      if(obj == null || obj.createdOn <= val.createdOn) {
        obj = val;
      }
    })
    return obj;
  }


  getPurposeForDisplay() {
    if (this.purposeItemsMap == null) {
      return "";
    } else {
      let purposes: string[] = [];
      this.purposeItemsMap.forEach((value, key) => {
        purposes.push(value.getDisplayPurpose());
      });
      return purposes.join(", ");
    }
  }

  getLatestPGOrderDetials() {
    if (this.pgOrderDetails != null && this.pgOrderDetails.size > 0) {
      let p: PGOrderDetails;
      this.pgOrderDetails.forEach((obj, key) => {
        if (obj.isSuccessful() && (p == null || p.createdOn < obj.createdOn)) {
          p = obj;
        }
      });
      return p;
    } else {
      return null;
    }
  }

  isSuccessful(): boolean {
    return this.transactionStatus == TransactionStatus.COMPLETED;
  }
}
