<div class="card mb-0 rounded p-2">
    <h6>Appts. of {{doctorName}} with {{patientName}}:</h6>
    <div style="max-height: 8rem;overflow-y: scroll;" class="p-1">
        <div class="card mb-0 p-1 mt-1 d-flex" *ngFor="let appointment of patientAppointmentsList"
            style="font-size: 14px;">
            <div> {{appointment.getShortDate()}}, {{appointment.getStartTimeIn12HoursFormat()}} {{appointment.amount ? "("+currencyToSymbol(appointment)+" "+ appointment.amount + ")" : ""}}</div>
        </div>
        <div *ngIf="patientAppointmentsList.length==0&&!isLoadingPatientAppointments" class="alert alert-info" style="font-size: 0.9em!important;">
            💤 No Appointments!
        </div>
        <div class="spinner-border ms-3 mt-3" role="status" *ngIf="isLoadingPatientAppointments">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>