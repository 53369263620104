import { Expose } from "class-transformer";

export class LeadRecipientSettlement {
  @Expose() id: string;
  @Expose() status: string; //failed or processed
  @Expose() utr: string;
  @Expose() createdOn: number;
  isSuccessful(): boolean {
    return this.status && this.status == 'processed';
  }
  isFailed(): boolean {
    return this.status && this.status == 'failed';
  }
}