import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CRMUser } from 'src/app/shared/models-ts/Actors/User/CRMUser';
import { User } from 'src/app/shared/models-ts/Actors/User/User';
import { ChatListItem } from 'src/app/shared/models-ts/Entities/chat/ChatListItem';
import { AuthUserInfoService } from 'src/app/shared/services/auth-user-info/auth-user-info.service';
import { MediaUploadService } from '../services/media-upload.service';
@Component({
  selector: 'chat-file-uploader',
  templateUrl: './chat-file-uploader.component.html',
  styleUrls: ['./chat-file-uploader.component.scss'],
})
export class ChatFileUploaderComponent implements OnInit {
  @ViewChild('chatUploadFileModal') chatUploadFileModal;
  @Input() chatListItem: ChatListItem = new ChatListItem();
  modalRef: BsModalRef;
  fileDetails: any[] = [];
  isUploading: boolean = false;
  currentHeroInfo: CRMUser = new CRMUser();
  constructor(
    private modalService: BsModalService,
    private _authUserInfoService: AuthUserInfoService,
    private toastr: ToastrService,
    private mediaService: MediaUploadService
  ) {}

  ngOnInit(): void {
    this.currentHeroInfo = this._authUserInfoService.getUserInfo();
  }
  openModal() {
    this.modalRef = this.modalService.show(this.chatUploadFileModal, {
      class: 'modal-lg',
    });
  }
  closeModal() {
    this.fileDetails = [];
    this.modalRef.hide();
  }
  initSteps() {
    this.fileDetails = [];
  }

  addFileLocally(files) {
    let _files: File[] = files.target.files;
    for (let file of _files) {
      this.getFileStream(file);
    }
    files.target.value = null;
  }

  getFileStream(f: File) {
    let reader = new FileReader();
    reader.readAsDataURL(f);
    reader.onload = (_event) => {
      if (reader.error) {
        console.error(reader.error);
      }
      if (reader.result) {
        let _file = {
          name: f.name,
          contentType: f.type,
          obj: reader.result,
          size: f.size,
        };
        this.fileDetails.push(_file);
      }
    };
  }

  discardFile(index) {
    if (!this.isUploading) {
      this.fileDetails.splice(index, 1);
    }
  }

  uploadAllFiles() {
    if (
      this.fileDetails.length > 0 &&
      this.currentHeroInfo &&
      this.chatListItem &&
      this.chatListItem.platformSenderId &&
      this.chatListItem.chatItemId
    ) {
      let finalJson = {
        chatId: this.chatListItem.chatItemId,
        fileDetails: this.fileDetails,
        recipient: this.chatListItem.platformSenderId,
        heroInfo: this.currentHeroInfo,
      };
      this.isUploading = true;
      this.mediaService
        .uploadFiles(finalJson)
        .then((respBody) => {
          this.isUploading = false;
          if (respBody && respBody['failed'] && respBody['failed'].length > 0) {
            for (let fname of respBody['failed']) {
              this.toastr.error('Upload Failed: ' + fname);
            }
          }
          if (
            respBody &&
            respBody['uploaded'] &&
            respBody['uploaded'].length > 0
          ) {
            for (let file of respBody['uploaded']) {
              if (file['fileName'] && file['url']) {
                this.toastr.success('Uploaded: ' + file['fileName']);
                let index = this.fileDetails.findIndex(
                  (da) =>
                    da &&
                    da['name'] &&
                    da['name'].trim().split(' ').join('_') == file['fileName']
                );
                this.discardFile(index);
              }
            }
          }
          if (
            respBody['uploaded'].length > 0 &&
            (respBody['failed'].length == 0 || !respBody['failed'])
          ) {
            this.closeModal();
          }
        })
        .catch((err) => {
          this.isUploading = false;
          this.toastr.error('Upload Failed..');
        });
    } else {
      this.toastr.warning('Missing details, cannot upload..');
    }
  }
}
