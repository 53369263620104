import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { LedgerService } from 'src/app/ledger/services/ledger.service';
import { User } from '../../models-ts/Actors/User/User';
import { PaymentSourcesArray } from '../../models-ts/constants/payment-constants';
import { Lead } from '../../models-ts/Entities/Lead';
import { CountrywiseFeeCalc, Currency } from '../../models-ts/Helpers/countrywiseFeeCalc';

@Component({
  selector: 'app-client-credit-entry',
  templateUrl: './client-credit-entry.component.html',
  styleUrls: ['./client-credit-entry.component.scss'],
  providers: [LedgerService]
})
export class ClientCreditEntryComponent implements OnInit {

  modalRef: BsModalRef;
  isLoading: boolean = false;
  creditAmount: number;
  creditSource: string;
  creditedOnDate: string;
  creditedOnTime: string;
  paymentId: string;
  paymentSourceArray: string[] = PaymentSourcesArray;
  client: User;
  @Input() clientId: string;
  currency:Currency = Currency.INR;
  constructor(
    private modalService: BsModalService,
    private toastr: ToastrService,
    private ledgerService: LedgerService
  ) {}
  ngOnInit(): void {}
  @ViewChild('clientCreditEntry') manualCreditEntry;
  openModal() {
    this, this.initForm();
    this.modalRef = this.modalService.show(this.manualCreditEntry);
  }
  initForm() {
    this.creditAmount = null;
    this.creditSource = null;
    this.creditedOnDate = null;
    this.creditedOnTime = null;
    this.paymentId = null;
    this.currency = Currency.INR;
  }
  closeModal() {
    this.modalRef.hide();
  }
  creditSourceChanged(source: string) {
    this.creditSource = source;
  }
  createEntry() {
    if (this.validateFormEntry()) {
      this.isLoading = true;
      let createdOn = new Date(
        this.creditedOnDate + ' ' + this.creditedOnTime
      ).getTime();
      
      this.ledgerService
        .creditPurchaseEntryToWallet(
          this.clientId, this.creditAmount, this.creditSource, this.paymentId, createdOn ,this.currency
        )
        .then((res) => {
          this.isLoading = false;
          this.toastr.success('Amount added to wallet!');
          this.closeModal();
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
          this.toastr.error('Failed to update!');
        });
    } else {
      this.toastr.warning('Invalid or Missing details');
    }
  }
  validateFormEntry() {
    if (
      this.creditAmount &&
      this.paymentId &&
      this.paymentId.length > 0 &&
      this.creditSource &&
      this.creditSource.length > 0 &&
      this.creditedOnDate &&
      this.creditedOnDate.length > 0 &&
      this.creditedOnTime &&
      this.creditedOnTime.length > 0 &&
      this.clientId &&
      this.clientId.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  setPatientFromSearch(event) {
    this.client = new User();
    if (event && event['docvitaId']) {
      this.client = event;
      this.clientId = event['docvitaId'];

    }
  }

  currencyToSymbol(currency:Currency){
    return new CountrywiseFeeCalc().currencyToSymbol(currency);
  }
  setCurrency(val:Currency){
    this.currency = val;
  }

}
