<div class="mb-5">
    <app-navbar (backClicked)="backClicked()" [title]="title"></app-navbar>
</div>

<div class="d-flex">
    <div class="col-md-4">
        <app-doc-master-list (selectedDoctor)="onSelectedDoctorClicked($event)"></app-doc-master-list>
    </div>

    <div class="col-md-8" style="overflow-y: scroll; height: calc(100vh - 150px)">
        <div class="mx-4 my-1">
            Use this button to upload multiple profile image photos. Format: providerID.jpeg
            <input
                type="file"
                id="files"
                accept="image/jpeg"
                style="height: 150px"
                multiple
                (change)="fileChangeEvent($event)"
                />
            <!-- <button class="btn btn-sm btn-outline-primary">Upload in bulk</button> -->
        </div>
        <app-provider-header *ngIf="selectedDoctor != null" class="mb-1 w-100" [selectedDoctor]="selectedDoctor">
        </app-provider-header>
        <router-outlet></router-outlet>
    </div>
</div>