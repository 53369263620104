export let city = [
  'Bangalore',
  'Mumbai',
  'Delhi',
  'Ahmedabad',
  'Indore',
  'Hyderabad',
  'Chennai',
  'Navi Mumbai',
  'Kolkata',
  'Jaipur',
  'Gurgaon',
  'Lucknow',
  'Bhopal',
  'Visakhapatnam',
  'Patna',
  'Ranchi',
  'Srinagar',
  'Raipur',
  'Chandigarh',
  'Guwahati',
  'Bhubaneswar',
  'Thiruvananthapuram',
  'Dehradun',
  'Agartala',
  'Noida',
];
export let state = [
  'Maharashtra',
  'Delhi',
  'Gujarat',
  'Madhya Pradesh',
  'Karnataka',
  'Telangana',
  'Tamil Nadu',
  'West Bengal',
  'Rajasthan',
  'Uttar Pradesh',
  'Andhra Pradesh',
  'Bihar',
  'Jharkhand',
  'Srinagar',
  'Chhattisgarh',
  'Punjab',
  'Assam',
  'Odisha',
  'Kerala',
  'Uttarakhand',
  'Tripura',
];
export let specializations = [
  { id: 'dentist', name: 'Dentist' },
  { id: 'general-physician', name: 'General Physician' },
  { id: 'gynaecologist', name: 'Gynaecologist' },
  { id: 'gynecologist', name: 'Gynecologist' },
  { id: 'dermatologist', name: 'Dermatologist' },
  { id: 'paediatrician', name: 'Paediatrician' },
  { id: 'pediatrician', name: 'Pediatrician' },
  { id: 'gastroenterologist', name: 'Gastroenterologist' },
  { id: 'ear-nose-throat-ent-specialist', name: 'ENT specialist' },
  { id: 'psychiatrist', name: 'Psychiatrist' },
  { id: 'psychologist', name: 'Psychologist' },
  { id: 'counselling-psychologist', name: 'Counselling Psychologist' },
  { id: 'life-coach', name: 'Life Coach' },
  { id: 'psychologist', name: 'Psychologist' },
  { id: 'veterinarian', name: 'Veterinarian' },
  { id: 'cardiologist', name: 'Cardiologist' },
  { id: 'dietitian-nutritionist', name: 'Dietitian Nutritionist' },
  { id: 'neurologist', name: 'Neurologist' },
  { id: 'neurosurgeon', name: 'Neurosurgeon' },
  { id: 'oncologist', name: 'Oncologist' },
  { id: 'orthopedist', name: 'Orthopedist' },
  { id: 'physiotherapist', name: 'Physiotherapist' },
  { id: 'occupational-therapist', name: 'Occupational Therapist' },
  { id: 'joint-replacement-surgeon', name: 'Joint Replacement Surgeon' },
  { id: 'ayurveda', name: 'Ayurveda' },
  { id: 'yoga-specialist', name: 'Yoga Specialist' },
  { id: 'nephrologist', name: 'Nephrologist' },
  { id: 'homeopath', name: 'Homeopath' },
  { id: 'general-surgeon', name: 'General Surgeon' },
  { id: 'sexologist', name: 'Sexologist' },
];
export let languages = [
  'Hindi',
  'English',
  'Bengali',
  'Urdu',
  'Punjabi',
  'Marathi',
  'Telugu',
  'Tamil',
  'Gujarati',
  'Kannada',
  'Odia',
  'Malayalam',
  'Assamese',
  'Santali',
];

export let days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export let tagsList = ['Known', 'New'];

export let accountManagersList = ['Anmol', 'Shriya', 'Palak', 'Vikram'];

export let internTags = {
  cant_verify_medical_registration: {
    key: 'cant_verify_medical_registration',
    value: 'Cannot verify MCI registration.',
  },
  profile_photograph_not_found: {
    key: 'profile_photograph_not_found',
    value: 'Profile photograph not found.'
  }
};



