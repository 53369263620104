<form>
  <div class="form-group d-flex mb-2">
    <input
      type="text"
      class="form-control flex-fill me-2"
      placeholder="Search by Name or ID"
      name="search"
      [formControl]="searchFormControl"
    />
    <button
      class="btn btn-sm border-0 btn-icon btn-outline-primary"
      data-toggle="modal"
      data-target="#exampleModal"
      (click)="openModal(filterModal)"
    >
      <span class="material-icons"> filter_list </span>
    </button>
  </div>
  <div class="form-group mb-2">
    <div class="row">
      <div class="col-md-6">
        <ng-select
          id="exampleFormControlSelect1"
          name="city"
          [addTag]="true"
          [(ngModel)]="selectedCity"
          placeholder="Select city"
          (ngModelChange)="onCityChange($event)"
        >
          <ng-option *ngFor="let c of city" [value]="c">{{ c }}</ng-option>
        </ng-select>
      </div>
      <div class="col-md-6">
        <!-- <label for="exampleFormControlSelect2">Specializations</label> -->
        <ng-select
          [addTag]="true"
          id="exampleFormControlSelect2"
          [(ngModel)]="selectedSpecialization"
          (ngModelChange)="onSpecialityChange($event)"
          placeholder="Select speciality"
          name="speciality"
        >
          <ng-option *ngFor="let s of specialization" [value]="s.name">
            {{ s.name }}</ng-option
          >
        </ng-select>
      </div>
    </div>
  </div>
</form>
<!-- <div class="form-group">
  <button
    class="btn btn-sm btn-secondary w-50"
    data-toggle="modal"
    data-target="#exampleModal"
  >
    Filter +
  </button>
</div> -->

<ng-template #filterModal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Filter</h5>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="closeModal()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="d-flex w-100 modal-body">
    <div class="w-100 d-flex flex-column">
      <div class="form-row form-group w-100">
        <div class="form-inline w-100">
          <div>
            <label for="">Photo Url</label>
          </div>
          <div >
            <select
              class="custom-select form-control w-100"
              [(ngModel)]="newFilter.photoUrl"
              (ngModelChange)="saveFilter()"
              ngValue="newFilter.photoUrl"
            >
              <option value=""></option>
              <option [ngValue]="false">Not Exists</option>
              <option [ngValue]="true">Exists</option>
            </select>
          </div>
        </div>
      </div>
      <div class="form-row form-group w-100">
        <div class="form-inline w-100">
          <div>
            <label for="">
              <span class="font-weight-bold">&& &nbsp;</span> Email</label
            >
          </div>
          <div>
            <select
              class="custom-select form-control w-100"
              [(ngModel)]="newFilter.email"
              (ngModelChange)="saveFilter()"
              ngValue="newFilter.email"
            >
              <option value=""></option>
              <option [ngValue]="false">Not Exists</option>
              <option [ngValue]="true">Exists</option>
            </select>
          </div>
        </div>
      </div>
      <div class="form-row form-group w-100">
        <div class="form-inline w-100">
          <div >
            <label for="">
              <span class="font-weight-bold">&& &nbsp;</span> Medical
              Registration Docs Uploaded</label
            >
          </div>
          <div >
            <select
              class="custom-select form-control w-100"
              [(ngModel)]="newFilter.medicalRegistrationUploaded"
              (ngModelChange)="saveFilter()"
              ngValue="newFilter.medicalRegistrationUploaded"
            >
              <option value=""></option>
              <option [ngValue]="false">Not Uploaded</option>
              <option [ngValue]="true">Uploaded</option>
            </select>
          </div>
        </div>
      </div>
      <div class="form-row form-group w-100">
        <div class="form-inline w-100">
          <div>
            <label for="">
              <span class="font-weight-bold">&& &nbsp;</span> Medical
              Registration Verified</label
            >
          </div>
          <div >
            <select
              class="custom-select form-control w-100"
              [(ngModel)]="newFilter.medicalRegistrationVerified"
              (ngModelChange)="saveFilter()"
              ngValue="newFilter.medicalRegistrationVerified"
            >
              <option value=""></option>
              <option [ngValue]="false">Not Verified</option>
              <option [ngValue]="true">Verfied</option>
            </select>
          </div>
        </div>
      </div>
      <div class="form-row form-group w-100">
        <div class="form-inline w-100">
          <div >
            <label for="">
              <span class="font-weight-bold">&& &nbsp; &nbsp;</span> Show In
              Listing</label
            >
          </div>
          <div >
            <select
              class="custom-select form-control w-100"
              [(ngModel)]="newFilter.showInListing"
              (ngModelChange)="saveFilter()"
              ngValue="newFilter.showInListing"
            >
              <option value=""></option>
              <option [ngValue]="false">Not Approved</option>
              <option [ngValue]="true">Approved</option>
            </select>
          </div>
        </div>
      </div>
      <div class="form-row form-group w-100">
        <div class="form-inline w-100">
          <div >
            <label for="">
              <span class="font-weight-bold">&& &nbsp; &nbsp;</span>Profile
              Claimed Status</label
            >
          </div>
          <div >
            <select
              class="custom-select form-control w-100"
              [(ngModel)]="newFilter.isProfileClaimed"
              (ngModelChange)="saveFilter()"
              ngValue="newFilter.isProfileClaimed"
            >
              <option value=""></option>
              <option [ngValue]="false">Not claimed</option>
              <option [ngValue]="true">Claimed</option>
            </select>
          </div>
        </div>
      </div>
      <div class="form-row form-group w-100">
        <div class="form-inline w-100">
          <div >
            <label for=""
              ><span class="font-weight-bold">&& &nbsp; &nbsp;</span> Public
              Contact Number</label
            >
          </div>
          <div >
            <select
              class="custom-select form-control w-100"
              [(ngModel)]="newFilter.publicContactNumber"
              (ngModelChange)="saveFilter()"
              ngValue="newFilter.publicContactNumber"
            >
              <option value=""></option>
              <option [ngValue]="false">Not Exists</option>
              <option [ngValue]="true">Exists</option>
            </select>
          </div>
        </div>
      </div>

      <div class="form-row form-group w-100">
        <div class="form-inline w-100">
          <div >
            <label for=""
              ><span class="font-weight-bold">&& &nbsp; &nbsp;</span>
              Primary Contact Number</label
            >
          </div>
          <div >
            <select
              class="custom-select form-control w-100"
              [(ngModel)]="newFilter.primaryContactNumber"
              (ngModelChange)="saveFilter()"
              ngValue="newFilter.primaryContactNumber"
            >
              <option value=""></option>
              <option [ngValue]="false">Not Exists</option>
              <option [ngValue]="true">Exists</option>
            </select>
          </div>
        </div>
      </div>
      <div class="form-row form-group w-100">
        <div class="form-inline w-100">
          <div >
            <label for="">
              <span class="font-weight-bold">&& &nbsp; &nbsp;</span>Claim
              Profile - Email Invite
            </label>
          </div>
          <div>
            <select
              class="custom-select form-control w-100"
              [(ngModel)]="newFilter.isClaimProfileEmailInviteSent"
              (ngModelChange)="saveFilter()"
              ngValue="newFilter.isClaimProfileEmailInviteSent"
            >
              <option value=""></option>
              <option [ngValue]="false">Not sent</option>
              <option [ngValue]="true">Sent</option>
            </select>
          </div>
        </div>
      </div>
      <div class="form-row form-group w-100">
        <div class="form-inline w-100">
          <div >
            <label for="">
              <span class="font-weight-bold">&& &nbsp; &nbsp;</span>Claim
              Profile - Whatsapp Invite
            </label>
          </div>
          <div >
            <select
              class="custom-select form-control w-100"
              [(ngModel)]="newFilter.isClaimProfileWAInviteSent"
              (ngModelChange)="saveFilter()"
              ngValue="newFilter.isClaimProfileWAInviteSent"
            >
              <option value=""></option>
              <option [ngValue]="false">Not sent</option>
              <option [ngValue]="true">Sent</option>
            </select>
          </div>
        </div>
      </div>
      <div class="form-row form-group w-100">
        <div class="form-inline w-100">
          <div >
            <label for="">
              <span class="font-weight-bold">&& &nbsp; &nbsp;</span>Founder
              - Whatsapp Invite
            </label>
          </div>
          <div >
            <select
              class="custom-select form-control w-100"
              [(ngModel)]="newFilter.isFounderInviteViaWhatsappSent"
              (ngModelChange)="saveFilter()"
              ngValue="newFilter.isFounderInviteViaWhatsappSent"
            >
              <option value=""></option>
              <option [ngValue]="false">Not sent</option>
              <option [ngValue]="true">Sent</option>
            </select>
          </div>
        </div>
      </div>
      <div class="form-row form-group w-100">
        <div class="form-inline w-100">
          <div >
            <label for="">
              <span class="font-weight-bold">&& &nbsp; &nbsp;</span>Founder
              - Email Invite
            </label>
          </div>
          <div >
            <select
              class="custom-select form-control w-100"
              [(ngModel)]="newFilter.isFounderInviteViaEmailSent"
              (ngModelChange)="saveFilter()"
              ngValue="newFilter.isFounderInviteViaEmailSent"
            >
              <option value=""></option>
              <option [ngValue]="false">Not sent</option>
              <option [ngValue]="true">Sent</option>
            </select>
          </div>
        </div>
      </div>
      <div class="form-row form-group w-100">
        <div class="form-inline w-100">
          <div >
            <label for=""
              ><span class="font-weight-bold">&& &nbsp; &nbsp;</span> Tags
            </label>
          </div>
          <div>
            <ng-select
              id="misc_tags_select"
              [multiple]="true"
              [addTag]="true"
              [items]="tagsSuggestionList"
              (ngModelChange)="onDropdownFilterChange($event)"
              placeholder="Misc. Tags"
              [(ngModel)]="newFilter.tags"
            >
            </ng-select>
          </div>
        </div>
      </div>
      <div class="form-row form-group w-100">
        <div class="form-inline w-100">
          <div >
            <label for=""
              ><span class="font-weight-bold">&& &nbsp; &nbsp;</span>
              Account Managers
            </label>
          </div>
          <div>
            <ng-select
              id="account_managers_select"
              [multiple]="true"
              [addTag]="true"
              [items]="accountManagerSuggestionList"
              (ngModelChange)="onDropdownFilterChange($event)"
              placeholder="Account Managers"
              [(ngModel)]="newFilter.accountManagers"
            >
            </ng-select>
          </div>
        </div>
      </div>

      <div class="form-row form-group w-100">
        <div class="form-inline w-100">
          <div>
            <label for=""
              ><span class="font-weight-bold">&& &nbsp; &nbsp;</span> Limit
              To</label
            >
          </div>
          <div class="d-flex">
            <input
              type="number"
              maxlength="4"
              size="4"
              class="form-control"
              style="width: 125px"
              [(ngModel)]="newFilter.limit"
            />
            <button
              type="button"
              class="btn btn-sm btn-info ms-2"
              (click)="getDoctorProfiles()"
            >
              Fetch
            </button>
          </div>
        </div>
      </div>

      <button
        class="btn btn-sm btn-secondary float-right"
        (click)="clearFilters()"
      >
        Clear Filters
      </button>
    </div>
    <div class=" ms-2 w-100 d-flex flex-column">
      <div class="form-inline w-100">
        <div >
          <label for=""
            >Show claim ready doctors only with pending invitation</label
          >
        </div>
        <div >
          <select
            class="custom-select form-control w-100"
            [(ngModel)]="
              newFilter.showClaimReadyDoctorProfilesOnlyWithPendingInvitation
            "
            (ngModelChange)="saveFilter()"
            ngValue="newFilter.showClaimReadyDoctorProfilesOnlyWithPendingInvitation"
          >
            <option value=""></option>
            <!-- <option [ngValue]="false">Not Exists</option> -->
            <option [ngValue]="true">True</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</ng-template>
