import { Expose } from "class-transformer";

export class LeadFollowUp {
  @Expose() id:string;
  @Expose() createdOn: number;
  @Expose() followUpDate:number; //epoch
  @Expose() status:string; //CREATED -> BOOKED | NO_FURTHER_BOOKING
  @Expose() metaData:any;
  @Expose() comments:string;
  @Expose() linkedLeadId:string;
}
