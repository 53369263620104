<div class="card form-row">
    <div class="card-header bg-transparent d-flex">
      <h6>Phone numbers</h6>
      <button
        class="btn btn-sm btn-primary ms-auto"
        (click)="addPhoneField()"
      >
        +
      </button>
    </div>
    <div class="card-body">
      <div
        class="form-group"
        *ngFor="let phone of contactNumbers; let i = index"
      >
        <label [for]="'PhoneInput' + i"
          >Phone {{ i + 1 }}</label
        >
        <div class="d-flex">
          <input
            type="text"
            class="form-control me-1"
            [value]="'+'+phone.countryCode"
            style="width: 3rem; padding: 0.5rem"
            disabled
          />
          <input
            type="number"
            class="form-control"
            [id]="'PhoneInput' + i"
            placeholder="Contact Number"
            [(ngModel)]="phone.number"
          />
          <button
            class="btn btn-sm btn-danger ms-1"
            (click)="deletePhoneField(i)"
          >
            &times;
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="card form-row mt-3 mb-3">
    <div class="card-header bg-transparent d-flex">
      <h6>Emails</h6>
      <button
        class="btn btn-sm btn-primary ms-auto"
        (click)="addEmailField()"
      >
        +
      </button>
    </div>
    <div class="card-body">
      <div
        class="form-group"
        *ngFor="
          let _email of emails;
          let x = index;
          "
      >
        <label [for]="'EmailInput' + x"
          >Email {{ x + 1 }}</label
        >
        <div class="d-flex">
          <input
            type="email"
            class="form-control"
            [id]="'EmailInput' + x"
            [(ngModel)]="_email.email"
            placeholder="Email"
          />
          <button
            class="btn btn-sm btn-danger ms-1"
            (click)="deleteEmailField(x)"
          >
            &times;
          </button>
        </div>
      </div>
    </div>
  </div>