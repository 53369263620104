import { Expose, Type } from "class-transformer";
import { Address } from "./Address";
import { ContactNumber } from "./ContactNumber";

export class EmergencyContact {
    @Expose() id:string;
    @Expose() name: string;
    
    @Expose() 
    @Type(()=>Address)
    address: Address;
    
    @Expose()
    @Type(()=>ContactNumber)
    contactNumber: ContactNumber

}