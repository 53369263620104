<button class="btn btn-sm btn-outline-primary" *ngIf="showButton" (click)="openModal();">
    + Follow-up
</button>

<ng-template #followUp let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        New Follow-up
      </h4>
      <div class="ms-auto d-flex align-items-center">
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="modal-body d-flex flex-column">
        <div style="font-weight:500;" *ngIf="leadId!=undefined">LeadId: <span style="font-weight:400;">{{leadId?leadId:"-"}}</span></div>
        <div style="font-weight:500;" class="mt-2">Patient:</div>
        <app-patient-search [patientId]="patientId"  (getPatient)="setPatientFromSearch($event)"></app-patient-search>
        <div style="font-weight:500;" class="mt-2">Doctor:</div>
        <app-doctor-search [providerId]="doctorId" (getProvider)="setProviderFromSearch($event)"></app-doctor-search>
        <div style="font-weight:500;" class="mt-2">Follow-up Date: <span class="text-muted">(Time is optional)</span></div>
        <div class="d-flex align-items-center mt-1">
            <input type="date" class="form-control" [(ngModel)]="date">
            <input type="time" class="form-control ms-3" [(ngModel)]="time">
        </div>
        <div style="font-weight:500;" class="mt-2">Comment: <span class="text-muted">(Optional)</span></div>
        <textarea cols="30" rows="3" class="form-control" [(ngModel)]="comment"></textarea>
    </div>
    <div class="modal-footer">
      <button class="btn btn-outline-dark me-2" (click)="closeModal()">
        Close
      </button>
        <button type="button" class="btn btn-outline-primary" (click)="createFollowUp()" *ngIf="true" [disabled]="createFollowUpLoader">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                *ngIf="createFollowUpLoader"></span>
            Create Follow-up
        </button>
    </div>
  </ng-template>