import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'specialityFilter'
})
export class SpecialityFilterPipe implements PipeTransform {

  transform(items: any[], specs: any[]): any {
    if(specs.includes('All')||specs.length==0){
      return items;
    }else{
      let _args = specs.map(da=>{
        if(da=='General Physician'){
          return 'physician';
        }else if(da=='Physiotherapist'){
          return 'therapist';
        }
        return da.toLowerCase();
      })
      let __ = []
      let ___ = []
      for(let i of items){
        for(let j of _args){
          if(i['specialization']&&i['specialization'].includes(j)){
            __.push(i);
            break;
          }
          if(i['spec']&&i['spec'] == j?.toLowerCase()){
            ___.push(i);
            break;
          }
        }
      }
      // if(items.length>0&&items[0]['spec']!=undefined){
      //   return ___;
      // }else{
      //   return __;
      // }
      if(items.length>0&&items[0]['spec']!=undefined){
          return ___;
      }else{
        
          return __;
      }
      
    }
  }

}
