import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DoctorListingProfile } from '../../models-ts/Actors/Doctor/DoctorListingProfile';
import { User } from '../../models-ts/Actors/User/User';
import { DoctorsService } from '../../services/doctors/doctors.service';
import { PatientService } from '../../services/patient/patient.service';

@Component({
  selector: 'new-provider-select',
  templateUrl: './new-provider-select.component.html',
  styleUrls: ['./new-provider-select.component.scss']
})
export class NewProviderSelectComponent implements OnInit {
  doctorProfile:DoctorListingProfile = new DoctorListingProfile();
  @Input() set patientProfile(user:User){
    this._patientProfile = user;
    this.getOrganizaitonNameForQuickContact();
  }
  get patientProfile(){
    return this._patientProfile;
  }
  _patientProfile:User = new User()
  @Input() providerId:string;
  @Output() clearSelection:EventEmitter<any> = new EventEmitter<any>();
  @Output() getProvider:EventEmitter<DoctorListingProfile> = new EventEmitter<DoctorListingProfile>();
  connectedProfiles: DoctorListingProfile[] = [];

  @Input() disableClearing: boolean = false;
  constructor(private _doctorService:DoctorsService, private _patientService: PatientService) { }

  ngOnInit(): void {
  }

  setProvider(event) {
    if (event) {
      this.doctorProfile = event;
      this.getProvider.emit(event)
    } else {
      this.clearProviderSelection();
    }
  }

  clearProviderSelection() {
    this.doctorProfile = new DoctorListingProfile();
    this.providerId = null;
    this.clearSelection.emit();
  }

  async getOrganizaitonNameForQuickContact() {
    this.connectedProfiles = (await this._patientService.getOrganizaitonNameForQuickContact(this._patientProfile)).profiles;
  }
}
