import { Expose } from "class-transformer";
import { TransactionSource } from "../Enums/pay";

export class LeadPayout {
  @Expose() beneficiaryId: string;
  @Expose() transferId: string;
  @Expose() transferMode: string;
  @Expose() remarks: string;
  @Expose() settlementAmount: number;
  @Expose() subCode: string;
  @Expose() message: string;
  @Expose() referenceId: string;
  @Expose() utr: string;
  @Expose() acknowledged: number;
  @Expose() status: LeadPayoutStatus;
  @Expose() createdOn: number;
  @Expose() updatedOn: number;
  @Expose() source: TransactionSource;

  isSuccessful() {
    return this.status == LeadPayoutStatus.SUCCESS;
  }
}

export enum LeadPayoutStatus {
  SUCCESS="SUCCESS",
  ERROR="ERROR",
  FAILED="FAILED",
  REVERSED="REVERSED",
  PENDING="PENDING",
}
