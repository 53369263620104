<!-- Introduction part -->
<div class="d-flex w-100 mt-3">
  <h2>Schedule</h2>
  <button class="btn btn-sm btn-primary ms-auto me-2" style="width: 120px" (click)="saveSchedule()" [disabled]="loader">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loader"></span>
    Save
  </button>
</div>

<div class="w-100 d-flex justify-content-start mt-4">
  <div class="d-flex flex-column" style="width: 60%;">
    <h5>
      Schedule Config
    </h5>
    <div class="d-flex flex-wrap mt-2">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="allselect" value="all"
          (change)="markAllConfig($event.target.checked)">
        <label class="form-check-label ms-1" for="allselect">All</label>
      </div>
      <div class="form-check form-check-inline" *ngFor="let item of scheduleConfig|keyvalue:returnZero;">
        <input class="form-check-input" type="checkbox" [id]="item.key" [value]="item.key" [checked]="item.value.bool"
          (change)="setSpecificConfig(item.key,$event.target.checked)">
        <label class="form-check-label ms-1" [for]="item.key">{{item.value.display}}</label>
      </div>
    </div>
  </div>
  <div class="d-flex flex-column">
    <h5>
      Slot Length (mins)
    </h5>
    <input type="number" class="form-control mt-2" placeholder="(min)"
      [(ngModel)]="scheduleGenericSlotLength" />
  </div>
</div>


<h5 class="mt-5">
  Provider's weekly schedule
</h5>
<div class="d-flex justify-content-between align-items-start border-top mt-3">
  <div class="d-flex flex-column align-items-start border-end w-75">
    <div class="d-flex justify-content-start border-bottom py-3 px-2 w-100"
      *ngFor="let day of schedule | keyvalue: returnZero">
      <div class="d-flex align-items-center" style="width: 60px; height: 25px">
        <!-- <input type="checkbox" [(ngModel)]="day.value.bool" /> -->
        <div class="mx-1 px-2" style="font-weight: 600">
          {{ day.key }}
        </div>
      </div>

      <div class="d-flex flex-column">
        <div
          class="d-flex align-items-center mx-1 mb-1 px-3"
          *ngFor="let slot of day.value.slots; let i = index"
        >
          <input
            type="time"
            class="form-control me-2"
            style="width: 145px"
            [(ngModel)]="slot['startTime']"
          />
          <input
            type="time"
            class="form-control me-2"
            style="width: 145px"
            [(ngModel)]="slot['endTime']"
          />
          <button
            type="button"
            class="btn btn-outline-danger border-0 btn-sm"
            (click)="deleteSlot(day.key, i)"
          >
            <i class="feather icon-trash-2"></i>
          </button>
        </div>
      </div>
      <div *ngIf="day.value.slots && day.value.slots.length == 0"
        class="text-muted justify-content-center align-items-center px-2" style="margin-top: 3px">
        Unavailable
      </div>

      <div class="ms-auto">
        <button type="button" class="btn btn-sm btn-outline-primary border-0 px-2 mt-2" style="height: 25px"
          (click)="addSlot(day.key)">
          <i class="feather icon-plus"></i>
        </button>

        <div class="btn-group" dropdown [insideClick]="true">
          <button id="button-animated" dropdownToggle type="button" class="btn btn-sm btn-outline-primary border-0 mt-2"
            aria-controls="dropdown-animated" style="height: 25px" (click)="copyEvent(day.key)">
            <i class="feather icon-copy"></i>
          </button>
          <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-config">
            <p class="text-muted px-2">COPY TIMES TO ...</p>
            <li role="menuitem" *ngFor="let day of schedule | keyvalue:returnZero">
              <div class="d-flex align-items-center justify-content-between p-2" *ngIf="day.key != selectedCopyDayKey">
                <div class="ms-2">{{ day.key }}</div>
                <input type="checkbox" (change)='copyTheDaysToCopy($event.target.checked, day.key)' />
              </div>
            </li>
            <div class="d-flex flex-column py-1 px-2">
              <button class="btn btn-primary" dropdownToggle (click)="applyCurrentCopyEvent()">
                Apply
              </button>
            </div>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!-- Add Date Override -->
  <div class="pt-3 ">
    <div class="w-100 px-3">
      <app-schedule-override-modal  [doctorProfile]="doctorProfile" #overrideModal></app-schedule-override-modal>
    </div>
    <div class="w-100 d-flex flex-column mt-3 border-top" style="overflow-y: auto;max-height: calc(100vh - 300px);">
        <div class="w-100 border-bottom d-flex py-3" *ngFor="let oSlot of overridedSlotsArray">
          <div class="ps-3 me-3">
            {{oSlot.date|date:'dd MMM yyyy'}}
          </div>
          <div class="text-muted">
            •
          </div>
          <div class="d-flex flex-column px-3">
            <div *ngFor="let time of oSlot.oslots" class="mb-1">
              {{timeslotFormatter(time['timeslot'],oSlot.date)}}
            </div>
          </div>
          
          <div class="ms-auto d-flex align-items-center">
            <button type="button" class="btn btn-outline-primary border-0 btn-sm me-2" (click)="overrideModal.openModal(oSlot.date)">
              <i class="feather icon-edit-2"></i>
            </button>
          </div>
        </div>
    </div>
  </div>
</div>