import { Expose } from "class-transformer";
import { RefundSource } from "../Enums/pay";
import { Currency } from "../Helpers/countrywiseFeeCalc";

export class Refund {
    @Expose() refundSource:RefundSource;
    @Expose() orderId: string; //cashfree orderId
    @Expose() refundId: string;
    @Expose() isManualEntry: boolean;//not in use for now
    @Expose() arn: string; //For cashfree
    @Expose() referenceId: string;
    @Expose() txAmount: number;
    @Expose() refundAmount: number;
    @Expose() currency: Currency;
    @Expose() refundNote: string;
    @Expose() processed: string;
    @Expose() initiatedOn: number;
    @Expose() processedOn: number;

    public get isRefundProcessed() {
        return this.processed == 'YES';
    }
    public get isRefundInProcess() {
        return this.processed == 'NO';
    }
}