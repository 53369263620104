import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DoctorListingProfile } from '../../models-ts/Actors/Doctor/DoctorListingProfile';
import { User } from '../../models-ts/Actors/User/User';
import { Lead } from '../../models-ts/Entities/Lead';
import { CopyClipboardService } from '../../services/copy-clipboard/copy-clipboard.service';
import { PatientLeadsBackendService } from '../../../patient-leads/services/patient-leads-backend.service';
import { WaTemplateMessagesService } from '../../../patient-leads/services/wa-template-messages.service';
import { WaTemplateMessagesHelperService } from '../../../patient-leads/services/wa-template-messages-helper.service';
import {
  CheckListActionKey,
  CheckListEntity,
  CheckListSection,
} from '../../../patient-leads/components/next-steps/next-steps.models';
import { LeadBooleanAction } from '../../models-ts/Entities/LeadBooleanAction';
import { CRMUser } from '../../models-ts/Actors/User/CRMUser';

@Component({
  selector: 'app-next-steps-master',
  templateUrl: './next-steps-master.component.html',
  styleUrls: ['./next-steps-master.component.css'],
})
export class NextStepsMasterComponent implements OnInit {
  @Input() sections: CheckListSection[] = [];
  @Input() lead: Lead = new Lead();
  @Input() currentHeroInfo: CRMUser = new CRMUser();
  @Input() provider: DoctorListingProfile = new DoctorListingProfile();
  @Input() patient: User = new User();
  @Input() showPatientProfile: boolean = true;
  @Input() showProviderProfile: boolean = true;
  @Input() showProfileDetails: boolean = true;
  @Input() showSections: boolean = true;
  providerWaNumbers = [];
  patientWaNumbers = [];

  constructor(
    private _copyToClipboardService: CopyClipboardService,
    private _leadsBackendService: PatientLeadsBackendService,
    private _toastrService: ToastrService,
    private _waTemplateMessagesService: WaTemplateMessagesService,
    private _waTemplateMessagesHelper: WaTemplateMessagesHelperService
  ) {}

  ngOnInit(): void {
    // console.log('called init: next-steps-master');
    this.providerWaNumbers = [];
    this.patientWaNumbers = [];
    if (
      this.provider.getAllContactNumbers() &&
      this.provider.getAllContactNumbers().length > 0
    ) {
      for (let number of this.provider.getAllContactNumbers()) {
        if (number.isWhatsappContactNumber) {
          this.providerWaNumbers.push(number.countryCode + number.number);
        }
      }
    }

    if (
      this.patient &&
      this.patient.docvitaId != undefined &&
      this.patient.getWhatsappContactNumbers().length > 0
    ) {
      for (let number of this.patient.getWhatsappContactNumbers()) {
        let _number = number.countryCode + number.number;
        if (!this.patientWaNumbers.includes(_number)) {
          this.patientWaNumbers.push(_number);
        }
      }
    } else {
      if (
        this.patient &&
        this.patient.docvitaId != undefined &&
        this.patientContactInfo()
      ) {
        this.patientWaNumbers.push(
          this.patientContactInfo().countryCode +
            this.patientContactInfo().number
        );
      }
    }
  }
  currentChecklistAction(key: CheckListActionKey) {
    if (
      this.lead.checklistActions != null &&
      this.lead.checklistActions.has(key)
    ) {
      return this.lead.checklistActions.get(key).bool;
    } else {
      return false;
    }
  }

  recordEvent(key: string, sentStatus: boolean) {
    let finalJson: any = {};
    finalJson['checklistActions.' + key + '.bool'] = sentStatus;
    finalJson['checklistActions.' + key + '.updatedById'] =
      this.currentHeroInfo.docvitaId;
    finalJson['checklistActions.' + key + '.updatedByName'] =
      this.currentHeroInfo.name;
    finalJson['checklistActions.' + key + '.updatedOn'] = new Date().getTime();

    let leadBooleanAction = new LeadBooleanAction();
    leadBooleanAction.bool = sentStatus;
    leadBooleanAction.updatedById = this.currentHeroInfo.docvitaId;
    leadBooleanAction.updatedByName = this.currentHeroInfo.name;
    leadBooleanAction.updatedOn =
      finalJson['checklistActions.' + key + '.updatedOn'];
    this.lead.checklistActions.set(key, leadBooleanAction);

    this._leadsBackendService
      .updateLead(this.lead.id, finalJson)
      .then(() => {
        if (sentStatus) {
          this._toastrService.success('Message sent: ' + key);
        } else {
          this._toastrService.success('Undo');
        }
      })
      .catch((err) => {
        this._toastrService.error('Something went wrong!');
        console.error(err);
      });
  }

  copyTheDamnThing(someStupidText: string) {
    this._copyToClipboardService.copyToClipoard(someStupidText);
  }

  patientContactInfo() {
    if (this.patient && this.patient.primaryContactNumber) {
      let contact = this.patient.primaryContactNumber;
      if (contact.number && contact.number.length > 0) {
        return contact;
      } else {
        return null;
      }
    } else if (this.patient && this.patient.contactNumbers.size > 0) {
      let primary = this.patient.getPrimaryContactNumbers()[0];
      return primary;
    } else {
      return null;
    }
  }

  copyPatientNumber(patient) {
    if (patient) {
      let _ =
        this.patientContactInfo().countryCode +
        '-' +
        this.patientContactInfo().number;
      this.copyTheDamnThing(_);
    }
  }

  tryToOpenWhatsAppChatForPatient() {
    let contact = this.patientContactInfo();
    if (this.lead != null && contact != null) {
      let _url = `whatsapp://send?phone=${contact.countryCode}${contact.number}`;
      window.open(_url, '_blank');
    }
  }

  tryToOpenWhatsAppChatForProvider(contact) {
    if (this.lead != null && contact != null) {
      let _url = `whatsapp://send?phone=${contact.countryCode}${contact.number}`;
      window.open(_url, '_blank');
    }
  }

  tryToOpenWhatsAppChatForNumber(number: string, text: string) {
    if (number && number.length > 0) {
      let _url = `whatsapp://send?phone=${number}`;
      if (text != undefined) {
        _url += `&text=${encodeURIComponent(text)}`;
      }
      window.open(_url, '_blank');
    }
  }

  sendViaWaApi(checkListEntity: CheckListEntity, number: string) {
    switch (checkListEntity.key) {
      case CheckListActionKey.ClientConfirmationWA: {
        this._waTemplateMessagesHelper.sendClientConfirmationUsingWaAPI(
          number,
          this.lead.consultationInfo.appointmentId
        );
        break;
      }
      case CheckListActionKey.ClientConfirmationWAPunchline: {
        this._waTemplateMessagesHelper.sendClientConfirmationUsingWaAPI(
          number,
          this.lead.consultationInfo.appointmentId
        );
        break;
      }
      case CheckListActionKey.ProviderConfirmationWA: {
        this._waTemplateMessagesHelper.sendProviderConfirmationUsingWaAPI(
          number,
          this.lead.consultationInfo.appointmentId
        );
        break;
      }
      case CheckListActionKey.InformProviderOfRescheduling: {
        this._waTemplateMessagesHelper.sendProviderRescheduleConfirmationUsingWaAPI(
          this.lead.consultationInfo?.appointmentId,
          number
        );
        break;
      }
      case CheckListActionKey.InformClientOfRescheduling: {
        this._waTemplateMessagesHelper.sendClientRescheduleConfirmationUsingWaAPI(
          this.lead.consultationInfo?.appointmentId,
          number
        );
        break;
      }
    }
  }
}
