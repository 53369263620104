import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { classToPlain, plainToClass } from 'class-transformer';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault('Asia/Kolkata');
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ClientWalletService } from 'src/app/services/client-wallet.service';
import { DoctorListingProfile } from '../../models-ts/Actors/Doctor/DoctorListingProfile';
import { ContactNumber } from '../../models-ts/Actors/Person/ContactNumber';
import { Email } from '../../models-ts/Actors/Person/Email';
import { CRMUser } from '../../models-ts/Actors/User/CRMUser';
import { User } from '../../models-ts/Actors/User/User';
import { Appointment } from '../../models-ts/Entities/Appointment';
import { Lead } from '../../models-ts/Entities/Lead';
import {
  PaylinkDetails,
  PaylinkType,
} from '../../models-ts/Entities/PaylinkDetails';
import { TransactionSource } from '../../models-ts/Enums/pay';
import { ActionDoneBy } from '../../models-ts/Protocols/ActionDoneBy';
import { OrganisationConnection } from '../../models-ts/Relationships/OrganisationConnection';
import { CopyClipboardService } from '../../services/copy-clipboard/copy-clipboard.service';
import { DoctorsService } from '../../services/doctors/doctors.service';
import { PatientService } from '../../services/patient/patient.service';
import { PaymentLinksService } from '../../services/payment-links/payment-links.service';
import firebase from 'firebase';
import 'firebase/database';
import { AppointmentService } from '../../services/appointment/appointment.service';
@Component({
  selector: 'booking-paylink-v2',
  templateUrl: './booking-paylink-v2.component.html',
  styleUrls: ['./booking-paylink-v2.component.scss'],
  providers: [ClientWalletService]
})
export class BookingPaylinkV2Component implements OnInit {
  modalRef: BsModalRef;
  @ViewChild('bookingPaylinkModal') bookingPaylinkModal;
  @Input() viewOnlyMode: boolean = false;
  @Input() showButton: boolean = true;
  @Input() paymentLinkType: PaylinkType = PaylinkType.BOOKING; //default
  @Input() lead: Lead = new Lead();

  currentHeroInfo: CRMUser = new CRMUser();
  btnLoader: boolean = false;
  bookingSwitch: boolean = false;
  noPaymentLinks: boolean = false;
  allLinksLoader: boolean = false;
  pageNumber: number = 1;
  patientId;
  doctorId;
  patientProfile: User = new User();
  doctorProfile: DoctorListingProfile = new DoctorListingProfile();
  newPatientForm;
  paymentLinkForm;
  appointmentBookingForm;
  paymentLink = '';
  quickConnections: OrganisationConnection[] = [];
  currentAppointment: Appointment = new Appointment();
  allPaymentLinks: PaylinkDetails[] = [];
  paymentSource = 'razorpay';
  currentSlots: any[] = [];
  appointmentsList: Appointment[];
  patientAppointmentsList: Appointment[];
  isLoadingPatientAppointments: boolean = false;
  slotLoader: boolean = false;
  apptLoader: boolean = false;
  noAppointments: boolean = false;
  noSlots: boolean = false;
  selectedSlot: any;
  amtfetching: boolean;
  selectedFilter: string; //all or paid or unpaid
  noticeText: string;
  showReassignOptions: boolean = false;
  showAvailableTimeslots: boolean = true;
  allowTimeEdit: boolean = false;
  currentProviderSlots: any[] = [];
  payViaWallet: boolean = false;
  walletBalance: number = 0;
  isWalletBalanceLoading: boolean = false;
  constructor(
    private modalService: BsModalService,
    private _patientService: PatientService,
    private _toastrService: ToastrService,
    private _doctorService: DoctorsService,
    private _paymentLinksService: PaymentLinksService,
    private _clipboardService: CopyClipboardService,
    private _clientWalletService: ClientWalletService,
    private _appointmentService: AppointmentService
  ) { }

  ngOnInit(): void { }

  openModal() {
    this.gotoPage(1);
    this.resetPaymentForm();
    this.modalRef = this.modalService.show(this.bookingPaylinkModal);
  }
  closeModal() {
    this.modalRef.hide();
  }

  get getBookingDateTime() {
    if (
      this.appointmentBookingForm['date'] &&
      this.appointmentBookingForm['time']
    ) {
      let dateTime = dayjs(
        new Date(
          this.appointmentBookingForm['date'] +
          ' ' +
          this.appointmentBookingForm['time']
        ),
        'Asia/Kolkata'
      ).format('hh:mma, DD MMM');
      return dateTime;
    } else {
      return '';
    }
  }

  getBalance(clientId: string) {
    this.walletBalance = null;
    this.payViaWallet = false;
    this.isWalletBalanceLoading = true;
    this._clientWalletService
      .getClientBalance(clientId)
      .then((balance) => {
        this.walletBalance = balance;
        this.isWalletBalanceLoading = false;
      })
      .catch((err) => {
        console.log(err);
        this.walletBalance = 0;
        this.isWalletBalanceLoading = false;
      });
  }

  resetPaymentForm() {
    this.paymentSource = 'razorpay';
    this.patientId = '';
    this.doctorId = '';
    this.paymentLink = '';
    this.patientProfile = new User();
    this.allowTimeEdit = false;
    this.showAvailableTimeslots = true;
    this.doctorProfile = new DoctorListingProfile();
    this.quickConnections = [];
    this.allPaymentLinks = [];
    this.currentProviderSlots = [];
    this.walletBalance = null;
    this.isWalletBalanceLoading = false;
    this.payViaWallet = false;
    this.initBools();
    this.initNewPatientForm();
    this.initPaymentLinkForm();
    this.initAppointmentBookingForm();
  }

  initBools() {
    this.btnLoader = false;
    this.bookingSwitch = false;
    this.noPaymentLinks = false;
    this.allLinksLoader = false;
  }

  initNewPatientForm() {
    this.newPatientForm = {
      name: '',
      gender: '',
      contacts: [
        {
          countryCode: '',
          number: '',
          isPrimaryContactNumber: true,
          isWhatsappContactNumber: false,
        },
      ],
      emails: [{ email: '', isPrimary: true }],
    };
  }
  initPaymentLinkForm() {
    this.paymentLinkForm = {
      amount: null,
      description: '',
      customerName: '',
      customerEmail: '',
      customerContact: {
        countryCode: '',
        number: '',
      },
    };
  }
  initAppointmentBookingForm() {
    this.currentSlots = [];
    this.appointmentsList = [];
    this.patientAppointmentsList = [];
    this.doctorProfile = new DoctorListingProfile();
    this.appointmentBookingForm = {
      date: new Date().toISOString().split('T')[0],
      time: '',
      slotLength: '',
    };
  }

  appointmentFormValidation() {
    if (
      !this.appointmentBookingForm ||
      !this.appointmentBookingForm['date'] ||
      this.appointmentBookingForm['date'].length < 1 ||
      !this.appointmentBookingForm['time'] ||
      this.appointmentBookingForm['time'].length < 1 ||
      !this.appointmentBookingForm['slotLength'] ||
      this.appointmentBookingForm['slotLength'].length < 1
    ) {
      return false;
    } else {
      return true;
    }
  }
  paymentLinkFormValidation() {
    if (
      !this.paymentLinkForm ||
      !this.paymentLinkForm['amount'] ||
      !this.paymentLinkForm['customerName'] ||
      this.paymentLinkForm['customerName'].length < 1 ||
      !this.paymentLinkForm['customerContact'] ||
      !this.paymentLinkForm['customerContact']['countryCode'] ||
      this.paymentLinkForm['customerContact']['countryCode'].length < 1 ||
      isNaN(this.paymentLinkForm['customerContact']['countryCode']) ||
      !this.paymentLinkForm['customerContact']['number'] ||
      this.paymentLinkForm['customerContact']['number'].length < 1 ||
      isNaN(this.paymentLinkForm['customerContact']['number'])
      // !this.paymentLinkForm["customerEmail"]||
      // this.paymentLinkForm["customerEmail"].length<1
    ) {
      return false;
    } else {
      return true;
    }
  }

  newPaymentLink() {
    this.resetPaymentForm();
    this.gotoPage(1);
  }

  viewAllPayments() {
    this.resetPaymentForm();
    this.getAllPaymentLinks();
  }

  setPatient(event) {
    if (event) {
      this.patientProfile = event;
      this.getOrganizaitonNameForQuickContact();
      this.getBalance(this.patientProfile.docvitaId);
      // console.log(event);
      this.paymentLinkForm['customerName'] = this.patientProfile.name;
      if (
        this.patientProfile.contactNumbers &&
        this.patientProfile.getPrimaryContactNumbers() &&
        this.patientProfile.getPrimaryContactNumbers().length > 0
      ) {
        let contact = this.patientProfile.getPrimaryContactNumbers()[0];
        this.paymentLinkForm['customerContact'] = {
          number: contact.number,
          countryCode: contact.countryCode,
        };
      } else if (
        this.patientProfile.primaryContactNumber &&
        this.patientProfile.primaryContactNumber.number
      ) {
        let contact = this.patientProfile.primaryContactNumber;
        this.paymentLinkForm['customerContact'] = {
          number: contact.number,
          countryCode: contact.countryCode,
        };
      }

      if (
        this.patientProfile &&
        this.patientProfile.emails &&
        this.patientProfile.getPrimaryEmail() &&
        this.patientProfile.getPrimaryEmail().email
      ) {
        this.paymentLinkForm['customerEmail'] =
          this.patientProfile.getPrimaryEmail().email;
      } else if (
        this.patientProfile &&
        this.patientProfile.emails &&
        this.patientProfile.getEmails() &&
        this.patientProfile.getEmails().length > 0
      ) {
        this.paymentLinkForm['customerEmail'] =
          this.patientProfile.getEmails()[0].email;
      } else if (this.patientProfile.primaryEmail) {
        this.paymentLinkForm['customerEmail'] =
          this.patientProfile.primaryEmail;
      }
      console.log('customer name: ', this.paymentLinkForm['customerName']);
      console.log(
        'customer contact: ',
        this.paymentLinkForm['customerContact']
      );
      console.log('customer email: ', this.paymentLinkForm['customerEmail']);
    }
  }

  createPatient() {
    let _ = this.newPatientForm;
    let isValid = true;
    let newContactMap = {};
    let newEmailMap = {};

    //for back-compat
    let mobileNumber = '';
    let countryCode = '';
    let email = '';

    if (_['gender'] == null) _['gender'] = '';

    if (_['contacts']) {
      if (mobileNumber.length < 1 && _['contacts'].length == 1) {
        mobileNumber = _['contacts'][0]['number'].toString();
        countryCode = _['contacts'][0]['countryCode'].toString();
      }
      for (let i of _['contacts']) {
        if (
          i['countryCode'].toString().length > 0 &&
          i['number'].toString().length > 0
        ) {
          let nc = new ContactNumber();
          nc.countryCode = i['countryCode'].toString();
          nc.number = i['number'].toString();
          nc.isPrimaryContactNumber = i['isPrimaryContactNumber'];
          nc.isWhatsappContactNumber = i['isWhatsappContactNumber'];
          if (newContactMap[i['countryCode']]) {
            newContactMap[i['countryCode']][i['number']] = this.jsonParser(
              classToPlain(nc)
            );
          } else {
            newContactMap[i['countryCode']] = {};
            newContactMap[i['countryCode']][i['number']] = this.jsonParser(
              classToPlain(nc)
            );
          }
          if (i['isPrimaryContactNumber'] && mobileNumber.length < 1) {
            mobileNumber = i['number'].toString();
            countryCode = i['countryCode'].toString();
          }
        } else {
          isValid = false;
          break;
        }
      }
    }
    if (_['emails']) {
      if (email.length < 1 && _['emails'].length == 1) {
        email = _['emails'][0]['email'];
      }
      for (let i of _['emails']) {
        if (i['email'].length > 0) {
          let ne = new Email();
          ne.email = i['email'];
          ne.isPrimary = i['isPrimary'];
          newEmailMap[i['email']] = this.jsonParser(classToPlain(ne));
          if (i['isPrimary'] && email.length < 1) {
            email = i['email'];
          }
        }
      }
    }
    if (isValid) {
      let body = {
        name: _['name'],
        gender: _['gender'],
        contactNumbers: this.jsonParser(classToPlain(newContactMap)),
        emails: this.jsonParser(classToPlain(newEmailMap)),
        countryCode: countryCode,
        mobileNumber: mobileNumber,
        email: email,
      };
      this.btnLoader = true;
      // console.log(body)
      this.createPatientFinal(body);
    } else {
      this._toastrService.warning('Invalid details');
    }
  }

  createPatientFinal(body) {
    this._patientService
      .createPatientProfile(body)
      .then((res) => {
        if (res && res['body'] && res['body']['docvitaId']) {
          this.delay(3500)
            .then(() => {
              this._toastrService.success('Patient Created!');
              this.patientId = res['body']['docvitaId'];
              this.btnLoader = false;
              this.gotoPage(1);
            })
            .catch((err) => {
              this._toastrService.error('Patient Failed to create.');
              this.btnLoader = false;
            });
        } else {
          this._toastrService.error('Patient Failed to create.');
          this.btnLoader = false;
        }
      })
      .catch((err) => {
        this.btnLoader = false;
        console.log(err);
        this._toastrService.error('Patient Failed to create!');
      });
  }

  togglePaymentSource(source: string) {
    if (source) {
      this.paymentSource = source;
    }
  }

  delay(time: number) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(true);
      }, time);
    });
  }

  jsonParser(data) {
    return JSON.parse(JSON.stringify(data));
  }

  newPatientCreator() {
    this.pageNumber = 2;
    this.initNewPatientForm();
  }

  gotoPage(number: number) {
    this.pageNumber = number;
  }

  toggleBookingSwitch() {
    if (this.bookingSwitch) {
      this.initAppointmentBookingForm();
    }
  }

  async getOrganizaitonNameForQuickContact() {
    this.quickConnections = (await this._patientService.getOrganizaitonNameForQuickContact(this.patientProfile)).quickConnections;
  }

  getAllPaymentLinks() {
    this.gotoPage(4);
    this.allPaymentLinks = [];
    this.noPaymentLinks = false;
    this.allLinksLoader = true;
    this._paymentLinksService
      .getAllPaymentLinks(
        this.selectedFilter == 'paid' ? true : null,
        this.selectedFilter == 'unpaid' ? true : null
      )
      .onSnapshot(
        (snap) => {
          this.allPaymentLinks = [];
          if (!snap.empty) {
            snap.forEach((doc) => {
              let _ = doc.data();
              this.allPaymentLinks.push(plainToClass(PaylinkDetails, _));
            });
          } else {
            this.noPaymentLinks = true;
          }
          this.allLinksLoader = false;
        },
        (err) => {
          console.log(err);
          this.allLinksLoader = false;
        }
      );
  }

  setProvider(event) {
    if (event) {
      this.doctorProfile = event;
      this.appointmentBookingForm['slotLength'] = this.doctorProfile
        .appointmentDuration
        ? this.doctorProfile.appointmentDuration.toString()
        : null;
      this.doctorId = this.doctorProfile.id;
      this.paymentLinkForm['amount'] = this.doctorProfile
        .onlineConsultationConfig.fee
        ? this.doctorProfile.onlineConsultationConfig.fee
        : null;
      // if (this.isReassignMode) {
      //   this.setDifferenceAmtForNewProvider();
      // }
    } else {
      this.doctorProfile = new DoctorListingProfile();
      this.appointmentBookingForm['slotLength'] = '';
      this.currentProviderSlots = [];
      this.doctorId = '';
    }
  }

  manageListFetchState(event: string) {
    if (event == 'fetch_start') {
      this.amtfetching = true;
    } else if (event == 'fetch_end') {
      this.amtfetching = false;
    }
  }

  fetchLatestAppointmentAmount(list: Appointment[]) {
    if (list && list.length > 0) {
      // let latestAppt = list[0];
      let _amt = null;
      for (let i = 0; i < list.length; i++) {
        if (list[i].amount != null && list[i].amount > 0) {
          _amt = list[i].amount;
          break;
        } else {
          continue;
        }
      }
      if (_amt) {
        this.paymentLinkForm['amount'] = _amt;
      } else {
        this.setDoctorConfigAmt();
      }
    } else {
      this.setDoctorConfigAmt();
    }
  }

  setDoctorConfigAmt() {
    if (
      this.doctorProfile &&
      this.doctorProfile.id &&
      this.doctorProfile.onlineConsultationConfig &&
      this.amtfetching == false
    ) {
      this.paymentLinkForm['amount'] = this.doctorProfile
        .onlineConsultationConfig.fee
        ? this.doctorProfile.onlineConsultationConfig.fee
        : null;
    } else {
      this.paymentLinkForm['amount'] = null;
    }
  }

  getAppointmentBody() {
    if (
      this.doctorProfile &&
      this.doctorProfile.id &&
      this.appointmentFormValidation()
    ) {
      this.currentAppointment = new Appointment();
      let _timeslot =
        this.appointmentBookingForm['time'] +
        '-' +
        dayjs(
          new Date(
            this.appointmentBookingForm['date'] +
            ' ' +
            this.appointmentBookingForm['time']
          ),
          'Asia/Kolkata'
        )
          .add(+this.appointmentBookingForm['slotLength'], 'minutes')
          .format('HH:mm');
      this.currentAppointment.timeslot = _timeslot;
      this.currentAppointment.doctorId = this.doctorProfile.id;
      this.currentAppointment.organisationId = this.doctorProfile.id;
      this.currentAppointment.doctorName = this.doctorProfile.fullName();
      this.currentAppointment.organisationName = this.doctorProfile.fullName();
      this.currentAppointment.date = this.appointmentBookingForm['date'];
      if (this.paymentLinkForm && this.paymentLinkForm['amount'] != null)
        this.currentAppointment.amount = this.paymentLinkForm['amount'];
      this.currentAppointment.purposeOfVisitTitle = 'Online Consultation';
      this.currentAppointment.purposeOfVisitDescription =
        'Chat and Video (Valid for 72 hrs)';
      this.currentAppointment.modeOfAppointment = 'docvita';

      let body = classToPlain(this.currentAppointment);
      body['paymentIntent'] = 'payNow';
      body['platform'] = 'whatsapp';
      body['type'] = 'online';

      let patientInfo = {
        patientId: this.patientProfile.docvitaId,
        patientName: this.patientProfile.name,
        patientGender: this.patientProfile.gender
          ? this.patientProfile.gender
          : '',
      };
      if (
        this.patientProfile.contactNumbers &&
        this.patientProfile.contactNumbers.size > 0
      ) {
        let contactNumber = this.patientProfile.getPrimaryContactNumbers()[0];
        if (contactNumber.number && contactNumber.number.length > 0) {
          patientInfo['patientPrimaryContactNumber'] = {
            countryCode: contactNumber.countryCode,
            number: contactNumber.number,
          };
        }
      } else if (
        this.patientProfile.primaryContactNumber &&
        this.patientProfile.primaryContactNumber.number?.length > 0
      ) {
        patientInfo['patientPrimaryContactNumber'] = {
          countryCode: this.patientProfile.primaryContactNumber.countryCode,
          number: this.patientProfile.primaryContactNumber.number,
        };
      }

      if (
        this.patientProfile.emails &&
        this.patientProfile.emails.size > 0 &&
        this.patientProfile.getPrimaryEmail()
      ) {
        patientInfo['patientPrimaryEmail'] =
          this.patientProfile.getPrimaryEmail().email;
      } else {
        patientInfo['patientPrimaryEmail'] = this.patientProfile.primaryEmail
          ? this.patientProfile.primaryEmail
          : '';
      }

      body['patientsArray'] = [patientInfo];
      // console.log(JSON.parse(JSON.stringify(body)))
      return JSON.parse(JSON.stringify(body));
    } else {
      return null;
    }
  }

  @ViewChild('apptClashModal') apptClashModal;
  createPaymentLink() {
    this.btnLoader = true;
    if (!this.paymentSource || this.paymentSource.length == 0) {
      this._toastrService.warning('Select payment source.');
      this.btnLoader = false;
      return;
    }
    // if (
    //   this.paymentSource &&
    //   this.paymentSource == 'cashfree' &&
    //   this.paymentLinkForm['description'].length == 0
    // ) {
    //   this._toastrService.warning('Payment Description Required!');
    //   return;
    // }
    if (this.paymentLinkFormValidation()) {
      // console.log(this.paymentLinkForm);
      let body = {};
      body['amount'] = this.paymentLinkForm['amount'];
      body['description'] = this.paymentLinkForm['description']
        ? this.paymentLinkForm['description']
        : '';
      body['customer'] = {
        name: this.paymentLinkForm['customerName'],
        contact: this.paymentLinkForm['customerContact'],
      };
      if (this.lead && this.lead.id && this.lead.consultationInfo) {
        body['leadId'] = this.lead.id;
        body['appointmentId'] = this.lead.consultationInfo.appointmentId;
      }
      body['providerId'] = this.doctorProfile.id;
      body['patientId'] = this.patientProfile.docvitaId;

      if (this.currentHeroInfo && this.currentHeroInfo.docvitaId) {
        body['createdById'] = this.currentHeroInfo.docvitaId;
        body['createdByName'] = this.currentHeroInfo.name;
      }
      if (
        this.paymentLinkForm['customerEmail'] &&
        this.paymentLinkForm['customerEmail'].length > 1
      ) {
        if (this.validateEmail(this.paymentLinkForm['customerEmail'])) {
          body['customer']['email'] =
            this.paymentLinkForm['customerEmail'].trim();
        } else {
          this._toastrService.warning('Customer email is not valid!');
          this.btnLoader = false;
          return;
        }
      }
      if (this.currentHeroInfo) {
        body['createdById'] = this.currentHeroInfo.docvitaId;
        body['createdByName'] = this.currentHeroInfo.name;
      }

      if (
        this.appointmentFormValidation() &&
        this.doctorProfile.id &&
        this.patientProfile.docvitaId
      ) {
        let apptBody = this.getAppointmentBody();
        if (apptBody) {
          body['consultationInfo'] = apptBody;
          body['type'] = PaylinkType.BOOKING;
          if (body['description'] == '') {
            // console.log('Making custom desc.');
            body['description'] = this.payLinkNewSessionDescriptionBody(
              body['consultationInfo']
            );
          }
        } else {
          this._toastrService.warning('Incomplete Profile info!');
          this.btnLoader = false;
          return;
        }
      } else {
        this._toastrService.warning('Incomplete booking info!');
        this.btnLoader = false;
        return;
      }

      if (body && body['consultationInfo']) {
        if (body['consultationInfo']) {
          this.checkforClashingAppts(body, body['consultationInfo']);
        }
      } else {
        this.continueWithPaymentLinkCreation(body);
      }
    } else {
      this._toastrService.warning('Payment form incomplete.');
      this.btnLoader = false;
    }
  }
  checkforClashingAppts(payLinkBody, consultBody) {
    this.btnLoader = true;
    this.apptClashModal
      .openConflictPromise(
        consultBody['doctorId'],
        consultBody['date'],
        consultBody['timeslot'],
        consultBody['doctorName']
      )
      .then((hasConflicts) => {
        if (hasConflicts) {
          this.btnLoader = false;
          this.apptClashModal.openModal();
        } else {
          this.continueWithPaymentLinkCreation(payLinkBody);
        }
      })
      .catch((err) => {
        this.continueWithPaymentLinkCreation(payLinkBody);
      });
  }
  continueWithPaymentLinkCreation(body) {
    this.btnLoader = true;
    this._paymentLinksService
      .createPaymentLink(body, this.paymentSource)
      .then((res) => {
        if (
          res &&
          res['body'] &&
          res['body']['short_url'] &&
          res['body']['id']
        ) {
          this.btnLoader = false;
          this._toastrService.success(
            'Payment link created! ' + this.paymentSource
          );
          if (
            this.paymentSource == 'cashfree' &&
            body['consultationInfo'] != null &&
            body['consultationInfo']['patientsArray'] != null &&
            body['consultationInfo']['doctorId'] != null
          ) {
            this.gotoPage(3);
            this.paymentLink = res['body']['short_url'];
            this.sendWAMessageForPaymentLinkWithBooking(
              res['body']['id'],
              this.paymentSource,
              this.patientProfile
            );
          } else if (
            this.paymentSource == 'razorpay' &&
            body['consultationInfo'] != null &&
            body['consultationInfo']['patientsArray'] != null &&
            body['consultationInfo']['doctorId'] != null
          ) {
            this.gotoPage(3);
            this.paymentLink = res['body']['short_url'];
            this.sendWAMessageForPaymentLinkWithBooking(
              res['body']['id'],
              this.paymentSource,
              this.patientProfile
            );
          } else {
            this.gotoPage(3);
            this.paymentLink = res['body']['short_url'];
          }
        } else {
          this._toastrService.error('Failed to create payment link');
          this.btnLoader = false;
        }
      })
      .catch((err) => {
        this.btnLoader = false;
        this._toastrService.error('Failed to create payment link');
      });
  }

  sendWAMessageForPaymentLinkWithBooking(
    paymentLinkId: string,
    paymentSource: string,
    patientProfile: User
  ) {
    let promises: Promise<any>[] = [];
    let waNumbers = patientProfile.getWhatsappContactNumbers();
    this._toastrService.info('Sharing the link on WA');
    if (waNumbers && waNumbers.length > 0) {
      for (let waNum of waNumbers) {
        promises.push(
          new Promise((resolve, reject) => {
            let requestBody: any = {};
            requestBody['paymentLinkId'] = paymentLinkId;
            requestBody['recipient'] = waNum.countryCode + '' + waNum.number;
            this._paymentLinksService
              .sendBookingPaymentLinkonWA(requestBody, paymentSource)
              .then((val) => {
                resolve(val);
              })
              .catch((err) => {
                reject(err);
              });
          })
        );
      }
      Promise.all(promises)
        .then((val) => {
          this._toastrService.success("Sent the link on client's whatsapp");
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  payLinkNewSessionDescriptionBody(appointmentBody) {
    /*
    Session with Dr. Aditya for 13 Dec, 10:00 am IST
     */
    let desc = '';
    if (this.doctorProfile && this.doctorProfile.id && appointmentBody) {
      let date = appointmentBody['date'];
      let time = appointmentBody['timeslot'].split('-')[0];
      desc = `Session with ${this.doctorProfile.getFirstNameWithSalutation()} for ${dayjs(
        date,
        'Asia/Kolkata'
      ).format('DD MMM')}, ${this.tConvert(time)} IST`;
      // console.log('desc is : ', desc);
    }
    return desc;
  }

  updatePaymentLinkCollection(body) { }

  copyPaymentLink() {
    this._clipboardService.copyToClipoard(
      this.paymentLink ? this.paymentLink : ''
    );
  }
  validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  copy(text) {
    if (text) {
      this._clipboardService.copyToClipoard(text);
    }
  }

  timeToMin(hm: any) {
    let a = hm.split(':');
    let minutes = +a[0] * 60 + +a[1];
    return minutes;
  }

  minToTime(mins: any) {
    let m = mins % 60;
    let h = ((mins - m) / 60).toString();
    var HHMM =
      (h.length > 1 ? h : '0' + h) + ':' + (m < 10 ? '0' : '') + m.toString();
    return HHMM;
  }

  setSlotLength(num) {
    this.appointmentBookingForm['slotLength'] = num;
  }

  getStatusStyle(status) {
    if (status == 'created') {
      return 'badge bg-primary';
    } else if (status == 'paid') {
      return 'badge bg-success';
    } else if (status == 'cancelled') {
      return 'badge bg-danger';
    } else {
      return 'badge bg-light';
    }
  }

  selectSlot(slot) {
    this.selectedSlot = slot;
    this.appointmentBookingForm['time'] = this.selectedSlot['startTime'];
    this.toggleProviderTimeslots(false);
    this.allowTimeEdit = false;
    // this.selectedSlotLength = this.selectedSlot["sLength"];
  }

  displayCurrentDay() {
    return new Date(this.appointmentBookingForm['date']).getTime();
  }

  tConvert(time) {
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? ' am' : ' pm';
      time[0] = +time[0] % 12 || 12;
    }
    return time.join('');
  }

  addNewEmail(): void {
    this.newPatientForm['emails'].push({ email: '', isPrimary: false });
  }

  addNewContact(): void {
    this.newPatientForm['contacts'].push({
      countryCode: '',
      number: '',
      isPrimaryContactNumber: false,
      isWhatsappContactNumber: false,
    });
  }
  removeEmail(index) {
    if (
      this.newPatientForm['emails'] &&
      this.newPatientForm['emails'].length > 1
    ) {
      this.newPatientForm['emails'].splice(index, 1);
    } else {
      this.newPatientForm['emails'][index] = { email: '', isPrimary: true };
    }
  }
  removeContact(index) {
    if (
      this.newPatientForm['contacts'] &&
      this.newPatientForm['contacts'].length > 1
    ) {
      this.newPatientForm['contacts'].splice(index, 1);
    } else {
      this.newPatientForm['contacts'][index] = {
        countryCode: '',
        number: '',
        isPrimaryContactNumber: true,
        isWhatsappContactNumber: false,
      };
    }
  }
  appointmentDateScroller(direction) {
    if (direction == '+' && this.appointmentBookingForm['date']) {
      this.appointmentBookingForm['date'] = dayjs(
        new Date(this.appointmentBookingForm['date']),
        'Asia/Kolkata'
      )
        .add(1, 'day')
        .format('YYYY-MM-DD');
    } else if (direction == '-' && this.appointmentBookingForm['date']) {
      this.appointmentBookingForm['date'] = dayjs(
        new Date(this.appointmentBookingForm['date']),
        'Asia/Kolkata'
      )
        .subtract(1, 'day')
        .format('YYYY-MM-DD');
    } else {
      //cool
    }
  }
  toggleProviderTimeslots(bool: boolean) {
    this.showAvailableTimeslots = bool;
  }
  editTimeManually() {
    this.toggleProviderTimeslots(false);
    this.allowTimeEdit = true;
    this.appointmentBookingForm['time'] = null;
  }
  handleProviderSlots(slots: any[]) {
    this.currentProviderSlots = slots;
    // this.cdRef.detectChanges();
  }

  get disablePayViaWallet() {
    return (
      +this.paymentLinkForm['amount'] == null || this.walletBalance < +this.paymentLinkForm['amount']
    );
  }

  bookAppointmentWithWalletBalance() {
    if (this.appointmentBookingForm['date'] == null ||
      this.appointmentBookingForm['time'] == null ||
      this.appointmentBookingForm['slotLength'] == null ||
      this.patientProfile == null ||
      this.doctorProfile == null ||
      this.paymentLinkForm['amount'] == null) {
      this._toastrService.error("Something is missing to book the appointment via wallet balance");
    } else {
      let selectedTime = this.appointmentBookingForm['time'];
      let selectedDate = this.appointmentBookingForm['date'];
      let selectedSlotLength = +this.appointmentBookingForm['slotLength'];
      let selectedAmount = +this.paymentLinkForm['amount'];
      this.currentAppointment = new Appointment();
      let _timeslot =
        selectedTime +
        '-' +
        dayjs(
          new Date(selectedDate + ' ' + selectedTime),
          'Asia/Kolkata'
        )
          .add(selectedSlotLength, 'minutes')
          .format('HH:mm');
      this.currentAppointment.timeslot = _timeslot;
      this.currentAppointment.doctorId = this.doctorProfile.id;
      this.currentAppointment.organisationId = this.doctorProfile.id;
      this.currentAppointment.doctorName = this.doctorProfile.fullName();
      this.currentAppointment.organisationName = this.doctorProfile.fullName();
      this.currentAppointment.date = selectedDate;
      if (selectedAmount != null)
        this.currentAppointment.amount = selectedAmount;
      this.currentAppointment.purposeOfVisitTitle = 'Online Consultation';
      this.currentAppointment.purposeOfVisitDescription =
        'Chat and Video (Valid for 72 hrs)';
      this.currentAppointment.modeOfAppointment = 'docvita';
      this.currentAppointment.initiator = new ActionDoneBy();
      this.currentAppointment.initiator.updatedById =
        this.currentHeroInfo.docvitaId;
      this.currentAppointment.initiator.updatedByName =
        this.currentHeroInfo.name;
      this.currentAppointment.initiator.updatedOn = new Date().getTime();
      if (this.payViaWallet) {
        this.currentAppointment.paymentId = firebase
          .database()
          .ref()
          .push().key;
        this.currentAppointment.paymentSource = TransactionSource.DocVitaWallet;
        this.currentAppointment.paymentDoneOn = new Date().getTime();
        this.currentAppointment.totalAmountPaid = selectedAmount;
      }
      let body = classToPlain(this.currentAppointment);
      body['paymentIntent'] = 'payNow';
      if (this.payViaWallet) body['payViaWallet'] = this.payViaWallet;
      body['platform'] = 'whatsapp';
      body['type'] = 'online';

      let patientInfo = {};
      if (
        this.patientProfile &&
        this.patientProfile.docvitaId &&
        this.patientProfile.name &&
        this.patientProfile.primaryContactNumber &&
        this.patientProfile.primaryContactNumber.countryCode &&
        this.patientProfile.primaryContactNumber.number
      ) {
        patientInfo = {
          patientId: this.patientProfile.docvitaId,
          patientName: this.patientProfile.name,
          patientGender: this.patientProfile.gender
            ? this.patientProfile.gender
            : '',
        };

        if (
          this.patientProfile.contactNumbers &&
          this.patientProfile.contactNumbers.size > 0
        ) {
          let contactNumber = this.patientProfile.getPrimaryContactNumbers()[0];
          if (contactNumber.number && contactNumber.number.length > 0) {
            patientInfo['patientPrimaryContactNumber'] = {
              countryCode: contactNumber.countryCode,
              number: contactNumber.number,
            };
          }
        } else if (
          this.patientProfile.primaryContactNumber &&
          this.patientProfile.primaryContactNumber.number?.length > 0
        ) {
          patientInfo['patientPrimaryContactNumber'] = {
            countryCode: this.patientProfile.primaryContactNumber.countryCode,
            number: this.patientProfile.primaryContactNumber.number,
          };
        }

        if (
          this.patientProfile.emails &&
          this.patientProfile.emails.size > 0 &&
          this.patientProfile.getPrimaryEmail()
        ) {
          patientInfo['patientPrimaryEmail'] =
            this.patientProfile.getPrimaryEmail().email;
        } else {
          patientInfo['patientPrimaryEmail'] = this.patientProfile.primaryEmail
            ? this.patientProfile.primaryEmail
            : '';
        }

        body['patientsArray'] = [patientInfo];
      }
      // console.log(body);
      this.btnLoader = true;
      this.apptClashModal
        .openConflictPromise(
          body['doctorId'],
          body['date'],
          body['timeslot'],
          body['doctorName']
        )
        .then((hasConflicts) => {
          if (hasConflicts) {
            this.btnLoader = false;
            this.apptClashModal.openModal();
          } else {
            this.continueBookingAppointment(body);
          }
        })
        .catch((err) => {
          this.continueBookingAppointment(body);
        });
    }
  }

  continueBookingAppointment(body) {
    this._appointmentService
      .bookAppointment(body)
      .then((res) => {
        if (res) {
          console.log(res);
          this.btnLoader = false;
          this._toastrService.success('🎉Appointment booked!');
          this.modalService.hide();
        }
      })
      .catch((err) => {
        console.log(err);
        this._toastrService.error('⚠ Booking failed!');
        this.btnLoader = false;
      });
  }
}
