import { Expose, plainToClass, Transform, Type } from "class-transformer";
import {
	ChatListItemAssociatedProfileType,
	ChatMessageChannel,
} from "../../Enums/chatEnum";
import { BooleanAction } from "../../Protocols/BooleanAction";
import { ChatMessage } from "./ChatMessage";

export class SnoozeChatListItem {
	@Expose() createdOn: number;
	@Expose() bool: boolean;
	@Expose() snoozeTillInMillis: number;
}

export class AssociatedWithItem {
	@Expose() name: string;
	@Expose() type: ChatListItemAssociatedProfileType;
	@Expose() id: string;
}

export class TypingNowItem {
	@Expose() name: string;
	@Expose() lastTypedOn: number;
	@Expose() id: string;

	isTyping(currentTimestamp: number): boolean {
		if (this.lastTypedOn + 5 * 1000 > currentTimestamp) {
			return true;
		}
		return false;
	}
}
export class ChatEntityConnection{
    @Expose() name:string;
    @Expose() id:string;
	@Expose() photoUrl:string;
	@Expose() entityType:string;

	@Expose()
	@Transform(
		(value) => {
			if (value) {
				let map = new Map<string, number>();
				for (let entry of Object.entries(value)) {
					map.set(entry[0], entry[1] as number);
				}
				return map;
			} else {
				return new Map<string, number>();
			}
		},
		{ toClassOnly: true }
	)
	unreadMessages: Map<string, number>;

	get unreadCount(): number {
		return this.unreadMessages != null ? this.unreadMessages.size : 0;
	}
}

export enum DvChatEntities{
    docvita="docvita",
    provider="provider",
    client="client"
}
export class ChatListItem {
	@Expose() chatItemId: string;
	@Expose() displayName: string;
	@Expose() platformSenderId: string;
	@Expose() channel: ChatMessageChannel;
	@Expose() updatedOn: number;

	@Expose()
	@Transform(
		(value) => {
			if (value) {
				let map = new Map<string, number>();
				for (let entry of Object.entries(value)) {
					map.set(entry[0], entry[1] as number);
				}
				return map;
			} else {
				return new Map<string, number>();
			}
		},
		{ toClassOnly: true }
	)
	unreadMessages: Map<string, number>;

	@Expose()
	@Transform(
		(value) => {
			if (value) {
				let map = new Map<string, number>();
				for (let entry of Object.entries(value)) {
					map.set(entry[0], entry[1] as number);
				}
				return map;
			} else {
				return new Map<string, number>();
			}
		},
		{ toClassOnly: true }
	)
	lastFewMessages: Map<string, number>;

	@Type(() => ChatMessage)
	@Expose()
	latestMessage: ChatMessage;

	@Expose() latestCustomerMessageTimestamp: number;

	@Type(() => BooleanAction)
	@Expose()
	isProvider: BooleanAction;

	@Type(() => BooleanAction)
	@Expose()
	isClient: BooleanAction;

	@Type(() => BooleanAction)
	@Expose()
	isResolved: BooleanAction;

	@Type(() => BooleanAction)
	@Expose()
	isUnread: BooleanAction;

	@Expose()
	@Transform(
		(value) => {
			if (value) {
				let map = new Map<string, TypingNowItem>();
				for (let entry of Object.entries(value)) {
					map.set(entry[0], plainToClass(TypingNowItem, entry[1]));
				}
				return map;
			} else {
				return new Map<string, TypingNowItem>();
			}
		},
		{ toClassOnly: true }
	)
	typingMap: Map<string, TypingNowItem>;

	
	@Expose()
	classifications: string[];

	@Expose() assignedTo: string; //Id of a care manager or team

	@Expose()
	@Transform(
		(value) => {
			if (value) {
				let map = new Map<string, AssociatedWithItem>();
				for (let entry of Object.entries(value)) {
					map.set(entry[0], plainToClass(AssociatedWithItem, entry[1]));
				}
				return map;
			} else {
				return new Map<string, AssociatedWithItem>();
			}
		},
		{ toClassOnly: true }
	)
	associatedWithMap: Map<string, AssociatedWithItem> = new Map();

	@Expose() activeEscalations: Map<string, boolean> = new Map();

	@Type(() => SnoozeChatListItem)
	@Expose()
	snoozeSettings: SnoozeChatListItem;

	@Expose() 
    @Transform(
		(value) => {
			if (value) {
				let map = new Map<string, ChatEntityConnection>();
				for (let entry of Object.entries(value)) {
					map.set(entry[0], entry[1] as ChatEntityConnection);
				}
				return map;
			} else {
				return new Map<string, ChatEntityConnection>();
			}
		},
		{ toClassOnly: true }
	)
    connectedEntities:Map<string,ChatEntityConnection>;

	@Expose()
	@Transform(
		(value) => {
			let masterMap: Map<string, Map<string, number>> = new Map<string,Map<string, number>>();
			if (value) {
				for (let entryMap of Object.entries(value)) {
				  let map = new Map<string, number>();
				  if (masterMap.has(entryMap[0])) {
					map = masterMap.get(entryMap[0]);
				  }
				  for (let c of Object.entries(entryMap[1])) {
					map.set(c[0], c[1]);
				  }
				  masterMap.set(entryMap[0], map);
				}
			  }
			  return masterMap;
		},
		{ toClassOnly: true }
	)
	unreadMessagesForEntites: Map<string, Map<string, number>> = new Map<string, Map<string, number>>();

	get unreadCount(): number {
		return this.unreadMessages != null ? this.unreadMessages.size : 0;
	}

	get escalationCount(): number {
		return this.activeEscalations != null ? this.activeEscalations.size : 0;
	}

	is24WindowActive(): boolean {
		if (
			this.latestCustomerMessageTimestamp &&
			this.latestCustomerMessageTimestamp + 24 * 60 * 60 * 1000 >
				new Date().getTime()
		) {
			return true;
		} else {
			return false;
		}
	}

	whoIsTyping(idToNotShow?: string): string {
		let currentTime = new Date().getTime();
		let arr: string[] = [];
		if (this.typingMap) {
			this.typingMap.forEach((val, key) => {
				if (
					val.isTyping(currentTime) &&
					(!idToNotShow || (idToNotShow && idToNotShow != val.id))
				) {
					arr.push(val.name);
				}
			});
		}
		if (arr.length > 0) {
			return (
				arr.join(", ") + " " + (arr.length > 1 ? "are" : "is") + " typing..."
			);
		} else {
			return null;
		}
	}
}


