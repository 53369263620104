// import * as PouchDB from 'pouchdb';
import { plainToClass } from 'class-transformer';
import PouchdbUpsert from 'pouchdb-upsert';
import PouchDB from 'pouchdb';
import PouchdbFind from 'pouchdb-find';
PouchDB.plugin(PouchdbFind);
PouchDB.plugin(PouchdbUpsert);

import { ChatListItem } from 'src/app/shared/models-ts/Entities/chat/ChatListItem';

export class InitChatDB {
  db = new PouchDB('chat-list', { revs_limit: 1, auto_compaction: true });

  constructor() {
    this.db.info().then(function (info) {
      // console.log(info);
    });
  }

  get(_id: string) {
    return new Promise<ChatListItem>((resolve, reject) => {
      this.db
        .get(_id)
        .then((doc) => {
          // console.log(doc);
          resolve(plainToClass(ChatListItem, doc));
        })
        .catch((err) => {
          console.error(err);
          resolve(null);
        });
    });
  }

  put(_id: string, json: any) {
    json['_id'] = _id;
    this.db
      .upsert(_id, function (doc: any) {
        if (!doc.count) {
          doc.count = 0;
        }
        doc.count++;
        for (let fieldId in json) {
          if (doc) doc[fieldId] = json[fieldId];
        }
        return doc;
      })
      .then(function (res) {
        // success, res is {rev: '1-xxx', updated: true, id: 'myDocId'}
      })
      .catch(function (err) {
        // error
        console.error(err);
      });
  }
}
