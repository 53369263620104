import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CRMUser } from 'src/app/shared/models-ts/Actors/User/CRMUser';
import { AuthUserInfoService } from 'src/app/shared/services/auth-user-info/auth-user-info.service';
import { TextBlurService } from 'src/app/shared/services/text-blur/text-blur.service';
import { AppUpdatesService } from 'src/app/shared/services/app-updates/app-updates.service';
import { AuthApiService } from 'src/app/auth/services/auth-api.service';
import { TitleMetaService } from 'src/app/services/title-meta.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  currentHeroInfo: CRMUser;
  textBlur: boolean = false;
  frequentOptions: CRMOption[] = [
    new CRMOption(
      'Patients ❤️',
      'Help our Patients have amazing appointment experience at DocVita',
      'patient-bookings'
    ),
    new CRMOption(
      'Specialists 👨🏻‍⚕️👩🏻‍⚕️',
      'Doctors, Dentists, Psychologists at DocVita',
      'profile-listing'
    ),
    new CRMOption(
      'Chats 💬',
      'Whatspp Chat Support - Zoko',
      'chats'
    ),
  ];

  options: CRMOption[] = [
    new CRMOption(
      'Help Center',
      'Support center tickets',
      'help-center'
    ),
  ];
  availableOptions: CRMOption[] = [];
  availableFrequentOptions: CRMOption[] = [];

  constructor(
    private _authUserInfoService: AuthUserInfoService,
    private _router: Router,
    private _authApiService: AuthApiService,
    private _activatedRoute: ActivatedRoute,
    private _blurTextService: TextBlurService,
    private appUpdatesService: AppUpdatesService,
    private _titleMetaService: TitleMetaService
  ) { }

  ngOnInit(): void {
    this.currentHeroInfo = this._authUserInfoService.getUserInfo();
    if (this.currentHeroInfo) {
      this._authUserInfoService.getHealthHeroProfile(this.currentHeroInfo.docvitaId);
    }
    // console.log('currentHeroInfo', this.currentHeroInfo);
    this.filterFrequentOptions();
    this.filterAvailableOptions();
    this.getTextBlur();

    this._titleMetaService.setTitleDescription("Home | DocVita HQ", "");
  }

  logoutCurrentUser() {
    this._authUserInfoService.signout();
  }

  toggleTextBlur() {
    this._blurTextService.setBlurState(this.textBlur);
    window.location.reload();
  }

  getTextBlur() {
    this.textBlur = this._blurTextService.getBlurState();
  }

  filterFrequentOptions() {
    if (this.currentHeroInfo != null) {
      for (let op of this.frequentOptions) {
        switch (op.route) {
          case 'profile-listing': {
            if (
              this.currentHeroInfo != null &&
              this.currentHeroInfo['access'] != null &&
              this.currentHeroInfo['access']
                .canAccessDoctorNetwork
            ) {
              this.availableFrequentOptions.push(op);
            }
            break;
          }
          case 'patient-bookings': {
            if (
              this.currentHeroInfo != null &&
              this.currentHeroInfo['roles'] != null &&
              this.currentHeroInfo['access']
                .canAccessDoctorNetwork
            ) {
              this.availableFrequentOptions.push(op);
            }
            break;
          }
          case 'chats': {
            if (
              this.currentHeroInfo != null &&
              this.currentHeroInfo['roles'] != null &&
              this.currentHeroInfo['access']
                .canAccessDoctorNetwork
            ) {
              this.availableFrequentOptions.push(op);
            }
            break;
          }
        }
      }
    }
  }

  filterAvailableOptions() {
    if (this.currentHeroInfo != null) {
      for (let op of this.options) {
        switch (op.route) {
          case 'dashboard': {
            if (
              this.currentHeroInfo != null &&
              this.currentHeroInfo['access'] != null &&
              this.currentHeroInfo['access'].canAccessDashboard
            ) {
              this.availableOptions.push(op);
            }
            break;
          }
          case 'help-center': {
            if (
              this.currentHeroInfo != null &&
              this.currentHeroInfo['access'] != null &&
              this.currentHeroInfo['access'].canAccessDashboard
            ) {
              this.availableOptions.push(op);
            }
            break;
          }
        }
      }
    }
  }

  goTo(route: string, fragment?: string) {
    console.log(route);
    if (route === 'dashboard') {
      this._router.navigate(['../' + route], {
        relativeTo: this._activatedRoute,
      });
    } else if (route === 'profile-listing') {
      this._router.navigate(['../' + route], {
        relativeTo: this._activatedRoute,
        // queryParams: {
        //   "isProfileClaimed": true
        // }
      });
    }
    else {
      if (fragment) {
        this._router.navigate(['../' + route], {
          relativeTo: this._activatedRoute,
          fragment: fragment,
        });
      } else {
        this._router.navigate(['../' + route], {
          relativeTo: this._activatedRoute,
        });
      }
    }
  }

  reloadApp() {
    this.appUpdatesService.forceRefreshApp()
  }

  toggle(city: string) {
    const cityEl = document.getElementById(city+"_btn");
    const contentEl = document.getElementById(city+"_content");
    if(cityEl.classList.contains("active")) {
      cityEl.classList.remove("active");
    } else {
      cityEl.classList.add("active");  
    }
    if(contentEl.classList.contains("active")) {
      contentEl.classList.remove("active");
    } else {
      contentEl.classList.add("active");  
    }
  }
}

class CRMOption {
  title: string;
  subtitle: string;
  route: string;
  fragment: string;
  imageUrl: string;

  constructor(
    title: string,
    subtitle: string,
    route: string,
    fragment?: string,
    imageUrl?: string
  ) {
    this.title = title;
    this.subtitle = subtitle;
    this.route = route;
    if (fragment) this.fragment = fragment;
    if (imageUrl) this.imageUrl = imageUrl;
  }
}
