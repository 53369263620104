import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DoctorListingProfile } from '../../models-ts/Actors/Doctor/DoctorListingProfile';

/**
 * This component is to generate UI for practice selection
 */

@Component({
  selector: 'app-appt-type-selector',
  templateUrl: './appt-type-selector.component.html',
  styleUrls: ['./appt-type-selector.component.scss']
})
export class ApptTypeSelectorComponent implements OnInit {
  practiceOptions: { key: string, value: string }[] = []

  _isInPerson: boolean = false;
  set isInPerson(value: boolean) {
    this._isInPerson = value;
    this.selection.emit({
      isInPerson: value,
      practiceSelection: this.practiceSelection
    })
  }

  get isInPerson(): boolean { return this._isInPerson; }

  _practiceSelection: { key: string, value: string } = {
    key: null,
    value: null
  };

  set practiceSelection(val: { key: string, value: string }) {
    this._practiceSelection = val;
    this.selection.emit({
      isInPerson: this.isInPerson,
      practiceSelection: val
    })
  }

  get practiceSelection() {
    return this._practiceSelection;
  }

  _provider: DoctorListingProfile;
  @Input() set provider(profile: DoctorListingProfile) {
    this._provider = profile;
    this.populatePracticeOptions();
  }

  get provider(): DoctorListingProfile {
    return this._provider;
  }

  @Output() selection = new EventEmitter<{ isInPerson: boolean, practiceSelection: { key: string, value: string } }>();

  constructor() { }

  ngOnInit(): void {
  }

  populatePracticeOptions() {
    this.practiceOptions = [];
    if (this.provider?.associatedPractices?.size > 0) {
      for (const i of this.provider?.associatedPractices) {
        if (i[1]?.acceptingAppointmentsOnDocVita) {
          this.practiceOptions.push({ key: i[0], value: i[1].name })
        }
      }
      if (this.practiceOptions?.length > 0) this.practiceSelection = this.practiceOptions[0];
    }
  }

}
