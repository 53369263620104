<ng-template id="commonPhrasesModal" #commonPhrasesModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Common Phrases</h4>
    </div>
    <div class="modal-body d-flex flex-column justify-content-center w-100">
       
    </div>
    <div class="modal-footer">
        <button (click)="closeModal()" class="ms-2 btn btn-outline-secondary">Close</button>
    </div>

</ng-template>