import * as dayjs from "dayjs";
import * as c from 'dayjs/plugin/customParseFormat';
dayjs.extend(c);
import * as t from "dayjs/plugin/timezone";
import * as rt from "dayjs/plugin/relativeTime";
import * as utc from "dayjs/plugin/utc";
dayjs.extend(rt);
dayjs.extend(t);
dayjs.extend(utc);

export class DayjsHelper {
    private timezone: string;
    constructor(timezoneVal?: string) {
        if (timezoneVal) {
            this.timezone = timezoneVal;
            dayjs.tz.setDefault(this.timezone);
        }
    }

    getDayjs() {
        return dayjs;
    }
    currentTime() { return new Date().getTime(); }

    todayYYYYMMDD() { return dayjs().format("YYYY-MM-DD"); }

    tomorrowYYYYMMDD() { return dayjs().add(24, "hours").format("YYYY-MM-DD"); }

    yesterdayYYYYMMDD() { return dayjs().subtract(24, "hours").format("YYYY-MM-DD"); }

    convertISTToLocal(dateYYYYMMDD: string, timeHHmm: string, print?: boolean) {
        let obj = dayjs(new Date(dateYYYYMMDD + " " + timeHHmm).getTime());
        if (print) console.log(dateYYYYMMDD, timeHHmm, dayjs.tz(dateYYYYMMDD + " " + timeHHmm, "Asia/Kolkata").valueOf());
        let time = obj.tz(this.timezone).format("HH:mm");
        let date = obj.tz(this.timezone).format("YYYY-MM-DD");
        return {
            dateYYYYMMDD: time == "00:00" ? obj.tz(this.timezone).add(1, "day").format("YYYY-MM-DD") : date,
            timeHHmm: time
        }
    }

    /**
     * Converts date and time in IST to Unix timestamp.
     * @param dateStr - Date in 'YYYYMMDD' format.
     * @param timeStr - Time in 'HHmm' format.
     * @returns Unix timestamp
     */
    convertISTtoUnix(dateStr: string, timeStr: string): number {
        // Combine date and time strings
        const dateTimeStr = `${dateStr} ${timeStr}`;

        // Parse the combined string into dayjs object in IST timezone ('Asia/Kolkata')
        const dateTimeInIST = dayjs.tz(dateTimeStr, "YYYYMMDD HHmm", "Asia/Kolkata");

        // Convert to Unix timestamp
        return dateTimeInIST.unix();
    }

    /**
     * Converts a Unix timestamp to date and time in IST.
     * @param unixTimestamp - The Unix timestamp to convert.
     * @returns Date and time string in 'YYYY-MM-DD' & 'HH:mm' format in IST.
     */
    convertUnixToISTDateTime(unixTimestamp: number) {
        // Convert Unix timestamp to dayjs object in IST timezone ('Asia/Kolkata')
        const dateTimeInIST = dayjs.unix(unixTimestamp).tz('Asia/Kolkata');

        // Format the date and time in 'YYYY-MM-DD HH:mm' format
        return {
            date: dateTimeInIST.format('YYYY-MM-DD'),
            time: dateTimeInIST.format('YYYY-MM-DD HH:mm')
        };
    }

    YYYYMMDDAddXMins(dateYYYYMMDD: string, mins: number) {
        return dayjs(dateYYYYMMDD).add(mins, "minutes").format("YYYY-MM-DD");
    }
    YYYYMMDDAddXMinshhmma(dateYYYYMMDD: string, mins: number) {
        return dayjs(dateYYYYMMDD).add(mins, "minutes").format("hh:mm a");
    }

    YYYYMMDDHHmmToUnix(dateYYYYMMDD: string, timeHHmm: string) {
        return new Date(dateYYYYMMDD + " " + timeHHmm).getTime();
    }

    YYYYMMDDHHmmssToUnix(dateYYYYMMDD: string, timeHHmmss: string) {
        return dayjs(dateYYYYMMDD + " " + timeHHmmss).valueOf();//  new Date(dateYYYYMMDD+" "+timeHHmmss).getTime();
    }

    YYYYMMToMMMYY(YYYYMM: string) {
        return dayjs(YYYYMM + "-01 00:01").format("MMMYY");//  new Date(dateYYYYMMDD+" "+timeHHmmss).getTime();
    }

    YYYYMMToFirstAndLastDate(YYYYMM: string) {
        // Use dayjs to create objects for the first and last day of the month
        const firstDate = dayjs(YYYYMM + "-01");
        const lastDate = dayjs(firstDate).endOf('month');

        // Format both dates to yyyy-mm-dd format
        const formattedFirstDate = firstDate.format('YYYY-MM-DD');
        const formattedLastDate = lastDate.format('YYYY-MM-DD');

        // Return an object containing both dates
        return {
            firstDate: formattedFirstDate,
            lastDate: formattedLastDate,
        };
    }

    unixToHHmm(millis: number) {
        return dayjs.tz(millis).format("HH:mm");
    }

    unixToHHmmWithoutTz(millis: number) {
        return dayjs(millis).format("HH:mm");
    }

    unixTohhmma(millis: number) {
        return dayjs.tz(millis).format("hh:mm a");
    }

    unixToYYYYMMDD(millis: number) {
        return dayjs.tz(millis).format("YYYY-MM-DD");
    }

    HHmmtohhmma(HHmm: string) {
        return dayjs(this.todayYYYYMMDD() + " " + HHmm).format("hh:mm a");
    }

    YYYYMMDDToEEEMMMd(dateYYYYMMDD: string) {
        return dayjs(dateYYYYMMDD).format("ddd, MMM D");
    }

    YYYYMMDDToEEEDDMMMYYYY(dateYYYYMMDD: string) {
        return dayjs(dateYYYYMMDD).format("ddd, DD MMM YYYY");
    }

    getWeekMonToSun(dateYYYYMMDD: string) {
        const currentDay = dayjs(dateYYYYMMDD).get("d");
        let monday: string;
        let sunday: string;
        if (currentDay == 1) {
            monday = dateYYYYMMDD;
            sunday = dayjs(dateYYYYMMDD).add(6, "day").format("YYYY-MM-DD");
        } else if (currentDay == 0) {
            sunday = dateYYYYMMDD;
            monday = dayjs(dateYYYYMMDD).subtract(6, "day").format("YYYY-MM-DD");
        } else {
            sunday = dayjs(dateYYYYMMDD).add(7 - currentDay, "day").format("YYYY-MM-DD");;
            monday = dayjs(dateYYYYMMDD).subtract(currentDay - 1, "day").format("YYYY-MM-DD");
        }
        return {
            monday: monday,
            sunday: sunday
        };
    }

    unixToDDMMYYYY(millis: number) {
        return dayjs.tz(millis).format("DD/MM/YYYY");
    }

    YYYYMMDDToDDMMYYYY(YYYYMMDD: string) {
        return dayjs(YYYYMMDD).format("DD/MM/YYYY");
    }

    unixToDDMMMYYYY(millis: number) {
        return dayjs.tz(millis).format("DD MMM YYYY");
    }

    YYYYMMDDtoDDMMMYY(YYYYMMDD: string) {
        return dayjs(YYYYMMDD).format("DD MMM YY");
    }

    YYYYMMDDtoDDMMM(YYYYMMDD: string) {
        return dayjs(YYYYMMDD).format("DD MMM");
    }

    dateTimeToReadable(dateYYYYMMDD: string, timeHHmm: string) {
        return dayjs(dateYYYYMMDD + ' ' + timeHHmm).format("DD MMM YY, hh:mm a");
    }

    dateTimeToReadableLonger(dateYYYYMMDD: string, timeHHmm: string) {
        return dayjs(dateYYYYMMDD + ' ' + timeHHmm).format("dddd, D MMM hh:mm a");
    }

    dateTimeToddddMMMD_at_hhmma(dateYYYYMMDD: string, timeHHmm: string) {
        return dayjs(dateYYYYMMDD).format("ddd, MMM D") + ' at ' + dayjs(dateYYYYMMDD + ' ' + timeHHmm).format("hh:mm a");
    }

    dateTimeToddddDMMM_at_hhmma(dateYYYYMMDD: string, timeHHmm: string) {
        return dayjs(dateYYYYMMDD).format("ddd, D MMM") + ' at ' + dayjs(dateYYYYMMDD + ' ' + timeHHmm).format("hh:mm a");
    }

    dateTodddDMMM(dateYYYYMMDD: string) {
        return dayjs(dateYYYYMMDD).format("ddd, D MMM")
    }

    unixToddddDMMMYYYY(millis: number) {
        return dayjs(millis).format("dddd, D MMM YYYY");
    }

    addDaysToYYYYMMDD(YYYYMMDD: string, days: number) {
        return dayjs(YYYYMMDD).add(days, 'day').format("YYYY-MM-DD");
    }
    subtractDaysFromYYYYMMDD(YYYYMMDD: string, days: number) {
        return dayjs(YYYYMMDD).subtract(days, 'day').format("YYYY-MM-DD");
    }

    YYYYMMDDToMMM(dateYYYYMMDD: string) {
        return dayjs(dateYYYYMMDD).format("MMM");
    }

    timeDiffInHoursAndMins(toDateYYYYMMDD: string, toTimeHHmm: string, fromDateYYYYMMDD?: string, fromTimeHHmm?: string) {
        if (!fromDateYYYYMMDD) {
            fromDateYYYYMMDD = this.todayYYYYMMDD();
            fromTimeHHmm = this.unixToHHmm(this.currentTime())
        }
        const diff = Math.abs(new Date(toDateYYYYMMDD + ' ' + toTimeHHmm).valueOf() - new Date(fromDateYYYYMMDD + ' ' + fromTimeHHmm).valueOf());
        return this.timeDiffMillisToDuration(diff)

    }

    timeDiffWithoutBreakdown(toDateYYYYMMDD: string, toTimeHHmm: string, fromDateYYYYMMDD?: string, fromTimeHHmm?: string) {
        if (!fromDateYYYYMMDD) {
            fromDateYYYYMMDD = this.todayYYYYMMDD();
            fromTimeHHmm = this.unixToHHmm(this.currentTime())
        }

        const diff = Math.abs(new Date(toDateYYYYMMDD + ' ' + toTimeHHmm).valueOf() - new Date(fromDateYYYYMMDD + ' ' + fromTimeHHmm).valueOf());
        return this.timeDiffMillisToDurationWithoutBreakdown(diff);
    }

    timeDiffMillisToDurationWithoutBreakdown(millis: number) {

        const diff = millis;
        const seconds = Math.floor(diff / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        return {
            days,
            hours,
            minutes,
            seconds
        }
    }
    timeDiffMillisToDuration(millis: number) {

        var delta = Math.floor(Math.abs(millis) / 1000);

        // const mins = Math.floor(secs / 60);
        // const hours = Math.floor(mins / 60);
        // const days = Math.floor(hours / 24);

        var days = Math.floor(delta / 86400);
        delta -= days * 86400;

        var hours = Math.floor(delta / 3600) % 24;
        delta -= hours * 3600;

        var minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;

        var seconds = Math.floor(delta % 60);

        return {
            days,
            hours,
            minutes,
            seconds
        }
    }

    endTimeForStartTimeHHmm(startTime: string, durationInMins: number) {
        const today = this.todayYYYYMMDD();
        const dateTimeUnix = this.YYYYMMDDHHmmToUnix(today, startTime)
        return dayjs(dateTimeUnix).add(+durationInMins, 'minutes').format('HH:mm')
    }

}