import { Expose } from "class-transformer";
export class ProviderDocument {
  @Expose() id: string;
  @Expose() clientId: string;
  @Expose() providerId: string;
  @Expose() appointmentId: string;
  @Expose() leadId: string;
  @Expose() fileType: FileType;
  @Expose() fileUrl: string;
  @Expose() fileDocumentType: string;
  @Expose() fileId: string;
  @Expose() title: string;
  @Expose() description: string;
  @Expose() uploadedOn: number;
  @Expose() uploadedById: string;
  @Expose() uploadedByName: string;
  @Expose() isAvailableToAll: boolean;
  @Expose() isDeleted: boolean;
  @Expose() shortCode: string;
  @Expose() source: FileSource;
}

export enum FileType {
  prescription = "prescription",
  report = "report",
  file = "file",
}

export enum FileSource {
  doctor = "doctor",
  patient = "patient",
  docvita = "docvita",
}
