<div class="my-4 py-2 px-4" style="border: 1px solid #bbb; border-radius: 8px">
  <h4>Create Claim</h4>
  <div class="form-row">
    <div class="form-group col-md-5">
      <label for="">Contact Number</label>
      <div class="d-flex">
        <input
          type="number"
          class="form-control me-2"
          id=""
          placeholder="Country Code"
          [(ngModel)]="countryCode"
          required
        />
        <input
          type="number"
          class="form-control"
          id=""
          placeholder="Contact Number"
          [(ngModel)]="mobileNumber"
          required
        />
      </div>
    </div>
    <div class="form-group col-md-5">
      <label for="inputEmail">Email</label>
      <input
        type="email"
        class="form-control"
        id="inputEmail"
        placeholder="Email"
        [(ngModel)]="email"
        email
        required
      />
    </div>
  </div>
  <div class="mb-3">
    <div class="d-flex flex-row">
      <h6 class="me-4">Upload Government ID Documents</h6>
      <input
        type="file"
        id="files"
        name="Upload Documents"
        (change)="fileGovernmentIDProgress($event)"
        accept="image/x-png,image/jpeg,application/pdf"
        multiple
      />
    </div>
    <div *ngFor="let f of filesForGovernmentIDs">
      <span>{{ f.name }}</span>
      <button
        (click)="removeFileFromGovernmentIdList(f)"
        class="ms-2 mt-1 btn btn-sm btn-danger"
      >
        X
      </button>
    </div>
    <hr />
  </div>
  <div class="mb-3">
    <div class="d-flex flex-row">
      <h6 class="me-4">Upload Medical Registration Documents</h6>
      <input
        type="file"
        id="files"
        name="Upload Documents"
        (change)="fileMedicalRegistrationProgress($event)"
        accept="image/x-png,image/jpeg,application/pdf"
        multiple
      />
    </div>
    <div *ngFor="let f of filesForMedicalRegistrations">
      <span>{{ f.name }}</span>
      <button
        (click)="removeFileFromMedicalRegistrationDocsList(f)"
        class="ms-2 mt-1 btn btn-sm btn-danger"
      >
        X
      </button>
    </div>
  </div>

  <button (click)="createClaimClicked()" class="mb-2 btn btn-sm btn-primary">
    Create Claim
  </button>
</div>
<div class="d-flex flex-column">
  <div id="existing-claims" *ngIf="claims.length > 0">
    <h4>Existing Claims</h4>
    <div *ngFor="let c of claims">
      <div>
        <span
          >+ {{ c.primaryContactNumber.countryCode }} -
          {{ c.primaryContactNumber.number }}, {{ c.email }}</span
        ><br />
        <span>{{ c.status }}</span>
        <div>
          <b><span>Medical Registration Files</span></b>
          <div *ngFor="let a of c.medicalRegistrationProofUrls">{{ a }}</div>
          <b><span>Government ID Files</span></b>
          <div *ngFor="let a of c.governmentIdProofUrls">{{ a }}</div>
        </div>
      </div>
      <div>
        <button
          (click)="processingClaim(c)"
          *ngIf="c.status == 'Submitted'"
          class="btn btn-sm btn-primary me-2"
        >
          Mark as processing
        </button>
        <button
          (click)="approveClaim(c)"
          *ngIf="c.status == 'Processing'"
          class="btn btn-sm btn-success me-2"
        >
          Approve
        </button>
        <button
          (click)="declineClaim(c)"
          *ngIf="c.status == 'Processing' || c.status == 'Submitted'"
          class="btn btn-sm btn-danger me-2"
        >
          Decline
        </button>
      </div>
      <hr />
    </div>
  </div>
</div>

<app-manage-login-credentials></app-manage-login-credentials>