import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Currency } from '../../models-ts/Helpers/countrywiseFeeCalc';

@Component({
  selector: 'currency-dropdown',
  templateUrl: './currency-dropdown.component.html',
  styleUrls: ['./currency-dropdown.component.scss']
})
export class CurrencyDropdownComponent implements OnInit {
  @Input() isDisabled: boolean = false;
  @Input() set defaultValue(val: Currency) {
    this.currentCurrency = val;
  };
  currenciesToSelect = [Currency.INR, Currency.AED, Currency.USD];
  currentCurrency: Currency = Currency.INR;

  @Output() selectedCurrency: EventEmitter<Currency> = new EventEmitter<Currency>();

  constructor() { }

  ngOnInit(): void {
  }

  setCurrency(val: Currency) {
    this.currentCurrency = val;
    this.selectedCurrency.emit(val);
  }
}
