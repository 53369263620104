<div class="card" *ngIf="selectedDoctor">
  <div class="card-body d-flex justify-content-between align-items-stretch p-2">
    <div
      class="d-flex align-items-center flex-fill pe-2"
      [ngClass]="{ 'border-right': showActions }"
    >
      <!-- style="
          height: 80px;
          width: 80px;
          object-fit: cover;
          margin-right: 12px;
          border-radius: 8px;
        " -->
      <img
        class="doc-image me-2"
        [src]="
          doesPhotographExist()
            ? noCacheImage(selectedDoctor.photoUrl)
            : noCacheImage(defaultLogoUrl)
        "
      />
      <div class="flex-fill">
        <div id="full-name" class="d-flex align-items-center">
          <h2 class="mb-0 me-2">{{ selectedDoctor.fullName() }}</h2>
          <button
            type="button"
            class="btn btn-sm btn-outline-primary border-0"
            (click)="copyDoctorsFullName()"
            [disabled]="false"
          >
            <i style="font-size: 15px" class="material-icons me-1"
              >content_copy</i
            >
          </button>
          <span
            *ngIf="selectedDoctor.isProfileClaimed"
            class="badge bg-success ms-auto"
            >Claimed</span
          >
          <span
            *ngIf="!selectedDoctor.isProfileClaimed"
            class="badge bg-warning ms-auto"
            >Not Claimed</span
          >
        </div>
        <div id="specialisation" class="d-flex align-items-center mt-1">
          <span class="me-2">{{ selectedDoctor.specializations }}</span>
          <button
            type="button"
            class="btn btn-sm btn-outline-primary border-0"
            (click)="copySpecializations()"
            [disabled]="
              selectedDoctor.specializations == null ||
              selectedDoctor.specializations.length == 0
            "
          >
            <i style="font-size: 15px" class="material-icons me-1"
              >content_copy</i
            >
          </button>
        </div>
        <div
          id="gender"
          class="d-flex justify-content-between align-items-center mt-1"
        >
          <span class="mb-0" style="text-transform: capitalize">
            {{ selectedDoctor.gender }} {{selectedDoctor.pronoun ? '('+selectedDoctor.pronoun+')': "" }}
          </span>
        </div>
        <div class="d-flex align-items-center mt-1" id="about">
          <span class="me-2">ID: {{ selectedDoctor.id }}</span>
          <!-- <span class="mb-0 pointer" style="text-transform: capitalize" data-toggle="tooltip" data-placement="left"
            [title]="selectedDoctor.about">
            {{
            selectedDoctor.about?.length > 0
            ? selectedDoctor.about.slice(0, 80) + "..."
            : "n/a"
            }}
          </span> -->
          <button
            type="button"
            class="btn btn-sm btn-outline-primary border-0"
            (click)="copyProviderID()"
            [disabled]="
              selectedDoctor.specializations == null ||
              selectedDoctor.specializations.length == 0
            "
          >
            <i style="font-size: 15px" class="material-icons me-1"
              >content_copy</i
            >
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
