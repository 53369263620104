import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { plainToClass } from 'class-transformer';
import { User } from '../../models-ts/Actors/User/User';
import { Appointment } from '../../models-ts/Entities/Appointment';
import { AppointmentService } from '../../services/appointment/appointment.service';
import { CopyClipboardService } from '../../services/copy-clipboard/copy-clipboard.service';
import { PatientService } from '../../services/patient/patient.service';

@Component({
  selector: 'app-patient-info-modal',
  templateUrl: './patient-info-modal.component.html',
  styleUrls: ['./patient-info-modal.component.css'],
})
export class PatientInfoModalComponent implements OnInit {
  allAppointments: Appointment[] = [];
  isLoadingAppointments: boolean = false;
  isLoadingPatient: boolean = false;
  modalPatientProfile: User = new User();
  @Input() patient: User = new User();
  @Input() patientId: string;
  @Input() btnText: string = 'View Patient';
  constructor(
    private _patientService: PatientService,
    private _appointmentService: AppointmentService,
    private _copyToClipboardService: CopyClipboardService,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {}

  setPatientFromSearch() {
    this.modalPatientProfile = new User();
    if (this.patient && this.patient.docvitaId) {
      this.modalPatientProfile = this.patient;
      this.getAppointments(this.modalPatientProfile.docvitaId);
      // if (this.modalService.getModalsCount() > 0) {
      //   this.modalService.hide();
      // }
      this.openModal();
    } else if (this.patientId && this.patientId.length > 0) {
      this.isLoadingPatient = true;
      this._patientService
        .getPatientById(this.patientId)
        .then((snap) => {
          if (snap.exists) {
            let _ = plainToClass(User, snap.data());
            this.modalPatientProfile = _;
            this.getAppointments(this.modalPatientProfile.docvitaId);
            if (this.modalService.getModalsCount() > 0) {
              this.modalService.hide();
            }
            this.openModal();
          }
          this.isLoadingPatient = false;
        })
        .catch((err) => {
          this.isLoadingPatient = false;
        });
    }
  }
  copy(message: string) {
    if (message) {
      this._copyToClipboardService.copyToClipoard(message);
    }
  }
  copyContact(patient) {
    let _ = this.patientContactInfo(patient);
    let __ = '+' + _.countryCode + _.number;
    this.copy(__);
  }
  patientContactInfo(patientProfile: User) {
    if (patientProfile && patientProfile.primaryContactNumber) {
      let contact = patientProfile.primaryContactNumber;
      if (contact.number && contact.number.length > 0) {
        return contact;
      } else {
        return null;
      }
    } else if (patientProfile && patientProfile.contactNumbers.size > 0) {
      let primary = patientProfile.getPrimaryContactNumbers()[0];
      return primary;
    } else {
      return null;
    }
  }

  openAppointmentInNewTab(appointment: Appointment) {
    //http://localhost:4200/patient-bookings/-Mg0y28Omf6FqGv-d4UH?from=2021-07-31&status=BOOKED&sortBy=createdOn&qk=docvitaId&qv=x823c41b1p6d8ap473cp841ep3623f8911462&to=2021-07-31
    let _ =
      window.location.origin +
      '/patient-bookings/ld_' +
      appointment.appointmentId +
      '?from=' +
      appointment.date +
      '&to=' +
      appointment.date +
      '&status=BOOKED&sortBy=bookingDate&qk=docvitaId&qv=' +
      appointment.doctorId;
    window.open(_, '_blank');
  }

  @ViewChild('patientInfo') modalRef;
  openModal(content?, size?) {
    this.modalService.show(this.modalRef, { class: 'modal-lg' });
  }

  getAppointments(userId: string) {
    this.allAppointments = [];
    this.isLoadingAppointments = true;
    this._appointmentService
      .getAppointmentsForPatient(userId)
      .subscribe((res) => {
        this.allAppointments = [];
        this.isLoadingAppointments = false;
        if (res != null && res['statusCode'] == 200) {
          // console.log('getAppointments', res['body']);
          let upcomingAppointments = res['body']['upcomingAppointments'];
          let pastAppointments = res['body']['pastAppointments'];

          if (upcomingAppointments) {
            for (let a of upcomingAppointments) {
              if (a.date && a.timeslot) {
                this.allAppointments.push(plainToClass(Appointment, a));
              }
            }
          }
          if (pastAppointments) {
            for (let a of pastAppointments) {
              if (a.date && a.timeslot) {
                this.allAppointments.push(plainToClass(Appointment, a));
              }
            }
          }
          this.allAppointments.sort((a, b) => {
            return b.date.localeCompare(a.date);
          });
          console.log(this.allAppointments);
        } else {
        }
      });
  }
  closeModal() {
    this.modalService.hide();
  }
}
