<ng-template #chatUploadFileModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title">
            <i class="feather icon-file-plus"></i>
            Upload File
        </h4>
        <div class="ms-auto d-flex align-items-center">
            <button type="button" class="close" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <div class="modal-body d-flex flex-column w-100 p-3">
        <ng-container *ngIf="fileDetails.length>0">
            <button class="btn btn-outline-primary btn-sm mb-3 mt-2 ms-auto me-auto" style="width: fit-content;" (click)="fileUpload.click()"><i class="feather icon-file-plus"></i> Choose files</button>
            <div class="w-100 d-flex flex-wrap justify-content-center">
                <div class="card __preview_card position-relative m-3" *ngFor="let item of fileDetails;let i=index;">
                    <div class="cross_mark" (click)="discardFile(i)">
                        &times;
                    </div>
                    <img [src]="item.obj" class="card-img-top p-1 __custom_img" [alt]="'file'+i" *ngIf="item.contentType.includes('image')">
                    <div *ngIf="!item.contentType.includes('image')" class="card-img-top __custom_img pt-4 pb-4 d-flex justify-content-center align-items-center">
                        <i class="feather icon-file text-primary" style="font-size: 50px;"></i>
                    </div>
                    <div class="card-body p-1" style="font-size: 12px;text-align: center;" [tooltip]="item.name" placement="bottom">
                      {{(item.name&&item.name.length>30)? (item.name | slice:0:30)+'..':(item.name)}}
                    </div>
                  </div>
              </div>
        </ng-container>
        
        <div class="w-100 d-flex flex-column align-items-center mt-5 mb-5" *ngIf="fileDetails.length==0">
            <div class="mb-2 text-muted">No files selected</div>
            <button class="btn btn-outline-primary" (click)="fileUpload.click()"><i class="feather icon-upload"></i> Choose files</button>
        </div>
        <input type="file" style="display:none" (change)="addFileLocally($event)" #fileUpload multiple>
    </div>
    <div class="modal-footer d-flex align-items-center">
        <div class="text-muted me-2">
            {{fileDetails.length>0?(fileDetails.length+' '+(fileDetails.length==1?'file':'files')+' selected'):''}}
        </div>
        <button class="btn btn-primary me-2" [disabled]="isUploading" (click)="uploadAllFiles()" *ngIf="fileDetails.length>0">
            <i class="feather icon-upload" *ngIf="!isUploading"></i>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isUploading"></span>
            Upload
        </button>
        <button class="btn btn-outline-dark me-2" (click)="closeModal()">
            Close
        </button>
    </div>
</ng-template>