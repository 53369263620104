import { Component, Input, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AppointmentService } from '../../services/appointment/appointment.service';
import { DoctorsService } from '../../services/doctors/doctors.service';
import {
  LeadPayout,
  LeadPayoutStatus,
} from 'src/app/shared/models-ts/Entities/LeadPayout';
import { Lead } from '../../models-ts/Entities/Lead';
import { RefundService } from 'src/app/patient-leads/services/refund.service';
import { RefundSource } from '../../models-ts/Enums/pay';
import { Currency } from '../../models-ts/Helpers/countrywiseFeeCalc';
import { CountrywiseFeeCalc } from '../../models-ts/Helpers/countrywiseFeeCalc';
import { Appointment } from '../../models-ts/Entities/Appointment';
@Component({
  selector: 'app-make-manual-refund',
  templateUrl: './make-manual-refund.component.html',
  styleUrls: ['./make-manual-refund.component.css'],
})
export class MakeManualRefundComponent implements OnInit {
  @Input() leadPayout: LeadPayout;
  @Input() leadDetails: Lead;
  refundAmount: number;
  refundSource: string;
  refundedOnDate: string;
  refundedOnTime: string;
  refundId: string;
  refundSourceArray: string[] = ['Cashfree', 'Paytm', 'Razorpay', 'HDFCBANK', RefundSource.Stripe, RefundSource.Cashgram];
  isLoading: boolean = false;
  currency: Currency = Currency.INR;
  constructor(
    private modalService: BsModalService,
    private _toastrService: ToastrService,
    private _refundService: RefundService
  ) {}

  ngOnInit(): void {}
  openModal(content) {
    this.clear();
    this.setDefaultDateTime();
    this.modalService.show(content, { class: 'modal-md', backdrop: 'static' });
    this.refundAmount = this.leadDetails.consultationInfo.totalAmountPaid;
  }

  clear() {
    this.refundSource = '';
    this.refundAmount = null;
    this.refundId = null;
  }
  setDefaultDateTime() {
    this.refundedOnDate = new Date().toISOString().split('T')[0];
    this.refundedOnTime = new Date().toLocaleTimeString().slice(0, 5);
  }
  initiateRefund() {
    if (this.refundSource) {
      switch (this.refundSource) {
        case 'Cashfree':
        case RefundSource.Cashgram:
        // {
        //   if (this.refundId) {
        //     this.refreshOrAssignCFRefundId();
        //   } else {
        //     this._toastrService.error('Please enter valid cashfree refundId');
        //   }
        //   break;
        // }
        case 'Paytm':
        case 'Stripe':
        case 'HDFCBANK':
        case 'Razorpay': {
          if (
            this.refundId &&
            this.refundAmount &&
            this.refundedOnDate &&
            this.refundedOnTime
          ) {
            this.manualRefundMarking();
          } else {
            this._toastrService.error('Please provide complete refund info');
          }
          break;
        }
      }
    } else {
      this._toastrService.error('Please select refund source');
    }
  }

  manualRefundMarking() {
    this.isLoading = true;
    this._toastrService.info('Updating info');
    this._refundService
      .saveManualRefundIdInLead(
        this.leadDetails,
        this.refundId,
        this.leadDetails.consultationInfo.totalAmountPaid,
        (+this.refundAmount),
        this.refundSource,
        'YES',
        new Date(this.refundedOnDate + ' ' + this.refundedOnTime).getTime(),
        this.currency
      )
      .then(() => {
        this.isLoading = false;
        this._toastrService.success('Updated successfully');
        this.closeModal();
      })
      .catch((err) => {
        console.error(err);
        this.isLoading = false;
        this._toastrService.error('Please try again');
      });
  }

  refreshOrAssignCFRefundId() {
    this.isLoading = true;
    this._toastrService.info('Updating info');
    this._refundService
      .refreshOrAssignCFRefundIdInLead(this.leadDetails, this.refundId)
      .then(() => {
        this.isLoading = false;
        this._toastrService.success('Updated successfully');
        this.closeModal();
      })
      .catch((err) => {
        console.error(err);
        this.isLoading = false;
        this._toastrService.error('Please try again');
      });
  }

  closeModal() {
    this.modalService.hide();
  }

  refundSourceChanged(source: string) {
    this.refundSource = source;
  }

  cashfreeOnlyField() {
    return this.refundSource && this.refundSource == 'Cashfree';
  }
  currencyToSymbol(currency:Currency){
    return new CountrywiseFeeCalc().currencyToSymbol(currency);
  }
  setCurrency(val:Currency){
    this.currency = val;
  }
}
