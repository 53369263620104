import { InfoCardComponent } from './reusable-components/info-card/info-card.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './reusable-components/navbar/navbar.component';
import { DocMasterListComponent } from './reusable-components/doc-master-list/doc-master-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { FilterPipe } from './pipes/filter.pipe';
import { PracticeFilterPipe } from './pipes/practice-filter.pipe';
import { YesNoPipe } from './pipes/yes-no.pipe';
import { AssignedFilterPipe } from './pipes/assignedFilters.pipe';
import { EducationFilterPipe } from './pipes/educationFilter.pipe';
import { ProviderHeaderComponent } from './reusable-components/provider-header/provider-header.component';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { LinkBankComponent } from './reusable-components/link-bank/link-bank.component';
import { ProfilePhotoComponent } from './reusable-components/profile-photo/profile-photo.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { DoctorFilterComponent } from './reusable-components/doctor-filter/doctor-filter.component';
import { BookAppointmentComponent } from './reusable-components/book-appointment/book-appointment.component';
import { DoctorSearchComponent } from './reusable-components/doctor-search/doctor-search.component';
import { RescheduleAppointmentComponent } from './reusable-components/reschedule-appointment/reschedule-appointment.component';
import { MakeRefundComponent } from './reusable-components/make-refund/make-refund.component';
import { MakeManualRefundComponent } from './reusable-components/make-manual-refund/make-manual-refund.component';
import { PatientSearchComponent } from './reusable-components/patient-search/patient-search.component';
import { PaymentsStatusFilterPipe } from './pipes/payments-status-filter.pipe';
import { DatePickerComponent } from './reusable-components/date-picker/date-picker.component';
import { PatientRegistrationComponent } from './reusable-components/patient-registration/patient-registration.component';
import { WebviewComponent } from './reusable-components/webview/webview.component';
import { SafePipe } from './pipes/safe.pipe';
import { SpecialityFilterPipe } from './pipes/speciality-filter.pipe';
import { PatientInfoModalComponent } from './reusable-components/patient-info-modal/patient-info-modal.component';
import { FilterProviderPipe } from './pipes/filter-provider.pipe';
import { InrToUsdPipe } from './pipes/inr-to-usd.pipe';
import { CreateFollowupComponent } from './reusable-components/create-followup/create-followup.component';
import { CreatePaymentLinksModalComponent } from './reusable-components/create-payment-links-modal/create-payment-links-modal.component';
import { NextStepsMasterComponent } from './reusable-components/next-steps-master/next-steps-master.component';
import { ReschedulingRequestComponent } from './reusable-components/rescheduling-request/rescheduling-request.component';
import { ProviderAppointmentsComponent } from './reusable-components/provider-appointments/provider-appointments.component';
import { ProviderAvailableSlotsComponent } from './reusable-components/provider-available-slots/provider-available-slots.component';
import { ProviderPatientAppointmentsComponent } from './reusable-components/provider-patient-appointments/provider-patient-appointments.component';
import { ViewRescheduleRequestsComponent } from './reusable-components/view-reschedule-requests/view-reschedule-requests.component';
// import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CreateInviteModalComponent } from './reusable-components-2/create-invite-modal/create-invite-modal.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ViewAllInvitesComponent } from './reusable-components-2/view-all-invites/view-all-invites.component';
import { CreateCashgramRefundLinkComponent } from './reusable-components-2/create-cashgram-refund-link/create-cashgram-refund-link.component';
import { NewPatientFormComponent } from './reusable-components-2/new-patient-form/new-patient-form.component';
import { CashgramStatusCheckComponent } from './reusable-components-2/cashgram-status-check/cashgram-status-check.component';
import { StringTrimPipe } from './pipes/string-trim.pipe';
import { UploadMedRecordsComponent } from './reusable-components-2/upload-med-records/upload-med-records.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PatientInfoComponent } from './reusable-components-2/patient-info/patient-info.component';
import { ClashingAppointmentsModalComponent } from './reusable-components-2/clashing-appointments-modal/clashing-appointments-modal.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ManualCreditEntryComponent } from './reusable-components-2/manual-credit-entry/manual-credit-entry.component';
import { BookingPaylinkV2Component } from './reusable-components-2/booking-paylink-v2/booking-paylink-v2.component';
import { ClientCreditEntryComponent } from './reusable-components-2/client-credit-entry/client-credit-entry.component';
import { PatientSearchV2Component } from './reusable-components-2/patient-search-v2/patient-search-v2.component';
import { NewClientBookingComponent } from './reusable-components-2/new-client-booking/new-client-booking.component';
import { ScheduleOverrideModalComponent } from './reusable-components-2/schedule-override-modal/schedule-override-modal.component';
import { DatePickerV2Component } from './reusable-components-2/date-picker-v2/date-picker-v2.component';
import { CurrencyDropdownComponent } from './reusable-components-2/currency-dropdown/currency-dropdown.component';
import { NewProviderSelectComponent } from './reusable-components-2/new-provider-select/new-provider-select.component';
import { IssueDvcCreditsComponent } from './reusable-components-2/issue-dvc-credits/issue-dvc-credits.component';
import { AppointmentAddonCreditsComponent } from './reusable-components-2/appointment-addon-credits/appointment-addon-credits.component';
import { ClientWalletComponent } from './reusable-components-2/client-wallet/client-wallet.component';
import { FollowUpInfoComponent } from './reusable-components/follow-up-info/follow-up-info.component';
import { LeadCommentsComponent } from './reusable-components-2/lead-comments/lead-comments.component';
import { PartnerWalletComponent } from './reusable-components-2/partner-wallet/partner-wallet.component';
import { QuickDateDropdownComponent } from './reusable-components-2/quick-date-dropdown/quick-date-dropdown.component';
import { ClientInfoCardComponent } from './reusable-components-2/client-info-card/client-info-card.component';
import { OffcanvasChatComponent } from './reusable-components-2/offcanvas-chat/offcanvas-chat.component';
import { ChatSharedModule } from '../chat-shared/chat-shared.module';
import { UpdateLedgerPathComponent } from './reusable-components-2/update-ledger-path/update-ledger-path.component';
import { PatientMembershipDetailsComponent } from './reusable-components-2/patient-membership-details/patient-membership-details.component';
import { PatientMembershipBookingSelectionComponent } from './reusable-components-2/patient-membership-booking-selection/patient-membership-booking-selection.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ApptTypeSelectorComponent } from './reusable-components-2/appt-type-selector/appt-type-selector.component';
import { SessionTypeSelectorComponent } from './reusable-components-2/session-type-selector/session-type-selector.component';
import { ConfirmCancellationModalComponent } from './reusable-components-2/confirm-cancellation-modal/confirm-cancellation-modal.component';
import { ClientRelationshipFeeConfigComponent } from './reusable-components-2/client-relationship-fee-config/client-relationship-fee-config.component';
@NgModule({
  declarations: [
    ConfirmCancellationModalComponent,
    NavbarComponent,
    DocMasterListComponent,
    ProviderHeaderComponent,
    FilterPipe,
    PracticeFilterPipe,
    YesNoPipe,
    AssignedFilterPipe,
    EducationFilterPipe,
    PaymentsStatusFilterPipe,
    LinkBankComponent,
    ProfilePhotoComponent,
    DoctorFilterComponent,
    InfoCardComponent,
    BookAppointmentComponent,
    DoctorSearchComponent,
    RescheduleAppointmentComponent,
    MakeRefundComponent,
    MakeManualRefundComponent,
    PatientSearchComponent,
    DatePickerComponent,
    PatientRegistrationComponent,
    WebviewComponent,
    SafePipe,
    SpecialityFilterPipe,
    PatientInfoModalComponent,
    FilterProviderPipe,
    InrToUsdPipe,
    CreateFollowupComponent,
    CreatePaymentLinksModalComponent,
    NextStepsMasterComponent,
    ReschedulingRequestComponent,
    ProviderAppointmentsComponent,
    ProviderAvailableSlotsComponent,
    ProviderPatientAppointmentsComponent,
    ViewRescheduleRequestsComponent,
    CreateInviteModalComponent,
    ViewAllInvitesComponent,
    CreateCashgramRefundLinkComponent,
    NewPatientFormComponent,
    CashgramStatusCheckComponent,
    StringTrimPipe,
    UploadMedRecordsComponent,
    PatientInfoComponent,
    ClashingAppointmentsModalComponent,
    ManualCreditEntryComponent,
    BookingPaylinkV2Component,
    ClientCreditEntryComponent,
    PatientSearchV2Component,
    NewClientBookingComponent,
    ScheduleOverrideModalComponent,
    DatePickerV2Component,
    CurrencyDropdownComponent,
    NewProviderSelectComponent,
    IssueDvcCreditsComponent,
    AppointmentAddonCreditsComponent,
    ClientWalletComponent,
    FollowUpInfoComponent,
    LeadCommentsComponent,
    PartnerWalletComponent,
    QuickDateDropdownComponent,
    ClientInfoCardComponent,
    OffcanvasChatComponent,
    UpdateLedgerPathComponent,
    PatientMembershipDetailsComponent,
    PatientMembershipBookingSelectionComponent,
    ApptTypeSelectorComponent,
    SessionTypeSelectorComponent,
    ClientRelationshipFeeConfigComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    NgxImageZoomModule,
    ImageCropperModule,
    PaginationModule,
    BsDropdownModule,
    BsDatepickerModule,
    TooltipModule,
    ChatSharedModule,
    TabsModule,
  ],
  exports: [
    ConfirmCancellationModalComponent,
    NavbarComponent,
    DocMasterListComponent,
    ProviderHeaderComponent,
    ProfilePhotoComponent,
    FilterPipe,
    PracticeFilterPipe,
    YesNoPipe,
    AssignedFilterPipe,
    EducationFilterPipe,
    PaymentsStatusFilterPipe,
    NgxImageZoomModule,
    ImageCropperModule,
    DoctorFilterComponent,
    InfoCardComponent,
    BookAppointmentComponent,
    DoctorSearchComponent,
    PatientSearchComponent,
    RescheduleAppointmentComponent,
    MakeRefundComponent,
    MakeManualRefundComponent,
    DatePickerComponent,
    PatientRegistrationComponent,
    WebviewComponent,
    SpecialityFilterPipe,
    PatientInfoModalComponent,
    FilterProviderPipe,
    InrToUsdPipe,
    CreateFollowupComponent,
    // InfiniteScrollModule,
    CreatePaymentLinksModalComponent,
    NextStepsMasterComponent,
    ReschedulingRequestComponent,
    ProviderAppointmentsComponent,
    ProviderAvailableSlotsComponent,
    ProviderPatientAppointmentsComponent,
    ViewRescheduleRequestsComponent,
    CreateInviteModalComponent,
    ViewAllInvitesComponent,
    CreateCashgramRefundLinkComponent,
    CashgramStatusCheckComponent,
    StringTrimPipe,
    UploadMedRecordsComponent,
    ClashingAppointmentsModalComponent,
    ManualCreditEntryComponent,
    BookingPaylinkV2Component,
    ClientCreditEntryComponent,
    PatientSearchV2Component,
    NewClientBookingComponent,
    ScheduleOverrideModalComponent,
    DatePickerV2Component,
    CurrencyDropdownComponent,
    PatientInfoComponent,
    NewProviderSelectComponent,
    IssueDvcCreditsComponent,
    AppointmentAddonCreditsComponent,
    ClientWalletComponent,
    FollowUpInfoComponent,
    LeadCommentsComponent,
    PartnerWalletComponent,
    QuickDateDropdownComponent,
    ClientInfoCardComponent,
    OffcanvasChatComponent,
    UpdateLedgerPathComponent,
    ClientRelationshipFeeConfigComponent
  ],
  providers: [BsModalRef],
})
export class SharedModule {}
