import { Expose, Type } from "class-transformer";
import { ContactNumber } from "../Actors/Person/ContactNumber";
export class Claim {
  @Expose()
  id: string;
  @Expose()
  approvedOn: number;
  @Expose()
  createdOn: number;
  @Expose()
  doctorProfileId: string;
  @Expose()
  email: string;
  @Expose()
  governmentIdProofUrls: string[];
  @Expose()
  isEmailVerified: boolean;
  @Expose()
  isMobileNumberVerified: boolean;
  @Expose()
  isProfileClaimed: boolean;
  @Expose()
  medicalRegistrationProofUrls: string[];

  @Type(() => ContactNumber)
  @Expose()
  primaryContactNumber: ContactNumber = new ContactNumber();

  @Expose()
  processingOn: number;
  @Expose()
  status: string;
}
