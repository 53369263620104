export class CountrywiseFeeCalc {
    fee = (currentFee: number, countryCode: string, masterSpecialization: string): { amount: number, unit: Currency } => {
        if (masterSpecialization == "therapist" || masterSpecialization == "psychologist") {
            switch (countryCode) {
                case "IN": {
                    return {
                        amount: currentFee,
                        unit: Currency.INR,
                    };
                }
                case "US":
                case "CA": {
                    if (currentFee <= 1000) {
                        return {
                            amount: 20,
                            unit: Currency.USD
                        };
                    } else if (currentFee > 1000 && currentFee <= 1500) {
                        return {
                            amount: 35,
                            unit: Currency.USD
                        };
                    } else if (currentFee > 1500 && currentFee <= 2500) {
                        return {
                            amount: 50,
                            unit: Currency.USD
                        };
                    }
                    else if (currentFee > 2500 && currentFee <= 3500) {
                        return {
                            amount: 65,
                            unit: Currency.USD
                        };
                    } else if (currentFee > 3500 && currentFee <= 4500) {
                        return {
                            amount: 75,
                            unit: Currency.USD
                        };
                    } else {
                        return {
                            amount: currentFee,
                            unit: Currency.INR
                        };
                    }
                }
                case "AE": {
                    if (currentFee <= 1000) {
                        return {
                            amount: 75,
                            unit: Currency.AED
                        };
                    } else if (currentFee > 1000 && currentFee <= 1500) {
                        return {
                            amount: 125,
                            unit: Currency.AED
                        };
                    } else if (currentFee == 2625) {
                        return {
                            amount: 131.25,
                            unit: Currency.AED
                        };
                    } else if (currentFee > 1500 && currentFee <= 2500) {
                        return {
                            amount: 175,
                            unit: Currency.AED
                        };
                    }
                    else if (currentFee > 2500 && currentFee <= 3500) {
                        return {
                            amount: 225,
                            unit: Currency.AED
                        };
                    } 
                    else if (currentFee > 3500 && currentFee <= 4500) {
                        return {
                            amount: 275,
                            unit: Currency.AED
                        };
                    } else {
                        return {
                            amount: currentFee,
                            unit: Currency.INR
                        };
                    }
                }
                default: {
                    if (currentFee <= 1000) {
                        return {
                            amount: 1500,
                            unit: Currency.INR
                        };
                    } else if (currentFee > 1000 && currentFee <= 1500) {
                        return {
                            amount: 2500,
                            unit: Currency.INR
                        };
                    } else if (currentFee > 1500 && currentFee <= 2500) {
                        return {
                            amount: 3500,
                            unit: Currency.INR
                        };
                    }
                    else if (currentFee > 2500 && currentFee <= 3500) {
                        return {
                            amount: 4500,
                            unit: Currency.INR
                        };
                    } 
                    else if (currentFee > 3500 && currentFee <= 4500) {
                        return {
                            amount: 5500,
                            unit: Currency.INR
                        };
                    } 
                    else {
                        return {
                            amount: currentFee,
                            unit: Currency.INR
                        };
                    }
                }
            }
        } else if (masterSpecialization == "psychiatrist") {
            switch (countryCode) {
                case "US":
                case "CA": {
                    if (currentFee <= 1000) {
                        return {
                            amount: 35,
                            unit: Currency.USD
                        };
                    } else if (currentFee > 1000 && currentFee <= 1500) {
                        return {
                            amount: 35,
                            unit: Currency.USD
                        };
                    } else if (currentFee > 1500 && currentFee <= 2500) {
                        return {
                            amount: 50,
                            unit: Currency.USD
                        };
                    }
                    else if (currentFee > 2500 && currentFee <= 3500) {
                        return {
                            amount: 65,
                            unit: Currency.USD
                        };
                    } else {
                        return {
                            amount: currentFee,
                            unit: Currency.INR
                        };
                    }
                }
                case "AE": {
                    if (currentFee <= 1000) {
                        return {
                            amount: 125,
                            unit: Currency.AED
                        };
                    } else if (currentFee > 1000 && currentFee <= 1500) {
                        return {
                            amount: 125,
                            unit: Currency.AED
                        };
                    } else if (currentFee > 1500 && currentFee <= 2500) {
                        return {
                            amount: 175,
                            unit: Currency.AED
                        };
                    }
                    else if (currentFee > 2500 && currentFee <= 3500) {
                        return {
                            amount: 225,
                            unit: Currency.AED
                        };
                    } else {
                        return {
                            amount: currentFee,
                            unit: Currency.INR
                        };
                    }
                }
                case "IN": {
                    return {
                        amount: currentFee,
                        unit: Currency.INR,
                    };
                }
                default: {
                    if (currentFee <= 1000) {
                        return {
                            amount: 2500,
                            unit: Currency.INR
                        };
                    } else if (currentFee > 1000 && currentFee <= 1500) {
                        return {
                            amount: 2500,
                            unit: Currency.INR
                        };
                    } else if (currentFee > 1500 && currentFee <= 2500) {
                        return {
                            amount: 3500,
                            unit: Currency.INR
                        };
                    }
                    else if (currentFee > 2500 && currentFee <= 3500) {
                        return {
                            amount: 4500,
                            unit: Currency.INR
                        };
                    } else {
                        return {
                            amount: currentFee,
                            unit: Currency.INR
                        };
                    }
                }
            }
        }
        else {
            return {
                amount: currentFee,
                unit: Currency.INR
            };
        }
    }

    currencyToSymbol = (currency: Currency) => {
        switch (currency) {
            case Currency.INR:
                return "₹";
            case Currency.USD:
                return "$";
            case Currency.AED:
                return "AED";
            case Currency.AUD:
                return "$";
            case Currency.EUR:
                return "€";
            case Currency.CAD:
                return "$";
            case Currency.SGD:
                return "$";
            case Currency.GBP:
                return "£";
            default:
                return "₹";
        }
    }

    currencyToCountry = (currency: Currency, isInternationalClient?: boolean) => {
        switch (currency) {
            case Currency.INR:
                if (isInternationalClient) {
                    return "ZZ";
                }
                return "IN";
            case Currency.USD:
                return "US";
            case Currency.AED:
                return "AE";
            default:
                return "ZZ";
        }
    }
}

export enum Currency {
    INR = "INR",
    USD = "USD",
    AED = "AED",
    EUR = "EUR",
    AUD = "AUD",
    CAD = "CAD",
    SGD = "SGD",
    GBP = "GBP"
}