import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { DoctorListingProfile } from 'src/app/shared/models-ts/Actors/Doctor/DoctorListingProfile';
import { CRMUser } from 'src/app/shared/models-ts/Actors/User/CRMUser';
import { User } from 'src/app/shared/models-ts/Actors/User/User';
import { OffcanvasChatComponent } from 'src/app/shared/reusable-components-2/offcanvas-chat/offcanvas-chat.component';
import { PatientInfoComponent } from 'src/app/shared/reusable-components-2/patient-info/patient-info.component';
import { AuthUserInfoService } from 'src/app/shared/services/auth-user-info/auth-user-info.service';
import { CopyClipboardService } from 'src/app/shared/services/copy-clipboard/copy-clipboard.service';
import { DoctorsService } from 'src/app/shared/services/doctors/doctors.service';
import { PatientLeadsBackendService } from '../../services/patient-leads-backend.service';

@Component({
  selector: 'app-booking-started',
  templateUrl: './booking-started.component.html',
  styleUrls: ['./booking-started.component.css'],
  providers: [DatePipe],
})
export class BookingStartedComponent implements OnInit {
  providerProfile: DoctorListingProfile = new DoctorListingProfile();
  currentHeroInfo: CRMUser = new CRMUser();
  isLoadingProviderProfile: boolean = false;
  list: any[] = [];
  isLoading: boolean = false;
  showDetails: boolean = false;
  showDetailsContainer: boolean = false;
  leadDetails: any;
  selectedLeadId: string;
  title: string = 'Booking Started';
  copyLoader: boolean = false;
  wpLoader: boolean = false;
  incompleteBookingCount = 0;
  currentDoctorId: string;
  currentPatientId: string;
  currentLeadBookingDate: string;
  currentLeadBookingTime: string;
  nextStepsCommunication: any;
  retargetMessageLoader:boolean = false;
  listFilterOptions = {
    all:"All",
    not_retargeted: "Not Retargeted",
    not_booked: "Not Booked"
  }
  selectedFilter:string='all';
  limit:number=200;
  currentIndex:number = 0;
  @ViewChild('chatoffcanvas1') chatOffcanvas:OffcanvasChatComponent;
  constructor(
    private _patientLeadsBackendService: PatientLeadsBackendService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _toastrService: ToastrService,
    private _copyToClipboardService: CopyClipboardService,
    private _modalService: BsModalService,
    private docService: DoctorsService,
    private _authUserInfoService: AuthUserInfoService,
    private _datePipe: DatePipe,
  ) {}

  ngOnInit(): void {
    this.currentHeroInfo = this._authUserInfoService.getUserInfo();
    const filter = this._activatedRoute.snapshot.queryParamMap.get('f');
    if(filter){
      this.selectedFilter = filter;
    }
    this.fetchList();
  }

  fetchList() {
    this.isLoading = true;
    this._patientLeadsBackendService.bookingStarted(null,null,null,null,this.limit).onSnapshot((snap) => {
      this.list = [];
      this.isLoading = false;
      this.incompleteBookingCount = 0;
      if (!snap.empty) {
        snap.forEach((doc) => {
          let _ = doc.data();
          _['_id'] = doc.id;
          if (
            !(
              (_['reTargetStep'] && _['reTargetStep']['bool'] == true) ||
              (_['apptBookedStep'] && _['apptBookedStep']['bool'] == true)
            )
          ) {
            this.incompleteBookingCount += 1;
          }
          if(this.selectedFilter && this.selectedFilter == 'not_retargeted'){
            if(!(_['reTargetStep'] && _['reTargetStep']['bool'] == true)&&!(_['apptBookedStep'] && _['apptBookedStep']['bool'] == true)){
              this.list.push(_);  
            }
          }else if(this.selectedFilter && this.selectedFilter == 'not_booked'){
            if(!(_['apptBookedStep'] && _['apptBookedStep']['bool'] == true)){
              this.list.push(_);  
            }
          }else{
            this.list.push(_);
          }
          
        });
        this._activatedRoute.params.subscribe((param) => {
          if (param['id'] && param['id'].length > 0) {
            this.selectedLeadId = param['id'];
            if (this.list.length > 0) {
              this.highlightSelectedLead(this.selectedLeadId);
            }
          }
        });
      } else {
        this.isLoading = false;
      }
    });
  }

  backClicked() {
    this._router.navigate(['../'], { relativeTo: this._activatedRoute });
  }

  openDetails(lead) {
    this.showDetails = true;
    this.showDetailsContainer = true;
    this.leadDetails = lead;
    if (this.leadDetails.metaData) {
      let data = this.leadDetails.metaData;
      this.currentDoctorId = data.doctorId ? data.doctorId : '';
      this.currentPatientId =
        data.patientsArray && data.patientsArray.length > 0
          ? data.patientsArray[0]['patientId']
          : '';
      this.currentLeadBookingDate = data.date ? data.date : '';
      this.currentLeadBookingTime = data.timeslot
        ? data.timeslot.split('-')[0]
        : '';
    }
  }

  onLeadSelect(lead,i) {
    this._router.navigate(['booking-started', lead._id], {
      queryParamsHandling: 'merge',
    });
    this.currentIndex = i;
  }

  highlightSelectedLead(leadId: string) {
    let lead = this.list.filter((l) => l._id == leadId)[0];
    if(lead){
      this.openDetails(lead);
    }else{
      this.showDetails = false;
      this.leadDetails = {}
    }
    
    // console.log(lead);
  }

  getLeadContact() {
    return this.leadDetails['customerPhone'];
  }

  tryToOpenWhatsAppChat() {
    if (this.leadDetails != null && this.leadDetails['customerPhone'] != null) {
      let _url = `whatsapp://send?phone=${this.leadDetails['customerPhone']}`;
      window.open(_url, '_blank');
    }
  }

  copyLeadContactInfo() {
    if (
      this.leadDetails != null &&
      this.leadDetails['customerName'] != null &&
      this.leadDetails['customerPhone'] != null
    ) {
      let nameRow = 'Name: ' + this.leadDetails['customerName'];
      let contactRow = 'Contact: +' + this.getLeadContact();
      let contactInfo = nameRow + '\n' + contactRow + '\n';

      this._copyToClipboardService.copyToClipoard(contactInfo);
    }
  }

  saveLeadContact() {
    if (this.leadDetails != null && this.leadDetails['metaData'] != null) {
      let metaData = this.leadDetails['metaData'];
      let patientMeta = metaData['patientsArray'][0];
      let patientContact = patientMeta['patientPrimaryContactNumber'];
      this._toastrService.info('Saving contact info');
      this._patientLeadsBackendService
        .saveBookingContact(
          'anmol@docvita.in',
          metaData['appointmentId'],
          patientMeta['patientName'],
          patientContact['countryCode'],
          patientContact['number']
        )
        .then(() => {
          this._toastrService.success(
            'Contact saved successfully in anmol@docvita.in'
          );
        })
        .catch((err) => {
          console.error(err);
          this._toastrService.error(
            'Something went wrong while saving the contact. Please try again'
          );
        });
    } else {
      this._toastrService.warning(
        'Insufficient information. We are working on the pop up to let you edit the information.'
      );
    }
  }

  reTargetSent(bool) {
    this._patientLeadsBackendService
      .updateIncompleteBooking(this.leadDetails._id, {
        'reTargetStep.bool': bool,
        'reTargetStep.updatedOn': new Date().getTime(),
      })
      .then(() => {
        if (bool) {
          this.moveToNext();
          this._toastrService.success('Retargeted!');
        } else {
          this._toastrService.success('Undo');
        }
      })
      .catch((err) => {
        this._toastrService.error('Something went wrong!');
        console.error(err);
      });
  }

  appointmentBookedSent(bool) {
    this._patientLeadsBackendService
      .updateIncompleteBooking(this.leadDetails._id, {
        'apptBookedStep.bool': bool,
        'apptBookedStep.updatedOn': new Date().getTime(),
      })
      .then(() => {
        if (bool) {
          this._toastrService.success('Status Updated!');
        } else {
          this._toastrService.success('Undo');
        }
      })
      .catch((err) => {
        this._toastrService.error('Something went wrong!');
        console.error(err);
      });
  }

  bookingFailedMessageSent() {
    let sentStatus;
    if (this.leadDetails.bookingFailedStep) {
      sentStatus = !this.leadDetails.bookingFailedStep.bool;
    } else {
      sentStatus = true;
    }
    this._patientLeadsBackendService
      .updateIncompleteBooking(this.leadDetails._id, {
        'bookingFailedStep.bool': sentStatus,
        'bookingFailedStep.updatedById': this.currentHeroInfo.docvitaId,
        'bookingFailedStep.updatedByName': this.currentHeroInfo.name,
        'bookingFailedStep.updatedOn': new Date().getTime(),
      })
      .then(() => {
        if (sentStatus) {
          this._toastrService.success('Booking failure message sent!');
        } else {
          this._toastrService.success('Undo');
        }
      })
      .catch((err) => {
        this._toastrService.error('Something went wrong!');
        console.error(err);
      });
  }

  resumeBookingMessageSent() {
    let sentStatus;
    if (this.leadDetails.resumeBookingStep) {
      sentStatus = !this.leadDetails.resumeBookingStep.bool;
    } else {
      sentStatus = true;
    }
    this._patientLeadsBackendService
      .updateIncompleteBooking(this.leadDetails._id, {
        'resumeBookingStep.bool': sentStatus,
        'resumeBookingStep.updatedById': this.currentHeroInfo.docvitaId,
        'resumeBookingStep.updatedByName': this.currentHeroInfo.name,
        'resumeBookingStep.updatedOn': new Date().getTime(),
      })
      .then(() => {
        if (sentStatus) {
          this._toastrService.success('Continue booking message sent!');
        } else {
          this._toastrService.success('Undo');
        }
      })
      .catch((err) => {
        this._toastrService.error('Something went wrong!');
        console.error(err);
      });
  }

  autoBookMessageSent() {
    let sentStatus;
    if (this.leadDetails.autoBookStep) {
      sentStatus = !this.leadDetails.autoBookStep.bool;
    } else {
      sentStatus = true;
    }
    this._patientLeadsBackendService
      .updateIncompleteBooking(this.leadDetails._id, {
        'autoBookStep.bool': sentStatus,
        'autoBookStep.updatedById': this.currentHeroInfo.docvitaId,
        'autoBookStep.updatedByName': this.currentHeroInfo.name,
        'autoBookStep.updatedOn': new Date().getTime(),
      })
      .then(() => {
        if (sentStatus) {
          this._toastrService.success('Auto book message sent!');
        } else {
          this._toastrService.success('Undo');
        }
      })
      .catch((err) => {
        this._toastrService.error('Something went wrong!');
        console.error(err);
      });
  }

  nextStepMeta(json) {
    if (json && json != null) {
      let _ =
        'Sent by ' +
        json['updatedByName'] +
        ' on ' +
        this._datePipe.transform(json['updatedOn'], 'hh:mm a, dd MMM');
      return _;
    }
  }

  IncompleteBookingLanguage() {
    let docName = this.leadDetails.metaData.doctorName
      ? 'with ' + this.leadDetails.metaData.doctorName
      : '';
    let patientName = this.leadDetails.customerName
      ? this.leadDetails.customerName
      : '';
    let dateNTime =
      'for ' +
      this.tConvert(this.leadDetails.metaData.timeslot.split('-')[0]) +
      ' - ';
    dateNTime += new Date(this.leadDetails.metaData.date)
      .toString()
      .substr(0, 10);
    let _ = `Hey ${
      patientName ? patientName : 'there'
    } 👋🏻, your appointment booking ${docName} ${dateNTime} could not be completed.`;
    return _;
  }

  copyIncompleteBooking() {
    let message = this.IncompleteBookingLanguage();
    this._copyToClipboardService.copyToClipoard(message);
  }

  wpIncompleteBooking() {
    let message = this.IncompleteBookingLanguage();
    this.tryToOpenWhatsAppChatForNumber(this.getLeadContact(), message);
  }

  resumeBookingLanguage() {
    return new Promise<string>((resolve, reject) => {
      let _ = 'Resume your booking on the link below: \n';
      let docId = this.leadDetails.metaData.doctorId;
      this.docService
        .getDocProfileById(docId)
        .then((snap) => {
          if (snap.exists) {
            let uniqTag = snap.data()['uniqueTag'];
            let city = snap.data()['city'].toLowerCase();
            _ += 'https://docvita.com/' + city + '/specialist/' + uniqTag;
            resolve(_);
          } else {
            resolve(null);
          }
        })
        .catch((err) => {
          this._toastrService.error('Failed to copy!');
          resolve(null);
        });
    });
  }

  async copyResumeBooking() {
    this.copyLoader = true;
    let message = await this.resumeBookingLanguage();
    this.copyLoader = false;
    this._copyToClipboardService.copyToClipoard(message);
  }

  async wpResumeBooking() {
    this.wpLoader = true;
    let message = await this.resumeBookingLanguage();
    this.wpLoader = false;
    this.tryToOpenWhatsAppChatForNumber(this.getLeadContact(), message);
  }

  internationalNumberSupportLanguage() {
    let _ =
      'Our website doesn’t yet support International Numbers. But if you like, one of our Care Coordinators can help you book this appointment over WhatsApp.';
    return _;
  }
  copyInternationalNumberSupport() {
    let message = this.internationalNumberSupportLanguage();
    this._copyToClipboardService.copyToClipoard(message);
  }
  wpInternationalNumberSupport() {
    let message = this.internationalNumberSupportLanguage();
    this.tryToOpenWhatsAppChatForNumber(this.getLeadContact(), message);
  }

  careCoordinatorIntroLanguage() {
    let _ =
      'I am ' +
      this.currentHeroInfo.getFirstName() +
      ' - your Care Coordinator at DocVita';
    return _;
  }
  copyCareCoordinatorIntro() {
    let message = this.careCoordinatorIntroLanguage();
    this._copyToClipboardService.copyToClipoard(message);
  }
  wpCareCoordinatorIntro() {
    let message = this.careCoordinatorIntroLanguage();
    this.tryToOpenWhatsAppChatForNumber(this.getLeadContact(), message);
  }

  askClientInfoLanguage() {
    let _ = `Could you please share the following details?
    - Name
    - Gender
    - Age
    - Email`;
    return _;
  }
  copyAskClientInfo() {
    let message = this.askClientInfoLanguage();
    this._copyToClipboardService.copyToClipoard(message);
  }
  wpAskClientInfo() {
    let message = this.askClientInfoLanguage();
    this.tryToOpenWhatsAppChatForNumber(this.getLeadContact(), message);
  }
  sharePaymentLinkLanguage() {
    let _ = `You can complete your booking using the link below:
    <INSERT LINK>
    We accept all international cards also. :)`;
    return _;
  }
  copySharePaymentLink() {
    let message = this.sharePaymentLinkLanguage();
    this._copyToClipboardService.copyToClipoard(message);
  }
  wpSharePaymentLink() {
    let message = this.sharePaymentLinkLanguage();
    this.tryToOpenWhatsAppChatForNumber(this.getLeadContact(), message);
  }
  replyYesLanguage() {
    let _ =
      'Reply with a "Yes" if you are unable to click the link, reply with "Chat" to speak with a Care Coordinator.';
    return _;
  }
  copyReplyYes() {
    let message = this.replyYesLanguage();
    this._copyToClipboardService.copyToClipoard(message);
  }
  wpReplyYes() {
    let message = this.replyYesLanguage();
    this.tryToOpenWhatsAppChatForNumber(this.getLeadContact(), message);
  }

  copyProviderID() {
    this._copyToClipboardService.copyToClipoard(
      this.leadDetails.metaData.doctorId
    );
  }

  tryToOpenWhatsAppChatForNumber(number: string, text: string) {
    if (number && number.length > 0) {
      let _url = `whatsapp://send?phone=${number}`;
      if (text != undefined) {
        _url += `&text=${encodeURIComponent(text)}`;
      }
      window.open(_url, '_blank');
    }
  }

  gotoDoctorProfile() {
    if (this.leadDetails.metaData.doctorId) {
      let url =
        location.origin +
        '/profile-listing/' +
        this.leadDetails.metaData.doctorId;
      window.open(url, '_blank').focus();
    }
  }

  tConvert(time) {
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? ' AM' : ' PM';
      time[0] = +time[0] % 12 || 12;
    }
    return time.join('');
  }

  openModal(content) {
    this._modalService.show(content);
  }

  async sendRetargetMessageToClient(){
    try {
      this.retargetMessageLoader = true;
      const res = await this._patientLeadsBackendService.sendWhatsappRetargetMessageToClient(this.leadDetails['_id'])  
      if(res){
        this.moveToNext();
        this._toastrService.success('Message sent!')
      }else{
        this._toastrService.error('Failed to send..')
      }
      this.retargetMessageLoader = false;
    } catch (error) {
      this.retargetMessageLoader = false;
      this._toastrService.error('Failed to send..')
    }
  }

  moveToNext(){
    if(this.list.length>0){
      const index = this.list.length==1||this.selectedFilter=='not_retargeted'?this.currentIndex:this.currentIndex+1
      const lead = this.list[index]
      this.onLeadSelect(lead,index)
    }
  }

  openPatientInfo() {
    const patientId = this.leadDetails['metaData']&&this.leadDetails['metaData']['patientsArray']&&this.leadDetails['metaData']['patientsArray'].length>0?this.leadDetails['metaData']['patientsArray'][0]['patientId']:null;
    const customerNumber = this.leadDetails['customerPhone']?this.leadDetails['customerPhone']:null;
    if(patientId){
      let modalRef = this._modalService.show(PatientInfoComponent, {
        initialState: { clientId: patientId},
        class: 'modal-lg',
      });
      modalRef.content.clientId = patientId;
      modalRef.content.close.subscribe(()=>{
        modalRef.hide();
      })
    }else{
      this._toastrService.warning('Client id is missing..')
    }
  }

  returnZero(){
    return 0;
  }
  selectFilter(key:string){
    if(this.listFilterOptions[key]){
      this.selectedFilter = key;
      this._router.navigate([],{queryParams:{
        f:this.selectedFilter
      },queryParamsHandling:'merge'})
      this.fetchList();
    }
  }

  get clientIdExists(){
    const id = this.leadDetails['metaData']&&this.leadDetails['metaData']['patientsArray']&&this.leadDetails['metaData']['patientsArray'].length>0?this.leadDetails['metaData']['patientsArray'][0]['patientId']:null
    return id?true:false;
  }

  openChatInCanvas(c: string) {
    this.chatOffcanvas.open('whatsapp'+c)
  }
}
