import { Pipe, PipeTransform } from '@angular/core';
import { DoctorListingProfile } from '../models-ts/Actors/Doctor/DoctorListingProfile';
@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform(items: DoctorListingProfile[], filter: any): any[] {
    if (!items) return [];
    // if (!filter.searchText) return items;
    filter.searchText = filter.searchText.toLowerCase();
    if (filter.photoUrl && filter.photoUrl != '') {
      items = items.filter((element) => {
        if (element.photoUrl) {
          return true;
        }
        return false;
      });
    }
    if (
      filter.medicalRegistrationVerified &&
      filter.medicalRegistrationVerified != ''
    ) {
      items = items.filter((element) => {
        if (element.isMedicalRegistrationVerified == true) {
          return true;
        }
        return false;
      });
    }
    if (filter.showInListing && filter.showInListing != '') {
      items = items.filter((element) => {
        if (element.showInListing) {
          return true;
        }
        return false;
      });
    }
    if (
      filter.isProfileClaimed != null &&
      filter.isProfileClaimed.toString().length > 0
    ) {
      items = items.filter((element) => {
        if (filter.isProfileClaimed == false) {
          if (
            element.isProfileClaimed == null ||
            element.isProfileClaimed == false
          ) {
            return true;
          }
          return false;
        }
        if (filter.isProfileClaimed == true) {
          if (element.isProfileClaimed) {
            return true;
          }
          return false;
        }
        return false;
      });
    }
    if (filter.publicContactNumber && filter.publicContactNumber != '') {
      items = items.filter((element) => {
        if (element.publicContactNumber && element.publicContactNumber.number) {
          return true;
        }
        return false;
      });
    }
    if (filter.primaryContactNumber && filter.primaryContactNumber != '') {
      items = items.filter((element) => {
        if (
          element.primaryContactNumber &&
          element.primaryContactNumber.number
        ) {
          return true;
        }
        return false;
      });
    }
    if (filter.photoUrl === false) {
      items = items.filter((element) => {
        if (element.photoUrl == null || element.photoUrl === '') {
          return true;
        }
        return false;
      });
    }
    if (filter.publicContactNumber === false) {
      items = items.filter((element) => {
        if (
          element.publicContactNumber == null ||
          element.publicContactNumber.number == null
        ) {
          return true;
        }
        return false;
      });
    }

    return items.filter((it) => {
      if (it.name.toLowerCase().includes(filter.searchText)) {
        return true;
      }
      if (it.id.toLowerCase().includes(filter.searchText)) {
        return true;
      }
      return false;
    });
  }
}
