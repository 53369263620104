import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { classToPlain, plainToClass } from 'class-transformer';
import { ClientWalletService } from 'src/app/services/client-wallet.service';
import { Currency } from '../../models-ts/Helpers/countrywiseFeeCalc';

@Component({
  selector: 'client-wallet',
  templateUrl: './client-wallet.component.html',
  styleUrls: ['./client-wallet.component.scss'],
  providers: [ClientWalletService]
})
export class ClientWalletComponent implements OnInit {
  wallet:Map<Currency,number> = new Map<Currency,number>();
  isWalletBalanceLoading:boolean = false;
  @Input() set patientId(val:string){
    if(val&&val.length>0){
      this.getWallet(val)
    }
    
  }
  @Output() walletVal:EventEmitter<Map<Currency,number>> = new EventEmitter<Map<Currency,number>>();
  constructor(private _clientWalletService: ClientWalletService,) { }

  ngOnInit(): void {
  }

  get walletDisplay() {
    const wallet = classToPlain(this.wallet)
    if (this.wallet && this.wallet.size > 0) {
      let walletString = "";
      for (let i in wallet) {
        walletString += "(" + i + ") " + wallet[i] + " • ";
      }
      walletString = walletString.slice(0,walletString.length-2)
      return walletString;
    } else {
      return "No Balance";
    }
  }

  getWallet(clientId: string) {
    this.isWalletBalanceLoading = true;
    this._clientWalletService
      .getClientWallet(clientId)
      .then((wallet) => {
        let _wallet = new Map<Currency,number>();
        if(wallet&&Object.keys(wallet).length>0){
          for(const cur in wallet){
            const _cur = cur as Currency;
            _wallet.set(_cur,wallet[cur])
          }    
        }
        this.isWalletBalanceLoading = false;
        this.wallet = _wallet;
        this.walletVal.emit(_wallet);
      })
      .catch((err) => {
        this.isWalletBalanceLoading = false;
        this.wallet = new Map<Currency,number>();;
        this.walletVal.emit(this.wallet);
        
      });
  }

}
