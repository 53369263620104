<div class="card rounded mb-0 p-2" *ngIf="!disableContainer">
    <h6>Available slots:</h6>
    <div style="max-height: 9rem;overflow-y: scroll;">
        <div class="d-flex flex-wrap" style="font-size: 14px;">
            <ng-container *ngFor="let slot of currentSlots">
                <div class="card p-1 m-1 slot" *ngIf="slot['isAvailable']"
                    [ngClass]="{'slot-selected': slot==_selectedSlot}" (click)="selectSlot(slot)">
                    <ng-container *ngTemplateOutlet="inPersonIconTemplate;  context: {slot: slot, text:slot['startTime'] }">
                    </ng-container>
                </div>
            </ng-container>
        </div>
        <div *ngIf="noSlots" class="alert alert-info" style="font-size: 0.9em!important;">
            💤 No Slots!
        </div>
        <div class="spinner-border ms-3 mt-3" role="status" *ngIf="slotLoader">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>
<div class="d-flex flex-wrap w-100" style="font-size: 14px;" *ngIf="disableContainer">
    <ng-container *ngIf="!slotLoader && currentSlots?.length > 0">
        <ng-container *ngFor="let slot of currentSlots">
            <div class="card p-1 m-1 slot" *ngIf="slot['isAvailable']"
                [ngClass]="{'slot-selected': slot==_selectedSlot}" (click)="selectSlot(slot)">
                <ng-container *ngTemplateOutlet="inPersonIconTemplate;  context: {slot: slot, text: getTimeInHHmma(slot['startTime']) }">
                </ng-container>
            </div>
        </ng-container>

    </ng-container>

    <div *ngIf="!slotLoader && noSlots" class="text-primary ms-auto me-auto" style="font-size: 0.8em!important;">
        💤 No Slots!
    </div>
    <div class="spinner-border spinner-border-sm ms-3" role="status" *ngIf="slotLoader">
        <span class="sr-only">Loading...</span>
    </div>
</div>

<ng-template #inPersonIconTemplate let-slot="slot" let-text="text">
    <div class="d-flex flex-row align-items-center justify-content-between">
        <span class="text-primary me-1 border-right" *ngIf="slot?.inPersonPracticeId" tooltip="{{practiceName(slot?.inPersonPracticeId)}}"
            placement="bottom">
            <i class="la la-building font-18"></i>
        </span>
        {{text}}
    </div>
</ng-template>