<div class="card form-row mt-3 mb-3">
    <div class="card-header bg-transparent d-flex">
      <h6>Contact numbers</h6>
      <button
        class="btn btn-sm btn-primary ms-auto"
        (click)="addPhoneField()"
      >
        +
      </button>
    </div>
    <div class="card-body">
      <div
        class="form-group"
        *ngFor="let phone of contactNumbers; let i = index"
      >
        <label [for]="'PhoneInput' + i">
          Contact {{ i + 1 }} <span *ngIf="phone.ext" class="border rounded p-1" style="font-size: 13px;">EXT No: {{phone.ext}}</span>
        </label> 

        <!-- <div class="ms-1 mt-2 badge bg-dark" style="font-size: 13px!important;" *ngIf="phone.ext">
          EXT No: {{phone.ext}}
        </div> -->
        <div class="d-flex flex-column">
          <div class="d-flex">
            <input
              type="number"
              class="form-control me-1"
              style="width: 4rem; padding: 0.5rem"
              [(ngModel)]="phone.countryCode"
            />
            <input
              type="number"
              class="form-control"
              [id]="'PhoneInput' + i"
              placeholder="Contact Number"
              [(ngModel)]="phone.number"
            />
            <button
              class="btn btn-sm btn-danger ms-1"
              (click)="deletePhoneField(i)"
            >
              &times;
            </button>
          </div>
          <div class="d-flex flex-wrap align-items-center text-muted mt-1" style="font-size: 14px;">
            <!-- <div class="ms-1 mt-2 badge bg-warning" style="font-size: 13px!important;" *ngIf="phone.isPublicContactNumber">
              Public Contact Number
            </div>
            <div class="ms-1 mt-2 badge bg-primary" style="font-size: 13px!important;" *ngIf="phone.isPrimaryContactNumber">
              Primary Contact Number
            </div>
            <div class="ms-1 mt-2 badge bg-success" style="font-size: 13px!important;" *ngIf="phone.isWhatsappContactNumber">
              Whatsapp Contact Number
            </div> -->
            <div class="form-check me-2">
              <input class="form-check-input" type="checkbox" [id]="'primaryContact'+i" [(ngModel)]="phone.isPrimaryContactNumber">
              <label class="form-check-label ms-1" [for]="'primaryContact'+i">isPrimaryContactNumber?</label>
            </div>
            <div class="form-check me-2">
              <input class="form-check-input" type="checkbox" [id]="'whatsappContact'+i" [(ngModel)]="phone.isWhatsappContactNumber">
              <label class="form-check-label ms-1" [for]="'whatsappContact'+i">isWhatsappContactNumber?</label>
            </div>
            <div class="form-check me-2" *ngIf="phone.isWhatsappContactNumber">
              <input class="form-check-input" type="checkbox" [id]="'receiveConfirmations'+i" [(ngModel)]="phone.receiveConfirmations">
              <label class="form-check-label ms-1" [for]="'receiveConfirmations'+i">receiveConfirmations?</label>
            </div>
          </div>
        </div>
        
      </div>
    </div>
</div>


<!-- <div
  class="modal fade"
  id="editContacts"
  tabindex="-1"
  aria-labelledby="editContactsLabel"
  aria-hidden="true"
  style="z-index: 9999;"
>
  <div class="modal-dialog modal-lg modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="editContactsLabel">
          Contact Info for: {{displayNumber}}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <dl>
            <dt>Type:</dt>
            <dd class="mt-2">
              <select class="form-select" [(ngModel)]="type">
                <option [value]="'mobile'">Mobile</option>
                <option [value]="'landline'">Landline</option>
              </select>
            </dd>
            <hr/>
            <dt>Category:</dt>
            <dd class="mt-2">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="primaryContact" [(ngModel)]="isPrimaryContactNumber">
                    <label class="form-check-label" for="primaryContact">isPrimaryContactNumber?</label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="publicContact" [(ngModel)]="isPublicContactNumber">
                    <label class="form-check-label" for="publicContact">isPublicContactNumber?</label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="whatsappContact" [(ngModel)]="isWhatsappContactNumber">
                    <label class="form-check-label" for="whatsappContact">isWhatsappContactNumber?</label>
                </div>
            </dd>
            <hr/>
            <dt>Source of information:</dt>
            <dd class="mt-2">
                <input
                type="text"
                class="form-control"
                id='sourceUrl'
                placeholder="Source url"
                [(ngModel)]="source"
                />
            </dd>
        </dl>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Close
        </button>
        <button type="button" class="btn btn-primary" (click)="saveChangesOfModal()">
          Save changes
        </button>
      </div>
    </div>
  </div>
</div> -->