import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { plainToClass } from 'class-transformer';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ClientWalletService } from 'src/app/services/client-wallet.service';
import { ContactNumber } from '../../models-ts/Actors/Person/ContactNumber';
import { Email } from '../../models-ts/Actors/Person/Email';
import { CRMUser } from '../../models-ts/Actors/User/CRMUser';
import { User } from '../../models-ts/Actors/User/User';
import { Appointment } from '../../models-ts/Entities/Appointment';
import { Lead } from '../../models-ts/Entities/Lead';
import { CountrywiseFeeCalc, Currency } from '../../models-ts/Helpers/countrywiseFeeCalc';
import { AppointmentService } from '../../services/appointment/appointment.service';
import { AuthUserInfoService } from '../../services/auth-user-info/auth-user-info.service';
import { CopyClipboardService } from '../../services/copy-clipboard/copy-clipboard.service';
import { PatientService } from '../../services/patient/patient.service';
import { P2PCallService } from '../../../services/p2p-call.service';
import { OffcanvasChatComponent } from '../offcanvas-chat/offcanvas-chat.component';
import { P2PParticipantInfo, P2PParticipantType } from '../../models-ts/Entities/P2PCall/P2PParticipantInfo';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-patient-info',
  templateUrl: './patient-info.component.html',
  styleUrls: ['./patient-info.component.scss'],
  providers: [ClientWalletService, DatePipe],
})
export class PatientInfoComponent implements OnInit {
  @Input() client: User = new User();
  allAppointments: Appointment[] = [];
  allLeads: Lead[] = [];
  currentHeroInfo: CRMUser;
  _clientId: string;
  @Input() hideTopbar: boolean;
  @Input() suggestedTimeForInvite: string;
  @Input() suggestedDateForInvite: string;
  @Input() doctorIdForInvite: string;
  @Input() set clientId(val: string) {
    this._clientId = val;
    this.fetchClientProfile(val);
    this.getAppointments(val);
  }
  get clientId(): string {
    return this._clientId;
  }
  isLoadingPatientProfile: boolean;
  isLoadingAppointments: boolean;

  contactNumbers: ContactNumber[] = [];
  emails: Email[] = [];
  isWalletBalanceLoading: boolean;
  wallet: Map<Currency, number> = new Map<Currency, number>();

  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() userProfileFetched: EventEmitter<User> = new EventEmitter();

  @ViewChild('chatoffcanvas') chatOffcanvas: OffcanvasChatComponent;


  constructor(
    private _patientService: PatientService,
    private _appointmentService: AppointmentService,
    private _clipboardService: CopyClipboardService,
    private _authUserInfoService: AuthUserInfoService,
    public bsModalRef: BsModalRef,
    private _clientWalletService: ClientWalletService,
    private _p2pCallService: P2PCallService
  ) { }

  ngOnInit(): void {
    this.currentHeroInfo = this._authUserInfoService.getUserInfo();
  }

  fetchClientProfile(clientId: string, forceReload?: boolean) {
    this.client = new User();
    let _patientFromCache =
      this._patientService.getPatientFromCacheMap(clientId);
    if (_patientFromCache && !forceReload) {
      this.client = _patientFromCache;
      this.userProfileFetched.emit(this.client);
    } else {
      this.isLoadingPatientProfile = true;
      this._patientService
        .getPatientById(clientId)
        .then((snap) => {
          if (snap.exists) {
            let _ = plainToClass(User, snap.data());
            this.client = _;

            this._patientService.updatePatientCacheMap(_);
            this.userProfileFetched.emit(this.client);
          }
          this.isLoadingPatientProfile = false;
        })
        .catch((err) => {
          this.isLoadingPatientProfile = false;
          console.error(err);
        });
    }
  }

  getAppointments(userId: string) {
    this.allAppointments = [];
    this.allLeads = []
    this.isLoadingAppointments = true;
    this._appointmentService
      .getAppointmentsForPatient(userId)
      .subscribe((res) => {
        this.allAppointments = [];
        this.allLeads = []
        this.isLoadingAppointments = false;
        if (res != null && res['statusCode'] == 200) {
          // console.log('getAppointments', res['body']);
          let upcomingAppointments = res['body']['upcomingAppointments'];
          let pastAppointments = res['body']['pastAppointments'];
          let upcomingLeads = res['body']['upcomingLeads'];
          let pastLeads = res['body']['pastLeads'];

          // if (upcomingAppointments) {
          //   for (let a of upcomingAppointments) {
          //     if (a.date && a.timeslot) {
          //       this.allAppointments.push(plainToClass(Appointment, a));
          //     }
          //   }
          // }
          // if (pastAppointments) {
          //   for (let a of pastAppointments) {
          //     if (a.date && a.timeslot) {
          //       this.allAppointments.push(plainToClass(Appointment, a));
          //     }
          //   }
          // }
          if (upcomingLeads) {
            for (let a of upcomingLeads) {
              if (a.consultationInfo && a.consultationInfo.date && a.consultationInfo.timeslot) {
                this.allLeads.push(plainToClass(Lead, a));
              }
            }
          }
          if (pastLeads) {
            for (let a of pastLeads) {
              if (a.consultationInfo && a.consultationInfo.date && a.consultationInfo.timeslot) {
                this.allLeads.push(plainToClass(Lead, a));
              }
            }
          }
          this.allLeads.sort((a, b) => {
            return b.consultationInfo?.date.localeCompare(a.consultationInfo?.date);
          });
          // console.log(this.allLeads)
        } else {
        }
      });
  }

  copy(text) {
    if (text) {
      this._clipboardService.copyToClipoard(text);
    }
  }

  closeModal() {
    this.close.emit({});
  }

  openAppointmentInNewTab(appointment: Lead) {
    let _ =
      window.location.origin +
      '/patient-bookings/ld_' +
      appointment.consultationInfo.appointmentId +
      '?from=' +
      appointment.consultationInfo.date +
      '&to=' +
      appointment.consultationInfo.date +
      '&status=BOOKED&sortBy=bookingDate&qk=docvitaId&qv=' +
      appointment.consultationInfo.doctorId;
    window.open(_, '_blank');
  }

  openWhatsAppChat(c: ContactNumber) {
    window.open(
      location.origin + '/chats/whatsapp' + c.countryCode + c.number,
      '_blank'
    );
  }

  openChatInCanvas(c: ContactNumber) {
    this.chatOffcanvas.open('whatsapp' + c.countryCode + c.number)
  }

  currencyToSymbol(appointment: Appointment) {
    return new CountrywiseFeeCalc().currencyToSymbol(appointment.currency);
  }

  getWalletBalance(wallet: Map<Currency, number>) {
    if (wallet.size > 0) {
      this.wallet = wallet;
    } else {
      this.wallet = new Map<Currency, number>();
    }
  }

  callClient(contactNumber: ContactNumber) {
    let to = new P2PParticipantInfo();
    to.id = this.client.docvitaId;
    to.name = this.client.name;
    to.phoneNumber = contactNumber.countryCode + contactNumber.number;
    to.type = P2PParticipantType.client;
    this._p2pCallService.callFromHQ(this.currentHeroInfo, to);
  }

}
