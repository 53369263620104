import { Expose } from "class-transformer";

export enum P2PParticipantType {
    "client"="client",
    "provider"="provider",
    "dvTeam"="dvTeam",
}
export class P2PParticipantInfo {
    @Expose() phoneNumber: string;
    @Expose() type: P2PParticipantType;
    @Expose() id: string;
    @Expose() name: string;
}