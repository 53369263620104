import { DatePipe } from '@angular/common';
import { Component, OnInit, Input, HostListener, AfterContentInit, AfterViewInit, NgZone, ViewChild } from '@angular/core';
import { plainToClass } from 'class-transformer';

import { Subscription } from 'rxjs';
import { DoctorListingProfile } from 'src/app/shared/models-ts/Actors/Doctor/DoctorListingProfile';
import { DayjsHelper } from 'src/app/shared/models-ts/Helpers/dayjsHelper';
import { ScheduleService } from '../../services/schedule/schedule.service';
import { BlockAllSlotsPopupComponent } from './block-all-slots-popup/block-all-slots-popup.component';

@Component({
  selector: 'app-three-day-schedule',
  templateUrl: './three-day-schedule.component.html',
  styleUrls: [
    './three-day-schedule.component.scss',
    './../../shared/availability-styles.scss',
  ],
  providers: [DatePipe]
})
export class ThreeDayScheduleComponent implements OnInit, AfterViewInit {
  NO_OF_DATES: number = 3;

  subscription: Subscription;

  currentDate = new DayjsHelper().todayYYYYMMDD();
  dates: string[] = []

  scheduleMap: any = {}
  loadingSchedule: boolean = false;
  dayjsHelper = new DayjsHelper();

  slotToBlock: string;
  blockingSlotLoader: boolean;

  slotToUnBlock: string;
  unblockingSlotLoader: boolean;

  addingNewTime: string;
  newSlotStartTime: string;
  addingNewTimeLoader: boolean;
  newTimeErr: string;

  _providerProfile: DoctorListingProfile = new DoctorListingProfile();

  @Input() set providerProfile(val: DoctorListingProfile) {
    if (val && val.id) {
      this._providerProfile = val;
      if (!this.loadingSchedule) {
        this.getThreeDaySchedule();
      }
    } else {
      this._providerProfile = new DoctorListingProfile();
    }
  }

  get providerProfile() {
    return this._providerProfile;
  }

  isDesktop: boolean;

  constructor(
    private _datePipe: DatePipe,
    private _scheduleService: ScheduleService,
    private _zone: NgZone
  ) {
    this.getThreeDays();
  }

  ngOnInit(): void {
    this.getThreeDays();
  }

  ngAfterViewInit(): void {
    this._zone.run(() => {
      setTimeout(() => {
        this.isDesktop = window.innerWidth > 768;
      });
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isDesktop = event.target.innerWidth > 768;
  }

  getThreeDays() {
    const startDateInMillis = new DayjsHelper().YYYYMMDDHHmmToUnix(this.currentDate, '00:00')
    this.dates = [];
    for (let i = 0; i < this.NO_OF_DATES; i++) {
      let d = this._datePipe.transform(
        startDateInMillis + 24 * i * 60 * 60 * 1000,
        'yyyy-MM-dd',
        'Asia/Kolkata'
      );
      if (!this.dates.includes(d)) {
        this.dates.push(d);
      }
    }
    this.dates.sort((a, b) => {
      return a.localeCompare(b);
    });
  }

  async getThreeDaySchedule(clearAndLoad?: boolean) {
    try {
      if (clearAndLoad) {
        this.scheduleMap = {};
      }
      if (this.dates.length == 0) {
        this.getThreeDays();
      }
      this.loadingSchedule = true;
      const _scheduleMap = await this._scheduleService.getThreeDaySchedule(this.dates, this.providerProfile?.id);
      if (_scheduleMap) {
        this.scheduleMap = _scheduleMap;
      } else {
        this.scheduleMap = {}
      }
      console.log(this.scheduleMap)
      this.loadingSchedule = false;
    } catch (error) {
      switch (error?.message) {
        case "not_the_latest_call": {
          //Nothing to do here
          break;
        }
        case "no_schedule":
        default: {
          this.scheduleMap = {}
          this.loadingSchedule = false;
        }
      }

    }
  }

  getTimeInhhmma(HHmm: string) {
    return this.dayjsHelper.HHmmtohhmma(HHmm);
  }

  confirmBlockSlot(date: string, timeslot) {
    this.slotToBlock = date + timeslot['startTime'];
  }

  clearBlockSlot() {
    this.slotToBlock = '';
  }

  async blockSlot(date: string, timeslot) {
    try {
      const body = {
        date: date,
        startTime: timeslot['startTime'],
        endTime: timeslot['endTime'],
        providerId: this.providerProfile?.id
      }
      this.blockingSlotLoader = true;
      const blockSlot = await this._scheduleService.blockTimeslot(body);
      if (blockSlot) {
        const slotToChange = this.scheduleMap[date]?.filter(v => v && v['startTime'] == timeslot['startTime'] && v['endTime'] == timeslot['endTime'])
        if (slotToChange && slotToChange.length == 1) {
          slotToChange[0]['isAvailable'] = false;
        }

        this.clearBlockSlot();
      }
      this.blockingSlotLoader = false;
      // console.log(blockSlot)
    } catch (error) {
      // handle error
    }
  }

  confirmUnBlockSlot(date: string, timeslot) {
    this.slotToUnBlock = date + timeslot['startTime'];
  }

  clearUnBlockSlot() {
    this.slotToUnBlock = '';
  }

  async unblockSlot(date: string, timeslot) {
    try {
      const body = {
        date: date,
        startTime: timeslot['startTime'],
        endTime: timeslot['endTime'],
        providerId: this.providerProfile?.id
      }
      this.unblockingSlotLoader = true;
      const unblockSlot = await this._scheduleService.unblockTimeslot(body);
      if (unblockSlot) {
        const slotToChange = this.scheduleMap[date]?.filter(v => v && v['startTime'] == timeslot['startTime'] && v['endTime'] == timeslot['endTime'])
        if (slotToChange && slotToChange.length == 1) {
          slotToChange[0]['isAvailable'] = true;
        }
        this.clearUnBlockSlot();
        this.unblockingSlotLoader = false;
        return true
      } else {
        this.unblockingSlotLoader = false;
        return false
      }
      // console.log(blockSlot)
    } catch (error) {
      this.unblockingSlotLoader = false;
      return false
    }
  }

  scrollDates(direction: string) {
    const jumpCount = this.isDesktop ? 3 : 1;
    if (direction == 'next') {
      this.currentDate = this.dayjsHelper.addDaysToYYYYMMDD(this.currentDate, jumpCount);
      this.getThreeDays();
      this.getThreeDaySchedule(true);
    } else if (direction == 'prev' && !this.isToday) {
      let prevDate = this.dayjsHelper.subtractDaysFromYYYYMMDD(this.currentDate, jumpCount);
      const today = this.dayjsHelper.todayYYYYMMDD();
      if (prevDate < today) {
        prevDate = today;
      }
      this.currentDate = prevDate;
      this.getThreeDays();
      this.getThreeDaySchedule(true);
    }
    if (this.addingNewTime) {
      this.discardNewOpenSlot();
    }
  }

  get isToday() {
    const today = this.dayjsHelper.todayYYYYMMDD();
    return this.currentDate == today
  }

  addNewOpenSlot(date: string) {
    this.addingNewTime = date;
    this.newSlotStartTime = '';
  }
  discardNewOpenSlot() {
    this.addingNewTime = '';
    this.newSlotStartTime = '';
    this.newTimeErr = '';
  }

  async saveNewOpenSlot(date: string) {
    if (!this.newSlotStartTime || this.newSlotStartTime?.length < 5) {
      this.newTimeErr = 'Invalid time'
      return null;
    }

    if (this.isInPast(date, this.newSlotStartTime)) {
      this.newTimeErr = 'Use future times only'
      return;
    }

    const checkIfSameStartTime = this.scheduleMap[date]?.filter(v => v && v['isAvailable'] && v['startTime'] == this.newSlotStartTime)
    if (checkIfSameStartTime.length > 0) {
      this.newTimeErr = 'Slot time already exists'
      return;
    }

    const dayjs = this.dayjsHelper.getDayjs();
    const timeslot: any = {
      startTime: this.newSlotStartTime,
      endTime: dayjs(
        new Date(
          date +
          ' ' +
          this.newSlotStartTime
        )
      ).add(5, 'minutes').format('HH:mm')
    }

    const checkOverlapWithBooked = this.scheduleMap[date]?.filter(v => v && !v['isAvailable'] && v['reason'] == 'appointment-booking' && this.checkOverlap(v, timeslot))
    if (checkOverlapWithBooked.length > 0) {
      this.newTimeErr = 'Times overlap with a booked slot'
      return;
    }

    this.newTimeErr = '';

    try {
      this.addingNewTimeLoader = true;
      const unblocked = await this.unblockSlot(date, timeslot);
      if (unblocked) {
        const slot = {
          startTime: timeslot['startTime'],
          endTime: timeslot['endTime'],
          isAvailable: true,
        }
        this.scheduleMap[date]?.push(slot)
        this.scheduleMap[date]?.sort((a, b) => {
          if (a != null && b != null && a.startTime != null && b.startTime != null) {
            return a.startTime.localeCompare(b.startTime);
          } else {
            return 0;
          }
        })
        this.addingNewTimeLoader = false;
        this.discardNewOpenSlot();
      } else {
        this.addingNewTimeLoader = false;
      }
    } catch (error) {
      this.addingNewTimeLoader = false;
    }

  }

  isInPast(date: string, startTime: string) {
    const currentDateTime = new Date().getTime();
    const dateToCheck = new Date(date + "T" + startTime + ":00").getTime();
    if (currentDateTime > dateToCheck) {
      return true;
    } else {
      return false;
    }
  }

  get slotDuration() {
    if (
      this.providerProfile.schedule &&
      this.providerProfile.schedule.length > 0 &&
      this.providerProfile.schedule[0].timeSlotLength
    ) {
      return this.providerProfile.schedule[0].timeSlotLength;
    } else {
      return 60;
    }
  }

  checkOverlap(timeslot1, timeslot2) {
    const anyDate = new DayjsHelper().todayYYYYMMDD();
    const start1 = new Date(`${anyDate}T${timeslot1.startTime}:00`);
    const end1 = new Date(`${anyDate}T${timeslot1.endTime}:00`);
    const start2 = new Date(`${anyDate}T${timeslot2.startTime}:00`);
    const end2 = new Date(`${anyDate}T${timeslot2.endTime}:00`);
    if ((start1 <= end2) && (end1 >= start2)) {
      return true;
    } else {
      return false;
    }
  }

  @ViewChild('blockAllSlotsPopup') blockAllSlotsPopup: BlockAllSlotsPopupComponent;
  openBlockAllSlotsPopup(date: string) {
    this.blockAllSlotsPopup.openModal(date, this.providerProfile?.id, this.scheduleMap[date]);
  }

  trackById(date:string,index, item) {
    return '_'+date + '_'+ item['startTime'];
  }


}
