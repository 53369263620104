import { Injectable } from '@angular/core';
import { WaTemplateMessagesService } from './wa-template-messages.service';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root',
})
export class WaTemplateMessagesHelperService {
  constructor(
    private _waTemplateMessagesService: WaTemplateMessagesService,
    private _toastrService: ToastrService
  ) {}
  sendClientConfirmationUsingWaAPI(number: string, appointmentId: string) {
    this._waTemplateMessagesService
      .sendClientConfirmationForAppointment('', number, appointmentId)
      .then((resp) => {
        this._toastrService.success('Client confirmation sent');
      })
      .catch((err) => {
        console.error(err);
        this._toastrService.error(err);
      });
  }
  sendClientNoShowUsingWaAPI(number: string, appointmentId: string) {
    this._waTemplateMessagesService
      .sendClientNoShowForAppointment('', number, appointmentId)
      .then((resp) => {
        this._toastrService.success('message sent!');
      })
      .catch((err) => {
        console.error(err);
        this._toastrService.error(err);
      });
  }
  sendClientNotJoinedUsingWaAPI(number: string, appointmentId: string) {
    this._waTemplateMessagesService
      .sendClientNotJoinedForAppointment('', number, appointmentId)
      .then((resp) => {
        this._toastrService.success('message sent!');
      })
      .catch((err) => {
        console.error(err);
        this._toastrService.error(err);
      });
  }

  sendProviderConfirmationUsingWaAPI(number: string, appointmentId: string) {
    this._waTemplateMessagesService
      .sendProviderConfirmationForAppointment('', number, appointmentId)
      .then((resp) => {
        if (resp['status']) {
          this._toastrService.success('Provider confirmation sent');
        }
      })
      .catch((err) => {
        console.error(err);
        this._toastrService.error(err);
      });
  }

  sendClientRescheduleConfirmationUsingWaAPI(appointmentId: string, number: string) {
    this._waTemplateMessagesService
      .sendClientRescheduleConfirmationForAppointment(appointmentId, number)
      .then((resp) => {
        this._toastrService.success('Client reschedule info sent');
      })
      .catch((err) => {
        console.error(err);
        this._toastrService.error('Failed to send Client reschedule info');
      });
  }

  sendProviderRescheduleConfirmationUsingWaAPI(appointmentId: string, number: string) {
    this._waTemplateMessagesService
      .sendProviderRescheduleConfirmationForAppointment(appointmentId, number)
      .then((resp) => {
        this._toastrService.success('Provider reschedule info sent');
      })
      .catch((err) => {
        console.error(err);
        this._toastrService.error('Failed to send provider reschedule info');
      });
  }
}
