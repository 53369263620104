import { Component, Input, OnInit } from '@angular/core';
import { Lead } from 'src/app/shared/models-ts/Entities/Lead';
import * as dayjs from 'dayjs';
import { PatientLeadsBackendService } from '../../../patient-leads/services/patient-leads-backend.service';
import { ToastrService } from 'ngx-toastr';
import { CRMUser } from 'src/app/shared/models-ts/Actors/User/CRMUser';
import { AuthUserInfoService } from 'src/app/shared/services/auth-user-info/auth-user-info.service';
@Component({
  selector: 'app-follow-up-info',
  templateUrl: './follow-up-info.component.html',
  styleUrls: ['./follow-up-info.component.scss'],
})
export class FollowUpInfoComponent implements OnInit {
  _lead: Lead;
  @Input() set lead(val: Lead) {
    this._lead = val;
    this.followupDate = null;
    this.followupTime = null;
    if (val && val.consultationInfo && val.consultationInfo.followupDate) {
      this.editMode = false;
      this.followupDate = val.consultationInfo.followupDate;
      if (val.consultationInfo.followupTime)
        this.followupTime = val.consultationInfo.followupTime;
    } else {
      this.editMode = true;
      this.followupDate = null;
      this.followupTime = null;
    }
  }
  get lead(): Lead {
    return this._lead;
  }
  isLoading: boolean;
  followupTime: string;
  followupDate: string;
  editMode: boolean = false;
  currentHeroInfo: CRMUser;
  @Input() hideTitle: boolean;
  constructor(
    private _patientLeadsBackendService: PatientLeadsBackendService,
    private _toastrService: ToastrService,
    private _authUserInfoService: AuthUserInfoService
  ) {
    this.currentHeroInfo = this._authUserInfoService.getUserInfo();
  }

  ngOnInit(): void {}

  undo() {
    this.editMode = !this.editMode;
  }

  save() {
    this._toastrService.info('Saving follow up information');
    this.isLoading = true;
    if (this.followupDate && this.followupDate.length == 10) {
      this._patientLeadsBackendService
        .addFollowupInfo(this.lead.id, this.followupDate, this.followupTime)
        .then(() => {
          this._toastrService.success('Follow up information saved!');
          this.isLoading = false;
        })
        .catch((err) => {
          console.error(err);
          this.isLoading = false;
          this._toastrService.error(err);
        });
    }
  }
}
