<div class="card shadow-sm my-1">
  <div class="card-body d-flex flex-column p-2" style="font-size: 14px">
    <div class="d-flex align-items-center w-100 mb-2" style="font-weight: 700">
      <span
        class="material-icons me-1"
        style="font-size: 16px; font-weight: 700; color: rgb(70, 50, 255)"
        [innerHTML]="materialIconName"
      >
      </span
      >{{ title }}
    </div>
    <div
      *ngFor="let eachInfo of infoArray"
      class="d-flex justify-content-between align-items-start w-100 my-1"
    >
      <span style="font-weight: 500;" class="me-3">
        {{ eachInfo[0] }}</span
      >
      <span *ngIf="!isBool(eachInfo[1])">{{ eachInfo[1] }}</span>
      <span *ngIf="isBool(eachInfo[1])">{{ yesNo(eachInfo[1]) }}</span>
    </div>
  </div>
</div>
