import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as urls from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LeadCommentsService {

  constructor(private _http: HttpClient) { }

  async addNote(note:string, createdByName:string, createdById:string, leadId:string){
    try {
      const url = urls.environment['lead-add-note'];
      const body = { note, createdByName, createdById, leadId };
      const noteAdded = await this._http.post(url, body).toPromise();
      if(noteAdded && noteAdded['statusCode'] == 200 && noteAdded['body'] && noteAdded['body']['success'] == true){
        return true;
      }else{
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  async updateNote(noteId:string, note:string, updatedById:string, updatedByName:string, leadId:string){
    try {
      const url = urls.environment['lead-update-note'];
      note = note.toString();
      const body = { noteId, note, updatedById, updatedByName, leadId };
      const noteUpdated = await this._http.post(url, body).toPromise();
      if(noteUpdated && noteUpdated['statusCode'] == 200 && noteUpdated['body'] && noteUpdated['body']['success'] == true){
        return true;
      }else{
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  async deleteNote(noteId:string, leadId:string){
    try {
      const url = urls.environment['lead-delete-note'];
      const body = { noteId, leadId };
      console.log(body)
      const noteDeleted = await this._http.post(url, body).toPromise();
      console.log(noteDeleted)
      if(noteDeleted && noteDeleted['statusCode'] == 200 && noteDeleted['body'] && noteDeleted['body']['success'] == true){
        return true;
      }else{
        return false;
      }
    } catch (error) {
      return false;
    }
  }

}
