<div class="">
  <h2 class="mt-3">Practices & Connections</h2>
  <div class="d-flex justify-content-end mb-4">
    <button class="btn btn-sm btn-primary me-2" (click)="registerNewPractice()">
      + Register New
    </button>
    <button class="btn btn-sm btn-primary" (click)="connectPracticeModalOpen()">
      + Connect Existing Practice
    </button>
  </div>

  <div
    class="d-flex mb-4"
    *ngFor="let practice of associatedPractices; let i = index"
  >
    <div class="card w-100">
      <div class="card-body">
        <div class="d-flex mb-2">
          <h5>{{ practice.name }}</h5>
          <button
            class="btn btn-info btn-sm me-3"
            style="margin-left: auto"
            (click)="editClicked(practice.id)"
          >
            Edit Clinic Profile
          </button>
          <button
            class="btn btn-danger btn-sm"
            (click)="deletePractice(practice.id, i)"
          >
            Delete
          </button>
        </div>
        <div class="d-flex mb-2">
          <h6 style="width: 100px">Practice Id :</h6>
          <h6 style="font-weight: 400">{{ practice.id }}</h6>
        </div>
        <div class="d-flex mb-2">
          <h6 style="width: 100px">Type :</h6>
          <h6 style="font-weight: 400; text-transform: capitalize">
            {{ practice.type }}
          </h6>
        </div>
        <div class="form-row form-group required">
          <div class="col-6">
            <label for="a">Accepting Appointments on Docvita?</label>
            <select
              class="custom-select"
              id="a"
              [(ngModel)]="
                associatedPractices[i].acceptingAppointmentsOnDocVita
              "
              [ngValue]="associatedPractices[i].acceptingAppointmentsOnDocVita"
              name="a"
            >
              <option [ngValue]="true">Yes</option>
              <option [ngValue]="false">No</option>
            </select>
          </div>
          <div class="col-3">
            <label for="type">Relationship</label>
            <select
              class="custom-select"
              id="type"
              [(ngModel)]="associatedPractices[i].relationship"
              [value]="associatedPractices[i].relationship"
            >
              <option value="owner">Owner</option>
              <option value="visiting">Visiting</option>
            </select>
          </div>
          <div class="col-3">
            <label for="type">Fees</label>
            <input
              type="number"
              class="form-control"
              placeholder="Enter fees"
              [value]="practice.fee"
              [(ngModel)]="associatedPractices[i].fee"
            />
          </div>
        </div>
        <div class="form-row">
        <div class="col">
          <label for="weight" style="font-weight: 500;">Weight(?) <span style="font-size: 0.9em;font-weight: 400;">(Higher value = Higher priority in listing)</span></label>
          <input
              type="number"
              class="form-control"
              placeholder="Weight"
              style="width: 10rem;"
              [(ngModel)]="associatedPractices[i].orderWeight"
            />
        </div>
      </div>
        <button
          class="btn btn-primary btn-sm"
          style="float: right"
          (click)="updatePractice(i)"
        >
          Save Changes
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="registerPracticesModal"
  tabindex="-1"
  aria-labelledby="registerPracticesModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="registerPracticesModalLabel">
          New Practice Registration
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="card mb-3">
          <div class="card-body">
            <h4>Basic Information</h4>
            <div class="d-flex mt-4">
              <div class="col-3">
                <label class="file" *ngIf="!photoUrl">
                  <span style="font-size: 14px">+</span> <br />
                  <span style="font-size: 8px">Upload</span><br />
                  <span style="font-size: 8px">Photograph</span>
                  <input
                    type="file"
                    id="files"
                    (change)="fileProgress($event)"
                    accept="image/x-png,image/jpeg"
                    style="height: 150px"
                  />
                </label>
                <div style="z-index: 1000; position: relative" *ngIf="photoUrl">
                  <input
                    type="file"
                    (change)="fileProgress($event)"
                    accept="image/x-png,image/jpeg"
                    style="
                      background-color: tomato !important;
                      width: 100%;
                      height: 150px;
                    "
                  />
                  <img
                    [src]="photoUrl"
                    alt="Hero Image"
                    width="100%"
                    class="style__photo"
                    type="file"
                    height="150px"
                  />
                </div>
              </div>
              <div class="col-9">
                <div class="form-group required">
                  <label for="type" class="control-label">Practice Type</label>
                  <select
                    class="custom-select"
                    id="type"
                    [value]="currentSelectedPractice.type"
                    [(ngModel)]="currentSelectedPractice.type"
                  >
                    <option value="centre">Centre</option>
                    <option value="clinic">Clinic</option>
                    <option value="hospital">Hospital</option>
                  </select>
                </div>
                <div class="form-group required">
                  <label for="type" class="control-label">Practice Name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Practice Name"
                    [(ngModel)]="currentSelectedPractice.name"
                  />
                </div>
                <div class="form-group required">
                  <label for="type">Tagline</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Tagline"
                    [(ngModel)]="currentSelectedPractice.tagline"
                  />
                </div>
                <div class="form-row form-group required">
                  <div class="col-6">
                    <label for="type">Contact Number</label>
                    <input
                      type="number"
                      class="form-control"
                      placeholder="Mobile / Landline Number"
                      [(ngModel)]="publicContactNumber.number"
                    />
                  </div>
                  <div class="col-6">
                    <label for="type">Year of Establishment</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Year of Establishment"
                      [(ngModel)]="currentSelectedPractice.establishmentYear"
                    />
                  </div>
                </div>
                <div class="form-group required">
                  <label for="type">Email</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Email"
                    [(ngModel)]="currentSelectedPractice.email"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card mb-3">
          <div class="card-body">
            <h4>How To Reach</h4>
            <div class="form-group required mt-4">
              <label for="type" class="control-label">Address Line 1 </label>
              <input
                type="text"
                class="form-control"
                placeholder="Address line 1"
                [(ngModel)]="currentSelectedPractice.addressLine1"
              />
            </div>
            <div class="form-group required">
              <label for="type">Address Line 2</label>
              <input
                type="text"
                class="form-control"
                placeholder="Address line 1"
                [(ngModel)]="currentSelectedPractice.addressLine2"
              />
            </div>

            <div class="form-row form-group required">
              <div class="col-4">
                <label for="type" class="control-label">Locality</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Locality"
                  [(ngModel)]="currentSelectedPractice.locality"
                />
              </div>
              <div class="col-4">
                <label for="cityInput" class="control-label">City</label>
                <ng-select
                  [addTag]="true"
                  id="cityInput"
                  placeholder="Select city"
                  [(ngModel)]="currentSelectedPractice.city"
                >
                  <ng-option *ngFor="let c of city" [value]="c">{{ c }}</ng-option>
                </ng-select>
              </div>
              <div class="col-4">
                <label for="stateInput" class="control-label">State</label>
                <ng-select
                  [addTag]="true"
                  id="stateInput"
                  placeholder="Select state"
                  [(ngModel)]="currentSelectedPractice.state"
                >
                  <ng-option *ngFor="let s of state" [value]="s">{{ s }}</ng-option>
                </ng-select>
              </div>
            </div>
            <div class="form-row form-group required">
              <div class="col-4">
                <label for="type" class="control-label">Pincode</label>
                <input
                  type="number"
                  class="form-control"
                  placeholder="Pincode"
                  [(ngModel)]="currentSelectedPractice.pincode"
                />
              </div>
              <div class="col-8">
                <label for="type">Google Maps Link</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Gmaps location"
                  [(ngModel)]="currentSelectedPractice.googleMapLink"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="card mb-3" *ngIf="!editMode">
          <div class="card-body">
            <h4>Connection Settings</h4>
            <div class="form-row form-group required">
              <div class="col-6">
                <label for="type">Accepting Appointments on Docvita?</label>
                <select
                  class="custom-select"
                  id="type"
                  [(ngModel)]="clinicConnection.acceptingAppointmentsOnDocVita"
                  [ngValue]="clinicConnection.acceptingAppointmentsOnDocVita"
                >
                  <option [ngValue]="true">Yes</option>
                  <option [ngValue]="false">No</option>
                </select>
              </div>
              <div class="col-3">
                <label for="type">Relationship</label>
                <select
                  class="custom-select"
                  id="type"
                  [(ngModel)]="clinicConnection.relationship"
                  [value]="clinicConnection.relationship"
                >
                  <option value="owner">Owner</option>
                  <option value="visiting">Visiting</option>
                </select>
              </div>
              <div class="col-3">
                <label for="type">Fees</label>
                <input
                  type="number"
                  class="form-control"
                  placeholder="Enter fees"
                  [value]="clinicConnection.fee"
                  [(ngModel)]="clinicConnection.fee"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <h4>Profile Links</h4>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="ownWebsiteInp">Own Website</label>
                <input
                  type="text"
                  class="form-control"
                  id="ownWebsiteInp"
                  placeholder="Paste URL Link here"
                  [(ngModel)]="profileLinks.ownWebsiteUrl"
                />
              </div>
              <div class="form-group col-md-6">
                <label for="googleInp">Google</label>
                <input
                  type="text"
                  class="form-control"
                  id="googleInp"
                  placeholder="Paste URL Link here"
                  [(ngModel)]="profileLinks.googleProfileUrl"
                />
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="PractoInp">Practo</label>
                <input
                  type="text"
                  class="form-control"
                  id="PractoInp"
                  placeholder="Paste URL Link here"
                  [(ngModel)]="profileLinks.practoProfileUrl"
                />
              </div>
              <div class="form-group col-md-6">
                <label for="facebookInp">Facebook</label>
                <input
                  type="text"
                  class="form-control"
                  id="facebookInp"
                  placeholder="Paste URL Link here"
                  [(ngModel)]="profileLinks.facebookProfileUrl"
                />
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="justDialInp">JustDial</label>
                <input
                  type="text"
                  class="form-control"
                  id="justDialInp"
                  placeholder="Paste URL Link here"
                  [(ngModel)]="profileLinks.justdialProfileUrl"
                />
              </div>
              <div class="form-group col-md-6">
                <label for="InstagramInp">Instagram</label>
                <input
                  type="text"
                  class="form-control"
                  id="InstagramInp"
                  placeholder="Paste URL Link here"
                  [(ngModel)]="profileLinks.instagramProfileUrl"
                />
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="LinkedInInp">LinkedIn</label>
                <input
                  type="text"
                  class="form-control"
                  id="LinkedInInp"
                  placeholder="Paste URL Link here"
                  [(ngModel)]="profileLinks.linkedinProfileUrl"
                />
              </div>
              <div class="form-group col-md-6">
                <label for="TwitterInp">Twitter</label>
                <input
                  type="text"
                  class="form-control"
                  id="TwitterInp"
                  [(ngModel)]="profileLinks.twitterProfileUrl"
                  placeholder="Paste URL Link here"
                />
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="MyUpchaarInp">MyUpchaar</label>
                <input
                  type="text"
                  class="form-control"
                  id="MyUpchaarInp"
                  placeholder="Paste URL Link here"
                  [(ngModel)]="profileLinks.myupcharProfileUrl"
                />
              </div>
              <div class="form-group col-md-6">
                <label for="DocPrimeInp">DocPrime</label>
                <input
                  type="text"
                  class="form-control"
                  id="DocPrimeInp"
                  placeholder="Paste URL Link here"
                  [(ngModel)]="profileLinks.docprimeProfileUrl"
                />
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="KiviHealthInp">Kivi Health</label>
                <input
                  type="text"
                  class="form-control"
                  id="KiviHealthInp"
                  placeholder="Paste URL Link here"
                  [(ngModel)]="profileLinks.kivihealthProfileUrl"
                />
              </div>
              <div class="form-group col-md-6">
                <label for="DenteeInp">Dentee</label>
                <input
                  type="text"
                  class="form-control"
                  id="DenteeInp"
                  placeholder="Paste URL Link here"
                  [(ngModel)]="profileLinks.denteeProfileUrl"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            (click)="editPractice()"
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="connectPracticesModal"
  tabindex="-1"
  aria-labelledby="connectPracticesModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="connectPracticesModalLabel">
          Connect Practice
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="card">
          <div class="card-body">
            <h4>Connect Practice</h4>
            <div class="form-row form-group required">
              <div class="col-6">
                <label for="type" class="control-label">Type</label>
                <select
                  class="custom-select"
                  id="type"
                  [(ngModel)]="currentSelectedType"
                  [value]="currentSelectedType"
                >
                  <option value="centre">Centre</option>
                  <option value="clinic">Clinic</option>
                  <option value="hospital">Hospital</option>
                </select>
              </div>
              <div class="col-6">
                <label for="type" class="control-label">Choose Practice</label>
                <select
                  class="custom-select"
                  id="type"
                  [(ngModel)]="connectCurrenteSelectPractice"
                  [value]="connectCurrenteSelectPractice"
                >
                  <option
                    [value]="practice.id"
                    *ngFor="
                      let practice of allPractices
                        | practiceFilter: currentSelectedType
                    "
                  >
                    {{ practice.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-row form-group required">
              <div class="col-6">
                <label for="a" class="control-label">Accepting Appointments on Docvita?</label>
                <select
                  class="custom-select"
                  id="a"
                  [(ngModel)]="
                    connectExistingPractice.acceptingAppointmentsOnDocVita
                  "
                  ngValue="connectExistingPractice.acceptingAppointmentsOnDocVita"
                >
                  <option [ngValue]="true">Yes</option>
                  <option [ngValue]="false">No</option>
                </select>
              </div>
              <div class="col-3">
                <label for="type">Relationship</label>
                <select
                  class="custom-select"
                  id="type"
                  [(ngModel)]="connectExistingPractice.relationship"
                >
                  <option value="owner">Owner</option>
                  <option value="visiting">Visiting</option>
                </select>
              </div>
              <div class="col-3">
                <label for="type" class="control-label">Fees</label>
                <input
                  type="number"
                  class="form-control"
                  placeholder="Enter fees"
                  [(ngModel)]="connectExistingPractice.fee"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            (click)="connectPractice()"
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner></ngx-spinner>
