import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DoctorListingProfile } from '../../models-ts/Actors/Doctor/DoctorListingProfile';
import { Provider } from '../../models-ts/Actors/Provider/Provider';
@Injectable({
  providedIn: 'root',
})
export class DoctorDataService {
  currentSelection: BehaviorSubject<{}>;

  constructor() {
    this.currentSelection = new BehaviorSubject(null);
  }

  updateCurrentDoctor(selectedDoctor: DoctorListingProfile) {
    this.currentSelection['memberSelected'] = selectedDoctor;
    this.currentSelection.next(this.currentSelection);
  }

  getCurrentDoctor(): DoctorListingProfile {
    return this.currentSelection['memberSelected'];
  }
}
