<ng-select
  class="w-100"
  [placeholder]="customPlaceholder"
  [(ngModel)]="searchText"
  (change)="setDoctor()"
  (input)="setSearch($event)"
>
  <ng-option
    *ngFor="let doc of doctorProfilesList"
    [value]="doc.id"
    class="d-flex align-items-center"
  >
    <img
      [src]="doc.photoUrl"
      alt="doc-img"
      style="min-width: 20px; width: 20px; height: 20px"
      class="avatar avatar-circle avatar-image"
    />
    <span class="ms-1">{{ doc.fullName() }}</span>
    <span class="ms-1">{{
      doc.getMasterSpecializationDisplay()
        ? "(" + doc.getMasterSpecializationDisplay() + ")"
        : ""
    }}</span>
    <!-- <span class="d-flex"> -->
    <!-- <span *ngIf="doc.isProfileClaimed" class="badge bg-info ms-1 me-1">Claimed</span> -->
    <!-- <span *ngIf="doc.showInListing" class="badge bg-primary">Listed</span> -->
    <!-- </span> -->
  </ng-option>
</ng-select>
