import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterProvider'
})
export class FilterProviderPipe implements PipeTransform {

  transform(items:any[],providerName:string): unknown {
    if(providerName&&providerName.length>1){
      return items.filter(da=>da&&da['name'].includes(providerName));
    }else{
      return items;
    }
    
  }

}
