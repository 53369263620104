import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ChatListItem } from 'src/app/shared/models-ts/Entities/chat/ChatListItem';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { plainToClass } from 'class-transformer';
import { InitChatDB } from '../db/init';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ChatListService {
  public chatListItemsUpdated: BehaviorSubject<ChatListItem[]> =
    new BehaviorSubject([]);
  private chatListItems: ChatListItem[] = [];
  private cache: Map<string, ChatListItem> = new Map();
  private firebaseListener: any;
  private chatDB: InitChatDB = new InitChatDB();
  batch = 100;
  batchCount = 1;

  constructor(private _http: HttpClient) {}

  activateChatsLiveListener(tab?: string) {
    return new Promise((resolve, reject) => {
      if (this.firebaseListener) {
        this.firebaseListener();
      }
      let ref = firebase
        .firestore()
        .collection('chat_list')
        .orderBy('updatedOn', 'desc')
        .limit(this.batch);
      if (tab && tab == 'brain') {
        ref = firebase
          .firestore()
          .collection('chat_list')
          .where('isResolved.bool', '==', true)
          .orderBy('updatedOn', 'desc')
          .limit(this.batch);
      }
      // else if (tab && tab == 'active') {
      //   ref = firebase
      //     .firestore()
      //     .collection('chat_list')
      //     .where('isResolved.bool', '!=', true)
      //     .orderBy('isResolved.bool', 'desc')
      //     .orderBy('updatedOn', 'desc')
      //     .limit(this.batch);
      // }
      this.firebaseListener = ref.onSnapshot(
        (snap) => {
          this.chatListItems = [];
          if (!snap.empty) {
            snap.forEach((doc) => {
              if (doc.exists) {
                let obj = plainToClass(ChatListItem, doc.data());
                obj.chatItemId = doc.id;
                if (
                  !tab ||
                  (tab && tab != 'active') ||
                  (tab &&
                    tab == 'active' &&
                    (!obj.isResolved ||
                      (obj.isResolved && obj.isResolved.bool == false)))
                ) {
                  this.chatListItems.push(obj);
                  let jsonVal = doc.data();
                  jsonVal['chatItemId'] = doc.id;
                  // this.putInChatDB(obj,jsonVal);
                }
              }
            });
          } else {
          }
          this.chatListItemsUpdated.next(this.chatListItems);
          resolve(true);
        },
        (err) => {
          console.error(err);
          // this.activateLocalDBCache();
          reject(err);
        }
      );
    });
  }

  nextBatch(chatListItem: ChatListItem, tab?: string) {
    //Check if we have the data of next 50
    let ref = firebase
      .firestore()
      .collection('chat_list')
      .where('updatedOn', '<', chatListItem.updatedOn)
      .orderBy('updatedOn', 'desc')
      .limit(this.batch);
    if (tab && tab == 'brain') {
      ref = firebase
        .firestore()
        .collection('chat_list')
        .where('updatedOn', '<', chatListItem.updatedOn)
        .where('isResolved.bool', '==', true)
        .orderBy('updatedOn', 'desc')
        .limit(this.batch);
    }
    //  else if (tab && tab == 'active') {
    //   ref = firebase
    //     .firestore()
    //     .collection('chat_list')
    //     .where('updatedOn', '<', chatListItem.updatedOn)
    //     .where('isResolved.bool', '!=', true)
    //     .orderBy('updatedOn', 'desc')
    //     .limit(this.batch);
    // }
    ref.onSnapshot(
      (snap) => {
        if (!snap.empty) {
          snap.forEach((doc) => {
            if (doc.exists) {
              let obj = plainToClass(ChatListItem, doc.data());
              obj.chatItemId = doc.id;
              if (
                !tab ||
                (tab && tab != 'active') ||
                (tab &&
                  tab == 'active' &&
                  (!obj.isResolved ||
                    (obj.isResolved && obj.isResolved.bool == false)))
              ) {
                this.chatListItems.push(obj);
                let jsonVal = doc.data();
                jsonVal['chatItemId'] = doc.id;
                // this.putInChatDB(obj,jsonVal);
              }
            }
          });
          this.batchCount++;
          this.activateLocalDBCache();
        } else {
        }
      },
      (err) => {}
    );
  }

  putInChatDB(obj: ChatListItem, jsonVal: any) {
    this.cache.set(obj.chatItemId, obj);
    // this.chatDB.put(obj.chatItemId,jsonVal );
  }

  updateTyping(
    chatListItemId: string,
    loggedInUserId: string,
    loggedInUserName: string
  ) {
    let url = environment['chat-typing'];
    let finalJSON = {
      chatListItemId: chatListItemId,
      typerId: loggedInUserId,
      typerName: loggedInUserName,
    };
    this._http.post(url, finalJSON).subscribe((resp) => {});
  }

  activateLocalDBCache() {
    console.log('tick', new Date().getTime());
    // this.chatDB.db.createIndex({
    //   index: {
    //     fields: ['updatedOn'],
    //     ddoc: "chat-list-updated-on"
    //   }
    // })
    // this.chatDB.db.find({
    //   selector: {},
    //   sort: [{'updatedOn':"desc"}],
    //   limit: this.batch,
    //   use_index: "chat-list-updated-on"
    // }).then((result) => {
    //   console.log("got results",result.docs.length,new Date().getTime());
    //   // handle result
    //   if(result.docs) {
    //     this.chatListItems = [];
    //     for(let r of result.docs) {
    //       let obj = plainToClass(ChatListItem,r);
    //       this.chatListItems.push(obj);
    //     }
    //     this.chatListItemsUpdated.next(this.chatListItems);
    //     console.log("tock",new Date().getTime());
    //   } else {
    //     this.chatListItemsUpdated.next([]);
    //     console.log("tock",new Date().getTime());
    //   }
    // }).catch((err) => {
    //   console.log(err);
    //   this.chatListItemsUpdated.next([]);
    //   console.log("tock",new Date().getTime());
    // });
  }

  async getChatListItem(chatListItemId: string): Promise<ChatListItem> {
    if (this.cache.has(chatListItemId)) {
      return this.cache.get(chatListItemId);
    } else {
      try {
        let val = await this.chatDB.get(chatListItemId);
        if (val) {
          return val;
        } else {
          let doc = await firebase
            .firestore()
            .collection('chat_list')
            .doc(chatListItemId)
            .get();
          if (doc.exists) {
            let obj = plainToClass(ChatListItem, doc.data());
            obj.chatItemId = doc.id;
            let jsonVal = doc.data();
            jsonVal['chatItemId'] = doc.id;
            this.putInChatDB(obj, jsonVal);
            return obj;
          } else {
            return null;
          }
        }
      } catch (e) {
        console.error(e);
        return null;
      }
    }
  }
}
