<div class="p-1 w-100 d-flex flex-column">
  <div class="w-100 d-flex align-items-center py-2 sticky-top position-sticky" style="background-color: white;">
    <span class="flex-fill">
      <b>Note:</b>&nbsp;Do not forget to save the changes by pressing the button
      on the right.
    </span>
    <button type="button" style="width: 120px" class="btn btn-sm btn-primary" (click)="updateBasicInformation()">
      Save Changes
    </button>
    <button type="button" *ngIf="
        currentHeroInfo != null &&
        currentHeroInfo != null &&
        currentHeroInfo.isDocVitaAdmin()
      " class="btn btn-sm btn-danger ms-2" data-bs-toggle="modal" data-bs-target="#deleteModal"
      (click)="onDeleteConfirmation('#deleteModal')" [disabled]="false">
      Delete
    </button>
  </div>
  <div class="d-flex align-items-center py-2">
    <div>
      <b>Mobile App Last Access:</b>
      {{ (mobileAppLastAccess ? mobileAppLastAccess : "") | date: "short" }}
    </div>
    <app-verify-approve [profileData]="profileData" class="ms-auto"></app-verify-approve>
  </div>
  <div class="form-row py-3 d-flex" style="position: relative">
    <div class="mx-2" style="width: 150px">
      <app-profile-photo [listingProfile]="profileData"></app-profile-photo>
    </div>
    <div class="flex-fill px-2 d-flex flex-column">
      <div class="form-row d-flex">
        <div class="form-group required">
          <!-- <label for="inputState" class="control-label">Title</label> -->
          <select id="inputState" class="form-select" [disabled]="profileData.isProfileVerifiedByDocVita"
            [(ngModel)]="profileData.salutation" required>
            <option selected value="Dr.">Dr.</option>
            <option value="Mr.">Mr.</option>
            <option value="Mrs.">Mrs.</option>
            <option value="Ms.">Ms.</option>
            <option value=""></option>
          </select>
        </div>
        <div class="form-group required flex-fill">
          <!-- <label for="nameInput" class="control-label">Name</label> -->
          <input type="text" class="form-control form-control-right" id="nameInput"
            [disabled]="profileData.isProfileVerifiedByDocVita" placeholder="Enter Doctor's Name"
            [(ngModel)]="profileData.name" required />
        </div>
        <div class="form-group required">
          <!-- <label for="genderSelect">Gender</label> -->
          <select id="genderSelect" class="form-select" style="border-radius: 0px;" [(ngModel)]="profileData.gender"
            required>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
        </div>
        <div class="form-group">
          <input class="form-control form-control-right" [(ngModel)]="profileData.pronoun" placeholder="Pronoun - she/they" type="text"/>
        </div>
      </div>
      <div class="form-row d-flex">
        <div class="form-group flex-fill required">
          <!-- <label for="cityInput" class="control-label">City</label> -->
          <ng-select [addTag]="true" id="cityInput" placeholder="Select city" [(ngModel)]="profileData.city">
            <ng-option *ngFor="let c of city" [value]="c">{{ c }}</ng-option>
          </ng-select>
        </div>
        <div class="form-group flex-fill">
          <!-- <label for="typeInput" class="control-label">Type</label> -->
          <ng-select class="ng-select-right" [addTag]="true" id="typeInput" placeholder="Select type"
            [(ngModel)]="profileData.type">
            <ng-option *ngFor="let c of ['doctor', 'specialist']" [value]="c">{{
              c
              }}</ng-option>
          </ng-select>
        </div>
      </div>
      <div class="form-row d-flex align-items-center pb-3">
        <label class="me-2 mb-0" for="expInYears">Experience (in yrs): </label>
        <div class="form-group mb-0 flex-fill">
          <input type="number" class="form-control" id="expInYears" placeholder="Experience in years"
            [(ngModel)]="profileData.experienceInYears" />
        </div>
      </div>
      <div class="form-row d-flex">
        <div class="form-group w-100">
          <!-- <label for="lang_select">Languages</label> -->
          <ng-select id="lang_select" [multiple]="true" [addTag]="true" placeholder="Select Languages"
            [(ngModel)]="profileData.languages">
            <ng-option *ngFor="let lang of languages" [value]="lang">{{
              lang
              }}</ng-option>
          </ng-select>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex align-items-center pb-3">
    <span class="me-2"> Profile: </span>
    <a me-3 href="{{ docvitaProfileLink }}" target="_blank">{{
      docvitaProfileLink
      }}</a>
    <button type="button" class="btn btn-sm btn-outline-primary border-0" (click)="copyDocVitaProfileLink()">
      <i style="font-size: 15px" class="material-icons me-1">content_copy</i>
    </button>
  </div>

  <div class="form-row d-flex align-items-center pb-3">
    <label class="me-2 mb-0" for="spec_select">Specializations:</label>
    <div class="form-group mb-0 flex-fill">
      <ng-select id="spec_select" [multiple]="true" [addTag]="true" placeholder="Select Specialization(s)"
        [(ngModel)]="profileData.specializations">
        <ng-option *ngFor="let spec of specializations" [value]="spec.name">{{
          spec.name
          }}</ng-option>
      </ng-select>
    </div>
  </div>

  <div class="form-row d-flex align-items-center pb-3">
    <label class="me-2 mb-0" for="listed_in_cities">Listed in:</label>
    <div class="form-group mb-0 flex-fill">
      <ng-select id="city_select" [multiple]="true" [addTag]="addCustomListedInCity" placeholder="Select cities"
        [(ngModel)]="profileData.listedInCities">
        <ng-option *ngFor="let c of city" [value]="c">{{ c }}</ng-option>
      </ng-select>
    </div>
  </div>

  <div class="form-row d-flex align-items-center">
    <div class="form-group flex-fill">
      <label for="about">About</label>
      <textarea type="text" class="form-control" id="about" rows="8" placeholder="About"
        [(ngModel)]="profileData.about">
      </textarea>
    </div>

    <button class="btn btn-sm btn-info ms-2" [disabled]="profileData.showInListing" (click)="autoFillAbout()">
      Auto
    </button>
  </div>

  <div class="form-row d-flex align-items-center" *ngIf="
      currentHeroInfo != null &&
      currentHeroInfo.isDocVitaAdmin()
    ">
    <div class="form-group flex-fill">
      <label for="about">Unique Tag</label>
      <textarea [disabled]="profileData.showInListing" type="text" class="form-control" id="unique_tag" rows="1"
        placeholder="Unique Tag" [(ngModel)]="uniqueTagInputStr">
      </textarea>
    </div>
    <button [disabled]="profileData.showInListing" class="btn btn-sm btn-info ms-2" (click)="generateUniqueTag()">
      Auto
    </button>
  </div>

  <app-contact-numbers [profileData]="profileData" (contactMap)="updateContactNumbers($event)" #getContactMap>
  </app-contact-numbers>
  <app-emails [profileData]="profileData" (emailMap)="updateEmails($event)" #getEmailMap></app-emails>
  <div id="online-platforms" class="d-flex flex-column" style="width: 100%;">
    <h3>Available On: </h3>
    <div *ngFor="let platform of onlineConsultationOnPlatformOptions" class="d-flex flex-column mt-4">
      <div class="d-flex align-items-center mb-3">
        <span style="min-width:7rem;font-size: 16px;color: rgb(56, 56, 56);" class="fw-600">{{getPlatformTitle(platform)}}</span>
        <div class="form-check form-switch mb-0 ms-5">
          <input [(ngModel)]="offersOnlineConsultationOnPlatform[platform]" (ngModelChange)="platformToggle($event,platform)" type="checkbox" class="form-check-input"
            id="{{platform}}" />
          <label class="form-check-label" for="{{platform}}"></label>
        </div>
      </div>

      <div class="d-flex ms-4" *ngIf="offersOnlineConsultationOnPlatform[platform]">
        <div class="form-check" style="min-width: 7rem;">
          <input class="form-check-input" type="checkbox" (ngModelChange)="makePrimaryConsult($event,platform)"
            [(ngModel)]="onlineConsultationPlatformConfig[platform]['isPrimary']">
          <label class="form-check-label ms-2">
            isPrimary?
          </label>
        </div>
        <div class="d-flex flex-row" *ngIf="platform=='whatsapp'">
          <div class="ms-4">
            <span>Provider WhatsApp Details</span>
            <div class="d-flex mt-1">
              <input type="text" style="font-size: 0.8em; padding: 0.2rem; width:5rem;" placeholder="Country Code"
                class="form-control rounded" [(ngModel)]="waContactNumber.countryCode">
              <input type="text" style="font-size: 0.8em; padding: 0.2rem; width:10rem;" placeholder="WhatsApp Number"
                class="form-control ms-3 rounded" [(ngModel)]="waContactNumber.number">
            </div>
          </div>
          <div class="ms-4">
            <span>Who will initiate the call?</span>
            <div class="d-flex mt-1">
              <div class="form-check" style="min-width: 5rem;">
                <input class="form-check-input" type="radio"
                  name="options" id="option1" value="client" 
                  [(ngModel)]="onlineConsultationPlatformConfig[platform]['waCallInitiator']">
                <label class="form-check-label ms-2">
                  Client
                </label>
              </div>
              <div class="form-check" style="min-width: 5rem;">
                <input class="form-check-input" type="radio"
                  name="options" id="option2" value="provider" 
                  [(ngModel)]="onlineConsultationPlatformConfig[platform]['waCallInitiator']">
                <label class="form-check-label ms-2">
                  Provider
                </label>
              </div>
            </div>
          </div>
          <div class="ms-4">
            <span>Call Medium</span>
            <div class="d-flex mt-1">
              <div class="form-check" style="min-width: 5rem;">
                <input class="form-check-input" type="radio"
                  name="medium-options" value="audio" 
                  [(ngModel)]="onlineConsultationPlatformConfig[platform]['waCallMedium']">
                <label class="form-check-label ms-2">
                  Audio
                </label>
              </div>
              <div class="form-check" style="min-width: 5rem;">
                <input class="form-check-input" type="radio"
                  name="medium-options" value="video" 
                  [(ngModel)]="onlineConsultationPlatformConfig[platform]['waCallMedium']">
                <label class="form-check-label ms-2">
                  Video
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="form-check ms-2" style="min-width: 7rem;" *ngIf="platform!='whatsapp'&&platform!='phone'">
          <input class="form-check-input" type="checkbox"
            [disabled]="(!profileData.isGoogleAuthVerified&&platform=='googlemeet')||(!profileData.isZoomAuthVerified&&platform=='zoom')"
            [(ngModel)]="onlineConsultationPlatformConfig[platform]['isDynamic']">
          <label class="form-check-label ms-2" placement="top" [tooltip]="dynMessage">
            Dynamic Link?
          </label>
        </div>
        <div *ngIf="!onlineConsultationPlatformConfig[platform]['isDynamic']&&(platform!='whatsapp'&&platform!='phone')"
          class="d-flex align-items-center">
          <input type="text" style="font-size: 0.8em; padding: 0.2rem; width:14rem;" placeholder="Meeting Link"
            class="form-control ms-3 rounded" [(ngModel)]="onlineConsultationPlatformConfig[platform]['url']">
          <ng-container *ngIf="platform=='zoom'">
            <input type="text" style="font-size: 0.8em; padding: 0.2rem; width:10rem;" placeholder="Meeting ID"
              class="form-control ms-3 rounded" [(ngModel)]="onlineConsultationPlatformConfig[platform]['meetingId']">
            <input type="text" style="font-size: 0.8em; padding: 0.2rem; width:8rem;" placeholder="Password"
              class="form-control ms-3 rounded" [(ngModel)]="onlineConsultationPlatformConfig[platform]['password']">
          </ng-container>
          <span class="material-icons ms-2 text-muted pointer" style="font-size: 1.2em;" [title]="staticLinkMessage">
            help_outline
          </span>
        </div>

      </div>
      <div class="w-100 mt-3" style="height:1.5px;background-color: rgb(228, 232, 250);"></div>
    </div>
  </div>
  <h2 class="mb-4 mt-3">Online Consultation</h2>
  <div class="form-row">
    <div class="form-group col-md-4">
      <label for="consultFees">Video consultation fees</label>
      <input type="number" class="form-control" id="consultFees" placeholder="Video Consultation fees (₹)"
        [(ngModel)]="onlineConsultationConfig.fee" />
    </div>
  </div>
  <!-- <div class="form-row">
    <div class="col-md-4">
      <label for="consultFees">Appointment Length (minutes)</label>
      <input type="number" class="form-control" id="appointmentDuration" placeholder="Appointment length (in minutes)"
        [(ngModel)]="onlineConsultationConfig.appointmentDuration" />
    </div>
    <div class="col-md-4">
      <label for="consultFees"> Accept Appointment Time Diff. (minutes)</label>
      <input type="number" class="form-control" id="acceptAppointmentDiff"
        placeholder="Accept Appointment Time Diff. (in minutes)"
        [(ngModel)]="onlineConsultationConfig.acceptAppointmentDiff" />
    </div>
  </div> -->
  <ng-container>
    <div class="d-flex justify-content-between align-items-center">
      <h2 class="mb-4 mt-3">Listed In</h2>
      <button (click)="saveListedInValues()" class="btn btn-sm btn-primary">
        Save
      </button>
    </div>
    <ng-select [(ngModel)]="selectionListedInArray" [multiple]="true" bindValue="urlPath" bindLabel="title"
      [closeOnSelect]="false" (add)="addListedInItem($event)" (remove)="removeListedInItem($event)"
      [items]="listedInSuggestionArray" placeholder="Choose Listed In Groups">
    </ng-select>
  </ng-container>

  <ng-container>
    <div class="d-flex justify-content-between align-items-center">
      <h2 class="mb-4 mt-3">Reasons Of Visit</h2>
      <button (click)="saveReasonOfVisitValues()" class="btn btn-sm btn-primary">
        Save
      </button>
    </div>
    <ng-select [(ngModel)]="selectionReasonsOfVisitArray" [multiple]="true" bindValue="urlPath" bindLabel="title"
      [closeOnSelect]="false" (add)="addReasonOfVisitItem($event)" (remove)="removeReasonOfVisitItem($event)"
      [items]="reasonOfVisitSuggestionArray" placeholder="Choose Reason Of Visits">
    </ng-select>
    <div class="" *ngIf="
        selectionReasonsOfVisitArray != null &&
        selectionReasonsOfVisitArray.length > 0
      ">
      <div class="d-flex flex-row my-1 justify-content-between"
        *ngFor="let reasonUrlPath of selectionReasonsOfVisitArray">
        <div class="d-flex flex-row justify-content-between w-100" *ngIf="getReasonFromSuggesstion(reasonUrlPath)">
          <div class="d-flex flex-row justify-content-start align-items-center">
            <span># {{ getReasonFromSuggesstion(reasonUrlPath).title }}</span>
            <input [(ngModel)]="
            selectionReasonsOfVisitMap.get(reasonUrlPath).profileChipDisplay
          " type="text" placeholder="Profile Chip" class="mx-2 form-control" style="min-width: 250px;" />
          </div>
          <!-- <button class="ms-2 btn btn-sm" [ngClass]="{'btn-outline-danger': selectionReasonsOfVisitMap.get(reasonUrlPath).display,
          'btn-outline-primary':!selectionReasonsOfVisitMap.get(reasonUrlPath).display}" (click)="toggleVisibilityForReason(reasonUrlPath)">
            {{selectionReasonsOfVisitMap.get(reasonUrlPath).display ? "Mark as hidden": "Show In Display"}}
          </button> -->
          <div class="form-check form-switch ms-5">
            <input [(ngModel)]="
                selectionReasonsOfVisitMap.get(reasonUrlPath).display
              " type="checkbox" class="form-check-input" id="{{ reasonUrlPath }}" />
            <label class="form-check-label ms-2" for="{{ reasonUrlPath }}">Display toggle</label>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<!-- Modal -->
<!-- Modal -->
<div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteModalLabel">
          Are you sure you want to delete?
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onDeleteConfirmationClose('#deleteModal')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!-- <div class="modal-body">Are you sure you want to delete?</div> -->
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary"  data-dismiss="modal" (click)="onDeleteConfirmationClose('#deleteModal')">
          Close
        </button>
        <button type="button" class="btn btn-danger" (click)="deleteProfile()">
          Delete
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="deleteImageModal" tabindex="-1" aria-labelledby="deleteImageModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteImageModalLabel">
          Are you sure you want to delete?
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!-- <div class="modal-body">Are you sure you want to delete?</div> -->
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Close
        </button>
        <button type="button" class="btn btn-danger" (click)="deleteImage()">
          Delete
        </button>
      </div>
    </div>
  </div>
</div>
<ngx-spinner></ngx-spinner>
<canvas id="canvas" width="200" height="200"></canvas>