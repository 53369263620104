<div class="btn-group" [isDisabled]="isDisabled" dropdown>
  <button
    id="currency-selector"
    dropdownToggle
    type="button"
    class="btn btn-outline-secondary dropdown-toggle form-control"
    aria-controls="currency-selector-drp-dwn"
    style="width: 100px"
  >
    {{ currentCurrency }} <span class="caret"></span>
  </button>
  <ul
    id="currency-selector-drp-dwn"
    *dropdownMenu
    class="dropdown-menu"
    role="menu"
    aria-labelledby="currency-selector"
  >
    <li role="menuitem" *ngFor="let item of currenciesToSelect">
      <a class="dropdown-item" (click)="setCurrency(item)">
        {{ item }}
      </a>
    </li>
  </ul>
</div>
