export class URLHelper {
  public urls(baseUrl: String, crmBaseUrl: String, listingUrl: String) {
    return {
      'update-education-registration':
        crmBaseUrl + '/v1/member/update-education-registration',
      'unlink-clinic': crmBaseUrl + '/v1/doctor-listing/unlink-clinic',
      'upload-logo': crmBaseUrl + '/v1/clinic/logo-upload',
      'send-otp': crmBaseUrl + '/v1/auth/crm/generate-otp',
      'resend-otp': crmBaseUrl + '/v1/auth/crm/resend-otp',
      'verify-otp': crmBaseUrl + '/v1/auth/crm/verify-otp',
      'verify-gauth': crmBaseUrl + '/v1/auth/crm/email-auth',
      'reauth-check': crmBaseUrl + '/v1/auth/crm/reauth-check',
      'create-auth-acc': crmBaseUrl + '/v1/auth/crm/create-auth-account',
      'get-token': crmBaseUrl + '/v1/auth/crm/get-token',
      'medical-registration-docs-upload':
        crmBaseUrl + '/v1/medical-registration-document/direct-upload',
      'upload-photo': crmBaseUrl + '/v1/listing/photo-upload',
      'transaction-add-promo-balance':
        baseUrl + '/v2/transaction/add-promo-balance',
      'create-claim': crmBaseUrl + '/v1/claim-profile/retool',
      'docvita-user-stats': crmBaseUrl + '/v1/member/docvita-user-stats',
      'unlink-mutliple-clinic':
        crmBaseUrl + '/v1/doctor-listing/unlink-multiple-clinic',
      'generate-about': crmBaseUrl + '/v1/doctor-listing/generate-about',
      'generate-unique-tag':
        crmBaseUrl + '/v1/doctor-listing/autogenerate-slug',
      'assign-profiles': crmBaseUrl + '/v1/health-hero/assign-doctor-profile',
      'add-beneficiary': listingUrl + '/v1/payouts/add-beneficiary',
      'remove-beneficiary': listingUrl + '/v1/payouts/remove-beneficiary',
      'bank-details-using-razorpay-id':
        listingUrl + '/v1/bank-details/fetch-using-razorpay-id',
      'upload-medical-proofs-to-claims':
        crmBaseUrl + '/v1/medical-registration-document/claims-direct-upload',
      'upload-government-proofs-to-claims':
        crmBaseUrl +
        '/v1/government-registration-document/claims-direct-upload',
      'initialize-new-wallet':
        crmBaseUrl + '/v1/doctor-listing/wallet-intialization',
      'draft-email': crmBaseUrl + '/v1/crm-communication/draft-email',
      'save-contact': crmBaseUrl + '/v1/doctor-contact/save',
      'save-contact-customer': crmBaseUrl + '/v1/customer-contact/save',
      'save-multiple-customer-contacts':
        crmBaseUrl + '/v1/customer-multi-contact/save',
      'save-booking-contact-customer':
        crmBaseUrl + '/v1/customer-booking-contact/save',
      'generate-Token': crmBaseUrl + '/v1/access_token',
      'create-pay-link': listingUrl + '/v1/pay/create-link',
      'book-appointment': crmBaseUrl + '/v1/leads/book',
      'reschedule-appointment': listingUrl + '/v1/change-appointment/change',
      'reassign-appointment':
        baseUrl + '/v3/appointment/reassign-and-reschedule',
      'clashing-appointment':
        baseUrl + '/v3/appointment/get-clashing-appointments',
      'lead-payout': listingUrl + '/v1/payouts/request-transfer-for-lead',
      'provider-payout':
        listingUrl + '/v1/payouts/request-transfer-for-provider',
      'lead-refresh-status-payout':
        listingUrl + '/v1/payouts/refresh-transfer-status-for-lead',
      'payment-link-payout':
        listingUrl + '/v1/payouts/request-transfer-for-payment-link',
      'payment-link-refresh-status-payout':
        listingUrl + '/v1/payouts/refresh-transfer-status-for-payment-link',
      'create-patient-profile':
        baseUrl + '/v1/patient/profile/new-registration',
      'update-patient-profile':
        baseUrl + '/v1/patient/profile/update-patient-details',
      'update-patient-profile-no-merge':
        baseUrl + '/v1/patient/profile/update-patient-details-no-merge',
      'update-lead-payment-source':
        crmBaseUrl + '/v1/leads/update-payment-source',
      'update-lead-payment-id': crmBaseUrl + '/v1/leads/update-payment-id',
      'update-appointment-json':
        crmBaseUrl + '/v1/leads/update-appointment-json',
      'get-all-appointments':
        baseUrl + '/v3/appointment/patient/all-appointments',
      'calendar-event-create-for-doctor':
        baseUrl + '/v3/appointment/calendar-event/create-for-doctor',
      'calendar-event-create-for-patient':
        baseUrl + '/v3/appointment/calendar-event/create-for-patient',
      'read-calendar-event': baseUrl + '/v1/calendar-event/read',
      'update-calendar-event': baseUrl + '/v1/calendar-event/update',
      'refresh-cf-refund-info':
        listingUrl + '/v1/refund/refresh-refund-info-for-lead',
      'save-manual-refund-info':
        listingUrl + '/v1/refund/manual-refund-for-lead',
      'release-razorpay-transfer-from-hold':
        listingUrl + '/v1/razorpay/release-transfer-for-lead',
      'refresh-razorpay-transfer-for-lead':
        listingUrl + '/v1/razorpay/refresh-transfer-for-lead',
      'refresh-razorpay-transfer-for-lead-using-payment-link-id':
        listingUrl +
        '/v1/razorpay/refresh-transfer-for-lead-using-payment-link-id',
      'reversal-razorpay-transfer-for-lead':
        listingUrl + '/v1/razorpay/reversal-transfer-for-lead',
      'remove-search-item': listingUrl + '/v1/docvita-search/remove-entry',
      'create-search-item': listingUrl + '/v1/docvita-search/create-entry',
      'update-search-item': listingUrl + '/v1/docvita-search/update-entry',
      'cancel-appointment': baseUrl + '/v3/appointment/cancel',
      'restore-appointment': baseUrl + '/v3/appointment/restore',
      'add-followup-info': crmBaseUrl + '/v1/leads/add-followup-info',
      'clear-followup-info': crmBaseUrl + '/v1/leads/clear-followup-info',
      'get-zoom-access-token': baseUrl + '/v1/zoom-service/get-zoom-auth-token',
      'create-google-meeting':
        baseUrl + '/v1/calendar-meeting-service/create-google-meeting',
      'update-meeting-info':
        baseUrl + '/v1/calendar-meeting-service/update-meeting',
      'razorpay-create-payment-link':
        baseUrl + '/v1/paylinks-service/razorpay/create-paylink',
      'cashfree-create-payment-link':
        baseUrl + '/v1/paylinks-service/cashfree/create-paylink',
      'stripe-create-payment-link':
        listingUrl + '/v1/stripe/paymentlinks/create-payment-link',
      'delete-lead-calendar-event':
        baseUrl + '/v3/appointment/calendar-event/delete',
      'wa-client-appointment-confirmation':
        listingUrl + '/v1/communication/client/appt-conf/whatsapp',
      'wa-client-appointment-no-show':
        listingUrl + '/v1/communication/client/appt-noshow/whatsapp',
      'wa-client-appointment-client-not-joined':
        listingUrl + '/v1/communication/client/client-not-joined/whatsapp',
      'wa-provider-appointment-confirmation':
        listingUrl + '/v1/communication/provider/appt-conf/whatsapp',
      'wa-client-reschedule-confirmation':
        listingUrl + '/v1/communication/client/appt-resc/whatsapp',
      'wa-provider-reschedule-confirmation':
        listingUrl + '/v1/communication/provider/appt-resc/whatsapp',
      'wa-share-link-template':
        listingUrl + '/v1/wa/template/share_meeting_link',
      'wa-share-link-provider-template':
        listingUrl + '/v1/wa/template/share_meeting_link_to_provider',
      'wa-client-feedback-template':
        listingUrl + '/v1/wa/template/client_feedback',
      'create-googlemeet-link':
        baseUrl + '/v1/meeting-link-service/create-googlemeet-link',
      'rescheduling-request-create':
        listingUrl + '/v1/rescheduling-request/create',
      'rescheduling-request-modify':
        listingUrl + '/v1/rescheduling-request/modify',
      'rescheduling-request-cancel':
        listingUrl + '/v1/rescheduling-request/cancel',
      'rescheduling-request-update':
        listingUrl + '/v1/rescheduling-request/update',
      'rescheduling-request-proceed-rescheduling':
        listingUrl + '/v1/rescheduling-request/proceed-for-crm',
      'google-ads-conversion-entry':
        listingUrl + '/v1/google-ads/conversion/create',
      'google-ads-gclid-client-mapping':
        listingUrl + '/v1/google-ads/conversion/gclid-to-client-mapping',

      'facebook-ads-conversion-entry':
        listingUrl + '/v1/facebook-ads/conversion/create',
      'facebook-ads-single-conversion-entry':
        listingUrl + '/v1/facebook-ads/conversion/report-single-conversion',
      'facebook-ads-fbclid-client-mapping':
        listingUrl + '/v1/facebook-ads/conversion/fbclid-to-client-mapping',

      'appointment-invite-create': listingUrl + '/v1/appointment-invite/create',
      'appointment-invite-update-json':
        listingUrl + '/v1/appointment-invite/update-json',
      'appointment-invite-modify': listingUrl + '/v1/appointment-invite/modify',
      'appointment-invite-cancel': listingUrl + '/v1/appointment-invite/cancel',
      'cashgram-refund-create':
        listingUrl + '/v1/cashgram_refunds/create_paylink',
      'cashgram-refund-status': listingUrl + '/v1/cashgram_refunds/status',
      'cashgram-refund-deactivate':
        listingUrl + '/v1/cashgram_refunds/deactivate',
      'paylinks-service-update-json':
        baseUrl + '/v1/paylinks-service/update-json',
      'razorpay-booking-link-share-wa':
        listingUrl + '/v1/wa/template/share-rzp-payment-link',
      'cashfree-booking-link-share-wa':
        listingUrl + '/v1/wa/template/share-cf-payment-link',
      'stripe-booking-link-share-wa':
        listingUrl + '/v1/wa/template/share-stripe-payment-link',
      'med-records-upload': listingUrl + '/v1/client-med-records/upload',
      'med-records-update': listingUrl + '/v1/client-med-records/update',
      'med-records-delete': listingUrl + '/v1/client-med-records/delete',
      'generate-ledger-entry-for-lead':
        listingUrl + '/v1/ledger/new-appointment',
      'generate-ledger-entry-for-lead-payout':
        listingUrl + '/v1/ledger/appointment/payout-entry',
      'provider-payout-config-50':
        listingUrl + '/v1/payouts/provider-payout-config-50',
      'custom-appointment-credit-entry':
        listingUrl + '/v1/ledger/new-custom-appointment',
      'send-wa-text-message': listingUrl + '/v1/wa/send-text-message',
      'get-wingman-info': listingUrl + '/v1/wingman/get-info',
      'upload-media-to-chat': listingUrl + '/v1/chat/upload-media',
      'send-wa-hello-template-message':
        listingUrl + '/v1/wa/send-hello-template-message',
      'chat-mark-as-read': listingUrl + '/v1/chat/operations/mark-as-read',
      'chat-mark-as-unread': listingUrl + '/v1/chat/operations/mark-as-unread',
      'chat-typing': listingUrl + '/v1/chat/typing',
      'assign-to-brain': listingUrl + '/v1/wa/reassign-to-brain',
      'assign-to-team': listingUrl + '/v1/wa/reassign-to-team',
      'get-client-wallet-balance': listingUrl + '/v1/ledger/client/balance',
      'get-client-wallet': listingUrl + '/v1/ledger/client/wallet-balance',
      'get-partner-wallet': listingUrl + '/v1/ledger/partner/wallet-balance',
      'add-client-wallet-balance':
        listingUrl + '/v1/ledger/client/credit-purchase',
      'mark-as-international-client':
        listingUrl + '/v1/patient/international-client',
      'old-ledger-generation':
        listingUrl + '/v1/ledger/fix/old-ledger-generation',
      'feedback-it-was-great': listingUrl + '/v1/wa/feedback-great',
      'rzp-get-order-info': listingUrl + '/v1/razorpay/fetch-order-details',
      'ledger-fix-credit-entry': listingUrl + '/v1/ledger/fix/bulk-credit',
      'wallet-to-wallet-transfer':
        listingUrl + '/v1/ledger/client/w-to-w-transfer',
      'ledger-fix-wallet-balance-appointment-updation':
        listingUrl + '/v1/ledger/fix/wallet-balance-appointment-updation',
      'ledger-fix-partial-wallet-balance-appointment-updation':
        listingUrl +
        '/v1/ledger/fix/partial-wallet-balance-appointment-updation',
      'refund-to-wallet-from-appointment-entry':
        listingUrl + '/v1/ledger/refund-to-wallet-appointment-entry',
      'actual-refund-for-lead':
        listingUrl + '/v1/ledger/money-out/refund-for-lead',
      'lead-add-note':listingUrl + '/v1/lead/operations/add-note',
      'lead-update-note':listingUrl + '/v1/lead/operations/update-note',
      'lead-delete-note':listingUrl + '/v1/lead/operations/delete-note',
      'partner-wallet-to-appointment-wallet':listingUrl + '/v1/ledger/partner/partner-wallet-to-appointment-wallet',
      'appointment-wallet-to-partner-wallet':listingUrl + '/v1/ledger/partner/appointment-wallet-to-partner-wallet',
      'client-retarget-message': listingUrl + '/v1/wa/template/client-retarget-template',
      'doctor-search-by-number': listingUrl+ '/v1/crm-search/provider/search-with-number',
      'update-ledger-entries-path': listingUrl + '/v1/ledger/update-ledger-path',
      'generate-membership-plan': listingUrl + '/v1/membership-plans/generate-with-multiplier',
      'appointment-wallet-to-plan-wallet': listingUrl + '/v1/membership-plans/ledger/appointment-wallet-to-plan-wallet',
      'client-refund-communications': listingUrl + '/v1/communication/client/appt-canc/refund',
      'provider-ledger-manual-transfer-entry': listingUrl + '/v1/ledger/provider/manual-payout-entry',
      'provider-profile-deletion': crmBaseUrl + '/v1/provider/delete',
      'bulk-message-for-provider-payouts': listingUrl + '/v1/wa/template/bulk/payout_message_sending',
      'manual-payout-entry-bulk': listingUrl + '/v1/ledger/provider/manual-payout-entry-bulk',
    };
  }
}
