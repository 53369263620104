import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DoctorListingProfile } from 'src/app/shared/models-ts/Actors/Doctor/DoctorListingProfile';
import { AuthUserInfoService } from 'src/app/shared/services/auth-user-info/auth-user-info.service';
import { DoctorsService } from 'src/app/shared/services/doctors/doctors.service';

@Component({
  selector: 'app-verify-approve',
  templateUrl: './verify-approve.component.html',
  styleUrls: ['./verify-approve.component.css']
})
export class VerifyApproveComponent implements OnInit {

  _profileData: DoctorListingProfile;
  @Input() set profileData(val: DoctorListingProfile) {
    this._profileData = val;
    this.populate();
  }

  get profileData() {
    return this._profileData;
  }

  currentHeroInfo;
  isVerified: boolean;
  isApproved: boolean;
  isSearchable: boolean;
  constructor(
    private _toastr: ToastrService, 
    private _doctorService: DoctorsService,
    private _authUserInfoService: AuthUserInfoService,) { }

  ngOnInit(): void {
    this.currentHeroInfo = this._authUserInfoService.getUserInfo();
  }
   
  populate() {
    if (this.profileData.isProfileVerifiedByDocVita) {
      this.isVerified = this.profileData.isProfileVerifiedByDocVita;
    } else {
      this.isVerified = false;
    }
    if (this.profileData.showInListing) {
      this.isApproved = this.profileData.showInListing;
    } else {
      this.isApproved = false;
    }
    if (this.profileData.showInSearch) {
      this.isSearchable = this.profileData.showInSearch;
    } else {
      this.isSearchable = false;
    }
  }

  searchableDoctor(isSearchable: boolean) {
    this._doctorService
      .searchableDoctorProfile(this.profileData.id, isSearchable)
      .then((res) => {
        this.updateUserStats(
          this.currentHeroInfo.docvitaId,
          this.profileData.id,
          'searchable'
        );
        this._toastr.success(isSearchable? 'Profile is searchable on docvita.com': 'Profile is not searchable on docvita.com');
      })
      .catch((err) => {
        console.log(err);
        this._toastr.error('Failed to approve. Try again!');
      });
  }
  approveDoctor() {
    this._doctorService
      .approveDoctorProfile(this.profileData.id)
      .then((res) => {
        this.updateUserStats(
          this.currentHeroInfo.docvitaId,
          this.profileData.id,
          'approve'
        );
        this._toastr.success('Profile Approved!');
      })
      .catch((err) => {
        console.log(err);
        this._toastr.error('Failed to approve. Try again!');
      });
  }
  disapproveDoctor() {
    this._toastr.info('disapproving profile...');
    this._doctorService
      .disapproveDoctorProfile(this.profileData.id)
      .then((res) => {
        this.isApproved = false;
        this.updateUserStats(
          this.currentHeroInfo.docvitaId,
          this.profileData.id,
          'disapprove'
        );
        this._toastr.success('Profile disapproved!');
      })
      .catch((err) => {
        console.log(err);
        this._toastr.error('Failed to approve. Try again!');
      });
  }

  verifyDoctor() {
    this._doctorService
      .verifyDoctorProfile(this.profileData.id)
      .then((res) => {
        this.updateUserStats(
          this.currentHeroInfo.docvitaId,
          this.profileData.id,
          'verify'
        );
        this._toastr.success('Profile Verified!');
      })
      .catch((err) => {
        console.log(err);
        this._toastr.error('Failed to verify. Try again!');
      });
  }

  updateUserStats(heroId, docId, reqType) {
    this._doctorService
      .updateDocvitaUserStats(heroId, docId, reqType)
      .subscribe((data) => {
        if (data['statusCode'] == 200) {
          console.log('User stats updated!');
        } else {
          console.log('User stats did not update!');
        }
      });
  }


}
