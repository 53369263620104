import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';
import { DoctorListingProfile } from '../../models-ts/Actors/Doctor/DoctorListingProfile';
import { User } from '../../models-ts/Actors/User/User';
import { AuthUserInfoService } from '../../services/auth-user-info/auth-user-info.service';
import { DoctorDataService } from '../../services/doctor-data/doctor-data.service';
import { DoctorsService } from '../../services/doctors/doctors.service';
import { CRMUser } from '../../models-ts/Actors/User/CRMUser';
import { crmBaseUrl, listingUrl } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-link-bank',
  templateUrl: './link-bank.component.html',
  styleUrls: ['./link-bank.component.css'],
})
export class LinkBankComponent implements OnInit {
  beneficiary: any = {
    name: '',
    email: '',
    phone: '',
    bankAccount: '',
    bankName: '',
    ifsc: '',
    vpa: '',
    cardNo: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    pincode: '',
  };
  accountHolderName: string;
  bankName: string;
  accountNumber: string;
  ifscCode: string;
  currentHeroInfo: CRMUser = new CRMUser();
  doctorProfile: DoctorListingProfile = new DoctorListingProfile();
  linkedBankDetails: any[] = [];
  closeResult = '';
  bulkPayoutBool: boolean = false;
  payoutScheduleTextField: string;
  saveLoader: boolean = false;

  payoutPlanOptions: string[] = [];
  payoutPlanSelectionIndia: string = "P_First25_Recurring50";
  payoutPlanSelectionOverseas: string = "P_First25_Recurring50";
  isSavingPayoutConfig: boolean = false;
  constructor(
    private _toastrService: ToastrService,
    private _doctorDataService: DoctorDataService,
    private _authUserInfoService: AuthUserInfoService,
    private _doctorService: DoctorsService,
    private modalService: BsModalService,
    private _http: HttpClient
  ) {}

  ngOnInit(): void {
    this.currentHeroInfo = this._authUserInfoService.getUserInfo();
    this._doctorDataService.currentSelection.subscribe((data) => {
      if (data != null && data['memberSelected'] != null) {
        this.doctorProfile = data['memberSelected'];
        if (this.doctorProfile.bulkPayoutsOnly != undefined) {
          this.bulkPayoutBool = this.doctorProfile.bulkPayoutsOnly;
        }
        if (this.doctorProfile.payoutScheduleComments != undefined) {
          this.payoutScheduleTextField =
            this.doctorProfile.payoutScheduleComments;
        }
        this.loadDefaultsForTheForm();
        this.fetchBankDetails();
        this.fetchPayoutPlanConfigOptions();
      }
    });
  }

  async downloadReport() {
    if(this.currentHeroInfo.hasSpecialAccessWithTeam) {
      const resp = await this._http.get(listingUrl+"/v1/provider-metrics/new-report?providerId="+this.doctorProfile.id,
      {
        headers: {
          "content-type": "text/html; charset=utf-8",
          "Accept": "text/html; charset=utf-8"
        },
        responseType: "text"
      }).toPromise();
      // console.log(resp);
      const blob = new Blob([resp+""], { type: "text/plain" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${this.doctorProfile.id}.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
      // window.open(listingUrl+"/v1/provider-metrics/new-report?providerId="+this.doctorProfile.id,"_blank")
    } else {
      this._toastrService.error("Don't have access");
    }
  }

  validForm(): boolean {
    this.beneficiary.phone += '';
    if (
      this.beneficiary.name.length > 0 &&
      this.beneficiary.email.length > 0 &&
      this.beneficiary.phone.length > 0 &&
      this.beneficiary.address1.length > 0
    ) {
      if (
        this.beneficiary.vpa.length == 0 &&
        (this.beneficiary.bankName.length == 0 ||
          this.beneficiary.bankAccount.length == 0 ||
          this.beneficiary.ifsc.length == 0)
      ) {
        this._toastrService.error(
          'Please add valid vpa or bank account details'
        );
        return false;
      } else {
        this.beneficiary['profileId'] = this.doctorProfile.id;
        return true;
      }
    } else {
      return false;
    }
  }

  loadDefaultsForTheForm() {
    if (this.doctorProfile) {
      if (this.doctorProfile.emails != null) {
        this.doctorProfile.emails.forEach((val) => {
          this.beneficiary['email'] = val['email'];
        });
      }
      this.doctorProfile.getPrimaryContactNumbers().forEach((val) => {
        this.beneficiary['phone'] = val.countryCode + val.number;
      });
      if (this.doctorProfile.city) {
        this.beneficiary['city'] = this.doctorProfile.city;
        this.beneficiary['address1'] = this.doctorProfile.city;
      }
    }
  }

  providerPayoutAs50ForFirstSession() {
    if(this.doctorProfile) {
      this._toastrService.info("Updating!");
      this._doctorService.providerPayoutAs50ForFirstSession(this.doctorProfile.id).subscribe((resp)=>{
        this._toastrService.success("Successfully updated")
      },(err)=>{
        console.error(err);
        this._toastrService.error(err);
      })  
    }
  }

  addBeneficiaryDetails(): void {
    console.log(this.beneficiary);
    if (this.validForm()) {
      console.log('entered');
      this._doctorService.addBeneficiary(this.beneficiary).subscribe((resp) => {
        if (resp['subCode'] == '200' && resp['status'] == 'SUCCESS') {
          this.fetchBankDetails();
          this._toastrService.success('Bank Details Updated Successfully');
          this.closeModal();
        } else {
          this._toastrService.warning('Something went wrong!!');
        }
      });
    }
  }

  fetchBankDetails(): void {
    this.linkedBankDetails = [];
    firebase
      .firestore()
      .collection('linked_bank_details')
      .where('profileId', '==', this.doctorProfile.id)
      .get()
      .then((data) => {
        if (data.size > 0) {
          data.forEach((d) => {
            // console.log(d.data())
            this.linkedBankDetails.push(d.data());
          });
        }
      });
  }

  removeAccount(beneId: string): void {
    var result = confirm('Are you sure you want to delete?');
    if (result) {
      this._doctorService.removeBeneficiary(beneId).subscribe((resp) => {
        if (resp['subCode'] == '200' && resp['status'] == 'SUCCESS') {
          this.fetchBankDetails();
          this._toastrService.success('Bank Details Removed Successfully');
        } else {
          this._toastrService.warning('Something went wrong!!');
        }
      });
    }
  }

  updateRazorpayAccountID(bank: any) {
    if (bank.razorpayAccountId && bank.razorpayAccountId.length > 0) {
      this._toastrService.info('Updating');
      firebase
        .firestore()
        .collection('linked_bank_details')
        .doc(bank.beneficiaryId)
        .update({
          razorpayAccountId: bank.razorpayAccountId,
        })
        .then(() => {
          this._toastrService.success('Updated successfully');
        })
        .catch((err) => {
          console.error(err);
          this._toastrService.error('Please try again: ' + err);
        });
    }
  }

  openModal(content) {
    this.modalService.show(content);
  }

  toggleBulkPayoutBool() {
    this.bulkPayoutBool = !this.bulkPayoutBool;
  }

  updateProfilePayoutDetails() {
    this.saveLoader = true;
    this._doctorService
      .updateDoctorProfilePayoutDetails(
        this.bulkPayoutBool,
        this.payoutScheduleTextField,
        this.doctorProfile.id
      )
      .then((res) => {
        if (res) {
          this._toastrService.success('Updated profile payout details!');
        } else {
          this._toastrService.error('Failed to update profile payout details.');
        }
        this.saveLoader = false;
      })
      .catch((err) => {
        this.saveLoader = false;
        this._toastrService.error('Failed to update profile payout details.');
      });
  }
  closeModal() {
    this.modalService.hide();
  }

  async fetchPayoutPlanConfigOptions() {
    const url = `${crmBaseUrl}/v1/payout/plan/options?providerId=${this.doctorProfile.id}`;
    try {
      const resp = await this._http.get(url).toPromise(); 
      if(resp && resp["statusCode"] == 200 && resp["body"] && resp["body"]["data"]) {
        if(resp["body"]["payoutPlanSelectionIndia"]) this.payoutPlanSelectionIndia = resp["body"]["payoutPlanSelectionIndia"];
        if(resp["body"]["payoutPlanSelectionOverseas"]) this.payoutPlanSelectionOverseas = resp["body"]["payoutPlanSelectionOverseas"];
        this.payoutPlanOptions = resp["body"]["data"];
      }
    } catch (error) {
      console.error(error);
    }
  }

  async savePayoutPlanSelection() {
    if(this.payoutPlanSelectionOverseas == null || this.payoutPlanSelectionIndia == null) {
      this._toastrService.error('Please select a payout plan');
      return;
    }
    const url = `${crmBaseUrl}/v1/payout/provider/plan-config/update`;
    const body = {
      payoutPlanKeyIndia: this.payoutPlanSelectionIndia,
      payoutPlanKeyOverseas: this.payoutPlanSelectionOverseas,
      providerId: this.doctorProfile.id,
    };
    try {
      this.isSavingPayoutConfig = true;
      const resp = await this._http.post(url,body).toPromise(); 
      if(resp && resp["statusCode"] == 200) {
        this._toastrService.success('Updated payout plan selection!');
      }
      this.isSavingPayoutConfig = false;
    } catch (error) {
      console.error(error);
      this._toastrService.error('Failed to update payout plan selection.');
      this.isSavingPayoutConfig = false;
    }
  }
}
