import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DoctorListingProfile } from '../../models-ts/Actors/Doctor/DoctorListingProfile';
import { CRMUser } from '../../models-ts/Actors/User/CRMUser';
import { User } from '../../models-ts/Actors/User/User';
import { Lead } from '../../models-ts/Entities/Lead';
import { CountrywiseFeeCalc, Currency } from '../../models-ts/Helpers/countrywiseFeeCalc';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
import { ToastrService } from 'ngx-toastr';
import { PaylinkType } from '../../models-ts/Entities/PaylinkDetails';
import { PaymentLinksService } from '../../services/payment-links/payment-links.service';
import { CopyClipboardService } from '../../services/copy-clipboard/copy-clipboard.service';
import { LedgerService } from 'src/app/ledger/services/ledger.service';
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault('Asia/Kolkata');

@Component({
  selector: 'appointment-addon-credits',
  templateUrl: './appointment-addon-credits.component.html',
  styleUrls: ['./appointment-addon-credits.component.scss']
})
export class AppointmentAddonCreditsComponent implements OnInit {
  modalRef: BsModalRef
  paymentSource = 'razorpay';
  paymentLinkForm;
  loader:boolean = false;
  walletLoader:boolean = false;

  @Input() lead: Lead = new Lead()
  @Input() provider: DoctorListingProfile = new DoctorListingProfile();
  @Input() patient: User = new User();
  @Input() crmHero: CRMUser = new CRMUser();
  @Input() titleOverride:string;
  paymentLink:string;
  wallet:Map<Currency,number> = new Map<Currency,number>();
  partnerWallet:Map<Currency,number> = new Map<Currency,number>();
  showWalletPopup:boolean = false;
  payViaWallet:boolean = false;
  constructor(
    private modalService: BsModalService,
    private toastrService: ToastrService,
    private _paymentLinksService:PaymentLinksService,
    private _clipboardService:CopyClipboardService,
    private ledgerService:LedgerService
  ) { }

  ngOnInit(): void {
  }

  @ViewChild('addonPopup') addonPopup;
  openModal() {
    this.initPaymentLinkForm();
    this.modalRef = this.modalService.show(this.addonPopup)
  }
  closeModal() {
    this.modalRef.hide();
  }
  initPaymentLinkForm() {
    this.payViaWallet = false;
    this.showWalletPopup = false;
    this.paymentLink = null;
    this.paymentSource = 'razorpay'
    this.paymentLinkForm = {
      amount: null,
      currency: Currency.INR,
      description: this.payLinkAddonDescriptionBody(),
      customerName: '',
      customerEmail: '',
      customerContact: {
        countryCode: '',
        number: '',
      },
    };
  }

  togglePaymentSource(source: string) {
    if (source) {
      this.paymentSource = source;
      if (source != 'stripe') {
        this.paymentLinkForm['currency'] = Currency.INR;
      }
    }
  }

  setCurrency(val: Currency) {
    this.paymentLinkForm['currency'] = val;
  }
  get currencySymbol() {
    return new CountrywiseFeeCalc().currencyToSymbol(this.paymentLinkForm['currency']);
  }

  get apptDateTime() {
    if (this.lead && this.lead.consultationInfo && this.lead.consultationInfo.date) {
      const date = dayjs(this.lead.consultationInfo.date + ' ' + this.lead.consultationInfo.timeslot.split('-')[0]).format("DD MMM YYYY, hh:mma")
      return date;
    }
    return '-';
  }

  get patientName() {
    if (this.lead && this.lead.consultationInfo) {
      return this.lead.consultationInfo.patientName;
    }
    return '-';
  }

  get providerName() {
    if (this.lead && this.lead.consultationInfo) {
      return this.lead.consultationInfo.doctorName;
    }
    return '-';
  }

  payLinkAddonDescriptionBody() {
    /*
    Additional charges for session with Dr. Aditya - 13 Dec, 10:00 am IST
     */
    let desc = '';
    if (this.lead && this.lead.consultationInfo && this.lead.consultationInfo.date && this.lead.consultationInfo.timeslot) {
      let date = this.lead.consultationInfo.date;
      let time = this.lead.consultationInfo.timeslot.split('-')[0];
      desc = `Additional charges for session with ${this.lead.consultationInfo.doctorName
        } - ${dayjs(date + ' ' + time).format('DD MMM, hh:mm a')} IST`;
    }
    return desc;
  }

  paymentLinkFormValidation() {
    if (
      !this.paymentLinkForm ||
      !this.paymentLinkForm['amount'] ||
      !this.paymentLinkForm['customerName'] ||
      this.paymentLinkForm['customerName'].length < 1 ||
      !this.paymentLinkForm['customerContact'] ||
      !this.paymentLinkForm['customerContact']['countryCode'] ||
      this.paymentLinkForm['customerContact']['countryCode'].length < 1 ||
      isNaN(this.paymentLinkForm['customerContact']['countryCode']) ||
      !this.paymentLinkForm['customerContact']['number'] ||
      this.paymentLinkForm['customerContact']['number'].length < 1 ||
      isNaN(this.paymentLinkForm['customerContact']['number']) ||
      ((this.paymentSource == 'stripe' || this.paymentSource == 'cashfree') &&
        (this.paymentLinkForm['description'] == '' ||
          this.paymentLinkForm['description'] == null))
    ) {
      return false;
    } else {
      return true;
    }
  }

  checkAndCreatePaylink(){
    this.showWalletPopup = false;
    if (this.paymentLinkForm['amount'] != null && this.paymentLinkForm['currency'] && this.provider && this.provider.id && this.patient && this.patient.docvitaId && this.lead && this.lead.id && this.lead.consultationInfo) {
      //wallet check
      if(this.wallet.size>0&&this.wallet.get(this.paymentLinkForm['currency'])!=null&&this.paymentLinkForm['amount']<=this.wallet.get(this.paymentLinkForm['currency'])){
        this.showWalletPopup = true;
      }else{
        this.createPaymentLink();
      }
    } else {
      this.loader = false;
      this.toastrService.error("Missing details");
    }
  }

  createPaymentLink() {
    this.loader = true;
    if (this.paymentLinkForm['amount'] != null && this.paymentLinkForm['currency'] && this.provider && this.provider.id && this.patient && this.patient.docvitaId && this.lead && this.lead.id && this.lead.consultationInfo) {      
      this.paymentLinkForm['customerName'] = this.lead.consultationInfo.patientName;
      this.setDefaultCustomerDetails();
      if(this.paymentLinkFormValidation){
        let body = {};
        body['amount'] = +this.paymentLinkForm['amount'];
        body['description'] = this.paymentLinkForm['description']
          ? this.paymentLinkForm['description']
          : '';
        body['customer'] = {
          name: this.paymentLinkForm['customerName'],
          contact: this.paymentLinkForm['customerContact'],
        };

        body['leadId'] = this.lead.id;
        body['appointmentId'] = this.lead.consultationInfo.appointmentId;
        body['providerId'] = this.provider.id
        body['patientId'] = this.patient.docvitaId;

        if (this.crmHero && this.crmHero.docvitaId) {
          body['createdById'] = this.crmHero.docvitaId;
          body['createdByName'] = this.crmHero.name;
        }

        if (
          this.paymentLinkForm['customerEmail'] &&
          this.paymentLinkForm['customerEmail'].length > 1
        ) {
          if (this.validateEmail(this.paymentLinkForm['customerEmail'])) {
            body['customer']['email'] =
              this.paymentLinkForm['customerEmail'].trim();
          } else {
            this.toastrService.warning('Customer email is not valid!');
            this.loader = false;
            return;
          }
        }

        if (this.paymentSource && this.paymentSource == 'stripe') {
          body['currency'] = this.paymentLinkForm['currency'];
        }

        body['type'] = PaylinkType.ADDON;
        this.continueCreatePaymentLink(body)
      }else{
        this.loader = false;
        this.toastrService.error("Form invalid!");  
      }
    } else {
      this.loader = false;
      this.toastrService.error("Missing details");
    }
  }

  async continueCreatePaymentLink(body:any){
    try {
      body = JSON.parse(JSON.stringify(body));
      const payLinkRes = await this._paymentLinksService.createPaymentLink(body, this.paymentSource)
      if(
        payLinkRes &&
          payLinkRes['body'] &&
          payLinkRes['body']['short_url'] &&
          payLinkRes['body']['id']
      ){
        this.loader = false;
        this.showWalletPopup = false;
        this.toastrService.success(
          'Payment link created! ' + this.paymentSource
        );
        this.paymentLink = payLinkRes['body']['short_url'];
      }else{
        this.loader = false;
        this.toastrService.error('Failed to create payment link');  
      }
    } catch (error) {
      this.loader = false;
      this.toastrService.error('Failed to create payment link');
    }
  }

  setDefaultCustomerDetails(){
    if (
      this.patient.contactNumbers &&
      this.patient.getPrimaryContactNumbers() &&
      this.patient.getPrimaryContactNumbers().length > 0
    ) {
      let contact = this.patient.getPrimaryContactNumbers()[0];
      this.paymentLinkForm['customerContact'] = {
        number: contact.number,
        countryCode: contact.countryCode,
      };
    } else if (
      this.patient.primaryContactNumber &&
      this.patient.primaryContactNumber.number
    ) {
      let contact = this.patient.primaryContactNumber;
      this.paymentLinkForm['customerContact'] = {
        number: contact.number,
        countryCode: contact.countryCode,
      };
    }

    if (
      this.patient &&
      this.patient.emails &&
      this.patient.getPrimaryEmail() &&
      this.patient.getPrimaryEmail().email
    ) {
      this.paymentLinkForm['customerEmail'] =
        this.patient.getPrimaryEmail().email;
    } else if (
      this.patient &&
      this.patient.emails &&
      this.patient.getEmails() &&
      this.patient.getEmails().length > 0
    ) {
      this.paymentLinkForm['customerEmail'] =
        this.patient.getEmails()[0].email;
    } else if (this.patient.primaryEmail) {
      this.paymentLinkForm['customerEmail'] =
        this.patient.primaryEmail;
    }
  }

  validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  copyPaymentLink() {
    this._clipboardService.copyToClipoard(
      this.paymentLink ? this.paymentLink : ''
    );
  }

  getWalletBalance(wallet:Map<Currency,number>){
    if(wallet.size>0){
      this.wallet = wallet;
    }else{
      this.wallet = new Map<Currency,number>();
    }
  }

  getPartnerWalletBalance(wallet:Map<Currency,number>){
    if(wallet.size>0){
      this.partnerWallet = wallet;
    }else{
      this.partnerWallet = new Map<Currency,number>();
    }
  }

  get amountWithCurrency(){
    return this.currencySymbol+""+this.paymentLinkForm['amount']
  }

  resetWalletPopup(){
    this.showWalletPopup = false;
  }

  async debitViaWallet(){
    this.walletLoader = true;
    try {
      if (this.paymentLinkForm['amount'] != null && this.paymentLinkForm['currency'] && this.provider && this.provider.id && this.patient && this.patient.docvitaId && this.lead && this.lead.id && this.lead.consultationInfo) {
        if(this.enablePayviaWallet){
          await this.ledgerService.partialAppointmentWithWalletCredit(this.lead.id,+this.paymentLinkForm['amount'],this.paymentLinkForm['currency'],new Date().getTime())
          this.toastrService.success('Transfer Successful!');
          this.walletLoader = false;
          this.closeModal();
        }else{
          this.walletLoader = false;
          this.toastrService.error('Insufficient Funds!');
          return;
        }
      }else{
        this.walletLoader = false;
        this.toastrService.error('Missing details!');
      }
    } catch (error) {
      this.walletLoader = false;
      this.toastrService.error('Failed action');
    }
  }

  async debitViaPartnerWallet(){
    this.walletLoader = true;
    try {
      if (this.paymentLinkForm['amount'] != null && this.paymentLinkForm['currency'] && this.provider && this.provider.id && this.patient && this.patient.docvitaId && this.lead && this.lead.id && this.lead.consultationInfo) {
        if(this.enablePayviaPartnerWallet){
          // this.lead.id,+this.paymentLinkForm['amount'],this.paymentLinkForm['currency']
          await this.ledgerService.partnerWalletToAppointmentWallet(this.lead.id,this.lead.consultationInfo.partnerId,this.paymentLinkForm['amount'],this.paymentLinkForm['currency'])
          this.toastrService.success('Transfer Successful!');
          this.walletLoader = false;
          this.closeModal();
        }else{
          this.walletLoader = false;
          this.toastrService.error('Insufficient Funds!');
          return;
        }
      }else{
        this.walletLoader = false;
        this.toastrService.error('Missing details!');
      }
    } catch (error) {
      this.walletLoader = false;
      this.toastrService.error('Failed action');
    }
  }

  get enablePayviaWallet(){
    if(this.paymentLinkForm['amount']!=null&&this.paymentLinkForm['amount']>0&&this.wallet.size>0&&this.wallet.get(this.paymentLinkForm['currency'])!=null&&this.paymentLinkForm['amount']<=this.wallet.get(this.paymentLinkForm['currency'])){
      return true;
    }else{
      return false
    }
  }
  get enablePayviaPartnerWallet(){
    if(this.paymentLinkForm['amount']!=null&&this.lead.consultationInfo&&this.lead.consultationInfo.partnerId&&this.paymentLinkForm['amount']>0&&this.partnerWallet.size>0&&this.partnerWallet.get(this.paymentLinkForm['currency'])!=null&&this.paymentLinkForm['amount']<=this.partnerWallet.get(this.paymentLinkForm['currency'])){
      return true;
    }else{
      return false
    }
  }

  get payViaPartnerWallet(){
    if(this.lead&&this.lead.id&&this.lead.consultationInfo&&this.lead.consultationInfo.partnerId!=null){
      return true;
    }else{
      return false;
    }
  }

}
