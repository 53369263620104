import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DoctorListingProfile } from '../../models-ts/Actors/Doctor/DoctorListingProfile';
import { User } from '../../models-ts/Actors/User/User';
import { Appointment } from '../../models-ts/Entities/Appointment';
import { AppointmentService } from '../../services/appointment/appointment.service';
import { CopyClipboardService } from '../../services/copy-clipboard/copy-clipboard.service';

@Component({
  selector: 'patient-search-v2',
  templateUrl: './patient-search-v2.component.html',
  styleUrls: ['./patient-search-v2.component.scss'],
})
export class PatientSearchV2Component implements OnInit {
  modalRef: BsModalRef;
  modalRef2: BsModalRef;
  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent): void {
    if (event.ctrlKey && event.shiftKey && event.key === 'S') {
      this.openSearchModal();
      event.preventDefault();
    }
  }
  constructor(
    private modalService: BsModalService,
    private _appointmentService: AppointmentService,
    private _copyToClipboardService: CopyClipboardService
  ) { }
  modalPatientProfile: User = new User();
  modalProviderProfile: DoctorListingProfile = new DoctorListingProfile();
  isLoadingAppointments: boolean = false;
  allAppointments: Appointment[] = [];
  @ViewChild('patientSearch') patientSearch;
  @ViewChild('patientInfo') patientInfo;
  @Input() showButton: boolean = true;
  @Input() searchTerm: string;
  ngOnInit(): void { }

  openSearchModal() {
    this.closeModal();
    this.modalPatientProfile = new User();
    this.modalProviderProfile = new DoctorListingProfile();
    this.modalRef = this.modalService.show(this.patientSearch, {
      class: 'modal-lg',
    });
  }

  setPatientFromSearch(event) {
    this.modalPatientProfile = new User();
    if (event && event['docvitaId']) {
      this.modalPatientProfile = event;
      this.getAppointments(this.modalPatientProfile.docvitaId);
      this.modalRef2 = this.modalService.show(this.patientInfo, {
        class: 'modal-lg',
      });
    }
  }

  closeModal() {
    this.modalRef?.hide();
  }

  closeModal2() {
    this.modalRef2.hide();
  }

  getAppointments(userId: string) {
    this.allAppointments = [];
    this.isLoadingAppointments = true;
    this._appointmentService
      .getAppointmentsForPatient(userId)
      .subscribe((res) => {
        this.allAppointments = [];
        this.isLoadingAppointments = false;
        if (res != null && res['statusCode'] == 200) {
          // console.log('getAppointments', res['body']);
          let upcomingAppointments = res['body']['upcomingAppointments'];
          let pastAppointments = res['body']['pastAppointments'];

          if (upcomingAppointments) {
            for (let a of upcomingAppointments) {
              if (a.date && a.timeslot) {
                this.allAppointments.push(plainToClass(Appointment, a));
              }
            }
          }
          if (pastAppointments) {
            for (let a of pastAppointments) {
              if (a.date && a.timeslot) {
                this.allAppointments.push(plainToClass(Appointment, a));
              }
            }
          }
          this.allAppointments.sort((a, b) => {
            return b.date.localeCompare(a.date);
          });
          // console.log(this.allAppointments)
        } else {
        }
      });
  }
  patientContactInfo(patientProfile: User) {
    if (patientProfile && patientProfile.primaryContactNumber) {
      let contact = patientProfile.primaryContactNumber;
      if (contact.number && contact.number.length > 0) {
        return contact;
      } else {
        return null;
      }
    } else if (patientProfile && patientProfile.contactNumbers.size > 0) {
      let primary = patientProfile.getPrimaryContactNumbers()[0];
      return primary;
    } else {
      return null;
    }
  }

  copy(message: string) {
    if (message) {
      this._copyToClipboardService.copyToClipoard(message);
    }
  }
  openAppointmentInNewTab(appointment: Appointment) {
    let _ =
      window.location.origin +
      '/patient-bookings/ld_' +
      appointment.appointmentId +
      '?from=' +
      appointment.date +
      '&to=' +
      appointment.date +
      '&status=BOOKED&sortBy=bookingDate&qk=docvitaId&qv=' +
      appointment.doctorId;
    window.open(_, '_blank');
  }
}
