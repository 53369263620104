import { Component, Input, OnInit } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { MembershipDetailsService } from 'src/app/shared/services/membership-details/membership-details.service'
import { DoctorListingProfile } from '../../models-ts/Actors/Doctor/DoctorListingProfile';
import { MembershipPlanPurchased } from '../../models-ts/Entities/MembershipPlan/MembershipPlanPurchased';
import { DayjsHelper } from '../../models-ts/Helpers/dayjsHelper';
import { DoctorsService } from '../../services/doctors/doctors.service';
@Component({
  selector: 'patient-membership-details',
  templateUrl: './patient-membership-details.component.html',
  styleUrls: ['./patient-membership-details.component.scss']
})
export class PatientMembershipDetailsComponent implements OnInit {

  @Input() set patientId(val:string){
    this.getPatientMembershipDetails(val)
  }
  loading:boolean = false;

  plansPurchased:MembershipPlanPurchased[] = [];
  providerMap:any = {}

  constructor(private membershipDetailsService:MembershipDetailsService,private doctorService:DoctorsService) { }

  async getPatientMembershipDetails(patientId:string){
    try {
      this.loading = true;
      this.plansPurchased = []
      this.providerMap = {}
      const plans = await this.membershipDetailsService.patientMembershipPlans(patientId);
      if(plans&&plans.length>0){
        let providerPromises = []
        plans.forEach(plan=>{
          this.providerMap[plan.providerId] = ""
        })
        for(const i in this.providerMap){
          providerPromises.push(this.doctorService.getDocProfileById(i))
        }
        const profiles = await Promise.all(providerPromises);
        profiles.forEach(p=>{
          const provider = plainToClass(DoctorListingProfile,p.data())
          if(!this.providerMap[provider.id]){
            this.providerMap[provider.id] = provider.getFirstNameWithSalutation();
          }
        })
      }
      this.loading = false;
      // this.plansPurchased = plans.filter(p=>{return new DayjsHelper().todayYYYYMMDD() < (p?.planExpiryDateYYYYMMDD)});
      this.plansPurchased = plans;
    } catch (error) {
      this.loading = false;
      console.log(error)
    }
  }

  isPlanActive(plan:MembershipPlanPurchased){
    return new DayjsHelper().todayYYYYMMDD() < (plan?.planExpiryDateYYYYMMDD);
  }

  ngOnInit(): void {
  }

}
