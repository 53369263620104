import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { classToPlain, plainToClass } from 'class-transformer';
import { ToastrService } from 'ngx-toastr';
import { DoctorListingProfile } from '../../models-ts/Actors/Doctor/DoctorListingProfile';
import { Appointment } from '../../models-ts/Entities/Appointment';
import { DoctorsService } from '../../services/doctors/doctors.service';
import { AppointmentService } from 'src/app/shared/services/appointment/appointment.service';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault('Asia/Kolkata');
import { Lead } from '../../models-ts/Entities/Lead';
import { PatientService } from 'src/app/shared/services/patient/patient.service';
import { User } from '../../models-ts/Actors/User/User';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ContactNumber } from '../../models-ts/Actors/Person/ContactNumber';
import { Email } from '../../models-ts/Actors/Person/Email';
import { ScheduleHelper } from '../../models-ts/Actors/Doctor/ScheduleHelper';
import { OrganisationConnection } from '../../models-ts/Relationships/OrganisationConnection';
import { ActionDoneBy } from '../../models-ts/Protocols/ActionDoneBy';
import { AuthUserInfoService } from '../../services/auth-user-info/auth-user-info.service';
import { CRMUser } from '../../models-ts/Actors/User/CRMUser';
import { PaymentSourcesArray } from '../../models-ts/constants/payment-constants';
import { ClientWalletService } from 'src/app/services/client-wallet.service';
import { TransactionSource } from '../../models-ts/Enums/pay';
import firebase from 'firebase';
import 'firebase/database';
@Component({
  selector: 'app-book-appointment',
  templateUrl: './book-appointment.component.html',
  styleUrls: ['./book-appointment.component.css'],
  providers: [ClientWalletService],
})
export class BookAppointmentComponent implements OnInit {
  @Input() showButton: boolean = true;
  @Input() doctorId: string;
  @Input() patientId: string;
  @Input() leadDetails: Lead = new Lead();
  @Input() patient: User = new User();
  @Input() bookingDate: string;
  @Input() bookingTime: string;
  currentHeroInfo: CRMUser = new CRMUser();
  doctorName: string;
  doctorProfile: DoctorListingProfile = new DoctorListingProfile();
  patientProfile: User = new User();
  currentAppointment: Appointment = new Appointment();
  doctorProfiles: DoctorListingProfile[];
  appointmentsList: Appointment[];
  noAppointments: boolean = false;
  noSlots: boolean = false;
  currentSlots: any[] = [];
  // blockedSlots:any[]=[];
  selectedSlot: any;
  selectedDate: string;
  selectedTime: string;
  selectedSlotLength: string;
  selectedPurpose: string;
  selectedAmount: number;
  payInfoform: UntypedFormGroup;
  slotLoader: boolean = false;
  apptLoader: boolean = false;
  bookingLoader: boolean = false;
  alreadyPaid: boolean = false;
  payViaWallet: boolean = false;
  bookingSteps: number = 1;
  newPatientForm;
  textForPatientSearch;
  PaymentSourceArray: string[] = PaymentSourcesArray;
  amtfetching: boolean = false;
  quickConnections: OrganisationConnection[] = [];
  walletBalance: number = 0;
  isWalletBalanceLoading: boolean = false;
  constructor(
    private modalService: BsModalService,
    private _toastrService: ToastrService,
    private _doctorService: DoctorsService,
    private _patientService: PatientService,
    private _appointmentService: AppointmentService,
    private _authUserInfoService: AuthUserInfoService,
    private _clientWalletService: ClientWalletService
  ) {}

  ngOnInit(): void {
    this.currentHeroInfo = this._authUserInfoService.getUserInfo();
    this.selectedDate = this.bookingDate
      ? this.bookingDate
      : dayjs(new Date(), 'Asia/Kolkata').format('YYYY-MM-DD');
  }
  @ViewChild('appointment') appModal;
  openModal() {
    this.bookingLoader = false;
    this.alreadyPaid = false;
    this.payViaWallet = false;
    this.bookingSteps = 1;
    this.appointmentsList = [];
    this.currentSlots = [];
    // this.blockedSlots=[];
    this.doctorProfile = new DoctorListingProfile();
    this.patientProfile = new User();
    this.selectedDate = this.bookingDate
      ? this.bookingDate
      : dayjs(new Date(), 'Asia/Kolkata').format('YYYY-MM-DD');
    // this.newPatientForm = new FormGroup({
    //   name: new FormControl('',[Validators.required,Validators.minLength(4)]),
    //   email: this.formBuilder.array([ this.createEmail() ]),
    //   countryCode: new FormControl('',[Validators.required]),
    //   number: this.formBuilder.array([ this.createContact() ]),
    //   gender: new FormControl()
    // })
    this.newPatientForm = {
      name: '',
      gender: '',
      contacts: [
        {
          countryCode: '',
          number: '',
          isPrimaryContactNumber: true,
          isWhatsappContactNumber: false,
        },
      ],
      emails: [{ email: '', isPrimary: true }],
    };

    this.setPaymentInfoForm();
    this.setBookingForm();
    if (this.patient && this.patient.docvitaId) {
      this.patientProfile = this.patient;
      this.getOrganizaitonNameForQuickContact();
      this.setStep(3);
    }
    if (this.leadDetails && this.leadDetails.docvitaId) {
      this.doctorProfile.id = this.leadDetails.docvitaId;
      this.doctorProfile.name = this.leadDetails.doctorName;
      // this.getProviderInfo();
      this.setPatientForm();
    }
    this.getOrganizaitonNameForQuickContact();
    this.modalService.show(this.appModal, {
      class: 'modal-lg',
      backdrop: 'static',
    });
  }

  addNewEmail(): void {
    this.newPatientForm['emails'].push({ email: '', isPrimary: false });
  }

  addNewContact(): void {
    this.newPatientForm['contacts'].push({
      countryCode: '',
      number: '',
      isPrimaryContactNumber: false,
      isWhatsappContactNumber: false,
    });
  }
  removeEmail(index) {
    if (
      this.newPatientForm['emails'] &&
      this.newPatientForm['emails'].length > 1
    ) {
      this.newPatientForm['emails'].splice(index, 1);
    } else {
      this.newPatientForm['emails'][index] = { email: '', isPrimary: true };
    }
  }
  removeContact(index) {
    if (
      this.newPatientForm['contacts'] &&
      this.newPatientForm['contacts'].length > 1
    ) {
      this.newPatientForm['contacts'].splice(index, 1);
    } else {
      this.newPatientForm['contacts'][index] = {
        countryCode: '',
        number: '',
        isPrimaryContactNumber: true,
        isWhatsappContactNumber: false,
      };
    }
  }

  getBalance(clientId: string) {
    this.isWalletBalanceLoading = true;
    this._clientWalletService
      .getClientBalance(clientId)
      .then((balance) => {
        this.walletBalance = balance;
        this.isWalletBalanceLoading = false;
      })
      .catch((err) => {
        console.log(err);
        this.walletBalance = 0;
        this.isWalletBalanceLoading = false;
      });
  }

  get disablePayViaWallet() {
    return (
      this.selectedAmount == null || this.walletBalance < this.selectedAmount
    );
  }

  setPatientForm() {
    let _patientName =
      this.leadDetails && this.leadDetails.patientName
        ? this.leadDetails.patientName
        : '';
    let _countryCode =
      this.leadDetails &&
      this.leadDetails.patientContactInfo &&
      this.leadDetails.patientContactInfo.countryCode
        ? this.leadDetails.patientContactInfo.countryCode
        : '';
    let _number =
      this.leadDetails &&
      this.leadDetails.patientContactInfo &&
      this.leadDetails.patientContactInfo.number
        ? this.leadDetails.patientContactInfo.number
        : '';
    this.textForPatientSearch =
      _number?.length > 0
        ? _number
        : _patientName?.length > 0
        ? _patientName
        : undefined;
    // this.newPatientForm = new FormGroup({
    //   name: new FormControl(_patientName,[Validators.required,Validators.minLength(4)]),
    //   email: new FormControl(''),
    //   countryCode: new FormControl(_countryCode,[Validators.required]),
    //   mobileNumber: new FormControl(_number,[Validators.required,Validators.minLength(5),Validators.maxLength(12)]),
    //   gender: new FormControl()
    // })
    this.newPatientForm = {
      name: _patientName,
      gender: '',
      contacts: [
        {
          countryCode: _countryCode,
          number: _number,
          isPrimaryContactNumber: true,
          isWhatsappContactNumber: false,
        },
      ],
      emails: [{ email: '', isPrimary: true }],
    };
  }

  setPaymentInfoForm() {
    this.alreadyPaid = false;
    this.payViaWallet = false;
    let date = dayjs(new Date(), 'Asia/Kolkata').format('YYYY-MM-DD');
    let time = dayjs(new Date(), 'Asia/Kolkata').format('HH:mm');
    this.payInfoform = new UntypedFormGroup({
      paymentId: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(4),
      ]),
      paymentSource: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(1),
      ]),
      paymentDoneDate: new UntypedFormControl(date, [
        Validators.required,
        Validators.minLength(4),
      ]),
      paymentDoneTime: new UntypedFormControl(time, [
        Validators.required,
        Validators.minLength(4),
      ]),
      // totalAmountPaid: new FormControl('',[Validators.required,Validators.minLength(1)])
    });
  }

  paymentSourceChanged(source: string) {
    this.payInfoform.patchValue({
      paymentSource: source,
    });
  }

  get selectedPaymentSource(): string {
    return this.payInfoform.get('paymentSource').value;
  }

  setBookingForm() {
    this.selectedSlot = null;
    this.selectedTime = this.bookingTime ? this.bookingTime : '';
    this.selectedSlotLength = '';
    this.selectedAmount = null;
  }

  setStep(stepNumber: number) {
    this.bookingSteps = stepNumber;
    if (stepNumber == 2) {
      this.patientProfile = new User();
      this.setPatientForm();
    }
    if (stepNumber == 3) {
      this.setPaymentInfoForm();
    }
  }

  setProvider(event) {
    if (event) {
      this.doctorProfile = event;
      if (this.doctorProfile.onlineConsultationConfig) {
        this.selectedSlotLength = this.doctorProfile.appointmentDuration
          ? this.doctorProfile.appointmentDuration.toString()
          : null;
        // this.selectedAmount = this.doctorProfile.onlineConsultationConfig.fee
        //   ? this.doctorProfile.onlineConsultationConfig.fee
        //   : null;
        // this.payInfoform.get('totalAmountPaid').setValue(this.selectedAmount.toString()) ;
      }
    }
  }

  setPatient(event) {
    if (event) {
      this.patientProfile = event;
      this.getBalance(this.patientProfile.docvitaId);
      this.getOrganizaitonNameForQuickContact();
    }
  }

  manageListFetchState(event: string) {
    if (event == 'fetch_start') {
      this.amtfetching = true;
    } else if (event == 'fetch_end') {
      this.amtfetching = false;
    }
  }

  fetchLatestAppointmentAmount(list: Appointment[]) {
    // if (list && list.length > 0) {
    //   // let latestAppt = list[0];
    //   let _amt = null;
    //   for (let i = 0; i < list.length; i++) {
    //     if (list[i].amount != null && list[i].amount > 0) {
    //       _amt = list[i].amount;
    //       break;
    //     } else {
    //       continue;
    //     }
    //   }
    //   if (_amt) {
    //     this.selectedAmount = _amt;
    //   } else {
    //     this.setDoctorConfigAmt();
    //   }
    // } else {
    //   this.setDoctorConfigAmt();
    // }
  }

  // setDoctorConfigAmt() {
  //   if (
  //     this.doctorProfile &&
  //     this.doctorProfile.id &&
  //     this.doctorProfile.onlineConsultationConfig &&
  //     this.amtfetching == false
  //   ) {
  //     this.selectedAmount = this.doctorProfile.onlineConsultationConfig.fee
  //       ? this.doctorProfile.onlineConsultationConfig.fee
  //       : null;
  //   } else {
  //     this.selectedAmount = null;
  //   }
  // }

  selectSlot(slot) {
    this.selectedSlot = slot;
    this.selectedTime = this.selectedSlot['startTime'];
    // this.selectedSlotLength = this.selectedSlot["sLength"];
  }

  // mapBlockedSlots():any[]{
  //   if(this.doctorProfile?.blockedSlots){
  //     // let today = new Date().toISOString().split('T')[0]
  //     let date = this.selectedDate;
  //     let _ =  Object.values(this.doctorProfile.blockedSlots).filter(da=>da&&da['date']==date);
  //     _.sort((a, b) => (a['timeslot'].split('-')[0]).localeCompare(b['timeslot'].split('-')[0]));
  //     return _;
  //   }else{
  //     return [];
  //   }
  // }

  clinicMapper(practices) {
    console.log(practices);
    let _ = [];
    let pracs = Object.keys(practices);
    // pracs.forEach(id=>{
    //   _.push(this.doctorProfile.associatedPractices.get(id).name)
    // })
    return _.toString();
  }

  createPatient() {
    let _ = this.newPatientForm;
    let isValid = true;
    let newContactMap = {};
    let newEmailMap = {};

    //for back-compat
    let mobileNumber = '';
    let countryCode = '';
    let email = '';

    if (_['gender'] == null) _['gender'] = '';

    if (_['contacts']) {
      if (mobileNumber.length < 1 && _['contacts'].length == 1) {
        mobileNumber = _['contacts'][0]['number'].toString();
        countryCode = _['contacts'][0]['countryCode'].toString();
      }
      for (let i of _['contacts']) {
        if (
          i['countryCode'].toString().length > 0 &&
          i['number'].toString().length > 0
        ) {
          let nc = new ContactNumber();
          nc.countryCode = i['countryCode'].toString();
          nc.number = i['number'].toString();
          nc.isPrimaryContactNumber = i['isPrimaryContactNumber'];
          nc.isWhatsappContactNumber = i['isWhatsappContactNumber'];
          if (newContactMap[i['countryCode']]) {
            newContactMap[i['countryCode']][i['number']] = this.jsonParser(
              classToPlain(nc)
            );
          } else {
            newContactMap[i['countryCode']] = {};
            newContactMap[i['countryCode']][i['number']] = this.jsonParser(
              classToPlain(nc)
            );
          }
          if (i['isPrimaryContactNumber'] && mobileNumber.length < 1) {
            mobileNumber = i['number'].toString();
            countryCode = i['countryCode'].toString();
          }
        } else {
          isValid = false;
          break;
        }
      }
    }
    if (_['emails']) {
      if (email.length < 1 && _['emails'].length == 1) {
        email = _['emails'][0]['email'];
      }
      for (let i of _['emails']) {
        if (i['email'].length > 0) {
          let ne = new Email();
          ne.email = i['email'];
          ne.isPrimary = i['isPrimary'];
          newEmailMap[i['email']] = this.jsonParser(classToPlain(ne));
          if (i['isPrimary'] && email.length < 1) {
            email = i['email'];
          }
        }
      }
    }
    if (isValid) {
      // this.bookingLoader=true;
      // this._patientService.checkIfNumberExists(_['mobileNumber']).then(res=>{
      //   if(res.empty){

      //   }else{
      //     this._toastrService.warning('Number Already exists!');
      //     this.bookingLoader=false;
      //   }
      // }).catch(err=>{
      //   console.log(err)
      //   this.bookingLoader=false;
      // })
      let body = {
        name: _['name'],
        gender: _['gender'],
        contactNumbers: this.jsonParser(classToPlain(newContactMap)),
        emails: this.jsonParser(classToPlain(newEmailMap)),
        countryCode: countryCode,
        mobileNumber: mobileNumber,
        email: email,
      };
      this.bookingLoader = true;
      // console.log(body)
      this.createPatientFinal(body);
    } else {
      this._toastrService.warning('Invalid details');
    }
  }

  getOrganizaitonNameForQuickContact() {
    this.quickConnections = [];
    let map = this.patientProfile?.organisationConnections;
    if (map) {
      map.forEach((val, key) => {
        this._doctorService
          .getProviderProfile(key)
          .then((profile) => {
            this.patientProfile.organisationConnections.get(
              key
            ).organisationName = profile.fullName();
            this.patientProfile.organisationConnections.get(
              key
            ).organisationId = key;
          })
          .catch((err) => {});
      });
      this.patientProfile.organisationConnections.forEach((val) => {
        if (val) {
          this.quickConnections.push(val);
        }
      });
      this.quickConnections.sort((a, b) => {
        if (a.organisationId && b.organisationId) {
          return a.organisationId.localeCompare(b.organisationId);
        } else {
          return 0;
        }
      });
    }
  }

  jsonParser(data) {
    return JSON.parse(JSON.stringify(data));
  }

  createPatientFinal(body) {
    this._patientService
      .createPatientProfile(body)
      .then((res) => {
        if (res && res['body'] && res['body']['docvitaId']) {
          this._patientService
            .getPatientById(res['body']['docvitaId'])
            .then((snap) => {
              if (snap.exists) {
                this.bookingLoader = false;
                this.patientProfile = plainToClass(User, snap.data());
                this._toastrService.success('Patient Created!');
                this.setStep(3);
              } else {
                this.bookingLoader = false;
                this._toastrService.error('Patient Failed to create!');
              }
            })
            .catch((err) => {
              this.bookingLoader = false;
              console.log(err);
              this._toastrService.error('Patient Failed to create!');
            });
        }
      })
      .catch((err) => {
        this.bookingLoader = false;
        console.log(err);
        this._toastrService.error('Patient Failed to create!');
      });
  }

  validatePaymentForm(): boolean {
    let _ = this.payInfoform.value;
    if (this.payInfoform.valid) {
      return true;
    } else {
      return false;
    }
  }
  @ViewChild('apptClashModal') apptClashModal;
  bookAppointment() {
    // console.log(
    //   new Date(
    //     this.payInfoform.value['paymentDoneDate'] +
    //       ' ' +
    //       this.payInfoform.value['paymentDoneTime']
    //   )
    // );
    if (
      this.doctorProfile &&
      this.doctorProfile.id &&
      this.selectedSlotLength &&
      this.selectedTime &&
      this.selectedDate
    ) {
      let innerValidation;
      this.bookingLoader = true;
      this.currentAppointment = new Appointment();
      let _timeslot =
      this.selectedTime +
        '-' +
        dayjs.tz(+dayjs.tz(new Date(this.selectedDate + ' ' + this.selectedTime)).valueOf()+(+this.selectedSlotLength*60*1000))
          .format('HH:mm');
      this.currentAppointment.timeslot = _timeslot;
      this.currentAppointment.doctorId = this.doctorProfile.id;
      this.currentAppointment.organisationId = this.doctorProfile.id;
      this.currentAppointment.doctorName = this.doctorProfile.fullName();
      this.currentAppointment.organisationName = this.doctorProfile.fullName();
      this.currentAppointment.date = this.selectedDate;
      if (this.selectedAmount != null)
        this.currentAppointment.amount = this.selectedAmount;
      this.currentAppointment.purposeOfVisitTitle = 'Online Consultation';
      this.currentAppointment.purposeOfVisitDescription =
        'Chat and Video (Valid for 72 hrs)';
      this.currentAppointment.modeOfAppointment = 'docvita';
      this.currentAppointment.initiator = new ActionDoneBy();
      this.currentAppointment.initiator.updatedById =
        this.currentHeroInfo.docvitaId;
      this.currentAppointment.initiator.updatedByName =
        this.currentHeroInfo.name;
      this.currentAppointment.initiator.updatedOn = new Date().getTime();
      if (this.payViaWallet) {
        this.currentAppointment.paymentId = firebase
          .database()
          .ref()
          .push().key;
        this.currentAppointment.paymentSource = TransactionSource.DocVitaWallet;
        this.currentAppointment.paymentDoneOn = new Date().getTime();
        this.currentAppointment.totalAmountPaid = this.selectedAmount;
      } else if (this.alreadyPaid) {
        if (this.validatePaymentForm()) {
          console.log('payment validation', this.validatePaymentForm());
          innerValidation = true;
          this.currentAppointment.paymentId =
            this.payInfoform.value['paymentId'];
          this.currentAppointment.paymentSource =
            this.payInfoform.value['paymentSource'];
          this.currentAppointment.paymentDoneOn = new Date(
            this.payInfoform.value['paymentDoneDate'] +
              ' ' +
              this.payInfoform.value['paymentDoneTime']
          ).getTime();
          this.currentAppointment.totalAmountPaid = this.selectedAmount;
        } else {
          console.log('payment validation', this.validatePaymentForm());
          this.bookingLoader = false;
          innerValidation = false;
          this._toastrService.warning('Invalid Payment info!');
        }
      } else {
        innerValidation = true;
      }
      let body = classToPlain(this.currentAppointment);
      body['paymentIntent'] = 'payNow';
      if (this.payViaWallet) body['payViaWallet'] = this.payViaWallet;
      body['platform'] = 'whatsapp';
      body['type'] = 'online';

      let patientInfo = {};

      if (
        this.patientProfile &&
        this.patientProfile.docvitaId &&
        this.patientProfile.name &&
        this.patientProfile.primaryContactNumber &&
        this.patientProfile.primaryContactNumber.countryCode &&
        this.patientProfile.primaryContactNumber.number
      ) {
        patientInfo = {
          patientId: this.patientProfile.docvitaId,
          patientName: this.patientProfile.name,
          patientGender: this.patientProfile.gender
            ? this.patientProfile.gender
            : '',
        };

        if (
          this.patientProfile.contactNumbers &&
          this.patientProfile.contactNumbers.size > 0
        ) {
          let contactNumber = this.patientProfile.getPrimaryContactNumbers()[0];
          if (contactNumber.number && contactNumber.number.length > 0) {
            patientInfo['patientPrimaryContactNumber'] = {
              countryCode: contactNumber.countryCode,
              number: contactNumber.number,
            };
          }
        } else if (
          this.patientProfile.primaryContactNumber &&
          this.patientProfile.primaryContactNumber.number?.length > 0
        ) {
          patientInfo['patientPrimaryContactNumber'] = {
            countryCode: this.patientProfile.primaryContactNumber.countryCode,
            number: this.patientProfile.primaryContactNumber.number,
          };
        }

        if (
          this.patientProfile.emails &&
          this.patientProfile.emails.size > 0 &&
          this.patientProfile.getPrimaryEmail()
        ) {
          patientInfo['patientPrimaryEmail'] =
            this.patientProfile.getPrimaryEmail().email;
        } else {
          patientInfo['patientPrimaryEmail'] = this.patientProfile.primaryEmail
            ? this.patientProfile.primaryEmail
            : '';
        }

        body['patientsArray'] = [patientInfo];
        // console.log(body);
        this.apptClashModal
          .openConflictPromise(
            body['doctorId'],
            body['date'],
            body['timeslot'],
            body['doctorName']
          )
          .then((hasConflicts) => {
            if (hasConflicts) {
              this.bookingLoader = false;
              this.apptClashModal.openModal();
            } else {
              this.continueBookingAppointment(body);
            }
          })
          .catch((err) => {
            this.continueBookingAppointment(body);
          });

        // if (innerValidation == true) {
        //   // console.log(JSON.parse(JSON.stringify(body)));
        // }
      } else {
        this.bookingLoader = false;
        this._toastrService.warning('Incomplete patient info!');
      }

      // console.log(JSON.parse(JSON.stringify(body)));
    } else {
      this._toastrService.warning('Incomplete booking info!');
    }
  }

  continueBookingAppointment(body) {
    this._appointmentService
      .bookAppointment(body)
      .then((res) => {
        if (res) {
          console.log(res);
          this.bookingLoader = false;
          this._toastrService.success('🎉Appointment booked!');
          this.modalService.hide();
        }
      })
      .catch((err) => {
        console.log(err);
        this._toastrService.error('⚠ Booking failed!');
        this.bookingLoader = false;
      });
  }

  setSlotLength(num) {
    this.selectedSlotLength = num;
  }

  displayCurrentDay() {
    return new Date(this.selectedDate).getTime();
  }

  timeToMin(hm: any) {
    let a = hm.split(':');
    let minutes = +a[0] * 60 + +a[1];
    return minutes;
  }

  minToTime(mins: any) {
    let m = mins % 60;
    let h = ((mins - m) / 60).toString();
    var HHMM =
      (h.length > 1 ? h : '0' + h) + ':' + (m < 10 ? '0' : '') + m.toString();
    return HHMM;
  }

  addtime(time, duration) {
    let times = time.split(':');
    //clear here more than 24 hours
    duration = duration % (24 * 60);
    times[0] = parseInt(times[0]) + +(duration / 60);
    times[1] = parseInt(times[1]) + (duration % 60);
    //here control if hour and minutes reach max
    if (times[1] >= 60) {
      times[1] = 0;
      times[0]++;
    }
    times[0] >= 24 ? (times[0] -= 24) : null;

    //here control if less than 10 then put 0 frond them
    times[0] < 10 ? (times[0] = '0' + times[0]) : null;
    times[1] < 10 ? (times[1] = '0' + times[1]) : null;

    return times.join(':');
  }

  appointmentDateScroller(direction) {
    if (direction == '+' && this.selectedDate) {
      this.selectedDate = dayjs(
        new Date(this.selectedDate),
        'Asia/Kolkata'
      )
        .add(1, 'day')
        .format('YYYY-MM-DD');
    } else if (direction == '-' && this.selectedDate) {
      this.selectedDate = dayjs(
        new Date(this.selectedDate),
        'Asia/Kolkata'
      )
        .subtract(1, 'day')
        .format('YYYY-MM-DD');
    } else {
      //cool
    }
  }

  closeModal() {
    this.modalService.hide();
  }
}
