<ng-template #bookingPaylinkModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" *ngIf="pageNumber == 1">
      <ng-container *ngIf="titleOverride">
        {{'('+titleOverride+') '}}
      </ng-container>
      {{ currentStepName | titlecase }}
    </h4>
    <h4 class="modal-title" *ngIf="pageNumber == 3">
      ✅ Task Completed: {{ currentStepName | titlecase }}
    </h4>
    <h4 class="modal-title" *ngIf="pageNumber == 4">💸 All Payment links</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()" style="outline: none">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body d-flex flex-column justify-content-center w-100">
    <!-- payment form -->
    <ng-container *ngIf="pageNumber == 1">
      <!-- <div class="d-flex w-100 mb-3">
                  <button class="btn btn-sm btn-primary ms-auto me-2" (click)="getAllPaymentLinks()">💸 View Payment
                      Links</button>
              </div> -->
      <div class="mt-2 fw-600">
        {{
        !patientProfile || !patientProfile.docvitaId
        ? "Select Client:"
        : "Client:"
        }}
      </div>
      <div class="d-flex align-items-center w-100">
        <app-patient-search [patientId]="patientId" (getPatient)="setPatient($event)" class="w-100">
        </app-patient-search>
        <button class="btn btn-sm btn-info ms-2" style="width: 10rem" (click)="newPatientReg.openModal()">
          + New Client
        </button>
      </div>
      <div *ngIf="patientProfile && patientProfile.docvitaId" class="mt-1 mb-3 text-muted d-flex align-items-center">
        {{
        "+" +
        paymentLinkForm["customerContact"].countryCode +
        "-" +
        paymentLinkForm["customerContact"].number
        }}
        |
        {{ paymentLinkForm["customerEmail"] }}
        |
        <span>
          💰Wallet->
        </span>
        <client-wallet class="ms-1" [patientId]="patientProfile.docvitaId"
          (walletVal)="getWalletBalance($event)"></client-wallet>
      </div>
      <ng-container *ngIf="patientProfile.docvitaId">
        <div class="my-1 fw-600" *ngIf="!formPurpose">
          What would you like to do?
        </div>
        <div class="w-100 d-flex align-items-center justify-content-center">
          <button class="w-100 btn btn-sm btn-outline-primary" [ngClass]="{
              'btn-primary text-white': formPurpose == 'accept-money'
            }" style="border-radius: 5px 0px 0px 5px; border-right: 0" (click)="setFormPurpose('accept-money')">
            Accept Money
          </button>
          <button class="w-100 btn btn-sm btn-outline-primary" [ngClass]="{
              'btn-primary text-white': formPurpose == 'booking-link'
            }" style="border-radius: 0px; border-right: 0" (click)="setFormPurpose('booking-link')">
            Booking Link
          </button>
          <button class="w-100 btn btn-sm btn-outline-primary" [ngClass]="{
              'btn-primary text-white': formPurpose == 'book-appointment'
            }" style="border-radius: 0px" (click)="setFormPurpose('book-appointment')">
            Book Appointment
          </button>

          <button class="w-100 btn btn-sm btn-outline-primary" [ngClass]="{
              'btn-primary text-white': formPurpose == 'free-followup'
            }" style="border-radius: 0px 5px 5px 0px; border-left: 0" (click)="setFormPurpose('free-followup')">
            Free Follow-up
          </button>
        </div>
        <div *ngIf="formPurpose" class="mt-3 text-muted fw-600">
          {{ formPurposeMap[formPurpose] }}
        </div>
      </ng-container>

      <div *ngIf="patientProfile.docvitaId && formPurpose" class="w-100 d-flex flex-column mt-2">
        <ng-container *ngIf="formPurpose == 'accept-money'">
          <ng-container *ngTemplateOutlet="acceptMoneyTemplate"></ng-container>
        </ng-container>
        <ng-container *ngIf="formPurpose == 'booking-link'">
          <ng-container *ngTemplateOutlet="bookingLinkTemplate"></ng-container>
        </ng-container>
        <ng-container *ngIf="formPurpose == 'book-appointment'">
          <ng-container *ngTemplateOutlet="bookDirectAppointment">
          </ng-container>
        </ng-container>
        <ng-container *ngIf="formPurpose == 'free-followup'">
          <ng-container *ngTemplateOutlet="freeFollowupTemplate">
          </ng-container>
        </ng-container>
      </div>
    </ng-container>

    <!-- copy payment link details -->
    <ng-container *ngIf="pageNumber == 3">
      <div class="w-100 d-flex flex-column align-items-center justify-content-center">
        <div class="border rounded d-flex flex-column p-2 w-75 align-items-center" style="font-size: 15px">
          <div>
            <span class="wt-500 me-2">Patient:</span>{{
            paymentLinkForm["customerName"]
            ? paymentLinkForm["customerName"]
            : ""
            }}
          </div>
          <div>
            <span class="wt-500 me-2">Contact:</span>{{
            paymentLinkForm["customerContact"] &&
            paymentLinkForm["customerContact"]["number"]
            ? "+" +
            paymentLinkForm["customerContact"]["countryCode"] +
            "-" +
            paymentLinkForm["customerContact"]["number"]
            : ""
            }}
          </div>
          <div class="d-flex" *ngIf="appointmentBookingForm['time']">
            <div class="wt-500 me-2" style="font-size: 13px">
              Booking Details:
            </div>
            <div class="d-flex flex-column ms-2" style="font-size: 13px">
              <div>
                <span class="wt-500 me-2">Doctor:</span>{{
                doctorProfile && doctorProfile.fullName()
                ? doctorProfile.fullName()
                : ""
                }}
              </div>
              <div>
                <span class="wt-500 me-2">Date|Time:</span>{{ getBookingDateTime }}
              </div>
            </div>
          </div>
        </div>
        <div class="input-group mt-3 mb-3 w-75">
          <input type="text" class="form-control" [(ngModel)]="paymentLink" placeholder="Payment Link"
            disabled="true" />
          <div class="input-group-append">
            <button class="btn btn-outline-info" type="button" (click)="copyPaymentLink()">
              <i style="font-size: 14px" class="material-icons me-1">content_copy</i>
              Copy
            </button>
          </div>
        </div>
        <!-- <div class="d-flex mt-3">
          <button
            class="btn btn-sm btn-outline-dark"
            (click)="newPaymentLink()"
          >
            <i style="font-size: 14px" class="material-icons me-1"
              >chevron_left</i
            >
            New payment link
          </button>
          <button
            class="btn btn-sm btn-outline-primary ms-2"
            (click)="viewAllPayments()"
          >
            View all payment links
            <i style="font-size: 14px" class="material-icons me-1"
              >chevron_right</i
            >
          </button>
        </div> -->
      </div>
    </ng-container>
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-dark me-2" (click)="closeModal()">
      Close
    </button>

    <button type="button" class="btn btn-outline-dark" [disabled]="btnLoader" (click)="createPaymentLink()" *ngIf="
        !payViaWallet &&
        !payViaPartnerWallet &&
        pageNumber == 1 &&
        formPurpose &&
        formPurpose != 'book-appointment' &&
        formPurpose != 'free-followup'
      ">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="btnLoader"></span>
      Create PayLink
    </button>

    <button type="button" class="btn btn-outline-dark" [disabled]="btnLoader"
      (click)="bookAppointmentWithWalletBalance()" *ngIf="payViaWallet && pageNumber == 1 && formPurpose">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="btnLoader"></span>
      Book Appointment Using Wallet Credits
    </button>

    <button type="button" class="btn btn-outline-dark" [disabled]="btnLoader"
      (click)="bookAppointmentWithPartnerWallet()" *ngIf="payViaPartnerWallet && pageNumber == 1 && formPurpose">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="btnLoader"></span>
      Book Appointment Using Partner Credits
    </button>

    <button type="button" class="btn btn-outline-dark" [disabled]="btnLoader"
      (click)="bookAppointmentWithWalletBalance()" *ngIf="
        !payViaWallet &&
        !payViaPartnerWallet &&
        pageNumber == 1 &&
        formPurpose &&
        formPurpose == 'book-appointment'&&
        !isPlanSelected
      ">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="btnLoader"></span>
      Book Appointment
    </button>

    <button type="button" class="btn btn-outline-dark" [disabled]="btnLoader"
      (click)="bookAppointmentWithSelectedPlan()" *ngIf="
        pageNumber == 1 &&
        formPurpose &&
        formPurpose == 'book-appointment'&&
        isPlanSelected
      ">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="btnLoader"></span>
      Book Plan Appointment
    </button>

    <button type="button" class="btn btn-outline-dark" [disabled]="btnLoader"
      (click)="bookAppointmentWithWalletBalance()"
      *ngIf="pageNumber == 1 && formPurpose && formPurpose == 'free-followup'">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="btnLoader"></span>
      Book Free Follow-up
    </button>
  </div>
</ng-template>

<ng-template #acceptMoneyTemplate>
  <ng-container *ngTemplateOutlet="paymentModeSelection"></ng-container>
  <div style="font-weight: 600" class="mt-3">Amount({{currencySymbol}}):</div>
  <div class="d-flex align-items-center w-100">
    <input type="number" placeholder="Amount" class="form-control w-100 me-1" [(ngModel)]="paymentLinkForm['amount']" />
    <ng-container *ngTemplateOutlet="currencySelector"></ng-container>
  </div>

  <ng-container *ngIf="!payViaWallet && !payViaPartnerWallet">
    <div style="font-weight: 600" class="mt-3">
      Payment Description:
      <span class="text-muted" style="font-size: 13px">{{
        paymentSource && (paymentSource == "cashfree"||paymentSource == "stripe") ? "(required)" : "(optional)"
        }}</span>
    </div>
    <textarea rows="2" placeholder="Payment Description" class="form-control"
      [(ngModel)]="paymentLinkForm['description']"></textarea>
  </ng-container>

</ng-template>
<ng-template #practicesTemplate>
  <div class="d-flex align-items-center my-2 gap-3" *ngIf="practiceOptions && practiceOptions?.length > 0">
    <div class="d-flex align-items-center">
      <input class="form-check-input me-2" type="checkbox" (change)="toggleInPersonBool($event)"
      [(ngModel)]="isInPerson">
      <label class="form-check-label" style="white-space: nowrap;">
        In-Person?
      </label>
    </div>
    <ng-container *ngIf="isInPerson">
      <select class="form-control" [(ngModel)]="practiceSelection.key">
        <option [value]="item.key" [selected]="item.key == practiceSelection?.key"  *ngFor="let item of practiceOptions">{{item.value}}</option>
      </select>
    </ng-container>
  </div>
</ng-template>
<ng-template #bookingLinkTemplate>
  <ng-container *ngTemplateOutlet="providerForm"></ng-container>
  <ng-container *ngIf="doctorProfile && doctorProfile.id && !payViaWallet && !payViaPartnerWallet">
    <div style="font-weight: 500" class="mt-3">
      Payment Description:
      <span class="text-muted" style="font-size: 13px">{{
        paymentSource && (paymentSource == "cashfree" || paymentSource == "stripe")
        ? "(required)"
        : "(optional)"
        }}</span>
    </div>
    <textarea rows="2" placeholder="Description (auto generated, if field empty.)" class="form-control"
      [(ngModel)]="paymentLinkForm['description']"></textarea>
  </ng-container>
</ng-template>
<ng-template #bookDirectAppointment>
  <ng-container *ngTemplateOutlet="providerForm"></ng-container>
  <ng-container *ngIf="
      !payViaWallet &&
      !payViaPartnerWallet &&
      !isPlanSelected &&
      doctorProfile &&
      doctorProfile.id &&
      paymentLinkForm['amount'] != 0
    ">
    <ng-container *ngTemplateOutlet="alreadyPaidEntries"></ng-container>
  </ng-container>
</ng-template>
<ng-template #freeFollowupTemplate>
  <ng-container *ngTemplateOutlet="providerForm"></ng-container>
</ng-template>
<ng-template #providerForm>
  <ng-container *ngTemplateOutlet="apptDetails"></ng-container>
  <ng-container *ngTemplateOutlet="providerSearchSection"></ng-container>
  <ng-container *ngIf="doctorProfile && doctorProfile.id">
    <ng-container *ngTemplateOutlet="practicesTemplate"></ng-container>
    <div *ngIf="
        doctorProfile &&
        doctorProfile.id &&
        doctorProfile.onlineConsultationConfig
      " class="standard-details-container">
      <span *ngIf="doctorProfile?.onlineConsultationConfig?.fee"><b>Std. fees:</b> {{ standardNatInNatPricing }}</span>
      <span *ngIf="doctorProfile?.onlineConsultationConfig"><b>Session Type-wise:</b> {{ getSessionTypeWiseFee }}</span>
      <span *ngIf="doctorProfile.appointmentDuration"><b>Std. duration:</b> {{ doctorProfile.appointmentDuration }}
        mins</span>
    </div>

    <ng-container *ngIf="formPurpose == 'book-appointment'">
      <div class="mt-3 fw-600">
        Choose active plan:
      </div>
      <patient-membership-booking-selection [patientId]="patientProfile.docvitaId" [providerId]="doctorProfile.id"
        (selectedPlan)="selectedPlan($event)"></patient-membership-booking-selection>
    </ng-container>

    <ng-container *ngIf="formPurpose == 'booking-link'">
      <!-- <div class="mt-3 fw-600">
        View active plans:
      </div> -->
      <patient-membership-booking-selection [viewMode]="true" [patientId]="patientProfile.docvitaId"
        [providerId]="doctorProfile.id"></patient-membership-booking-selection>
    </ng-container>

  
    <ng-container *ngIf="
        formPurpose != 'book-appointment' && formPurpose != 'free-followup'
      ">
      <div class="mt-3 w-100"></div>
      <ng-container *ngTemplateOutlet="paymentModeSelection"></ng-container>
    </ng-container>

    <ng-container >
      <div class="mt-2"> Session Type: </div>
      
      <select class="form-select" [disabled]="selectedMembershipPlan?.id" (change)="getFeeForSessionType()"
          [(ngModel)]="currentSessionType">
          <option [value]="item" *ngFor="let item of sessionTypeOptions">{{ item|titlecase }}</option>
      </select>

    </ng-container>

    <div class="d-flex mt-3">
      <div class="d-flex flex-column position-relative me-3">
        <div style="font-weight: 500">Amount({{currencySymbol}}):</div>
        <div class="d-flex align-items-center">
          <input type="number" placeholder="Amount" class="form-control me-1"
            [disabled]="formPurpose == 'free-followup'||isPlanSelected" style="width: 180px"
            [(ngModel)]="paymentLinkForm['amount']" />
          <ng-container *ngTemplateOutlet="currencySelector"></ng-container>
        </div>

        <!-- <div
          class="text-muted"
          style="font-size: 0.85em; position: absolute; top: 80px; left: 200px"
        >
          <ng-container *ngIf="amtfetching"> Fetching Amount... </ng-container>
        </div> -->
        <ng-container *ngIf="formPurpose != 'free-followup'&&!isPlanSelected">
          <div class="d-flex align-items-center mt-2">
            <span>
              Client's wallet->
            </span>
            <client-wallet class="ms-1" [patientId]="patientProfile.docvitaId"
              (walletVal)="getWalletBalance($event)"></client-wallet>
          </div>
          <!-- <div class="text-muted" style="font-size: 0.85em; top: 65px">
            <ng-container *ngIf="isWalletBalanceLoading">
              Fetching Wallet Balance...
            </ng-container>
          </div> -->
          <div class="d-flex align-items-center">
            <div class="form-check mb-2 mt-2" style="font-size: 14px">
              <input [disabled]="disablePayViaWallet||isPlanSelected" class="form-check-input" type="checkbox"
                id="payViaWallet" [(ngModel)]="payViaWallet" />
              <label class="form-check-label ms-1" for="payViaWallet">
                Pay Via Wallet?
              </label>
            </div>
            <div class="form-check mb-2 mt-2 ms-2" style="font-size: 14px" *ngIf="checkForPartnership">
              <input class="form-check-input" type="checkbox" id="payViaPartnerWallet"
                [(ngModel)]="payViaPartnerWallet" />
              <label class="form-check-label ms-1" for="payViaPartnerWallet">
                Pay Via Partner Wallet?
              </label>
            </div>
          </div>

        </ng-container>
      </div>
      <div class="ms-5">
        <div style="font-weight: 500">Duration:(Min)</div>
        <input type="number" class="form-control" [disabled]="isPlanSelected" style="width: 180px"
          [(ngModel)]="appointmentBookingForm['slotLength']" />
        <div class="d-flex mt-1" style="font-size: 14px">
          <span class="me-2 p-1 card slot mb-0" *ngFor="let min of [15, 30, 45]" (click)="setSlotLength(min)">{{ min }}
            min</span>
        </div>
      </div>
    </div>

    <div class="d-flex flex-column mt-3"
      *ngIf="paymentSource=='stripe'&&currentHeroInfo&&currentHeroInfo.hasSpecialAccess">
      <div style="font-weight: 500">Provider Display Amount ({{currencyProviderSymbol}}):</div>
      <div class="d-flex align-items-center">
        <input type="number" placeholder="Amount" class="form-control me-1" [disabled]="formPurpose == 'free-followup'"
          style="width: 180px" [(ngModel)]="providerDisplayAmount" />
        <ng-container *ngTemplateOutlet="providerCurrencySelector"></ng-container>
      </div>
    </div>

    <div class="d-flex mt-3">
      <div class="d-flex flex-column">
        <div class="d-flex" style="font-weight: 500; width: 180px" tooltip="Date of the appointment">
          <div>Date:</div>
          <div class="text-muted ms-auto">
            {{ DateInDay }}
          </div>
        </div>
        <div class="d-flex">
          <input type="date" class="form-control" style="width: 180px" [(ngModel)]="appointmentBookingForm['date']" />
          <!-- date scroller arrows -->
          <div class="d-flex ms-1">
            <button class="btn btn-outline-primary btn-sm border-0 ps-0 pe-0" (click)="appointmentDateScroller('-')">
              <i class="feather icon-chevron-left" style="font-size: 16px"></i>
            </button>
            <button class="btn btn-outline-primary btn-sm border-0 ps-0 pe-0" (click)="appointmentDateScroller('+')">
              <i class="feather icon-chevron-right" style="font-size: 16px"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="ms-4" *ngIf="!showAvailableTimeslots || appointmentBookingForm['time']">
        <div style="font-weight: 500">Time:</div>
        <input type="time" class="form-control" [disabled]="!allowTimeEdit" style="width: 180px"
          [(ngModel)]="appointmentBookingForm['time']" />
      </div>
    </div>

    <div class="mt-3 w-100">
      <ng-container *ngIf="showAvailableTimeslots && doctorProfile && doctorProfile.id">
        <provider-available-slots [practiceId]="practiceSelection?.key" [doctorProfile]="doctorProfile" [date]="appointmentBookingForm['date']"
          (selectedSlot)="selectSlot($event)" [disableContainer]="true" (allSlots)="handleProviderSlots($event)">
        </provider-available-slots>
        <button class="btn btn-outline-primary border-0 btn-sm w-100 mt-1" (click)="editTimeManually()">
          Choose another time
        </button>
      </ng-container>

      <button class="btn btn-outline-primary border-0 btn-sm w-100 mt-1"
        *ngIf="!showAvailableTimeslots && doctorProfile && doctorProfile.id" (click)="toggleProviderTimeslots(true)">
        Show available times
      </button>
    </div>
  </ng-container>
</ng-template>
<ng-template #paymentLinkFilterTemplate>
  <div dropdown class="btn-group d-inline-block mx-2">
    <button id="button-animated" dropdownToggle type="button" class="btn btn-outline-secondary dropdown-toggle"
      aria-controls="dropdown-animated">
      Show {{ selectedFilter }}
    </button>

    <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
      aria-labelledby="button-animated">
      <li role="menuitem" (click)="changeViewPaymentLinkFilter('all')">
        <a class="dropdown-item p-3"> All </a>
      </li>
      <li role="menuitem" (click)="changeViewPaymentLinkFilter('paid')">
        <a class="dropdown-item p-3"> Only Paid </a>
      </li>
      <li role="menuitem" (click)="changeViewPaymentLinkFilter('unpaid')">
        <a class="dropdown-item p-3"> Only Unpaid </a>
      </li>
    </ul>
  </div>
</ng-template>
<ng-template #paymentModeSelection>
  <div class="w-100 d-flex align-items-center justify-content-center" *ngIf="!payViaWallet&&!payViaPartnerWallet">
    <button class="w-100 btn btn-sm btn-outline-primary"
      [ngClass]="{ 'btn-primary text-white': paymentSource == 'cashfree' }"
      style="border-radius: 5px 0px 0px 5px; border-right: 0" (click)="togglePaymentSource('cashfree')">
      Cashfree
    </button>
    <button class="w-100 btn btn-sm btn-outline-primary"
      [ngClass]="{ 'btn-primary text-white': paymentSource == 'razorpay' }" style="border-radius: 0px; border-right: 0"
      (click)="togglePaymentSource('razorpay')">
      Razorpay
    </button>
    <button class="w-100 btn btn-sm btn-outline-primary"
      [ngClass]="{ 'btn-primary text-white': paymentSource == 'stripe' }" style="border-radius: 0px 5px 5px 0px"
      (click)="togglePaymentSource('stripe')">
      Stripe
    </button>
  </div>
</ng-template>
<ng-template #alreadyPaidEntries>
  <form [formGroup]="payInfoform">
    <div class="d-flex flex-column" style="width: 425px">
      <div class="mt-3 d-flex">
        <div style="width: 185px">
          <div style="font-weight: 500">Select Payment Source:</div>
          <div dropdown class="btn-group d-inline-block w-100">
            <button id="button-animated" dropdownToggle type="button" [disabled]="isPlanSelected"
              class="btn btn-outline-secondary dropdown-toggle form-control justify-content-start"
              aria-controls="dropdown-animated">
              {{ selectedPaymentSource || "Payment Source" }}
            </button>
            <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu w-100" role="menu"
              aria-labelledby="button-animated">
              <li role="menuitem" *ngFor="let source of PaymentSourceArray">
                <a class="dropdown-item" (click)="paymentSourceChanged(source)">
                  {{ source }}
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div *ngIf="
            payInfoform &&
            payInfoform.get('paymentSource') &&
            payInfoform.get('paymentSource').value
          " class="ms-auto" style="width: 185px">
          <div style="font-weight: 500 w-100">Payment Id:</div>
          <ng-container *ngIf="payInfoform.value['paymentSource'] != 'Razorpay'">
            <input type="text" class="form-control w-100" placeholder="Payment Id" formControlName="paymentId"
              required />
          </ng-container>

          <ng-container *ngIf="payInfoform.value['paymentSource'] == 'Razorpay'">
            <input type="text" class="form-control w-100" placeholder="Payment Id" formControlName="paymentId"
              (input)="setOrderIdValues($event)" required />
          </ng-container>
        </div>
      </div>
      <ng-container *ngIf="payInfoform.value['paymentSource'] == 'Razorpay'">
        <div class="spinner-border spinner-border-sm m-1" role="status" *ngIf="orderCheckLoader">
          <span class="sr-only">Loading...</span>
        </div>
        <div [ngClass]="{
            'text-success': orderIdMatched,
            'text-danger': !orderIdMatched
          }" class="m-1 d-flex flex-column" style="font-size: 13px" *ngIf="
            !orderCheckLoader &&
            orderDataDisplay &&
            orderDataDisplay != 'No matching order!'
          ">
          <div>
            {{ orderIdMatched ? "Amount Matched!" : "Amount Mismatch" }}
          </div>
          <div>
            {{ orderDataDisplay }}
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="
          payInfoform &&
          payInfoform.get('paymentSource') &&
          payInfoform.get('paymentSource').value
        ">
        <div class="mt-3" *ngIf="payInfoform.value['paymentSource'] != 'Razorpay'">
          <div style="font-weight: 500">Payment Done On:</div>
          <div class="d-flex align-items-center">
            <input type="date" class="form-control" placeholder="" style="width: 185px"
              formControlName="paymentDoneDate" required />
            <div class="d-flex ms-1">
              <button class="btn btn-outline-primary btn-sm border-0 ps-0 pe-0"
                (click)="appointmentPaymentDateScroller('-')">
                <i class="feather icon-chevron-left" style="font-size: 16px"></i>
              </button>
              <button class="btn btn-outline-primary btn-sm border-0 ps-0 pe-0"
                (click)="appointmentPaymentDateScroller('+')">
                <i class="feather icon-chevron-right" style="font-size: 16px"></i>
              </button>
            </div>
            <input type="time" class="form-control ms-auto" style="width: 185px" placeholder=""
              formControlName="paymentDoneTime" required />
          </div>
        </div>
      </ng-container>
    </div>
  </form>
</ng-template>
<ng-template #providerSearchSection>
  <div style="position: relative" class="mt-2 fw-600">
    {{
    !doctorProfile || !doctorProfile.id
    ? "Select Provider:"
    : "Provider:"
    }}
  </div>
  <ng-container *ngIf="!doctorProfile || !doctorProfile.id">
    <app-doctor-search [providerId]="doctorId" (getProvider)="setProvider($event)" #docSearchRef></app-doctor-search>
  </ng-container>
  <div class="doctor-connection-tile border py-1 px-2 w-100" *ngIf="doctorProfile && doctorProfile.id">
    <img [src]="doctorProfile.photoUrl" alt="doc-img" style="min-width: 40px; width: 40px; height: 40px"
      class="avatar avatar-circle avatar-image" />
    <div class="d-flex flex-column ms-3" style="font-size: 14px">
      <div>
        {{ doctorProfile.fullName() ? doctorProfile.fullName() : "-" }}
      </div>
      <div class="text-muted">
        {{
        doctorProfile.getMasterSpecializationDisplay()
        ? doctorProfile.getMasterSpecializationDisplay()
        : ""
        }}
      </div>
    </div>
    <div class="ms-auto pointer fw-600" style="font-size: 14px" (click)="clearProviderSelection()">
      &times;
    </div>
  </div>
  <ng-container *ngIf="
      patientProfile &&
      patientProfile.organisationConnections &&
      doctorProfile &&
      !doctorProfile.id
    ">
    <div class="mb-2" style="font-size: 14px" *ngIf="connectedProfiles && connectedProfiles.length > 0">
      <div style="font-weight: 500" class="mt-2 text-muted">
        Doctor Connections:
      </div>
      <div class="connections-list-container-horizontal">
        <div [ngClass]="{
            'slot-selected-2': doctorProfile.id == doc.id
          }" *ngFor="let doc of connectedProfiles" class="border m-1 py-1 px-2 pointer doctor-connection-tile"
          (click)="setProvider(doc)">
          <img [src]="doc.photoUrl" alt="doc-img" style="min-width: 35px; width: 35px; height: 35px"
            class="avatar avatar-circle avatar-image" />
          <div class="d-flex flex-column ms-2" style="font-size: 12px">
            <div>
              {{ doc.fullName() ? doc.fullName() : "-" }}
            </div>
            <div class="text-muted">
              {{
              doc.getMasterSpecializationDisplay()
              ? doc.getMasterSpecializationDisplay()
              : ""
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>
<ng-template #apptDetails>
  <div style="position: relative">
    <div class="doctor-appt-details">
      <div class="w-100 d-flex flex-column" class="doctor-appt-details-card" *ngIf="doctorProfile && doctorProfile.id">
        <provider-appointments [doctorProfile]="doctorProfile"
          [date]="appointmentBookingForm['date']"></provider-appointments>
      </div>

      <div class="w-100 d-flex flex-column" style="margin-top: 1rem" *ngIf="
          doctorProfile &&
          doctorProfile.id &&
          patientProfile &&
          patientProfile.docvitaId
        " class="doctor-appt-details-card">
        <provider-patient-appointments [doctorProfile]="doctorProfile" [patientProfile]="patientProfile"
          (appoimentListState)="manageListFetchState($event)">
        </provider-patient-appointments>
      </div>
    </div>
  </div>
</ng-template>
<clashing-appointments-modal #apptClashModal></clashing-appointments-modal>
<app-patient-registration [isNewPatient]="true" [showButton]="false" #newPatientReg></app-patient-registration>
<ng-template #currencySelector>
  <currency-dropdown [defaultValue]="this.selectedCurrency" (selectedCurrency)="setCurrency($event)"
    [isDisabled]="paymentSource != 'stripe'&&formPurpose!='book-appointment'||isPlanSelected"></currency-dropdown>
</ng-template>
<ng-template #providerCurrencySelector>
  <currency-dropdown [defaultValue]="this.providerDisplayCurrency" (selectedCurrency)="setProviderCurrency($event)"
    [isDisabled]="paymentSource != 'stripe'&&formPurpose!='book-appointment'"></currency-dropdown>
</ng-template>