import { Component, OnInit } from '@angular/core';
import {
  classToPlain,
  classToPlainFromExist,
  plainToClass,
  serialize,
} from 'class-transformer';
import firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';
import { DoctorListingProfile } from 'src/app/shared/models-ts/Actors/Doctor/DoctorListingProfile';
import { ContactNumber } from 'src/app/shared/models-ts/Actors/Person/ContactNumber';
import { CRMUser } from 'src/app/shared/models-ts/Actors/User/CRMUser';
import { User } from 'src/app/shared/models-ts/Actors/User/User';
import { AuthUserInfoService } from 'src/app/shared/services/auth-user-info/auth-user-info.service';
import { DoctorDataService } from 'src/app/shared/services/doctor-data/doctor-data.service';
import {
  AccountConnectionMeta,
  ProviderLogin,
} from '../../shared/models-ts/Actors/ProviderLogin/ProviderLogin';
@Component({
  selector: 'app-manage-login-credentials',
  templateUrl: './manage-login-credentials.component.html',
  styleUrls: ['./manage-login-credentials.component.css'],
})
export class ManageLoginCredentialsComponent implements OnInit {
  countryCode: number;
  mobileNumber: number;
  email: string;
  currentHeroInfo: CRMUser = new CRMUser();
  name: string;
  doctorProfile: DoctorListingProfile = new DoctorListingProfile();
  roles: string[] = ['Self', 'Staff', 'Manager', 'Owner'];
  type: string = 'Self';
  profiles: any[] = [];
  creatingCredentials:boolean = false;
  constructor(
    private _toastrService: ToastrService,
    private _doctorDataService: DoctorDataService,
    private _authUserInfoService: AuthUserInfoService
  ) {}

  ngOnInit(): void {
    this.currentHeroInfo = this._authUserInfoService.getUserInfo();
    this._doctorDataService.currentSelection.subscribe((data) => {
      if (data != null && data['memberSelected'] != null) {
        this.doctorProfile = data['memberSelected'];
        if (this.doctorProfile.name) {
          this.name = this.doctorProfile.fullName();
        }
        if (
          this.doctorProfile.primaryContactNumber &&
          this.doctorProfile.primaryContactNumber.number
        ) {
          this.mobileNumber = parseInt(
            this.doctorProfile.primaryContactNumber.number
          );
        }

        if (this.doctorProfile.email) {
          this.email = this.doctorProfile.email;
        }
      }
    });
    this.fetchData();
  }

  fetchData() {
    firebase
      .firestore()
      .collection('profiles_credentials')
      .where(
        `profiles.${this.doctorProfile.id}.profileId`,
        '==',
        this.doctorProfile.id
      )
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((element) => {
          this.profiles.push(element.data());
        });
      });
  }

  createLoginCredentials() {
    if (
      this.name != null &&
      this.name.length > 0 &&
      this.countryCode != null &&
      this.mobileNumber != null
    ) {
      this.creatingCredentials = true;
      let id = firebase.database().ref().push().key;
      let obj = new ProviderLogin();
      obj.docvitaId = id;
      obj.name = this.name;
      if (this.email) obj.primaryEmail = this.email;
      let c = new ContactNumber();
      c.number = this.mobileNumber.toString();
      c.countryCode = this.countryCode.toString();
      obj.primaryContactNumber = c;
      obj.type = this.type;
      obj.createdOn = new Date().getTime();
      let ref = new AccountConnectionMeta();
      ref.profileId = this.doctorProfile.id;
      ref.type = this.doctorProfile.type;
      ref.connectedById = this.currentHeroInfo.docvitaId;
      ref.connectedByName = this.currentHeroInfo.name;
      ref.connectedOn = new Date().getTime();
      obj.connectedAccounts.set(this.doctorProfile.id, ref);
      obj.profiles.set(this.doctorProfile.id, ref);
      let jsonVal = classToPlain(obj);
      
      firebase
        .firestore()
        .collection('profiles_credentials')
        .doc(id)
        .set(JSON.parse(JSON.stringify(jsonVal)))
        .then(() => {
          this.creatingCredentials = false;
          this._toastrService.success('Done');
        })
        .catch((err) => {
          console.log(err);
          this.creatingCredentials = false;
          this._toastrService.error('Error');
        });
    } else {
      this.creatingCredentials = false;
      this._toastrService.error('Incomplete form infomration');
    }
  }
}
