import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { DoctorListingProfile } from 'src/app/shared/models-ts/Actors/Doctor/DoctorListingProfile';
import { CRMUser } from 'src/app/shared/models-ts/Actors/User/CRMUser';
import { MembershipPlan } from 'src/app/shared/models-ts/Entities/MembershipPlan/MembershipPlan';
import { AuthUserInfoService } from 'src/app/shared/services/auth-user-info/auth-user-info.service';
import { DoctorDataService } from 'src/app/shared/services/doctor-data/doctor-data.service';
import { DocMembershipPlanGeneratorComponent } from './components/doc-membership-plan-generator/doc-membership-plan-generator.component';
import { DocMembershipPlanModifyComponent } from './components/doc-membership-plan-modify/doc-membership-plan-modify.component';
import { MembershipPlansService } from "./membership-plans.service";
@Component({
  selector: 'app-doc-membership-plans',
  templateUrl: './doc-membership-plans.component.html',
  styleUrls: ['./doc-membership-plans.component.scss'],
  providers: [MembershipPlansService]
})
export class DocMembershipPlansComponent implements OnInit, OnDestroy {
  profileData: DoctorListingProfile = new DoctorListingProfile();
  currentHeroInfo: CRMUser;
  dataLayerSubscription: Subscription;
  membershipPlans: MembershipPlan[] = [];
  @ViewChild("planmodifycanvas") c: DocMembershipPlanModifyComponent;
  @ViewChild("plangeneratorcanvas") cGenerator: DocMembershipPlanGeneratorComponent;
  constructor(
    private _membershipService: MembershipPlansService,
    private _authUserInfoService: AuthUserInfoService,
    private _doctorDataService: DoctorDataService,

  ) { }

  ngOnInit(): void {
    this.currentHeroInfo = this._authUserInfoService.getUserInfo();
    this.dataLayerSubscription =
      this._doctorDataService.currentSelection.subscribe(async (data) => {
        if (data != null && data['memberSelected'] != null) {
          this.profileData = data['memberSelected'];
          this.fetchPlans();
        }
      })
  }

  async fetchPlans() {
    this.membershipPlans = await this._membershipService.fetchPlans(this.profileData.id);
  }

  addPlan() {
    this.c.open(this.profileData);
  }

  
  editPlan(plan: MembershipPlan) {
    this.c.open(this.profileData, plan);
  }
  
  generate4WeekPlan() {
    this.cGenerator.open(this.profileData, 4, true);
  }

  generate9WeekPlan() {
    this.cGenerator.open(this.profileData, 9, false);
  }

  ngOnDestroy() {
    this.dataLayerSubscription.unsubscribe();
  }


}
