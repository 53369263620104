<div *ngIf="!listMode && !specificProvider">
  <ng-container *ngTemplateOutlet="patientSearchSelect"></ng-container>
</div>

<div *ngIf="listMode && !specificProvider" class="w-100">
  <ng-container *ngTemplateOutlet="patientSearchTextInput"></ng-container>
  <div class="mt-3"></div>
  <ng-container *ngTemplateOutlet="patientList"></ng-container>
</div>

<div *ngIf="specificProvider">
  <div class="w-100 d-flex align-items-center">
    <div class="w-50 me-2">
      <div class="text-primary mb-1">Client:</div>
      <ng-container *ngTemplateOutlet="patientSearchTextInput"></ng-container>
    </div>
    <div class="w-50">
      <div class="text-primary mb-1">Provider:</div>
      <app-doctor-search (getProvider)="setProviderFromSearch($event)">
      </app-doctor-search>
    </div>
  </div>
  <div class="mt-3"></div>
  <ng-container *ngTemplateOutlet="patientList2"></ng-container>
</div>

<ng-template #patientSearchSelect>
  <ng-select
    class="w-100"
    id="patient-search-select"
    placeholder="Client Search - Name, Id, Number, etc."
    [(ngModel)]="searchText"
    (change)="setPatient()"
    (input)="setSearch($event)"
  >
    <ng-option
      *ngFor="let patient of patientProfilesList"
      [value]="patient.docvitaId"
    >
      <span class="pii-text"
        >{{ patient.name }} •
        {{
          patient.primaryContactNumber
            ? "+" +
              patient.primaryContactNumber.countryCode +
              "-" +
              patient.primaryContactNumber.number
            : ""
        }}
      </span>
    </ng-option>
  </ng-select>
</ng-template>

<ng-template #patientList>
  <div class="w-100 mt-2 list">
    <h6
      class="text-muted ms-3"
      *ngIf="patientProfilesList?.length == 0 && !loadList"
    >
      No patients to select😷
    </h6>
    <div class="spinner-border text-secondary" role="status" *ngIf="loadList">
      <span class="sr-only">Loading...</span>
    </div>
    <div
      class="card cursor-pointer p-1 pii-text mb-0 mx-2"
      [ngClass]="{ selected: i == selectedIndex }"
      *ngFor="let patient of patientProfilesList; let i = index"
      (click)="setPatient(patient, i)"
    >
      {{ patient.name ? patient.name : "-" }}
      {{
        patient.primaryContactNumber
          ? "+" +
            patient.primaryContactNumber.countryCode +
            "-" +
            patient.primaryContactNumber.number
          : ""
      }}
    </div>
  </div>
</ng-template>

<ng-template #patientList2>
  <div class="w-100 mt-2 list">
    <h6
      class="text-muted ms-auto me-auto mt-3"
      *ngIf="patientProfilesList?.length == 0 && !loadList"
    >
      No patients to select 😷
    </h6>
    <div class="spinner-border text-secondary" role="status" *ngIf="loadList">
      <span class="sr-only">Loading...</span>
    </div>
    <div
      class="card border cursor-pointer pii-text mb-1 mx-2"
      style="border-radius: 0"
      [ngClass]="{ selected: i == selectedIndex }"
      *ngFor="let patient of patientProfilesList; let i = index"
      (click)="setPatient(patient, i)"
    >
      <div class="d-flex align-items-center justify-content-start w-100 p-2">
        <div class="d-flex flex-column">
          <div class="d-flex">
            <span class="fw-500">
              {{ patient.name ? patient.name : "-" }}
            </span>
            <span *ngIf="patient.gender" class="ms-2">
              | {{ patient.gender ? patient.gender : "-" }}
            </span>
          </div>
          <div class="text-secondary">
            {{
              patient.primaryContactNumber
                ? "+" +
                  patient.primaryContactNumber.countryCode +
                  "-" +
                  patient.primaryContactNumber.number
                : ""
            }}
            {{ patient.primaryEmail ? ", " + patient.primaryEmail : "" }}
          </div>
        </div>
        <div class="ms-auto">
          <i class="feather icon-chevron-right" style="font-size: 15px"></i>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #patientSearchTextInput>
  <input
    type="text"
    class="form-control w-100"
    style="border-radius: 0"
    id="patient-search-inp"
    title="search patient name or number"
    placeholder="Client Search - Name, Id, Number, etc."
    [(ngModel)]="searchText"
    (input)="setSearch($event)"
  />
</ng-template>
