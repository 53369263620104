import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DoctorListingProfile } from 'src/app/shared/models-ts/Actors/Doctor/DoctorListingProfile';
import { CountrywisePricingItem, MembershipPlan } from 'src/app/shared/models-ts/Entities/MembershipPlan/MembershipPlan';
import { ServiceTypes } from 'src/app/shared/models-ts/Enums/Enums';
import { Currency } from 'src/app/shared/models-ts/Helpers/countrywiseFeeCalc';
import { MembershipPlansService } from '../../membership-plans.service';
import { CountriesData } from './countries';
declare var bootstrap: any;

@Component({
  selector: 'app-doc-membership-plan-modify',
  templateUrl: './doc-membership-plan-modify.component.html',
  styleUrls: ['./doc-membership-plan-modify.component.scss']
})
export class DocMembershipPlanModifyComponent implements OnInit {
  providerProfile: DoctorListingProfile;
  plan: MembershipPlan = new MembershipPlan();
  countrywisePricingArr: CountrywisePricingItem[] = [];
  indiaPricingItem: CountrywisePricingItem = new CountrywisePricingItem();
  defaultInternationalPricingItem: CountrywisePricingItem = new CountrywisePricingItem();
  title: string;
  countries = new CountriesData().masterArray;
  sessionTypes = [
    ServiceTypes.individual,
    ServiceTypes.family,
    ServiceTypes.couple,
  ]
  currencies = [
    Currency.INR,
    Currency.USD,
    Currency.AED,
  ]
  @Output() refreshPlans = new EventEmitter<void>();
  offcanvas;
  constructor(
    private _toastrService:ToastrService,
    private _membershipPlansService: MembershipPlansService
  ) { }

  ngOnInit(): void {
  }

  public open(providerProfile: DoctorListingProfile, plan?: MembershipPlan) {
    this.providerProfile = providerProfile;
    if(plan) {
      this.plan = plan;
      this.title = "Edit Plan Details";
      this.countrywisePricingArr = [];
      plan.countrywisePricing.forEach((item)=>{
        if(item.country == "IN") {
          this.indiaPricingItem = item;
        } else if(item.country == "ZZ") {
          this.defaultInternationalPricingItem = item;
        } else {
          this.countrywisePricingArr.push(item);
        }
      })
      if(this.indiaPricingItem.country == null) {
        this.indiaPricingItem = new CountrywisePricingItem();
        this.indiaPricingItem.country = "IN";
        this.indiaPricingItem.currency = Currency.INR;
      }
      if(this.defaultInternationalPricingItem.country == null) {
        this.defaultInternationalPricingItem = new CountrywisePricingItem();
        this.defaultInternationalPricingItem.country = "ZZ";
        this.defaultInternationalPricingItem.currency = Currency.INR;
      }
      this.countrywisePricingArr.sort((a,b)=>{
        return a.country.localeCompare(b.country);
      });
    } else {
      this.plan = new MembershipPlan();
      this.plan.validityUnit = "day";
      this.plan.providerId = providerProfile.id;
      this.plan.isCareManagerSupportOn = true;
      this.plan.isEmailSupportOn = true;
      this.plan.sessionDurationInMinutes = this.providerProfile?.onlineConsultationConfig?.appointmentDuration;
      this.indiaPricingItem = new CountrywisePricingItem();
      this.defaultInternationalPricingItem = new CountrywisePricingItem();
      this.title = "Create Membership Plan";
      this.countrywisePricingArr = [];
      if(this.indiaPricingItem.country == null) {
        this.indiaPricingItem = new CountrywisePricingItem();
        this.indiaPricingItem.country = "IN";
        this.indiaPricingItem.currency = Currency.INR;
      }
      if(this.defaultInternationalPricingItem.country == null) {
        this.defaultInternationalPricingItem = new CountrywisePricingItem();
        this.defaultInternationalPricingItem.country = "ZZ";
        this.defaultInternationalPricingItem.currency = Currency.INR;
      }
    }
    var myOffcanvas = document.getElementById('offcanvas-for-membership-plan-modify')
    this.offcanvas = new bootstrap.Offcanvas(myOffcanvas, { backdrop: true, scroll: true })
    this.offcanvas.show();
  }

  removeCountrywisePricingItem(idx: number) {
    if(idx != null) {
      this.countrywisePricingArr.splice(idx,1);
    }
  }

  selectedSessionTypeFunc(a: ServiceTypes) {
    this.plan.sessionType = a;
  } 

  addNewCountry() {
    this.countrywisePricingArr.push(new CountrywisePricingItem());
  }

  async save() {
    if(this.validateForm()) {
      try{
        this.plan.countrywisePricing = new Map();
        this.plan.countrywisePricing.set(this.indiaPricingItem.country,this.indiaPricingItem);
        this.plan.countrywisePricing.set(this.defaultInternationalPricingItem.country,this.defaultInternationalPricingItem);
        this.countrywisePricingArr.forEach((a)=>{
          if(a) this.plan.countrywisePricing.set(a.country, a);
        })
        await this._membershipPlansService.createOrUpdatePlan(this.providerProfile.id,this.plan);
        this.refreshPlans.emit();
        this._toastrService.success("Plan saved!")
        this.closeCanvas();
      } catch(err) {
        console.error(err);
      }
    }
  }

  closeCanvas() {
    if(this.offcanvas) {
      this.offcanvas.hide();
    }
  }

  validateForm() {
    if(this.plan.validity == null || this.plan.validity == 0) {
      this._toastrService.error("Validity is not defined")
      return false;
    } else if(this.plan.noOfSessions == null || this.plan.noOfSessions == 0) {
      this._toastrService.error("No of sessions is not defined")
      return false;
    } else if(this.plan.sessionDurationInMinutes == null || this.plan.sessionDurationInMinutes == 0) {
      this._toastrService.error("Session duration is not defined")
      return false;
    } else if(this.plan.sessionType == null) {
      this._toastrService.error("Session type is not defined")
      return false;
    } else if(this.plan.sessionType == null) {
      this._toastrService.error("Session type is not defined")
      return false;
    } else if(!this.validateCountryPricingItem(this.indiaPricingItem)) {
      this._toastrService.error("India plan pricing is missing some details")
      return false;
    } else if(!this.validateCountryPricingItem(this.defaultInternationalPricingItem)) {
      this._toastrService.error("Default international plan pricing is missing some details")
      return false;
    }
    for(let a of this.countrywisePricingArr) {
      if(!this.validateCountryPricingItem(a)) {
        this._toastrService.error(a.country+" plan pricing is missing some details")
        return false;
      }
    }
    return true;
  }

  validateCountryPricingItem(item: CountrywisePricingItem) {
    if(item.additionalSessionCost == null) {
      return false;
    }
    if(item.country == null) {
      return false;
    }
    if(item.currency == null) {
      return false;
    }
    if(item.listPrice == null) {
      return false;
    }
    if(item.sellingPrice == null) {
      return false;
    }
    if(item.country != "IN" && item.sellingPricePerSessionInINR == null) {
      return false;
    }
    return true;
  }

}
