import { Expose, plainToClass, Transform, Type } from "class-transformer";
import { LeadRecipientSettlement } from "./LeadRecipientSettlement";
import { LeadTransferReversal } from "./LeadTransferReversal";

export class LeadTransfer {
  @Expose() id: string;
  @Expose() amount: number;
  @Expose() amountReversed: number;
  @Expose() createdOn: number;
  @Expose() onHold: boolean;
  @Expose() onHoldUntil: number;
  @Expose() processedOn: number;
  @Expose() recipient: string;
  @Expose() recipientSettlementId: string;
  @Type(()=>LeadRecipientSettlement)
  @Expose() recipientSettlement: LeadRecipientSettlement;
  @Expose() source: string;
  @Expose()
  @Transform(
    (value) => {
      if (value) {
        let map = new Map<string, LeadTransferReversal>();
        for (let entry of Object.entries(value)) {
          map.set(entry[0], plainToClass(LeadTransferReversal, entry[1]));
        }
        return map;
      } else {
        return new Map<string, LeadTransferReversal>();
      }
    },
    { toClassOnly: true }
  )
  reversals: Map<string, LeadTransferReversal> = new Map<string, LeadTransferReversal>();

  isSettlementDone() {
    return !this.hasBeenReversed() && this.recipientSettlement != null && this.recipientSettlement.isSuccessful();
  }
  isSettlementFailed() {
    return this.recipientSettlement != null && this.recipientSettlement.isFailed();
  }

  public get netTransferAmount(): number {
    let totalAmountReversed = 0;
    if(this.reversals) this.reversals.forEach((val)=>{
      totalAmountReversed += +(val.amount);
    })
    return (this.amount - totalAmountReversed);
  }

  hasBeenReversed():boolean {
    let totalAmountReversed = 0;
    if(this.reversals) this.reversals.forEach((val)=>{
      totalAmountReversed += +(val.amount);
    })
    return (this.amount - totalAmountReversed) <= 0
  }
}
