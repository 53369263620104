import { Component, Input, NgZone, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { classToPlain, plainToClass } from 'class-transformer';
import { Subscription } from 'rxjs';
import { DoctorListingProfile } from 'src/app/shared/models-ts/Actors/Doctor/DoctorListingProfile';
import { Schedule } from 'src/app/shared/models-ts/Entities/Schedule';
import { ScheduleService } from '../../services/schedule/schedule.service';
import { DayjsHelper } from 'src/app/shared/models-ts/Helpers/dayjsHelper';

@Component({
  selector: 'app-working-hours',
  templateUrl: './working-hours.component.html',
  styleUrls: [
    './working-hours.component.scss',
    './../../shared/availability-styles.scss',
  ],
})
export class WorkingHoursComponent implements OnInit, OnDestroy {
  schedule: any = {
    SUN: {
      bool: false,
      slots: [],
    },
    MON: {
      bool: false,
      slots: [],
    },
    TUE: {
      bool: false,
      slots: [],
    },
    WED: {
      bool: false,
      slots: [],
    },
    THU: {
      bool: false,
      slots: [],
    },
    FRI: {
      bool: false,
      slots: [],
    },
    SAT: {
      bool: false,
      slots: [],
    },
  };
  scheduleConfig = {
    availableForVideoConsultation: {
      bool: true,
      display: 'Video Consult'
    },
    availableForAudioConsultation: {
      bool: true,
      display: 'Phone Consult'
    },
    availableForChatConsultation: {
      bool: true,
      display: 'Chat Consult'
    },
    acceptsBookingOnDocVita: {
      bool: true,
      display: 'Accept Booking On Docvita'
    },
    acceptsCallOnDocVita: {
      bool: true,
      display: 'Accept Call On Docvita'
    }
  }
  copyEventObj: any = {};
  dayToClone: string[] = [];
  selectedCopyDayKey: string;
  overridedSlotsArray: any[] = []
  overrideDateScoller = new Date();
  scheduleGenericSlotLength: number;
  subscription: Subscription;
  
  showSaveActions:boolean = false;
  loader:boolean;
  slotErrors = {}

  practiceOptions: {key:string,value:string} [] = []

  _providerProfile:DoctorListingProfile = new DoctorListingProfile();
  @Input() set providerProfile(val:DoctorListingProfile){
    // console.log("set provider", val);
    if(val&&val.id){
      this._providerProfile = val;
      // console.log(this._providerProfile.schedule)
      this._zone.run(()=>{
        setTimeout(()=>{
          this.populatePracticeOptions();
          this.populateSchedule();
        },0)
      })
    }else{
      this._providerProfile = new DoctorListingProfile();
    }
  }

  get providerProfile(){
    return this._providerProfile;
  }

  constructor(
    private _scheduleService: ScheduleService,
    private _zone: NgZone
  ) {}

  ngOnInit(): void {
    this.initForm();
    // this.setProfileSub();
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  returnZero() {
    return 0;
  }

  populateSchedule() {
    this.resetSchedule();
    if (this.providerProfile.schedule && this.providerProfile.schedule.length > 0) {
      this.scheduleGenericSlotLength = +this.providerProfile.schedule[0].timeSlotLength
      for (let sch of this.providerProfile.schedule) {
        if (sch && sch.days && sch.days.length > 0 && sch.startTime && sch.endTime && sch.acceptsBookingOnDocVita == true) {
          for (let c in this.scheduleConfig) {
            if (sch[c] != null) {
              this.scheduleConfig[c]['bool'] = sch[c]
            }
          }
          for (let d of sch.days) {
            const inPersonPracticeId = sch.availableAtPractices.size>0? sch.availableAtPractices.keys().next().value: null;
            this.addSlot(d.toUpperCase(), sch.startTime, sch.endTime,inPersonPracticeId)
            this.schedule[d.toUpperCase()]['bool'] = true;
          }
        }
      }
    }
  }

  populatePracticeOptions(){
    this.practiceOptions = []
    if(this.providerProfile?.associatedPractices?.size>0){
      for(const i of this.providerProfile?.associatedPractices){
        if(i[1]?.acceptingAppointmentsOnDocVita){
          this.practiceOptions.push({key:i[0],value:i[1].name})
        }
      }
    }
  }

  initForm() {
    this.copyEventObj = {};
    this.dayToClone = [];
    this.resetSchedule();
  }

  resetSchedule() {
    for (let sc in this.schedule) {
      this.schedule[sc]['bool'] = false;
      this.schedule[sc]['slots'] = [];
    }
    this.slotErrors = {};
  }

  addSlot(day: string, startTime?: string, endTime?: string,availableAtPracticeId?:string) {
    this.schedule[day]['slots'].push({
      startTime: startTime ? startTime : null,
      endTime: endTime ? endTime : null,
      availableAtPractices: {
        bool:availableAtPracticeId?true:false,
        practiceId: availableAtPracticeId?availableAtPracticeId:(this.practiceOptions[0]?.key?this.practiceOptions[0]?.key:null)
      }
    });
  }

  deleteSlot(day: string, index: number) {
    if (
      this.schedule[day]['slots'][index].startTime != null ||
      this.schedule[day]['slots'][index].endTime != null
    ) {
      this.schedule[day]['slots'][index].startTime = null;
      this.schedule[day]['slots'][index].endTime = null;
    } else {
      this.schedule[day]['slots'].splice(index, 1);
    }
    this.toggleSaveActions(true)
  }

  copyEvent(dayToCopy: string) {
    this.dayToClone = [];
    this.selectedCopyDayKey = dayToCopy;
    this.copyEventObj = Object.assign({}, this.schedule[dayToCopy]);
  }

  copyTheDaysToCopy(event, day: string) {
    const bool: boolean = event;
    if (!this.dayToClone.includes(day) && bool) {
      this.dayToClone.push(day);
    } else if (!bool) {
      const index = this.dayToClone.findIndex((da) => da == day);
      this.dayToClone.splice(index, 1);
    }
  }

  applyCurrentCopyEvent() {
    const slotsToCopy =
      this.copyEventObj && this.copyEventObj['slots']
        ? this.copyEventObj['slots']
        : null;
    if (this.dayToClone.length > 0 && slotsToCopy) {
      for (let day of this.dayToClone) {
        this.schedule[day]['slots'] = JSON.parse(JSON.stringify(slotsToCopy));
      }
    }
    this.toggleSaveActions(true)
    this.dayToClone = [];
  }

  toggleSaveActions(bool:boolean,resetOnfalse?:boolean) {
    this.showSaveActions = bool;
    if(bool==false && resetOnfalse){
      this.populateSchedule();
    }
  }

  async saveSchedule() {
    if(Object.keys(this.slotErrors).length>0){
      return;
    }
    if (this.scheduleGenericSlotLength != null) {
      this.loader = true;
      let finalSchedules = []

      for (const day in this.schedule) {
        const schedule = this.schedule[day];
        if (schedule['slots'] && schedule['slots'].length > 0) {
          for (const _slot of schedule['slots']) {
            if (_slot['startTime'] && _slot['endTime']) {
              let new_schedule = new Schedule();
              //set default config
              for (let c in this.scheduleConfig) {
                new_schedule[c] = this.scheduleConfig[c]['bool']
              }
              new_schedule.timeSlotLength = this.scheduleGenericSlotLength;
              new_schedule.days = [this.capitalize(day)]
              new_schedule.startTime = _slot['startTime']
              new_schedule.endTime = _slot['endTime']
              if(_slot['availableAtPractices'] && _slot['availableAtPractices']['bool']){
                const practiceId = _slot['availableAtPractices']['practiceId']
                new_schedule.availableAtPractices = new Map<string,boolean>();
                new_schedule.availableAtPractices.set(practiceId,true)
              }
              finalSchedules.push(classToPlain(new_schedule))
            }
          }
        }
      }
      
      if (finalSchedules && finalSchedules.length > 0) {
        try {
          // console.log(finalSchedules)
          const response = await this._scheduleService.saveSchedule(finalSchedules, this.providerProfile.id)
          if(response){
            //success
            this.toggleSaveActions(false);
          }else{
            //fail state
          }
          this.loader = false;
        } catch (error) {
          //fail state
          this.loader = false;
        }
      } else {
        // this.toastrService.success("No schedule!")
        this.loader = false;
      }

    }
  }

  private capitalize(val: string) {
    if (val && val.length > 0) {
      val = val[0] + val.slice(1).toLowerCase()
    }
    return val;
  }

  slotValidationChecker(dayKey:string, slotIndex:number){
    if(this.schedule[dayKey]&&this.schedule[dayKey]['slots'][slotIndex]){
      if
      (!(this.schedule[dayKey]['slots'][slotIndex]['startTime']&&
      this.schedule[dayKey]['slots'][slotIndex]['endTime']&&
      this.schedule[dayKey]['slots'][slotIndex]['startTime'].length==5&&
      this.schedule[dayKey]['slots'][slotIndex]['endTime'].length==5)
      ){
        // console.log('set error ', this.slotErrors)
        this.clearLinkedErrors(dayKey,slotIndex);
        this.slotErrors[dayKey+slotIndex] = {dayKey:dayKey,slotIndex:slotIndex,reason:'Invalid time'}
      }
      else if(
        this.schedule[dayKey]['slots'][slotIndex]['startTime'] > this.schedule[dayKey]['slots'][slotIndex]['endTime']
      ){
        this.clearLinkedErrors(dayKey,slotIndex);
        this.slotErrors[dayKey+slotIndex] = {dayKey:dayKey,slotIndex:slotIndex,reason:'Choose an end time later than start time'}
      }
      else{
        const overlappingSlots = this.getOverlappingSlotsIndices(dayKey,slotIndex);
        if(overlappingSlots&&overlappingSlots.length>1){
          // console.log('overlapping:', overlappingSlots)
          overlappingSlots.map((i)=>{
            this.slotErrors[dayKey+i] = {dayKey:dayKey,slotIndex:i,linkedErrIdx:slotIndex,reason:'Times overlap with another set of times'}
          })
          return;
        }
        if(this.slotErrors[dayKey+slotIndex]){
          this.clearLinkedErrors(dayKey,slotIndex);
          delete this.slotErrors[dayKey+slotIndex]
        }
          
      }
    }
  }

  clearLinkedErrors(dayKey:string,slotIndex:number){
    if(
      this.slotErrors[dayKey+slotIndex]&&
      this.slotErrors[dayKey+slotIndex]['linkedErrIdx']&&
      this.slotErrors[dayKey+slotIndex]['linkedErrIdx']==slotIndex)
    {
      // console.log('trying to delete from parent')
      const getLinkedErrs = Object.values(this.slotErrors).filter(v=>v&&v['linkedErrIdx']==slotIndex&&v['slotIndex']!=slotIndex)
      // console.log(getLinkedErrs)
      getLinkedErrs.map((v)=>{
        delete this.slotErrors[v['dayKey']+v['slotIndex']]
      })
    }else if(
      this.slotErrors[dayKey+slotIndex]&&
      this.slotErrors[dayKey+slotIndex]['linkedErrIdx']&&
      this.slotErrors[dayKey+slotIndex]['linkedErrIdx']!=slotIndex
    ){
      // console.log('trying to delete from child')
      const linkedIndx = this.slotErrors[dayKey+slotIndex]['linkedErrIdx']
      delete this.slotErrors[dayKey+linkedIndx]
    }else{
      //nothing
      // console.log('all failed')
    }
  }

  getOverlappingSlotsIndices(dayKey:string,slotIndexToCompare:number){
    const slots:any[] = this.schedule[dayKey]['slots']
    const timeslot1 = slots[slotIndexToCompare];
    let overlapIndicies = []
    if(slots&&slots.length>0){
      for(let i=0;i<slots.length;i++){
        if(this.checkOverlap(slots[i],timeslot1)){
          overlapIndicies.push(i)
        }
      }
      return overlapIndicies;
    }
    return [];
  }

  checkOverlap(timeslot1, timeslot2) {
    const anyDate = new DayjsHelper().todayYYYYMMDD();
    const start1 = new Date(`${anyDate}T${timeslot1.startTime}:00`);
    const end1 = new Date(`${anyDate}T${timeslot1.endTime}:00`);
    const start2 = new Date(`${anyDate}T${timeslot2.startTime}:00`);
    const end2 = new Date(`${anyDate}T${timeslot2.endTime}:00`);
    if ((start1 <= end2) && (end1 >= start2)) {
      return true;
    } else {
      return false;
    }
  }

  get hoursHaveErrors(){
    return Object.keys(this.slotErrors).length>0;
  }



}
