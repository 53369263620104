import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { plainToClass } from 'class-transformer';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault('Asia/Kolkata');
import { BsModalService } from 'ngx-bootstrap/modal';
import { AppointmentInvite } from '../../models-ts/Entities/AppointmentInvite/AppointmentInvite';
import { AppointmentInviteStatus } from '../../models-ts/Enums/Enums';
import { AppointmentInviteService } from '../../services/appointment-invite/appointment-invite.service';

@Component({
  selector: 'view-all-invites',
  templateUrl: './view-all-invites.component.html',
  styleUrls: ['./view-all-invites.component.scss'],
})
export class ViewAllInvitesComponent implements OnInit {
  @Input() showButton: boolean = false;
  inviteList: AppointmentInvite[] = [];
  isLoadingList: boolean = false;
  listSub: any;
  @ViewChild('allInvitesModal') allInvitesModal;
  constructor(
    private modalService: BsModalService,
    private _apptInviteService: AppointmentInviteService
  ) {}

  ngOnInit(): void {}

  openModal() {
    this.getAllInvites();
    this.modalService.show(this.allInvitesModal);
  }
  getAllInvites() {
    if (this.listSub) {
      this.listSub();
    }
    this.inviteList = [];
    this.isLoadingList = true;
    this.listSub = this._apptInviteService.getAllInvites().onSnapshot(
      (snap) => {
        if (!snap.empty) {
          this.inviteList = [];
          snap.forEach((doc) => {
            let invite = plainToClass(AppointmentInvite, doc.data());
            this.inviteList.push(invite);
          });
          this.isLoadingList = false;
        } else {
          this.inviteList = [];
          this.isLoadingList = false;
        }
      },
      (err) => {
        this.inviteList = [];
        this.isLoadingList = false;
      }
    );
  }
  closeModal() {
    this.modalService.hide();
  }
  consultationDateTime(item: AppointmentInvite) {
    if (item.inviteDate && item.inviteTime) {
      let date = item.inviteDate;
      let time = item.inviteTime;
      return (
        dayjs(new Date(date + ' ' + time)).format(
          'DD/MM/YYYY, hh:mm a'
        ) + ' IST'
      );
    } else {
      return '-';
    }
  }
}
