import { Injectable } from '@angular/core';
import * as env from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DoctorsSearchService {

  constructor(
    private _http: HttpClient,
  ) { }

  async getDoctorByNumber(mobileNumber:string){
    try {
      const url = env.environment['doctor-search-by-number']+'?mobileNumber='+mobileNumber
      const data = await this._http.get(url).toPromise()
      if(data&&data['statusCode']==200&&data['body']&&data['body']['profiles']){
        return data['body']['profiles']
      }else{
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async doctorSearchByNameId(nameOrId: string) {
    try {
      const url = env.listingUrl + '/v1/crm-search/provider/search-with-name-id' +'?nameOrId='+nameOrId
      const data = await this._http.get(url).toPromise()
      if(data&&data['statusCode']==200&&data['body']&&data['body']['profiles']){
        return data['body']['profiles']
      }else{
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async searchDegreeByName(degreeName: string) {
    try {
      const url = env.listingUrl + '/v1/crm-search/provider/search-degree-by-name' +'?degreeName='+degreeName
      const data = await this._http.get(url).toPromise()
      if(data&&data['statusCode']==200&&data['body']&&data['body']['profiles']){
        return data['body']['profiles']
      }else{
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async searchCollegeByName(collegeName: string) {
    try {
      const url = env.listingUrl + '/v1/crm-search/provider/search-college-by-name' +'?collegeName='+collegeName
      const data = await this._http.get(url).toPromise()
      if(data&&data['statusCode']==200&&data['body']&&data['body']['profiles']){
        return data['body']['profiles']
      }else{
        return null;
      }
    } catch (error) {
      return null;
    }
  }
}
