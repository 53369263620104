import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { classToPlain, plainToClass } from 'class-transformer';
import { ToastrService } from 'ngx-toastr';
import { debounceTime } from 'rxjs/operators';
import { ChatListItem } from 'src/app/shared/models-ts/Entities/chat/ChatListItem';
import { ChatMessage } from 'src/app/shared/models-ts/Entities/chat/ChatMessage';
import { CRMUser } from 'src/app/shared/models-ts/Actors/User/CRMUser';
import { ChatListService } from '../services/chat-list.service';
import { ChatMessagesService } from '../services/chat-messages.service';
import { AuthUserInfoService } from 'src/app/shared/services/auth-user-info/auth-user-info.service';
import { Subject, Subscription } from 'rxjs';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { BooleanAction } from 'src/app/shared/models-ts/Protocols/BooleanAction';

@Component({
  selector: 'app-chat-window',
  templateUrl: './chat-window.component.html',
  styleUrls: ['./chat-window.component.scss'],
  providers: [ChatMessagesService],
})
export class ChatWindowComponent implements OnInit, OnDestroy {
  @ViewChild('messagesContainer') private messagesContainer: ElementRef;
  _chatListItemId: string;
  chatListItem: ChatListItem = new ChatListItem();

  private _specificMessageIds: string[] = [];

  get specificMessageIds(): string[] {
    return this._specificMessageIds;
  }

  @Input() set specificMessageIds(value: string[]) {
    this._specificMessageIds = value;
    if (value && value.length > 0) {
      // this.queriedMessageId = value[value.length - 1]; //TODO: will do it later
      this.initMessagesStream(this.chatListItemId);
    }
  }


  @Input() inLeads: boolean = false;
  @Input() inFollowups: boolean = false;
  @Input() set leadChatListItemId(val: string) {
    this.initMessagesStream(val, null);
  }
  set chatListItemId(val: string) {
    this._chatListItemId = val;
  }
  get chatListItemId(): string {
    return this._chatListItemId;
  }
  chatItemLoader: boolean = false;

  latestMessageFromCustomer: ChatMessage;

  haveGoneTospecificMessageOnce: boolean = false;

  messages: ChatMessage[] = [];
  isLoading: boolean = false;
  isSendingTextMessage: boolean = false;
  _textMessage: string;
  set textMessage(val: string) {
    this._textMessage = val;
    this.textMessageSub.next(val);
  }

  get textMessage() {
    return this._textMessage;
  }

  textMessageSub = new Subject<string>();
  messageSub: Subscription;
  readNewMessagesSub = new Subject();
  loggedInUser: CRMUser;
  isScrollingUp: boolean = false;
  loadingOlderMessages: boolean = false;
  loadingLaterMessages: boolean = false;
  lastMessageId: string;
  resolvingChat: boolean = false;
  chatListItemFBListenerSub;
  alreadyMarkedAsRead: string[] = [];
  queriedMessageId: string;
  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _chatListService: ChatListService,
    private _chatMessagesService: ChatMessagesService,
    private _toastrService: ToastrService,
    private _authUserInfoService: AuthUserInfoService
  ) {
    this.loggedInUser = this._authUserInfoService.getUserInfo();
  }

  ngOnInit(): void {
    if (!this.inLeads && !this.inFollowups) {
      this._activatedRoute.params.subscribe((params) => {
        let messageIdQuery =
          this._activatedRoute.snapshot.queryParamMap.get('mid');
        this.queriedMessageId = messageIdQuery;
        if (
          params &&
          params['chatListItemId'] &&
          params['chatListItemId'].length > 0
        ) {
          this.initMessagesStream(params['chatListItemId'], messageIdQuery);
        } else {
          this.chatListItemId = null;
        }
        this._activatedRoute.queryParams.subscribe((qparams) => {
          if (
            qparams['mid'] &&
            qparams['mid'].length > 0 &&
            (!messageIdQuery ||
              (messageIdQuery && messageIdQuery != qparams['mid']))
          ) {
            console.log('called again');
            this.queriedMessageId = qparams['mid'];
            this.initMessagesStream(params['chatListItemId'], qparams['mid']);
          } else {
            this.queriedMessageId = null;
          }
        });
      });
    }

    this.textMessageSub.pipe(debounceTime(400)).subscribe((val) => {
      if (val && val.length > 0) {
        this.typingUpdation();
      }
    });
    this.readNewMessagesSub.pipe(debounceTime(1000)).subscribe(() => {
      this.filterAndMarkAsRead();
    });
  }

  initMessagesStream(chatListItemId: string, messageIdQuery?: string) {
    this.chatListItem = new ChatListItem();
    this.chatListItemId = chatListItemId;
    this.messages = [];
    this.alreadyMarkedAsRead = [];
    this._chatMessagesService.activateChatMessages(
      this.chatListItemId,
      messageIdQuery
    );
    this.markChatAsRead();
    this.subToLatestMessages();
    if (this.chatListItemId) this.liveListenerForChatListItem();
  }

  liveListenerForChatListItem() {
    if (this.chatListItemFBListenerSub) {
      this.chatListItemFBListenerSub();
    }
    this.chatItemLoader = true;
    this.chatListItemFBListenerSub = firebase
      .firestore()
      .collection('chat_list')
      .doc(this.chatListItemId)
      .onSnapshot(
        (doc) => {
          if (doc.exists) {
            this.chatListItem = plainToClass(ChatListItem, doc.data());
          }
          this.chatItemLoader = false;
        },
        (err) => {
          this.chatItemLoader = false;
        }
      );
  }

  subToLatestMessages() {
    this.isLoading = true;
    this.messageSub = this._chatMessagesService.messagesSub
      .pipe(debounceTime(600))
      .subscribe((val) => {
        // console.log(val);
        this.isLoading = false;
        this.loadingOlderMessages = false;
        this.loadingLaterMessages = false;
        this.messages = val['messages'];
        if (this.messages.length > 0) {
          this.readNewMessagesSub.next();
        }

        if (val['scrollDown'] == true) {
          // console.log('scroll to bottom called');
          if (!this.isScrollingUp && !this.queriedMessageId) {
            this.scrollToBottom();
          }
          if (this.queriedMessageId && this.queriedMessageId.length > 0) {
            console.log('called messageScroller');
            if (!this.haveGoneTospecificMessageOnce) {
              this.haveGoneTospecificMessageOnce = true;
              this.scrollToMessageId(this.queriedMessageId);
            }
          }
        }
      });
  }

  scrollToBottom(): void {
    setTimeout(() => {
      try {
        if (this.messagesContainer) {
          this.messagesContainer.nativeElement.scrollTop =
            this.messagesContainer.nativeElement.scrollHeight;
          this.isScrollingUp = false;
        } else console.log('Empty');
      } catch (err) {
        console.error(err);
      }
    }, 0);
  }

  scrollToMessageId(mid: string) {
    const messageDocRef = window.document.getElementById('_' + mid);
    if (messageDocRef) {
      console.log(messageDocRef);
      messageDocRef.scrollIntoView(true);
    }
  }

  sendTextMessage() {
    if (this.chatListItem) {
      this.isSendingTextMessage = true;
      this._chatMessagesService
        .sendTextMessage(
          this.textMessage,
          this.chatListItem.platformSenderId,
          this.loggedInUser.docvitaId,
          this.loggedInUser.name
        )
        .then((val) => {
          if (val['success']) {
          } else if (val['success'] == false && val['errorCode'] == 811) {
            this._toastrService.error(
              'Falied to send message: 24 hours window has expired'
            );
          } else {
            this._toastrService.error('Falied to send message');
          }
          this.isSendingTextMessage = false;
          this.resetMessageState();
        })
        .catch((err) => {
          console.error(err);
          this._toastrService.error('Err: ' + err);
          this.isSendingTextMessage = false;
        });
    } else {
    }
  }

  sendHelloTemplateMessage() {
    if (this.chatListItem) {
      this.isSendingTextMessage = true;
      this.textMessage = this.textMessage.split('\n').join('');
      this._chatMessagesService
        .sendHelloTemplateMessage(
          this.textMessage,
          this.chatListItem.platformSenderId,
          this.loggedInUser.docvitaId,
          this.loggedInUser.name
        )
        .then((val) => {
          if (val['success']) {
          } else if (val['success'] == false && val['errorCode'] == 811) {
            this._toastrService.error(
              'Falied to send message: 24 hours window has expired'
            );
          } else {
            this._toastrService.error('Falied to send message');
          }
          this.isSendingTextMessage = false;
          this.resetMessageState();
        })
        .catch((err) => {
          console.error(err);
          this._toastrService.error('Err: ' + err);
          this.isSendingTextMessage = false;
        });
    } else {
    }
  }

  typingUpdation() {
    if (this._chatListItemId && this.loggedInUser) {
      console.log('calling typing');
      this._chatListService.updateTyping(
        this._chatListItemId,
        this.loggedInUser.docvitaId,
        this.loggedInUser.name
      );
    }
  }

  resetMessageState() {
    this.textMessage = null;
  }

  loadPrevMessages() {
    if (this.chatListItemId && this.messages && this.messages.length > 0) {
      // alert(this.messages[0].createdOn);
      this.loadingOlderMessages = true;
      this._chatMessagesService.previousBatchOfChatMessagesFromFirestore(
        this.chatListItemId,
        this.messages[this.messages.length - 1].createdOn
      );
    }
  }

  loadLaterMessages() {
    if (
      this.chatListItemId &&
      this.messages &&
      this.messages.length > 0 && false
    ) {
      // alert(this.messages[0].createdOn);
      console.log(
        "loadLaterMessages"
      )
      this.loadingLaterMessages = true;
      this._chatMessagesService.nextBatchOfChatMessagesFromFirestore(
        this.chatListItemId,
        this.messages[0].createdOn,
        false
      );
    }
  }

  markChatAsUnRead() {
    if (this.chatListItemId) {
      this._chatMessagesService
        .markChatAsUnRead(this.chatListItemId)
        .then(() => {
          this._toastrService.success('Marked as Unread');
        })
        .catch((err) => {
          this._toastrService.error('Failed to marked as Unread ');
        });
      this._router.navigate(['chats'], { queryParams: null });
    }
  }
  markChatAsRead(messageIdsToMarkRead?: string[]) {
    if (this.chatListItemId) {
      this._chatMessagesService
        .markChatAsRead(this.chatListItemId, messageIdsToMarkRead)
        .then(() => {
          // console.log('chat marked read: ', this.chatListItem.chatItemId);
        })
        .catch((err) => {
          console.log('mark read failed: ', this.chatListItem.chatItemId);
        });
    }
  }
  filterAndMarkAsRead() {
    // console.log('called mark read filter');
    let messageIdsToMarkRead = [];
    for (let i of this.messages) {
      if (
        i.isInbound &&
        !i.readReceipts.get(this.loggedInUser.docvitaId) &&
        !this.chatListItem.unreadMessages.get(i.messageId) &&
        !this.alreadyMarkedAsRead.includes(i.messageId)
      ) {
        messageIdsToMarkRead.push(i.messageId);
        let ba = new BooleanAction();
        ba.bool = true;
        ba.updatedById = this.loggedInUser.docvitaId;
        ba.updatedByName = this.loggedInUser.name;
        ba.updatedOn = new Date().getTime();
        i.readReceipts.set(this.loggedInUser.docvitaId, ba);
        this.alreadyMarkedAsRead.push(i.messageId);
      }
    }
    // console.log(messageIdsToMarkRead);
    this.markChatAsRead(messageIdsToMarkRead);
  }
  scrollEvent(event: any) {
    if (
      event.target.scrollHeight - Math.abs(event.target.scrollTop) <
      event.target.scrollHeight * 0.5 &&
      !this.loadingOlderMessages
    ) {
      // console.log('loading older');
      // this.loadPrevMessages();
    }
    if (
      event.target.scrollHeight - Math.abs(event.target.scrollTop) <
      event.target.scrollHeight * 0.8 &&
      !this.isScrollingUp
    ) {
      this.isScrollingUp = true;
    }
    if (
      event.target.scrollHeight - Math.abs(event.target.scrollTop) >
      event.target.scrollHeight * 0.999
    ) {
      this.isScrollingUp = false;
    }
  }
  resolveChat() {
    let platformSenderId = this.chatListItem.platformSenderId;
    this.resolvingChat = true;
    this._chatMessagesService
      .reassignToBrain(platformSenderId)
      .then((snap) => {
        this.resolvingChat = false;
        this._toastrService.success('🚀 Chat resolved!');
        this._router.navigate(['chats'], { queryParams: null });
      })
      .catch((err) => {
        this.resolvingChat = false;
        this._toastrService.error('Failed to resolve chat..');
      });
  }
  unResolveChat() {
    const platformSenderId = this.chatListItem.platformSenderId;
    this.resolvingChat = true;
    this._chatMessagesService.reassignToTeam(platformSenderId).then(() => {
      this.resolvingChat = false;
      this._toastrService.success('🚀 Chat is alive!');
    }).catch(err => {
      this.resolvingChat = false;
      this._toastrService.error('Failed to un-resolve chat..');
    })
  }

  get chatDisplayName() {
    if (this.chatListItem.displayName) {
      if (this.chatListItem.displayName.length > 35) {
        return this.chatListItem.displayName.slice(0, 35) + '..';
      } else {
        return this.chatListItem.displayName;
      }
    } else {
      return '';
    }
  }

  goToMessageReply(messageId: string) {
    if (messageId) {
      // search in local message array if reply message id exists
    } else {
      // use jump to search via message id
      this.jumpToSearchMessage(messageId)
    }
  }

  jumpToSearchMessage(messageId: string) {
    this._router.navigate([], {
      queryParams: {
        mid: messageId,
      },
    });
  }

  ngOnDestroy(): void {
    if (this.messageSub) {
      this.messageSub.unsubscribe();
    }
    if (this.readNewMessagesSub) {
      this.readNewMessagesSub?.unsubscribe();
    }
    if (this.textMessageSub) {
      this.textMessageSub.unsubscribe();
    }
  }
}
