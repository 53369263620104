import { Component, Input, OnInit } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { DoctorListingProfile } from '../../models-ts/Actors/Doctor/DoctorListingProfile';
import { Appointment } from '../../models-ts/Entities/Appointment';
import { DoctorsService } from '../../services/doctors/doctors.service';

@Component({
  selector: 'provider-appointments',
  templateUrl: './provider-appointments.component.html',
  styleUrls: ['./provider-appointments.component.css'],
})
export class ProviderAppointmentsComponent implements OnInit {
  @Input() set doctorProfile(val: DoctorListingProfile) {
    this._doctorProfile = val;
    this.getAppointments();
  }
  @Input() set date(val: string) {
    this._date = val;
    this.getAppointments();
  }
  _date: string = new Date().toISOString().split('T')[0];
  _doctorProfile: DoctorListingProfile = new DoctorListingProfile();
  appointmentsList: Appointment[];
  apptLoader: boolean = false;
  noAppointments: boolean = false;
  constructor(private _doctorService: DoctorsService) {}

  ngOnInit(): void {
    this.getAppointments();
  }

  getAppointments() {
    this.appointmentsList = [];
    this.noAppointments = false;
    this.apptLoader = true;
    if (this._doctorProfile && this._doctorProfile.id) {
      this._doctorService
        .getAppointmentByDate(this._doctorProfile.id, this._date)
        .then((snap) => {
          if (!snap.empty) {
            let _ = [];
            snap.forEach((data) => {
              _.push.apply(_, Object.values(data.data()));
            });
            if (_ && _.length < 1) {
              this.noAppointments = true;
              this.apptLoader = false;
            } else {
              this.appointmentsList = _.map((data) => {
                return plainToClass(Appointment, data);
              });
              this.appointmentsList.sort(
                (a, b) =>
                  new Date(b.date + ' ' + b.timeslot.split('-')[0]).getTime() -
                  new Date(a.date + ' ' + a.timeslot.split('-')[0]).getTime()
              );
              this.apptLoader = false;
            }
          } else {
            this.noAppointments = true;
            this.apptLoader = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.noAppointments = true;
          this.apptLoader = false;
        });
    } else {
      this.noAppointments = true;
      this.apptLoader = false;
    }
  }

  get displayCurrentDate() {
    return new Date(this._date).getTime();
  }
}
