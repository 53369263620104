import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { classToPlain } from 'class-transformer';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CRMUser } from '../../models-ts/Actors/User/CRMUser';
import { User } from '../../models-ts/Actors/User/User';
import { Lead } from '../../models-ts/Entities/Lead';
import {
  ProviderDocument,
  FileType,
  FileSource,
} from '../../models-ts/Entities/ProviderDocument';
import { AuthUserInfoService } from '../../services/auth-user-info/auth-user-info.service';
import { MedRecordsService } from '../../services/med-records/med-records.service';
@Component({
  selector: 'upload-med-records',
  templateUrl: './upload-med-records.component.html',
  styleUrls: ['./upload-med-records.component.scss'],
})
export class UploadMedRecordsComponent implements OnInit {
  modalRef: BsModalRef;
  @ViewChild('uploadRecords') uploadRecordsModal;
  @Input() showButton: boolean = false;
  @Input() isWingman: boolean = false;
  @Input() lead: Lead = new Lead();
  @Input() currentHeroInfo: CRMUser = new CRMUser();
  uploading: boolean = false;
  fileDetails: any[] = [];
  fileTypes = Object.keys(FileType);
  constructor(
    private modalService: BsModalService,
    private medRecordsService: MedRecordsService,
    private toastrService: ToastrService,
    private _authUserInfoService: AuthUserInfoService
  ) {}

  ngOnInit(): void {
    this.currentHeroInfo = this._authUserInfoService.getUserInfo();
  }
  openModal() {
    this.initState();
    this.modalRef = this.modalService.show(this.uploadRecordsModal);
  }
  closeModal() {
    this.modalRef.hide();
  }
  initState() {
    this.fileDetails = [];
  }
  addFileLocally(files) {
    let _files: File[] = files.target.files;
    for (let file of _files) {
      let documentMeta = new ProviderDocument();
      documentMeta.leadId = this.lead.id;
      documentMeta.appointmentId = this.lead.consultationInfo.appointmentId;
      documentMeta.clientId = this.lead.consultationInfo.patientId;
      documentMeta.providerId = this.lead.consultationInfo.doctorId;
      documentMeta.uploadedById = this.currentHeroInfo.docvitaId;
      documentMeta.uploadedByName = this.currentHeroInfo.name;
      documentMeta.uploadedOn = new Date().getTime();
      documentMeta.title = file.name;
      documentMeta.description = '';
      documentMeta.fileDocumentType = file.type;
      documentMeta.fileType = FileType.prescription;
      documentMeta.source = FileSource.doctor;
      this.getFileStream(file, documentMeta);
    }
  }

  getFileStream(f: File, documentMeta: ProviderDocument) {
    let reader = new FileReader();
    reader.readAsDataURL(f);
    reader.onload = (_event) => {
      if (reader.error) {
        console.error(reader.error);
      }
      if (reader.result) {
        let _file = {
          name: f.name,
          contentType: f.type,
          obj: reader.result,
        };
        this.fileDetails.push({ documentMeta: documentMeta, file: _file });
        this.uploadFiles([{ documentMeta: documentMeta, file: _file }]);
      }
    };
  }

  discardFile(index) {
    this.fileDetails.splice(index, 1);
  }
  setFileType(type: string, index: number) {
    this.fileDetails[index]['documentMeta']['fileType'] = FileType[type];
  }
  setRecordSource(type: string, index: number) {
    this.fileDetails[index]['documentMeta']['source'] = type;
  }
  uploadFiles(fileDetails: any[]) {
    if (fileDetails && fileDetails.length > 0) {
      for (let file of fileDetails) {
        if (
          file['documentMeta']['title'].length == 0 ||
          file['documentMeta']['title'] == ' ' ||
          file['documentMeta']['title'] == undefined
        ) {
          file['documentMeta']['title'] = file['file']['name'];
        }
        file['documentMeta'] = classToPlain(file['documentMeta']);
      }
      this.uploading = true;
      this.medRecordsService
        .uploadDocuments(fileDetails)
        .then((res) => {
          if (res && res['success'] == true) {
            this.toastrService.success('Uploaded successfully!');
            this.fileDetails = [];
            // this.closeModal();
          } else {
            for (let i of res['data']) {
              this.toastrService.error('Failed to upload - ' + i['title']);
            }
          }
          this.uploading = false;
        })
        .catch((err) => {
          this.uploading = false;
          this.toastrService.error('Something went wrong!');
        });
    }
  }
}
