<h6>Provider fee config for this patient</h6>
<ng-container *ngIf="client">
    <div>
        Doctor connections:
        <select class="form-select doctor-list-selection" [(ngModel)]="currentSelectedConnection">
            <option [ngValue]="item" *ngFor="let item of quickConnections">{{ item.organisationName }}</option>
        </select>
    </div>
    <div *ngIf="providerSelected">
        <div class="mb-2">Std. fee. {{ standardNatInNatPricingStr }}</div>
        <div>Service Type Selection:</div>
        <div class="d-flex flex-row">
            <select class="form-select service-list-selection" (change)="selectServiceType()" [(ngModel)]="formSelection.serviceType">
                <option [value]="item" *ngFor="let item of serviceOptions">{{ item | titlecase }}</option>
            </select>
            <input class="ms-2 me-2 form-control" placeholder="amount" type="number" [(ngModel)]="formSelection.amount" />
            <currency-dropdown (selectedCurrency)="selectedCurrency($event)" [defaultValue]="formSelection.currency" [(ngModel)]="formSelection.currency" ></currency-dropdown>
            <button class="ms-2 btn btn-primary btn-sm" (click)="save()"> 
                <div class="spinner-border spinner-border-sm" role="status" *ngIf="isSaving">
                    <span class="visually-hidden">Loading...</span>
                </div>
                Save</button>
        </div>
    </div>
</ng-container>