import { Pipe, PipeTransform } from '@angular/core';
import { PaymentTransaction } from '../models-ts/Entities/PaymentTransaction/PaymentTransaction';

@Pipe({
  name: 'paymentsStatusFilter',
  pure:false
})
export class PaymentsStatusFilterPipe implements PipeTransform {

  transform(items:PaymentTransaction[], filter:string): any {
    switch(filter){
      case 'completed': return items.filter(da=>da.transactionStatus=="COMPLETED");break;
      case 'not_completed': return items.filter(da=>da.transactionStatus=="CREATED");break;
      case 'transferred': return items.filter(da=>da.transactionStatus=="COMPLETED"&&da.getLatestPayout?.status=="SUCCESS");break;
      case 'not_transferred': return items.filter(da=>da.transactionStatus=="COMPLETED"&&da.getLatestPayout?.status!="SUCCESS");break;
      default: return items;
    }
  }

}
