import { Injectable } from '@angular/core';
import { ReschedulingRequest } from '../../models-ts/Entities/ReschedulingRequest';
import { classToPlain, plainToClass } from 'class-transformer';
import firebase from 'firebase';
import 'firebase/firestore';
import { HttpClient } from '@angular/common/http';
import * as urls from '../../../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class RescheduleRequestService {
  constructor(private _http: HttpClient) {}

  rescheduleRequest(body: ReschedulingRequest) {
    body = JSON.parse(JSON.stringify(classToPlain(body)));
    return new Promise((resolve, reject) => {
      this._http
        .post(urls.environment['rescheduling-request-create'], body)
        .toPromise()
        .then((res) => {
          if (
            res &&
            res['statusCode'] == 200 &&
            res['body'] &&
            res['body']['success'] == true
          ) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((err) => {
          resolve(false);
        });
    });
  }

  getRescheduleRequests(patientId: string, doctorId: string, leadId: string) {
    let ref = firebase
      .firestore()
      .collection('rescheduling_request')
      .orderBy('createdOn', 'desc');
    if (patientId) {
      ref = ref.where('clientId', '==', patientId);
    }
    if (doctorId) {
      ref = ref.where('providerId', '==', doctorId);
    }
    if (leadId) {
      ref = ref.where('leadId', '==', leadId);
    }
    return ref;
  }

  modifyRescheduleRequest(request: ReschedulingRequest) {
    return new Promise((resolve, reject) => {
      let body = JSON.parse(JSON.stringify(classToPlain(request)));
      let updateJson = {
        suggestedDate: body['suggestedDate'],
        suggestedTime: body['suggestedTime'],
        updatedOn: body['updatedOn'],
        updatedById: body['updatedById'],
        updatedByName: body['updatedByName'],
      };
      let _body = {
        requestId: body['id'],
        updateBody: updateJson,
      };
      _body = JSON.parse(JSON.stringify(_body));
      this._http
        .post(urls.environment['rescheduling-request-modify'], _body)
        .toPromise()
        .then((resp) => {
          if (resp && resp['statusCode'] == 200) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((err) => {
          console.log(err);
          resolve(false);
        });
    });
  }

  proceedWithReschedulingFlow(requestId: string) {
    return new Promise((resolve, reject) => {
      let body = {
        requestId,
      };
      this._http
        .post(
          urls.environment['rescheduling-request-proceed-rescheduling'],
          body
        )
        .toPromise()
        .then((res) => {
          if (
            res &&
            res['statusCode'] == 200 &&
            res['body'] &&
            res['body']['success'] == true
          ) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((err) => {
          resolve(false);
        });
    });
  }

  cancelRescheduleRequest(requestItem: ReschedulingRequest) {
    return new Promise((resolve, reject) => {
      let body = {
        requestId: requestItem.id,
      };
      this._http
        .post(urls.environment['rescheduling-request-cancel'], body)
        .toPromise()
        .then((res) => {
          if (
            res &&
            res['statusCode'] == 200 &&
            res['body'] &&
            res['body']['success'] == true
          ) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((err) => {
          resolve(false);
        });
    });
  }
  restoreRescheduleRequest(requestItem: ReschedulingRequest) {
    return new Promise((resolve, reject) => {
      let json = {
        status: 'created',
      };
      this.updateRescheduleRequestJson(requestItem.id, json)
        .then((res) => {
          if (res) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((err) => {
          resolve(false);
        });
    });
  }
  updateRescheduleRequestJson(id: string, jsonVal) {
    return new Promise((resolve, reject) => {
      let body = {
        id,
        jsonVal,
      };
      this._http
        .post(urls.environment['rescheduling-request-update'], body)
        .toPromise()
        .then((res) => {
          if (
            res &&
            res['statusCode'] == 200 &&
            res['body'] &&
            res['body']['success'] == true
          ) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((err) => {
          resolve(false);
        });
    });
  }
}
