import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AppointmentService } from '../../services/appointment/appointment.service';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault('Asia/Kolkata');
interface LeadsConflict {
  leadId: string;
  doctorid: string;
  patientId: string;
  date: string;
  timeslot: string;
}
@Component({
  selector: 'clashing-appointments-modal',
  templateUrl: './clashing-appointments-modal.component.html',
  styleUrls: ['./clashing-appointments-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ClashingAppointmentsModalComponent implements OnInit {
  @Input() showButton: boolean = false;
  @ViewChild('apptClashModal') clashModal;
  @Input() date: string;
  @Input() doctorId: string;
  @Input() timeslot: string;
  @Input() doctorName: string;
  @Input() leadId: string;
  modalRef: BsModalRef;
  conflictingLeads: LeadsConflict[] = [];
  isLoading: boolean = false;
  hasConflicts: boolean;
  loadingEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  hasConflictsEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(
    private modalService: BsModalService,
    private apptService: AppointmentService
  ) {}

  ngOnInit(): void {}

  openModal() {
    this.modalRef = this.modalService.show(
      this.clashModal,
      Object.assign({}, { class: 'custom-modal modal-lg' })
    );
  }
  openModalWithFetch() {
    this.openConflictPromise(
      this.doctorId,
      this.date,
      this.timeslot,
      this.doctorName
    )
      .then((data) => {})
      .catch((err) => {});
    this.openModal();
  }
  closeModal() {
    this.modalRef.hide();
  }

  setDateTimeDoctor(
    doctorId: string,
    date: string,
    timeslot: string,
    doctorName: string
  ) {
    if (!this.date || (this.date && date != this.date)) this.date = date;
    if (!this.doctorId || (this.doctorId && doctorId != this.doctorId))
      this.doctorId = doctorId;
    if (!this.doctorName || (this.doctorName && doctorName != this.doctorName))
      this.doctorName = doctorName;
    if (!this.timeslot || (this.timeslot && timeslot != this.timeslot))
      this.timeslot = timeslot;
  }

  openConflictPromise(
    doctorId: string,
    date: string,
    timeslot: string,
    doctorName: string,
    leadId?: string
  ) {
    return new Promise((resolve, reject) => {
      this.setDateTimeDoctor(doctorId, date, timeslot, doctorName);
      this.conflictingLeads = [];
      this.updateLoadingStatus(true);
      this.apptService
        .checkIfAppointmentConflicting(
          this.doctorId,
          this.date,
          this.timeslot,
          leadId
        )
        .then((data) => {
          if (data) {
            // console.log(data);
            this.conflictingLeads = data['leads'];
            this.updateConflictStatus(data['hasConflicts']);
            if (data['hasConflicts']) {
              resolve(data);
            } else {
              resolve(null);
            }
          } else {
            this.conflictingLeads = [];
            this.updateConflictStatus(false);
            resolve(null);
          }
          this.updateLoadingStatus(false);
        })
        .catch((err) => {
          this.conflictingLeads = [];
          this.updateConflictStatus(false);
          this.updateLoadingStatus(false);
          resolve(null);
        });
    });
  }

  updateConflictStatus(hasConflicts, resolve?) {
    if (hasConflicts) {
      this.hasConflicts = true;
      this.hasConflictsEmitter.emit(this.hasConflicts);
    } else {
      this.hasConflicts = false;
      this.hasConflictsEmitter.emit(this.hasConflicts);
    }
  }

  updateLoadingStatus(load: boolean) {
    this.isLoading = load;
    this.loadingEmitter.emit(this.isLoading);
  }
  displayFormattedDateTime(lead) {
    if (lead && lead['date'] && lead['timeslot']) {
      let _t = lead['timeslot'].split('-');
      let date = dayjs(new Date(lead['date'])).format('DD MMM');
      let stime = dayjs(
        new Date(lead['date'] + ' ' + _t[0])
      ).format('hh:mm a');
      let etime = dayjs(
        new Date(lead['date'] + ' ' + _t[1])
      ).format('hh:mm a');
      return date + ', ' + stime + ' to ' + etime;
    } else {
      return '';
    }
  }
  get selectedDateTime() {
    if (this.timeslot && this.date) {
      let dateTime = this.displayFormattedDateTime({
        date: this.date,
        timeslot: this.timeslot,
      });
      return ' ' + dateTime;
    } else {
      return '';
    }
  }
  gotoLead(lead) {
    if (lead && lead.leadId && lead.date) {
      let url = `${window.location.origin}/patient-bookings/${lead.leadId}?from=${lead.date}&to=${lead.date}`;
      window.open(url, '_blank');
    }
  }
}
