import { Expose } from "class-transformer";

export class PGOrderDetails {
    @Expose() orderId: string;
    @Expose() orderCurrency: string;
    @Expose() orderAmount: number;
    @Expose() addedInPassbookOn: number;
    @Expose() createdOn: number;
    @Expose() customerEmail: string;
    @Expose() customerName: string;
    @Expose() customerPhone: string;
    @Expose() isAddedInPassbook: boolean;
    @Expose() paymentMode: string;
    @Expose() paymentModes: string;
    @Expose() pg: string;
    @Expose() referenceId: string;
    @Expose() returnUrl: string;
    @Expose() signature: string;
    @Expose() txMsg: string;
    @Expose() txStatus: string;
    @Expose() txTime: string;

    isSuccessful() {
        return this.txStatus != null && this.txStatus == "SUCCESS";
    }
}