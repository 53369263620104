<div class="w-100 d-flex bg-white p-2 border" style="font-size: 15px;">
    <ng-container *ngIf="!loader">
        <img src="assets/images/user-icon.png" class="client-image" alt="client-image" [attr.loading]="'lazy'" />
        <div class="d-flex flex-column">
            <div class="d-flex align-items-center mb-1">
                <span class="font-18 fw-500 pe-2 pointer pii-text" (click)="copy(patientProfile.name)">{{
                    patientProfile.name?patientProfile.name:"" }}
                </span>
                <span class="me-1 pointer" style="color: #777" (click)="copy(patientProfile.gender)">
                    {{
                    patientProfile && patientProfile.gender
                    ? "| " + patientProfile.gender
                    : "NA - Gender"
                    }}</span>
        
                <span class="me-1" style="color: #777"> {{ "| " }}</span>
                <button class="btn btn-sm btn-outline-primary border-0 py-1"
                    (click)="openPatientInfo()">
                    View Details
                </button>
                
            </div>
            <div class="d-flex align-items-center">
                <div *ngIf="patientProfile && patientProfile.docvitaId">
                    <div *ngFor="let c of patientProfile.getAllContactNumbers()" class="d-flex align-items-center pii-text"
                        style="font-size: 1em">
                        <span>
                            <span class="pointer" (click)="copy(c.number)">{{ c ? "+" + c.countryCode + "-" +
                                c.number : "" }}
                            </span>
                            <span style="color: #777" *ngIf="!isWingman">
                                {{
                                c.isWhatsappContactNumber ? "(✅ WhatsApp)" : "(No WhatsApp)"
                                }}
                            </span>
                            <span class="pointer" style="color: #777" *ngIf="isWingman"
                                (click)="openChat(c.countryCode + c.number)">
                                {{ c.isWhatsappContactNumber ? "(✅ WA)" : "(No WA)" }}
                            </span>
                        </span>
                        <button class="btn btn-sm btn-outline-primary border-0" tooltip="Open WhatsApp Chat"
                            (click)="openWhatsAppChat(c)">
                            <i class="la la-whatsapp font-18"></i>
                        </button>
                        <button class="btn btn-sm btn-outline-success border-0" *ngIf="currentHeroInfo.hasSpecialAccess" tooltip="Open Personal WhatsApp Chat" (click)="openWhatsAppChat(c,true)">
                            <i class="la la-whatsapp font-18"></i>
                        </button>
                        &nbsp;|&nbsp;
                    </div>
                </div>
                <div class="d-flex align-items-center" *ngIf="patientProfile&&patientProfile.docvitaId">
                    <span class="pointer pii-text"
                        (click)="copy(patientProfile.primaryEmail)">{{
                            patientProfile.primaryEmail
                        ? patientProfile.primaryEmail
                        : "(No Email)"
                        }}</span>
                    <span class="pointer pii-text text-primary ms-1"
                        style="font-size:13px;"
                        (click)="copy(patientProfile.primaryEmail)">
                        Copy Email
                    </span>
                </div>
            </div>
        </div>
    </ng-container>
    <div class="spinner-border text-dark" role="status" *ngIf="loader">
        <span class="visually-hidden">Loading...</span>
    </div>
    <div *ngIf="(!patientProfile||!patientProfile.docvitaId) && !loader">
        🙆🏻‍♂️ No Profile
    </div>
</div>

