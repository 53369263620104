<div class="card rounded p-2 mb-0 mt-3">
    <h6>Appointments on {{displayCurrentDate|date:"dd-MM-yyyy"}}:</h6>
    <div style="max-height: 8rem;overflow-y: scroll;" class="p-1">
        <div class="card p-1 mb-0 mt-1" *ngFor="let appointment of appointmentsList"
            style="font-size: 14px;">
            With {{appointment.patientName}} &#64; {{appointment.timeslot}}
        </div>
        <div *ngIf="appointmentsList.length==0&&!apptLoader" class="alert alert-info" style="font-size: 0.9em!important;">
            💤 No Appointments!
        </div>
        <div class="spinner-border ms-3 mt-3" role="status" *ngIf="apptLoader">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>