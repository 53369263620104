<div class="mb-5">
  <app-navbar (backClicked)="backClicked()" [title]="title"></app-navbar>
</div>

<div class="d-flex">
  <div class="col-md-3">
    <div
      style="overflow-y: scroll; height: calc(100vh - 180px); padding: 0.5rem"
    >
      <div
        class="profile_card mt-3"
        *ngFor="
          let hero of heroes | slice: (page - 1) * pageSize:page * pageSize
        "
        [class.highlighted]="
          this.currentSelectedHero != null &&
          this.currentSelectedHero.docvitaId.length > 0 &&
          hero != null &&
          currentSelectedHero.docvitaId == hero.docvitaId
        "
        style="cursor: pointer"
      >
        <div class="d-flex align-items-start justify-content-between">
          <div
            class="d-flex flex-column flex-fill"
            (click)="onSelectedHeroClicked(hero)"
          >
            <div style="font-size: 14px; font-weight: 700">
              {{ hero.name }}
            </div>
            <div
              style="
                font-size: 12px;
                font-weight: 500;
                word-wrap: break-word;
                line-height: 1;
                margin-top: 8px;
              "
            >
              {{ hero.city }}
            </div>
            <div style="font-size: 12px; font-weight: 500; margin-top: 8px">
              {{ hero.docvitaId }}
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="fetching"
        class="d-flex justify-content-center align-items-center"
        style="height: 300px"
      >
        <div class="spinner-border" role="status">
          <span class="sr-only">Fetching...</span>
        </div>
      </div>
    </div>
    <div
      class="d-flex justify-content-between p-2"
      style="position: sticky; bottom: 0"
    >
      
      <pagination [totalItems]="heroes?.length" [itemsPerPage]="pageSize" [(ngModel)]="page" maxSize="5"></pagination>

      <select style="width: 50%" [(ngModel)]="pageSize" class="custom-select">
        <option [value]="10">10 per page</option>
        <option [value]="20">20 per page</option>
        <option [value]="30">30 per page</option>
      </select>
    </div>
  </div>

  <div
    class="col-md-5"
    *ngIf="currentSelectedHero"
    style="overflow-y: auto; height: calc(100vh - 180px)"
  >
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Basic Info</h5>
        <div>
          {{ currentSelectedHero.name }}
        </div>
        <div>
          {{ currentSelectedHero.getAllContactNumbers()[0]?.fullNumber() }}
          {{ currentSelectedHero.getEmails()[0]?.email }}
        </div>
        <div>
          {{ currentSelectedHero.city }}
        </div>
        <div>
          {{ currentSelectedHero.docvitaId }}
        </div>
      </div>
    </div>
    <div class="card mt-5" *ngIf="currentSelectedHero">
      <div class="card-body">
        <h5 class="card-title">Roles & Permissions</h5>
        <div class="form-check form-switch mt-3">
          <input
            type="checkbox"
            class="form-check-input"
            id="dRecommendation"
            [(ngModel)]="createDoctorProfileOnNewRecommendation"
          />
          <label class="form-check-label ms-2" for="dRecommendation"
            >Create doctor profile for new recommendations
          </label>
        </div>

        <ng-select
          id="roles"
          [multiple]="true"
          [(ngModel)]="selectedRoles"
          name="roles"
          class="mt-3"
        >
          <ng-option *ngFor="let r of roles" [value]="r">{{ r }}</ng-option>
        </ng-select>

        <h5 class="card-title mt-3">Access Levels</h5>
        <div class="d-flex justify-content-between">
          <div class="form-check form-switch w-50">
            <input
              type="checkbox"
              class="form-check-input"
              id="customSwitch1"
              [(ngModel)]="access.canAccessDoctorNetwork"
            />
            <label class="form-check-label ms-2" for="customSwitch1"
              >Can acess doctor network</label
            >
          </div>
          <div class="form-check form-switch w-50">
            <input
              type="checkbox"
              class="form-check-input"
              id="customSwitch2"
              [(ngModel)]="access.canAccessMedicalRegList"
            />
            <label class="form-check-label ms-2" for="customSwitch2"
              >Can access medical registration list</label
            >
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div class="form-check form-switch w-50">
            <input
              type="checkbox"
              class="form-check-input"
              id="customSwitch3"
              [(ngModel)]="access.canAccessPhotoUploadList"
            />
            <label class="form-check-label ms-2" for="customSwitch3"
              >Can access photo upload list</label
            >
          </div>
          <div class="form-check form-switch w-50">
            <input
              type="checkbox"
              class="form-check-input"
              id="customSwitch4"
              [(ngModel)]="access.canAccessBasicInfoList"
            />
            <label class="form-check-label ms-2" for="customSwitch4"
              >Can access basic info list</label
            >
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div class="form-check form-switch w-50">
            <input
              type="checkbox"
              class="form-check-input"
              id="customSwitch5"
              [(ngModel)]="access.canAccessDashboard"
            />
            <label class="form-check-label ms-2" for="customSwitch5"
              >Can access dashboard</label
            >
          </div>
          <div class="form-check form-switch w-50">
            <input
              type="checkbox"
              class="form-check-input"
              id="customSwitch6"
              [(ngModel)]="access.canManageClaims"
            />
            <label class="form-check-label ms-2" for="customSwitch6"
              >Can manage claims</label
            >
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div class="form-check form-switch w-50">
            <input
              type="checkbox"
              class="form-check-input"
              id="customSwitch7"
              [(ngModel)]="access.canAssignProfiles"
            />
            <label class="form-check-label ms-2" for="customSwitch7"
              >Can assign profiles</label
            >
          </div>
          <div class="form-check form-switch w-50">
            <input
              type="checkbox"
              class="form-check-input"
              id="customSwitch8"
              [(ngModel)]="access.canManageCredentials"
            />
            <label class="form-check-label ms-2" for="customSwitch8"
              >Can manage credentials</label
            >
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div class="form-check form-switch w-50">
            <input
              type="checkbox"
              class="form-check-input"
              id="customSwitch9"
              [(ngModel)]="access.canAccessClinicNetwork"
            />
            <label class="form-check-label ms-2" for="customSwitch9"
              >Can acess clinic network</label
            >
          </div>
          <div class="form-check form-switch w-50">
            <input
              type="checkbox"
              class="form-check-input"
              id="customSwitch10"
              [(ngModel)]="access.canAccessHospitalNetwork"
            />
            <label class="form-check-label ms-2" for="customSwitch10"
              >Can acess hospital network</label
            >
          </div>
        </div>

        <button
          class="btn btn-sm btn-info mt-3 float-right"
          (click)="onUpdatePermissions()"
        >
          Update
        </button>
      </div>
    </div>
  </div>

  <div class="col-md-4" *ngIf="currentSelectedHero">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Stats</h4>
        <!-- <form class="form-inline">
          <div class="form-group hidden">
            <div class="input-group">
              <input
                name="datepicker"
                class="form-control"
                ngbDatepicker
                #datepicker="ngbDatepicker"
                [autoClose]="'outside'"
                (dateSelect)="onDateSelection($event)"
                [displayMonths]="2"
                [dayTemplate]="t"
                outsideDays="hidden"
                [startDate]="fromDate!"
                tabindex="-1"
              />
              <ng-template #t let-date let-focused="focused">
                <span
                  class="custom-day"
                  [class.focused]="focused"
                  [class.range]="isRange(date)"
                  [class.faded]="isHovered(date) || isInside(date)"
                  (mouseenter)="hoveredDate = date"
                  (mouseleave)="hoveredDate = null"
                >
                  {{ date.day }}
                </span>
              </ng-template>
            </div>
          </div>
          <div class="form-group">
            <div class="input-group">
              <input
                #dpFromDate
                class="form-control"
                placeholder="yyyy-mm-dd"
                name="dpFromDate"
                [value]="formatter.format(fromDate)"
                (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar_today"
                  (click)="datepicker.toggle()"
                  type="button"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-calendar-date"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M6.445 11.688V6.354h-.633A12.6 12.6 0 0 0 4.5 7.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61h.675zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82h-.684zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23z"
                    />
                    <path
                      d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="form-group ms-2">
            <div class="input-group">
              <input
                #dpToDate
                class="form-control"
                placeholder="yyyy-mm-dd"
                name="dpToDate"
                [value]="formatter.format(toDate)"
                (input)="toDate = validateInput(toDate, dpToDate.value)"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary"
                  (click)="datepicker.toggle()"
                  type="button"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-calendar-date"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M6.445 11.688V6.354h-.633A12.6 12.6 0 0 0 4.5 7.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61h.675zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82h-.684zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23z"
                    />
                    <path
                      d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </form> -->
        <span class="text-muted float-right" style="font-size: 14px"
          >Note: Dates are inclusive</span
        >
        <div id="stats" class="mt-4">
          <div *ngFor="let item of currentSelectedHeroStats | keyvalue">
            <b>{{ item.key }}</b
            >: <b>{{ item.value }}</b>
          </div>
          <div *ngIf="(currentSelectedHeroStats | json) == '{}' && !fetchStats">
            No stats to show
          </div>
        </div>

        <div
          *ngIf="fetchStats"
          class="d-flex justify-content-center align-items-center"
          style="height: 100px"
        >
          <div class="spinner-border" role="status">
            <span class="sr-only">Fetching...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
