import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { GetTokenService } from './get-token.service';

@Injectable({
  providedIn: 'root'
})
export class HttpTokenInterceptorService implements HttpInterceptor {
  constructor(public _getTokenService: GetTokenService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this._getTokenService.token()).pipe(
      switchMap(token => {
        let headersConfig = {};
        // console.log(req.headers);
        if (req.headers == null || req.headers["Content-Type"] == null) {
          headersConfig = {
            "Content-Type": "application/json",
            Accept: "application/json",
          };
        }

        // console.log("skipToken", req.headers.get("skipToken"),req.headers)
        if ((!req.headers.has("skipToken") || req.headers.get("skipToken") == "false")
          && token && req.headers["Authorization"] == null) {
          headersConfig["Authorization"] = "bearer " + token;
        }
        headersConfig["platform"] = "web";
        const request = req.clone({ setHeaders: headersConfig });
        return next.handle(request);
      })
    );
  }
}
