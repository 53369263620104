import { Expose } from "class-transformer";
import {
  AppointmentInviteType,
  AppointmentInviteStatus,
  ServiceTypes,
  AppointmentType,
} from "../../Enums/Enums";
import { Currency } from "../../Helpers/countrywiseFeeCalc";
import { AppointmentInviteAction } from "./AppointmentInviteAction";
import { DayjsHelper } from "../../Helpers/dayjsHelper";
export class AppointmentInvite {
  @Expose() id: string;
  @Expose() type: AppointmentInviteType; //exact implementation is pending
  @Expose() status: AppointmentInviteStatus;
  @Expose() clientId: string;
  @Expose() providerId: string;
  @Expose() doctorName: string;
  @Expose() patientName: string;
  @Expose() inviteDate: string;
  @Expose() inviteTime: string;
  @Expose() createdOn: number;
  @Expose() createdById: string;
  @Expose() createdByName: string;
  @Expose() updatedOn: number;
  @Expose() duration: number; //In mins
  @Expose() amount: number;
  @Expose() currency: Currency;
  @Expose() updatedById: string;
  @Expose() updatedByName: string;
  @Expose() actions: AppointmentInviteAction[];
  @Expose() expiresOn: number;
  @Expose() providerDisplayAmount: number;
  @Expose() providerDisplayCurrency: Currency;
  @Expose() practiceId: string;
  @Expose() practiceName: string;
  @Expose() sessionType: ServiceTypes;
  @Expose() appointmentType: AppointmentType;
  @Expose() scheduledForUnix: number;

  get scheduleDateTime() {
    return new DayjsHelper('Asia/Kolkata').convertUnixToISTDateTime(this.scheduledForUnix)
  }

  setScheduleForUnix(yyyymmdd: string, HHmm: string) {
    this.scheduledForUnix = new DayjsHelper('Asia/Kolkata').convertISTtoUnix(yyyymmdd, HHmm);
  }
}
