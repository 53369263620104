import { Component, Input, OnInit } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ContactNumber } from '../../models-ts/Actors/Person/ContactNumber';
import { CRMUser } from '../../models-ts/Actors/User/CRMUser';
import { User } from '../../models-ts/Actors/User/User';
import { AuthUserInfoService } from '../../services/auth-user-info/auth-user-info.service';
import { CopyClipboardService } from '../../services/copy-clipboard/copy-clipboard.service';
import { PatientService } from '../../services/patient/patient.service';
import { PatientInfoComponent } from '../patient-info/patient-info.component';

@Component({
  selector: 'client-info-card',
  templateUrl: './client-info-card.component.html',
  styleUrls: ['./client-info-card.component.scss']
})
export class ClientInfoCardComponent implements OnInit {

  @Input() set clientId(val:string){
    if(val){
      this.getClientDetails(val);
    }
  }
  @Input() patientProfile:User = new User();
  loader:boolean = false;
  currentHeroInfo: CRMUser = new CRMUser();
  constructor(
    private patientService:PatientService,
    private modalService:BsModalService,
    private _copyToClipboardService:CopyClipboardService,
    private _authUserInfoService:AuthUserInfoService
    ) { }

  ngOnInit(): void {
    this.currentHeroInfo = this._authUserInfoService.getUserInfo();
  }

  getClientDetails(id:string){
    this.loader = true;
    this.patientService
      .getPatientById(id)
      .then((snap) => {
        if (snap.exists) {
          this.patientProfile = plainToClass(User, snap.data());
          console.log(this.patientProfile)
        } else {
          this.patientProfile = new User();
        }
        this.loader = false;
      })
      .catch((err) => {
        this.patientProfile = new User();
        this.loader = false;
      });
  }

  openPatientInfo() {
    let modalRef = this.modalService.show(PatientInfoComponent, {
      initialState: { clientId: this.patientProfile.docvitaId },
      class: 'modal-lg',
    });
    modalRef.content.clientId = this.patientProfile.docvitaId;
    modalRef.content.close.subscribe(()=>{
      modalRef.hide();
    })
  }

  openWhatsAppChat(c: ContactNumber,wpIntent?:boolean) {
    if(wpIntent){
      let _url = `https://wa.me/${c.countryCode}${c.number}`
      window.open(_url,'_blank');
    }else{
      window.open(
        location.origin + '/chats/whatsapp' + c.countryCode + c.number,
        '_blank'
      );
    }
    
  }

  copy(message: string) {
    if(message){
      this._copyToClipboardService.copyToClipoard(message);
    }
  }

}
