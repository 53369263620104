import { Component, OnInit, ViewChildren, ViewChild } from '@angular/core';
//services
import { DoctorsService } from '../../shared/services/doctors/doctors.service';
import { DoctorListingProfile } from '../../shared/models-ts/Actors/Doctor/DoctorListingProfile';
import { plainToClass } from 'class-transformer';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault('Asia/Kolkata');
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterModule,
} from '@angular/router';
import { DoctorDataService } from 'src/app/shared/services/doctor-data/doctor-data.service';
import {
  city,
  specializations,
  languages,
} from 'src/app/shared/constants/constants';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import firebase from 'firebase';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { AuthUserInfoService } from 'src/app/shared/services/auth-user-info/auth-user-info.service';
import { AssignProfile } from 'src/app/shared/models-ts/Entities/AssignProfile';
import { EmailsComponent } from '../form-control/emails/emails.component';
import { ContactNumbersComponent } from '../form-control/contact-numbers/contact-numbers.component';
import { ContactNumber } from 'src/app/shared/models-ts/Actors/Person/ContactNumber';
import { TitleMetaService } from 'src/app/services/title-meta.service';
declare var $: any;
@Component({
  selector: 'app-doc-profile-listing',
  templateUrl: './doc-profile-listing.component.html',
  styleUrls: ['./doc-profile-listing.component.css'],
})
export class DocProfileListingComponent implements OnInit {
  title: string = 'Doctor Network';
  tabState: string = 'basicInfo';
  listingProfiles: DoctorListingProfile[] = [];
  selectedDoctor: DoctorListingProfile;
  heroId: string = '';
  currentHeroData: any;
  currentHeroInfo: any;
  idInvalid: boolean = false;
  listingListener: any;
  defaultLogoUrl: string =
    'https://default-docvita-images.s3.ap-south-1.amazonaws.com/no-image-available.jpg';
  photoUrl: any;
  id: string;
  city = city;
  specialization = specializations;
  languages = languages;
  searchText: string = '';
  selectedCity: string = '';
  selectedSpecialization: string = '';
  listingProfilesCopy: DoctorListingProfile[] = [];
  isNewDoctor: boolean = false;
  newDoctorForm: DoctorListingProfile = new DoctorListingProfile();

  extension: any;
  logoUploaded: boolean = false;
  newRegPhotoUrl: any;
  page: number = 0;
  pageSize: number = 10;
  newFilter: any = {
    photoUrl: false,
    medicalRegistrationVerified: false,
    showInListing: false,
    publicContactNumber: false,
    limit: 100,
  };
  photoUrlExists: string;
  isLoading: boolean = false;
  filterMetadata = { count: 0 };

  tablist = {
    "basicInfo": 'info-tab',
    "education": 'education-tab',
    "practices": 'practices-tab',
    "schedule": 'schedule-tab',
    "claim": 'claim-tab',
    "wallet": 'wallet-tab',
    "bank": 'bank-tab',
    "payments": 'payments-tab',
    "appointments": 'appointment-tab',
    "slots": 'slots-tab',
    "module-config": 'module-config-tab',
    "fee-config": 'fee-config-tab'
  };
  doctorsIndex: number = 0;

  constructor(
    private _doctorService: DoctorsService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _doctorDataService: DoctorDataService,
    private _authUserInfoService: AuthUserInfoService,
    private _spinner: NgxSpinnerService,
    private _toastr: ToastrService,
    private _titleMetaService: TitleMetaService
  ) {
    this._titleMetaService.setTitleDescription("Provider Profiles", "");
  }

  ngOnInit(): void {
    this.getTabState();
    if (this._activatedRoute.snapshot.firstChild) {
      this.id = this._activatedRoute.snapshot.firstChild.params['id'];
      // this.getExistingFilters();
    } else {
      // this.getExistingFilters();
    }
    this.currentHeroInfo = this._authUserInfoService.getUserInfo();
  }

  logoutCurrentUser() {
    this._authUserInfoService.signout();
  }

  openNewUserRegistration() {
    this.newRegPhotoUrl = null;
    this.logoUploaded = false;
    this.newDoctorForm = new DoctorListingProfile();
    let id = firebase.database().ref().push().key;
    this.newDoctorForm.id = id;
    this.newDoctorForm.type = 'doctor';
    this.newDoctorForm['createdOn'] = new Date().getTime();
    $('#registerUserModal').modal('show');
  }

  updatePhotoChanges(e) {
    this.photoUrl = e.photoUrl;
    this.extension = e.extension;
    this.logoUploaded = true;
  }

  updateNewDoctor() {
    if (
      this.newDoctorForm['name'] &&
      this.newDoctorForm['name'].length > 0 &&
      this.newDoctorForm['salutation'] &&
      this.newDoctorForm['city']
    ) {
      this.newDoctorForm['city'] = this.newDoctorForm['city']['label']
        ? this.newDoctorForm['city']['label']
        : this.newDoctorForm['city'];
      if (this.newDoctorForm['specializations']) {
        for (let i = 0; i < this.newDoctorForm['specializations'].length; i++) {
          this.newDoctorForm['specializations'][i] = this.newDoctorForm[
            'specializations'
          ][i]['label']
            ? this.newDoctorForm['specializations'][i]['label']
            : this.newDoctorForm['specializations'][i];
        }
      }
      if (this.newDoctorForm['languages']) {
        for (let i = 0; i < this.newDoctorForm['languages'].length; i++) {
          this.newDoctorForm['languages'][i] = this.newDoctorForm['languages'][
            i
          ]['label']
            ? this.newDoctorForm['languages'][i]['label']
            : this.newDoctorForm['languages'][i];
        }
      }

      // if (
      //   this.newDoctorForm.primaryContactNumber &&
      //   this.newDoctorForm.primaryContactNumber.number
      // ) {
      //   this.newDoctorForm.primaryContactNumber['countryCode'] = '91';
      //   this.newDoctorForm.primaryContactNumber.number = this.newDoctorForm.primaryContactNumber.number.toString();
      // }
      // if (
      //   this.newDoctorForm.publicContactNumber &&
      //   this.newDoctorForm.publicContactNumber.number
      // ) {
      //   this.newDoctorForm.publicContactNumber['countryCode'] = '91';
      //   this.newDoctorForm.publicContactNumber.number = this.newDoctorForm.publicContactNumber.number.toString();
      // }

      // if (
      //   this.newDoctorForm.whatsappContactNumber &&
      //   this.newDoctorForm.whatsappContactNumber.number
      // ) {
      //   this.newDoctorForm.whatsappContactNumber['countryCode'] = '91';
      //   this.newDoctorForm.whatsappContactNumber.number = this.newDoctorForm.whatsappContactNumber.number.toString();
      // }

      if (this.newDoctorForm.experienceInYears) {
        this.newDoctorForm['experienceInYears'] =
          this.newDoctorForm.experienceInYears + '';
      }

      this.newDoctorForm['uniqueTag'] = this.newDoctorForm.id;
      this.newDoctorForm['createdOn'] = new Date().getTime();
      this.newDoctorForm['isProfileClaimed'] = false;
      this.newDoctorForm['isMedicalRegistrationVerified'] = false;
      this.newDoctorForm['showInListing'] = false;
      this.newDoctorForm['id'] = this.newDoctorForm.id;
      this.newDoctorForm['profileSource'] =
        'created new profile by docvita team';
      let assignProfileDefaults = new AssignProfile();
      this.newDoctorForm.assignProfile = new Map<string, AssignProfile>();
      this.newDoctorForm['assignProfile'].set(
        'photoUpload',
        assignProfileDefaults
      );
      this.newDoctorForm['assignProfile'].set(
        'medicalRegistrationVerification',
        assignProfileDefaults
      );
      this.newDoctorForm['assignProfile'].set(
        'basicContactInfo',
        assignProfileDefaults
      );
      let id = this.newDoctorForm.id;
      let doctor = this.newDoctorForm;
      this._doctorService
        .updateBasicInformation(this.newDoctorForm, id)
        .then((res) => {
          if (this.logoUploaded) {
            this._doctorService
              .uploadPhoto(this.newRegPhotoUrl, id, this.extension)
              .subscribe((data) => {
                this.updateUserStats(
                  this.currentHeroInfo.docvitaId,
                  this.id,
                  'photo'
                );
                $('#registerUserModal').modal('hide');
                this.newRegPhotoUrl = null;
                this._toastr.success('New Doctor Added!');
                this._toastr.success('Photo uploaded!');
                // this.id = id;
                // this._doctorDataService.updateCurrentDoctor(doctor);
                // this.selectedDoctor = doctor;

                // if (
                //   this.selectedDoctor != null &&
                //   this.selectedDoctor.id != null
                // ) {
                //   this._router.navigate([
                //     `profile-listing/${this.selectedDoctor.id}/basicInfo`,
                //   ]);
                // }
                this.onSelectedDoctorClicked(doctor);
              });
          } else {
            this._toastr.success('New Doctor Added!');
            $('#registerUserModal').modal('hide');
            this.onSelectedDoctorClicked(doctor);
          }
          this.updateUserStats(
            this.currentHeroInfo.docvitaId,
            this.id,
            'create'
          );
        })
        .catch((err) => console.log(err));
    } else {
      this._toastr.warning('Incomplete parameters!');
    }
  }

  autoFillAbout() {
    if (
      this.newDoctorForm.salutation &&
      this.newDoctorForm.name &&
      this.newDoctorForm.experienceInYears
    ) {
      let about =
        this.newDoctorForm.salutation +
        ' ' +
        this.newDoctorForm.name +
        ' is a highly qualified and experienced doctor with an overall experience of ' +
        this.newDoctorForm.experienceInYears +
        ' years.';

      this.newDoctorForm.about = about;
    } else {
      this._toastr.error(
        'Salutation, Name and Exp. in years required, for auto to work.'
      );
    }
  }

  getTabState() {
    let url = this._router.url;
    let state = url.split('/')[3];
    if (state && state.includes('?')) {
      state = state.split('?')[0].trim();
    }
    if (state) {
      this.tabState = state;
      // $(`#${this.tablist[this.tabState]}`).tab('show');
    }
  }

  checkHeroIfExists() {
    if (this.heroId.length > 0) {
      this._doctorService
        .getHeroProfile(this.heroId)
        .then((doc) => {
          this.currentHeroData = doc.data();
          if (
            this.currentHeroData &&
            this.currentHeroData['healthHeroInfo'] &&
            this.currentHeroData['healthHeroInfo']['isEnrolled']
          ) {
            this.idInvalid = false;
          } else {
            this.idInvalid = true;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  getSpecialisation(specialisations) {
    if (specialisations != null && specialisations.length > 0) {
      let result = '';
      for (let i = 0; i < specialisations.length; i++) {
        let s = specialisations[i];
        if (s && s.length > 0) {
          s = s.charAt(0).toUpperCase() + s.slice(1);
          s = s.replace('-', ' ');
          result += s;
          if (i != specialisations.length - 1) {
            result += ', ';
          }
        }
      }
      return result;
    }
  }

  ifPhoto(doctor) {
    if (doctor['photoUrl']) {
      return true;
    } else {
      return false;
    }
  }

  formatCreateOn(timestamp) {
    return dayjs(timestamp).format('LLL');
  }

  onSelectedDoctorClicked(
    doctor: DoctorListingProfile,
    index?: number,
    setFocus?: boolean
  ) {
    // if (!setFocus) {
    //   document.getElementById('track-keyup').focus();
    //   this.doctorsIndex = index;
    // }

    if (doctor) {
      // console.log("onSelectedDoctorClicked "+doctor.fullName())
      this.isNewDoctor = false;
      this.selectedDoctor = doctor;
      this.id = this.selectedDoctor.id;
      if (this.selectedDoctor != null && this.id != null) {
        this._doctorDataService.updateCurrentDoctor(this.selectedDoctor);
        if (this.tabState == null || this.tabState.length == 0) {
          this.tabState = 'basicInfo';
        }
        this._router.navigate(
          ['../../../' + `profile-listing/${this.id}/${this.tabState}`],
          { queryParamsHandling: 'preserve' }
        );
        // $(`#${this.tablist[this.tabState]}`).tab('show');
      }
    } else {
      this._toastr.warning('Please select the profile again');
    }
  }

  routerToPreview() {
    if (this.selectedDoctor != null && this.selectedDoctor.id != null) {
      this._router.navigate(
        [`profile-listing/${this.selectedDoctor.id}/preview`],
        { queryParamsHandling: 'preserve' }
      );
      this.tabState = 'preview';
    }
  }

  routerToClaim() {
    if (this.selectedDoctor != null && this.selectedDoctor.id != null) {
      this._router.navigate(
        [`profile-listing/${this.selectedDoctor.id}/claim`],
        { queryParamsHandling: 'preserve' }
      );
      this.tabState = 'claim';
    }
  }

  routerToWallet() {
    if (this.selectedDoctor != null && this.selectedDoctor.id != null) {
      this._router.navigate(
        [`profile-listing/${this.selectedDoctor.id}/wallet`],
        { queryParamsHandling: 'preserve' }
      );
      this.tabState = 'wallet';
    }
  }

  routerToMembershipPlans() {
    if (this.selectedDoctor != null && this.selectedDoctor.id != null) {
      this._router.navigate(
        [`profile-listing/${this.selectedDoctor.id}/membership-plans`],
        { queryParamsHandling: 'preserve' }
      );
      this.tabState = 'membership-plans';
    }
  }

  routerToTransactions() {
    if (this.selectedDoctor != null && this.selectedDoctor.id != null) {
      this._router.navigate(
        [`profile-listing/${this.selectedDoctor.id}/transactions`],
        { queryParamsHandling: 'preserve' }
      );
      this.tabState = 'transactions';
    }
  }

  routerToPayments() {
    if (this.selectedDoctor != null && this.selectedDoctor.id != null) {
      this._router.navigate(
        [`profile-listing/${this.selectedDoctor.id}/payments`],
        { queryParamsHandling: 'preserve' }
      );
      this.tabState = 'payments';
    }
  }

  routerToNewSlots() {
    if (this.selectedDoctor != null && this.selectedDoctor.id != null) {
      this._router.navigate(
        [`profile-listing/${this.selectedDoctor.id}/schedule-v2`],
        { queryParamsHandling: 'preserve' }
      );
      this.tabState = 'schedule-v2';
    }
  }
  routerToDVOneUIForSlots() {
    if (this.selectedDoctor != null && this.selectedDoctor.id != null) {
      this._router.navigate(
        [`profile-listing/${this.selectedDoctor.id}/schedule-v3`],
        { queryParamsHandling: 'preserve' }
      );
      this.tabState = 'schedule-v3';
    }
  }

  routerToBasicInfo() {
    if (this.selectedDoctor != null && this.selectedDoctor.id != null) {
      this._router.navigate(
        [`profile-listing/${this.selectedDoctor.id}/basicInfo`],
        { queryParamsHandling: 'preserve' }
      );
      this.tabState = 'basicInfo';
    }
  }
  routerToEducation() {
    if (this.selectedDoctor != null && this.selectedDoctor.id != null) {
      this._router.navigate(
        [`profile-listing/${this.selectedDoctor.id}/education`],
        { queryParamsHandling: 'preserve' }
      );
      this.tabState = 'education';
    }
  }
  routerToSchedule() {
    if (this.selectedDoctor != null && this.selectedDoctor.id != null) {
      this._router.navigate(
        [`profile-listing/${this.selectedDoctor.id}/schedule`],
        { queryParamsHandling: 'preserve' }
      );
      this.tabState = 'schedule';
    }
  }

  routerToPractices() {
    if (this.selectedDoctor != null && this.selectedDoctor.id != null) {
      this._router.navigate(
        [`profile-listing/${this.selectedDoctor.id}/practices`],
        { queryParamsHandling: 'preserve' }
      );
      this.tabState = 'practices';
    }
  }

  routeToCredentials() {
    if (this.selectedDoctor != null && this.selectedDoctor.id != null) {
      this._router.navigate(
        [`profile-listing/${this.selectedDoctor.id}/manage-credentials`],
        { queryParamsHandling: 'preserve' }
      );
      this.tabState = 'manage-credentials';
    }
  }

  routeToBankDetails() {
    if (this.selectedDoctor != null && this.selectedDoctor.id != null) {
      this._router.navigate(
        [`profile-listing/${this.selectedDoctor.id}/link-bank`],
        { queryParamsHandling: 'preserve' }
      );
      this.tabState = 'link-bank';
    }
  }

  routerToAppointments() {
    if (this.selectedDoctor != null && this.selectedDoctor.id != null) {
      this._router.navigate(
        [`profile-listing/${this.selectedDoctor.id}/appointments`],
        { queryParamsHandling: 'preserve' }
      );
      this.tabState = 'appointments';
    }
  }

  routerToSlots() {
    if (this.selectedDoctor != null && this.selectedDoctor.id != null) {
      this._router.navigate(
        [`profile-listing/${this.selectedDoctor.id}/slots`],
        { queryParamsHandling: 'preserve' }
      );
      this.tabState = 'slots';
    }
  }

  routerToModuleConfig() {
    if (this.selectedDoctor != null && this.selectedDoctor.id != null) {
      this._router.navigate(
        [`profile-listing/${this.selectedDoctor.id}/module-config`],
        { queryParamsHandling: 'preserve' }
      );
      this.tabState = 'module-config';
    }
  }

  routerToFeeConfig() {
    if (this.selectedDoctor != null && this.selectedDoctor.id != null) {
      this._router.navigate(
        [`profile-listing/${this.selectedDoctor.id}/fee-config`],
        { queryParamsHandling: 'preserve' }
      );
      this.tabState = 'fee-config';
    }
  }

  // fileProgress(fileInput: any) {
  //   let imageData;
  //   imageData = <File>fileInput.target.files[0];
  //   fileInput.target.value = null;
  //   this.extension = imageData['name'].match(/\.[0-9a-z]+$/i)[0];
  //   var mimeType = imageData.type;
  //   if (mimeType.match(/image\/*/) == null) {
  //     return;
  //   }

  //   var reader = new FileReader();
  //   reader.readAsDataURL(imageData);
  //   reader.onload = (_event) => {
  //     this.newRegPhotoUrl = reader.result;
  //     this.logoUploaded = true;
  //   };
  // }

  fileProgress(fileInput: any) {
    let self = this;
    let imageData;
    imageData = <File>fileInput.target.files[0];
    // this.extension = self.photoUrl.match(/\.[0-9a-z]+$/i);
    var mimeType = imageData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var myCanvas = document.createElement('canvas');
    var ctx = myCanvas.getContext('2d');
    var img = new Image();
    img.onload = function () {
      myCanvas.width = img.width;
      myCanvas.height = img.height;

      ctx.drawImage(img, 0, 0);

      self.newRegPhotoUrl = myCanvas.toDataURL('image/jpeg');
      self.extension =
        '.' +
        self.newRegPhotoUrl.substring(
          self.newRegPhotoUrl.indexOf('/') + 1,
          self.newRegPhotoUrl.indexOf(';base64')
        );
      self.logoUploaded = true;
    };

    img.src = URL.createObjectURL(fileInput.target.files[0]);
    fileInput.target.value = null;
  }

  updateUserStats(heroId, docId, reqType) {
    this._doctorService
      .updateDocvitaUserStats(heroId, docId, reqType)
      .subscribe((data) => {
        if (data['statusCode'] == 200) {
          console.log('User stats updated!');
        } else {
          console.log('User stats did not update!');
        }
      });
  }

  backClicked() {
    this._router.navigate(['../'], { relativeTo: this._activatedRoute });
  }

  isLoadingChanged(event) {
    this.isLoading = event;
  }

  ngOnDestroy(): void {
    if (this.listingListener) {
      this.listingListener();
    }
  }

  navigateDoctors(event) {
    switch (event.key) {
      case 'ArrowUp': {
        console.log('doctor selected');
        if (this.doctorsIndex == 0) {
          this.doctorsIndex = this.listingProfiles.length - 1;
          const doctor = this.listingProfiles[this.doctorsIndex];
          console.log(doctor);
          this.onSelectedDoctorClicked(doctor, null, true);
        } else {
          this.doctorsIndex = this.doctorsIndex - 1;
          const doctor = this.listingProfiles[this.doctorsIndex];
          this.onSelectedDoctorClicked(doctor, null, true);
          console.log(doctor);
        }
        break;
      }
      case 'ArrowDown': {
        console.log('doctor selected downnnn');
        if (this.doctorsIndex == this.listingProfiles.length - 1) {
          this.doctorsIndex = 0;
          const doctor = this.listingProfiles[this.doctorsIndex];
          this.onSelectedDoctorClicked(doctor, null, true);
        } else {
          this.doctorsIndex = this.doctorsIndex + 1;
          const doctor = this.listingProfiles[this.doctorsIndex];
          this.onSelectedDoctorClicked(doctor, null, true);
        }
        break;
      }
      default: {
      }
    }
    // const doctor = this.listingProfiles[this.doctorsIndex];
    // this.onSelectedDoctorClicked(doctor, null, true);
  }
}
