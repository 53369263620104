import { Injectable } from '@angular/core';
import { classToPlain, plainToClass } from 'class-transformer';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/database';
import { MembershipPlan } from "../../shared/models-ts/Entities/MembershipPlan/MembershipPlan";
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ServiceTypes } from 'src/app/shared/models-ts/Enums/Enums';
@Injectable()
export class MembershipPlansService {

  constructor(
    private _http: HttpClient
  ) { }

  async fetchPlans(providerId: string) {
    try {
      const membershipPlans: MembershipPlan[] = [];
      const snap = await firebase.firestore().collection("membership_plans").where("providerId", "==", providerId).get();
      if (!snap.empty) {
        snap.forEach(doc=>{
          if(doc.exists) {
            const m = plainToClass(MembershipPlan, doc.data());
            membershipPlans.push(m);
          }
        })
      }
      membershipPlans.sort((a,b)=>{
        return a.noOfSessions - b.noOfSessions;
      })
      return membershipPlans;
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  async createOrUpdatePlan(providerId: string, plan: MembershipPlan) {
    try {
      if(plan.providerId == null) {
        plan.providerId = providerId;
      }
      if(plan.createdOn == null) {
        plan.createdOn = new Date().getTime();
      } 
      plan.updatedOn = new Date().getTime();
      if(plan.id == null) {
        plan.id = firebase.database().ref().push().key;
        const json = classToPlain(plan);
        await firebase.firestore().collection("membership_plans").doc(plan.id).set(
          JSON.parse(JSON.stringify(json)), {merge: true}
        );
      } else {
        const json = classToPlain(plan);
        await firebase.firestore().collection("membership_plans").doc(plan.id).update(
          JSON.parse(JSON.stringify(json))
        );
      }
      return plan;
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  async generatePlan(providerId: string,
    multiplier: number, 
    isMostPopular: boolean, 
    numberOfSessions: number, 
    type: ServiceTypes,
    validityInDays: number
    ) {
    const url = environment['generate-membership-plan'];
    let body = {
      isMostPopular: isMostPopular,
      providerId: providerId,
      multiplier: multiplier,
      numberOfSessions: numberOfSessions,
      validityInDays: validityInDays,
      type: type
    }
    await this._http.post(url,body).toPromise();
    return;
  }
  
}
