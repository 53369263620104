export const reasonOfVisitSuggestions = [
	{
		title: "Marriage Counselling",
		urlPath: "marriage-counselling",
		reason: ["Marriage Counselling"],
		primaryCategory: "counsellors",
	},
	{
		title: "Anxiety, Stress, Depression Therapy",
		urlPath: "anxiety-stress-depression-therapy",
		reason: ["Anxiety", "Stress", "Depression"],
		primaryCategory: "counsellors",
	},
	{
		title: "Depression Treatment",
		urlPath: "depression-treatment",
		reason: ["Depression"],
		primaryCategory: "psychiatrists",
	},
	{
		title: "Adjustment Disorder Treatment",
		urlPath: "adjustment-disorder-treatment",
		reason: ["Adjustment Disorder"],
		primaryCategory: "psychiatrists",
	},
	{
		title: "Anger Management Treatment",
		urlPath: "anger-management-treatment",
		reason: ["Anger Management"],
		primaryCategory: "psychiatrists",
	},
	{
		title: "Anxiety Disorders Treatment",
		urlPath: "anxiety-disorders-treatment",
		reason: ["Anxiety Disorders"],
		primaryCategory: "psychiatrists",
	},
	{
		title: "Family Therapy",
		urlPath: "family-therapy",
		reason: ["Family Therapy"],
		primaryCategory: "psychologists",
	},
	{
		title: "Relationship Counselling",
		urlPath: "relationship-counselling",
		reason: ["Relationship Counselling"],
		primaryCategory: "psychologists",
	},
	{
		title: "Premarital Counselling",
		urlPath: "premarital-counselling",
		reason: ["Premarital Counselling"],
		primaryCategory: "psychologists",
	},
	{
		title: "Couples Counselling",
		urlPath: "couples-counselling",
		reason: ["Couples Counselling"],
		primaryCategory: "psychologists",
	},
	{
		title: "Child Counselling",
		urlPath: "child-counselling",
		reason: ["Child Counselling"],
		primaryCategory: "psychologists",
	},
	{
		title: "Online Therapy",
		urlPath: "online-therapy",
		reason: ["Online Therapy"],
		primaryCategory: "psychologists",
	},
	{
		title: "Art Therapy",
		urlPath: "art-therapy",
		reason: ["Art Therapy"],
		primaryCategory: "psychologists",
	},
	{
		title: "Online Counselling",
		urlPath: "online-counselling",
		reason: ["Online Counselling"],
		primaryCategory: "psychologists",
	},
	{
		title: "Anger Management Therapy",
		urlPath: "anger-management-therapy",
		reason: ["Anger Management"],
		primaryCategory: "psychologists",
	},
	{
		title: "Cognitive Behavioral Therapy",
		urlPath: "cognitive-behavioral-therapy",
		reason: ["Cognitive Behavioral Therapy"],
		primaryCategory: "psychologists",
	},
	{
		title: "Dialectical Behavior Therapy",
		urlPath: "dialectical-behavior-therapy",
		reason: ["Dialectical Behavior Therapy (DBT)"],
		primaryCategory: "psychologists",
	},
	{
		title: "EMDR Therapy",
		urlPath: "emdr-therapy",
		reason: ["Eye Movement Desensitization and Reprocessing (EMDR) Therapy"],
		primaryCategory: "psychologists",
	},
	{
		title: "IFS Therapy",
		urlPath: "ifs-therapy",
		reason: ["Internal Family Systems (IFS) Therapy"],
		primaryCategory: "psychologists",
	},
	{
		title: "Grief Counselling",
		urlPath: "grief-counselling",
		reason: ["Bereavement/Grief Counselling"],
		primaryCategory: "psychologists",
	},
	{
		title: "Adolescent/Teen Counselling",
		urlPath: "adolescent-teen-counselling",
		reason: ["Adolescent/Teen Issues"],
		primaryCategory: "psychologists",
	},
	{
		title: "OCD Treatment",
		urlPath: "ocd-treatment",
		reason: ["Obsessive Complusive Disorder (OCD)"],
		primaryCategory: "specialists",
	},
	{
		title: "OCD Therapy",
		urlPath: "ocd-therapy",
		reason: ["Obsessive Complusive Disorder (OCD)"],
		primaryCategory: "psychologists",
	},
	{
		title: "ADHD Treatment",
		urlPath: "adhd-treatment",
		reason: ["Attention Deficit Hyperactivity Disorder (ADHD)"],
		primaryCategory: "psychiatrists",
	},
	{
		title: "Bipolar Disorder Treatment",
		urlPath: "bipolar-disorder-treatment",
		reason: ["Bipolar Disorder"],
		primaryCategory: "psychiatrists",
	},
	{
		title: "Sleep Disorder Treatment",
		urlPath: "sleep-disorder-treatment",
		reason: ["Sleep Disorder"],
		primaryCategory: "psychiatrists",
	},
	{
		title: "PTSD Treatment",
		urlPath: "ptsd-treatment",
		reason: ["Post-Traumatic Stress Disorder (PTSD)"],
		primaryCategory: "psychiatrists",
	},
	{
		title: "PTSD Therapy",
		urlPath: "ptsd-therapy",
		reason: ["Post-Traumatic Stress Disorder (PTSD) Therapy"],
		primaryCategory: "psychologists",
	},
	{
		title: "Neuro-Linguistic Programming Therapy",
		urlPath: "nlp-therapy",
		reason: ["Neuro-Linguistic Programming Therapy"],
		primaryCategory: "psychologists",
	},
	{
		title: "Schizophrenia Treatment",
		urlPath: "schizophrenia-treatment",
		reason: ["Schizophrenia"],
		primaryCategory: "psychiatrists",
	},
	{
		title: "Phobia Treatment",
		urlPath: "phobia-treatment",
		reason: ["Phobia"],
		primaryCategory: "psychiatrists",
	},
	{
		title: "Social Phobia Treatment",
		urlPath: "social-phobia-treatment",
		reason: ["Social Phobia"],
		primaryCategory: "psychiatrists",
	},
	{
		title: "Schizophrenia Therapy",
		urlPath: "schizophrenia-therapy",
		reason: ["Schizophrenia Therapy"],
		primaryCategory: "psychologists",
	},
	{
		title: "Eating Disorder Therapy",
		urlPath: "eating-disorder-therapy",
		reason: ["Eating Disorder"],
		primaryCategory: "psychologists",
	},
	{
		title: "Nimhans-alumni",
		urlPath: "nimhans",
		reason: ["Nimhans Alumni"],
		typeOfReason: "education",
		primaryCategory: "specialists",
	},
	{
		title: "Vimhans-alumni",
		urlPath: "vimhans",
		reason: ["Vimhans Alumni"],
		typeOfReason: "education",
		primaryCategory: "specialists",
	},
	{
		title: "Ihbas-alumni",
		urlPath: "ihbas",
		reason: ["Ihbas Alumni"],
		typeOfReason: "education",
		primaryCategory: "specialists",
	},
	{
		title: "Therapy for Anger Management",
		urlPath: "therapy-for-anger-management",
		reason: ["Therapy for Anger Management"],
		primaryCategory: "specialists",
	},
	{
		title: "Sexual Abuse Counselling and Treatment",
		urlPath: "sexual-abuse",
		reason: ["Sexual Abuse Counselling and Treatment"],
		primaryCategory: "specialists",
	},
];
