import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as env from '../../../../environments/environment';
import { plainToClass } from 'class-transformer';
import { ClientRelationship } from '../../models-ts/Entities/client-relationship';
import { Currency } from '../../models-ts/Helpers/countrywiseFeeCalc';
import { ServiceTypes } from '../../models-ts/Enums/Enums';
@Injectable({
  providedIn: 'root',
})
export class PatientRelationshipService {
 
  constructor(
    private _http: HttpClient
  ) {
    
  }

  async saveFee(clientId: string, providerId: string, amount: number, currency: Currency, serviceType: ServiceTypes) {
    const url =  env.crmBaseUrl + "/v1/cr/fee-config/update";
    try {
        const resp = await this._http.post(url,{
            clientId: clientId, providerId: providerId, amount: amount, currency: currency, serviceType: serviceType
        }).toPromise();
        if(resp["statusCode"] == 200 && resp["body"] && resp["body"]["success"]) {
            return true;
        } else {
            return null;
        }
    } catch (error) {
        console.error(error);
    }
  }

  async getClientRelationship(clientId: string) {
    const url = env.crmBaseUrl + "/v1/cr/get/info?clientId="+clientId;
    try {
        const resp = await this._http.get(url).toPromise();
        if(resp["statusCode"] == 200 && resp["body"] && resp["body"]["data"]) {
            return plainToClass(ClientRelationship, resp["body"]["data"]);
        } else {
            return null;
        }
    } catch (error) {
        console.error(error);
        return null;
    }
  }

  async getFeeSuggestion(clientId: string, providerId: string, serviceType: ServiceTypes) {
    try {
      const cr = await this.getClientRelationship(clientId);
      if(cr?.connectedProviders?.has(providerId)) {
        const feeConfig = cr.connectedProviders.get(providerId).feeConfig;
        if(feeConfig.has(serviceType)) {
          return feeConfig.get(serviceType);
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}
