<div
  *ngIf="chatListItemId != null"
  class="w-100 h-100 bg-white d-flex flex-column position-relative"
  style="
    border-left: 1px solid rgba(204, 223, 234, 0.15);
    border-right: 1px solid rgba(204, 223, 234, 0.15);
  "
>
  <div id="chat-header" class="d-flex align-items-center border-bottom p-2">
    <div id="avatar">
      <img
        src="assets/images/user-icon.png"
        class="client-image"
        [attr.loading]="'lazy'"
      />
    </div>
    <div class="d-flex flex-fill flex-column">
      <span class="fw-500 font-16" [tooltip]="chatListItem.displayName">{{
        chatDisplayName
      }}</span>
      <span *ngIf="chatItemLoader">
        Loading..
      </span>
      <div class="d-flex align-items-center">
        <span class="text-muted fw-500 font-12 me-2">{{
          chatListItem.platformSenderId
        }}</span>
        <div *ngIf="chatListItem.isClient && chatListItem.isClient.bool">
          <span class="badge bg-success fw-400 font-8 p-1"> Client </span>
        </div>
        <div
          *ngIf="chatListItem.isProvider && chatListItem.isProvider.bool"
          class="ms-1"
        >
          <span class="badge bg-info fw-400 font-8 p-1"> Provider </span>
        </div>
      </div>
    </div>
    <button
      class="btn btn-sm btn-outline-primary me-2 d-flex align-items-center"
      *ngIf="
        !chatListItem.isResolved ||
        (chatListItem.isResolved && chatListItem.isResolved?.bool == false)
      "
      [disabled]="resolvingChat"
      (click)="resolveChat()"
    >
      <i class="la la-check-double me-2" *ngIf="!resolvingChat"></i>
      <div
        class="spinner-border spinner-border-sm me-2"
        role="status"
        *ngIf="resolvingChat"
      >
        <span class="sr-only">Loading...</span>
      </div>
      Resolve
    </button>

    <div
      *ngIf="chatListItem.isResolved && chatListItem.isResolved?.bool == true"
      class="text-muted d-flex flex-column align-items-end"
    >
      <button
        class="btn btn-sm btn-outline-primary me-2 d-flex align-items-center"
        [disabled]="resolvingChat"
        (click)="unResolveChat()"
      >
        <i class="la la-check-double me-2" *ngIf="!resolvingChat"></i>
        <div
          class="spinner-border spinner-border-sm me-2"
          role="status"
          *ngIf="resolvingChat"
        >
          <span class="sr-only">Loading...</span>
        </div>
        Un-Resolve
      </button>
      <div style="font-size: 10px">
        Resolved on:
        {{ chatListItem.isResolved.updatedOn | date: "dd MMM, hh:mma" }}
      </div>
    </div>
    <div dropdown class="btn-group d-inline-block mx-2" *ngIf="!inLeads || !inFollowups">
      <span class="pointer" dropdownToggle aria-controls="dropdown-animated">
        <i class="feather icon-more-vertical"></i>
        <span class="caret"></span>
      </span>
      <ul
        id="dropdown-animated"
        *dropdownMenu
        class="dropdown-menu dropdown-menu-right"
        role="menu"
        aria-labelledby="button-animated"
      >
        <li role="menuitem">
          <a class="dropdown-item" (click)="markChatAsUnRead()">
            Mark as Unread
          </a>
        </li>
        <li role="menuitem">
          <a class="dropdown-item"> Create Escalation </a>
        </li>
      </ul>
    </div>
  </div>
  <div
    id="conversation"
    #messagesContainer
    class="d-flex"
    [ngClass]="{ 'messages-window ': !inLeads && !inFollowups, 'messages-window-3 ': inFollowups, 'messages-window-2': inLeads }"
    style="flex-direction: column-reverse"
    id="messagesContainer"
    (scroll)="scrollEvent($event)"
  >
    <div class="w-100 d-flex" *ngIf="loadingLaterMessages">
      <div
        class="spinner-border spinner-border-sm text-primary ms-auto me-auto mt-3 mb-3"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <app-chat-message-cell
      [ngStyle]="{'background-color': specificMessageIds.includes(m.messageId) ? '#d8d7f6' : 'none'}"
      *ngFor="
        let m of messages;
        trackBy: m?.messageId;
        let i = index;
        let last = last;
        let first = first
      "
      [lastIndex]="i == messages?.length - 3"
      [firstIndex]="i == 2"
      [indexVal]="i"
      [highLightId]="queriedMessageId"
      [message]="m"
      (loadPrev)="loadPrevMessages()"
      (loadNext)="loadLaterMessages()"
      (gotoReplyMessage)="goToMessageReply($event)"
      [id]="'_' + m.messageId"
    ></app-chat-message-cell>
    <!-- <button  (click)="loadPrevMessages()" class="btn btn-sm btn-outline-primary" *ngIf="!isLoading">Load More</button> -->
    <div class="w-100 d-flex" *ngIf="loadingOlderMessages">
      <div
        class="spinner-border spinner-border-sm text-primary ms-auto me-auto mt-3 mb-3"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <span class="" *ngIf="isLoading">Loading...</span>
    <button
      class="scroll-bottom-btn"
      *ngIf="isScrollingUp"
      tooltip="Scroll to latest"
      placement="top"
      (click)="scrollToBottom()"
    >
      <i class="feather icon-arrow-down text-primary fs-4"></i>
    </button>
  </div>
  <span class="ms-2 text-primary" style="height: 20px">{{
    chatListItem.whoIsTyping(loggedInUser.docvitaId)
  }}</span>
  <div
    id="chat-footer-input"
    (submit)="sendTextMessage(); $event.preventDefault()"
    class="form form-control d-flex ps-2 py-2 border-0"
    *ngIf="chatListItem.is24WindowActive()"
  >
    <textarea
      (keyup.enter)="sendTextMessage(); $event.preventDefault()"
      type="text"
      id="messageTextField"
      [(ngModel)]="textMessage"
      class="form-control ms-2"
      placeholder="Enter your message here"
    ></textarea>
    <button
      class="btn btn-sm btn-outline-primary mx-2 d-flex align-items-center border-0"
      (click)="chatFileUploaderRef.openModal()"
    >
      <i class="feather icon-upload font-18"></i>
      <!-- <i class="feather icon-send "></i> -->
    </button>
    <button
      class="btn btn-sm btn-primary me-2 d-flex align-items-center"
      (click)="sendTextMessage()"
      [disabled]="isSendingTextMessage"
    >
      <span
        *ngIf="isSendingTextMessage"
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
      <i *ngIf="!isSendingTextMessage" class="feather icon-send me-2"></i>
      Send
    </button>
    <!-- [(ngModel)]="message" 
        (keydown.enter)="sendMessage();$event.preventDefault()" -->
  </div>
  <div
    id="chat-footer-input"
    (submit)="sendHelloTemplateMessage(); $event.preventDefault()"
    class="form form-control d-flex ps-2 py-2 border-0"
    *ngIf="!chatListItem.is24WindowActive()"
  >
    <h6>Hello</h6>
    <textarea
      (keyup.enter)="sendHelloTemplateMessage(); $event.preventDefault()"
      type="text"
      id="messageTextField"
      [(ngModel)]="textMessage"
      class="form-control ms-2"
      placeholder="Enter your message here"
    ></textarea>
    <button
      class="btn btn-sm btn-primary me-2 d-flex align-items-center"
      (click)="sendHelloTemplateMessage()"
      [disabled]="isSendingTextMessage"
    >
      <span
        *ngIf="isSendingTextMessage"
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
      <i *ngIf="!isSendingTextMessage" class="feather icon-send me-2"></i>
      Send
    </button>
    <!-- [(ngModel)]="message" 
        (keydown.enter)="sendMessage();$event.preventDefault()" -->
  </div>
</div>

<div
  *ngIf="chatListItemId == null"
  class="w-100 h-100 bg-white d-flex flex-column"
  style="
    border-left: 1px solid rgba(204, 223, 234, 0.15);
    border-right: 1px solid rgba(204, 223, 234, 0.15);
  "
></div>

<chat-file-uploader
  [chatListItem]="chatListItem"
  #chatFileUploaderRef
></chat-file-uploader>
