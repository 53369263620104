import { Expose } from "class-transformer";
import {
  ReschedulingRequestStatus,
  ReschedulingRequestType,
} from "../Enums/Enums";

export class ReschedulingRequest {
  @Expose() id: string;
  @Expose() type: ReschedulingRequestType;
  @Expose() leadId: string;
  @Expose() status: ReschedulingRequestStatus;
  @Expose() appointmentId: string;
  @Expose() date: string;
  @Expose() timeslot: string;
  @Expose() clientId: string;
  @Expose() providerId: string;
  @Expose() suggestedDate: string;
  @Expose() suggestedTime: string;
  @Expose() createdOn: number;
  @Expose() createdById: string;
  @Expose() createdByName: string;
  @Expose() updatedOn: number;
  @Expose() updatedById: string;
  @Expose() updatedByName: string;
  @Expose() doctorName: string;
  @Expose() patientName: string;
  @Expose() actions: any[];
}
