export const listedInSuggestions = [
  {
    urlPath: "psychotherapists",
    title: "Psychotherapists"
  },
  {
    urlPath: "counselling-psychologists",
    title: "Counselling Psychologists"
  },
  {
    urlPath: "psychologists",
    title: "Psychologists"
  },
  {
    urlPath: "clinical-psychologists",
    title: "Clinical Psychologists"
  },
  {
    urlPath: "therapists",
    title: "Therapists"
  },
  {
    urlPath: "counsellors",
    title: "Counsellors"
  },
  {
    urlPath: "psychiatrists",
    title: "Psychiatrists"
  },
  {
    urlPath: "sexologists",
    title: "Sexologists"
  },
  {
    urlPath: "specialists",
    title: "Specialists"
  }
]