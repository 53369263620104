import { Expose, plainToClass, Transform, Type } from "class-transformer";
import { ServiceTypes } from "../../Enums/Enums";
import { TransactionSource } from "../../Enums/pay";
import { Currency } from "../../Helpers/countrywiseFeeCalc";
import { DayjsHelper } from "../../Helpers/dayjsHelper";
import { AmountWithCurrency } from "../../Protocols/AmountWithCurrency";
import { MembershipPlanLedgerItem } from "../../Protocols/MembershipPlanLedgerItem";
import { CountrywisePricingItem } from "./MembershipPlan";




export class PlanDetails {
  @Expose() createdOn: number;
  @Expose() id: string;
  @Expose() isCareManagerSupportOn: boolean;
  @Expose() isEmailSupportOn: boolean;
  @Expose() isMostPopular: boolean;
  @Expose() noOfSessions: number;
  @Expose() providerId: string;
  @Expose() sessionDurationInMinutes: number;
  @Expose() sessionType: ServiceTypes;
  @Expose() validity: number;
  @Expose() validityUnit: string;

  @Expose()
  @Type(() => CountrywisePricingItem)
  pricing: CountrywisePricingItem;

  get planExpiresOnDate() {
    return new DayjsHelper("Asia/Kolkata").unixToDDMMMYYYY((this.createdOn + 24 * 60 * 60 * 1000 * this.validity));
  }

  get appointmentBookingAmountAndCurrency() {
    const amount = Math.floor(this.pricing.sellingPrice / this.noOfSessions);
    const currency = this.pricing.currency;
    const obj = new AmountWithCurrency();
    obj.amount = amount;
    obj.currency = currency;
    return {
      amount: amount,
      currency: currency,
      providerDisplayAmount: this.pricing.sellingPricePerSessionInINR,
      providerDisplayCurrency: Currency.INR
    };
  }
}

//This is to define the purchased membership plans
export class MembershipPlanPurchased {
  @Expose() id: string;
  @Expose() amount: number;
  @Expose() createdOn: number;
  @Expose() category: string;
  @Expose() clientId: string;
  @Expose() country: string;
  @Expose() updatedOn: number;
  @Expose() currency: Currency;
  @Expose() date: string;
  @Expose() getStartedSessionId: string;
  @Expose() planId: string;
  @Expose() planPurchaseId: string;
  @Expose() providerId: string;
  @Expose() timeslot: string;
  @Expose() transferId: string;
  @Expose() transferSource: TransactionSource;

  @Expose()
  @Transform(
    (value) => {
      if (value) {
        let map = new Map<string, MembershipPlanLedgerItem>();
        for (let entry of Object.entries(value)) {
          map.set(entry[0], plainToClass(MembershipPlanLedgerItem, entry[1]));
        }
        return map;
      } else {
        return new Map<string, MembershipPlanLedgerItem>();
      }
    },
    { toClassOnly: true }
  )
  ledger: Map<string, MembershipPlanLedgerItem> = new Map();

  @Expose()
  @Type(() => PlanDetails)
  planDetails: PlanDetails;

  get amountPaid() {
    const obj = new AmountWithCurrency();
    obj.amount = this.amount;
    obj.currency = this.currency;
    return obj;
  }

  get ledgerBalance() {
    let balance = 0;
    if (this.ledger) {
      this.ledger.forEach((val, key) => {
        balance += (val.displayAmountPaidByClientHelper.currency != Currency.INR) ? val.displayAmountPaidByClientHelper.amount : val.credit;
        balance -= val.debit;
      });
    }
    return balance;
  }

  get pendingSessionBalance(): number {
    if (this.planDetails) {
      const pricePerSession = this.planDetails?.appointmentBookingAmountAndCurrency.amount;
      return Math.floor(this.ledgerBalance / pricePerSession);
    } else {
      return 0;
    }
  }

  get associatedLeads() {
    const arr: string[] = [];
    if (this.ledger) {
      this.ledger.forEach((val) => {
        if (val && val.leadId && !arr.includes(val.leadId)) {
          arr.push(val.leadId);
        }
      })
    }
    return arr;
  }

  get planExpiryDateReadable() {
    const expiry = new DayjsHelper("Asia/Kolkata").addDaysToYYYYMMDD(this.date, this.planDetails.validity)
    const dayjs = new DayjsHelper("Asia/Kolkata").getDayjs()
    return dayjs(expiry).format("DD MMM YYYY");
  }
  get planExpiryDateYYYYMMDD() {
    const expiry = new DayjsHelper("Asia/Kolkata").addDaysToYYYYMMDD(this.date, this.planDetails.validity)
    return expiry;
  }
}
