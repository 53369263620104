<button
  class="btn btn-outline-primary d-flex align-items-center justify-content-start border-0 w-100 mb-2"
  style="padding: 8px 20px 8px 8px"
  [ngClass]="{ 'btn-sm': isWingman }"
  *ngIf="showButton"
  (click)="fileUpload.click()"
>
  <ng-container *ngIf="uploading">
    <span
      class="spinner-border spinner-border-sm me-2"
      role="status"
      aria-hidden="true"
    ></span>
    <span class="visually-hidden">Loading...</span> </ng-container
  ><i class="feather icon-upload me-2"></i>
  {{ uploading ? "Uploading..." : "Upload Prescription" }}
</button>
<input
  type="file"
  style="display: none"
  (change)="addFileLocally($event)"
  #fileUpload
  multiple
/>

<!-- <ng-template #uploadRecords let-modal>
    <div class="modal-header">
      <h4 class="modal-title">
        Upload Prescription
      </h4>
      <div class="ms-auto d-flex align-items-center">
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="modal-body" style="position: relative;min-height: 200px;">
        
            <ng-container *ngIf="fileDetails&&fileDetails.length>0">
              <button class="btn btn-sm btn-primary mb-2" (click)="fileUpload.click()"><i class="feather icon-upload"></i> Add</button>
                <div class="w-100 d-flex flex-column align-items-center p-1" style="height: 400px;overflow-y: auto;">
                    <div class="card mb-0 p-2 d-flex flex-column mt-2 mx-1" style="width: 98%;" *ngFor="let item of fileDetails;let i=index;">
                        <div class="d-flex flex-row align-items-center justify-content-between w-100">
                          <div>
                            <i class="feather icon-edit-2"></i>
                          </div>
                          <input tooltip="Title" type="text" class="ms-1 border-0 form-control" [placeholder]="item.file.name" style="font-size: 0.9em;width: 85%;" [(ngModel)]="item.documentMeta.title">
                          <div class="ms-auto cursor-pointer" (click)="discardFile(i)">
                              <i class="feather icon-x-circle"></i>
                          </div>
                        </div>
                        <div class="d-flex align-items-center w-100 mt-2">
                          <input type="text" class="form-control w-100" placeholder="Description (optinal)" style="font-size: 0.85em;" [(ngModel)]="item.documentMeta.description">
                          <div class="btn-group ms-2" dropdown>
                            <button id="filedrop-db" dropdownToggle type="button" class="btn btn-sm btn-outline-primary dropdown-toggle"
                                    aria-controls="dropdown-basic" style="width: 120px;">
                              {{item.documentMeta.fileType}} <span class="caret"></span>
                            </button>
                            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu"
                                role="menu" aria-labelledby="filedrop-db">
                              <li role="menuitem" *ngFor="let type of fileTypes"><a class="dropdown-item" (click)="setFileType(type,i)">{{type}} </a></li>

                            </ul>
                          </div>  
                        </div>
                        <div class="w-100 d-flex align-items-center mt-2">
                          <div class="me-2">Source of file:</div>
                          <div class="form-check form-check-inline mb-0">
                            <input class="form-check-input" type="radio" [name]="'file-source'+i" [id]="'doctor-opt'+i" value="doctor" [(ngModel)]="item.documentMeta.source">
                            <label class="form-check-label ms-1" [for]="'doctor-opt'+i">Doctor</label>
                          </div>
                          <div class="form-check form-check-inline mb-0">
                            <input class="form-check-input" type="radio" [name]="'file-source'+i" [id]="'patient-opt'+i" value="patient" [(ngModel)]="item.documentMeta.source">
                            <label class="form-check-label ms-1" for="'patient-opt'+i">Patient</label>
                          </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="fileDetails.length==0">
                <div class="w-100 d-flex flex-column align-items-center mt-5" >
                    <div class="mb-2 text-muted">No files selected</div>
                    <button class="btn btn-primary" (click)="fileUpload.click()"><i class="feather icon-upload"></i> Upload</button>
                </div>
            </ng-container>

       <input type="file" style="display:none" (change)="addFileLocally($event)" #fileUpload multiple> 
    </div>
    <div class="modal-footer border-0">
        <button class="btn btn-outline-dark me-2" (click)="closeModal()">
          Close
        </button>
        <button type="button" *ngIf="fileDetails&&fileDetails.length>0" class="btn btn-outline-primary" (click)="uploadFiles()" [disabled]="uploading">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                *ngIf="uploading"></span>
            Upload
        </button>
      </div>
</ng-template> -->
