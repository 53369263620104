<div class="mx-2">
  <app-doctor-filter (populateDoctors)="filtersChange($event)"></app-doctor-filter>

  <div class="d-flex align-items-center w-100">
    <span class="flex-fill ms-1"> {{ listingProfiles?.length }} results </span>
    <button style="background: white" class="btn btn-sm btn-light" (click)="newDoctorClicked()">
      + New Specialist
    </button>
  </div>
  <!-- <button class="btn btn-sm btn-secondary ms-2" (click)="exportCSV()">
    Export CSV
  </button> -->
  <div style="
      overflow-y: scroll;
      height: calc(100vh - 310px);

      margin-top: 12px;
    ">
    <ng-container *ngIf="listingProfiles.length > 0">
      <div class="profile_card d-flex flex-column" *ngFor="
        let doctor of listingProfiles
          | slice: (page - 1) * pageSize:page * pageSize;
        let i = index
      " [ngClass]="{ provider_card_selected: isCurrentSelectedProvider(doctor) }" [ngStyle]="{
        'border-top':
          isCurrentSelectedProvider(doctor) && i == 0 ? '1px solid #777' : '0'
      }" (click)="onSelectedDoctorClicked(doctor, i)">
        <div class="d-flex align-items-center mb-1">
          <div class="flex-fill" style="font-size: 14px; font-weight: 700">
            {{ doctor.fullName() }}
          </div>
          <div style="font-size: 12px; font-weight: 500">
            {{ doctor.city }}
          </div>
        </div>
        <div style="
          font-size: 12px;
          font-weight: 500;
          word-wrap: break-word;
          line-height: 1;
        ">
          {{ getSpecialisation(doctor.specializations) }}
        </div>
      </div>
    </ng-container>
    <div *ngIf="fetching" class="d-flex justify-content-center align-items-center" style="height: 300px">
      <div class="spinner-border" role="status">
        <span class="sr-only">Fetching...</span>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-between align-items-center mt-2" style="position: sticky; bottom: 0">

    <pagination [totalItems]="listingProfiles?.length" [itemsPerPage]="pageSize" [(ngModel)]="page" maxSize="5"
      class="mb-0"></pagination>

    <select [(ngModel)]="pageSize" class="form-control ml-auto" style="width: fit-content;">
      <option [value]="10">10 per page</option>
      <option [value]="20">20 per page</option>
      <option [value]="30">30 per page</option>
    </select>
  </div>
</div>