import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { DoctorListingProfile } from 'src/app/shared/models-ts/Actors/Doctor/DoctorListingProfile';
import { CRMUser } from 'src/app/shared/models-ts/Actors/User/CRMUser';
import { Lead } from 'src/app/shared/models-ts/Entities/Lead';
import { AuthUserInfoService } from 'src/app/shared/services/auth-user-info/auth-user-info.service';
import { DoctorDataService } from 'src/app/shared/services/doctor-data/doctor-data.service';
import { DoctorsService } from 'src/app/shared/services/doctors/doctors.service';
import { ProviderModuleConfigsService } from 'src/app/shared/services/provider-module-configs/provider-module-configs.service'

@Component({
  selector: 'app-doc-profile-module-configs',
  templateUrl: './doc-profile-module-configs.component.html',
  styleUrls: ['./doc-profile-module-configs.component.scss']
})
export class DocProfileModuleConfigsComponent implements OnInit {
  dataLayerSubscription: Subscription;
  currentHeroInfo: CRMUser = new CRMUser();
  profileData:DoctorListingProfile = new DoctorListingProfile();

  updatingChatModule:boolean = false;
  chatModuleEnabled:boolean = false;
  
  constructor(
    private _doctorDataService: DoctorDataService,
    private _authUserInfoService: AuthUserInfoService,
    private _doctorService: DoctorsService,
    private _toastr: ToastrService,
    private _providerModuleConfigsService:ProviderModuleConfigsService
  ) { }

  ngOnInit(): void {
    this.currentHeroInfo = this._authUserInfoService.getUserInfo();
    this.dataLayerSubscription =
      this._doctorDataService.currentSelection.subscribe((data) => {
        if (data != null && data['memberSelected'] != null) {
          this.profileData = data['memberSelected'];
          this.getChatModuleConfig()
        }
      });
  }

  async getChatModuleConfig(){
    try {
      this.chatModuleEnabled = false;
      this.updatingChatModule = true;
      const enabled = await this._providerModuleConfigsService.getChatModuleConfig(this.profileData.id);

      if(enabled){
        this.chatModuleEnabled = true;
      }else{
        this.chatModuleEnabled = false;
      }
      // console.log("chat module enabled", this.chatModuleEnabled)
      this.updatingChatModule = false;
    } catch (error) {
      
      this.updatingChatModule = false;
      this._toastr.error("Failed to fetch chat module config")
    }
  }


  async enableChatModule(bool:boolean){
    try {
      this.updatingChatModule = true;
      const toggeled = await this._providerModuleConfigsService.enableChatModule(this.profileData.id,bool,this.currentHeroInfo?.docvitaId,this.currentHeroInfo?.name);
      if(toggeled){
        // this.chatModuleEnabled = bool;
        this._toastr.success("Updated chat module rules")
      }else{
        this._toastr.error("Failed to update")
      }
      this.updatingChatModule = false;
    } catch (error) {
      this.updatingChatModule = false;
      this._toastr.error("Failed to update")
    }
  }


}
