<ng-template #blockAllSlots>
    <div class="modal-header">
        <h5 class="modal-title pull-left">{{modalTitle}}</h5>
    </div>
    <div class="modal-body">
        {{modalbody}}
    </div>
    <div class="modal-footer border-0">
        <button type="button" class="btn btn-light" *ngIf="!canBlock" (click)="closeModal()">Ok, Got it.</button>
        <ng-container *ngIf="canBlock">
            <button type="button" class="btn btn-primary" [disabled]="btnLoader" (click)="closeAllOpenSlots()">
                <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true" *ngIf="btnLoader"></span>
                Yes
            </button>
            <button type="button" class="btn btn-light" *ngIf="!btnLoader" (click)="closeModal()">No</button>
        </ng-container>
    </div>
</ng-template>