import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as urls from '../../../../environments/environment';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { User } from '../../models-ts/Actors/User/User';
import { ManualCancellation } from '../../models-ts/Entities/PaylinkDetails';
import { classToPlain } from 'class-transformer';
import { CRMUser } from '../../models-ts/Actors/User/CRMUser';
@Injectable({
  providedIn: 'root',
})
export class PaymentLinksService {
  constructor(private _http: HttpClient) {}

  createPaymentLink(body, source) {
    return new Promise((resolve, reject) => {
      let url;
      if (source && source == 'razorpay') {
        url = urls.environment['razorpay-create-payment-link'];
      } else if (source && source == 'cashfree') {
        url = urls.environment['cashfree-create-payment-link'];
      } else if (source && source == 'stripe') {
        url = urls.environment['stripe-create-payment-link'];
      }
      this._http.post(url, body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  sendBookingPaymentLinkonWA(body, source) {
    return new Promise((resolve, reject) => {
      let url;
      if (source && source == 'razorpay') {
        url = urls.environment['razorpay-booking-link-share-wa'];
      } else if (source && source == 'cashfree') {
        url = urls.environment['cashfree-booking-link-share-wa'];
      }else if (source && source == 'stripe') {
        url = urls.environment['stripe-booking-link-share-wa'];
      }
      this._http.post(url, body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  getAllPaymentLinks(
    paidOnly?: boolean,
    unpaidOnly?: boolean,
    consultationDate?: string
  ) {
    let ref = firebase
      .firestore()
      .collection('paylinks-service')
      .orderBy('created_at', 'desc');

    if (paidOnly) {
      ref = ref.where('status', '==', 'paid');
    } else if (unpaidOnly) {
      ref = ref.where('status', '==', 'created');
    }
    if (consultationDate) {
      ref = ref.where('consultationInfo.date', '==', consultationDate);
    }
    ref = ref.limit(100);
    return ref;
  }

  manualCancelState(
    paymentLinkId: string,
    currentHero: CRMUser,
    bool: boolean
  ) {
    return new Promise((resolve, reject) => {
      let markAsCancelled = new ManualCancellation();
      markAsCancelled.updatedById = currentHero.docvitaId;
      markAsCancelled.updatedByName = currentHero.name;
      markAsCancelled.updatedOn = new Date().getTime();
      markAsCancelled.bool = bool;
      let jsonVal = {
        markedAsCancelled: JSON.parse(
          JSON.stringify(classToPlain(markAsCancelled))
        ),
      };
      let body = {
        linkId: paymentLinkId,
        jsonVal: jsonVal,
      };
      let url = urls.environment['paylinks-service-update-json'];
      this._http
        .post(url, body)
        .toPromise()
        .then((res) => {
          if (
            res &&
            res['statusCode'] == 200 &&
            res['body']['success'] == true
          ) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
