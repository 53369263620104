import { Injectable } from '@angular/core';
import { MembershipPlanPurchased } from '../../models-ts/Entities/MembershipPlan/MembershipPlanPurchased';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/database';
import { plainToClass } from 'class-transformer';

@Injectable({
  providedIn: 'root'
})
export class MembershipDetailsService {

  constructor() { }

  async patientMembershipPlans(clientId: string,providerId?:string) {
    try {
      const membershipPlans: MembershipPlanPurchased[] = [];
      let ref = firebase.firestore().collection("membership_plans_purchased").where("clientId", "==", clientId);

      if(providerId&&providerId.length>0){
        ref = ref.where("providerId", "==", providerId);
      }

      const snap = await ref.get();
      
      if (!snap.empty) {
        snap.forEach(doc=>{
          if(doc.exists) {
            const m = plainToClass(MembershipPlanPurchased, doc.data());
            membershipPlans.push(m);
          }
        })
      }

      membershipPlans.sort((a,b)=>{
        return a?.planDetails?.noOfSessions - b?.planDetails?.noOfSessions;
      })

      return membershipPlans;
    } catch (err) {
      console.error(err);
      return [];
    }
  }

}
