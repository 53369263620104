import { URLHelper } from '../app/url-helper';

export const baseUrl = 'https://india-pro-api.docvita.app';
export const crmBaseUrl = 'https://crm-prod.sencillo.in';
export const listingUrl = 'https://listing.docvita.app';
export const providerSignupBackendUrl = '';

export const websiteBaseUrl = 'https://docvita.com';

let urls = new URLHelper().urls(baseUrl,crmBaseUrl,listingUrl);

let env = {
  production: true,
	analyticsTracking: true,
	conversionTracking: true,
  initiatePaymentGatewayUrl:
        'https://www.cashfree.com/checkout/post/submit',
  firebaseConfig: {
    apiKey: "AIzaSyCStUB506JnLWp4V5ntef6Vt8RT_AVqczo",
    authDomain: "docvita-pro.firebaseapp.com",
    databaseURL: "https://docvita-pro.firebaseio.com",
    projectId: "docvita-pro",
    storageBucket: "docvita-pro.appspot.com",
    messagingSenderId: "867083659261",
    appId: "1:867083659261:web:3829ad0eb3ab0a2de91b94",
    measurementId: "G-PTDZ7NYTJ8"
  },
	iframeListingBaseUrl: "https://docvita.com",
	elasticSearchUrl: "https://e57bdd38412247f5a2e2d197ef167ec0.ap-south-1.aws.elastic-cloud.com:9243",
	elasticSearchAPIKey: "elastic:sgZhW60ZrDB0EsqOAeI7c2TJ",
	zoomClientId: "_2FkXp_zQXa3HPLm9L7Kgg",
        cloudFunctionsUrl: "https://us-central1-docvita-pro.cloudfunctions.net",
        algoliaAppID:"ECGB7FM5S4",
        algoliaAdminAPIKey:"ccf84099fab64c3e172df25bff193016",
};

export const environment = Object.assign(env, urls);