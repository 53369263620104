<div class="d-flex flex-row align-items-center justify-content-between mb-2 mx-2 w-100">
    <div class="d-flex flex-column">
        <h2>
            Working Hours
        </h2>
        <span class="help-text">default schedule</span>
    </div>
    <!-- appears only if any change made in weekly schedule -->
    <!-- <div class="ms-auto" *ngIf="true"> -->
    <div class="ms-auto" *ngIf="showSaveActions && !loader">
        <button class="btn btn-primary me-2 save-action-btns" [disabled]="hoursHaveErrors" (click)="saveSchedule()">Save Changes</button>
        <button class="btn btn-outline-primary me-2 save-action-btns" (click)="toggleSaveActions(false,true)">Cancel</button>
    </div>
    <div class="spinner-border spinner-border-sm" role="status" *ngIf="loader">
      <span class="visually-hidden">Loading...</span>
    </div>
</div>

<div class="white-card d-flex flex-column">
    <div class="d-flex justify-content-start border-bottom py-3 px-2 w-100 day-schedule"
          *ngFor="let day of schedule | keyvalue: returnZero">
          <div class="d-flex align-items-center day-label-container" >
            <!-- <input type="checkbox" [(ngModel)]="day.value.bool" /> -->
            <div class="mx-1 px-2 day-label" style="font-weight: 600">
              {{ day.key }}
            </div>
            <div class="ms-auto show-in-mobile">
                <ng-container *ngTemplateOutlet="dayOptions;context:{day:day};"></ng-container>
            </div>
          </div>
          <div class="d-flex flex-column">
            <ng-container *ngFor="let slot of day.value.slots; let i = index">
              <div class="d-flex align-items-center mx-1 px-3 mb-1 time-inputs-row">
              <input
                type="time"
                class="form-control me-2"
                style="width: 145px"
                (blur)="slotValidationChecker(day.key,i)"
                (change)="toggleSaveActions(true)"
                [(ngModel)]="slot['startTime']"
              />
              <input
                type="time"
                class="form-control me-2"
                style="width: 145px"
                (blur)="slotValidationChecker(day.key,i)"
                (change)="toggleSaveActions(true)"
                [(ngModel)]="slot['endTime']"
              />
              <button
                type="button"
                class="btn btn-outline-danger border-0 btn-sm"
                (click)="deleteSlot(day.key, i)"
              >
                <i class="feather icon-trash-2"></i>
              </button>

              <div class="d-flex align-items-center ms-5 gap-3" *ngIf="practiceOptions && practiceOptions?.length > 0">
                <div class="d-flex align-items-center">
                  <input class="form-check-input me-2" type="checkbox" (change)="toggleSaveActions(true)" [id]="'in-person-checkbox'+i+'_'+day.key" [(ngModel)]="slot['availableAtPractices']['bool']">
                  <label class="form-check-label" [for]="'in-person-checkbox'+i+'_'+day.key" style="white-space: nowrap;">
                    In-Person?
                  </label>
                </div>
                <ng-container *ngIf="slot['availableAtPractices']['bool']">
                  <select class="form-control" [(ngModel)]="slot['availableAtPractices']['practiceId']">
                    <option [value]="item.key" *ngFor="let item of practiceOptions">{{item.value}}</option>
                  </select>
                </ng-container>
              </div>
            </div>
            <div class="font-12 text-danger mx-2 mb-2 pb-2 px-3" *ngIf="slotErrors&&slotErrors[day.key+i]&&slotErrors[day.key+i]['dayKey']==day.key&&slotErrors[day.key+i]['slotIndex']==i">
              {{slotErrors[day.key+i]['reason']}}
            </div>
            </ng-container>
          </div>
          <div *ngIf="day.value.slots && day.value.slots.length == 0"
            class="text-muted justify-content-center align-items-center px-2 unavailable-text" style="margin-top: 3px">
            Unavailable
          </div>
          <div class="ms-auto show-in-desktop">
            <ng-container *ngTemplateOutlet="dayOptions;context:{day:day};"></ng-container>
          </div>
        </div>

</div>

<ng-template #dayOptions let-day="day">
    <div class="d-flex gap-2">
        <button type="button" class="btn btn-sm btn-outline-primary border-0 px-2 mt-2 add-time-btn" style="height: 25px"
          (click)="addSlot(day.key);toggleSaveActions(true)">
          <i class="feather icon-plus"></i>
        </button>

        <div class="btn-group" dropdown [insideClick]="true">
          <button id="button-animated" dropdownToggle type="button" class="btn btn-sm btn-outline-primary border-0 mt-2 copy-time-btn"
            aria-controls="dropdown-animated" style="height: 25px" (click)="copyEvent(day.key)">
            <i class="feather icon-copy"></i>
          </button>
          <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-config">
            <p class="text-muted px-2">COPY TIMES TO ...</p>
            <li role="menuitem" *ngFor="let day of schedule | keyvalue:returnZero">
              <div class="d-flex align-items-center justify-content-between p-2" *ngIf="day.key != selectedCopyDayKey">
                <div class="ms-2">{{ day.key }}</div>
                <input type="checkbox" (change)='copyTheDaysToCopy($event.target.checked, day.key)' />
              </div>
            </li>
            <div class="d-flex flex-column py-1 px-2">
              <button class="btn btn-primary" dropdownToggle (click)="applyCurrentCopyEvent()">
                Apply
              </button>
            </div>
          </ul>
        </div>
    </div>
</ng-template>