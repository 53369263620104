import { Person } from "../Person/Person";
import {
  Expose,
  Transform,
  plainToClass,
  Type,
  Exclude,
} from "class-transformer";
import { HealthHeroAccessLevels } from "../../Relationships/HealthHeroInfo";

export class CRMUser extends Person {

  @Expose() roles: string[] = []; //inputter, verifier, admin, docvita_admin, crm_access

  @Expose() suspended: boolean = false;

  @Type(() => HealthHeroAccessLevels)
  @Expose()
  access: HealthHeroAccessLevels = new HealthHeroAccessLevels();

  @Expose()
  createDoctorProfileOnNewRecommendation: boolean;

  isDocVitaAdmin() {
    return this.roles != null && this.roles.includes("docvita_admin");
  }

  isAllowedCRMAccess() {
    return this.roles != null && this.roles.includes("crm_access");
  }

  hasApproverPermission() {
    return this.roles != null && (this.roles.includes("approver") || this.roles.includes("docvita_admin"));
  }

  getFirstName() {
    let str;
    if (this.name != null) {
      let name = this.name;
      if (name.includes(".") && !name.includes(". ")) {
        name = name.split(".").join(". ");
      }
      let arr = name.split(" ");
      let trimmedArr: string[] = [];
      for (let a of arr) {
        let val = a.toLowerCase();
        if(val == "mrs" || val == "mr" || val == "dr") {
          //No action required
        }else {
          trimmedArr.push(a.trim());
        }
      }
      for (let t of trimmedArr) {
        if (
          str == null &&
          t.length > 2 &&
          !t.includes("(") &&
          !t.includes(")") &&
          !t.includes(".")
        ) {
          str = t;
        }
      }
    }
    if(str == null) {
      return this.name;
    }
    return str;
  }

  get hasSpecialAccess():boolean {
    let bool = false;
    if (
      this.contactNumbers &&
      this.contactNumbers.has('91') &&
      (this.contactNumbers.get('91').has('9407406690') ||
        this.contactNumbers.get('91').has('9713311888'))
    ) {
      bool = true;
    }
    return bool;
  }

  get hasSpecialAccessWithTeam():boolean {
    let bool = false;
    if (
      this.contactNumbers &&
      this.contactNumbers.has('91') &&
      (this.contactNumbers.get('91').has('9407406690') ||
        this.contactNumbers.get('91').has('9713311888') ||
        this.contactNumbers.get('91').has('7021474301')
        )
    ) {
      bool = true;
    }
    return bool;
  }

}
