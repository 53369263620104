import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DoctorListingProfile } from '../../models-ts/Actors/Doctor/DoctorListingProfile';
import { CRMUser } from '../../models-ts/Actors/User/CRMUser';
import { User } from '../../models-ts/Actors/User/User';
import { AuthUserInfoService } from '../../services/auth-user-info/auth-user-info.service';
import { CopyClipboardService } from '../../services/copy-clipboard/copy-clipboard.service';
declare var $: any;
@Component({
  selector: 'app-provider-header',
  templateUrl: './provider-header.component.html',
  styleUrls: ['./provider-header.component.css'],
})
export class ProviderHeaderComponent implements OnInit {
  @Input() selectedDoctor: DoctorListingProfile;
  @Input() showActions: boolean = false;
  defaultLogoUrl: string =
    'https://default-docvita-images.s3.ap-south-1.amazonaws.com/no-image-available.jpg';
  healthHeroInfo: CRMUser = new CRMUser();

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _copyToClipboardService: CopyClipboardService,
    private _toastr: ToastrService,
    // private _crmProviderService: CrmProvidersService,
    private _authUserInfoService: AuthUserInfoService
  ) { }

  ngOnInit(): void {
    this.healthHeroInfo = this._authUserInfoService.getUserInfo();
  }

  copyProviderID() {
    if (this.selectedDoctor != null) {
      this._copyToClipboardService.copyToClipoard(this.selectedDoctor.id);
    } else {
      this._toastr.error('No doctor selected!');
    }
  }
  copyDoctorsFullName() {
    if (this.selectedDoctor != null) {
      this._copyToClipboardService.copyToClipoard(
        this.selectedDoctor.fullName()
      );
    } else {
      this._toastr.error('No doctor selected!');
    }
  }
  copySpecializations() {
    if (this.selectedDoctor != null) {
      if (
        this.selectedDoctor.specializations != null &&
        this.selectedDoctor.specializations.length > 0
      ) {
        this._copyToClipboardService.copyToClipoard(
          this.selectedDoctor.specializations.toString().split(',').join(', ')
        );
      } else {
        this._toastr.error('Email Not Available');
      }
    } else {
      this._toastr.error('No doctor selected!');
    }
  }

  doesPhotographExist() {
    if (this.selectedDoctor != null && this.selectedDoctor['photoUrl']) {
      return true;
    } else {
      return false;
    }
  }

  noCacheImage(url) {
    return url + `?cache=${new Date()}`;
  }
}
