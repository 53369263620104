<button class="btn btn-sm btn-outline-primary border-0 d-flex justify-content-center align-items-center"
    (click)="openModal(refundManual)">
    <i style="font-size: 15px" class="material-icons me-1">
        reply
    </i>
    Manual Refund Entry
</button>

<ng-template #refundManual let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Manual Refund Entry</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()" style="outline: none;">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body d-flex flex-column justify-content-center w-100">
        <div class="card rounded mt-1 p-2">
            <h5>Details:</h5>
            <hr class="m-1 p-0">
            <div><span style="font-weight: 500;">Name:</span> {{leadDetails.patientName?leadDetails.patientName:"-"}}
            </div>
            <div><span style="font-weight: 500;">Contact:</span>
                {{leadDetails.patientContactInfo?('+'+leadDetails.patientContactInfo.countryCode+'-'+leadDetails.patientContactInfo.number):""}}
            </div>
            <div><span style="font-weight: 500;">Amount Received:</span>
                {{leadDetails.consultationInfo.totalAmountPaid}} {{currencyToSymbol(leadDetails.consultationInfo?.currency)}}</div>

        </div>
        <div class="d-flex align-items-center mt-2">
            <h6 class="me-3 form-row-header">Refund Source</h6>
            <div dropdown class="btn-group d-inline-block w-100">
                <button id="button-animated" dropdownToggle type="button"
                    class="btn btn-sm btn-outline-secondary dropdown-toggle" aria-controls="dropdown-animated">
                    {{ refundSource || "Select Refund Source" }}
                </button>
                <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu" role="menu"
                    aria-labelledby="button-animated">
                    <li role="menuitem" *ngFor="let source of refundSourceArray">
                        <a class="dropdown-item" (click)="refundSourceChanged(source)">
                            {{ source }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="d-flex align-items-center mt-2">
            <h6 class="me-3 form-row-header">Refund Id</h6>
            <input type="text" class="form-control" style="width: 180px;" [(ngModel)]="refundId">
        </div>
        <!-- <ng-container  *ngIf="cashfreeOnlyField()">
            <div class="d-flex align-items-center mt-2">
                <h6 class="me-3 form-row-header">Refund Id</h6>
                <input type="text" class="form-control" style="width: 180px;" [(ngModel)]="refundId">
            </div>
        </ng-container> -->
        <ng-container *ngIf="refundSource != null && refundSource.length > 0">
            <div class="d-flex align-items-center mt-2">
                <h6 class="me-3 form-row-header">Refund Amount</h6>
                <div class="d-flex">
                    <input type="text" class="form-control me-1" style="width: 100px;" [(ngModel)]="refundAmount">
                    <currency-dropdown [defaultValue]="this.currency" (selectedCurrency)="setCurrency($event)"></currency-dropdown>
                </div>
                
            </div>
            <div class="d-flex align-items-start mt-2">
                <h6 class="me-3 form-row-header">Refunded On</h6>
                <div>
                    <input type="date" class="form-control mb-2" placeholder="" style="font-size: 13px;"
                        [(ngModel)]="refundedOnDate" required>
                    <input type="time" class="form-control mb-2" placeholder="" style="font-size: 13px;"
                        [(ngModel)]="refundedOnTime" required>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="modal-footer">
        <button type="button" [disabled]="isLoading" class="btn btn-outline-dark"
            (click)="initiateRefund()">Refund</button>
    </div>
</ng-template>