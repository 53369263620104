import { Component, OnInit } from '@angular/core';
import { classToPlain } from 'class-transformer';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { DoctorListingProfile } from 'src/app/shared/models-ts/Actors/Doctor/DoctorListingProfile';
import { CRMUser } from 'src/app/shared/models-ts/Actors/User/CRMUser';
import { Schedule } from 'src/app/shared/models-ts/Entities/Schedule';
import { AuthUserInfoService } from 'src/app/shared/services/auth-user-info/auth-user-info.service';
import { DoctorDataService } from 'src/app/shared/services/doctor-data/doctor-data.service';
import { DoctorsService } from 'src/app/shared/services/doctors/doctors.service';
import { DayjsHelper } from 'src/app/shared/models-ts/Helpers/dayjsHelper';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault('Asia/Kolkata');
@Component({
  selector: 'app-doc-profile-new-slots',
  templateUrl: './doc-profile-new-slots.component.html',
  styleUrls: ['./doc-profile-new-slots.component.scss'],
})
export class DocProfileNewSlotsComponent implements OnInit {
  copyEventObj: any = {};
  dayToClone: string[] = [];
  selectedCopyDayKey: string;
  overridedSlotsArray: any[] = []
  overrideDateScoller = new Date();
  currentHeroInfo: CRMUser = new CRMUser();
  doctorProfile: DoctorListingProfile = new DoctorListingProfile();
  dataLayerSubscription: Subscription;
  loader: boolean = false;
  constructor(
    private _doctorDataService: DoctorDataService,
    private _authUserInfoService: AuthUserInfoService,
    private _doctorService: DoctorsService,
    private toastrService: ToastrService
  ) { }

  schedule: any = {
    SUN: {
      bool: false,
      slots: [],
    },
    MON: {
      bool: false,
      slots: [],
    },
    TUE: {
      bool: false,
      slots: [],
    },
    WED: {
      bool: false,
      slots: [],
    },
    THU: {
      bool: false,
      slots: [],
    },
    FRI: {
      bool: false,
      slots: [],
    },
    SAT: {
      bool: false,
      slots: [],
    },
  };

  scheduleConfig = {
    availableForVideoConsultation: {
      bool: true,
      display: 'Video Consult'
    },
    availableForAudioConsultation: {
      bool: true,
      display: 'Phone Consult'
    },
    availableForChatConsultation: {
      bool: true,
      display: 'Chat Consult'
    },
    acceptsBookingOnDocVita: {
      bool: true,
      display: 'Accept Booking On Docvita'
    },
    acceptsCallOnDocVita: {
      bool: true,
      display: 'Accept Call On Docvita'
    }
  }

  scheduleGenericSlotLength: number;

  ngOnInit(): void {
    this.initForm();
    this.currentHeroInfo = this._authUserInfoService.getUserInfo();
    this.dataLayerSubscription = this._doctorDataService.currentSelection.subscribe(
      (data) => {
        if (data != null && data['memberSelected'] != null) {
          this.doctorProfile = data['memberSelected'];
          this.populateSchedule();
          this.populateSlotOverrides();
        }
      }
    );

  }

  initForm() {
    this.copyEventObj = {};
    this.dayToClone = [];
    this.resetSchedule();
  }
  resetSchedule() {
    for (let sc in this.schedule) {
      this.schedule[sc]['bool'] = false;
      this.schedule[sc]['slots'] = [];
    }
  }
  populateSchedule() {
    this.resetSchedule();
    if (this.doctorProfile.schedule && this.doctorProfile.schedule.length > 0) {
      this.scheduleGenericSlotLength = +this.doctorProfile.schedule[0].timeSlotLength
      for (let sch of this.doctorProfile.schedule) {
        if (sch && sch.days && sch.days.length > 0 && sch.startTime && sch.endTime && sch.acceptsBookingOnDocVita == true) {
          for (let c in this.scheduleConfig) {
            if (sch[c] != null) {
              this.scheduleConfig[c]['bool'] = sch[c]
            }
          }
          for (let d of sch.days) {
            this.addSlot(d.toUpperCase(), sch.startTime, sch.endTime)
            this.schedule[d.toUpperCase()]['bool'] = true;
          }
        }
      }
    }
  }

  populateSlotOverrides() {
    let overridedSlotsMap = {}
    this.overridedSlotsArray = []
    const todayDate = new DayjsHelper('Asia/kolkata').todayYYYYMMDD()
    if (this.doctorProfile && this.doctorProfile.id && this.doctorProfile.openSlots) {
      for (const slot in this.doctorProfile.openSlots) {
        const openslot = this.doctorProfile.openSlots[slot]
        if (openslot && openslot['date'] && openslot['timeslot'] && openslot['date'] >= todayDate) {
          if (overridedSlotsMap[openslot['date']] != null) {
            overridedSlotsMap[openslot['date']].push({ id: slot, timeslot: openslot['timeslot'] })
          } else {
            overridedSlotsMap[openslot['date']] = [{ id: slot, timeslot: openslot['timeslot'] }]
          }
        }
      }
    }
    if(overridedSlotsMap&&Object.keys(overridedSlotsMap).length>0){
      for(const key in overridedSlotsMap){
        let oslots = overridedSlotsMap[key]
        oslots.sort((a, b) => {
          return a['timeslot'].localeCompare(b['timeslot']);
        })
        this.overridedSlotsArray.push({date:key,oslots:oslots})
      }
    }
    this.overridedSlotsArray.sort((a, b) => {
      return a['date'].localeCompare(b['date']);
    })
  }

  addSlot(day: string, startTime?: string, endTime?: string) {
    this.schedule[day]['slots'].push({
      startTime: startTime ? startTime : null,
      endTime: endTime ? endTime : null,
    });
  }

  deleteSlot(day: string, index: number) {
    if (
      this.schedule[day]['slots'][index].startTime != null ||
      this.schedule[day]['slots'][index].endTime != null
    ) {
      this.schedule[day]['slots'][index].startTime = null;
      this.schedule[day]['slots'][index].endTime = null;
    } else {
      this.schedule[day]['slots'].splice(index, 1);
    }
  }

  returnZero() {
    return 0;
  }

  copyEvent(dayToCopy: string) {
    this.dayToClone = [];
    this.selectedCopyDayKey = dayToCopy;
    this.copyEventObj = Object.assign({}, this.schedule[dayToCopy]);
  }

  copyTheDaysToCopy(event, day: string) {
    const bool: boolean = event;
    if (!this.dayToClone.includes(day) && bool) {
      this.dayToClone.push(day);
    } else if (!bool) {
      const index = this.dayToClone.findIndex((da) => da == day);
      this.dayToClone.splice(index, 1);
    }
  }

  applyCurrentCopyEvent() {
    const slotsToCopy =
      this.copyEventObj && this.copyEventObj['slots']
        ? this.copyEventObj['slots']
        : null;
    if (this.dayToClone.length > 0 && slotsToCopy) {
      for (let day of this.dayToClone) {
        this.schedule[day]['slots'] = JSON.parse(JSON.stringify(slotsToCopy));
      }
    }
    this.dayToClone = [];
  }

  markAllConfig(event: boolean) {
    console.log(event)
    const bool: boolean = event;
    for (let i in this.scheduleConfig) {
      this.scheduleConfig[i]['bool'] = bool;
    }
  }

  setSpecificConfig(key: string, event: boolean) {
    this.scheduleConfig[key]['bool'] = event;
  }

  async saveSchedule() {
    if (this.scheduleGenericSlotLength != null) {
      this.loader = true;
      let finalSchedules = []
      let slotsMergeMap = {}
      for (const sc in this.schedule) {
        const schedule = this.schedule[sc];
        if (schedule['slots'] && schedule['slots'].length > 0) {
          for (const _slot of schedule['slots']) {
            if (_slot['startTime'] && _slot['endTime']) {
              const slot_key = _slot['startTime'] + "-" + _slot['endTime']
              if (slotsMergeMap[slot_key] && !slotsMergeMap[slot_key].includes(sc)) {
                slotsMergeMap[slot_key].push(sc)
              } else {
                slotsMergeMap[slot_key] = [sc]
              }
            }

          }
        }
      }
      if (slotsMergeMap != null && Object.keys(slotsMergeMap).length > 0) {
        for (const slot in slotsMergeMap) {
          let new_schedule = new Schedule();
          //set default config
          for (let c in this.scheduleConfig) {
            new_schedule[c] = this.scheduleConfig[c]['bool']
          }
          new_schedule.timeSlotLength = this.scheduleGenericSlotLength;
          new_schedule.days = slotsMergeMap[slot].map(d => {
            return this.capitalize(d)
          })
          new_schedule.startTime = slot.split("-")[0]
          new_schedule.endTime = slot.split("-")[1]
          finalSchedules.push(classToPlain(new_schedule))
        }
      }
      if (finalSchedules && finalSchedules.length > 0) {
        try {
          await this._doctorService.updateScheduleInformation(finalSchedules, this.doctorProfile.id)
          this.toastrService.success("Schedule Updated!")
          this.loader = false;
        } catch (error) {
          this.toastrService.success("Schedule failed to update!")
          this.loader = false;
        }
      } else {
        this.toastrService.success("No schedule!")
        this.loader = false;
      }

    }
  }

  private capitalize(val: string) {
    if (val && val.length > 0) {
      val = val[0] + val.slice(1).toLowerCase()
    }
    return val;
  }

  timeslotFormatter(timeslot: string, date: string) {
    if (timeslot && timeslot.length > 0) {
      const st = timeslot.split("-") ? timeslot.split("-")[0] : null;
      const et = timeslot.split("-") ? timeslot.split("-")[1] : null;
      if (st && et && date) {
        return dayjs(date + ' ' + st).format("hh:mma") + " - " + dayjs(date + ' ' + et).format("hh:mma")
      } else {
        return ''
      }
    } else {
      return ''
    }
  }

  editOpenslotForDay(dayKey: string) {

  }
}
