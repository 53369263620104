<button class="btn btn-sm btn-outline-primary border-0" *ngIf="showButton" (click)="openModalWithFetch();">
    Appointment Conflicts
</button>

<ng-template #apptClashModal let-modal style="background-color: teal;">
    <div class="modal-header">
      <h4 class="modal-title">
        Appointment Conflicts
      </h4>
      <div class="ms-auto d-flex align-items-center">
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="modal-body d-flex flex-column align-items-center p-3" style="position: relative;">
      <div class="text-muted">
        <span>{{doctorName?doctorName:"The provider "}} has some overlapping appointments for specified time</span>
        <span style="font-weight: 600;">{{selectedDateTime}}</span>
      </div>
        <div class="list-container w-100 mt-2" *ngIf="conflictingLeads&&conflictingLeads.length>0&&!isLoading">
            <div class="border p-2 w-100 mb-2 d-flex align-items-center" *ngFor="let lead of conflictingLeads">
              <span>{{displayFormattedDateTime(lead)}} with {{lead.patientName}}</span> 
              <button class="btn btn-sm btn-outline-primary border-0 ms-auto" tooltip="Open Appointment" (click)="gotoLead(lead)">
                <i class="feather icon-external-link"></i>
              </button>
            </div>  
            
        </div>
        
        <div *ngIf="!isLoading&&conflictingLeads.length==0" class="m-4">
            No Conflicts 💤
        </div>
        <div class="spinner-border" role="status" *ngIf="isLoading">
          <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <div class="modal-footer">
      <button
      (click)="closeModal()"
      class="btn btn-outline-secondary"
    >
      Ok, I understand
    </button>
    </div>
  </ng-template>