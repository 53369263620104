import { Expose } from "class-transformer";
import { CashgramRefundStatus } from "./Enums";

export class CashgramRefund {
  @Expose() id: string;
  @Expose() createdOn: number;
  @Expose() createdById: string;
  @Expose() createdByName: string;
  @Expose() updatedOn: number;
  @Expose() updatedById: string;
  @Expose() updatedByName: string;
  @Expose() leadId: string;
  @Expose() appointmentId: string;
  @Expose() clientId: string;
  @Expose() clientName: string;
  @Expose() clientPhone: string;
  @Expose() clientEmail: string;
  @Expose() amount: number;
  @Expose() expiryDate: string; // format - YYYY/MM/DD
  @Expose() remarks: string; //description
  @Expose() cashgramLinkInfo: CashgramLinkInfo;
  @Expose() status: CashgramRefundStatus;
  @Expose() actions: CashgramRefundAction[];
  @Expose() isWalletRefund:boolean;
  
  public get isDeactivated() {
    return this.status == CashgramRefundStatus.DEACTIVATED;
  }
}

export class CashgramLinkInfo {
  @Expose() referenceId: string;
  @Expose() cashgramLink: string;
}

export class CashgramRefundAction {
  @Expose() createdOn: number;
  @Expose() status: CashgramRefundStatus;
  @Expose() metaData: any;
}
